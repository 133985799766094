import { combineReducers } from 'redux'
import {createSlice} from "@reduxjs/toolkit";

export const MFDIDetailSlice = createSlice({
  name: "MFDIDetail",
  initialState: null,
  reducers: {
    MFDIDetailRequest: {
      reducer(){
        return {loading: true}
      }
    },
    MFDIDetailSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    MFDIDetailFailed: {
      reducer(){
        return {}
      }
    },
    uploadMFDIOssFile: {
      reducer(state){
        return state
      }
    },
    uploadMFDIOssFileRequest: {
      reducer(state){
        return state
      }
    },
    uploadMFDIOssFileSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return state
      }
    },
    uploadMFDIOssFileFailed: {
      reducer(state){
        return state
      }
    },
    getMFDIOssFileRequest: {
      reducer(state){
        return state
      }
    },
    getMFDIOssFileSuccess: {
      reducer(state, action) {
        const data = action.payload;
        const url = data.replace('https://files.padi.com.cn/', '')
        const certType = url.split('/')[2]
        if(certType === 'efri') {
          const { efrifiles, ...detail } = state
          return { efrifiles: efrifiles ? [ data, ...efrifiles ] : [data], ...detail }
        }else if(certType === 'fdi') {
          const { fdifiles, ...detail } = state
          return { fdifiles: fdifiles ? [ data, ...fdifiles ] : [data], ...detail }
        }else if(certType === 'teachingCerts') {
          const { teachingCountFiles, ...detail } = state
          return { teachingCountFiles: teachingCountFiles ? [ data, ...teachingCountFiles ] : [data], ...detail }
        }else if(certType === 'mfd') {
          const { mfdfiles, ...detail } = state
          return { mfdfiles: mfdfiles ? [ data, ...mfdfiles ] : [data], ...detail }
        }else if(certType === 'prepare') {
          const { fdipreFiles, ...detail } = state
          return { fdipreFiles: fdipreFiles ? [ data, ...fdipreFiles ] : [data], ...detail }
        }else if(certType === 'afdi') {
          const { afdifiles, ...detail } = state
          return { afdifiles: afdifiles ? [ data, ...afdifiles ] : [data], ...detail }
        }
      }
    },
    getMFDIOssFileFailed: {
      reducer(state){
        return state
      }
    },
    removeMFDIFile: {
      reducer(state, action) {
        const data = action.payload;
        const url = data.replace('https://files.padi.com.cn/', '')
        const certType = url.split('/')[2]
        if(certType === 'efri') {
          const { efrifiles, ...detail } = state
          const newEfrifiles = efrifiles.filter(item => item !== data)
          return { efrifiles: newEfrifiles, ...detail }
        }else if(certType === 'fdi') {
          const { fdifiles, ...detail } = state
          const newFdifiles = fdifiles.filter(item => item !== data)
          return { fdifiles: newFdifiles, ...detail }
        }else if(certType === 'teachingCerts') {
          const { teachingCountFiles, ...detail } = state
          const newTeachingFiles = teachingCountFiles.filter(item => item !== data)
          return { teachingCountFiles: newTeachingFiles, ...detail }
        }else if(certType === 'mfd') {
          const { mfdfiles, ...detail } = state
          const newMfdfiles = mfdfiles.filter(item => item !== data)
          return { mfdfiles: newMfdfiles, ...detail }
        }else if(certType === 'prepare') {
          const { fdipreFiles, ...detail } = state
          const newFdipreFiles = fdipreFiles.filter(item => item !== data)
          return { fdipreFiles: newFdipreFiles, ...detail }
        }else if(certType === 'afdi') {
          const { afdifiles, ...detail } = state
          const newAfdiFiles = afdifiles.filter(item => item !== data)
          return { afdifiles: newAfdiFiles, ...detail }
        }
      }
    }
  }
})

export const { 
  MFDIDetailRequest,
  MFDIDetailSuccess,
  MFDIDetailFailed,
  uploadMFDIOssFile,
  uploadMFDIOssFileRequest,
  uploadMFDIOssFileSuccess,
  uploadMFDIOssFileFailed,
  getMFDIOssFileRequest,
  getMFDIOssFileSuccess,
  getMFDIOssFileFailed,
  removeMFDIFile } = MFDIDetailSlice.actions

export const MFDIDetail = {
  request: (data) => MFDIDetailRequest(data),
  success: (data) => MFDIDetailSuccess(data),
  failure: (error) => MFDIDetailFailed(error),
}

export const uploadMFDIAttachFile = {
  request: () => uploadMFDIOssFileRequest(),
  success: (data) => uploadMFDIOssFileSuccess(data),
  failure: (error) => uploadMFDIOssFileFailed(error),
}

export const getMFDIOssFile = {
  request: () => getMFDIOssFileRequest(),
  success: (data) => getMFDIOssFileSuccess(data),
  failure: (error) => getMFDIOssFileFailed(error),
}

export const MFDITListSlice = createSlice({
  name: "MFDITList",
  initialState: {},
  reducers: {
    skipMFDITListPage: {
      reducer(state, action) {
        return state
      }
    },
    MFDITListRequest: {
      reducer(state){
        return state
      }
    },
    MFDITListSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    MFDITListFailed: {
      reducer(state){
        return state
      }
    }
  }
});

export const { MFDITListRequest, MFDITListSuccess, MFDITListFailed, skipMFDITListPage } = MFDITListSlice.actions

export const getMFDITListRequest = {
  request: () => MFDITListRequest(),
  success: (data) => MFDITListSuccess(data),
  failure: (error) => MFDITListFailed(error),
}

export const MFDITDetailSlice = createSlice({
  name: "MFDITDetail",
  initialState: {},
  reducers: {
    MFDITDetailRequest: {
      reducer(){
        return {}
      }
    },
    MFDITDetailSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    MFDITDetailFailed: {
      reducer(){
        return {}
      }
    }
  }
})

export const { 
  MFDITDetailRequest, 
  MFDITDetailSuccess, 
  MFDITDetailFailed
} = MFDITDetailSlice.actions

export const MFDITDetail = {
  request: () => MFDITDetailRequest(),
  success: (data) => MFDITDetailSuccess(data),
  failure: (error) => MFDITDetailFailed(error),
}

const mfdiReducer = combineReducers({
  MFDIDetail: MFDIDetailSlice.reducer,
  MFDITList: MFDITListSlice.reducer,
  MFDITDetail: MFDITDetailSlice.reducer,
})

export default mfdiReducer