import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl';
import { showModal, getSubmitOssFormat, removeExpires } from '../../components/common/utils'
import ApplicantDetailStatus from '../../components/common/ApplicantDetailStatus'
import { isMember } from '../../reducers/selectors'
import { usePayMfdi, useSubmitMfdi } from '../../services/hooks'
import { useHistory } from 'react-router-dom';

const ApplicationStatus = ({
  detail,
  formData,
  checkAge,
  checked,
  certComplete,
  addressComplete
}) => {

  const { formatMessage } = useIntl()
  const { runAsync: toPay } = usePayMfdi();
  const { push } = useHistory()
  const { runAsync: submitMfdi, loading: submitting } = useSubmitMfdi();
  const { signatureUrl, ismember } = useSelector((state) => ({
    signatureUrl: state.ui.signatureUrl,
    ismember: isMember(state)
  }));

  const verifyData = () => {
    const {
      languageId,
      regionId,
      teachingCount,
      teachingCountFiles,
      cdinstructorNumber,
      courseLocation,
      finishDate,
      afdi,
      owsi
    } = formData
    const regionStatus = ismember ? true : regionId
    const trainingInfoComplete = (afdi || owsi) ? true : Boolean(cdinstructorNumber && courseLocation && finishDate)
    const regionAndLangCmplete = Boolean(regionStatus && languageId)
    const teachingComplete = teachingCount || (teachingCountFiles && teachingCountFiles.length > 0)
    return trainingInfoComplete &&
      regionAndLangCmplete &&
      checkAge &&
      checked &&
      signatureUrl &&
      certComplete &&
      teachingComplete &&
      addressComplete
  }

  const submit = () => {
    if (verifyData()) {
      const { finishDate, cdinstructorNumber, code, regionId, ...data } = formData
      let submitData = {
        ...data,
        efrifiles: data.efrifiles ? removeExpires(data.efrifiles) : [],
        afdifiles: data.afdifiles ? removeExpires(data.afdifiles) : [],
        teachingCountFiles: data.teachingCountFiles ? removeExpires(data.teachingCountFiles) : [],
        mfdfiles: data.mfdfiles ? removeExpires(data.mfdfiles) : [],
        fdipreFiles: data.fdipreFiles ? removeExpires(data.fdipreFiles) : [],
        courseDate: finishDate,
        trainerInstructorNumber: cdinstructorNumber,
        handwrittenSignatureUrl: getSubmitOssFormat(signatureUrl),
      }
      if (!ismember) submitData.regionId = regionId
      if (code) submitData.code = code
      submitMfdi(submitData).then(res => {
        if (res.code) {
          push('/application_submit_success?type=MFDI', { code: res.code, paid: res.paid, paymentType: 'MEMBER_APPLICATION_MFDI' });
        }
      })
    } else {
      showModal(
        formatMessage({ id: 'dm-form-submit-error' }),
        formatMessage({ id: 'dm-form-submit-error-info' }),
        () => { },
        () => { },
        formatMessage({ id: 'common-comfirmBtn' }),
        formatMessage({ id: 'common-cancel' })
      )
    }
  }

  return <ApplicantDetailStatus
    detail={detail}
    idType={'trainer'}
    submitFn={submit}
    payFn={toPay}
    applicationType={'MFDI'}
    payApplicationType={'MEMBER_APPLICATION_MFDI'}
    loading={submitting}
  />
}

export default ApplicationStatus