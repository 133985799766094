import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { List, InputItem, TextareaItem } from 'antd-mobile';
import PickerComponent from '../../components/common/Picker'
import DatePickerComponent from '../../components/common/DatePicker'
import areaDataSc from '../../assets/pca-code-m2.json';
import { getCookie } from '../../components/common/cookie';
import { genderOptions } from '../../components/FormTools/utils'
const country = [{
  'label': '中国',
  'value': 'cn'
}]
const ProfileInfo = ({
  updateFormData,
  firstName,
  lastName,
  birthDate,
  gender,
  pending,
  address,
  stateId
}) => {
  const { formatMessage } = useIntl()
  const lang = getCookie('language');
  const getDate = () => {
    const date = new Date()
    return new Date(date.getFullYear() - 100 + '/' + (date.getMonth() + 1) + '/' + date.getDate())
  }
  return (
    <List>
      <InputItem
        className="name"
        type="text"
        name="name"
        disabled={pending}
        placeholder={formatMessage({ id: "setProfile-defaultName" })}
        onChange={name => updateFormData({ firstName: name })}
        value={firstName}>
        <FormattedMessage id="setProfile-firstName" />
      </InputItem>
      <InputItem
        className="name"
        type="text"
        name="name"
        disabled={pending}
        placeholder={formatMessage({ id: "setProfile-defaultName" })}
        onChange={name => updateFormData({ lastName: name })}
        value={lastName} >
        <FormattedMessage id="setProfile-lastName" />
      </InputItem>
      <PickerComponent
        disabled={pending}
        extra={formatMessage({ id: 'common-select' })}
        data={genderOptions[lang]}
        onOk={gender => updateFormData({ gender })}
        value={gender}>
        <FormattedMessage id="setProfile-gender" />
      </PickerComponent>
      <DatePickerComponent
        extra={formatMessage({ id: 'common-select' })}
        title={formatMessage({ id: 'setProfile-chooseDate' })}
        disabled={pending}
        minDate={getDate()}
        onChange={birthDate => updateFormData({ birthDate })}
        value={birthDate}>
        <FormattedMessage id="setProfile-birthDate" />
      </DatePickerComponent>
      <PickerComponent
        disabled={pending}
        extra={formatMessage({ id: 'common-select' })}
        data={country}
        value={"cn"}>
        国家
      </PickerComponent>
      <PickerComponent
        disabled={pending}
        extra={formatMessage({ id: 'common-select' })}
        onOk={region => updateFormData({ stateId: region })}
        data={areaDataSc}
        value={stateId}>
        地区
      </PickerComponent>
      <TextareaItem
        title="详细地址"
        placeholder="请输入详细地址"
        autoHeight
        value={address}
        onChange={val => updateFormData({ address: val })}
      />
    </List>
  )
}
export default ProfileInfo