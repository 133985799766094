import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './CertDetailList.module.css'

function getInstructorFullName(cert) {
  return cert.instructorFirstName ? `${cert.instructorFirstName} ${cert.instructorLastName}` : '';
}

const InstructorInformation = ({ cert }) => {
  //Hide trainer 291181 for EFR Public Instructor
  if(cert.credentialNumber && (cert.credentialNumber === 12815 || cert.credentialNumber === 12689) && cert.instructorNumber === 291181) return null;
  
  return (
  <div className={styles.instructor}>
    <h3 className={styles.title}><FormattedMessage id="certdetail-instructorInfo" /></h3>
    <div className={styles.instructInfo}>
      <div className={styles.left}>
        <span className={styles.itemTitle}><FormattedMessage id="certdetail-name" /></span>
        <span className={styles.itemContent}>{getInstructorFullName(cert)}</span>
      </div>
      <div className={styles.right}>
        <span className={styles.itemTitle}><FormattedMessage id="certdetail-instructorNum" /></span>
        <span className={styles.itemContent}>{cert.instructorNumber}</span>
      </div>
    </div>
  </div>
);}

export { InstructorInformation }
