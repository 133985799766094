import { FormattedMessage, useIntl } from 'react-intl';
import React, { useContext } from 'react';
import PickerComponent from '../../components/common/Picker'

import { Context } from '../../components/IntlWrapper';

const languageOptions = [
  {
    label: '简体中文',
    value: 'sc',
  },
  {
    label: '繁體中文',
    value: 'tc',
  },
  {
    label: 'English',
    value: 'en',
  },
];

export default () => {
  const intlContext = useContext(Context);
  const { formatMessage } = useIntl();

  return <PickerComponent 
    data={languageOptions} 
    onOk={intlContext.toggleLanguage}
    smallSize={true}
    value={intlContext.language}
    extra={formatMessage({ id: 'profile-setLanguage' })}>
      <i className="fa fa-cog fa-lg fa-fw"></i>
      <span style={{ fontWeight: 'bold' }}>
        <FormattedMessage id="profile-language" />
      </span>
  </PickerComponent>
};
