import React from 'react'
import { WhiteSpace } from 'antd-mobile'
import { Radio } from 'antd';
import { FormattedMessage } from 'react-intl';
import CardLayout from '../DiveMasterForm/CardLayout'

import styles from '../OwsiApplication/OwsiApplication.module.css'

const IeTimes = ({ ieAttempt, updateFormData, resitList }) => {

  const onChange = e => {
    const ieAttempt = e.target.value
    updateFormData({ ieAttempt: ieAttempt, ieExamModules: [] });
  };

  const resit = (resitKey) => {
    if (findResit(resitKey)) {
      const newResitList = resitList.filter(item => item !== resitKey)
      updateFormData({ ieExamModules: newResitList })
    } else {
      updateFormData({ ieExamModules: [...resitList, resitKey] })
    }
  }

  const findResit = (resitKey) => {
    return !!resitList.find(item => item === resitKey)
  }

  const isComplete = ieAttempt && resitList.length > 0

  return (
    <CardLayout title={<FormattedMessage id="profile-ie" />} status={isComplete}>
      <div className={styles['ie']}>
        <div className={styles['ie-title']}>
          <FormattedMessage id="owsi-ie-times" />
        </div>
        <WhiteSpace />
        <Radio.Group
          onChange={onChange}
          value={ieAttempt === 1 ? 2 : ieAttempt}
          disabled={false}>
          <div>
            <Radio value={2}><FormattedMessage id="owsi-ie-second" /></Radio>
            <Radio value={3}><FormattedMessage id="owsi-ie-third" /></Radio>
          </div>
          <WhiteSpace />
          <div>
            <Radio value={4}><FormattedMessage id="owsi-ie-fourth" /></Radio>
          </div>
        </Radio.Group>
        <WhiteSpace />
        <div>
          <div className={styles['ie-title']}>
            <FormattedMessage id="owsi-ie-resit" />
          </div>
          <WhiteSpace />
          <div>
            <Radio
              checked={findResit(1)}
              disabled={false}
              onClick={() => resit(1)}><FormattedMessage id="owsi-ie-written-exam" /></Radio>
            <Radio
              checked={findResit(2)}
              disabled={false}
              onClick={() => resit(2)}><FormattedMessage id="owsi-ie-knowledge" /></Radio>
          </div>
          <WhiteSpace />
          <div>
            <Radio
              checked={findResit(3)}
              disabled={false}
              onClick={() => resit(3)}><FormattedMessage id="owsi-ie-confined-water" /></Radio>
            <Radio
              checked={findResit(4)}
              disabled={false}
              onClick={() => resit(4)}><FormattedMessage id="owsi-ie-open-water" /></Radio>
          </div>
        </div>
      </div>
    </CardLayout>
  )
}

export default IeTimes