import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl';
import { List, InputItem } from 'antd-mobile';
import { showAlert } from '../../components/common/utils'
const isRightPassword = (value) => {
  return /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{8,20}/.test(value);
}
const SetPassword = ({ updateFormData, password, confirmPassword, pending, errors }) => {
  const { formatMessage } = useIntl()
  const [secret, setSecret] = useState(true)
  const setStateAndShowAlert = (isNotPassword, isNotSamePassword, message, btnLabel) => {
    updateFormData({ errors: { password: isNotPassword, confirmPassword: isNotSamePassword } });
    showAlert(message, btnLabel);
  }
  const verifyIsRightPwd = () => {
    const pw = password.trim();
    if (!isRightPassword(pw)) {
      setStateAndShowAlert(!isRightPassword(pw), !isRightPassword(pw), formatMessage({ id: 'common-reset' }), formatMessage({ id: 'common-close' }));
      return;
    } else {
      const { password, confirmPassword, ...otherErrors } = errors
      updateFormData({ errors: { ...otherErrors } })
    }
  }
  const verifyPwd = () => {
    if (password !== confirmPassword) {
      setStateAndShowAlert(true, true, '请确保两次输入的密码一致', formatMessage({ id: 'common-close' }));
      return
    } else {
      const { password, confirmPassword, ...otherErrors } = errors
      updateFormData({ errors: { ...otherErrors } })
    }
  }
  return (
    <List>
      <InputItem
        type={secret ? 'password' : 'text'}
        name="password"
        disabled={pending}
        error={errors.password}
        onBlur={verifyIsRightPwd}
        onChange={password => updateFormData({ password })}
        value={password}
        maxLength="20"
        placeholder={formatMessage({ id: 'common-passwordInput' })}>
        设置密码
      </InputItem>
      <InputItem
        type={secret ? 'password' : 'text'}
        name="confirmPassword"
        disabled={pending}
        error={errors.confirmPassword}
        onBlur={verifyPwd}
        onChange={confirmPassword => updateFormData({ confirmPassword })}
        value={confirmPassword}
        placeholder={formatMessage({ id: 'common-confirmPassword' })}
        maxLength="20"
        extra={
          <i className={`fa ${secret ? 'fa-eye-slash' : 'fa-eye'} fa-lg`}
            onClick={() => setSecret(!secret)}>
          </i>
        }>
        确认密码
      </InputItem>
    </List>
  )
}
export default SetPassword