import React, { useState, useEffect } from 'react'
import { Modal, Form, Checkbox, Button } from 'antd-mobile-v5'
import { ExclamationCircleFill } from 'antd-mobile-icons'
import { FormattedMessage, useIntl } from 'react-intl';
import { useLanguage } from '../../services/hooks/user';
import { useHistory } from 'react-router-dom';
import { useSubmitUserAgreement, useMainlandIp } from '../../services/hooks'

import styles from '../../pages/Register/Register.module.css';

const AgreementPopup = ({ visible, setVisible, agreements }) => {

  const { push } = useHistory()
  const lang = useLanguage();
  const { formatMessage } = useIntl()
  const [ agreement1, setAgreement1 ] = useState(false)
  const [ agreement2, setAgreement2 ] = useState(false)
  const [ globalAgreement, setGlobalAgreement] = useState(false)
  const [form] = Form.useForm();
  const { run, loading } = useSubmitUserAgreement({
    onSuccess: (data) => {
      setVisible(false)
    }
  });
  const { data: isMainlandIp } = useMainlandIp({});
  const showUserAgreement = () => {
    const userAgreementItem = agreements.find(agree => agree.type === 'USER_AGREEMENT')
    return userAgreementItem ? !userAgreementItem.agree : false
  }
  const showCrossTransfer = () => {
    const crossTransferItem = agreements.find(agree => agree.type === 'CROSS_BORDER_DATA_TRANSFER')
    return crossTransferItem ? !crossTransferItem.agree : false
  }
  const showGlobalAgreement = () => {
    const globalAgreementItem = agreements.find(agree => agree.type === 'GLOBAL_AGREEMENT')
    return globalAgreementItem ? !globalAgreementItem.agree : false
  }

  const onFinish = (data) => {
    if (!data.agreement) {
      form.setFields([
        {
          name: "agreement",
          errors: [formatMessage({ id: 'customize-cert-agree-first' })],
        },
      ]);
      return
    } 
    const agreementsData = agreements.filter(agree => !agree.agree).map(item => {
      return {
        ...item,
        agree: true
      }
    })
    run(agreementsData)
  }

  useEffect(() => {
    // let agreement
    // if(isMainlandIp) {
    //   if (!userAgreement() && !crossTransfer()) agreement = agreement1 && agreement2
    //   else agreement = agreement1 || agreement2
    // }else {
    //   agreement = agreement1
    // }
    const agreement = (agreement1 && agreement2) || globalAgreement
    form.setFieldsValue({ agreement });
  }, [agreement1, agreement2, globalAgreement])

  return <Modal
    visible={visible}
    header={<ExclamationCircleFill
      style={{
        fontSize: 64,
        color: 'var(--adm-color-warning)',
      }}
    />}
    title={formatMessage({ id: "common-agreement-update" })}
    content={
      <Form
        layout="horizontal"
        form={form}
        onFinish={onFinish}
        validateTrigger="submit"
        footer={
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            <Button
              block
              type="submit"
              color="primary"
              loading={loading}
            >
              <FormattedMessage id="common-agree" />
            </Button>
            <Button 
              color='primary' 
              fill='none'
              onClick={() => push('/emailLogin')}
              disabled={loading}>
              <FormattedMessage id="common-not-agree" />
            </Button>
          </div>
        }
      >
        <Form.Item
          name="agreement"
          form={form}
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'customize-cert-agree-first' }),
            },
          ]}
        >
          {showUserAgreement() && <Checkbox className={styles.agreement} checked={agreement1} onChange={val => setAgreement1(val)}>
            <FormattedMessage
              id="common-agreement-hint"
              values={{
                link: (
                  <a 
                    rel="noopener noreferrer" 
                    target="_blank" 
                    href={`https://assets.padi.com.cn/agreement_${lang}.html`}
                  >
                    <FormattedMessage id="common-agreement" />
                  </a>
                )
              }}
            />
          </Checkbox>}
          {showGlobalAgreement() && <Checkbox className={styles.agreement} checked={globalAgreement} onChange={val => setGlobalAgreement(val)}>
            <FormattedMessage
              id="common-agreement-hint"
              values={{
                link: (
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={'https://www.padi.com/privacy'}
                  >
                    <FormattedMessage id="common-agreement" />
                  </a>
                )
              }}
            />
          </Checkbox>}
          {showCrossTransfer() && <Checkbox className={styles.agreement} checked={agreement2} onChange={val => setAgreement2(val)}>
            <FormattedMessage
              id="common-info-agreement-hint"
              values={{
                link: (
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`https://assets.padi.com.cn/personalInfo_agreement_${lang}.html`}
                  >
                    <FormattedMessage id="common-info-agreement" />
                  </a>
                ),
              }}
            />
          </Checkbox>}
        </Form.Item>
      </Form>
    }
    closeOnAction
    onClose={() => {
      setVisible(false)
    }}
  />
}

export default AgreementPopup