import React from 'react';
import { useSelector } from 'react-redux';
import { skipCdApplicationsPage } from '../../reducers/owsi'
import AppList from '../../components/common/AppList'

const OwsiCdAppsPage = () => {
  const { confirmRes } = useSelector((state) => ({
    confirmRes: state.owsi.cdList,
  }));

  return <AppList
    confirmRes={confirmRes}
    detailUrl="/owsi_cd_app_detail"
    paging={skipCdApplicationsPage}
    listHeader="profile-owsi-cd"
  />
};

export default OwsiCdAppsPage;
