import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { List } from 'antd-mobile';
import CardLayout from '../DiveMasterForm/CardLayout'
import CertsConditionsItem from './CertsConditionsItem'
import { FormattedMessage } from 'react-intl';
import { isEfrExpired } from '../../components/common/findCerts'
import { findEfrOrEfri } from '../../components/common/utils'

const CheckCertsConditions = ({
  aidmfiles,
  aowfiles,
  efrfiles,
  owfiles,
  otherFiles,
  rescuefiles,
  updateCertStatus,
  certStatus,
  editable
}) => {

  const { credentials, certifications, owsiDetail } = useSelector(state => ({
    certifications: state.data.certifications,
    credentials: state.data.credentials,
    owsiDetail: state.owsi.applicantDetail,
  }));

  useEffect(() => {
    if (owsiDetail) {
      const { aidmfiles, aowfiles, efrfiles, owfiles, rescuefiles, otherFiles } = owsiDetail
      updateCertStatus({
        ow: Boolean(findCert('OW') || owfiles),
        aow: Boolean(findCert('AOW') || aowfiles),
        efr: Boolean(findEfrOrEfri(certifications, credentials) || efrfiles),
        rescue: Boolean(findCert('RESCUE') || rescuefiles),
        aidm: Boolean(findCert('AI/DM') || aidmfiles),
        other: Boolean(otherFiles && otherFiles.length > 0)
      })
    }
  }, [owsiDetail])

  const findCert = (type) => {
    switch (type) {
      case 'OW':
        return certifications.find(item => item.certificationNumber === 75)
      case 'AOW':
        return certifications.find(item => item.certificationNumber === 26)
      case 'RESCUE':
        return certifications.find(item => item.certificationNumber === 72)
      case 'EFR':
        return certifications.find(item => {
          return !item.expired && (item.certificationNumber === 76 ||
            item.certificationNumber === 68 ||
            item.certificationNumber === 31 ||
            item.certificationNumber === 20)
        })
      case 'AI/DM':
        return credentials.find(item => item.credentialNumber === 25 || item.credentialNumber === 23 || item.credentialNumber === 6092)
    }
  }

  return <CardLayout title={<FormattedMessage id="owsi-cert-conditions" />} status={certStatus}>
    <List>
      <CertsConditionsItem
        type={'OW'}
        cert={findCert('OW')}
        attachFileList={owfiles}
        editable={editable} />
      <CertsConditionsItem
        type={'AOW'}
        cert={findCert("AOW")}
        attachFileList={aowfiles}
        editable={editable} />
      <CertsConditionsItem
        type={'EFR'}
        cert={findCert("EFR")}
        expired={isEfrExpired(certifications)}
        attachFileList={efrfiles}
        editable={editable} />
      <CertsConditionsItem
        type={'RESCUE'}
        cert={findCert("RESCUE")}
        attachFileList={rescuefiles}
        editable={editable} />
      <CertsConditionsItem
        type={'AI/DM'}
        cert={findCert("AI/DM")}
        attachFileList={aidmfiles}
        editable={editable} />
      <CertsConditionsItem
        type={'other'}
        attachFileList={otherFiles}
        editable={editable} />
    </List>
  </CardLayout>
}

export default CheckCertsConditions