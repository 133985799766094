import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'antd-mobile-v5'
import { CheckCircleFill } from 'antd-mobile-icons'
import { WhiteSpace, WingBlank } from 'antd-mobile';
import styles from './FindAccount.module.css'
import { useIntl } from 'react-intl'; 

const FindSuccess = ({ email, setVisible }) => {

  const { push } = useHistory()
  const { formatMessage } = useIntl()

  return <WingBlank size="lg">
    <WhiteSpace size="lg" />
    <WhiteSpace size="lg" />
    <WhiteSpace size="lg" />
    <div className={styles['status-icon']}>
      <CheckCircleFill fontSize={58} color='var(--adm-color-primary)' />
    </div>
    <WhiteSpace size="lg" />
    <WhiteSpace size="lg" />
    <WhiteSpace size="lg" />
    <div>{formatMessage({ id: "findme-find-email-tips" })}</div>
    <WhiteSpace size="lg" />
    <strong>{email}</strong>
    <WhiteSpace size="lg" />
    <div>{formatMessage({ id: "findme-has-email-account" })}</div>
    <WhiteSpace size="lg" />
    <Button 
      color='primary' 
      fill='solid' 
      block
      onClick={() => push('/emailLogin')}
    >
      {formatMessage({ id: 'findme-login' })}
    </Button>
    <WhiteSpace size="lg" />
    <div>{formatMessage({ id: 'findme-success-contact-service' })}</div>
    <WhiteSpace size="lg" />
    <Button 
      color='primary' 
      fill='none' 
      block
      onClick={() => setVisible(true)}
    >
      {formatMessage({ id: 'findme-contact-service' })}
    </Button>
    <Button 
      color='primary' 
      fill='none' 
      block
      onClick={() => push('/find_account/user_info')}
    >
      {formatMessage({ id: 'findme-fill-in-again' })}
    </Button>
  </WingBlank>
}

export default FindSuccess