import { useIntl } from 'react-intl';
import React, { useState } from 'react';

import { getCookie } from '../../components/common/cookie';
import { regionOptions } from '../../components/FormTools/utils';
import PickerTool from './Picker';
import { useGetStandardRegions } from '../../services/hooks'

export default ({
  form,
  countryIdFormKey = 'countryId',
  stateIdFormKey = 'stateId',
  required,
  disabled,
}) => {
  const { formatMessage } = useIntl();
  const [countryId, setCountryId] = useState(
    form.getFieldValue(countryIdFormKey)
  );
  const [regionData, setRegionData] = useState([])
  const language = getCookie('language');

  const onCountryIdChange = (value) => {
    if (value !== '51') {
      form.setFieldsValue({ [stateIdFormKey]: '-1' });
    }
    setCountryId(value);
  };

  const { loading } = useGetStandardRegions({
    onSuccess: (data) => {
      const formatData = data.map(item => {
        return {
          label: item.region,
          value: item.id
        }
      })
      setRegionData(formatData)
    },
  });

  return (
    <>
      <PickerTool
        form={form}
        name={countryIdFormKey}
        disabled={disabled}
        placeholder={formatMessage({ id: 'dm-select-region-error' })}
        rules={
          required && [
            {
              required: true,
              message: formatMessage({ id: 'form-region-empty-error' }),
            },
          ]
        }
        onOk={onCountryIdChange}
        data={regionData}
        label={formatMessage({ id: 'setMobile-regions' })}
      />
      {countryId === '51' && (
        <PickerTool
          form={form}
          name={stateIdFormKey}
          disabled={disabled}
          placeholder={formatMessage({ id: 'form-stateid-hint' })}
          data={regionOptions[language]}
          rules={
            required && [
              {
                required: true,
                message: formatMessage({ id: 'form-stateid-empty-error' }),
              },
            ]
          }
          label={formatMessage({ id: 'common-Province' })}
        />
      )}
    </>
  );
};
