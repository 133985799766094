import React from 'react'
import { WhiteSpace, WingBlank } from 'antd-mobile';
import { Button, Form, Input } from 'antd-mobile-v5'
import Header from '../../components/common/FormHeader'
import DatePickerTool from '../../components/FormTools/DatePicker';
import { useIntl } from 'react-intl'; 
import { useForm } from 'antd/lib/form/Form';
import { useFindMeSearch } from '../../services/hooks'
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom'
import { formatDate } from '../../components/common/utils';
import { NAME_PATTERN } from '../../utils/user';

const PersonalInfo = () => {
  const { formatMessage } = useIntl(); 
  const [form] = useForm(); 
  const { push } = useHistory()
  const { loading, run } = useFindMeSearch({
    onSuccess: (data, params) => {
      if (data.email) push('/find_account/result?status=true', { email: data.email, ...params[0] })
      else push('/find_account/result?status=false', { email: data.email, ...params[0] })
    },
    onError: (error) => {
      const { clientMsg } = error;
      if (clientMsg) {
        form.setFields([
          {
            name: 'lastName',
            errors: [clientMsg],
          },
        ]);
        return true;
      }
      return false;
    }
  });

  const submit = (values) => {
    if (!values.studentNumber) {
      values.birthDate = formatDate(values.birthDate, 'YYYY-MM-DD')
      const { studentNumber, ...value } = values
      run(value)
      return
    }
    run(values)
  }

  return <>
    <Header
      text={formatMessage({ id: "findme-header" })}
      noIcon={true}
    />
    <WhiteSpace size="lg" />
    <WhiteSpace size="lg" />
    <WhiteSpace size="lg" />
    <WingBlank size="lg">
      <div>{formatMessage({ id: "findme-user-info-desc" })}</div>
      <WhiteSpace size="lg" />
      <div>{formatMessage({ id: "findme-desc" })}</div>
      <WhiteSpace size="lg" />
      <WhiteSpace size="lg" />
      <Form 
        layout='vertical' 
        validateTrigger="onSubmit"
        onFinish={(values) => submit(values)}
        footer={
          <Button
            color='primary'
            fill='solid'
            block
            type='submit'
            loading={loading}
          >
            {formatMessage({ id: 'common-comfirmBtn' })}
          </Button>
        }
      >
        <Form.Item 
          label={formatMessage({ id: "setProfile-lastName" })} 
          name='lastName'
          form={form}
          rules={[
            { required: true, message: formatMessage({ id: 'form-firstname-error' }) },
            {
              type: 'lastName',
              message: formatMessage({ id: 'form-firstname-error' }),
            },
            {
              pattern: NAME_PATTERN,
              message: formatMessage({ id: 'form-name-format-error' }),
            }
          ]}
        >
          <Input placeholder={formatMessage({ id: "setProfile-defaultName" })} clearable />
        </Form.Item>
        <Form.Item 
          label={formatMessage({ id: "setProfile-firstName" })} 
          name='firstName'
          form={form}
          rules={[
            { required: true, message: formatMessage({ id: 'form-lastname-error' }) },
            {
              pattern: NAME_PATTERN,
              message: formatMessage({ id: 'form-name-format-error' }),
            }
          ]}
        >
          <Input placeholder={formatMessage({ id: "setProfile-defaultName" })} clearable />
        </Form.Item>
        <DatePickerTool
          name="birthDate"
          placeholder={formatMessage({ id: 'form-birthdate-hint' })}
          label={formatMessage({ id: 'dm-form-birth' })}
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'form-birthdate-empty-error' }),
            },
          ]}
          min={dayjs().subtract(70, 'year').toDate()}
          max={dayjs().subtract(6, 'year').toDate()}
        />
        <Form.Item
          label={formatMessage({ id: "dm-form-student-number" })}
          name='studentNumber'
        >
          <Input placeholder={formatMessage({ id: "findme-email-option" })} clearable />
        </Form.Item>
      </Form>
      <WhiteSpace size="lg" />
    </WingBlank>
  </>
}

export default PersonalInfo