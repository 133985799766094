import React from 'react'
import { Loading } from 'antd-mobile-v5'
import { FormattedMessage } from 'react-intl';
import styles from './tools.module.css'

const LoadingStatus = () => {
  return <div className={styles.loading}>
    <Loading color='primary' />
    <span>
      <FormattedMessage id="common-loading" />
    </span>
  </div>
}

export default LoadingStatus