import { useState, useEffect } from 'react'
import { Toast } from 'antd-mobile';

const PageLoading = ({ pageLoading }) => {
  const [isVisible, setVisible] = useState(false)
  useEffect(() => {
    if (pageLoading) {
      if(!isVisible) {
        Toast.loading('', 0)
        setVisible(true)
      }
    } else {
      if(isVisible) {
        Toast.hide()
        setVisible(false)
      }
    }
  }, [pageLoading, isVisible])
  return null
}

export default PageLoading
