import React, { useState, useRef } from 'react';
import { Form, Picker } from 'antd-mobile-v5';
import { IsNotPc } from '../common/utils';
import styles from './tools.module.css';

const PickerTool = (props) => {
  const selectRef = useRef();
  const {
    form,
    name,
    placeholder,
    data,
    onOk=()=>{},
    label,
  } = props;
  const [selected, setSelected] = useState(form.getFieldValue(name));

  return IsNotPc() ? (<Form.Item name={name} label={label} {...props} onClick={async (e) => {
    const value = await Picker.prompt({
      columns: [data],
    })
    if(value) {
      const selectedValue = '' + value[0];
      setSelected(selectedValue);
      form.setFieldsValue({[name]: selectedValue});
      onOk(selectedValue);
    }
  }}>
      <select disabled ref={selectRef} className={selected === "" ? styles.picker : styles['picker-selected']} onClick={e => e.preventDefault()}>
      <option value="">
          {placeholder}
        </option>
        {data.map((item) => (<option key={item.value} value={'' + item.value}>
             {item.label}
           </option>
       ))}    
  </select>
  

  </Form.Item>

    ) : (<Form.Item {...props}>
      <select onChange={e => {const value=e.target.value; setSelected(value);onOk(value)}}
          className={selected === "" ? styles.picker : styles['picker-selected']}
          >
        <option value="">
          {placeholder}
        </option>
        {data.map((item) => (
          <option key={item.value} value={'' + item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </Form.Item>
  
  );
};

export default PickerTool;
