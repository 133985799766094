import React from 'react'
import { List, Button, ErrorBlock } from 'antd-mobile-v5'
import { useHistory } from 'react-router-dom';

const ListHeader = ({ disabled }) => {

  const { push } = useHistory()

  return <div style={{
    display: 'flex',
    justifyContent: 'space-between' }}>
    <span>發票資訊</span>
    {!disabled && <Button 
      color='primary' 
      fill='none' 
      size='small'
      onClick={() => push(`/invoice_form`)}>
      編輯
    </Button>}
  </div>
}

const InvoiceInfo = ({ invoiceInfo, disabled }) => {
  const { push } = useHistory()
  if (!invoiceInfo) return null
  if (!invoiceInfo.email) return <List header={<ListHeader disabled={disabled} />}>
    <ErrorBlock
      status='empty'
      title={<>
        <div>暫無發票訊息</div>
        <Button color='primary' fill='none' size='small' onClick={() => push('/invoice_form')}>
          新增
        </Button>
      </>}
      description=""
    />
  </List>
  if (!invoiceInfo.type2) {
    return <List header={<ListHeader disabled={disabled} />}>
      <List.Item extra={invoiceInfo.type1}>發票形式</List.Item>
      <List.Item extra={invoiceInfo.invoiceHeader}>抬頭</List.Item>
      <List.Item extra={invoiceInfo.invoiceUnifiedNumbering}>統一編號</List.Item>
      <List.Item extra={invoiceInfo.invoiceEmail}>電子信箱</List.Item>
    </List>
  }
  switch (invoiceInfo.type2) {
    case '會員載具': 
      return <List header={<ListHeader disabled={disabled} />}>
        <List.Item extra={invoiceInfo.type1}>發票形式</List.Item>
        <List.Item extra={invoiceInfo.type2}>發票類型</List.Item>
        <List.Item extra={invoiceInfo.invoiceEmail}>電子信箱</List.Item>
      </List>
    case '手機載具':
      return <List header={<ListHeader disabled={disabled} />}>
        <List.Item extra={invoiceInfo.type1}>發票形式</List.Item>
        <List.Item extra={invoiceInfo.type2}>發票類型</List.Item>
        <List.Item extra={invoiceInfo.invoiceMobile}>手機具載</List.Item>
        <List.Item extra={invoiceInfo.invoiceEmail}>電子信箱</List.Item>
      </List>
    case '自然人憑證': 
      return <List header={<ListHeader disabled={disabled} />}>
        <List.Item extra={invoiceInfo.type1}>發票形式</List.Item>
        <List.Item extra={invoiceInfo.type2}>發票類型</List.Item>
        <List.Item extra={invoiceInfo.invoiceCitizenDigitalCertificate}>自然人憑證</List.Item>
        <List.Item extra={invoiceInfo.invoiceEmail}>電子信箱</List.Item>
      </List>
    case '發票捐贈': 
      return <List header={<ListHeader disabled={disabled} />}>
        <List.Item extra={invoiceInfo.type1}>發票形式</List.Item>
        <List.Item extra={invoiceInfo.type2}>發票類型</List.Item>
        <List.Item extra={invoiceInfo.invoiceDonationUnitCode}>捐贈單位代號</List.Item>
        <List.Item extra={invoiceInfo.invoiceEmail}>電子信箱</List.Item>
      </List>
  }
}

export default InvoiceInfo