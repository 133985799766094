import React from 'react';
import imageFiles from '../../components/certImages.json';
import { getImageIdFromCardStockId } from '../../services/utilities';
import { getEncodedId } from '../../components/common/utils';

import styles from './Campaign.module.css';

const defaultImage = '-1';
const getImageFile = (id) => {
  if ('rd' === id) return id;
  const match = imageFiles.filter((i) => i.id === '' + id);
  return match.length > 0 ? match[0].file || defaultImage : defaultImage;
};

const Cert = ({ ecard, check, checked }) => {
  let imageId = getImageIdFromCardStockId(ecard);
  return (
    <div className={styles["certs-items"]} onClick={() => check(getEncodedId(ecard))}>
      <div className={styles["cert-item-wrapper"]}>
        {checked && (
          <div className={styles["layer"]}>
            <i className="fa fa-check fa-2x"></i>
          </div>
        )}
        <img
          src={require(`../../assets/images/certs/cert_${getImageFile(
            imageId
          )}.jpg`)}
          alt="cert"
        />
      </div>
      <h6>{ecard.ecardName}</h6>
    </div>
  );
};

export default Cert;
