import React from "react";
import { useSelector } from 'react-redux';
import {
  InputItem,
  WhiteSpace
} from 'antd-mobile';
import { FormattedMessage, useIntl } from 'react-intl';
import CardLayout from '../DiveMasterForm/CardLayout'
import Signature from '../../components/common/Signature'
import SearchStore from '../../components/common/SearchStore';
import SearchInstructor from '../../components/common/SearchInstructor';
import UploadOssFile from '../../components/common/UploadOssFile'
import UploadFileWrapper from '../../components/common/UploadFileWrapper'
import UserPhoto from '../../components/common/UserPhoto'
import DetailStatus from '../../components/common/DetailStatus'
import {
  formatDate,
  filterStateId,
  filterTeachingLanguage
} from "../../components/common/utils";
import DatePickerComponent from '../../components/common/DatePicker'
import EFRConfirm from '../../components/common/EFRConfirm'
import { mermaidTApproved } from '../../services/api';
import { usePayMermaid } from '../../services/hooks'

import styles from '../DiveMasterForm/DiveMasterForm.module.css'
import { getCookie } from "../../components/common/cookie";

const MermaidTDetail = () => {

  const { formatMessage } = useIntl()
  const { runAsync: toPay } = usePayMermaid();
  const { detail } = useSelector(state => ({
    detail: state.mermaid.mermaidTDetail,
  }));
  const lang = getCookie('language');
  const editable = !!(detail.status === 'WAIT_CONFIRM')

  if (!Object.keys(detail).length > 0) return null
  return <>
    <CardLayout title={<FormattedMessage id="dm-form-diver-info" />} status={"none"}>
      <div className={styles["diver-info"]}>
        <div>
          <InputItem
            type="text"
            editable={false}
            value={detail.userInfoDTO.englishName}
          >
            <FormattedMessage id="dm-form-name" />
          </InputItem>
          <InputItem
            type="text"
            editable={false}
            value={formatDate(detail.userInfoDTO.birthDate, 'YYYY-MM-DD')}
          >
            <FormattedMessage id="dm-form-birth" />
          </InputItem>
        </div>
        <UserPhoto id={detail.userInfoDTO.affiliateId} />
      </div>
    </CardLayout>
    <WhiteSpace />
    <CardLayout title={<FormattedMessage id="dm-form-required-certs" />} status={"none"}>
      <EFRConfirm appDetail={detail} />
      <UploadFileWrapper
        labelName={detail.padiadvancedMermaid ? `${formatMessage({ id: 'BMI-advanced-mermaid' })} (${detail.padiadvancedMermaid})` : formatMessage({ id: 'BMI-advanced-mermaid' })}
        editable={false}>
        <UploadOssFile
          fileList={detail.padiadvancedMermaidFiles || []}
          editable={false} />
      </UploadFileWrapper>
      <UploadFileWrapper
        labelName={formatMessage({ id: 'mermaid-other-files' })}
        editable={false}>
        <UploadOssFile
          fileList={detail.otherFiles || []}
          editable={false} />
      </UploadFileWrapper>
    </CardLayout>
    <WhiteSpace />
    <CardLayout title={<FormattedMessage id="dm-form-training-info" />} status={"none"}>
      {detail.instructorInfoDTO && <SearchInstructor
        id={"trainer"}
        editable={false}
        instructor={detail.instructorInfoDTO}
        instructorNumber={detail.instructorInfoDTO.instructorNumber}
      />}
      {detail.storeInfoDTO && <SearchStore
        editable={false}
        store={detail.storeInfoDTO}
        storeNumber={detail.storeInfoDTO.storeNumber}
      />}
      <InputItem
        type="text"
        editable={false}
        value={detail.courseLocation}
      >
        <FormattedMessage id="si-course-location-title" />
      </InputItem>
      <DatePickerComponent
        extra={formatMessage({ id: 'common-select' })}
        title={formatMessage({ id: 'efri-course-date-error' })}
        maxDate={new Date()}
        disabled={true}
        value={detail.courseDate}>
        <FormattedMessage id="common-course-date" />
      </DatePickerComponent>
    </CardLayout>
    <WhiteSpace />
    <CardLayout title={<FormattedMessage id="dm-region-and-lang-title" />} status={"none"}>
      {detail.regionDTO && <InputItem
        type="text"
        editable={false}
        value={detail.regionDTO.region}
      >
        <FormattedMessage id="dm-region" />
      </InputItem>}
      <InputItem
        type="text"
        editable={false}
        value={filterTeachingLanguage(detail.languageId)}
      >
        <FormattedMessage id="dm-lang-title" />
      </InputItem>
    </CardLayout>
    <WhiteSpace />
    <CardLayout title={formatMessage({ id: 'common-address-detail' })} status={"none"}>
      <InputItem
        type="text"
        editable={false}
        value={detail.addressRegionDTO.region}
      >
        <FormattedMessage id="setMobile-regions" />
      </InputItem>
      {detail.addressStateId > 0 && <InputItem
        type="text"
        editable={false}
        value={filterStateId(detail.addressStateId, lang)}
      >
        <FormattedMessage id="common-Province" />
      </InputItem>}
      <InputItem
        type="text"
        editable={false}
        value={detail.address}
      >
        <FormattedMessage id="common-address" />
      </InputItem>
    </CardLayout>
    <WhiteSpace />
    <Signature
      modified={editable}
      filePath={'signature'}
      initSignature={detail.handwrittenSignatureUrlTrainer} />
    <WhiteSpace size="lg" />
    <DetailStatus 
      detail={detail} 
      approveFn={mermaidTApproved} 
      payFn={toPay} 
      listUrl="/mermaid_app_list"
      payApplicationType="MEMBER_APPLICATION_PADI_MERMAID_I" />
    <WhiteSpace size="lg" />
  </>
}

export default MermaidTDetail