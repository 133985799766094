import React, { useState, useEffect } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { useRequest } from 'ahooks';

import {
  List,
  Icon,
  Button,
  WhiteSpace,
  WingBlank,
  Modal,
} from 'antd-mobile';
import CardLayout from '../../pages/DiveMasterForm/CardLayout';
import SearchStore from '../../components/common/SearchStore';
import {
  searchStudent,
} from '../../services/api';
import { showAlert, formatDate } from '../../components/common/utils';
import SearchInput from '../../components/common/SearchInput'
import DatePickerComponent from '../../components/common/DatePicker'
import Header from '../../components/common/FormHeader';
import SearchInstructor from '../../components/common/SearchInstructor';
import { useInstructorCreate } from '../../services/hooks'
import { Toast } from 'antd-mobile-v5'

const DMFormPage = (props) => {
  const { formatMessage } = props.intl;

  const { push } = useHistory();

  const [students, setStudents] = useState([]);
  const [completionDate, setCompletionDate] = useState('');
  const [storeNumber, setStoreNumber] = useState('');
  const [instructorNumber, setInstructorNumber] = useState('');
  const { state: { instructor, store } } = useLocation();
  const { runAsync: instructorCreate, loading: submitting } = useInstructorCreate();

  useEffect(() => {
    if (instructor) {
      setInstructorNumber(instructor.instructorNumber)
    } else if (store) {
      setStoreNumber(store.storeNumber)
    }
  }, [instructor, store])

  const searchStudentRequest = async (studentNoSearch) => {
    if (!studentNoSearch) {
      showAlert(
        formatMessage({ id: 'dm-form-studentno-error' }),
        formatMessage({ id: 'common-close' })
      );
      return;
    }
    try {
      const result = await searchStudent(studentNoSearch);
      const student = result.data;
      if (student && student.affiliateId) {
        if (students.find((s) => s.affiliateId === student.affiliateId)) {
          showAlert(
            formatMessage({ id: 'dm-form-student-added' }),
            formatMessage({ id: 'common-close' })
          );
          return;
        }
        setStudents([student, ...students]);
      } else if (result.error) {
        showAlert(
          result.error.clientMsg,
          formatMessage({ id: 'common-close' })
        );
        return;
      }
    } catch (e) {
      showAlert(
        formatMessage({ id: 'common-errorStatus' }),
        formatMessage({ id: 'common-close' })
      );
    }
  }

  const searchStudentSubmit = useRequest(searchStudentRequest, { manual: true });

  const submit = async () => {
    if (!completionDate || students.length <= 0 || !instructorNumber) {
      Modal.alert(
        formatMessage({ id: 'dm-form-submit-error' }),
        formatMessage({ id: 'dm-form-submit-error-info-no-agreement' }),
        [{ text: formatMessage({ id: 'common-close' }) }]
      );
      return
    }
    const submitData = {
      finishTime: formatDate(completionDate, 'YYYY-MM-DD'),
      instructorNumber: instructorNumber,
      students,
      // TODO: handwrittenSignatureId: signatureId
    };
    if (storeNumber) submitData.storeNumber = storeNumber
    instructorCreate(submitData).then(res => {
      if (res.details) {
        Toast.show({
          icon: 'success',
          content: formatMessage({ id: 'common-submit-success' }),
          afterClose: () => push('/myProfile')
        })
      }
    })
  };

  const removeStudent = (affiliateId) => () => {
    const list = students.filter((s) => s.affiliateId !== affiliateId);
    setStudents(list);
  };

  return (
    <>
      <Header text={<FormattedMessage id="dm-form-instructor-create" />} />
      <CardLayout
        title={<FormattedMessage id="dm-form-training-info" />}
        status={instructorNumber && completionDate}
      >
        <SearchInstructor
          type={"NONE"}
          id={"instructor"}
          editable={true}
          instructor={instructor}
          instructorNumber={instructorNumber}
          onSuccess={(result) => setInstructorNumber(result.instructorNumber)}
        />
        <SearchStore
          editable={true}
          store={store}
          placeholder={formatMessage({ id: 'dm-form-store-warning' })}
          onSuccess={(result) => {
            setStoreNumber(result.storeNumber)
          }}
        />
        <DatePickerComponent
          extra={formatMessage({ id: 'common-select' })}
          title={formatMessage({ id: 'dm-form-date-warning' })}
          maxDate={new Date()}
          onChange={(date) => setCompletionDate(date)}
          value={completionDate}>
          <FormattedMessage id="dm-form-complete-date" />
        </DatePickerComponent>
      </CardLayout>
      <WhiteSpace />
      <CardLayout
        title={formatMessage({ id: 'dm-cert-info' })}
        status={students.length}
      >
        <SearchInput
          type={"text"}
          editable={true}
          placeholder={formatMessage({ id: 'dm-form-search-applicant' })}
          text={<FormattedMessage id="dm-form-student-number" />}
          searchFn={(student) => searchStudentSubmit.run(student)}>
          <List>
            {students.map(({ affiliateId, fullName, studentNumber }) => (
              <List.Item
                thumb={<i className="fa fa-user"></i>}
                extra={<Icon type="cross" onClick={removeStudent(affiliateId)} />}
                multipleLine
                key={affiliateId}
              >
                {fullName} <List.Item.Brief>{studentNumber}</List.Item.Brief>
              </List.Item>
            ))}
            {searchStudentSubmit.loading && <div style={{ width: '100%', textAlign: 'center', padding: '10px 0' }}>
              <i className="fa fa-spinner fa-spin fa-2x fa-fw"></i>
            </div>}
          </List>
        </SearchInput>
      </CardLayout>
      <WhiteSpace size="xl" />
      <WingBlank>
        <Button
          type="primary"
          loading={submitting}
          disabled={submitting}
          onClick={() => submit()}
        >
          <FormattedMessage id="dm-form-submit" />
        </Button>
      </WingBlank>
    </>
  );
};

export default injectIntl(DMFormPage);
