/* global document, _hmt, dataLayer */
import { Modal } from 'antd-mobile';
import { getCookie } from './cookie';
import fetcher from './fetcher';
import thumbnail from '../../assets/images/share_thumbnail.png';
import dayjs from 'dayjs';
import areaDataSc from '../../assets/pca-code-m2.json'
import areaDataTc from '../../assets/pca-code-m2-tc.json'
import areaDataEn from '../../assets/pca-code-m2-en.json'

export const isiOS = () => new RegExp('\\biPhone\\b|\\biPod\\b', 'i').test(window.navigator.userAgent);

export const openAfter = (timeString) => {
  return dayjs().isAfter(dayjs(timeString));
}

export const isEmpty = (obj) => {
  for (var i in obj) {
    return false;
  }
  return true;
}

export const formatMoney = (amount, currency, withAbbr = false) => {
  const symbol = currency;
  return withAbbr ? currency + ' ' + Number(amount).toFixed(2)
    : symbol + ' ' + Number(amount).toFixed(2);

}

export const groupBy = (original = [], groupKey) => {
  const result = {}
  original.forEach(o => {
    if (result[o[groupKey]]) {
      result[o[groupKey]].push(o)
    } else {
      result[o[groupKey]] = [o]
    }
  })
  return result
}
export const getEncodedId = ecard =>
  ecard.affiliateCertificationId || ecard.affiliateCredentialId;

export const setPathVars = (url, vars) => {
  Object.keys(vars).forEach(key => (url = url.replace(`{${key}}`, vars[key])));
  return url;
};

export const showError = (message, onPress = () => { }) => {
  Modal.alert('错误', message, [{ text: '关闭', onPress }]);
};

export const getDivingHistory = certs => {
  return certs.length ? certs[certs.length - 1].issueDate.split('-')[2] : '';
};

export const wechatShareConfig = ({
  sharedId,
  shareType,
  getShareMsg,
  getShareLink,
  success,
  cacel,
}) => async () => {
  let id;
  if (sharedId) {
    id = sharedId;
  } else {
    const result = await fetcher('shareId', { method: 'POST' });
    id = result.weChatShareId;
  }
  const origin = document.location.origin;
  return {
    title: getShareMsg,
    desc: '',
    link: getShareLink(id),
    imgUrl: `${origin}${thumbnail}`,
    type: 'link',
    success:
      success ||
      function () {
        _hmt.push(['_trackEvent', 'Wechat', 'share', shareType]);
      },
    cancel:
      cacel ||
      function () {
        _hmt.push(['_trackEvent', 'Wechat', 'shareCancel', shareType]);
      },
  };
};

export const gtm = (eventAction, eventLabel, value) => {
  dataLayer.push({
    'event': 'interaction',
    'eventCategory': 'Navigation',
    'eventAction': eventAction,
    'eventLabel': eventLabel,
    'eventValue': value
  });
}

export const debug = (...args) => {
  console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
  console.log(...args)
  console.log('<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<')
}

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1)

export const isWechat = () =>
  window.navigator.userAgent.toLocaleLowerCase().indexOf('micromessenger') >
  0 ||
  (getCookie('padiWxId') && getCookie('padiWxId').length === 28);

export const courseName = {
  'Enriched Air Diver': 'EnrichedAirDiver',
  'EFR - Primary Care (CPR) & Sec Care (1st) w/ AED': 'EFR',
  'AWARE Coral Reef Conservation Specialty': 'CoralReefConservation',
  'Equipment Specialist': 'EquipmentSpecialist',
  'Advanced Open Water': 'AdvancedOpenWater',
  'Open Water': 'OpenWater',
  'Peak Performance Buoyancy': 'PeakPerformanceBuoyancy',
  Drift: 'DriftDiver',
  'Rescue Diver': 'RescueDiver',
  Divemaster: 'DiveMaster',
  'Advanced Freediver': 'AdvancedFreediver',
  'Master Freediver': 'MasterFreediver',
  Freediver: 'Freediver',
  'Basic Freediver': 'BasicFreediver',
  'PADI Discover Mermaid': 'DiscoverMermaid',
  'PADI Basic Mermaid': 'BasicMermaid',
  'PADI Mermaid': 'Mermaid',
  'PADI Advanced Mermaid': 'AdvancedMermaid',
};

export const getCurrentGoods = (cardOption, data, certName) => {
  const currentGoods = cardOption[data.ecardId].cardStockDTOS.find(option => {
    return option.skuId === data.skuId;
  });
  currentGoods.ecardId = data.ecardId;
  currentGoods.ecardName = certName;
  return currentGoods;
};

export const InputOnBlur = () => {
  setTimeout(() => {
    window.scrollBy(0, 1);
    window.scrollBy(0, -1);
  }, 100);
};

export const isProduction = () => document.location.origin === 'https://wx.padi.com.cn'
export const compareObj = (obj1, obj2) => {
  let flag = true;
  const compare = (obj1, obj2) => {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      flag = false;
    } else {
      for (let x in obj1) {
        if (obj2.hasOwnProperty(x)) {
          if (obj1[x] !== obj2[x]) {
            let type1 = Object.prototype.toString.call(obj1[x]);
            type1 = type1.substring(8, type1.length - 1).toLowerCase();
            let type2 = Object.prototype.toString.call(obj2[x]);
            type2 = type2.substring(8, type2.length - 1).toLowerCase();
            if (
              (type1 === 'object' || type1 === 'array') &&
              (type2 === 'object' || type2 === 'array')
            ) {
              compare(obj1[x], obj2[x]);
            } else {
              flag = false;
            }
          }
        } else {
          flag = false;
        }
      }
    }
    return flag;
  };
  return compare(obj1, obj2);
};

export const region_sc = [
  {
    label: '大陆+86',
    value: '86',
  },
  {
    label: '香港+852',
    value: '852',
  },
  {
    label: '台湾+886',
    value: '886',
  },
  {
    label: '澳门+853',
    value: '853',
  },
];

export const region_tc = [
  {
    label: '大陸+86',
    value: '86',
  },
  {
    label: '香港+852',
    value: '852',
  },
  {
    label: '台灣+886',
    value: '886',
  },
  {
    label: '澳門+853',
    value: '853',
  },
];

export const region_en = [
  {
    label: 'Mainland+86',
    value: '86',
  },
  {
    label: 'HongKong+852',
    value: '852',
  },
  {
    label: 'Taiwan+886',
    value: '886',
  },
  {
    label: 'Macao+853',
    value: '853',
  },
];

export const isMobile = value => {
  return /^[0-9]{7,15}$/.test(value);
};

export const isCode = value => {
  return /^\d{6}$/.test(value);
};

export const isMainlandMobile = value => {
  return /^1\d{10}$/.test(value);
};

export const isNumber = val => {
  const regPos = /^\d+(\.\d+)?$/;
  const regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/;
  if (regPos.test(val) || regNeg.test(val)) {
    return true;
  } else {
    return false;
  }
};

export const formatDate = (date, pattern) => {
  return dayjs(date).format(pattern);
};

export const showAlert = (message, btnLabel, method) => {
  Modal.alert('', message, [{ text: btnLabel, onPress: method }]);
  // fix wechat button drifting issue
  setTimeout(() => {
    window.scrollBy(0, 1);
    window.scrollBy(0, -1);
  }, 800);
};

// export const isiOS = () => {
//   let u = navigator.userAgent;
//   let iOs = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
//   return iOs;
// };

export const isAndroid = () => {
  let u = navigator.userAgent;
  let android = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
  return android;
};

export const getParameterByName = (name, url = window.location.href) => {
  const normalizedName = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + normalizedName + '(=([^&]*)|&|$)', 'i');
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

// export const getRedirectUrl = () => {
//   const referer = getParameterByName('referer')
//   const { href, search } = window.location
//   return referer ? href ? search ? `${href}&`
// }

export const confirm = ({
  title = 'cardOptions-replaceCardHint',
  message,
  cancelText = 'common-cancel',
  confirmText = 'common-comfirmBtn',
  cancelFn = () => { },
  confirmFn,
  formatMessage,
}) => {
  Modal.alert(formatMessage({ id: title }), formatMessage({ id: message }), [
    {
      text: formatMessage({ id: cancelText }),
      onPress: () => cancelFn,
    },
    {
      text: formatMessage({ id: confirmText }),
      onPress: () => confirmFn(),
    },
  ]);
};

export const STORE_LEVELS = {
  sc: {
    DIVE_CENTER: '潜水中心',
    DIVE_RESORT: '潜水度假村',
    DIVE_BOAT: '潜水船宿',
    DIVE_CENTER_5STAR: '5星潜水中心',
    DIVE_RESORT_5STAR: '5星潜水度假村',
    IDC_DIVE_CENTER: '5星教练发展中心',
    IDC_DIVE_RESORT: '5星教练发展潜水度假村',
    CDC: '5星职业发展中心',
    EDUCATION: '教育机构',
    RECREATION: '休闲设施',
  },
  tc: {
    DIVE_CENTER: '潛水中心',
    DIVE_RESORT: '潛水度假村',
    DIVE_BOAT: '潛水船宿',
    DIVE_CENTER_5STAR: '5星潛水中心',
    DIVE_RESORT_5STAR: '5星潛水度假村',
    IDC_DIVE_CENTER: '5星教練發展中心',
    IDC_DIVE_RESORT: '5星教練發展潛水度假村',
    CDC: '5星職業發展中心',
    EDUCATION: '教育機構',
    RECREATION: '休閒設施',
  },
  en: {
    DIVE_CENTER: 'DIVE CENTER',
    DIVE_RESORT: 'DIVE RESORT',
    DIVE_BOAT: 'DIVE BOAT',
    DIVE_CENTER_5STAR: 'DIVE CENTER 5STAR',
    DIVE_RESORT_5STAR: 'DIVE RESORT 5STAR',
    IDC_DIVE_CENTER: 'IDC DIVE CENTER',
    IDC_DIVE_RESORT: 'IDC DIVE RESORT',
    CDC: 'CDC',
    EDUCATION: 'EDUCATION',
    RECREATION: 'RECREATION',
  }
}

export const showModal = (title, content, onOk, onCancel, okText, cancelText) => {
  Modal.alert(title, content, [
    { text: cancelText, onPress: onCancel },
    { text: okText, onPress: onOk },
  ])
}

export const getStoreFullAddress = ({
  addressDTO,
  storeAddressLine1,
  storeAddressLine2,
  storeAddressLine3,
  storeState,
  storeCountry
}) => {
  if(addressDTO) {
    return addressDTO.addressLine1 ? `${addressDTO.addressLine1} ${addressDTO.addressLine2} ${addressDTO.addressLine3}
    ${addressDTO.stateName} ${addressDTO.countryName}` : ''
  } 
  return storeAddressLine1 ? `${storeAddressLine1} ${storeAddressLine2} ${storeAddressLine3}
    ${storeState} ${storeCountry}` : ''
  }

export const mapCourse = {
  'BasicFreediver': 'BFD',
  'Freediver': 'FD',
  'EnrichedAirDiver': 'EANx'
}
export const specialty = [
  {
    name: '适性技术',
    sp: 4568,
    spi: 11783,
    spt: 11784,
    star: true,
    desc: {
      en: 'Upload formal training and/or history of experience.',
      sc: '上传关于培训记录的书面证明文件或训练经验历史的书面证明文件。',
      tc: '上傳關於培訓記錄的書面證明文件或訓練經驗歷史的書面證明文件。',
    }
  },
  {
    name: 'AWARE 鲨鱼保育',
    sp: 3404,
    spi: 9871,
    spt: 9872,
  },
  {
    name: '船潜潜水员',
    sp: 24,
    spi: 54,
    spt: 91,
  },
  {
    name: '深潜潜水员',
    sp: 35,
    spi: 59,
    spt: 93,
  },
  {
    name: '延迟水面浮标（DSMB）潜水员',
    sp: 4251,
    spi: 11541,
    spt: 11542,
  },
  {
    name: '数码水底摄影师',
    sp: 45,
    spi: 64,
    spt: 97,
  },
  {
    name: '打击海洋垃圾',
    sp: 3979,
    spi: 11008,
    spt: 11009,
  },
  {
    name: '潜水员推进器',
    sp: 10,
    spi: 60,
    spt: 94,
  },
  {
    name: '放流潜水员',
    sp: 6,
    spi: 61,
    spt: 95,
  },
  {
    name: '紧急供氧专长',
    sp: 28,
    spi: 6094,
    spt: 6095,
    star: true,
    desc: {
      en: 'Upload emergency oxygen provider certification from other organization.',
      sc: '上传其他组织符合资格的等同级别证书。',
      tc: '上傳其他組織符合資格的等同級別證書。',
    }
  },
  {
    name: '高氧空气潜水员',
    sp: 71,
    spi: 65,
    spt: 98,
    star: true,
    desc: {
      en: 'Upload documentation of a qualifying certification from another training organization for enriched air diver.',
      sc: '上传其他组织符合资格的高氧空气潜水员等同级别证书。',
      tc: '上傳其他組織符合資格的高氧空氣潛水員等同級別證書。',
    }
  },
  {
    name: '鱼类辨识',
    sp: 36,
    spi: 67,
    spt: 100,
  },
  {
    name: '全面镜潜水员',
    sp: 4253,
    spi: 11543,
    spt: 11544,
  },
  {
    name: '水底自然观察家',
    sp: 2,
    spi: 73,
    spt: 105,
  },
  {
    name: '水底导航',
    sp: 77,
    spi: 74,
    spt: 106,
  },
  {
    name: '夜潜潜水员',
    sp: 9,
    spi: 75,
    spt: 107,
  },
  {
    name: '独行侠潜水员',
    sp: 4255,
    spi: 11545,
    spt: 11546,
    star: true,
    desc: {
      en: 'Upload documentation of a qualifying certification from another training organization for self-reliant or technical diver.',
      sc: '上传其他组织符合资格的独行侠潜水员或技术潜水员等同级别证书。',
      tc: '上傳其他組織符合資格的獨行俠潛水員或技術潛水員等同級別證書。',
    }
  },
  {
    name: '水底摄影师',
    sp: 65,
    spi: 82,
    spt: 114,
  },
  {
    name: '沉船潜水员',
    sp: 8,
    spi: 83,
    spt: 115,
  },
  {
    name: '高海拔潜水员',
    sp: 12,
    spi: 52,
    spt: 89,
  },
  {
    name: '干式潜水衣潜水员',
    sp: 34,
    spi: 62,
    spt: 96,
  },
  {
    name: '搜索和寻回潜水员',
    sp: 41,
    spi: 79,
    spt: 111,
  },
  {
    name: '装备专家',
    sp: 52,
    spi: 66,
    spt: 99,
    star: true,
    desc: {
      en: 'Upload documentation of attendance at an equipment manufacturer’s repair clinic or written equipment repair authorization from a manufacturer.',
      sc: '上传参加过装备厂商维修班或装备厂商的装备维修授权书。',
      tc: '上傳參加過裝備廠商維修班或裝備廠商的裝備維修授權書。',
    }
  },
  {
    name: '冰潜潜水员',
    sp: 78,
    spi: 70,
    spt: 103,
    star: true,
    desc: {
      en: 'Upload documentation of a qualifying certification from another training organization for ice diver.',
      sc: '上传其他组织符合资格的冰潜潜水员等同级别证书。',
      tc: '上傳其他組織符合資格的冰潛潛水員等同級別證書。',
    }
  },
  {
    name: '洞窟潜水员',
    sp: 82,
    spi: 55,
    spt: 92,
    star: true,
    desc: {
      en: 'Upload documentation of certification as a full Cave Diver by a recognized Cave certification agency.',
      sc: '上传合格的洞穴潜水认证机构的全洞穴潜水员(Full Cave Diver)证书。',
      tc: '上傳合格的洞穴潛水認證機構的全洞穴潛水員(Full Cave Diver)證書。',
    }
  },
  {
    name: '侧挂气瓶潜水员',
    sp: 3461,
    spi: 9967,
    spt: 9968,
    star: true,
    desc: {
      en: 'Upload documentation of a qualifying certification from another training organization for sidemount diver; or attached documentation of proof of at least 50 sidemount dives completed.',
      sc: '上传其他组织符合资格的侧挂气瓶潜水员等同级别证书; 或附上已经完成 50 次侧挂气瓶潜水的证明文件。',
      tc: '上傳其他組織符合資格的側掛氣瓶潛水員等同級別證書; 或附上已經完成 50 次側掛氣瓶潛水的證明文件。',
    }
  }
]

export const specialtyCd = [
  {
    name: '适性技术',
    sp: 4568,
    spi: 11783,
    spt: 11784
  },
  {
    name: 'AWARE 鲨鱼保育',
    sp: 3404,
    spi: 9871,
    spt: 9872,
  },
  {
    name: '船潜潜水员',
    sp: 24,
    spi: 54,
    spt: 91,
  },
  {
    name: '深潜潜水员',
    sp: 35,
    spi: 59,
    spt: 93,
  },
  {
    name: '延迟水面浮标（DSMB）潜水员',
    sp: 4251,
    spi: 11541,
    spt: 11542,
  },
  {
    name: '数码水底摄影师',
    sp: 45,
    spi: 64,
    spt: 97,
  },
  {
    name: '打击海洋垃圾',
    sp: 3979,
    spi: 11008,
    spt: 11009,
  },
  {
    name: '潜水员推进器',
    sp: 10,
    spi: 60,
    spt: 94,
  },
  {
    name: '放流潜水员',
    sp: 6,
    spi: 61,
    spt: 95,
  },
  {
    name: '紧急供氧专长',
    sp: 28,
    spi: 6094,
    spt: 6095
  },
  {
    name: '高氧空气潜水员',
    sp: 71,
    spi: 65,
    spt: 98,
    star: true,
    desc: {
      en: 'Upload documentation of a qualifying certification from another training organization for enriched air diver.',
      sc: '上传其他组织符合资格的高氧空气潜水员等同级别证书。',
      tc: '上傳其他組織符合資格的高氧空氣潛水員等同級別證書。',
    }
  },
  {
    name: '鱼类辨识',
    sp: 36,
    spi: 67,
    spt: 100,
  },
  {
    name: '全面镜潜水员',
    sp: 4253,
    spi: 11543,
    spt: 11544,
  },
  {
    name: '水底自然观察家',
    sp: 2,
    spi: 73,
    spt: 105,
  },
  {
    name: '水底导航',
    sp: 77,
    spi: 74,
    spt: 106,
  },
  {
    name: '夜潜潜水员',
    sp: 9,
    spi: 75,
    spt: 107,
  },
  {
    name: '独行侠潜水员',
    sp: 4255,
    spi: 11545,
    spt: 11546,
    star: true,
    desc: {
      en: 'Upload documentation of a qualifying certification from another training organization for self-reliant or technical diver.',
      sc: '上传其他组织符合资格的独行侠潜水员或技术潜水员等同级别证书。',
      tc: '上傳其他組織符合資格的獨行俠潛水員或技術潛水員等同級別證書。',
    }
  },
  {
    name: '水底摄影师',
    sp: 65,
    spi: 82,
    spt: 114,
  },
  {
    name: '沉船潜水员',
    sp: 8,
    spi: 83,
    spt: 115,
  },
  {
    name: '高海拔潜水员',
    sp: 12,
    spi: 52,
    spt: 89,
  },
  {
    name: '干式潜水衣潜水员',
    sp: 34,
    spi: 62,
    spt: 96,
  },
  {
    name: '搜索和寻回潜水员',
    sp: 41,
    spi: 79,
    spt: 111,
  },
  {
    name: '装备专家',
    sp: 52,
    spi: 66,
    spt: 99
  },
  {
    name: '冰潜潜水员',
    sp: 78,
    spi: 70,
    spt: 103
  },
  {
    name: '洞窟潜水员',
    sp: 82,
    spi: 55,
    spt: 92,
    star: true,
    desc: {
      en: 'Upload documentation of certification as a full Cave Diver by a recognized Cave certification agency.',
      sc: '上传合格的洞穴潜水认证机构的全洞穴潜水员(Full Cave Diver)证书。',
      tc: '上傳合格的洞穴潛水認證機構的全洞穴潛水員(Full Cave Diver)證書。',
    }
  },
  {
    name: '侧挂气瓶潜水员',
    sp: 3461,
    spi: 9967,
    spt: 9968
  }
]

export const specialtyMapping = {
  11783: {
    sc: '适性技术专长教练',
    tc: '適性技術專長教練',
    en: 'PADI Adaptive Techniques Specialty Instructor'
  },
  9871: {
    sc: 'AWARE 鲨鱼保育专长教练',
    tc: 'AWARE 鯊魚保育專長教練',
    en: 'AWARE Shark Conservation Specialty Instructor'
  },
  54: {
    sc: '船潜潜水专长教练',
    tc: '船潛潛水專長教練',
    en: 'Boat Specialty Instructor'
  },
  59: {
    sc: '深潜潜水专长教练',
    tc: '深潛潛水專長教練',
    en: 'Deep Specialty Instructor'
  },
  11541: {
    sc: '延迟水面浮标（DSMB）潜水专长教练',
    tc: '延遲水面浮標（DSMB）潛水專長教練',
    en: 'DELAYED SURFACE MARKER BUOY Specialty Instructor'
  },
  64: {
    sc: '数码水底摄影师专长教练',
    tc: '數碼水底攝影師專長教練',
    en: 'Digital Underwater Photography Instructor'
  },
  11008: {
    sc: '打击海洋垃圾专长教练',
    tc: '打擊海洋垃圾專長教練',
    en: 'Dive Against Debris Specialty Instructor'
  },
  60: {
    sc: '潜水员推进器专长教练',
    tc: '潛水員推進器專長教練',
    en: 'Diver Propulsion Vehicle Specialty Instructor'
  },
  61: {
    sc: '放流潜水专长教练',
    tc: '放流潛水專長教練',
    en: 'Drift Specialty Instructor'
  },
  6094: {
    sc: '紧急供氧专长教练',
    tc: '緊急供氧專長教練',
    en: 'Emergency Oxygen Provider Specialty Instructor'
  },
  65: {
    sc: '高氧空气潜水专长教练',
    tc: '高氧空氣潛水專長教練',
    en: 'Enriched Air Specialty Instructor'
  },
  67: {
    sc: '鱼类辨识专长教练',
    tc: '魚類辨識專長教練',
    en: 'Fish Identification Specialty Instructor'
  },
  11543: {
    sc: '全面镜潜水专长教练',
    tc: '全面鏡潛水專長教練',
    en: 'Full Face Mask Specialty Instructor'
  },
  73: {
    sc: '水底自然观察家专长教练',
    tc: '水底自然觀察家專長教練',
    en: 'Underwater Naturalist Specialty Instructor'
  },
  74: {
    sc: '水底导航专长教练',
    tc: '水底導航專長教練',
    en: 'Underwater Navigator Specialty Instructor'
  },
  75: {
    sc: '夜潜潜水专长教练',
    tc: '夜潛潛水專長教練',
    en: 'Night Specialty Instructor'
  },
  11545: {
    sc: '独行侠潜水专长教练',
    tc: '獨行俠潛水專長教練',
    en: 'Self-Reliant Specialty Instructor'
  },
  82: {
    sc: '水底摄影师专长教练',
    tc: '水底攝影師專長教練',
    en: 'Underwater Videographer Instructor'
  },
  83: {
    sc: '沉船潜水专长教练',
    tc: '沉船潛水專長教練',
    en: 'Wreck Specialty Instructor'
  },
  52: {
    sc: '高海拔潜水专长教练',
    tc: '高海拔潛水專長教練',
    en: 'Altitude Specialty Instructor'
  },
  62: {
    sc: '干式潜水衣潜水专长教练',
    tc: '乾式潛水衣潛水專長教練',
    en: 'Dry Suit Specialty Instructor'
  },
  79: {
    sc: '搜索和寻回潜水专长教练',
    tc: '搜索和尋回潛水專長教練',
    en: 'Search & Recovery Specialty Instructor'
  },
  66: {
    sc: '装备专家专长教练',
    tc: '裝備專家專長教練',
    en: 'Equipment Specialist Specialty Instructor'
  },
  70: {
    sc: '冰潜潜水专长教练',
    tc: '冰潛潛水專長教練',
    en: 'Ice Specialty Instructor'
  },
  55: {
    sc: '洞窟潜水专长教练',
    tc: '洞窟潛水專長教練',
    en: 'Cavern Specialty Instructor'
  },
  9967: {
    sc: '侧挂气瓶潜水专长教练',
    tc: '側掛氣瓶潛水專長教練',
    en: 'Sidemount Specialty Instructor'
  }
}

export const siAppStatus = {
  SI_1: {
    sc: {
      "WAIT_ADMIN_APPROVED": "待审核",
      "ADMIN_NOT_APPROVED": "审核未通过",
      "SUCCESS": "已录入",
      "ADMIN_APPROVED": "等待发证",
      "SUBMIT": "待审核",
      "CD_CREATED": "CD已提交申请",
      "SHIPPED": "等待发证",
      "WAIT_PAY": "等待付款",
      "PAY_ERROR": "支付异常"
    },
    tc: {
      "WAIT_ADMIN_APPROVED": "待審核",
      "ADMIN_NOT_APPROVED": "審核未通過",
      "SUCCESS": "已錄入",
      "ADMIN_APPROVED": "等待發證",
      "SUBMIT": "待審核",
      "CD_CREATED": "CD已提交申請",
      "SHIPPED": "等待發證",
      "WAIT_PAY": "等待付款",
      "PAY_ERROR": "支付異常"
    },
    en: {
      "WAIT_ADMIN_APPROVED": "To Confirm",
      "ADMIN_NOT_APPROVED": "Rejected",
      "SUCCESS": "Completed",
      "ADMIN_APPROVED": "Approved",
      "SUBMIT": "To Confirm",
      "CD_CREATED": "Created by CD",
      "SHIPPED": "Wait for Cert",
      "WAIT_PAY": "to Pay",
      "PAY_ERROR": "Pay Error"
    }
  },
  SI_2: {
    sc: {
      "WAIT_ADMIN_APPROVED": "待审核",
      "ADMIN_NOT_APPROVED": "审核未通过",
      "SUCCESS": "已录入",
      "ADMIN_APPROVED": "等待发证",
      "SUBMIT": "待审核",
      "CD_CREATED": "CD已提交申请",
      "SHIPPED": "等待发证",
      "WAIT_PAY": "等待付款",
      "PAY_ERROR": "支付异常"
    },
    tc: {
      "WAIT_ADMIN_APPROVED": "待審核",
      "ADMIN_NOT_APPROVED": "審核未通過",
      "SUCCESS": "已錄入",
      "ADMIN_APPROVED": "等待發證",
      "SUBMIT": "待審核",
      "CD_CREATED": "CD已提交申請",
      "SHIPPED": "等待發證",
      "WAIT_PAY": "等待付款",
      "PAY_ERROR": "支付異常"
    },
    en: {
      "WAIT_ADMIN_APPROVED": "To Confirm",
      "ADMIN_NOT_APPROVED": "Rejected",
      "SUCCESS": "Completed",
      "ADMIN_APPROVED": "Approved",
      "SUBMIT": "To Confirm",
      "CD_CREATED": "Created by CD",
      "SHIPPED": "Wait for Cert",
      "WAIT_PAY": "to Pay",
      "PAY_ERROR": "Pay Error"
    }
  }
}

export const cdSiAppStatus = {
  sc: {
    "WAIT_ADMIN_APPROVED": "待审核",
    "ADMIN_NOT_APPROVED": "审核未通过",
    "SUCCESS": "已录入",
    "ADMIN_APPROVED": "等待发证",
    "SUBMIT": "待审核",
    "CD_CREATED": "等待会员签名确认",
    "SHIPPED": "等待发证",
    "WAIT_PAY": "等待付款",
    "PAY_ERROR": "支付异常"
  },
  tc: {
    "WAIT_ADMIN_APPROVED": "待審核",
    "ADMIN_NOT_APPROVED": "審核未通過",
    "SUCCESS": "已錄入",
    "ADMIN_APPROVED": "等待發證",
    "SUBMIT": "待審核",
    "CD_CREATED": "等待會員簽名確認",
    "SHIPPED": "等待發證",
    "WAIT_PAY": "等待付款",
    "PAY_ERROR": "支付異常"
  },
  en: {
    "WAIT_ADMIN_APPROVED": "To Confirm",
    "ADMIN_NOT_APPROVED": "Rejected",
    "SUCCESS": "Completed",
    "ADMIN_APPROVED": "Approved",
    "SUBMIT": "To Confirm",
    "CD_CREATED": "Applicant to Sign",
    "SHIPPED": "Wait for Cert",
    "WAIT_PAY": "to Pay",
    "PAY_ERROR": "Pay Error"
  }
}

export const dataURLtoBlobOrBlodURL = (dataurl, isBlodUrl = false) => {
  let arr = dataurl.split(',')
  let mime = arr[0].match(/:(.*?);/)[1]
  let bstr = atob(arr[1])
  let n = bstr.length
  let u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  if (isBlodUrl) {
    return URL.createObjectURL(new Blob([u8arr], { type: mime }))
  }
  return new Blob([u8arr], { type: mime })
}

export const verifyCredits = (credits) => {
  if (document.location.origin === 'https://wx.padi.com.cn') {
    return credits < 25
  } else {
    return credits < 1
  }
}

export const getAge = (birthDay) => {
  const today = dayjs()
  const birthDate = dayjs(birthDay)
  const baseAge = Math.abs(today.year()) - Math.abs(birthDate.year())
  const thisBirthday = dayjs(`${today.year()}-${birthDate.month() + 1}-${birthDate.date()}`)
  return today.isBefore(thisBirthday) ? baseAge - 1 : baseAge
}

export const removeExpires = (list) => {
  let newAttachList = list.map(i => i.split('?Expires=')[0].replace('https://files.padi.com.cn/', '').replace('https://assets.padi.com.cn/', '').replace('https://ecards-h5.oss-cn-beijing.aliyuncs.com/', ''))
  return newAttachList
}

export const regionsMapping = {
  sc: [
    {
      label: '大陆',
      value: 51,
    },
    {
      label: '台湾',
      value: 215,
    },
    {
      label: '香港',
      value: 100,
    },
    {
      label: '澳门',
      value: 130,
    },
  ],
  tc: [
    {
      label: '大陸',
      value: 51,
    },
    {
      label: '台灣',
      value: 215,
    },
    {
      label: '香港',
      value: 100,
    },
    {
      label: '澳門',
      value: 130,
    },
  ],
  en: [
    {
      label: 'Mainland',
      value: 51,
    },
    {
      label: 'Taiwan',
      value: 215,
    },
    {
      label: 'Hong Kong',
      value: 100,
    },
    {
      label: 'Macao',
      value: 130,
    },
  ]
};

export const languagesMapping = [
  {
    label: '简体中文',
    value: 45
  },
  {
    label: '繁體中文',
    value: 18
  },
  {
    label: 'English',
    value: 5
  }
]

export const filterRegion = (region) => {
  const filterRegion = regionsMapping['sc'].find(item => item.value === region)
  return filterRegion ? filterRegion.label : ''
}

export const filterLanguage = (language) => {
  const filterLang = languagesMapping.find(item => item.value === language)
  return filterLang ? filterLang.label : ''
}

export const getUserRegion = (countryISOCode, teachingLocation) => {
  if (!teachingLocation) {
    return countryISOCode
  }
  return teachingLocation
}

export const getSubmitOssFormat = (url) => {
  return url.split('?Expires=')[0].replace('https://files.padi.com.cn/', '')
}

const EFR_VALID_MONTH = 24;
const efrExpired = (efr) => dayjs(efr.issueDate).add(EFR_VALID_MONTH, 'month').isBefore(dayjs());
export const findEfrOrEfri = (certificates, credentials) => 
(credentials.find(item => item.credentialNumber === 35 || item.credentialNumber === 38) && 'EFRI')
|| certificates.find(item => (item.certificationNumber === 76 ||
      item.certificationNumber === 68 ||
      item.certificationNumber === 31 ||
      item.certificationNumber === 20) && !efrExpired(item))?.studentNumber || '';
// export const findEfrOrEfri = (certifications, credentials) => {
//   const efr = certifications.find(item => {
//     return !item.expired && (item.certificationNumber === 76 ||
//       item.certificationNumber === 68 ||
//       item.certificationNumber === 31 ||
//       item.certificationNumber === 20)
//   })
//   const efri = credentials.find(item => {
//     return !item.expired && item.credentialNumber === 35
//   })
//   if (efr) return efr.studentNumber
//   else if (efri) return 'EFRI'
//   else return null
// }

export function IsNotPc() {
  let userAgent = navigator.userAgent, Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
  return Agents.some((i) => {
    return userAgent.includes(i)
  })
}

export const findCity = (id) => {
  return areaDataSc.find(item => item.value === id).label
}

export const filterTeachingLanguage = (languageId) => {
  return languagesMapping.find(item => item.value === languageId).label
}

export const filterStateId = (addressStateId, lang) => {
  const area = {
    sc: areaDataSc,
    tc: areaDataTc,
    en: areaDataEn
  }
  return area[lang].find(item => item.value === addressStateId).label
}

export const specialtyInsMapping = {
  11783: '适性技术专长教练',
  9871: 'AWARE 鲨鱼保育专长教练',
  54: '船潜潜水专长教练',
  59: '深潜潜水专长教练',
  11541: '延迟水面浮标（DSMB）潜水专长教练',
  64: '数码水底摄影师专长教练',
  11008: '打击海洋垃圾专长教练',
  60: '潜水员推进器专长教练',
  61: '放流潜水专长教练',
  6094: '紧急供氧专长教练',
  65: '高氧空气潜水专长教练',
  67: '鱼类辨识专长教练',
  11543: '全面镜潜水专长教练',
  73: '水底自然观察家专长教练',
  74: '水底导航专长教练',
  75: '夜潜潜水专长教练',
  11545: '独行侠潜水专长教练',
  82: '水底摄影师专长教练',
  83: '沉船潜水专长教练',
  52: '高海拔潜水专长教练',
  62: '干式潜水衣潜水专长教练',
  79: '搜索和寻回潜水专长教练',
  66: '装备专家专长教练',
  70: '冰潜潜水专长教练',
  55: '洞窟潜水专长教练',
  9967: '侧挂气瓶潜水专长教练'
}

export const textbooksRegions = {
  en: [
    {
      label: 'Taiwan - Main Island & Remote Area',
      value: 1,
    },
    {
      label: 'Taiwan - Liuqiu Township and Hengchun Township',
      value: 2,
    },
    {
      label: 'Taiwan - Green Island',
      value: 3,
    },
    {
      label: 'Hong Kong',
      value: 4,
    },
    {
      label: 'Macau',
      value: 5,
    },
  ],
  sc: [
    {
      label: '台湾 - 主岛及偏远地区',
      value: 1,
    },
    {
      label: '台湾 - 琉球及恒春',
      value: 2,
    },
    {
      label: '台湾 - 绿岛',
      value: 3,
    },
    {
      label: '香港',
      value: 4,
    },
    {
      label: '澳门',
      value: 5,
    },
  ],
  tc: [
    {
      label: '台灣 - 主島及偏遠地區',
      value: 1,
    },
    {
      label: '台灣 - 琉球及恆春',
      value: 2,
    },
    {
      label: '台灣 - 綠島',
      value: 3,
    },
    {
      label: '香港',
      value: 4,
    },
    {
      label: '澳門',
      value: 5,
    },
  ],
};

export const textbooksOrderStatus = {
  sc: [
    {
      code: 'WAIT_PAY',
      status: '待付款'
    },
    {
      code: 'PAID',
      status: '已付款'
    },
    {
      code: 'CLOSED',
      status: '已过期'
    },
    {
      code: 'REFUNDED',
      status: '已退款'
    }
  ],
  tc: [
    {
      code: 'WAIT_PAY',
      status: '待付款'
    },
    {
      code: 'PAID',
      status: '已付款'
    },
    {
      code: 'CLOSED',
      status: '已過期'
    },
    {
      code: 'REFUNDED',
      status: '已退款'
    }
  ],
  en: [
    {
      code: 'WAIT_PAY',
      status: 'Waiting for payment'
    },
    {
      code: 'PAID',
      status: 'Already paid'
    },
    {
      code: 'CLOSED',
      status: 'Expired'
    },
    {
      code: 'REFUNDED',
      status: 'Refunded'
    }
  ],
}