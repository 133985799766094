import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Icon, Result, Card, WingBlank, WhiteSpace, Flex } from 'antd-mobile';
import { formatMoney } from '../../components/common/utils';

const BooksCheckoutResult = () => {

  const { formatMessage } = useIntl()
  const { state: { price } } = useLocation(); 
  const { profile } = useSelector(state => ({
    profile: state.data.profile,
  }));

  return (
    <>
      <Result
        img={<Icon type="check-circle" style={{ fill: '#1F90E6', width: '60px', height: '60px' }} />}
        title={formatMessage({id: 'book-submit-success'})}
        message={
          formatMessage({ id: 'book-offline-instruction' }, 
            { price: profile && profile.teachingLocation === 'TW' ? formatMoney(price, 'TWD') : '$AUD' + price })}
      />
      <WingBlank size="lg">
      <WhiteSpace size="lg" />
      <Card>
        <Card.Header title={formatMessage({id: 'book-remittance'})} />
          {profile && profile.teachingLocation === 'TW' ? (
            <Card.Body>
              <Flex align="start">
                <Flex.Item><FormattedMessage id="book-result-bank" /></Flex.Item>
                <Flex.Item>臺灣銀行松山分行</Flex.Item>
              </Flex>
              <WhiteSpace />
              <Flex align="start">
                <Flex.Item>統編號</Flex.Item>
                <Flex.Item>89144753</Flex.Item>
              </Flex>
              <WhiteSpace />
              <Flex align="start">
                <Flex.Item><FormattedMessage id="book-result-account-name" /></Flex.Item>
                <Flex.Item>澳大利亞商派迪亞太股份有限公司台灣分公司</Flex.Item>
              </Flex>
              <WhiteSpace />
              <Flex align="start">
                <Flex.Item><FormattedMessage id="book-result-account-number" /></Flex.Item>
                <Flex.Item>064-001-06170-9</Flex.Item>
              </Flex>
            </Card.Body>
          ) : (
              <Card.Body>
                <Flex align="start">
                  <Flex.Item><FormattedMessage id="book-result-bank" /></Flex.Item>
                  <Flex.Item>Westpac Banking Corporation Limited</Flex.Item>
                </Flex>
                <WhiteSpace />
                <Flex align="start">
                  <Flex.Item><FormattedMessage id="book-result-address" /></Flex.Item>
                  <Flex.Item>Shop 20, Forestway Shopping Centre Frenchs Forest NSW 2086 Australia</Flex.Item>
                </Flex>
                <WhiteSpace />
                <Flex align="start">
                  <Flex.Item><FormattedMessage id="book-result-account-name" /></Flex.Item>
                  <Flex.Item>PADI Asia Pacific</Flex.Item>
                </Flex>
                <WhiteSpace />
                <Flex align="start">
                  <Flex.Item>BSB: </Flex.Item>
                  <Flex.Item>032123</Flex.Item>
                </Flex>
                <WhiteSpace />
                <Flex align="start">
                  <Flex.Item><FormattedMessage id="book-result-account-number" /></Flex.Item>
                  <Flex.Item>131904</Flex.Item>
                </Flex>
                <WhiteSpace />
                <Flex align="start">
                  <Flex.Item>SWIFT/IBAN: </Flex.Item>
                  <Flex.Item>WPACAU2S</Flex.Item>
                </Flex>
              </Card.Body>
          )}
      </Card>
      <WhiteSpace size="lg" />
    </WingBlank>
    </>
  )
}

export default BooksCheckoutResult