import React, { Component } from 'react';
import { Button, WingBlank, Card, WhiteSpace, Modal } from 'antd-mobile';
import { connect } from 'react-redux'
import { push } from 'connected-react-router';
import { getAddressList } from '../../reducers/selectors'
import { delectAddress, selectAddress, submitLimitCard } from '../../actions';
import { injectIntl, FormattedMessage } from 'react-intl';
import querystring from 'querystring'

import './Address.css';

class AddressPage extends Component {

    delectAddress(addressId) {
        const { intl:{formatMessage} } = this.props;
        Modal.alert('', formatMessage({id: 'addressList-hintMsg'}), [
            {
              text: formatMessage({id: 'common-cancel'}),
            },
            {
              text: formatMessage({id: 'common-comfirmBtn'}),
              onPress: () => this.props.delectAddress(addressId)
            },
        ])
    }

    clickAddressCard = (addressInfo, fromSource, submitLimitCard, bindEcardImgInput) => {
        if(fromSource === 'profile'){
            return
        }else if(fromSource === 'tmallCampaign') {
            submitLimitCard({bindEcardImgInput, addressId: addressInfo.addressId})
            return
        }
        this.props.selectAddress(addressInfo.addressId);
        this.props.push('/order');
    }

    render(){
        const { push, addressList, fromSource, submitLimitCard, bindEcardImgInput } = this.props;
        return (
            <div className="my-address">
                <h2 className="address-title"><FormattedMessage id="addressList-title" /></h2>
                <div className="address-list">
                    { addressList && addressList.length > 0 ? addressList.map(addressInfo => (
                        <WingBlank size="lg">
                            <Card className="address">
                                <Card.Body>
                                    <div className="address-card" onClick={() => this.clickAddressCard(addressInfo, fromSource, submitLimitCard, bindEcardImgInput)}>
                                        <p><span><FormattedMessage id="orderList-userName" /></span>{addressInfo.userName}</p>
                                        <p><span><FormattedMessage id="orderList-userMobile" /></span>{addressInfo.userMobile}</p>
                                        <p><span><FormattedMessage id="orderList-address" /></span>{addressInfo.provinceLabel}{addressInfo.cityLabel}{addressInfo.districtLabel}{addressInfo.addressLine}</p>
                                    </div>
                                </Card.Body>
                                <Card.Footer extra={
                                    <div className="card-footer">
                                        <span onClick={() => push(`/addressForm?id=${addressInfo.addressId}`)}>
                                            <i className="fa fa-edit fa-lg"></i>
                                            <FormattedMessage id="addressList-edit" />
                                        </span>
                                        <span onClick={() => this.delectAddress(addressInfo.addressId)}>
                                            <i className="fa fa-trash-o fa-lg"></i>
                                            <FormattedMessage id="addressList-delete" />
                                        </span>
                                    </div>
                                } />
                            </Card>
                            <WhiteSpace size="sm" />
                        </WingBlank>
                    ))
                    :
                    <div className="empty-address">
                        <img src={require('../../assets/images/address-icon.png')} alt="empty" />
                        <p><FormattedMessage id="addressList-noAddress" /></p>
                    </div>
                    }
                </div>
                <div className="address-footer">
                    <Button type="primary" onClick={() => push('/addressForm')}>+ <FormattedMessage id="addressList-add" /></Button>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const urlStr = ownProps.location.search.split('?')[1]
    const fromSource = querystring.parse(urlStr).from
    return {
        addressList: getAddressList(state),
        fromSource
    }
}

export default connect(mapStateToProps, { push, delectAddress, selectAddress, submitLimitCard })(injectIntl(AddressPage));
