import React from 'react'
import { goBack } from 'connected-react-router';
import { connect } from 'react-redux'
import styles from './PadiPromotion.module.css'

const PadiPromotionPage = ({ goBack }) => {
  return <div className={styles.videoBox}>
    <a href="https://shop18875151.m.youzan.com/wscgoods/detail/2g08vq94a1aqf">
      <video
        id="video" 
        className={styles.video}
        src={require('../../assets/video/gear.mp4')}
        poster={require('../../assets/images/gear-poster.jpeg')}
        controls = "true"
        preload="auto" 
        webkit-playsinline="true"
        playsinline="true"
        x-webkit-airplay="allow" 
        x5-video-player-type="h5"
        x5-video-player-fullscreen="true"
      >
      </video>
    </a>
    <input 
      type="image" 
      name="imgBtn" 
      className={styles.imageBtn}
      src={require(`../../assets/images/close2.png`)} 
      alt="imgBtn" 
      onClick={() => goBack()} 
    />
  </div>
}

export default connect(null, { goBack })(PadiPromotionPage)