import {
  Badge,
  Button,
  Space,
  Grid,
  Image,
  Checkbox,
  Toast,
  Popover,
} from 'antd-mobile-v5';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';

import {
  useEcards,
  useBuyCards,
  useShopCart,
  useAddToCart,
  useProfile,
} from '../../services/hooks';
import {
  allEcards,
  hasPlasticCard,
  isCred,
  isEfr,
  isJuniorMermaid,
} from '../../utils/cert';
import Tag from '../../components/common/Tag';
import { CARD_TYPE_E, STANDARD_CARD_STR, getCardOptions } from './cardOptionUtils';
import LoadingStatus from '../../components/FormTools/Loading';
import { getStockTypeIdByCertId } from '../../services/utilities';
import styles from './BuyOptions.module.css';

const getSkuInfo = (certOrCred, options, styleName, isPlastic) => {
  options = options.filter(o => o.styleName === styleName);
  if(isPlastic) {
    options = options.filter(o => o.ecardType === certOrCred.type);
    if(styleName.indexOf(STANDARD_CARD_STR) >= 0) {
      // 普通实体卡
      let stockId = getStockTypeIdByCertId(certOrCred.type, certOrCred.typeNumber);
      if([8, 48, 77].indexOf(stockId) < 0) stockId = 1;
      return options.find(o => Math.abs(o.cardStockId) === stockId && certOrCred.cardRequestType === o.cardRequestType);
    } else {
      // 环保实体卡
      return options[0];
    }
  } else {
    // 环保电子卡
    return options.filter(o => o.typeName === CARD_TYPE_E)[0];
  }}
;

const getCertIdsInCart = (ecards, cart, styleName, isPlastic) => {
  if (!ecards || ecards.length === 0) return [];
  return cart
    .filter((item) => item.styleName === styleName && (isPlastic ? item.typeName === '实体卡' : item.typeName === '电子卡'))
    .map((item) => item.ecardId);
};

export default function BuyOptions({cardOptions}) {
  const options = getCardOptions(cardOptions);
  const [selectedImage, setSelectedImage] = useState(1);
  const [isPlastic, setIsPlastic] = useState(true);
  const [cart, setCart] = useState([]);
  const { loading: ecardsLoading, data: ecardsData } = useEcards({
    onSuccess: (data) => {
      setSelectedCerts(
        getCertIdsInCart(
          allEcards(data),
          cart,
          options[selectedImage].styleName,
          isPlastic
        )
      );
    },
  });
  const { loading: shopCartLoading } = useShopCart({
    onSuccess: ({ data }) => {
      setCart(data);
      setSelectedCerts(
        getCertIdsInCart(ecards, data, options[selectedImage].styleName, isPlastic)
      );
    },
  });
  const { loading: profileLoading, data: profile } = useProfile();
  const { loading: addToCartLoading, run: addToCartSubmit } = useAddToCart({
    onSuccess: () => Toast.show({ icon: 'success', content: '购物车已更新' }),
  });
  const { loading: buyCardsLoading, run: buyCardsSubmit } = useBuyCards({
    onSuccess: ({ url }) => (window.location.href = url),
  });
  const ecards = allEcards(ecardsData);
  const [selectedCerts, setSelectedCerts] = useState([]);

  const updateCart = () => {
    let newCart = [...cart];
    ecards.forEach((ecard) => {
      if(notAvailable(ecard)) return;
      const skuInfo = getSkuInfo(ecard, cardOptions, options[selectedImage].styleName, isPlastic);
      const { goodsId, skuId } = skuInfo;
      if (selectedCerts.find((c) => c === ecard.uniqueId)) {
        if (
          !newCart.find(
            (item) =>
              item.ecardId === ecard.uniqueId &&
              item.goodsId === goodsId &&
              item.skuId === skuId
          )
        ) {
          newCart.push({ ecardId: ecard.uniqueId, goodsId, skuId });
        }
      } else {
        newCart = newCart.filter(
          (item) =>
            !(
              item.ecardId === ecard.uniqueId &&
              item.goodsId === goodsId &&
              item.skuId === skuId
            )
        );
      }
    });
    return newCart;
  }

  const addToCart = () => {
    const newCart = updateCart();
    setCart(newCart);
    addToCartSubmit(newCart);
  };
  const checkout = () => {
    const newCart = updateCart();
    buyCardsSubmit(newCart);
  };

  const togglePlastic = (isPlastic) => {
    setSelectedCerts(
      getCertIdsInCart(ecards, cart, options[selectedImage].styleName, isPlastic)
    );
    setIsPlastic(isPlastic);
  };

  const changeImage = (index) => {
    setSelectedCerts(
      getCertIdsInCart(
        ecards,
        cart,
        options[index].styleName,
        index === 0 ? true : isPlastic
      )
    );
    setSelectedImage(index);
    if (index === 0) setIsPlastic(true);
  };

  const toggleCert = (ecard) => (checked) => {
    if (checked) {
      setSelectedCerts([...selectedCerts, ecard.uniqueId]);
    } else {
      setSelectedCerts(selectedCerts.filter((c) => c !== ecard.uniqueId));
    }
  };

  const notAvailable = (ecard) => {
    if(
      (isEfr(ecard) && selectedImage !== 0)
      || (selectedImage === 0 && !hasPlasticCard(ecard.ecardName))
      || (isPlastic && ('Discover Scuba Diving' === ecard.ecardName || 'PADI Discover Mermaid' === ecard.ecardName))
      || (!isJuniorMermaid(ecard) && options[selectedImage].styleName.indexOf('青少年') === 0)
      ) {
      return '此证书不支持当前卡面类型组合，请选择其他卡面和类型';
    }
    if(isCred(ecard) && !profile.buyCred) {
      return '请完成专业会员资格更新后，再申请专业会员卡';
    }
    return '';
  }

  return (
    <div className={styles.container}>
      <div className={styles.flexible}>
        <Grid columns={3}>
          <Grid.Item>
            <Image
              src={options[selectedImage].imageUrl}
              // style={{ borderRadius: '2vw' }}
            />
          </Grid.Item>
          <Grid.Item span={2} className={styles.priceArea} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <div>
              购买卡面
              <span className={styles.price}>
                <span style={{textDecoration: 'line-through'}}>￥{isPlastic ? 199 : 99}</span>
                <span className={styles.priceDigits}>
                ￥{isPlastic ? 99 : 50}
                </span>
              </span>
            </div>
             {/* <div>
              
             <span className={styles.price}>
                ￥
                <span className={styles.priceDigits}>
                  {isPlastic ? 100 : 50}
                </span>
              </span>
            </div> */}
            <div style={{paddingTop: '6px', fontSize: '12px'}}>卡片收益将捐助到 PADI 爱海洋公益基金</div>
            <div style={{paddingTop: '6px', fontSize: '12px'}}>所有优惠在结算页体现</div>
          </Grid.Item>
        </Grid>
        <h4 className={styles.optionTitle}>选择卡面</h4>
        <div>
          {options.map((option, index) => (
            <Tag
              key={option.styleName}
              selected={index === selectedImage}
              onClick={() => changeImage(index)}
            >
              {option.styleName}
            </Tag>
          ))}
        </div>
        <h4 className={styles.optionTitle}>选择类型</h4>
        <div>
          <Tag selected={isPlastic} onClick={() => togglePlastic(true)}>
            环保实体卡
          </Tag>
          {selectedImage !== 0 && (
            <Tag selected={!isPlastic} onClick={() => togglePlastic(false)}>
              环保电子卡
            </Tag>
          )}
        </div>
        <h4 className={styles.optionTitle}>选择证书（可多选）</h4>

        {(ecardsLoading || shopCartLoading || profileLoading) ? (
          <LoadingStatus />
        ) : (
          <Space direction="vertical" block className={styles.ecards}>
            {ecards.map((ecard) => (
              <Checkbox
                className={styles.ecard}
                key={ecard.uniqueId}
                disabled={notAvailable(ecard)}
                checked={!!selectedCerts.find((c) => c === ecard.uniqueId)}
                onChange={toggleCert(ecard)}
              >
                {notAvailable(ecard) ? (
                  <Popover
                    content={notAvailable(ecard)}
                    trigger="click"
                    placement="top"
                  >
                    <span>{ecard.ecardName}</span>
                  </Popover>
                ) : (
                  <span>{ecard.ecardName}</span>
                )}
              </Checkbox>
            ))}
          </Space>
        )}
      </div>
      <div className={styles.bottom}>
        <Badge content={cart.length}>
          <Link to={'/cart'}>
            <Image
              src={require('../../assets/images/cart.png')}
              width={40}
              className={styles.cart}
            />
          </Link>
        </Badge>
        <div className={styles.buttons}>
          <Button
            className={styles.addToCart}
            inline
            disabled={selectedCerts.length === 0}
            loading={addToCartLoading}
            onClick={addToCart}
          >
            加入购物车
          </Button>
          <Button
            className={styles.checkout}
            inline
            disabled={cart.length === 0 && selectedCerts.length === 0}
            onClick={checkout}
            loading={buyCardsLoading}
          >
            立即购买
          </Button>
        </div>
      </div>
    </div>
  );
};
