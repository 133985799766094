import React, { useState, useEffect } from 'react'
import { Checkbox } from 'antd';
import { WhiteSpace, WingBlank } from 'antd-mobile';
import CardLayout from '../DiveMasterForm/CardLayout'
import { FormattedMessage, useIntl } from 'react-intl';

const RequiredProjects = ({ setStatus, status, formStatus }) => {

  const [projects, setProjects] = useState({
    project1: !!formStatus,
    project2: !!formStatus, 
    project3: !!formStatus,
    project4: !!formStatus,
    project5: !!formStatus
  })
  const { formatMessage } = useIntl()

  useEffect(() => {
    let isProjectsComplete = projects.project1 && 
      projects.project2 && 
      projects.project3 && 
      projects.project4 && 
      projects.project5
    setStatus(isProjectsComplete)
  }, [projects])

  useEffect(() => {
    if(formStatus) setStatus(true)
  }, [formStatus])

  return <CardLayout title={formatMessage({ id: 'BMI-profects' })} status={status}>
    <WingBlank>
      <WhiteSpace />
      <Checkbox
        checked={projects.project1}
        disabled={formStatus !== null} 
        onChange={e => setProjects({...projects, project1: e.target.checked})}
      >
        <FormattedMessage id="BMI-profect1" />
      </Checkbox>
      <WhiteSpace />
      <Checkbox 
        checked={projects.project2}
        disabled={formStatus !== null} 
        onChange={e => setProjects({...projects, project2: e.target.checked})}
      >
        <FormattedMessage id="BMI-profect2" />
      </Checkbox>
      <WhiteSpace />
      <Checkbox 
        checked={projects.project3}
        disabled={formStatus !== null} 
        onChange={e => setProjects({...projects, project3: e.target.checked})}
      >
        <FormattedMessage id="BMI-profect3" />
      </Checkbox>
      <WhiteSpace />
      <Checkbox 
        checked={projects.project4}
        disabled={formStatus !== null} 
        onChange={e => setProjects({...projects, project4: e.target.checked})}
      >
        <FormattedMessage id="BMI-profect4" />
      </Checkbox>
      <WhiteSpace />
      <Checkbox 
        checked={projects.project5}
        disabled={formStatus !== null} 
        onChange={e => setProjects({...projects, project5: e.target.checked})}
      >
        <FormattedMessage id="BMI-profect5" />
      </Checkbox>
      <WhiteSpace />
    </WingBlank>
  </CardLayout>
}

export default RequiredProjects