export const storeLevel = [
  {
    name_sc: "潜水中心",
    name_tc: "潛水中心",
    name_en: "Dive Center"
  },
  {
    name_sc: "潜水度假村",
    name_tc: "潛水度假村",
    name_en: "Dive Resort"
  },
  {
    name_sc: "五星级潜水中心",
    name_tc: "五星級潛水中心",
    name_en: "5 Star Dive Center"
  },
  {
    name_sc: "五星级潜水度假村",
    name_tc: "五星級潛水度假村",
    name_en: "5 Star Dive Resort"
  },
  {
    name_sc: "五星教练发展中心",
    name_tc: "五星教練發展中心",
    name_en: "5 Star Instructor Development Dive Center"
  },
  {
    name_sc: "五星教练发展度假村",
    name_tc: "五星教練發展度假村",
    name_en: "5 Star  Instructor Development Dive Resort"
  },
  {
    name_sc: "分店 - 潜水中心",
    name_tc: "分店 - 潛水中心",
    name_en: "Secondary store - Dive Center"
  },
  {
    name_sc: "分店 - 潜水度假村",
    name_tc: "分店 - 潛水度假村",
    name_en: "Secondary store - Dive Resort"
  },
  {
    name_sc: "分店 - 五星潜水中心",
    name_tc: "分店 - 五星潛水中心",
    name_en: "Secondary store - 5 Star Dive Center"
  },
  {
    name_sc: "分店 - 五星潜水度假村",
    name_tc: "分店 - 五星潛水度假村",
    name_en: "Secondary store - 5 Star Dive Resort"
  },
  {
    name_sc: "分店 - 五星教练发展中心",
    name_tc: "分店 - 五星教練發展中心",
    name_en: "Secondary store - 5 Star Instructor Development Dive Center"
  },
  {
    name_sc: "分店 - 五星教练发展度假村",
    name_tc: "分店 - 五星教練發展度假村",
    name_en: "Secondary store - 5 Star  Instructor Development Dive Resort"
  },
  {
    name_sc: "EFR 中心",
    name_tc: "EFR 中心",
    name_en: "EFR Center"
  },
  {
    name_sc: "自由潜水中心",
    name_tc: "自由潛水中心",
    name_en: "Freediver Center"
  },
  {
    name_sc: "美人鱼中心",
    name_tc: "美人魚中心",
    name_en: "Mermaid Center"
  },
  {
    name_sc: "技术休闲潜水中心",
    name_tc: "技術休閒潛水中心",
    name_en: "TecRec Center"
  },
  {
    name_sc: "五星级职业发展中心",
    name_tc: "五星級職業發展中心",
    name_en: "5 STAR Career Development Center"
  },
  {
    name_sc: "生态潜水中心",
    name_tc: "生態潛水中心",
    name_en: "Eco Center"
  },
  {
    name_sc: "适性服务机构",
    name_tc: "適性服務機構",
    name_en: "Adaptive Service Facility"
  },
  {
    name_sc: "潜水船",
    name_tc: "潛水船",
    name_en: "Boat"
  },
  {
    name_sc: "休闲机构",
    name_tc: "休閒機構",
    name_en: "Recreation Facility"
  },
  {
    name_sc: "教学机构",
    name_tc: "教學機構",
    name_en: "Educational Facility"
  },
  {
    name_sc: "游泳学校",
    name_tc: "游泳學校",
    name_en: "Swimming School"
  }
]