import {
  postService,
  authedGetService,
  authedPostService,
  useRequestCommon,
} from '../hooks';
import * as config from '../config';
import { useContext } from 'react';
import { Context } from '../../components/IntlWrapper';

export const useLanguage = () => useContext(Context).language;

export const useGetEmailVerificationCode = (options) =>
  useRequestCommon(
    (email) => postService(config.getEmailCode, { email }),
    options
  );

export const useVerifyEmail = (options) =>
  useRequestCommon(
    ({ email, code }) => postService(config.verifyEmailCode, { email, code }),
    options
  );

export const useGetMobileVerificationCode = (options) =>
  useRequestCommon(
    ({ countryCode, mobile }) =>
      postService(config.getMobileCode, { countryCode, mobile }, 'text'),
    options
  );

export const useVerifyMobile = (options) =>
  useRequestCommon(
    ({ code, countryCode, mobile, email }) =>
      postService(config.verifyMobileCode, {
        code,
        countryCode,
        mobile,
        email,
      }),
    options
  );

export const useSaveProfile = (options) =>
  useRequestCommon(
    (profile) => postService(config.saveOrUpdateProfile, profile),
    options
  );


export const useEmailLogin = (options) =>
  useRequestCommon(
    ({username, password}) => authedPostService(config.emailLogin, {username, password}),
    {manual: true, ...options}
  );

export const usePhoto = (id = '', options) =>
  useRequestCommon(
    () => authedGetService(config.userPhoto + id, {}, 'blob'), {
      manual: false,
      ...options
    }
  );