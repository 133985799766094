import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { List, WingBlank } from 'antd-mobile';
import CardLayout from '../DiveMasterForm/CardLayout'
import { FormattedMessage, useIntl } from 'react-intl';
import UploadOssFile from '../../components/common/UploadOssFile'
import UploadFileWrapper from '../../components/common/UploadFileWrapper'
import { findMasterFD, findEfriCert, findOwsi, findFDI } from '../../components/common/findCerts'
import { uploadAFDIOssFile, removeAFDIFile } from '../../reducers/afdi'
import OwsiAndPrepare from './OwsiAndPrepare'
import FdiAndPrepare from './FdiAndPrepare'

const Item = List.Item

const CheckCerts = ({ 
  efrifiles,
  mfdfiles,
  editable, 
  fdiFiles,
  fdipreFiles,
  complete }) => {

  const { certifications, ossSignature, credentials } = useSelector((state) => ({
    certifications: state.data.certifications,
    credentials : state.data.credentials,
    ossSignature: state.oss.ossSignature
  }));
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const filename = (type) => `${ossSignature.dir}${type}/${new Date().getTime()}`

  return <CardLayout title={<FormattedMessage id="dm-form-required-certs" />} status={complete}>
  
    {/*
      证书要求：三选一 + EFRI + PADI MFD 或 持有其他组织的MFD
      PADI FDI
      PADI OWSI + 完成 PADI 自由潜水员教练预备课程
      其他组织自由潜水员教练证书 + 完成 PADI 自由潜水员教练预备课程
    */}

    {findFDI(credentials) ? (
      <Item>
        <FormattedMessage id="afdi-padi-fdi" /> <FormattedMessage id="afdi-satisfy" />
      </Item>
    ) : findOwsi(credentials) ? (
      <OwsiAndPrepare fdifiles={fdiFiles} editable={editable} />
    ) : (
      <FdiAndPrepare 
        fdiFiles={fdiFiles}
        fdipreFiles={fdipreFiles} 
        editable={editable} />
    )}    
    {/* mfd */}
    {findMasterFD(certifications) ? <Item>
      <FormattedMessage id="fdi-master-cert" /> {`（${findMasterFD(certifications).studentNumber}）`} 
    </Item> : <UploadFileWrapper 
      labelName={formatMessage({ id: 'afdi-master-fd' })} 
      editable={editable}>
      <UploadOssFile
        fileList={mfdfiles || []}
        fileName={filename('mfd')}
        onChange={(formData) => {
          dispatch(uploadAFDIOssFile({ formData }))
        }}
        onDelete={(index) => dispatch(removeAFDIFile(mfdfiles[index]))}
        editable={editable} />
      {editable && <WingBlank>
        <div className="small-font gray">
          <FormattedMessage id="common-upload-file-reminder" />
        </div>
      </WingBlank>}
    </UploadFileWrapper>}
    {/* efri */}
    {findEfriCert(credentials) ? <Item>
      EFRI 
    </Item> : <UploadFileWrapper 
      labelName={"EFRI"} 
      editable={editable}>
      <UploadOssFile
        fileList={efrifiles || []}
        fileName={filename('efri')}
        onChange={(formData) => {
          dispatch(uploadAFDIOssFile({ formData }))
        }}
        onDelete={(index) => dispatch(removeAFDIFile(efrifiles[index]))}
        editable={editable} />
      {editable && <WingBlank>
        <div className="small-font gray">
          <FormattedMessage id="common-upload-file-reminder" />
        </div>
      </WingBlank>}
    </UploadFileWrapper>}
  </CardLayout>
}

export default CheckCerts