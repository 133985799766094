import { Flex } from 'antd-mobile';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useScroll } from 'ahooks';
import React, { useRef } from 'react';

import { getDivingHistory } from '../../components/common/utils';
import { isMainlandUser } from '../../utils/user';
import AdsBanner from './AdsBanner';
import { useGetUserAdsList } from '../../services/hooks'

import './UserHeader.css';

const getAvatar = (profile) =>
  profile?.wechat?.headimgurl ||
  'https://assets.padi.com.cn/assets/images/avatar.jpg';

const PWInfo = ({pwCode}) => pwCode ? (
  <p>
    感谢您成为 PADI
    <span className="torcher">
      {pwCode}
    </span>
    号海洋守蔚者
  </p>
) : (
  <p>
    传递海洋环保理念，共同守护海洋家园，
    希望您成为“
    <Link to="/buy_cards" style={{ color: '#ea3340' }}>
      PADI 海洋守蔚者
    </Link>
    ”
  </p>
)

export default function UserHeader({ ecards, profile, userInfo }) {
  let headerStyle = {
    fixedClass: 'user-info',
    fixedContentClass: 'header-info',
  };

  const diverInfoRef = useRef();
  const adsRef = useRef();
  const { data } = useGetUserAdsList();

  const scroll = useScroll(document, (val) => val.top < 600 && val.top > 0);
  if (scroll && diverInfoRef.current && adsRef.current) {
    const { top } = scroll;
    const adsHeight = adsRef.current.offsetHeight;
    if (top >= adsHeight - 32) {
      headerStyle = {
        fixedClass: 'user-info fixed-top',
        fixedContentClass: 'header-info header-info-fixed',
      };
    } else {
      headerStyle = {
        fixedClass: 'user-info',
        fixedContentClass: 'header-info',
      };
    }
  }

  const getBannerData = () => {
    return data.filter(item => item.type.indexOf('banner') === 0)
  }

  return (
    <>
      <div>
        <img
          className={'user-header-bg'}
          src={require('../../assets/images/profile-header.jpg')}
          alt="bg"
        />
      </div>
      {/* {data && data.length > 0 && <div ref={adsRef}>
        <AdsBanner bannerList={getBannerData()} />
      </div>} */}
      <div ref={diverInfoRef} className={headerStyle.fixedClass}>
          <img
            src={getAvatar(profile)}
            alt="avatar"
          />
          <div className="name">
            {profile?.wechat?.nickname || (
              <FormattedMessage id="userHeader-userHeaderMsg" />
            )}
          </div>
          <div className={headerStyle.fixedContentClass}>
            <p>
              <FormattedMessage
                id="userHeader-userheaderamount"
                values={{
                  year: (
                    <span className="user_header_year">
                      {getDivingHistory(ecards) || ''}
                    </span>
                  ),
                  certAmount: (
                    <span className="user_header__count">{ecards.length}</span>
                  ),
                  count: ecards.length > 1 ? 's' : '',
                }}
              />
            </p>
            {isMainlandUser(userInfo) && <PWInfo pwCode={profile.publicWelfareCertificateCode} />}
          </div>
        {/* </div> */}
        {/* </div> */}
      </div>
    </>
  );
}
