import React from 'react'
import { getParameterByName } from '../../components/common/utils';
import { useWxPayJssdk, useErrorModal } from '../../services/hooks'
import { useWechatUserInfo } from '../../services/hooks/wechat';

const RedirectedeUri = () => {

  const { runAsync: getWxPayJssdk } = useWxPayJssdk();
  const showErrDialog = useErrorModal();
  const { loading } = useWechatUserInfo(getParameterByName('code'), {
    onSuccess: (data) => {
      if (data ?.unionid ?.length > 27) {
        document.cookie = 'padiWxId=' + data.unionid;
        wxPayStart()
      } else {
        showErrDialog('系统出错，请稍后再试，谢谢您的理解。');
      }
    },
    onError: (error) => {
      console.error(error);
      showErrDialog('微信授权失败。');
    }
  });
  
  const wxPayStart = () => {
    const referer = getParameterByName('referer')
    const refererObj = JSON.parse(referer)
    if (refererObj) {
      const { redirectUrl, email, ...jssdkParams } = refererObj
      getWxPayJssdk(jssdkParams).then(res => {
        const { packageVal, ...otherRes } = res
        const sdkData = { ...otherRes, package: packageVal }
        if (typeof window.top.WeixinJSBridge == "undefined") {
          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', onBridgeReady(sdkData, refererObj), false);
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', onBridgeReady(sdkData, refererObj));
            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady(sdkData, refererObj));
          }
        } else {
          onBridgeReady(sdkData, refererObj);
        }
      })
    }
  }

  function onBridgeReady(jssdkData, refererObj) {
    const { redirectUrl, email, ...jssdkParams } = refererObj
    window.top.WeixinJSBridge.invoke('getBrandWCPayRequest', jssdkData,
      function (res) {
        console.log('pay res', res)
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          console.log('pay success!!', res)
          console.log('redirectUrl', redirectUrl)
          console.log('email', email)
          window.location.href = `${redirectUrl}&email=${encodeURIComponent(email)}&reference=${encodeURIComponent(jssdkParams.outTradeNo)}`
        }else {
          showErrDialog('微信支付失败。');
        }
      });
  }

  return <>支付中，请稍候...</>
}

export default RedirectedeUri