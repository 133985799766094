import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { InputItem, WhiteSpace } from 'antd-mobile';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import CardLayout from '../DiveMasterForm/CardLayout'
import Signature from '../../components/common/Signature'
import UploadOssFile from '../../components/common/UploadOssFile'
import UploadFileWrapper from '../../components/common/UploadFileWrapper'
import { formatDate,  filterTeachingLanguage } from "../../components/common/utils";
import SearchInstructor from '../../components/common/SearchInstructor';
import { Checkbox, WingBlank } from 'antd-mobile';
import SearchStore from '../../components/common/SearchStore';
import EFRConfirm from '../../components/common/EFRConfirm'
import { getOssSignature } from '../../reducers/oss'
import UserPhoto from '../../components/common/UserPhoto'
import { instructorApproved } from '../../services/api'
import DetailStatus from '../../components/common/DetailStatus'
import { usePayDm } from '../../services/hooks'

import styles from '../DiveMasterForm/DiveMasterForm.module.css'

const DMForInstructorPage = () => {
  const dispatch = useDispatch();

  const [agreement, setAgreement] = useState(false);
  const { state: { isStore } } = useLocation();
  const { runAsync: toPay } = usePayDm();

  const { instructorConfirmDetail } = useSelector(state => ({
    instructorConfirmDetail: state.ui.instructorConfirmDetail,
  }));

  useEffect(() => {
    dispatch(getOssSignature('DM'))
  }, []);

  const editable = !isStore && (instructorConfirmDetail.status === 'WAIT_CONFIRM' || instructorConfirmDetail.status === 'SUBMIT')

  if (!instructorConfirmDetail.userInfoDTO) return null;

  return <>
    <CardLayout title={<FormattedMessage id="dm-form-diver-info" />} status={"none"}>
      <div className={styles["diver-info"]}>
        <div>
          <InputItem
            type="text"
            editable={false}
            value={instructorConfirmDetail.userInfoDTO.englishName}
          >
            <FormattedMessage id="dm-form-name" />
          </InputItem>
          <InputItem
            type="text"
            editable={false}
            value={formatDate(instructorConfirmDetail.userInfoDTO.birthDate, 'YYYY-MM-DD')}
          >
            <FormattedMessage id="dm-form-birth" />
          </InputItem>
        </div>
        <UserPhoto id={instructorConfirmDetail.userInfoDTO.affiliateId} />
      </div>
    </CardLayout>
    <WhiteSpace />
    <CardLayout title={<FormattedMessage id="dm-form-required-certs" />} status={"none"}>
      <UploadFileWrapper
        labelName={instructorConfirmDetail.aow ? `AOW (${instructorConfirmDetail.aow})` : 'AOW'}
        editable={false}>
        <UploadOssFile
          fileList={instructorConfirmDetail.aowFiles || []}
          type={"AOW"}
          editable={false} />
      </UploadFileWrapper>
      <UploadFileWrapper
        labelName={instructorConfirmDetail.rescue ? `RESCUE (${instructorConfirmDetail.rescue})` : "RESCUE"}
        editable={false}>
        <UploadOssFile
          fileList={instructorConfirmDetail.rescueFiles || []}
          type={"RESCUE"}
          editable={false} />
      </UploadFileWrapper>
      <EFRConfirm appDetail={instructorConfirmDetail} />
    </CardLayout>
    <WhiteSpace />
    <CardLayout title={<FormattedMessage id="dm-form-training-info" />} status={"none"}>
      {instructorConfirmDetail.instructorInfoDTO && <SearchInstructor
        id={"instructor"}
        editable={false}
        instructor={instructorConfirmDetail.instructorInfoDTO}
        instructorNumber={instructorConfirmDetail.instructorInfoDTO.instructorNumber}
      />}
      {instructorConfirmDetail.storeInfoDTO && <SearchStore
        editable={false}
        store={instructorConfirmDetail.storeInfoDTO}
        storeNumber={instructorConfirmDetail.storeInfoDTO.storeNumber}
      />}
      <InputItem
        type="text"
        editable={false}
        value={instructorConfirmDetail.finishTime}
      >
        <FormattedMessage id="dm-form-complete-date" />
      </InputItem>
    </CardLayout>
    {instructorConfirmDetail.languageId && <>
      <WhiteSpace />
      <CardLayout title={<FormattedMessage id="dm-region-and-lang-title" />} status={"none"}>
        {instructorConfirmDetail.regionDTO && <InputItem
          type="text"
          editable={false}
          value={instructorConfirmDetail.regionDTO.region}
        >
          <FormattedMessage id="dm-region" />
        </InputItem>}
        <InputItem
          type="text"
          editable={false}
          value={filterTeachingLanguage(instructorConfirmDetail.languageId)}
        >
          <FormattedMessage id="dm-lang-title" />
        </InputItem>
      </CardLayout>
    </>}
    {editable && <>
      <WhiteSpace />
      <Signature
        modified={editable}
        filePath={`${instructorConfirmDetail.instructorInfoDTO.email}/${instructorConfirmDetail.instructorInfoDTO.affiliateId}`} />
    </>}
    <WhiteSpace size="lg" />
    {editable && <>
      <WingBlank>
        <Checkbox.AgreeItem checked={agreement} onChange={e => setAgreement(e.target.checked)}>
          <FormattedMessage id="dm-form-instructor-agreement" />
        </Checkbox.AgreeItem>
      </WingBlank>
      <WhiteSpace size="lg" />
    </>}
    <DetailStatus
      detail={instructorConfirmDetail}
      approveFn={instructorApproved}
      payFn={toPay}
      listUrl="/confirmApps"
      isStore={isStore}
      agreement={agreement} 
      payApplicationType="MEMBER_APPLICATION_DM" />
    <WhiteSpace size="lg" />
  </>
}

export default DMForInstructorPage