import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { List } from 'antd-mobile';
import { getCollectionList } from '../../../services/api'
import { formatDate, showModal, getParameterByName } from '../../../components/common/utils';
import { useIntl } from 'react-intl';

const Item = List.Item;

const PicTasksList = () => {

  const [tasksList, setTasksList] = useState([])
  const { push } = useHistory()
  const { formatMessage } = useIntl()

  useEffect(() => {
    resquestTasksList()
  },[])

  const resquestTasksList = async () => {
    try {
      const tasksList = await getCollectionList()
      if(tasksList.data) {
        setTasksList(tasksList.data)
        return
      }
      if(tasksList.error) {
        if(tasksList.error.code === 'auth.required' || tasksList.error.code === 'sys.auth.permission.denied') {
          const referer = getParameterByName('referer');
          const redirectUrl = referer ? referer : encodeURIComponent(window.location.href);
          showModal(
            '', 
            tasksList.error.clientMsg, 
            () => { push(`/emailLogin?referer=${redirectUrl}`) }, 
            () => {}, 
            formatMessage({id: 'common-comfirmBtn'}), 
            formatMessage({id: 'common-cancel'})
          );
          return
        }else {
          showModal(
            '', 
            '系统出错，请稍后再试，谢谢您的理解。', 
            () => {}, 
            () => {}, 
            formatMessage({id: 'common-comfirmBtn'}), 
            formatMessage({id: 'common-cancel'})
          );
          return
        }
      }
    }finally {
      console.log('done')
    }
  }

  return <List renderHeader={() => '任务列表'}>
    {tasksList.map(item => <Item arrow="horizontal" onClick={() => push(`/admin/collection/task_detail_list?alias=${item.alias}`, { taskName: item.title })}>
      <div>
        <span>任务名：</span>
        {item.title}
      </div>
      <div>
        <span>更新时间：</span>
        {formatDate(item.updateDate, 'YYYY-MM-DD')}
      </div>
      <div>
        <span>参加总人数 / 图片数： </span>
        {item.peopleTotal} / {item.pictureTotal}
      </div>
    </Item>)}
  </List>
}

export default PicTasksList