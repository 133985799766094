import { combineReducers } from 'redux'
import {createSlice} from "@reduxjs/toolkit";

export const basicMermaidDetailSlice = createSlice({
  name: "basicMermaidDetail",
  initialState: null,
  reducers: {
    basicMermaidDetailRequest: {
      reducer(){
        return {loading: true}
      }
    },
    basicMermaidDetailSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    basicMermaidDetailFailed: {
      reducer(){
        return {}
      }
    },
    uploadBasicMermaidOssFile: {
      reducer(state){
        return state
      }
    },
    uploadBasicMermaidOssFileRequest: {
      reducer(state){
        return state
      }
    },
    uploadBasicMermaidOssFileSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return state
      }
    },
    uploadBasicMermaidOssFileFailed: {
      reducer(state){
        return state
      }
    },
    getBasicMermaidOssFileRequest: {
      reducer(state){
        return state
      }
    },
    getBasicMermaidOssFileSuccess: {
      reducer(state, action) {
        const data = action.payload;
        const url = data.replace('https://files.padi.com.cn/', '')
        const certType = url.split('/')[2]
        if(certType === 'efr') {
          const { efrfiles, ...detail } = state
          return { efrfiles: efrfiles ? [ data, ...efrfiles ] : [data], ...detail }
        }else if(certType === 'mermaid') {
          const { padiadvancedMermaidFiles, ...detail } = state
          return { padiadvancedMermaidFiles: padiadvancedMermaidFiles ? [ data, ...padiadvancedMermaidFiles ] : [data], ...detail }
        }else if(certType === 'other') {
          const { otherFiles, ...detail } = state
          return { otherFiles: otherFiles ? [ data, ...otherFiles ] : [data], ...detail }
        }
      }
    },
    getBasicMermaidOssFileFailed: {
      reducer(state){
        return state
      }
    },
    removeBasicMermaidFile: {
      reducer(state, action) {
        const data = action.payload;
        const url = data.replace('https://files.padi.com.cn/', '')
        const certType = url.split('/')[2]
        if(certType === 'efr') {
          const { efrfiles, ...detail } = state
          const newEfrfiles = efrfiles.filter(item => item !== data)
          return { efrfiles: newEfrfiles, ...detail }
        }else if(certType === 'mermaid') {
          const { padiadvancedMermaidFiles, ...detail } = state
          const newMermaidfiles = padiadvancedMermaidFiles.filter(item => item !== data)
          return { padiadvancedMermaidFiles: newMermaidfiles, ...detail }
        }else if(certType === 'other') {
          const { otherFiles, ...detail } = state
          const newOtherFiles = otherFiles.filter(item => item !== data)
          return { otherFiles: newOtherFiles, ...detail }
        }
      }
    }
  }
})

export const { 
  basicMermaidDetailRequest,
  basicMermaidDetailSuccess,
  basicMermaidDetailFailed,
  uploadBasicMermaidOssFile,
  uploadBasicMermaidOssFileRequest,
  uploadBasicMermaidOssFileSuccess,
  uploadBasicMermaidOssFileFailed,
  getBasicMermaidOssFileRequest,
  getBasicMermaidOssFileSuccess,
  getBasicMermaidOssFileFailed,
  removeBasicMermaidFile } = basicMermaidDetailSlice.actions

export const basicMermaidDetail = {
  request: (data) => basicMermaidDetailRequest(data),
  success: (data) => basicMermaidDetailSuccess(data),
  failure: (error) => basicMermaidDetailFailed(error),
}

export const uploadBasicMermaidAttachFile = {
  request: () => uploadBasicMermaidOssFileRequest(),
  success: (data) => uploadBasicMermaidOssFileSuccess(data),
  failure: (error) => uploadBasicMermaidOssFileFailed(error),
}

export const getBasicMermaidOssFile = {
  request: () => getBasicMermaidOssFileRequest(),
  success: (data) => getBasicMermaidOssFileSuccess(data),
  failure: (error) => getBasicMermaidOssFileFailed(error),
}

export const basicMermaidTListSlice = createSlice({
  name: "basicMermaidTList",
  initialState: {},
  reducers: {
    skipBasicMermaidTListPage: {
      reducer(state, action) {
        return state
      }
    },
    basicMermaidTListRequest: {
      reducer(state){
        return state
      }
    },
    basicMermaidTListSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    basicMermaidTListFailed: {
      reducer(state){
        return state
      }
    }
  }
});

export const { basicMermaidTListRequest, basicMermaidTListSuccess, basicMermaidTListFailed, skipBasicMermaidTListPage } = basicMermaidTListSlice.actions

export const getBasicMermaidTListRequest = {
  request: () => basicMermaidTListRequest(),
  success: (data) => basicMermaidTListSuccess(data),
  failure: (error) => basicMermaidTListFailed(error),
}

export const basicMermaidTDetailSlice = createSlice({
  name: "basicMermaidTDetail",
  initialState: {},
  reducers: {
    basicMermaidTDetailRequest: {
      reducer(){
        return {}
      }
    },
    basicMermaidTDetailSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    basicMermaidTDetailFailed: {
      reducer(){
        return {}
      }
    }
  }
})

export const { 
  basicMermaidTDetailRequest, 
  basicMermaidTDetailSuccess, 
  basicMermaidTDetailFailed
} = basicMermaidTDetailSlice.actions

export const basicMermaidTDetail = {
  request: () => basicMermaidTDetailRequest(),
  success: (data) => basicMermaidTDetailSuccess(data),
  failure: (error) => basicMermaidTDetailFailed(error),
}

export const mermaidDetailSlice = createSlice({
  name: "mermaidDetail",
  initialState: null,
  reducers: {
    mermaidDetailRequest: {
      reducer(){
        return {loading: true}
      }
    },
    mermaidDetailSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    mermaidDetailFailed: {
      reducer(){
        return {}
      }
    },
    uploadMermaidOssFile: {
      reducer(state){
        return state
      }
    },
    uploadMermaidOssFileRequest: {
      reducer(state){
        return state
      }
    },
    uploadMermaidOssFileSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return state
      }
    },
    uploadMermaidOssFileFailed: {
      reducer(state){
        return state
      }
    },
    getMermaidOssFileRequest: {
      reducer(state){
        return state
      }
    },
    getMermaidOssFileSuccess: {
      reducer(state, action) {
        const data = action.payload;
        const url = data.replace('https://files.padi.com.cn/', '')
        const certType = url.split('/')[2]
        if(certType === 'efr') {
          const { efrfiles, ...detail } = state
          return { efrfiles: efrfiles ? [ data, ...efrfiles ] : [data], ...detail }
        }else if(certType === 'mermaid') {
          const { padiadvancedMermaidFiles, ...detail } = state
          return { padiadvancedMermaidFiles: padiadvancedMermaidFiles ? [ data, ...padiadvancedMermaidFiles ] : [data], ...detail }
        }else if(certType === 'other') {
          const { otherFiles, ...detail } = state
          return { otherFiles: otherFiles ? [ data, ...otherFiles ] : [data], ...detail }
        }
      }
    },
    getMermaidOssFileFailed: {
      reducer(state){
        return state
      }
    },
    removeMermaidFile: {
      reducer(state, action) {
        const data = action.payload;
        const url = data.replace('https://files.padi.com.cn/', '')
        const certType = url.split('/')[2]
        if(certType === 'efr') {
          const { efrfiles, ...detail } = state
          const newEfrfiles = efrfiles.filter(item => item !== data)
          return { efrfiles: newEfrfiles, ...detail }
        }else if(certType === 'mermaid') {
          const { padiadvancedMermaidFiles, ...detail } = state
          const newMermaidfiles = padiadvancedMermaidFiles.filter(item => item !== data)
          return { padiadvancedMermaidFiles: newMermaidfiles, ...detail }
        }else if(certType === 'other') {
          const { otherFiles, ...detail } = state
          const newOtherFiles = otherFiles.filter(item => item !== data)
          return { otherFiles: newOtherFiles, ...detail }
        }
      }
    }
  }
})

export const { 
  mermaidDetailRequest,
  mermaidDetailSuccess,
  mermaidDetailFailed,
  uploadMermaidOssFile,
  uploadMermaidOssFileRequest,
  uploadMermaidOssFileSuccess,
  uploadMermaidOssFileFailed,
  getMermaidOssFileRequest,
  getMermaidOssFileSuccess,
  getMermaidOssFileFailed,
  removeMermaidFile } = mermaidDetailSlice.actions

export const mermaidDetail = {
  request: (data) => mermaidDetailRequest(data),
  success: (data) => mermaidDetailSuccess(data),
  failure: (error) => mermaidDetailFailed(error),
}

export const uploadMermaidAttachFile = {
  request: () => uploadMermaidOssFileRequest(),
  success: (data) => uploadMermaidOssFileSuccess(data),
  failure: (error) => uploadMermaidOssFileFailed(error),
}

export const getMermaidOssFile = {
  request: () => getMermaidOssFileRequest(),
  success: (data) => getMermaidOssFileSuccess(data),
  failure: (error) => getMermaidOssFileFailed(error),
}

export const mermaidTListSlice = createSlice({
  name: "mermaidTList",
  initialState: {},
  reducers: {
    skipMermaidTListPage: {
      reducer(state, action) {
        return state
      }
    },
    mermaidTListRequest: {
      reducer(state){
        return state
      }
    },
    mermaidTListSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    mermaidTListFailed: {
      reducer(state){
        return state
      }
    }
  }
});

export const { mermaidTListRequest, mermaidTListSuccess, mermaidTListFailed, skipMermaidTListPage } = mermaidTListSlice.actions

export const getMermaidTListRequest = {
  request: () => mermaidTListRequest(),
  success: (data) => mermaidTListSuccess(data),
  failure: (error) => mermaidTListFailed(error),
}

export const mermaidTDetailSlice = createSlice({
  name: "mermaidTDetail",
  initialState: {},
  reducers: {
    mermaidTDetailRequest: {
      reducer(){
        return {}
      }
    },
    mermaidTDetailSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    mermaidTDetailFailed: {
      reducer(){
        return {}
      }
    }
  }
})

export const { 
  mermaidTDetailRequest, 
  mermaidTDetailSuccess, 
  mermaidTDetailFailed
} = mermaidTDetailSlice.actions

export const mermaidTDetail = {
  request: () => mermaidTDetailRequest(),
  success: (data) => mermaidTDetailSuccess(data),
  failure: (error) => mermaidTDetailFailed(error),
}

const mermaidReducer = combineReducers({
  basicMermaidDetail: basicMermaidDetailSlice.reducer,
  basicMermaidTList: basicMermaidTListSlice.reducer,
  basicMermaidTDetail: basicMermaidTDetailSlice.reducer,
  mermaidiDetail: mermaidDetailSlice.reducer,
  mermaidTList: mermaidTListSlice.reducer,
  mermaidTDetail: mermaidTDetailSlice.reducer,
})

export default mermaidReducer