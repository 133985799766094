import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { InputItem, Modal } from 'antd-mobile';
import { isNumber, showAlert } from '../../components/common/utils'
import { useIntl } from 'react-intl';
import { clearStoreInfo } from '../../reducers/ui'

const prompt = Modal.prompt;

const SearchInput = ({ type, editable, placeholder, text, searchFn, searchInputVal, children }) => {

  const [inputVal, setInputVal] = useState('')
  const intl = useIntl()
  const dispatch = useDispatch()

  useEffect(() => {
    if(searchInputVal) setInputVal(searchInputVal)
  }, [searchInputVal])

  const clickInput = () => {
    if(!editable) return
    prompt(intl.formatMessage({id: 'common-search-title'}), placeholder, [
      { text: intl.formatMessage({id: 'common-cancel'}) },
      { text: intl.formatMessage({id: 'common-comfirmBtn'}), onPress: value => searchInfo(value) },
    ])
  }

  const searchInfo = (value) => {
    if(!isNumber(value) && type === 'number') {
      showAlert(intl.formatMessage({id: 'common-search-error'}), intl.formatMessage({id: 'common-close'}));
      return
    }
    // dispatch(clearStoreInfo())
    setInputVal(value); 
    searchFn(value)
  }

  return <>
    <InputItem
      clear
      editable={editable}
      placeholder={placeholder}
      value={inputVal || ''}
      onClick={() => clickInput()}
    >
      {text}
    </InputItem>
    {children}
  </>
}

export default SearchInput