import { NoticeBar } from 'antd-mobile';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const NoticeBarForEcards = ({ noticeHide, noticeMessage }) => (
  <NoticeBar 
    mode="closable" 
    icon={null}
    marqueeProps={{ loop: true }}
    onClick={() => { noticeHide() }}>
    <FormattedMessage id={noticeMessage} />
  </NoticeBar>
)

export default NoticeBarForEcards