import React from 'react';
import { Flex, List } from 'antd-mobile';
import { formatMoney } from '../../components/common/utils';

import styles from '../PriceList/PriceList.module.css';

const UnconventionalOrderItem = ({ item }) => {
  return (
    <List.Item align="bottom" thumb={item.picUrl} wrap>
      {item.title}<List.Item.Brief>
      <Flex justify="between">
        <div>
          <span className={styles.price}>
                 {item.price ? formatMoney(item.price, item.currencyType) : ''}
          </span>
        </div>
        <span>{'x' + item.number}</span>
      </Flex>
    </List.Item.Brief>
    </List.Item>
  );
};

export default UnconventionalOrderItem;
