import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { push } from 'connected-react-router';
import { Button } from 'antd-mobile-v5'
import { WingBlank, WhiteSpace } from 'antd-mobile';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PaymentBtn from '../../components/FormTools/PaymentBtn'
import { usePayDm } from '../../services/hooks'

import styles from './DiveMasterForm.module.css'

const DMFormResultPage = () => {
  const history = useHistory();

  const { dmFormInfo } = useSelector(state => ({
    dmFormInfo: state.ui.dmFormInfo
  }));
  const dispatch = useDispatch();
  const { runAsync: toPay } = usePayDm();

  useEffect(() => {
    document.documentElement.scrollTop = document.body.scrollTop = 0
    if(Object.keys(dmFormInfo).length <= 0) {
      dispatch(push('/dmForm'))
    }
    document.documentElement.scrollTop = document.body.scrollTop =0;
  }, []);
  return Object.keys(dmFormInfo).length > 0 ? <>
    <div className={styles.applicationNo}>
      <div className={styles.applicationTitle}><FormattedMessage id="dm-form-applicationNo" /></div>
      <div className={styles.number}>{dmFormInfo.code}</div>
    </div>
    <WingBlank>
      <p><FormattedMessage id="dm-form-message-review" /></p><br/>
      <p><FormattedMessage id="dm-form-message-address" /></p><br/>
      <p><FormattedMessage id="dm-form-message-address-detail" /></p><br/>
    </WingBlank> 
    {!dmFormInfo.paid && <WingBlank>
      <PaymentBtn code={dmFormInfo.code} paymentFn={toPay} payApplicationType={'MEMBER_APPLICATION_DM'} />
    </WingBlank>}
    <WhiteSpace />
    <WingBlank>
      <Button color='primary' fill='outline' block onClick={() => history.replace('/myProfile')}>
        <FormattedMessage id="common-back" />
      </Button>
    </WingBlank>
  </> : null
}

export default DMFormResultPage