import React from 'react'
import { Button, Steps } from 'antd-mobile-v5'
import { WhiteSpace, WingBlank } from 'antd-mobile'
import { FormattedMessage, useIntl } from 'react-intl';

const { Step } = Steps

const StepStatus = ({ desc, note, status }) => {
  const { formatMessage } = useIntl()
  return <>
    <WhiteSpace size="xl" />
    <Steps
      current={1}
      direction='vertical'
      style={{
        '--title-font-size': '17px',
        '--description-font-size': '15px',
        '--indicator-margin-right': '12px',
        '--icon-size': '22px',
      }}>
      <Step
        title={formatMessage({ id: "form-current-status" }) + desc}
        description={note ? formatMessage({ id: "form-not-approved-reason" }) + note : ''}
        status={status || 'finish'} />
    </Steps>
    <WhiteSpace size="xl" />
  </>
}

const AppStatus = ({ status, form, submitting, note, paymentMethod, noPayment }) => {
  const { formatMessage } = useIntl()
  switch (status) {
    case 'WAIT_ADMIN_APPROVED':
      return paymentMethod
        ? <StepStatus desc={formatMessage({ id: "app-wait-cs-review" })} />
        : !noPayment 
          ? <StepStatus desc={formatMessage({ id: "app-wait-pay" })} />
          : <StepStatus desc={formatMessage({ id: "app-wait-cs-review" })} />
    case 'ADMIN_APPROVED':
      return <StepStatus desc={formatMessage({ id: "app-success" })} />
    case 'ADMIN_NOT_APPROVED':
      return <>
        <StepStatus desc={formatMessage({ id: "app-cs-not-approved" })} status={'error'} note={note || ''} />
        <WingBlank>
          <Button
            form={form}
            block
            type="submit"
            color="primary"
            loading={submitting}
            loadingText={formatMessage({ id: 'common-submit-loading' })}
          >
            <FormattedMessage id="app-resubmit" />
          </Button>
        </WingBlank>
      </>
    case 'SUCCESS':
      return <StepStatus desc={formatMessage({ id: "app-success" })} />
    default:
      return <WingBlank>
        <Button
          form={form}
          block
          type="submit"
          color="primary"
          loading={submitting}
          loadingText={formatMessage({ id: 'common-submit-loading' })}
        >
          <FormattedMessage id="common-confirmRequest" />
        </Button>
      </WingBlank>
  }
}

export default AppStatus
