import React from 'react'
import '../Avatar/Avatar.css';

export default ({ avatarImg }) => {
  return <div>
    <img 
      src={avatarImg ? avatarImg : require('../../assets/images/avatar.jpg')} 
      alt="avatar"
      style={{ width: '100%', borderRadius: '50%', border: '1px solid #fff' }} />
  </div>
}
