import React from 'react'
import { Form, Input } from 'antd-mobile-v5';

const CompanyOptions = () => {

  return <>
    <Form.Item
      name="invoiceHeader"
      label='抬頭'
      rules={[
        {
          required: true,
          message: '抬頭不能为空。',
        }
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="invoiceUnifiedNumbering"
      label='統一編號'
      rules={[
        {
          required: true,
          message: '統一編號不能为空。',
        }
      ]}
    >
      <Input />
    </Form.Item>
  </>
}

export default CompanyOptions