import React, { Component } from 'react';
import SetEmail from '../../components/ForgetPassword/SetEmail'
import { connect } from 'react-redux'
import { submitForgotPassword } from '../../actions';
import { FormattedMessage } from 'react-intl';

import './forgetPassword.css';

class ForgetPassword extends Component {

  render() {
    const { submitForgotPassword } = this.props
    return (
      <div className="home-page">
        <div className="sign-in"><FormattedMessage id="forgotPassword-reset" /></div>
        <SetEmail forgotPassword={submitForgotPassword} />
      </div>
    );
  }
}

export default connect(null, { submitForgotPassword })(ForgetPassword);
