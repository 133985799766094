import React from 'react'
import { Form, Input } from 'antd-mobile-v5';

const NaturalPersonOptions = ({ form }) => {
  return <>
    <Form.Item
      name="invoiceCitizenDigitalCertificate"
      label={'自然人憑證'}
      rules={[
        {
          required: true,
          message: '自然人憑證不能為空。',
        }
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="invoiceCitizenDigitalCertificateAgain"
      label={'再次確認自然人憑證'}
      rules={[
        {
          required: true,
          message: '請再次輸入自然人凭证。',
        },
        {
          validator: (_, value) => {
            if (!value || form.getFieldValue('invoiceCitizenDigitalCertificate') === value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error("兩次輸入自然人凭证不一致，請重新輸入。"));
          }
        }
      ]}
    >
      <Input />
    </Form.Item>
  </>
}

export default NaturalPersonOptions