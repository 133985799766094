import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { List } from 'antd';
import { formatDate, isiOS } from '../../components/common/utils'
import { getApiRoot, uploadExamAttempts } from '../../services/api'
import { pageLoaded, pageLoading } from '../../actions';

const IeExamAttempts = () => {

  const inputElem = useRef()
  const dispatch = useDispatch()
  const [currentIeId, setCurrentIeId] = useState()
  const { ieList } = useSelector(state => ({
    ieList: state.owsi.ieListForExaminer
  }));

  const downloadExcel = (ieId) => {
    window.location.href = `${getApiRoot()}/api/backstage/IEExaminer/download/IEApplicationExcel?ieId=${ieId}&secret=LJc150`
  }

  const uploadExcel = (ieId) => {
    setCurrentIeId(ieId)
    inputElem.current.click();
    if(isiOS()) inputElem.current.click();
  }

  const onChange = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file, file.name);
    try {
      dispatch(pageLoading());
      await uploadExamAttempts({formData, ieId: currentIeId});
    } catch (e) {
    } finally {
      dispatch(pageLoaded());
    }
  };

  return ieList && ieList.length > 0 ? <>
    <List
      size="large"
      header={<div>IE Exam List</div>}
      bordered
      dataSource={ieList}
      renderItem={item => (
        <List.Item 
          actions={[
            <a onClick={() => downloadExcel(item.id)}>Download</a>, 
            <a onClick={() => uploadExcel(item.id)}>Upload</a>
          ]}>
          {formatDate(item.ieDate, 'YYYY-MMM-DD')} {item.location}
        </List.Item>
      )}
    />
    <input 
      type="file" 
      name="image"
      ref={inputElem}
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      style={{display: 'none'}}
      onChange={(e) => onChange(e)} 
    />
  </> : null
}

export default IeExamAttempts