import React from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'antd-mobile';
import { useIntl } from 'react-intl';
import { getSubmitOssFormat, removeExpires } from '../../components/common/utils'
import { isMember } from '../../reducers/selectors'
import ApplicantDetailStatus from '../../components/common/ApplicantDetailStatus'
import { usePayOwsi, useSubmitOwsi } from '../../services/hooks'
import { useHistory } from 'react-router-dom';

const OwsiApplicationStatus = ({
  formData,
  owsiDetail,
  certStatus,
  checkAge,
  agreementChecked }) => {

  const { push } = useHistory()
  const { formatMessage } = useIntl();
  const { runAsync: submitOwsi, loading: submitting } = useSubmitOwsi();
  const { runAsync: toPay } = usePayOwsi();
  const { signatureUrl, ismember } = useSelector((state) => ({
    signatureUrl: state.ui.signatureUrl,
    profile: state.data.profile,
    userInfo: state.data.userInfo,
    ismember: isMember(state)
  }));

  const verifySubmit = () => {
    const {
      alternateLocation,
      cdinstructorNumber,
      storeNumber,
      storeType,
      languageId,
      regionId,
      ieId,
      ieExamModules,
      ieAttempt
    } = formData;
    const ieComplete = ieAttempt !== 1 ? !!(ieExamModules.length > 0) : true
    const regionStatus = ismember ? true : regionId
    return (
      (alternateLocation || storeNumber) &&
      cdinstructorNumber &&
      languageId &&
      regionStatus &&
      storeType &&
      signatureUrl &&
      certStatus &&
      checkAge &&
      ieId &&
      agreementChecked &&
      ieComplete
    );
  };

  const submit = () => {
    if (verifySubmit()) {
      const { regionId, ...data } = formData
      let submitData = {
        ...data,
        aidmfiles: formData.aidmfiles ? removeExpires(formData.aidmfiles) : [],
        aowfiles: formData.aowfiles ? removeExpires(formData.aowfiles) : [],
        efrfiles: formData.efrfiles ? removeExpires(formData.efrfiles) : [],
        owfiles: formData.owfiles ? removeExpires(formData.owfiles) : [],
        otherFiles: formData.otherFiles ? removeExpires(formData.otherFiles) : [],
        rescuefiles: formData.rescuefiles ? removeExpires(formData.rescuefiles) : [],
        handwrittenSignatureUrl: getSubmitOssFormat(signatureUrl),
      };
      if (!ismember) submitData.regionId = regionId
      submitOwsi(submitData).then(res => {
        if (res.code) {
          push('/application_submit_success?type=OWSI', { code: res.code, paid: res.paid, paymentType: 'MEMBER_APPLICATION_OWSI' });
        }
      })
    } else {
      Modal.alert(
        formatMessage({ id: 'dm-form-submit-error' }),
        formatMessage({ id: 'dm-form-submit-error-info' }),
        [{ text: formatMessage({ id: 'common-close' }) }]
      );
    }
  }

  return <ApplicantDetailStatus
    detail={owsiDetail}
    idType={'cd'}
    submitFn={submit}
    payFn={toPay}
    applicationType={'OWSI'}
    isOwsi={true}
    payApplicationType={'MEMBER_APPLICATION_OWSI'}
    loading={submitting}
  />
};

export default OwsiApplicationStatus;
