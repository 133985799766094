import React, { Component } from 'react';
import SetNewPassword from '../../components/ForgetPassword/SetNewPassword';
import { connect } from 'react-redux'
import { resetPassword } from '../../actions';
import { FormattedMessage } from 'react-intl';

import '../ForgetPassword/forgetPassword.css';


class ResetPassword extends Component {

  render() {
    return (
      <div className="home-page">
        <div className="sign-in"><FormattedMessage id="forgotPassword-reset" /></div>
        <SetNewPassword resetPassword={this.props.resetPassword} />
      </div>
    );
  }
}

export default connect(null, { resetPassword })(ResetPassword);
