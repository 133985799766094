import React from 'react';
import { FormattedMessage } from 'react-intl';

import './Cart.css';

const CartHeader = ({ total }) => (
  <div className="cart-header">
    <div className="cart-bg">
      <img src={require('../../assets/images/cart-bg.jpg')} alt="cart-bg" />
      <div className="cart-shelter"></div>
    </div>
    <div className="cart-top">
      <h2 className="cart-title">
        <FormattedMessage id="cardRequest-cart" />
      </h2>
      <p className="total">
        <FormattedMessage
          id="cardRequest-count"
          values={{
            number: <span className="cart-amount"> {total} </span>,
            padi: <span className="cart-amount"> PADI </span>,
            count: total > 1 ? 's' : '',
          }}
        />
      </p>
    </div>
  </div>
);

export default CartHeader;
