import React, {useEffect, useRef} from 'react';
import { isiOS } from './common/utils';
import { configWechatJSSDK } from './common/withWechat';

function HaiHangLink({ children }) {
  const haiHangRef = useRef(null);
  useEffect(() => {
    if(!isiOS()) {
      configWechatJSSDK();
    }
    const ready = (e) => { 
      console.log("HaiHang link ready.", e); 
    };
    const launch = () => { 
      console.log("HaiHang link launched."); 
    };
    const error = (e) => { 
      console.log("HaiHang link failed.", e); 
    };

    const current = haiHangRef.current;
    current.addEventListener('ready', ready);
    current.addEventListener('launch', launch);
    current.addEventListener('error', error);
    return () => {
      current.removeEventListener('ready', ready);
      current.removeEventListener('launch', launch);
      current.removeEventListener('error', error);
    };
  }, []);
  return (
    <wx-open-launch-weapp
      ref={haiHangRef}
      appid="wx56dbc76c7b340f86"
      username="gh_c6a749737032"
      id="haihang-launch-btn"
      path="/pages/common/pageH5/pageH5?isLogin=1&link=https%3A%2F%2Fm.hnair.com%2Fhnams%2FhnaFlyCard%2Fhome%3FpType%3Dqyj%26periods%3D60%26utm_source%3DFXT_OTA_PADI"
    >
      <script type="text/wxtag-template">
        {children}
      </script>
    </wx-open-launch-weapp>
  );
}

export default HaiHangLink;
