import { combineReducers } from 'redux'
import {createSlice} from "@reduxjs/toolkit";

export const AFDIDetailSlice = createSlice({
  name: "AFDIDetail",
  initialState: null,
  reducers: {
    AFDIDetailRequest: {
      reducer(){
        return {loading: true}
      }
    },
    AFDIDetailSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    AFDIDetailFailed: {
      reducer(){
        return {}
      }
    },
    uploadAFDIOssFile: {
      reducer(state){
        return state
      }
    },
    uploadAFDIOssFileRequest: {
      reducer(state){
        return state
      }
    },
    uploadAFDIOssFileSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return state
      }
    },
    uploadAFDIOssFileFailed: {
      reducer(state){
        return state
      }
    },
    getAFDIOssFileRequest: {
      reducer(state){
        return state
      }
    },
    getAFDIOssFileSuccess: {
      reducer(state, action) {
        const data = action.payload;
        const url = data.replace('https://files.padi.com.cn/', '')
        const certType = url.split('/')[2]
        if(certType === 'efri') {
          const { efrifiles, ...detail } = state
          return { efrifiles: efrifiles ? [ data, ...efrifiles ] : [data], ...detail }
        }else if(certType === 'fdi') {
          const { fdifiles, ...detail } = state
          return { fdifiles: fdifiles ? [ data, ...fdifiles ] : [data], ...detail }
        }else if(certType === 'teachingCerts') {
          const { teachingCountFiles, ...detail } = state
          return { teachingCountFiles: teachingCountFiles ? [ data, ...teachingCountFiles ] : [data], ...detail }
        }else if(certType === 'mfd') {
          const { mfdfiles, ...detail } = state
          return { mfdfiles: mfdfiles ? [ data, ...mfdfiles ] : [data], ...detail }
        }else if(certType === 'prepare') {
          const { fdipreFiles, ...detail } = state
          return { fdipreFiles: fdipreFiles ? [ data, ...fdipreFiles ] : [data], ...detail }
        }
      }
    },
    getAFDIOssFileFailed: {
      reducer(state){
        return state
      }
    },
    removeAFDIFile: {
      reducer(state, action) {
        const data = action.payload;
        const url = data.replace('https://files.padi.com.cn/', '')
        const certType = url.split('/')[2]
        if(certType === 'efri') {
          const { efrifiles, ...detail } = state
          const newEfrifiles = efrifiles.filter(item => item !== data)
          return { efrifiles: newEfrifiles, ...detail }
        }else if(certType === 'fdi') {
          const { fdifiles, ...detail } = state
          const newFdifiles = fdifiles.filter(item => item !== data)
          return { fdifiles: newFdifiles, ...detail }
        }else if(certType === 'teachingCerts') {
          const { teachingCountFiles, ...detail } = state
          const newTeachingFiles = teachingCountFiles.filter(item => item !== data)
          return { teachingCountFiles: newTeachingFiles, ...detail }
        }else if(certType === 'mfd') {
          const { mfdfiles, ...detail } = state
          const newMfdfiles = mfdfiles.filter(item => item !== data)
          return { mfdfiles: newMfdfiles, ...detail }
        }else if(certType === 'prepare') {
          const { fdipreFiles, ...detail } = state
          const newFdipreFiles = fdipreFiles.filter(item => item !== data)
          return { fdipreFiles: newFdipreFiles, ...detail }
        }
      }
    }
  }
})

export const { 
  AFDIDetailRequest,
  AFDIDetailSuccess,
  AFDIDetailFailed,
  uploadAFDIOssFile,
  uploadAFDIOssFileRequest,
  uploadAFDIOssFileSuccess,
  uploadAFDIOssFileFailed,
  getAFDIOssFileRequest,
  getAFDIOssFileSuccess,
  getAFDIOssFileFailed,
  removeAFDIFile } = AFDIDetailSlice.actions

export const AFDIDetail = {
  request: (data) => AFDIDetailRequest(data),
  success: (data) => AFDIDetailSuccess(data),
  failure: (error) => AFDIDetailFailed(error),
}

export const uploadAFDIAttachFile = {
  request: () => uploadAFDIOssFileRequest(),
  success: (data) => uploadAFDIOssFileSuccess(data),
  failure: (error) => uploadAFDIOssFileFailed(error),
}

export const getAFDIOssFile = {
  request: () => getAFDIOssFileRequest(),
  success: (data) => getAFDIOssFileSuccess(data),
  failure: (error) => getAFDIOssFileFailed(error),
}

export const AFDITListSlice = createSlice({
  name: "AFDITList",
  initialState: {},
  reducers: {
    skipAFDITListPage: {
      reducer(state, action) {
        return state
      }
    },
    AFDITListRequest: {
      reducer(state){
        return state
      }
    },
    AFDITListSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    AFDITListFailed: {
      reducer(state){
        return state
      }
    }
  }
});

export const { AFDITListRequest, AFDITListSuccess, AFDITListFailed, skipAFDITListPage } = AFDITListSlice.actions

export const getAFDITListRequest = {
  request: () => AFDITListRequest(),
  success: (data) => AFDITListSuccess(data),
  failure: (error) => AFDITListFailed(error),
}

export const AFDITDetailSlice = createSlice({
  name: "AFDITDetail",
  initialState: {},
  reducers: {
    AFDITDetailRequest: {
      reducer(){
        return {}
      }
    },
    AFDITDetailSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    AFDITDetailFailed: {
      reducer(){
        return {}
      }
    }
  }
})

export const { 
  AFDITDetailRequest, 
  AFDITDetailSuccess, 
  AFDITDetailFailed
} = AFDITDetailSlice.actions

export const AFDITDetail = {
  request: () => AFDITDetailRequest(),
  success: (data) => AFDITDetailSuccess(data),
  failure: (error) => AFDITDetailFailed(error),
}

const afdiReducer = combineReducers({
  AFDIDetail: AFDIDetailSlice.reducer,
  AFDITList: AFDITListSlice.reducer,
  AFDITDetail: AFDITDetailSlice.reducer,
})

export default afdiReducer