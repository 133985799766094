import React from 'react'
import { useSelector } from 'react-redux';
import { List, WingBlank } from 'antd-mobile';
// import { findEfrOrEfri, isEfrExpired } from './findCerts'
import { findEfrOrEfri } from '../../components/common/utils'
import UploadOssFile from './UploadOssFile'
import UploadFileWrapper from './UploadFileWrapper'
import { FormattedMessage } from 'react-intl';

const Item = List.Item

const UploadEfrOrEfri = ({ 
  uploadFn=() => {},
  removeFn=() => {},
  filename,
  editable,
  efrfiles
}) => {

  const { certifications, credentials } = useSelector((state) => ({
    certifications: state.data.certifications,
    credentials : state.data.credentials,
  }));
  // const { formatMessage } = useIntl()

  // const efrCertReview = () => {
  //   const cert = findEfrOrEfri(certifications, credentials)
  //   if(cert.studentNumber) {
  //     return cert.studentNumber
  //   }else {
  //     return formatMessage({ id: 'app-has-efri' })
  //   }
  // }

  return findEfrOrEfri(certifications, credentials) ? (
    <Item>
      <FormattedMessage id="app-efr-or-efri" /> {`（${findEfrOrEfri(certifications, credentials)}）`}
    </Item>
  ) : (
    <UploadFileWrapper 
      labelName={"EFR/EFRI"} 
      editable={editable}>
      <UploadOssFile
        fileList={efrfiles || []}
        fileName={filename}
        onChange={(formData) => uploadFn(formData)}
        onDelete={(index) => removeFn(index)}
        editable={editable} />
      {editable && <WingBlank>
        <div className="small-font gray">
          <FormattedMessage id="common-upload-file-reminder" />
        </div>
      </WingBlank>}
      {/* {isEfrExpired(certifications) && <WingBlank>
        <div className="small-font gray">
          <FormattedMessage id="dm-efr-expired" />
        </div>
      </WingBlank>} */}
    </UploadFileWrapper>
  )
}

export default UploadEfrOrEfri