import React from 'react'
import { Button, List } from 'antd-mobile-v5'
import { WhiteSpace } from 'antd-mobile'
import { FormattedMessage, useIntl } from 'react-intl';
import { useGetUnconventionalOrderDetail, useUnconventionalOrderPayment } from '../../services/hooks'
import { formatMoney, getParameterByName, showAlert } from '../../components/common/utils';
import OrderPrice from "../PriceList/OrderPrice";
import UnconventionalOrderItem from "./OrderItem";


const PaymentBtn = ({ id }) => {

  const { formatMessage } = useIntl()
  const { run, loading } = useUnconventionalOrderPayment({
    onSuccess: (data) => {
      if (data.error) {
        showAlert(
          data.error.clientMsg,
          formatMessage({ id: 'common-close' })
        );
        return
      }
      if (data.url) {
        window.location.replace(data.url)
      }
    }
  })

  return <>
    <WhiteSpace />
    <Button
      block
      color='primary'
      loading={loading}
      disabled={loading}
      onClick={() => run(id)}>
      <FormattedMessage id="app-to-pay" />
    </Button>
  </>
}

const UnconventionalOrderDetail = () => {

  const id = getParameterByName('id')
  const { loading, data } = useGetUnconventionalOrderDetail(id);

  const formatOrderData = () => {
    const { itemDTOS, ...details } = data
    const formatItems = itemDTOS.map(item => ({ ...item, currencyType: data.currencyType }))
    return { ...details, itemDTOS: formatItems }
  }

  if (!data || loading) return <>loading</>

  return <>
    <List header={<FormattedMessage id="items-list" />} className="price-items">
      {formatOrderData().itemDTOS.map((item) => (
        <UnconventionalOrderItem
          key={item.goodsId}
          item={item}
        />
      ))}
    </List>
    <OrderPrice
      labelId="item-price"
      value={formatMoney(data.itemsPrice, formatOrderData().itemDTOS[0].currencyType)}
    />
    <OrderPrice
      labelId="shipment-fee"
      value={formatMoney(data.expressPrice, formatOrderData().itemDTOS[0].currencyType)}
    />
    <OrderPrice
      labelId="total-price"
      value={formatMoney(data.totalPrice, formatOrderData().itemDTOS[0].currencyType)}
    />
    {data.status === 'WAIT_PAY' && <PaymentBtn id={data.id} />}
  </>
}

export default UnconventionalOrderDetail