import { FormattedMessage } from 'react-intl';
import { Image } from 'antd-mobile-v5';
import { useDispatch } from 'react-redux';
import React, { useRef } from 'react';

import { uploadPhoto } from '../../reducers/ui';
import { usePhoto } from '../../services/hooks/user';

import styles from './UploadPhoto.module.css';

const UploadPhoto = () => {
  const inputElem = useRef();
  const dispatch = useDispatch();
  const { data } = usePhoto();

  const onChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('imageFile', file, file.name);
    dispatch(uploadPhoto({ formData }));
  };

  return (
    <div className={styles.uploadBox}>
      <input
        type="file"
        name="image"
        ref={inputElem}
        className={styles.uploadInput}
        onChange={(e) => onChange(e)}
      />
      <Image src={data ? URL.createObjectURL(data) : ''} />
      <a className={styles['update-btn']}>
        <FormattedMessage id="certdetail-update-photo" />
      </a>
    </div>
  );
};

export default UploadPhoto;
