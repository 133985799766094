import { SUCCESS } from '../actions'
import * as ActionTypes from '../actions'
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { getCurrentGoods } from '../components/common/utils';
import commonReducer from './common';
import uiReducer from './ui';
import ossReducer from './oss'
import owsiReducer from './owsi';
import efriReducer from './efri'
import dsdReducer from './dsd'
import mermaidReducer from './mermaid'
import freediveriReducer from './fdi'
import afdiReducer from './afdi'
import mfdiReducer from './mfdi';
import DSLReducer from './dsl'

function updateBindingStatus(state, data, active) {
  const ecardId = active ? data.ecardId : data
  const ecardImageBindingId = active ? data.ecardImageBindingId : null
  const certifications = state.certifications.map(cert => {
    if (cert.affiliateCertificationId === ecardId) {
      let customizedImgs = cert.customizedImages ? cert.customizedImages.map(item => {
        item.active = false
        return item
      }) : null
      let ecardImgs = cert.ecardImages ? cert.ecardImages.map(item => {
        item.active = false
        return item
      }) : null
      if (ecardImageBindingId) {
        ecardImgs = cert.ecardImages.map(item => {
          if (item.ecardImageBindingId === ecardImageBindingId) {
            const ecardImg = { ...item, active }
            return ecardImg
          }
          return item
        })
      }
      if (data.bindingId) {
        customizedImgs = cert.customizedImages.map(item => {
          if (item.id === data.bindingId) {
            const ecardImg = { ...item, active }
            return ecardImg
          }
          return item
        })
      }
      return { ...cert, customizedImages: customizedImgs, ecardImages: ecardImgs }
    }
    return cert
  })
  const credentials = state.credentials.map(cert => {
    if (cert.affiliateCredentialId === ecardId) {
      let customizedImgs = cert.customizedImages ? cert.customizedImages.map(item => {
        item.active = false
        return item
      }) : null
      let ecardImgs = cert.ecardImages ? cert.ecardImages.map(item => {
        item.active = false
        return item
      }) : null
      if (ecardImageBindingId) {
        ecardImgs = cert.ecardImages.map(item => {
          if (item.ecardImageBindingId === ecardImageBindingId) {
            const ecardImg = { ...item, active }
            return ecardImg
          }
          return item
        })
      }
      if (data.bindingId) {
        customizedImgs = cert.customizedImages.map(item => {
          if (item.id === data.bindingId) {
            const ecardImg = { ...item, active }
            return ecardImg
          }
          return item
        })
      }
      return { ...cert, customizedImages: customizedImgs, ecardImages: ecardImgs }
    }
    return cert
  })
  return { ...state, certifications, credentials }
}

function updateBindingImage(state, data, type) {
  const getUpdateData = () => {
    if (type === 'newYear') {
      return { ...data.ecardImage, ecardImageBindingId: data.ecardImageBindingId }
    } else if (type === 'tmall') {
      return { ...data.ecardImageBinding.ecardImage, ecardImageBindingId: data.ecardImageBinding.ecardImageBindingId }
    }
  }
  const updateData = getUpdateData()
  const { ecardId } = type === 'newYear' ? data : type === 'tmall' ? data.ecardImageBinding : null
  const certifications = state.certifications.map(c => {
    if (c.affiliateCertificationId === ecardId) {
      const ecardImages = c.ecardImages
      ecardImages && ecardImages.map(item => item.active = false)
      const newEcardImages = ecardImages ? [...ecardImages, updateData] : [updateData]
      return { ...c, ecardImages: newEcardImages }
    }
    return c
  })
  const credentials = state.credentials.map(c => {
    if (c.affiliateCredentialId === ecardId) {
      const ecardImages = c.ecardImages
      ecardImages && ecardImages.map(item => item.active = false)
      const newEcardImages = ecardImages ? [...ecardImages, updateData] : [updateData]
      return { ...c, ecardImages: newEcardImages }
    }
    return c
  })
  return { ...state, certifications, credentials }
}

// Updates an entity cache in response to any action with response.entities.
function data(state = {}, { type, data }) {
  switch (type) {
    case ActionTypes.ECARD_IMG_REBINDING[SUCCESS]:
      return updateBindingStatus(state, data, true)
    case ActionTypes.CANCEL_ECARD_IMG_BINDING[SUCCESS]:
      return updateBindingStatus(state, data, false)
    case ActionTypes.SUBMIT_ECARD_IMG_BINDING[SUCCESS]:
      return updateBindingImage(state, data, 'newYear')
    case ActionTypes.ECARDS[SUCCESS]:
    case ActionTypes.REFRESH_ECARDS:
      const { wechatUser, ...ecardsResult } = data
      return { ...state, ...ecardsResult }
    case ActionTypes.CARDREQUESTOPTIONS[SUCCESS]:
      const cardRequestOptions = { ...state.cardRequestOptions, ...data }
      return { ...state, cardRequestOptions }
    case ActionTypes.SHAREECARDS[SUCCESS]:
      return { ...state, ...data }
    case ActionTypes.WECHATUSER[SUCCESS]:
      return { ...state, wechatUser: data }
    case ActionTypes.VERIFY_MOBILE_CODE:
      const { code, ...mobile } = data
      return { ...state, profile: { profile: { ...state.profile.profile, ...mobile }, wechat: state.profile.wechat } }
    case ActionTypes.PROFILE[SUCCESS]:
      return { ...state, profile: data }
    case ActionTypes.SAVE_PROFILE:
      const { ...saveProfile } = data
      return { ...state, profile: { profile: { ...state.profile.profile, ...saveProfile }, wechat: state.profile.wechat } }
    case ActionTypes.FORGOT_PASSWORD:
      return { ...state, forgotPassword: data.email }
    case ActionTypes.POST_PASSWORD:
      const { password } = data
      return { ...state, profile: { profile: { ...state.profile.profile, password }, wechat: state.profile.wechat } }
    case ActionTypes.POST_PROFILE:
      const { firstName, lastName, gender, birthDate } = data
      return { ...state, profile: { profile: { ...state.profile.profile, firstName, lastName, gender, birthDate }, wechat: state.profile.wechat } }
    case ActionTypes.SHOPPINGCART[SUCCESS]:
      return { ...state, shoppingCart: data }
    case ActionTypes.ADDRESSLIST[SUCCESS]:
      data.sort((a1, a2) => new Date(a2.updatedDate) - new Date(a1.updatedDate))
      return { ...state, addressList: data }
    case ActionTypes.DELECT_ADDRESS_SUCCESS:
      const { addressList } = state
      const otherAddress = addressList.filter(address => address.addressId !== data)
      return { ...state, addressList: otherAddress }
    case ActionTypes.DELECT_CART_ITEM:
      const shopCartItems = state.shoppingCart.data
      const otherShopItems = shopCartItems.filter(item => item.skuId !== data.skuId || item.ecardId !== data.ecardId)
      const newShoppingCart = { ...state.shoppingCart, data: otherShopItems }
      return { ...state, shoppingCart: newShoppingCart }
    case ActionTypes.ADD_TO_CART:
      const certName = state.cardRequest.certificationName || state.cardRequest.credentialName
      const shoppingCartAfterAdd = getCurrentGoods(state.cardRequestOptions, data, certName)
      const { shoppingCart } = state
      return { ...state, shoppingCart: { ...shoppingCart, data: [...shoppingCart.data, shoppingCartAfterAdd] } }
    case ActionTypes.REPLACE_CARD:
      const certificationName = state.cardRequest.certificationName || state.cardRequest.credentialName
      const shoppingCartItems = state.shoppingCart.data
      const otherShopCartItems = shoppingCartItems.filter(item => item.ecardId !== data.ecardId)
      const shoppingCartAfterReplace = getCurrentGoods(state.cardRequestOptions, data, certificationName)
      return { ...state, shoppingCart: { ...state.shoppingCart, data: [...otherShopCartItems, shoppingCartAfterReplace] } }
    case ActionTypes.SAVE_PROFILE[SUCCESS]:
      const { wechat } = state.profile
      const newProfile = { profile: data, wechat }
      return { ...state, profile: newProfile }
    case ActionTypes.SUBMIT_STORE_NUM[SUCCESS]:
      if (data.status === 1) return
      const { store, ...profile } = state.profile
      const { isManager, ...storeContent } = store
      return { ...state, profile: { store: { isManager: 1, ...storeContent }, ...profile } }
    case ActionTypes.DELETE_STORE[SUCCESS]:
      if (data.status === 1) return
      const { storeRenewal, store: stores, ...profiles } = state.profile
      return { ...state, profile: { store: { isManager: 2, storeNo: null, storeName: null }, storeRenewal: -1, ...profiles } }
    case ActionTypes.CLEAR_CARDREQUEST_INFO:
      const { cardRequest, ...rest } = state
      return rest
    case ActionTypes.ADD_ECARD_DATA:
      const { certifications = [], credentials = [] } = state
      const certsList = certifications.concat(credentials)
      const ecard = certsList.find(e => e.uniqueId === decodeURIComponent(data))
      return { ...state, cardRequest: ecard }
    case ActionTypes.LOGIN_SUCCESS:
      return {}
    default:
      return state
  }
}

function entityRequestStatus(state = {}, { type, data, error }) {
  if (/_REQUEST$/.test(type)) {
    const entity = type.replace(/_REQUEST$/, '')
    return { ...state, [entity]: ActionTypes.ENTITY_REQUESTING }
  }
  if (/_FAILURE$/.test(type)) {
    const entity = type.replace(/_FAILURE$/, '')
    return { ...state, [entity]: ActionTypes.ENTITY_FAILURE }
  }

  if (/_SUCCESS$/.test(type)) {
    const entity = type.replace(/_SUCCESS$/, '')
    const { [entity]: value, ...rest } = state
    return rest
  }
  return state
}

export default (history) => combineReducers({
  data,
  common: commonReducer,
  ui: uiReducer,
  oss: ossReducer,
  owsi: owsiReducer,
  efri: efriReducer,
  dsd: dsdReducer,
  mermaid: mermaidReducer,
  FDI: freediveriReducer,
  AFDI: afdiReducer,
  MFDI: mfdiReducer,
  DSL: DSLReducer,
  router: connectRouter(history),
  entityRequestStatus
})
