import dayjs from 'dayjs';

import { getLanguage } from './env';

export const formatLocalDate = (dateString) => {
  if (!dateString) return '';
  let template;
  const lang = getLanguage();
  if (lang === 'sc') {
    template = 'YYYY年M月D日';
  } else if (lang === 'tc') {
    template = 'YYYY年M月D日';
  } else {
    template = 'MMM DD, YYYY';
  }
  return dayjs(dateString).format(template);
};
