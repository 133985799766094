import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { WhiteSpace, WingBlank } from 'antd-mobile'
import { FormattedMessage, useIntl } from 'react-intl';
import Header from '../../components/common/FormHeader'
import DiverInfo from '../DiveMasterForm/DiverInfo'
import { clearSignature } from '../../reducers/ui';
import VerifyEfr from './VerifyEfr'
import CheckAge from './CheckAge'
import { getAge, showModal } from '../../components/common/utils'
import { findEfrCert } from '../../components/common/findCerts'
import SearchInstructor from '../../components/common/SearchInstructor';
import CardLayout from '../DiveMasterForm/CardLayout'
import SearchStore from '../../components/common/SearchStore';
import AddressAndLangOption from '../DiveMasterForm/AddressAndLangOption';
import Signature from '../../components/common/Signature';
import AgreementCheckbox from '../../components/common/AgreementCheckbox'
import ApplicationStatus from './ApplicationStatus'
import DatePickerComponent from '../../components/common/DatePicker'
import Notification from '../../components/Notification';

const EFRIApplication = () => {

  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const [checkAge, setCheckAge] = useState(false)
  const [showDialog, setDialogStatus] = useState(false);
  const [formData, setFormData] = useState({
    efr: '',
    efrfiles: [],
    instructorNumber: '',
    storeNumber: '',
    languageId: 0,
    regionId: 0,
    checked: false,
  })
  const {
    userInfo,
    certifications,
    efriDetail
  } = useSelector((state) => ({
    userInfo: state.data.userInfo,
    certifications: state.data.certifications,
    efriDetail: state.efri.efriDetail
  }));

  useEffect(() => {
    if (userInfo) {
      setCheckAge(getAge(userInfo.birthDate) >= 18)
    }
  }, [userInfo]);

  useEffect(() => {
    if (efriDetail.note) {
      if (!showDialog) {
        showModal(
          formatMessage({ id: 'cardOptions-replaceCardHint' }),
          `${formatMessage({ id: 'dm-app-not-approved-tips' })}${
            efriDetail.note
          }`,
          () => { },
          () => { },
          formatMessage({ id: 'common-comfirmBtn' }),
          formatMessage({ id: 'common-cancel' })
        );
      }
      setDialogStatus(true);
    }
  }, [efriDetail, formatMessage, showDialog]);

  useEffect(() => {
    return () => dispatch(clearSignature());
  }, [])

  useEffect(() => {
    if (certifications && efriDetail) {
      setFormData({
        ...formData,
        code: efriDetail.code,
        regionId: efriDetail.regionId ? efriDetail.regionId : formData.regionId,
        efrfiles: efriDetail.efrfiles || formData.efrfiles,
        languageId: efriDetail.languageId ? efriDetail.languageId : formData.languageId,
        storeNumber: efriDetail.storeInfoDTO ? efriDetail.storeInfoDTO.storeNumber : formData.storeNumber,
        instructorNumber: efriDetail.instructorInfoDTO ? efriDetail.instructorInfoDTO.instructorNumber : formData.instructorNumber,
        efr: findEfrCert(certifications)?.studentNumber || '',
      })
    }
  }, [efriDetail, certifications])

  const updateFormData = (update) => setFormData({ ...formData, ...update })

  const isTrainingInfoComplete = !!formData.instructorNumber

  const isFormEditable = () => efriDetail.status === 'ADMIN_NOT_APPROVED' ||
    !efriDetail.status ||
    efriDetail.status === 'SUBMIT' ||
    efriDetail.status === 'INSTRUCTOR_NOT_APPROVED'

  if (!userInfo || !efriDetail) return null

  return <>
    <Notification position="MEMBER_APP" />
    <Header
      text={<FormattedMessage id="efri-application" />}
      code={efriDetail.code} />
    <DiverInfo diver={userInfo} />
    <WhiteSpace />
    <VerifyEfr
      cert={formData.efr}
      editable={isFormEditable()}
      attachFileList={formData.efrfiles} />
    <WhiteSpace />
    <CheckAge checkAge={checkAge} />
    <WhiteSpace />
    <CardLayout
      title={<FormattedMessage id="dm-form-training-info" />}
      status={isTrainingInfoComplete}
    >
      <SearchInstructor
        type={"EFRT"}
        id={"trainer"}
        editable={isFormEditable()}
        instructor={efriDetail.instructorInfoDTO}
        instructorNumber={formData.instructorNumber}
        onSuccess={(result) => {
          updateFormData({ instructorNumber: result.instructorNumber });
        }}
      />
      {(isFormEditable() || formData.storeNumber) && (
        <SearchStore
          placeholder={formatMessage({ id: 'dm-form-store-warning' })}
          editable={isFormEditable()}
          store={efriDetail.storeInfoDTO}
          storeNumber={formData.storeNumber}
          onSuccess={(result) => {
            updateFormData({ storeNumber: result.storeNumber });
          }}
        />
      )}
    </CardLayout>
    <WhiteSpace />
    <AddressAndLangOption
      onValueChange={updateFormData}
      languageId={formData.languageId}
      regionId={formData.regionId}
      editable={isFormEditable()} />
    <WhiteSpace />
    {efriDetail.courseDate && (
      <DatePickerComponent
        extra={formatMessage({ id: 'common-select' })}
        title={formatMessage({ id: 'efri-course-date-error' })}
        disabled={true}
        value={efriDetail.courseDate}>
        <FormattedMessage id="common-course-date" />
      </DatePickerComponent>
    )}
    <WhiteSpace />
    <Signature
      filePath={'signature'}
      initSignature={efriDetail.handwrittenSignatureUrl}
      modified={isFormEditable()}
    />
    {isFormEditable() && (
      <AgreementCheckbox
        checked={formData.checked}
        setChecked={(checked) => updateFormData({ checked })}>
        <FormattedMessage id="efri-agreement" />
      </AgreementCheckbox>
    )}
    <WhiteSpace size="xl" />
    <WingBlank>
      <ApplicationStatus
        detail={efriDetail}
        formData={formData}
        checkAge={checkAge}
      />
      <WhiteSpace size="xl" />
    </WingBlank>
  </>
}

export default EFRIApplication