import React from 'react'
import { ResultPage, Card } from 'antd-mobile-v5'
import { WhiteSpace } from 'antd-mobile'
import { FormattedMessage } from 'react-intl';
import {
  usePayDm,
  usePayBasicMermaid,
  usePayMermaid,
  usePayFdi,
  usePayAfdi,
  usePayMfdi,
  usePaySi,
  useIssueCertsPay,
  usePayFdpic
} from '../../services/hooks'
import PaymentBtn from '../../components/FormTools/PaymentBtn'

import styles from './applicationSubmitResult.module.css'

const TrainerContent = () => {
  return <>
    <ResultPage.Card className={styles['desc-card']}>
      <FormattedMessage id="BMI-message-review" />
    </ResultPage.Card>
    <WhiteSpace size="lg" />
    <Card className={styles['desc-card']}>
      <p><FormattedMessage id="BMI-pay-reminder" /></p>
      <p><FormattedMessage id="BMI-pay-reminder-t" /></p>
    </Card>
  </>
}

const ResultPageContent = ({ type, code, paymentType, paid }) => {

  const { runAsync: dmPay } = usePayDm();
  const { runAsync: bmiPay } = usePayBasicMermaid();
  const { runAsync: miPay } = usePayMermaid();
  const { runAsync: fdiPay } = usePayFdi();
  const { runAsync: afdiPay } = usePayAfdi();
  const { runAsync: mfdiPay } = usePayMfdi();
  const { runAsync: siPay } = usePaySi();
  const { runAsync: issueCertsPay } = useIssueCertsPay(type);
  const { runAsync: fdPicPay } = usePayFdpic();

  switch (type) {
    case 'DM':
      return <>
        <ResultPage.Card className={styles['desc-card']}>
          <FormattedMessage id="dm-form-message-review" />
        </ResultPage.Card>
        <WhiteSpace size="lg" />
        <Card className={styles['desc-card']}>
          <FormattedMessage id="dm-form-message-address" />
          <p className={styles.address}><FormattedMessage id="dm-form-message-address-detail" /></p>
        </Card>
        {!paid && <PaymentBtn code={code} paymentFn={dmPay} payApplicationType={paymentType} />}
      </>
    case 'EFRI':
      return <ResultPage.Card className={styles['desc-card']}>
        <FormattedMessage id="efri-message-review" />
      </ResultPage.Card>
    case 'OWSI':
      return <ResultPage.Card className={styles['desc-card']}>
        <FormattedMessage id="owsi-message-review" />
      </ResultPage.Card>
    case 'PADI_BASIC_MERMAID_INSTRUCTOR':
      return <>
        <TrainerContent />
        {!paid && <PaymentBtn code={code} paymentFn={bmiPay} payApplicationType={paymentType} />}
      </>
    case 'PADI_MERMAID_INSTRUCTOR':
      return <>
        <TrainerContent />
        {!paid && <PaymentBtn code={code} paymentFn={miPay} payApplicationType={paymentType} />}
      </>
    case 'FDI':
      return <>
        <TrainerContent />
        {!paid && <PaymentBtn code={code} paymentFn={fdiPay} payApplicationType={paymentType} />}
      </>
    case 'AFDI':
      return <>
        <TrainerContent />
        {!paid && <PaymentBtn code={code} paymentFn={afdiPay} payApplicationType={paymentType} />}
      </>
    case 'MFDI':
      return <>
        <TrainerContent />
        {!paid && <PaymentBtn code={code} paymentFn={mfdiPay} payApplicationType={paymentType} />}
      </>
    case 'SI_2':
    case 'SI_1':
      return <>
        <WhiteSpace size="lg" />
        <WhiteSpace size="lg" />
        <WhiteSpace size="lg" />
        <WhiteSpace size="lg" />
        {!paid && <PaymentBtn code={code} paymentFn={siPay} payApplicationType={paymentType} />}
      </>
    case 'EFRP':
      return <>
        <WhiteSpace size="lg" />
        <WhiteSpace size="lg" />
        <WhiteSpace size="lg" />
        <WhiteSpace size="lg" />
        {!paid && <PaymentBtn code={code} paymentFn={issueCertsPay} payApplicationType={paymentType} />}
      </>
    case 'FD':
      return <>
        <WhiteSpace size="lg" />
        <WhiteSpace size="lg" />
        <WhiteSpace size="lg" />
        <WhiteSpace size="lg" />
        {!paid && <PaymentBtn code={code} paymentFn={fdPicPay} payApplicationType={paymentType} />}
      </>
  }
}

export default ResultPageContent