import { Dialog } from "antd-mobile-v5";
import { useIntl } from "react-intl"

export const useShowMessage = () => {
  const {formatMessage} = useIntl();
  return (id, url) => Dialog.show({
    content: formatMessage({id}),
    closeOnAction: true,
    actions: [{key: 'close', text: formatMessage({id: 'common-confirm'})}],
    onClose: () => {
      if (url) window.location.href = url;
    },
});
}