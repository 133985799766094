import React, { Component } from 'react';
import { Button, Toast } from 'antd-mobile'
import isEmail from 'validator/lib/isEmail';
import { showAlert } from './utils'
import { injectIntl, FormattedMessage } from 'react-intl';
import { isMobile } from './utils'
import styles from './getCode.module.css'

class GetCode extends Component {

  state = {
    timer: 0,
  }

  startTimer() {
    this.setState({timer: 100});
    clearInterval(this.timer)
    this.timer = setInterval(() => this.tick(), 1000);
  }

  stopTimer () {
   clearInterval(this.timer)
 }

  tick () {
    this.setState({timer: (this.state.timer - 1)})
  }

  getCode(args, msg) {
    console.log(args)
    if(!args) return;
    const { intl:{formatMessage} } = this.props
    if (args.mobile && !isMobile(args.mobile)) {
      showAlert(formatMessage({id: 'common-errorMobile'}), formatMessage({id: 'common-close'}));
      return;
    }else if(args.email && !isEmail(args.email)) {
      showAlert(formatMessage({id: 'common-isNotEmail'}), formatMessage({id: 'common-close'}));
      return;
    } 
    this.startTimer();
    Toast.hide();
    showAlert(msg, formatMessage({id: 'common-close'}), () => this.setState({ pending: false }))
    this.props.requestFn(args)
  }

  render() {
    const { intl:{formatMessage} } = this.props
    if(this.state.timer < 0){
      this.stopTimer();
    }
    let args, msg;
    if(this.props.mobile) {
      args = {mobile: this.props.mobile.trim(), countryCode: this.props.countryCode}
      msg = `${formatMessage({id: 'getCode-sendMobile'})}${this.props.countryCode ? this.props.countryCode : '86'} ${this.props.mobile.trim()}`
    }else if(this.props.email) {
      args = {email: this.props.email.toLowerCase().trim()}
      msg = formatMessage(
        {id: 'getCode-sendEmail'},
        {email: this.props.email.trim()}
      )
    }
    return (
      <Button
        disabled={!args || this.state.timer>0}
        style={{width: this.state.timer>0 ? 100 : 80, height: 'auto'}}
        onClick={() => this.getCode(args,msg)}
        className={styles.btnRegister}>
        <FormattedMessage
          id="common-getCode"
        />
        {(this.state.timer>0) ? ('(' +this.state.timer + 's)') : '' }
      </Button>
    )
  }
}

export default injectIntl(GetCode)
