import React from 'react'
import { Form, Input } from 'antd-mobile-v5';

const InvoiceDonationOptions = ({ form }) => {
  return <>
    <Form.Item
      name="invoiceDonationUnitCode"
      label={'捐贈單位代號'}
      rules={[
        {
          required: true,
          message: '捐贈單位代號不能為空。',
        }
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="invoiceDonationUnitCodeAgain"
      label={'再次確認捐贈單位代號'}
      rules={[
        {
          required: true,
          message: '請再次輸入捐赠单位代号。',
        },
        {
          validator: (_, value) => {
            if (!value || form.getFieldValue('invoiceDonationUnitCode') === value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error("兩次輸入捐赠单位代号不一致，請重新輸入。"));
          }
        }
      ]}
    >
      <Input />
    </Form.Item>
  </>
}

export default InvoiceDonationOptions