import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import {
  List,
  InputItem,
  Flex,
  WingBlank,
  WhiteSpace
} from 'antd-mobile';
import { Toast, Button } from 'antd-mobile-v5'
import { FormattedMessage, useIntl } from 'react-intl';
import { getCookie } from '../../components/common/cookie';
import {
  agentElearningCreateOrder,
} from '../../services/api';
import { pageLoading, pageLoaded } from '../../actions/index';
import OrderItem from '../PriceList/OrderItem';
import OrderPrice from '../PriceList/OrderPrice';
import { formatMoney } from '../../components/common/utils';
import { displayGenderLabel } from '../../components/FormTools/utils'
import AgreementPopup from '../../components/FormTools/AgreementPopup'
import { useTaiwanIp } from '../../services/hooks';


import styles from '../PriceList/PriceList.module.css';

const memberType = {
  sc: {
    IM: '个人会员',
    CD: '总监',
    PRRA: '潜店',
    PRRA5: '5星潜店',
  },
  tc: {
    IM: '個人會員',
    CD: '總監',
    PRRA: '潛店',
    PRRA5: '5星潛店',
  },
  en: {
    IM: 'IM',
    CD: 'CD',
    PRRA: 'PRRA',
    PRRA5: 'PRRA5',
  },
};
const getDiscountLevel = (level) => {
  const language = getCookie('language');
  const [type, discountLevel] = level.split(' ');
  return memberType[language][type] + ' ' + discountLevel;
};

const Checkout = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const lang = getCookie('language');
  const [visible, setVisible] = useState(false)
  const { state: { order, submitData, searchResult } } = useLocation();
  const { data: isTaiwanIp } = useTaiwanIp({});
  const { profiles } = useSelector(state => ({
    profiles: state.data.profile,
  }));
  if (!order) {
    return <Redirect to="/agent_elearning" />;
  }
  const items = order.skus.map(item => { item.number = 1; return item; });

  const submitCheckoutData = async (submitData) => {
    try {
      dispatch(pageLoading());
      const { data, error } = await agentElearningCreateOrder(submitData);
      if(error) {
        Toast.show({
          content: error.clientMsg
        })
        return
      }
      if (data) {
        window.location.href = data.url
      }
    } catch (e) {
      //TODO: handle error
      console.log(e);
    } finally {
      dispatch(pageLoaded());
    }
  };

  const verifyIpBeforePay = (submitData) => {
    if (profiles.teachingLocation === 'TW' || isTaiwanIp) {
      setVisible(true)
    } else {
      submitCheckoutData(submitData)
    }
  }

  const currencyType = items[0].currencyType;
  const { profile } = submitData;
  return (
    <>
      <AgreementPopup visible={visible} setVisible={setVisible} toPay={() => submitCheckoutData(submitData)} />
      <List renderHeader={formatMessage({ id: 'agent-elearning-header-email' })}>
        <InputItem
          editable={false}
          value={submitData.email}
        >
          <FormattedMessage id="common-email"></FormattedMessage>
        </InputItem>
        <InputItem
          editable={false}
          value={searchResult.englishName || profile.firstName + ' ' + profile.lastName}
        >
          <FormattedMessage id="common-name"></FormattedMessage>
        </InputItem>
        {profile && <><InputItem
          editable={false}
          value={dayjs(profile.birthDate, 'MM/DD/YYYY').format('YYYY-MM-DD')}
        >
          <FormattedMessage id="setProfile-birthDate"></FormattedMessage>
        </InputItem>
          <InputItem
            editable={false}
            value={displayGenderLabel(lang, profile.gender)}
          >
            <FormattedMessage id="setProfile-gender"></FormattedMessage>
          </InputItem>
        </>}
      </List>
      <List renderHeader={<FormattedMessage id="items-list" />} className="price-items">
        {items.map((item) => (
          <OrderItem
            key={item.itemNo}
            item={item}
            orderPrice={item.discountPrice}
          />
        ))}
      </List>
      <List renderHeader={<FormattedMessage id="discount-price" />} className={styles.container}>
        <List.Item>
          <OrderPrice
            labelId="discount-level"
            value={getDiscountLevel(order.level)}
          />
          <OrderPrice
            labelId="discount-price"
            value={formatMoney(order.totalPrice, currencyType)}
          />
        </List.Item>
      </List>
      <WhiteSpace />
      <WhiteSpace />
      <WingBlank>
        <Button 
          color='primary' 
          block 
          onClick={() => verifyIpBeforePay(submitData)}
        >
          <FormattedMessage id="checkout" />
        </Button>
        <WhiteSpace />
        <Flex justify="center" onClick={() => history.push('/agent_elearning', { submitData, searchResult })}>
          <FormattedMessage id="change-order" />
        </Flex>
      </WingBlank>
    </>
  );
};

export default Checkout;
