import React from 'react';

import { getParameterByName, debug } from '../../components/common/utils';
import { useErrorModal } from '../../services/hooks';
import { useWechatUserInfo } from '../../services/hooks/wechat';

function removeSearchParamFromUrl(url, key) {
  const [base, pairs] = url.toLowerCase().split('?');
  if(pairs) {
    const params = new URLSearchParams(pairs);
    params.delete(key.toLowerCase());
    return params.size ? base + '?' + params.toString() : base;
  }
  return url;
}

export default function AuthorizePage(props) {
  const showErrDialog = useErrorModal();
  const { loading } = useWechatUserInfo(getParameterByName('code'), {
    onSuccess: (data) => {
      if (data?.unionid?.length > 27) {
        document.cookie = 'padiWxId=' + data.unionid;

        let referer = decodeURIComponent(getParameterByName('referer'));
        console.log('Referer in the wechat auth callback is: ' + referer)
        if(referer.indexOf('zhibo.tv') > 0) {
          window.location.href = `${document.location.origin}/emailLogin?referer=${encodeURIComponent(referer)}`
        } else {
          referer = removeSearchParamFromUrl(referer, 'padiwxid');
          debug('Referer in wechat callback', referer);
          window.location.href = referer;
        }
      } else {
        console.error(data);
        showErrDialog('系统出错，请稍后再试，谢谢您的理解。');
      }
    },
    onError: (error) => {
      console.error(error);
      showErrDialog('微信授权失败。');
  }
  });
  return loading && <div> 请求微信授权，请稍候。。。 </div>;
}
