import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware, { END } from 'redux-saga';
import createRootReducer from '../reducers';
import { history } from '../services';
import { routerMiddleware } from 'connected-react-router';

const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    state = undefined
  }
  return createRootReducer(history)(state, action);
}

export default function createStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();
  const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    middleware: [routerMiddleware(history), sagaMiddleware],
    devTools: process.env.NODE_ENV === 'production' ? false : true,
  });

  if (module.hot && process.env.NODE_ENV === 'development') {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers').default;
      store.replaceReducer((state, action) => {
        if (action.type === 'RESET_APP') {
          state = undefined
        }
        return nextRootReducer(history)(state, action);
      });
    });
  }
  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);
  return store;
}
