import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import styles from './Search.module.css';

const actionIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

export default () => (
  <div className={styles.searchResult}>
    <div className={styles.loading}>
      <Spin indicator={actionIcon} tip="查询中..." />
    </div>
  </div>
);
