import React from 'react';
import { Checkbox, Tag, Divider } from 'antd-mobile-v5';

import './Cart.css';

const Goods = ({ checked, remove, cartItem, toggleChecked, cardOptions }) => {
  let {imageUrl} = cardOptions.find(o => o.skuId === cartItem.skuId);

  return (
    <div className="goods">
      <Checkbox checked={checked} onChange={(e) => toggleChecked(cartItem)}>
        <div className="cart-item">
          <img
            src={imageUrl}
            className="cert-img"
            alt="cert"
          />
          <div className="cart-item-desc">
            <div>{cartItem.ecardName}</div>
            <div>{cartItem.styleName}</div>
            <div>
              <Tag small fill="outline" color="primary">
                {cartItem.typeName}
              </Tag>
              <span className="item-price">¥{cartItem.price / 100}</span>
            </div>
          </div>
        </div>
      </Checkbox>
      <Divider style={{ margin: '6px 0' }} />
      <img
        className="cart-close"
        src={require('../../assets/images/close.png')}
        alt="close"
        onClick={() => remove(cartItem)}
      />
    </div>
  );
};

export default Goods;
