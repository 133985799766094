/* global document */
import { getUnionId } from './cookie';
import { setPathVars } from './utils';
import * as config from '../../services/config';

const fetcher = (name, { method = 'GET', cache = true, ...variables } = {}) => {
  if (cache && method === 'GET' && fetcher.cache[name])
    return fetcher.cache[name];
  const apiUrl = config[name];
  if (!apiUrl) throw new Error(`Invalid api of: "${name}"`);
  let url;
  let baseURL;
  if (document.location.origin === config.URL_prod) {
    baseURL = config.baseURL_prod;
  } else if (document.location.origin === config.URL_stage) {
    baseURL = config.baseURL_stage;
  } else {
    baseURL = config.baseURL_qa;
  }

  const options = {
    method,
    headers: {
      Authorization: getUnionId(),
      'content-type': 'application/json',
    },
  };

  url = `${baseURL}${config[name]}`;
  const { pathVars, ...otherVars } = variables;
  if (pathVars) url = setPathVars(url, pathVars);
  if (url.match(/\{\w+\}/))
    throw new Error(`There're missing path variables: ${url}`);
  if (method === 'GET') {
    const queryStr = Object.keys(otherVars)
      .map(key => `${key}=${encodeURIComponent(otherVars[key])}`)
      .join('&');
    if (queryStr) url = `${url}?${queryStr}`;
  } else {
    options.body = JSON.stringify(otherVars);
  }

  return fetch(url, options).then(res => {
    const result = res.json();
    if (cache && method === 'GET') {
      fetcher.cache[name] = result;
    }
    return result;
  });
};
fetcher.cache = {};
export default fetcher;
