import React from 'react'
import { NavBar } from 'antd-mobile';
import styles from './FormHeader.module.css'

const Header = ({ code, text, rightContent, noIcon }) => <NavBar
  className={styles.header}
  mode="light"
  leftContent=""
  rightContent={rightContent}
  icon={!noIcon && <img src="https://gw.alipayobjects.com/zos/rmsportal/iXVHARNNlmdCGnwWxQPH.svg" className="am-icon am-icon-md" alt="" />}
  onLeftClick={() => '返回'}
>
  <span>
    {text}
    {code && <span>({code})</span>}
  </span>
</NavBar>

export default Header