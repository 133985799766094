import React from 'react'
import { Tag, Modal } from 'antd-mobile';
import { gtm, formatDate } from '../../components/common/utils'
import { setCookie, getCookie } from "../../components/common/cookie"
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'

import styles from './ResourcesList.module.css'

const ResourcesDetail = ({ detailInfo, layerStyle }) => {

  const { formatMessage } = useIntl()

  const toDetailUrl = (url, title) => {
    gtm('Click - Assets', `${title}`);
    const hasCanva = getCookie('padi_canva')
    if (url.indexOf('sctrack.sc.gg') > 0 && !hasCanva) {
      Modal.alert(formatMessage({ id: 'cardOptions-replaceCardHint' }), <div>{formatMessage({ id: 'resources-canva-tips' })}</div>, [
        {
          text: formatMessage({ id: 'resources-cana-registered' }),
          onPress: () => {
            setCookie('padi_canva', true)
            window.location.href = url
          }
        },
        {
          text: formatMessage({ id: 'resources-cana-to-register' }),
          onPress: () => window.location.href = 'https://www.canva.cn/brand/join?token=QijZBWzmJi1paYugv8tiFg&referrer=team-invite'
        },
        {
          text: formatMessage({ id: 'common-cancel' }),
          onPress: () => { }
        }
      ])
      return
    }
    window.location.href = url
  }

  const isNewItem = () => {
    const itemDate = ('20' + detailInfo.updateTime + '-1').replace('年', '-').replace('月', '')
    return dayjs(itemDate).isAfter(dayjs().subtract(6, 'month'));
    // const itemYear = dayjs(itemDate).year()
    // const itemMonth = dayjs(itemDate).month() + 1
    // const getTotalItemMonth = itemYear * 12 + itemMonth
    // const newDateYear = dayjs().year()
    // const newDateMonth = dayjs().month() + 1
    // const getTotalNowMonth = newDateYear * 12 + newDateMonth
    // const compare = Math.abs(getTotalNowMonth - getTotalItemMonth);
    // return compare <= 6
  }

  return <>
    <div className={[`${styles.lineItem}`, `${styles[layerStyle]}`].join(' ')} onClick={() => toDetailUrl(detailInfo.url, detailInfo.title)}>
      <span className={styles.detailTitle}>{detailInfo.title}</span>
      {detailInfo.updateTime && <span className={styles.updateTime}>- {detailInfo.updateTime}</span>}
      {detailInfo.updateContent && <span className={styles.updateContent}>({detailInfo.updateContent})</span>}
      {detailInfo.isCS && <Tag small className={styles.tag}>简</Tag>}
      {detailInfo.isCT && <Tag small className={styles.tag}>繁</Tag>}
      {isNewItem() && <img src={require('../../assets/images/new.png')} alt="new" className={styles.new} />}
    </div>
  </>
}

export default ResourcesDetail