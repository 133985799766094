import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { List, WingBlank } from 'antd-mobile';
import CardLayout from '../DiveMasterForm/CardLayout'
import { FormattedMessage, useIntl } from 'react-intl';
import UploadOssFile from '../../components/common/UploadOssFile'
import UploadFileWrapper from '../../components/common/UploadFileWrapper'
import { findMasterFD, findEfriCert, findOwsi } from '../../components/common/findCerts'
import { uploadFDIOssFile, removeFDIFile } from '../../reducers/fdi'

const Item = List.Item

const CheckCerts = ({
  efrifiles,
  editable,
  fdiFiles,
  otherFiles,
  complete }) => {

  const { certifications, ossSignature, credentials } = useSelector((state) => ({
    certifications: state.data.certifications,
    credentials: state.data.credentials,
    ossSignature: state.oss.ossSignature
  }));
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const filename = (type) => `${ossSignature.dir}${type}/${new Date().getTime()}`

  return <CardLayout title={<FormattedMessage id="dm-form-required-certs" />} status={complete}>

    {/*
      证书要求：三选一 + EFRI
      mfd
      owsi
      其他组织自由潜证书
    */}

    {findMasterFD(certifications) ? (
      <Item>
        <FormattedMessage id="fdi-master-cert" /> {`（${findMasterFD(certifications).studentNumber}）`}
      </Item>
    ) : findOwsi(credentials) ? (
      <Item>
        <FormattedMessage id="fdi-owsi-cert" /> <FormattedMessage id="afdi-satisfy" />
      </Item>
    ) : (
          <UploadFileWrapper
            labelName={formatMessage({ id: 'fdi-not-padi-cert' })}
            editable={editable}>
            <UploadOssFile
              fileList={fdiFiles || []}
              fileName={filename('fdi')}
              onChange={(formData) => {
                dispatch(uploadFDIOssFile({ formData }))
              }}
              onDelete={(index) => dispatch(removeFDIFile(fdiFiles[index]))}
              editable={editable} />
            {editable && <WingBlank>
              <div className="small-font gray">
                <FormattedMessage id="common-upload-file-reminder" />
              </div>
            </WingBlank>}
          </UploadFileWrapper>
        )}
    {/* efr */}
    {findEfriCert(credentials) ? <Item>
      EFRI {`（${formatMessage({ id: 'app-has-efri' })}）`}
    </Item> : <UploadFileWrapper
      labelName={"EFRI"}
      editable={editable}>
        <UploadOssFile
          fileList={efrifiles || []}
          fileName={filename('efri')}
          onChange={(formData) => {
            dispatch(uploadFDIOssFile({ formData }))
          }}
          onDelete={(index) => dispatch(removeFDIFile(efrifiles[index]))}
          editable={editable} />
        {editable && <WingBlank>
          <div className="small-font gray">
            <FormattedMessage id="common-upload-file-reminder" />
          </div>
        </WingBlank>}
      </UploadFileWrapper>}
    <UploadFileWrapper
      labelName={formatMessage({ id: 'mermaid-other-files' })}
      editable={editable}>
      <UploadOssFile
        fileList={otherFiles || []}
        fileName={filename('other')}
        onChange={(formData) => {
          dispatch(uploadFDIOssFile({ formData }))
        }}
        onDelete={(index) => dispatch(removeFDIFile(otherFiles[index]))}
        editable={editable} />
      {editable && <WingBlank>
        <div className="small-font gray">
          <FormattedMessage id="app-health" />
        </div>
      </WingBlank>}
    </UploadFileWrapper>
  </CardLayout>
}

export default CheckCerts