import { Button, Image, Popup } from 'antd-mobile-v5';
import React, { useState } from 'react';

import BuyOptions from './BuyOptions';
import CardIntros from './CardIntros';
import Tag from '../../components/common/Tag';
import {
  useCardOptions,
} from '../../services/hooks';
import LoadingStatus from '../../components/FormTools/Loading';
import { getCardOptionNames } from './cardOptionUtils';

import styles from './BuyCards.module.css';

export default () => {
  const [showBuyDialogue, setShowBuyDialogue] = useState(false);
  const { loading: cardOptionsLoading, data: cardOptions} = useCardOptions();

  if(cardOptionsLoading) return <LoadingStatus />;

  return (
    <div className={styles.container}>
      <div className={styles.flexible}>
        <Image src={require('../../assets/images/cards/banner_1.jpg')} />
        <div className={styles.slogan}>
          <p>购买卡面助力海洋公益，所有实体卡面使用再生塑料制作，卡片收益将捐助“PADI 爱海洋”公益基金。</p>
        </div>
        <div className={styles.optionsArea}>
          <div className={styles.options}>
            <div className={styles.label}>卡面</div>
            <div
              className={styles.opiton}
              onClick={() => {
                setShowBuyDialogue(true);
              }}
            >
              {getCardOptionNames(cardOptions).map(option => <Tag key={option}>{option}</Tag>)}
            </div>
          </div>
          <div className={styles.options}>
            <div className={styles.label}>类型</div>
            <div
              className={styles.opiton}
              onClick={() => {
                setShowBuyDialogue(true);
              }}
            >
              <Tag>环保实体卡</Tag>
              <Tag>环保电子卡</Tag>
            </div>
          </div>
        </div>
        <CardIntros />
      </div>
      <div className={styles.bottom}>
        <Button
          block
          color="primary"
          shape="rounded"
          onClick={() => setShowBuyDialogue(true)}
        >
          点击为海洋助力
        </Button>
        <Popup
        visible={showBuyDialogue}
        forceRender
        destroyOnClose
        onMaskClick={() => setShowBuyDialogue(false)}
        position="bottom"
        bodyStyle={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          height: '80vh',
          padding: '8px 8px 0',
        }}
      >
        <BuyOptions cardOptions={cardOptions}/>
      </Popup>
      </div>
    </div>
  );
};
