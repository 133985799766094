import React from 'react'
import { List, Checkbox } from 'antd-mobile';
import { specialtyMapping } from '../../components/common/utils'
import { getCookie } from '../../components/common/cookie'
import CardLayout from '../DiveMasterForm/CardLayout'
import { FormattedMessage } from 'react-intl';

import styles from './SpecialtyInstructor.module.css'

const CheckboxItem = Checkbox.CheckboxItem;

const DirectApplySi = ({ directData, checkedFn, checked }) => {
  const lang = getCookie('language')
  return <CardLayout title={<FormattedMessage id="si-conditions" />} status={"none"}>
    {directData.length > 0 ? <List>
      {directData.map(item => <CheckboxItem key={item.sp} onChange={(e) => checkedFn(e, item.spi)} checked={checked(item)}>
        {specialtyMapping[item.spi][lang]}
      </CheckboxItem>)}
    </List> : <div className={styles.noSpecialty}>
      <FormattedMessage id="si-no-direct-sp" />
    </div>}
  </CardLayout>
}

export default DirectApplySi