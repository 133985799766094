import React, { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WhiteSpace } from 'antd-mobile';
import { FormattedMessage, useIntl } from 'react-intl';
import Header from '../../components/common/FormHeader';
import DiverInfo from './DiverInfo';
import RequiredCerts from './RequiredCerts';
import Agreement from './Agreement';
import Signature from '../../components/common/Signature';
import SearchInstructor from '../../components/common/SearchInstructor';
import SearchStore from '../../components/common/SearchStore';
import ApplicationStatus from './ApplicationStatus';
import { clearSignature } from '../../reducers/ui';
import { getOssSignature } from '../../reducers/oss';
import { formatDate, showModal } from '../../components/common/utils';
import { findEfriCert, isEfrExpired } from '../../components/common/findCerts'
import CardLayout from './CardLayout';
import AddressAndLangOption from './AddressAndLangOption';
import DatePickerComponent from '../../components/common/DatePicker'
import Notification from '../../components/Notification';

export const certInfoEditableContext = createContext()

const findRequiredCerts = (certType, certs) => {
  if (certType === 'efr') {
    return certs.find((cert) => cert.printCardTypeId === 48 && !cert.expired);
  } else if (certType === 'aow') {
    return certs.find((cert) => cert.typeNumber === 26);
  } else if (certType === 'rescue')
    return certs.find((cert) => cert.typeNumber === 72);
};

const isAppEditable = (app) =>
  !app.status ||
  app.status === 'SUBMIT' ||
  app.status === 'INSTRUCTOR_NOT_APPROVED' ||
  app.status === 'INSTRUCTOR_CREATED' ||
  app.status === 'ADMIN_NOT_APPROVED';

const isTrainingInfoCompleted = (formData) => formData.instructorNumber && formData.finishTime

const isCertInfoEditable = (status) =>
  !status ||
  status === 'SUBMIT' ||
  status === 'INSTRUCTOR_NOT_APPROVED' ||
  status === 'ADMIN_NOT_APPROVED' ||
  status === 'INSTRUCTOR_CREATED';

const DMFormPage = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [formData, setFormData] = useState({
    aow: '',
    efr: '',
    rescue: '',
    languageId: '',
    regionId: '',
    finishTime: '',
    handwrittenSignatureUrl: '',
    instructorNumber: '',
    storeNumber: '',
    checkedAgreement: false,
    checkedTimes: false,
    aowFiles: [],
    efrfiles: [],
    rescueFiles: [],
    efri: false
  });
  const [showDialog, setDialogStatus] = useState(false);

  const {
    userInfo,
    certifications,
    credentials,
    dmFormInfo,
    profiles,
  } = useSelector((state) => ({
    userInfo: state.data.userInfo,
    profiles: state.data.profile,
    certifications: state.data.certifications,
    credentials: state.data.credentials,
    dmFormInfo: state.ui.dmFormCounter,
  }));

  useEffect(() => {
    dispatch(getOssSignature('DM'));
    return () => dispatch(clearSignature());
  }, []);

  useEffect(() => {
    if (dmFormInfo.note) {
      if (!showDialog) {
        showModal(
          formatMessage({ id: 'cardOptions-replaceCardHint' }),
          `${formatMessage({ id: 'dm-app-not-approved-tips' })}${
          dmFormInfo.note
          }`,
          () => { },
          () => { },
          formatMessage({ id: 'common-comfirmBtn' }),
          formatMessage({ id: 'common-cancel' })
        );
      }
      setDialogStatus(true);
    }
  }, [dmFormInfo, formatMessage, showDialog]);

  const updateFormData = (update) => setFormData({ ...formData, ...update })

  useEffect(() => {
    if (certifications && dmFormInfo) {
      setFormData({
        ...formData,
        aow: findRequiredCerts('aow', certifications) ? findRequiredCerts('aow', certifications).studentNumber : '',
        efr: findRequiredCerts('efr', certifications) ? findRequiredCerts('efr', certifications).studentNumber : '',
        efri: findEfriCert(credentials) ? true : false,
        rescue: findRequiredCerts('rescue', certifications) ? findRequiredCerts('rescue', certifications).studentNumber : '',
        languageId: dmFormInfo.languageId || formData.languageId,
        regionId: dmFormInfo.regionId || formData.regionId,
        finishTime: dmFormInfo.finishTime || formData.finishTime,
        handwrittenSignatureUrl: dmFormInfo.signatureUrl,
        instructorNumber: dmFormInfo.instructorInfoDTO ? dmFormInfo.instructorInfoDTO.instructorNumber : formData.instructorNumber,
        storeNumber: dmFormInfo.storeInfoDTO ? dmFormInfo.storeInfoDTO.storeNumber : formData.storeNumber,
        aowFiles: dmFormInfo.aowFiles ? dmFormInfo.aowFiles : [],
        efrfiles: dmFormInfo.efrfiles ? dmFormInfo.efrfiles : [],
        rescueFiles: dmFormInfo.rescueFiles ? dmFormInfo.rescueFiles : []
      })
    }
  }, [certifications, dmFormInfo])

  return userInfo && certifications && Object.keys(dmFormInfo).length > 0 ? (
    <>
      <Notification position="MEMBER_APP" />
      <Header
        code={dmFormInfo.code}
        text={<FormattedMessage id="dm-form-title" />}
      />
      <DiverInfo diver={userInfo} />
      <WhiteSpace />
      <certInfoEditableContext.Provider value={isCertInfoEditable(dmFormInfo.status)}>
        <RequiredCerts
          aow={findRequiredCerts('aow', certifications)}
          rescue={findRequiredCerts('rescue', certifications)}
          efr={findRequiredCerts('efr', certifications)}
          expired={isEfrExpired(certifications)}
          aowFiles={formData.aowFiles}
          efrfiles={formData.efrfiles}
          efri={formData.efri}
          rescueFiles={formData.rescueFiles}
        />
      </certInfoEditableContext.Provider>
      <WhiteSpace />
      <CardLayout
        title={<FormattedMessage id="dm-form-training-info" />}
        status={isTrainingInfoCompleted(formData)}
      >
        <SearchInstructor
          id={"instructor"}
          type={"NONE"}
          editable={isAppEditable(dmFormInfo) && dmFormInfo.status !== 'INSTRUCTOR_CREATED'}
          instructor={dmFormInfo.instructorInfoDTO}
          instructorNumber={formData.instructorNumber}
          onSuccess={(result) => {
            updateFormData({ instructorNumber: result.instructorNumber });
          }}
        />
        {(isAppEditable(dmFormInfo) || formData.storeNumber) && (
          <SearchStore
            placeholder={formatMessage({ id: 'dm-form-store-warning' })}
            editable={isAppEditable(dmFormInfo) && dmFormInfo.status !== 'INSTRUCTOR_CREATED'}
            store={dmFormInfo.storeInfoDTO}
            // storeNumber={storeInfo.storeNumber}
            onSuccess={(result) => {
              updateFormData({ storeNumber: result.storeNumber });
            }}
          />
        )}
        <DatePickerComponent
          maxDate={new Date()}
          extra={formatMessage({ id: 'common-select' })}
          title={formatMessage({ id: 'dm-form-date-warning' })}
          disabled={dmFormInfo.status === 'INSTRUCTOR_CREATED' || !isAppEditable(dmFormInfo)}
          onChange={(date, format) => format ? updateFormData({ finishTime: formatDate(date, 'YYYY-MM-DD') }) : updateFormData({ finishTime: date })}
          value={formData.finishTime || ''}>
          <FormattedMessage id="dm-form-complete-date" />
        </DatePickerComponent>
      </CardLayout>
      <WhiteSpace />
      <AddressAndLangOption
        onValueChange={updateFormData}
        languageId={formData.languageId}
        regionId={formData.regionId}
        editable={isAppEditable(dmFormInfo)} />
      <WhiteSpace />
      <Signature
        filePath={`${profiles.profile.email}/${userInfo.affiliateId}`}
        initSignature={dmFormInfo.signatureUrl}
        modified={isAppEditable(dmFormInfo)}
      />
      {isAppEditable(dmFormInfo) && <>
        <WhiteSpace />
        <Agreement
          checkedChange={updateFormData}
          checkedTimes={formData.checkedTimes}
          checkedAgreement={formData.checkedAgreement}
        // checkStatus={checkStatus}
        />
      </>}
      <WhiteSpace size="lg" />
      <ApplicationStatus
        formData={formData}
        dmFormInfo={dmFormInfo} />
      <WhiteSpace size="lg" />
    </>
  ) : null;
};

export default DMFormPage;
