import { Form, Button } from 'antd-mobile-v5';
import { useHistory } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import React, { useState } from 'react';

import {
  formatDate,
  isProduction,
  IsNotPc,
  getParameterByName,
} from '../../components/common/utils';
import {
  useIssueCertsCreate,
  useErrorModal,
  useProfile,
  issueCertsOptions,
} from '../../services/hooks';
import AddStore from '../../components/FormTools/AddStore';
import AddStudents from './AddStudents';
import TrainingForm from './TrainingForm';
import AddInstructor from './AddInstructor';

// const initialValues = {
//   email: 'a@b11.com',
//   firstName: 'xinhua',
//   lastName: 'huang',
//   gender: 'M',
//   addressCountryId: 51,
//   countryId: 51,
//   addressStateId: 225,
//   stateId: 226,
//   address: '光华路soho',
//   // birthDate: dayjs().subtract(20, 'year').toDate(),
//   courseDate: IsNotPc() ? dayjs().toDate() : dayjs().format('YYYY-MM-DD'),
// };

const isStore = (profile) => profile && profile.storeInfo;

export default function IssueCertsNewPage({ options }) {
  const type = getParameterByName('type');
  const { data: profile } = useProfile();
  // const { profile, userInfo } = useSelector((state) => ({
  //   profile: state.data.profile,
  //   userInfo: state.data.userInfo,
  // }));
  const initialValues = {
    gender: '',
    addressCountryId: '',
    countryId: '',
    addressStateId: '',
    // instructorName: 'Test User',//userInfo.englishName,
    stateId: '',
    storeNumber: isStore(profile) ? profile.storeInfo.storeNumber : '',
    // birthDate: dayjs().month(5).date(15).subtract(25, 'year').format('yyyy-mm-dd'),
    courseDate: IsNotPc() ? dayjs().toDate() : dayjs().format('YYYY-MM-DD'),
    // courseDate: dayjs().toDate()
  };

  const layout = 'horizontal';
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const { push, replace } = useHistory();
  const [instructorSearchResult, setInstructorSearchResult] = useState({});
  const [storeSearchResult, setStoreSearchResult] = useState(
    profile ?.storeInfo || {}
  );
  const [students, setEfrPublicApplicationSubmitItemDTOS] = useState([]);

  const showErrorDialog = useErrorModal();

  const onFinish = (data) => {
    if (profile.storeInfo && !instructorSearchResult.instructorName) {
      form.setFields([
        {
          name: 'instructorNumber',
          errors: [formatMessage({ id: 'form-instructornum-empty-error' })],
        },
      ]);
      return;
    }
    if (
      profile.storeInfo &&
      form.getFieldValue('instructorNumber') &&
      !instructorSearchResult
    ) {
      form.setFields([
        {
          name: 'instructorNumber',
          errors: [formatMessage({ id: 'form-instructornum-verify-error' })],
        },
      ]);
      return;
    }

    if (students.length === 0) {
      showErrorDialog(formatMessage({ id: 'student-search-list-empty' }));
      // form.setFields([
      //   {
      //     name: 'email',
      //     errors: [formatMessage({ id: 'student-search-list-empty' })],
      //   },
      // ]);
      return;
    }
    if (form.getFieldValue('storeNumber') && !storeSearchResult) {
      form.setFields([
        {
          name: 'storeNumber',
          errors: [formatMessage({ id: 'form-storenum-verify-error' })],
        },
      ]);
      return;
    }

    const dtos = students.map((dto) => {
      const d = { ...dto };
      d.birthDate = formatDate(data.birthDate, 'YYYY-MM-DD');
      data.addressCountryId = parseInt(data.addressCountryId, 10);
      if (data.addressCountryId === 51) {
        data.addressStateId = parseInt(data.addressStateId, 10);
      } else {
        data.addressStateId = -1;
      }
      return d;
    });
    data.students = dtos;
    data.courseDate = formatDate(data.courseDate, 'YYYY-MM-DD');
    data.countryId = parseInt(data.countryId, 10);
    if (data.countryId === 51) {
      data.stateId = parseInt(data.stateId, 10);
    } else {
      data.stateId = -1;
    }
    data.certificationId = isProduction() ? 5160 : 5097;

    submit(data);
  };

  const { loading, run: submit } = useIssueCertsCreate(type, {
    onSuccess: (response) => {
      if (type === 'CONFINED_WATER_DIVER' || type === 'SCUBA_DIVER') {
        push(`/issue_certs/list?type=${type}`);
      } else {
        push(`/application_submit_success?type=${type}`, { code: response.code, paid: false, paymentType: type });
      }
    },
  });

  return (
    <>
      <AddStudents
        onChange={setEfrPublicApplicationSubmitItemDTOS}
        type={type}
      />
      <Form
        layout={layout}
        disabled={loading}
        form={form}
        validateTrigger="onSubmit"
        onFinish={onFinish}
        initialValues={initialValues}
        footer={
          <Button
            block
            type="submit"
            color="primary"
            loading={loading}
            loadingText={formatMessage({ id: 'common-submit-loading' })}
          >
            <FormattedMessage id="common-confirmRequest" />
          </Button>
        }
      >
        <Form.Header>
          {formatMessage({ id: 'dm-form-training-info' })}
        </Form.Header>
        {isStore(profile) && (
          <AddInstructor
            form={form}
            onSearchResult={setInstructorSearchResult}
            type={issueCertsOptions[type].instructorType}
          />
        )}
        <AddStore
          form={form}
          readOnly={profile ?.storeInfo}
          initialStore={profile ?.storeInfo}
          onSearchResult={setStoreSearchResult}
          disabled={loading}
        />
        <TrainingForm form={form} disabled={loading} />
      </Form>
    </>
  );
}
