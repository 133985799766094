import { Empty, List } from 'antd-mobile-v5';
import { LeftOutline, RightOutline } from 'antd-mobile-icons';
import { Pagination, WhiteSpace } from 'antd-mobile';
import { useIntl, FormattedMessage } from 'react-intl';
import React from 'react';

import { formatLocalDate } from '../../utils/time';
import { issueCertsOptions, useIssueCertsList } from '../../services/hooks';
import FormHeader from './FormHeader';
import Loading from '../../components/Loading';
import { getParameterByName } from '../../components/common/utils';

export default function PicHistoryPage() {
  const type = getParameterByName('type');
  const { loading, data, pagination } = useIssueCertsList(type);
  const { formatMessage } = useIntl();

  const getStatus = ({status}) => {
    switch(status) {
      case 'PAID':
        return formatMessage({id: 'order-status-toship'});
      case 'SHIPPED':
        return formatMessage({id: 'order-status-shipped'});
      case 'SUCCESS':
      case 'CLOSE':
        return formatMessage({id: 'order-status-completed'});
      default:
        return formatMessage({id: 'order-status-completed'});
    }
  }

  if (loading) return <Loading />;
  if (data.total === 0)
    return (
      <>
        <FormHeader
          text={formatMessage({ id: issueCertsOptions[type].nameId })}
          type={'list'}
        />
        <Empty description={formatMessage({ id: 'form-apps-empty' })} />
      </>
    );
  return (
    <>
      <FormHeader
        text={formatMessage({ id: issueCertsOptions[type].nameId })}
        type={'list'}
      />
      <List>
        {data.list.map((item) => (
          <List.Item
            key={item.id}
            description={formatLocalDate(item.payTime)}
            extra={
              getStatus(item)
            }
          >
            {formatMessage({ id: 'issue-certs-pic-amount' }) + item.number}
          </List.Item>
        ))}
      </List>
      <WhiteSpace />

      <Pagination
        total={pagination.totalPage}
        current={pagination.current}
        locale={{
          prevText: (
            <span>
              <LeftOutline />
              <FormattedMessage id="form-pre-page" />
            </span>
          ),
          nextText: (
            <span>
              <FormattedMessage id="form-next-page" />
              <RightOutline />
            </span>
          ),
        }}
        onChange={pagination.onChange}
      />
    </>
  );
}
