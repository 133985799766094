import React, { Component } from 'react';
import { Checkbox, Button, WhiteSpace, WingBlank } from 'antd-mobile';
import { FormattedMessage } from 'react-intl';
import RenewStatements from './RenewStatements'

const AgreeItem = Checkbox.AgreeItem

class MemberRenew extends Component {

  state = {
    selectedEfr: true
  }

  getExcludeEfr = (memberRenewList) => {
    const { renewalMemberDTOS } = memberRenewList
    return renewalMemberDTOS.find(item => !item.isEfr)
  }

  selectedEfr = (e) => {
    const { memberRenewList } = this.props
    const { isEfr, isFreediver, isScubadiver } = memberRenewList
    if (isEfr && !isFreediver && !isScubadiver) return
    if (e.target.checked) {
      this.setState({ selectedEfr: true })
    } else {
      this.setState({ selectedEfr: false })
    }
  }

  filterIsEfr = () => {
    const { memberRenewList } = this.props
    const { selectedEfr } = this.state
    if (selectedEfr) {
      return memberRenewList.renewalMemberDTOS.find(item => item.isEfr)
    }
    return {}
  }

  submitRenewAction = () => {
    const { memberRenewList } = this.props
    const { selectedEfr } = this.state
    if (memberRenewList.isEfr && selectedEfr) {
      this.props.submitMemberRenew({ goodsId: this.filterIsEfr().goodsId })
    } else {
      this.props.submitMemberRenew({ goodsId: this.getExcludeEfr(memberRenewList).goodsId })
    }
  }

  render() {
    const { memberRenewList } = this.props
    const { selectedEfr } = this.state
    return JSON.stringify(memberRenewList) !== "{}" && <div className='renew'>
      <WhiteSpace size="xl" />
      <h2><FormattedMessage id="renew-member-title" /></h2>
      <WhiteSpace size="xl" />
      <WhiteSpace size="xl" />
      <h3><FormattedMessage id="renew-subtitle" /></h3>
      <WhiteSpace size="xl" />
      <div className='checkbox-list'>
        <div className='renew-main'>{memberRenewList.title}</div>
        {memberRenewList.isEfr && <AgreeItem className='renew-efr' checked={selectedEfr} onChange={e => this.selectedEfr(e)}>EFRI（<FormattedMessage id="renew-exclude-efr" />）</AgreeItem>}
      </div>
      <WhiteSpace size="xl" />
      <WingBlank>
        <RenewStatements memberRenewList={memberRenewList} selectedEfr={this.filterIsEfr()} />
      </WingBlank>
      <div className='renew-footer'>
        <Button type="ghost" inline className='renew-footer-btn am-button-borderfix' onClick={() => this.props.push('/myProfile')}><FormattedMessage id="renew-back" /></Button>
        <Button type="primary" inline className='renew-footer-btn' onClick={() => this.submitRenewAction()}><FormattedMessage id="common-confirmRequest" /></Button>
      </div>
    </div>
  }
}

export default MemberRenew