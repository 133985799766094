import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { List, WingBlank } from 'antd-mobile'
import { FormattedMessage } from 'react-intl';
import UploadOssFile from '../../components/common/UploadOssFile'
import UploadFileWrapper from '../../components/common/UploadFileWrapper'
import CardLayout from '../DiveMasterForm/CardLayout'
import { removeEfrFile, uploadEfrOssFile } from '../../reducers/efri'
import { isEfrExpired } from '../../components/common/findCerts'

const Item = List.Item

const VerifyEfr = ({ cert, editable, attachFileList }) => {

  const dispatch = useDispatch()
  const { certifications, ossSignature } = useSelector((state) => ({
    certifications: state.data.certifications,
    ossSignature: state.oss.ossSignature
  }));

  const filename = () => `${ossSignature.dir}${new Date().getTime()}`

  const complete = cert || (attachFileList && attachFileList.length > 0)

  return <CardLayout title={<FormattedMessage id="owsi-cert-conditions" />} status={complete}>
    {!cert ? (
      <UploadFileWrapper labelName={"EFR"} editable={editable}>
        <UploadOssFile
          fileList={attachFileList}
          editable={editable}
          type={"EFRI"}
          fileName={filename()}
          onChange={(formData) => {
            dispatch(uploadEfrOssFile({ formData }))
          }}
          onDelete={(index) => dispatch(removeEfrFile(attachFileList[index]))}
        />
        {editable && <WingBlank>
          <div className="small-font gray">
            <FormattedMessage id="common-upload-file-reminder" />
          </div>
        </WingBlank>}
        {isEfrExpired(certifications) && <WingBlank>
          <div className="small-font gray">
            <FormattedMessage id="dm-efr-expired" />
          </div>
        </WingBlank>}
      </UploadFileWrapper>
    ) : (
      <Item>
        EFR {`（${cert}）`}
      </Item>
    )}
  </CardLayout>
}

export default VerifyEfr