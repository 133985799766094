import { combineReducers } from 'redux'
import {createSlice} from "@reduxjs/toolkit";

export const ossSignatureSlice = createSlice({
  name: "ossSignature",
  initialState: {},
  reducers: {
    getOssSignature: {
      reducer(state, action){
        const data = action.payload;
        return data
      }
    },
    ossSignatureRequest: {
      reducer(state, action) {
        return state
      }
    },
    ossSignatureSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return { ...data }
      }
    },
    ossSignatureFailed: {
      reducer(state, action) {
        return state
      }
    }
  }
});

export const { getOssSignature, ossSignatureRequest, ossSignatureSuccess, ossSignatureFailed } = ossSignatureSlice.actions

export const getOssSignatureRequest = {
  request: () => ossSignatureRequest(),
  success: (data) => ossSignatureSuccess(data),
  failure: (error) => ossSignatureFailed(error),
}

const ossReducer = combineReducers({
  ossSignature: ossSignatureSlice.reducer
})

export default ossReducer