import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Header from '../../components/common/FormHeader'
import { specialtyCd, specialtyMapping, removeExpires, getSubmitOssFormat } from '../../components/common/utils'
import { getCookie } from '../../components/common/cookie'
import Signature from '../../components/common/Signature'
import {
  Button,
  WhiteSpace,
  WingBlank,
  Modal
} from 'antd-mobile';
import { FormattedMessage, useIntl } from 'react-intl';
import { getStudentInfo, removeSiFile, addSi, removeSi, uploadSifile, clearStudentInfo, clearSiDetail } from '../../reducers/ui'
import DirectApplySi from '../SpecialtyInstructor/DirectApplySi'
import UploadOssFile from '../../components/common/UploadOssFile'
import UploadFileWrapper from '../../components/common/UploadFileWrapper'
import CardLayout from '../DiveMasterForm/CardLayout'
import CourseInfo from './CourseInfo'
import SearchInstructor from '../../components/common/SearchInstructor';
import { cdSubmitSiApp } from '../../actions'

const CdApplication = () => {

  const lang = getCookie('language')
  const intl = useIntl()
  const dispatch = useDispatch()
  const [instructorNumber, setInstructorNumber] = useState('')
  const [completionDate, setCompletionDate] = useState('');
  const [storeNumber, setStoreNumber] = useState()
  const [location, setLocation] = useState('')
  const { instructorInfo, siDetail, ossSignature, signatureUrl, credentials } = useSelector(state => ({
    instructorInfo: state.ui.studentInfo,
    siDetail: state.ui.siDetail,
    ossSignature: state.oss.ossSignature,
    signatureUrl: state.ui.signatureUrl,
    credentials: state.data.credentials,
  }))

  useEffect(() => {
    return () => {
      dispatch(clearStudentInfo())
      dispatch(clearSiDetail())
    };
  }, []);

  const submitApplication = () => {
    if (!siDetail || !signatureUrl || !location || !completionDate) {
      Modal.alert(
        intl.formatMessage({ id: 'dm-form-submit-error' }),
        intl.formatMessage({ id: 'dm-form-submit-error-info-no-agreement' }),
        [{ text: intl.formatMessage({ id: 'common-close' }) }]
      );
      return
    }
    const siAppItemList = siDetail.siApplicationDetailDTOS.map(item => {
      if (item.attachFile) {
        const { credentialId } = item
        return { credentialId, attachFile: removeExpires(item.attachFile) }
      }
      return item
    })
    const submitData = {
      "handwrittenSignatureUrlCD": getSubmitOssFormat(signatureUrl),
      "instructorNumber": parseInt(instructorNumber),
      "number": siDetail.siApplicationDetailDTOS.length,
      "siApplicationSubmitDetailDTOList": siAppItemList,
      "courseDate": completionDate,
      "courseLocation": location
    }
    if (storeNumber) submitData.storeNumber = storeNumber
    dispatch(cdSubmitSiApp(submitData))
  }

  const hasNoSiCertList = () => {
    return specialtyCd.filter(item => {
      const credsNumArr = instructorInfo.data.credentials.map(item => item.credentialNumber)
      return credsNumArr.indexOf(item.spi) === -1 && credsNumArr.indexOf(item.spt) === -1
    })
  }

  const getApplicableList = (type) => {
    let directApplyList = []
    let needAttachList = []
    hasNoSiCertList().map(cert => {
      if (!cert.star) directApplyList.push(cert)
      else needAttachList.push(cert)
    })
    const certsNumArr = instructorInfo.data.certifications.map(item => item.certificationNumber)
    const credentialsNumArr = credentials.map(item => item.credentialNumber)
    for (let i = needAttachList.length - 1; i >= 0; i--) {
      // 判断独行侠专长
      if (needAttachList[i].sp === 4255 && (certsNumArr.indexOf(3147) > -1 ||
        certsNumArr.indexOf(4255) > -1 ||
        certsNumArr.indexOf(3148) > -1 ||
        certsNumArr.indexOf(3149) > -1 ||
        certsNumArr.indexOf(62) > -1 ||
        certsNumArr.indexOf(3460) > -1 ||
        certsNumArr.indexOf(3461) > -1)
      ) {
        directApplyList.push(needAttachList[i])
        needAttachList.splice(i, 1)
      } else {
        if (certsNumArr.indexOf(needAttachList[i].sp) > -1) {
          directApplyList.push(needAttachList[i])
          needAttachList.splice(i, 1)
        }
      }
    }
    // 验证cd的Trainer证书
    if (type === 'directApply') {
      for (let i = directApplyList.length - 1; i >= 0; i--) {
        if (credentialsNumArr.indexOf(directApplyList[i].spt) < 0) {
          directApplyList.splice(i, 1)
        }
      }
      return directApplyList
    } else {
      for (let i = needAttachList.length - 1; i >= 0; i--) {
        if (credentialsNumArr.indexOf(needAttachList[i].spt) < 0) {
          needAttachList.splice(i, 1)
        }
      }
      return needAttachList
    }
  }

  const selectedSi = (e, val) => {
    if (e.target.checked) {
      dispatch(addSi({ credentialId: val }))
    } else {
      dispatch(removeSi({ credentialId: val }))
    }
  }

  const checkChecked = (item) => {
    if (siDetail && siDetail.siApplicationDetailDTOS) {
      const checkedItem = siDetail.siApplicationDetailDTOS.find(i => item.spi === i.credentialId)
      return !!checkedItem
    }
  }

  const findAttachById = (id) => {
    if (siDetail && siDetail.siApplicationDetailDTOS) {
      const detailData = siDetail.siApplicationDetailDTOS.find(item => item.credentialId === id)
      return detailData ? detailData.attachFile : null
    }
    return null
  }

  const fileName = (type) => `${ossSignature.dir}${type}/${new Date().getTime()}`

  return <>
    <Header text={<FormattedMessage id="si-form-title" />} />
    <SearchInstructor
      id={"instructor"}
      type={"NONE"}
      placeholder={intl.formatMessage({ id: 'si-cd-instructor-num-tip' })}
      editable={true}
      onSuccess={(result) => {
        setInstructorNumber(result.instructorNumber)
        dispatch(getStudentInfo({ instructorNumber: result.instructorNumber }))
      }}
    />
    <WhiteSpace />
    <CourseInfo
      completionDate={completionDate}
      location={location}
      setCompletionDate={setCompletionDate}
      setLocation={setLocation}
      setStoreNumber={setStoreNumber}
      storeNumber={storeNumber} />
    {instructorInfo && instructorInfo.data && <>
      <WhiteSpace />
      <DirectApplySi
        directData={getApplicableList('directApply')}
        checkedFn={selectedSi}
        checked={checkChecked} />
      <WhiteSpace />
      <CardLayout title={<FormattedMessage id="si-upload-attach" />} status={"none"}>
        {getApplicableList().map((item) => <UploadFileWrapper labelName={specialtyMapping[item.spi][lang]}>
          <UploadOssFile
            fileList={findAttachById(item.spi) || []}
            editable={true}
            type={"SI"}
            fileName={fileName(item.spi)}
            onChange={(formData) => {
              dispatch(uploadSifile({ formData }))
            }}
            onDelete={(index) => dispatch(removeSiFile({ credentialId: item.spi, url: findAttachById(item.spi)[index] }))} />
          <WingBlank>
            <div className="small-font gray">{item.desc[lang]}</div>
          </WingBlank>
        </UploadFileWrapper>)}
      </CardLayout>
    </>}
    <WhiteSpace />
    <Signature
      modified={true}
      filePath={'signature'} />
    <WhiteSpace size="xl" />
    <WingBlank>
      <Button type="primary" onClick={() => submitApplication()}><FormattedMessage id="dm-form-submit" /></Button>
      <WhiteSpace size="xl" />
    </WingBlank>
  </>
}

export default CdApplication