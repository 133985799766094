import React, { Fragment } from 'react';
import { WhiteSpace, Accordion } from 'antd-mobile';
import ResourcesItem from './ResourcesItem';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import styles from './ResourcesList.module.css';

const ResourcesListPage = () => {
  const { memberLibrary } = useSelector((state) => ({
    memberLibrary: state.ui.memberLibrary,
  }));

  return memberLibrary && memberLibrary.length > 0 ? (
    <>
      <div className={styles.header}>
        <h2 className={styles.mainTitle}>
          <FormattedMessage id="resources-mainTitle" />
        </h2>
        <p className={styles.introduction}>
          <FormattedMessage
            id="resources-introduction"
            values={{
              link: (
                <span className={styles.link}
                >
                  <FormattedMessage id="resources-link" />
                </span>
              ),
            }}
          />
        </p>
      </div>
      {memberLibrary.map((data) => (
        <Fragment key={data.title}>
          <Accordion defaultActiveKey="0" key={data.title}>
            <Accordion.Panel
              header={<div className={styles.typeTitle}>{data.title}</div>}
            >
              <ResourcesItem key={data.id} resource={data} />
            </Accordion.Panel>
          </Accordion>
          <WhiteSpace />
        </Fragment>
      ))}
    </>
  ) : null;
};

export default ResourcesListPage;
