
/* global wx */
import React from 'react';
import thumbnail from '../../assets/images/share_thumbnail.png';
import { getWechatJSConfig } from '../../services/api'
import * as config from '../../services/config';
import { isiOS, debug } from './utils'

const { origin } = document.location;
let wechatConfigResolve, wechatConfigReject;
let wechatConfiged = new Promise((resolve, reject) => {
  wechatConfigResolve = resolve;
  wechatConfigReject = reject;
});

export const WECHAT_JSSDK_CONFIG = {
  debug: false,
  openTagList: [
    'wx-open-launch-weapp',
    'wx-open-launch-app',
    'wx-open-subscribe',
    'wx-open-audio'
  ],
  jsApiList: [
    'updateAppMessageShareData',
    'updateTimelineShareData',
    'chooseImage',
    'previewImage',
    'uploadImage',
    'downloadImage'
  ],
};

export const configWechatJSSDK = (url = document.location.href) => getWechatJSConfig(url).then((response) => {
  console.log(`Config Wechat JSSDK for ${url}...`)
  console.log("API response of ios wechat jssdk signature: ", JSON.stringify(response));
  const jsConfig = {
    ...WECHAT_JSSDK_CONFIG,
    ...response.data,
  };
  debug("Wechat JSSDK config: ", jsConfig);
  /* global wx */
  wx.config(jsConfig);
  wx.ready((e) => {
    document.addEventListener('WeixinOpenTagsError', function (e) {
      console.error("!!!!!!!!!!!!!!!!WeixinOpenTagsError!!!!!!!!!!!!!!!!!!!!!")
      console.error(e.detail.errMsg); 
      console.error("!!!!!!!!!!!!!!!!WeixinOpenTagsError!!!!!!!!!!!!!!!!!!!!!")
    });

    document.addEventListener('WeixinOpenTagsReady', function (e) {
      console.error("!!!!!!!!!!!!!!!!WeixinOpenTagsReady!!!!!!!!!!!!!!!!!!!!!")
      console.error(e); 
      console.error("!!!!!!!!!!!!!!!!WeixinOpenTagsReady!!!!!!!!!!!!!!!!!!!!!")
    });
    console.log('Wechat JSSDK config is done.');
  });
  wx.error((e) => {
    console.log('Wechat JSSDK config failed: ', e);
  });

  // props.saveWechatJsConfig(response.data);
});

const defaultShareConfig = {
  title: document.title,
  desc: '',
  link: document.location.href,
  imgUrl: origin + thumbnail,
  type: 'link'
}

export const configWechatJS = (config) => {
  const jsConfig = Object.assign({
        debug: false,
        openTagList: [
          'wx-open-launch-weapp',
          'wx-open-launch-app',
          'wx-open-subscribe',
          'wx-open-audio'
        ],
        jsApiList: [
          'updateAppMessageShareData',
          'updateTimelineShareData',
          'chooseImage',
          'previewImage',
          'uploadImage',
          'downloadImage'
        ],
      }, config);
  debug("Wechat JSSDK config: ", jsConfig);
  wx.config(jsConfig);
  wx.ready(() => {
    wechatConfigResolve();
    console.log('Wechat JSSDK config succeed.');
  });
  wx.error((e) => {
    wechatConfigReject();
    console.log('Wechat JSSDK config failed: ', e);
  });
};

export const configWechatSharing = (shareConfigFun) => {
  wechatConfiged.then(() => {
    // if (shareConfigFun) {
    //   shareConfigFun().then(config => {
    //     wx.updateTimelineShareData(config);
    //     wx.updateAppMessageShareData(config);
    //     console.log('Use passed in sharing config: ', config);
    //   })
    // } else {
    //   wx.updateTimelineShareData(defaultShareConfig);
    //   wx.updateAppMessageShareData(defaultShareConfig);
    //   console.log('Use default sharing config: ', defaultShareConfig);
    // }
  });
}

export const withWechat = (Wrapped) => {
  class WithWechatComponent extends React.Component {
    componentDidMount() {
      // if(isiOS()) {
      //   console.log(`Wechat signature for ios.`, this.props.wechatSignature);
      //   if(this.props?.wechatSignature?.signature) {
      //     configWechatJS(this.props.wechatSignature);
      //     configWechatSharing(this.props.wechatShareConfig);
      //   }
      // }else {
      //   console.log(`Wechat signature for non-ios.`);
      //   getWechatJSConfig(document.location.href).then(
      //     response => {
      //       console.log(`Wechat JSSDK signature returned: ${JSON.stringify(response)}`);
      //       configWechatJS(response.data);
      //       configWechatSharing(this.props.wechatShareConfig);
      //     }
      //   );
      // }
    }
    render() {
      return <Wrapped {...this.props} />;
    }
  }

  return WithWechatComponent;
};

export const getWeChatAuthUrl = (referer) => {
  const isQA = document.location.origin === config.URL_qa;
  const isProduction = document.location.origin === config.URL_prod;
  const WECHAT_AUTH_CALLBACK = document.location.origin;
  const WECHAT_WEB_APP_ID = isProduction ? 'wxb6e61a33874ff326' : isQA ? 'wxf2b56cb266f39be0' : 'wxf2b56cb266f39be0';
  const state = 'padidiving';
  const isPatronus = referer.indexOf('patronus') > 0 && referer.indexOf('post') > 0
  const authCallbackUrl = `${WECHAT_AUTH_CALLBACK}/authorize?referer=${encodeURIComponent(referer)}${isPatronus ? '&isPatronus=true' : ''}`;
  debug('Wechat auth callback: ', authCallbackUrl)
  return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${WECHAT_WEB_APP_ID}&redirect_uri=${encodeURIComponent(authCallbackUrl)}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;
}
