import { getPadiWxId } from './utils/user';
import { isUnionId, isWechat } from './utils/wechat';
import { debug, getReferer, isProduction } from './utils/env';

function getWeChatAuthUrl() {
  const WECHAT_WEB_APP_ID = isProduction()
    ? 'wxb6e61a33874ff326'
    : 'wxf2b56cb266f39be0';
  const state = 'padidiving';
  const referer = getReferer();
  const isPatronus =
    referer.indexOf('patronus') > 0 && referer.indexOf('post') > 0;
  const authCallbackUrl = `${origin}/authorize?referer=${referer}${
    isPatronus ? '&isPatronus=true' : ''
  }`;
  debug('WECHATAUTH: Wechat auth callback: ', authCallbackUrl);
  return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${WECHAT_WEB_APP_ID}&redirect_uri=${encodeURIComponent(
    authCallbackUrl
  )}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;
}

export default function WechatAuth({children}) {
  if (isWechat() 
      && !isUnionId(getPadiWxId()) 
      && window.location.pathname.indexOf('/authorize') !== 0 
      && window.location.pathname.indexOf('/redirected_uri') !== 0
  ) {
    window.location.href = getWeChatAuthUrl();
    return null;
  }
  return children;
}