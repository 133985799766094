import React from 'react';
import { Checkbox, Flex, List } from 'antd-mobile';

import { formatMoney } from '../../components/common/utils';

import styles from '../../pages/PriceList/PriceList.module.css';

const PriceItem = ({ item, checked, toggleSelect }) => {
  return (
    <List.Item
      align="bottom"
      thumb={
        <Checkbox
          onChange={(e) => toggleSelect(item.itemNo, e.target.checked)}
          checked={checked}
        />
      }
      wrap
    >
        {item.title}
      <List.Item.Brief>
        <Flex justify="between">
          <span className={styles.price}>
            {formatMoney(item.price, item.currencyType)}
          </span>
        </Flex>
      </List.Item.Brief>
    </List.Item>
  );
};

export default PriceItem;
