const tc = {
  "common-errorTitle": "錯 誤",
  "common-codeInput": "6位數字驗證碼",
  "common-getCode": "獲取驗證碼",
  "common-validate": "驗證",
  "common-confirm": "確 認",
  "common-close": "關 閉",
  "common-isNotEmail": "請輸入正確的郵箱地址",
  "common-select": "請選擇",
  "common-errorStatus": "系統出錯，請稍後再試，謝謝您的理解。",
  "common-passwordInput": "請輸入您設置的密碼",
  "common-confirmPassword": "請再次輸入您設置的密碼",
  "common-reset": "請輸入8-20位、包含大寫字母、小寫字母、數字以及符號的密碼",
  "common-confirmRequest": "提 交",
  "common-errorMobile": "請輸入正確的手機號",
  "common-prompt": '提 示',
  "common-cancel": "取消",
  "common-comfirmBtn": "確定",
  "common-updateCerts": "您有新的證書",
  "common-search-error": "輸入信息有誤，請重新輸入",
  "common-search-title": "查詢",
  "common-back": "返回",
  "common-check-file": "查看資料",
  "common-agreement": "隱私政策",
  "common-agreement-hint": "使用 PADI 提供的服務，即表示您同意我們的{link}",
  "common-info-agreement-hint": "我已認真閱讀、理解並同意{link}",
  "common-info-agreement": "個人信息跨境傳輸授權書",
  "common-back-home": "返回首頁",
  "common-upload-file-reminder": "請上傳潛水證書的正反面照片，或者電子證書的截圖。",
  "common-applicant-pay": "申請人支付",
  "common-instructor-pay": "教練支付",
  "common-course-date": "課程完成日期",
  "common-email": "電子信箱",
  "common-name": "姓名",
  "common-address-detail": "地址信息",
  "common-country": "國家",
  "common-address": "詳細地址",
  "common-address-reminder": "請輸入詳細地址",
  "common-Province": "省市",
  "common-yes": "是",
  "common-no": "否",
  "common-skip": "跳過",
  "common-submit-loading": "提交中",
  "common-submit-success": "提交成功",
  "common-search-loading": "查詢中",
  "common-loading": "加載中",
  "common-know": "我知道了",
  "common-instructor-name": "教練姓名",
  "common-error-verification-code": "請輸入正確的6位數字驗證碼",
  "order-status-toship": "待發貨",
  "order-status-shipped": "已發貨",
  "order-status-completed": "已完成",
  "register-email-not-verified": "請輸入驗證碼並點擊\"驗證\"完成郵箱驗證。",
  "register-mobile-not-verified": "請輸入驗證碼並點擊\"驗證\"完成手機驗證。",
  "common-check-agreement": "請先勾選同意協議",
  "common-agree": "我已閱讀並同意",
  "common-not-agree": "我不同意",
  "common-agreement-update": "協定更新",
  "common-load-no-more": "沒有更多了",
  "common-return-agreement1": "我已詳閱",
  "common-return-agreement2": " PADI會員規範",
  "common-return-and": "及",
  "common-return-agreement3": "退換貨辦法",
  "common-return-title": "退換貨協議",

  "notification-title": "溫馨提示",

  "student-search-list": "學員一覽",
  "student-search-added": "郵箱已添加。",
  "student-search-list-empty": "請輸入郵箱查找添加學員。",

  "form-email-empty-error": "郵箱不能為空",
  "form-email-format-error": "郵箱格式有誤",
  "form-email-verify-error": "未驗證郵箱，請點擊“查詢”進行郵箱驗證",
  "form-region-empty-error": "地區不能為空",
  "form-stateid-hint": "請選擇省市",
  "form-stateid-empty-error": "省市不能為空",
  "form-date-hint": "請選擇日期",
  "form-course-date-empty-error": "課程完成日期不能為空",
  "form-storenum-empty-error": "潛店編號不能為空",
  "form-storenum-format-error": "潛店編號有誤",
  "form-storenum-verify-error": '未驗證潛店信息，請點擊“查詢”進行潛店信息驗證',
  "form-instructornum-empty-error": "教練編號為空或未驗證",
  "form-instructornum-verify-error": '未驗證教練信息，請點擊“查詢”進行教練信息驗證',
  "form-store-address": "潛店地址",
  "form-student-info": "學員信息",
  "form-firstname-hint": "請輸入姓",
  "form-lastname-hint": "請輸入名",
  "form-firstname-error": "姓不能為空",
  "form-lastname-error": "名不能為空",
  "form-gender-empty-error": "性別不能為空",
  "form-birthdate-hint": "請選擇出生日期",
  "form-birthdate-empty-error": "出生日期不能為空",
  "form-address-empty-error": "地址不能為空",
  "form-app-info": "申請信息",
  "form-app-num": "申請編號",
  "form-app-status": "申請狀態",
  "form-app-submit-time": "申請提交時間：",
  "form-apps-empty": "暫無申請",
  "form-pre-page": "上一頁",
  "form-next-page": "下一頁",
  "form-app-submit-date": "提交日期：",
  "form-membernum-empty-error": "會員編號不能為空",
  "form-name-format-error": "格式有誤，請輸入英文字母組合",
  "form-name-format-hint": "不區分大小寫",
  "form-name-nochange-error": "提交的修改無變化，姓名不區分大小寫",
  "form-confirm-cert": "確認發證",
  "form-cert-success": "發證成功",
  "form-instructor-info": "教練信息",
  "form-current-status": "當前狀態：",
  "form-not-approved-reason": "不通過原因：",
  "form-upload-error": "請上傳圖片或者PDF文件",

  "youzan-binding-success": "綁定成功！",
  "youzan-binding-fail": "綁定失敗！",

  "customize-cert-ctm-btn": "自定義電子卡面",
  "customize-cert-title": "感謝為公益捐贈，自定義您的電子卡面",
  "customize-cert-create-pre": "創作屬於您的",
  "customize-cert-create-suffix": "電子卡面",
  "customize-cert-crop-btn": "確認上傳",
  "customize-cert-change-btn": "更換圖片",
  "customize-cert-upload-success": "圖片上傳成功，我們將盡快審核，請耐心等候。",
  "customize-cert-upload-failed": "圖片上傳遇到了問題，請稍候再試。",
  "customize-cert-upload-rejected": "您之前上傳的圖片因為以下原因未通過審核，請更換圖片重新上傳。",
  "customize-cert-hint1": "感謝您對海洋環保的關注和奉獻！您的捐助將用於支持海洋環保行動。請在捐助成功後點擊“我的”查看您的公益電子證書。",
  "customize-cert-hint2": "PADI 將對您上傳的圖片進行審核，待審核確認後，您的電子卡面將替換為您上傳的圖片。",
  "customize-cert-hint3": "請勿上傳違反法律規定的色情、暴力、虛假圖片，一旦發現，您的卡片被替換為默認卡面圖片。",
  "customize-cert-hint4": "我同意相關{link}。",
  "customize-cert-hint5": "最終解釋權歸 PADI 中國所有。",
  "customize-cert-hint6": "您可以兩指縮放、旋轉圖片。",
  "customize-cert-hint7": "點擊logo切換不同logo。",
  "customize-cert-agree-first": "請閱讀並勾選同意以上協議",
  "customize-upload-success": "上傳成功！",
  "customize-select-amount": "請選擇公益捐款金額。",

  "login-forgetEmail": "忘記電子信箱？請聯繫您的教練或發郵件至",
  "login-bottommsg": "目前 PADI 電子潛水證書，僅支持地址在中國大陸、台灣、香港、澳門的潛水員",

  "userHeader-userHeaderMsg": "我是 PADI 潛水員！",
  "userHeader-userheaderamount": "自 {year} 年起，我已考取 {certAmount} 張 PADI 潛水證書!",

  "certlist-noCert": "歡迎加入 PADI 大家庭！如果您剛剛完成 PADI 課程，你的證書正從海底深處向你飛奔而來，請耐心等待。",
  "certlist-certCountry": "發證地區",
  "certlist-storeName": "潛店名稱",
  "certlist-sendEmail": "沒有找到你的證書？或發現電子潛水證書不全，請發郵件至 {email} 獲得更多幫助。",
  "certlist-merge-certs": "證書缺失請點這裡",
  "certlist-merge-loading": "我們正在處理您的證書查找請求，感謝您的耐心等候。",
  "certlist-shareTextOne": "我是 PADI 潛水員！我要影響身邊更多的人!",
  "certlist-shareTextTwo": "點擊右上角，速速{share}給你的朋友們吧!",
  "certlist-shareOnceForEcards": "我是 PADI 潛水員！自 {year} 年起，我已考取 {length} 張 PADI 潛水證書！",
  "certlist-shareOnceForNoEcards": "我也想學潛水，考取 PADI 潛水證書， 大家一起吧！",
  "certlist-shareTwice": "{year} 新年小目標就 TA 了！",
  "certlist-nextCard": "考取你的下一張",
  "certlist-scuba": "水肺潛水證書",
  "certlist-freediver": "自由潛水證書",
  "certlist-mermaid": "美人魚證書",

  "sharecerts-isDiver": "如果您是潛水員，請掃描以下二維碼",
  "sharecerts-padiWeChat": "關注 PADI 官方微信公眾帳號：PADIDIVING",
  "sharecerts-checkCerts": "查看自己的潛水證書",
  "sharecerts-isNotDiver": "如果您不是潛水員，歡迎關注 PADI",
  "sharecerts-endMsg": "一起探索地球另外71%的美",

  "certdetail-storeMsg": "潛店",
  "certdetail-address": "地址",
  "certdetail-phone": "電話",
  "certdetail-instructorInfo": "教練",
  "certdetail-name": "姓名",
  "certdetail-instructorNum": "PADI 編號",
  "certdetail-myInfo": "學員詳情",
  "certdetail-diverNum": "PADI 編號",
  "certdetail-birth": "出生日期",
  "certdetail-certDate": "證書日期",
  "certdetail-expireDate": "證書有效至",
  "certdetail-switchCoverImage": "左右滑動選擇您想展示的電子卡面圖案",
  "certdetail-qualification": "資格",
  "certdetail-update-photo": "更新照片",
  "certdetail-reactivate": "再激活日期",

  "certDetailShareOnce": "我已是 PADI {ecardName}， 立即加入我們一起潛游全世界！",
  "certDetailShareTwice": "{year} 新年小目標就 TA 了！",

  "cardOptions-replaceCardHint": "溫馨提示",
  "cardOptions-replaceCardMsg": "您當前證書申請中已添加此級別的“{currentLevelInCart}”。是否確認更改為此“{currentCardOption}”？",
  "cardOptions-addToCart": "加入申請列表",
  "cardOptions-existing": "已加入申請列表",
  "cardOptions-buy": "立即申請",

  "cardRequest-non-mainland": "如需申請實體證書，請前往{url}。",
  "cardRequest-non-mainland-diver": "全球官網",
  "cardRequest-non-mainland-pro": "專業人士網站",
  "cardRequest-statusLoading": "正在查詢實體潛水證書申請記錄。。。",
  "cardRequest-statusError": "查詢實體潛水證書申請記錄時發生錯誤，請稍候再試。。。",

  "qrCodeWrapper-attention": "歡迎關注 PADI 官方微信帳號 PADIDIVING",
  "qrCodeWrapper-getMoreMsg": "獲取最新潛水資訊和潛水培訓課程活動信息",
  "qrCodeWrapper-service": "及享受最新潛水服務。",
  "qrCodeWrapper-dev": "此電子潛水證書服務由 PADI 官方開發完成",
  "qrCodeWrapper-Authorize": "請與PADI官方聯繫，獲取轉載授權！",
  "qrCodeWrapper-belongToPADI": "最終解釋權，歸 PADI 官方所有。",

  "emailLogin-login": "登 錄",
  "emailLogin-emailInput": "請輸入郵箱",
  "emailLogin-errorCode": "請輸入正確的驗證碼",

  "loginComponent-signUp": "立即註冊",
  "loginComponent-signUpTitle": "註冊",
  "loginComponent-errorPassword": "請輸入正確的郵箱地址及密碼",
  "loginComponent-passwordInput": "請輸入密碼",
  "loginComponent-forgotPassword": "找回密碼",
  "loginComponent-footer": "請輸入您的 PADI 統一登錄賬號和密碼登錄",
  "loginComponent-padiIntro": "PADI 全世界學習潛水的途徑，藍色星球的保護者大本營，和PADI一起擇善而行，了解更多請關注 PADIDIVING。",
  "reset-pwd-attention": "注意",
  "reset-pwd-desc": "重新設定密碼成功後，頁面可能沒有變化。此時您可以返回電子卡登錄頁面，使用新密碼進行登錄。",
  "reset-pwd-skip-now": "立即跳轉",

  "setEmail-errorEmail": "請輸入正確的郵箱地址以及6位數字驗證碼",
  "setEmail-title": "驗證郵箱",
  "setEmail-login": "立即登錄",

  "setPassword-errorConfirmPassword": "請確認您輸入的密碼是否一致",
  "setPassword-title": "設置密碼",

  "setProfile-errorName": "請輸入姓名（拼音或英文字母組合）",
  "setProfile-defaultName": "拼音或英文字母組合",
  "setProfile-errorGender": "請選擇性別",
  "setProfile-errorBirthDate": "請選擇出生年月日",
  "setProfile-name": "姓名",
  "setProfile-gender": "性別",
  "setProfile-chooseDate": "選擇日期",
  "setProfile-birthDate": "出生日期",
  "setProfile-skip": "跳過此步",
  "setProfile-footer": "如您願意，請協助完成您的個人信息，以便您可正常使用後續其他服務。謝謝您的支持！",
  "setProfile-title": "個人信息",
  "setProfile-firstName": "名",
  "setProfile-lastName": "姓",

  "setMobile-China": "大陸地區",
  "setMobile-Hongkong": "香港地區",
  "setMobile-Taiwan": "台灣地區",
  "setMobile-Macau": "澳門地區",
  "setMobile-errorCode": "請輸入正確的手機號以及6位數字驗證碼",
  "setMobile-chooseRegion": "選擇地區",
  "setMobile-regions": "地區",
  "setMobile-mobileInput": "請輸入手機號",
  "setMobile-title": "驗證手機",

  "forgotPassword-reset": "重置密碼",
  "forgotPassword-title": "請輸入您的郵箱地址",
  "forgotPassword-emailInput": "請輸入郵箱",
  "forgotPassword-sendEmail": "請登錄您的郵箱，查看重設密碼郵件，並進行對應操作。",
  "forgotPassword-sendSuccess": "發送成功",
  "forgotPassword-footer": "如您重設密碼，您的所有 PADI 相關賬號，包含PADI 專業人士網站，Scuba Earth， My PADI Club， eLearning 賬號密碼均會被同步更改。",

  "getCode-sendMobile": "驗證碼已發送。如未收到短信，請查看是否在被屏蔽的短信中。輸入收到的驗證碼並點擊\"驗證\"完成手機驗證。",
  "getCode-sendEmail": "驗證碼已發送。如驗證碼未在收件箱中，請查看垃圾郵箱。請輸入收到的驗證碼並點擊\"驗證\"完成郵箱驗證。",

  "campaign-loadingEcards": "正在查詢您的證書。 。 ",
  "campaign-noEcardSelected": "請選擇證書。",
  "campaign-tips": "請選擇一張欲更換龍年卡的潛水證書",

  "cardRequest-cart": "公益卡申請",
  "cardRequest-count": "共有 {number} 張 {padi} 公益卡",
  "cardRequest-continueToBuy": " 繼續申請",
  "cardRequest-checkedAll": "全選",
  "cardRequest-total": "合計：",
  "cardRequest-goToPay": "去結算",
  "cardRequest-empty": "您還沒有申請公益卡~",
  "cardRequest-goToCerts": " 去申請",
  "cardRequest-title": "潛水證書",
  "cardRequest-postage": "郵費：",

  "profile-profile": "個人資訊",
  "profile-name": "姓名：",
  "profile-birthDate": "出生日期：",
  "profile-mobile": "電話：",
  "profile-addressEdit": "收貨地址管理",
  "profile-language": "設置語言",
  "profile-setLanguage": "請設置語言",
  "profile-youzan": "綁定 PADI 有贊商城賬號",
  "profile-book": "購買簽證服務",
  "profile-agent-elearning": "elearning 代購",
  "profile-efr-public": "EFR Public 公眾救護代購",
  "profile-changeYouzan": "更換 PADI 有贊商城賬號",
  "profile-mobileTips": "僅限中國大陸的手機號碼",
  "profile-currentMobile": "當前綁定的手機號：",
  "profile-binding": "綁定",
  "profile-apply-manager": "申請潛店管理者",
  "profile-reviewing": "審核中",
  "profile-change-manager": "潛店管理者變更",
  "profile-remove-manager": "解除潛店管理者",
  "profile-action-sheet-title": "您目前管理的潛店為：",
  "profile-change-or-remove": "，您可以進行變更或者移除對該潛店的管理。",
  "profile-remove": "解 除",
  "profile-comfirm-text": "確認解除潛店管理者身份？",
  "profile-remove-success": "解除成功",
  "profile-store-number-error": "請輸入正確的潛店編號。",
  "profile-change-manager-title": "更改潛店管理",
  "profile-store-number-input": "請輸入潛店編號。",
  "profile-store-list-title": "您是潛店({storeNum})的管理者",
  "profile-store-title": "潛店信息",
  "profile-store-name": "潛店名稱: ",
  "profile-store-num": "潛店編號: ",
  "profile-copyright": "版權聲明",
  "profile-email": "更換郵箱",
  "profile-dm": "潛水長申請",
  "profile-app": "潛水長申請",
  "profile-si": "專長教練申請",
  "profile-si-cd": "簽發專長教練",
  "profile-owsi-cd": "IE 報名審核",
  "profile-owsi": "IE 考試報名及教練申請",
  "profile-ie": "IE 考試",
  "profile-efrt": "EFRI 審核",
  "profile-BMIT": "基礎美人魚教練審核",
  "profile-MIT": "美人魚教練審核",
  "profile-fdi": "自由潛水員教練審核",
  "profile-afdi": "進階自由潛水員教練審核",
  "profile-mfdi": "名仕自由潛水員教練審核",
  "profile-fd-pic": "自由潛升級簽證",
  "profile-scuba-app": "水肺專業級別申請",
  "profile-efr-app": "EFR 專業級別申請",
  "profile-efr-public-app": "EFR Public 公眾救護教練申請",
  "profile-mermaid-app": "美人魚專業級別申請",
  "profile-fd-app": "自由潛專業級別申請",
  "profile-course-pic": "預定課程簽證",
  "profile-course-insurance": "選購潜水保險服務",
  "profile-insurance-shopPublic": "俱樂部/潜店責任險",
  "profile-insurance-instructor": "教練職業責任險",
  "profile-insurance-diver": "潜水學員個人意外險（成為推廣俱樂部/教練）",

  "addressList-hintMsg": "確定要刪除該位址？",
  "addressList-title": "我的收貨地址",
  "addressList-edit": "編輯",
  "addressList-delete": "刪除",
  "addressList-noAddress": "您還沒有添加新地址哦~",
  "addressList-add": "新建收貨地址",

  "addressForm-edit": "編輯地址",
  "addressForm-add": "填寫新地址",
  "addressForm-contact": "聯繫人",
  "addressForm-mobile": "手機號碼",
  "addressForm-region": "所在地區",
  "addressForm-address": "地址",
  "addressForm-save": "保 存",
  "addressForm-defaultName": "張三",
  "addressForm-selectRegion": "選擇省市",
  "addressForm-addressDetail": "街道，門牌號",
  "addressForm-saveFail": "保存失敗",
  "addressForm-saveHint": "請填寫正確的聯繫資訊和位址資訊。",

  "home": '電子證書',
  "navi-campaign": '最新活動',
  "navi-store": 'PADI有贊商城',
  "cardRequest": "PADI 公益卡",
  "download-wall-cert": "下載牆上證書",
  "wechat-wall-cert": "請在瀏覽器中打開，下載牆上證書",
  "wechat-cert": "請在瀏覽器中打開，下載證書",
  "apply-for-free-card": "申請免費塑料卡",
  "apply-for-free-card-ok": "申請成功，請耐心等候。",
  "profile": "我的",

  "success-title": "註冊成功！",
  "success-hasSso": "請使用以下 PADI 統一登錄賬號登錄：",
  "success-login": "前往登錄",
  "failure-title": "註冊失敗",
  "failure-hint": "註冊失敗！請稍後再試",
  "failure-service": "聯繫客服：chinese.services@padi.com",
  "success-SignIn": "返回註冊",
  "success-hasYouzan": "您的PADI有讚商城手機帳號：",
  "success-hasnoYouzan": "您的手機號未能綁定 PADI 有贊商城賬戶，請前往個人中心進行手動綁定",

  "renew-member-title": "個人會員更新",
  "renew-status-ok": "您的2024會員資格已經續費",
  "renew-status-in-progress": "處理中",
  "renew-subtitle": "證書和費用信息",
  "renew-exclude-efr": "若要排除EFRI續訂，請單擊複選框取消",
  "renew-statement": "結算單",
  "renew-back": "返回個人中心",
  "renew-store-title": "潛店會員更新",
  "renew-choose-item": "請選擇您要續費的項目（可多選）",
  "renew-hint": "請選擇您要續費的項目",
  "renew-statement-tips": "2023年1月1日前更新獲得elearning優惠券",

  "store-list-no-more-store": "暫無更多潛店",
  "store-list-freedive": "自由潛",

  "dm-form-title": "PADI潛水長申請",
  "dm-app-list": 'PADI潛水長申請列表',
  "dm-form-diver-info": "申請人信息",
  "dm-form-name": "姓名",
  "dm-form-birth": "生日",
  "dm-form-confirm-birth": "請確認生日",
  "dm-form-required-certs": "先決條件",
  "dm-form-training-info": "訓練信息",
  "dm-form-instructor-warning": "請輸入教練編號",
  "dm-form-instructor-number": "教練編號",
  "dm-form-store-warning": "請輸入潛店編號（可選）",
  "dm-form-store-number": "潛店編號",
  "dm-form-complete-date": "完成日期",
  "dm-form-date-warning": "請選擇完成日期",
  "dm-form-skip-to": "將證書寄回",
  "dm-form-agreement-60-dives": "我已完成至少60次潛水記錄。",
  "dm-form-agree": "同意",
  "dm-form-reject": "拒絕",
  "dm-form-agreement-link": "會員資格協議書和授權協議書",
  "dm-form-agreement-text": "我已閱讀完畢 PADI {link}，並特此同意全部條款內容。我已瞭解並且同意，無論是在具有 PADI 會員資格的期間或在此之前，如有任何涉及未成年虐待或成人性虐待之刑事定罪，我的 PADI 會員資格將自動終止或被否決。此外，我已閱讀完畢「自動更新條款」，若我選擇註冊自動更新，代表我同意全部條款內容。(請見第二頁「條款」)根據本人所知所信，我在此保證上述資料皆真實且正確。遞交本申請表不代表已成為 PADI 會員，只有通過 PADI 的複審及批准后才能成為 PADI 的活躍會員。",
  "dm-form-instructor-agreement": "本人證明此申請人已具備至少 60 次的潛水紀錄。本人保證所有 PADI 教練手冊中所述的先決條件和簽證規定均已符合。",
  "dm-form-submit": "提交申請",
  "dm-form-searching": "正在搜索...",
  "dm-form-instructor-error": "請輸入正確的教練編號。",
  "dm-form-submit-error": "提交失敗",
  "dm-form-submit-error-info": "請確保所有信息輸入正確，並同意相關協議和授權。",
  "dm-form-submit-error-info-no-agreement": "請確保所有信息輸入正確。",
  "dm-form-entrance-title": "DM申請",
  "dm-form-applicationNo": "您的申請號為：",
  "dm-form-message-review": "您的教練以及PADI將會審核您的申請。您可以隨時訪問電子卡系統查看申請進展。",
  "dm-form-message-address": "為了加快您的審核，請盡快將潛水長課程提供的激光防偽貼，附註您的申請號或電郵或任意證書編號，按以下地址郵寄到PADI北京辦公室：",
  "dm-form-message-address-detail": "北京市朝陽區光華路22號SOHO一期2單元1515",
  "dm-form-pay": "繼續付款",
  "dm-form-signature": "簽名",
  "dm-form-signature-clear": "清除",
  "dm-form-signature-save": "保存",
  "dm-form-wait-review": "等待教練審核",
  "dm-form-wait-cert": "等待簽證",
  "dm-form-wait-pay-student": "等待申請人付款",
  "dm-form-wait-pay-instructor": "等待教練付款",
  "dm-form-processing": "PADI處理中",
  "dm-form-resubmit": "重新提交",
  "dm-form-signature-confirm": "請確認您已簽名。",
  "dm-form-instructor-create": "新建申請",
  "dm-form-search-applicant": "輸入候選人任意潛水證書編號",
  "dm-form-student-number": "證書編號",
  "dm-form-studentno-error": "請輸入正確的證書編號。",
  "dm-form-studentno-not-found": "未找到申請人，請確認輸入的證書編號正確。",
  "dm-form-student-added": "申請人已添加。",
  "dm-form-storeno-not-found": "未找到潛店，請確認輸入的潛店編號正確。",
  "dm-application-empty-list": "沒有進行中的申請。",
  "dm-application-add-app": "開始申請",
  "dm-app-not-approved-tips": "您提交的申請被拒絕，請重新提交申請。拒絕理由：",
  "dm-refuse-reason": "拒絕理由",
  "dm-refuse-tips": "請輸入拒絕理由（必填）",
  "dm-refuse-error": "請輸入拒絕理由。",
  "dm-region-and-lang-title": "教學地址及語言",
  "dm-select-region-error": "請選擇地區",
  "dm-region": "教學地址",
  "dm-lang": "選擇語言",
  "dm-lang-error": "請選擇語言",
  "dm-lang-title": "語言",
  "dm-efr-expired": "EFR證書已過期，請重新上傳相關資料進行審核。",
  "dm-dmai-text": "如果您同期提交了DM申請，可以上傳DM申請的截圖。",
  "dm-cert-info": "證書信息",

  "dm-form-instructor-reject-confirm": "確認拒絕該申請？",
  "dm-form-instructor-accept-confirm": "確認同意該申請？",

  "app-status-wait-confirm": "待確認",
  "app-status-instructor-approved": "已確認",
  "app-status-instructor-not-approved": "已拒絕",
  "app-status-success": "已完成",
  "app-status-instructor-created": "已創建",
  "app-status-submit": "申請人提交",
  "app-status-paid": "已付款",
  "app-status-to-pay": "待付款",
  "app-to-pay": "去支付",
  "app-wait-pay": "待支付",
  "app-wait-cs-review": "等待客服審核",
  "app-wait-candidate-pay": "等待候選人支付",
  "app-cs-not-approved": "客服審核未通過",
  "app-refuse-reason": "拒絕原因：",
  "app-wait-cert": "等待發證",
  "app-success": "已錄入",
  "app-close": "已關閉",
  "app-wait-cd-review": "等待總監審核",
  "app-wait-cd-pay": "等待總監支付",
  "app-has-efri": "有符合申請條件的EFRI證書",
  "app-efr-or-efri": "EFR或EFRI",
  "app-health": "上傳健康聲明等材料",
  "app-health-and-others": "上傳健康聲明、跟課證明等材料",
  "app-owsi-other-files": "如有其他機構證書副本請上傳/健康聲明數位版請上傳並將紙本帶至考場",
  "app-efr-or-efri-files": "EFR/EFRI材料",
  "app-not-has-right-cert": "未查詢到此證書",
  "app-wait-ins-review": "等待教練審核",
  "app-wait-trainer-review": "等待訓練官審核",
  "app-resubmit": "重新提交",
  "app-submit": "提交申請",
  "app-wait-applicant-confirm": "等待申請人確認",
  "app-pay-error": "支付異常",
  "app-prosite": "需前往pro site自行修改",
  "app-delete": "取消申請",
  "app-cancel": "確認取消該申請？",

  "resources-marketing-hub": "市場營銷資源中心",
  "resources-training-hub": "訓練資源中心",
  "resources-mainTitle": "PADI 中文素材庫",
  "resources-link": "請您將需要註冊PADI CANVA 的郵箱賬號發郵件到 chinese.member@padi.com，我們會為您發送定向邀請郵件，您通過收到的郵件邀請鏈接進行註冊",
  "resources-introduction": "歡迎來到 PADI 中文素材庫，您可在此查詢/下載有關 PADI 中文市場宣傳素材、課程培訓資料及 PADI 產品資料等。如您未擁有 PADI CANVA 賬號，{link}，以便訪問各類圖片素材。",
  "resources-canva-tips": "需要註冊Canva查看此內容。",
  "resources-cana-registered": "已註冊",
  "resources-cana-to-register": "現在註冊",

  "campaign-more": "更多精彩，敬請期待！",
  "campaign-title-festival": "2021嘉年華門票",
  "campaign-festival-ticket-one": "1張",
  "campaign-festival-ticket-two": "2張",
  "campaign-festival-max-tickets": "每人限購1張。",
  "campaign- festival - error": "請至少購買一張門票。",
  "campaign-festival-max-tickets-error": "每人限購2張。",
  "campaign-festival-ticket": "門票",
  "campaign-title-coupon": "成功",
  "campaign-title-coupon-success": "優惠券發放成功。",
  "campaign-title-coupon-success-go": "馬上使用",
  "campaign-title-coupon-success-later": "稍後再說",
  "card-requested": "您已經領取過該卡片。",

  "si-profile": "個人信息",
  "si-member-number": "會員號",
  "si-level": "級別",
  "si-conditions": "已滿足條件",
  "si-upload-attach": "需上傳資料",
  "si-upload-btn": "上傳資料",
  "si-form-title": "專長教練申請",
  "si-agreement-content": "我已經簽證過至少 25 位潛水員，並在我所申請的每一項專長領域中完成並記錄至少 20 次潛水，若 PADI 要求我出示這些潛水記錄，我將會配合。此外，我同意使用 PADI 標準大綱或經由 PADI 審閱並批准的教練撰寫的課程大綱，同時了解我僅可以在收到 PADI 書面同意和我的專長教練證明文件後，才可以執行上列專長課程。",
  "si-app-number": "申請編號：",
  "si-check": "查看",
  "si-reSubmit": "重新申請",
  "si-sp": "專長：",
  "si-app-status": "申請狀態：",
  "si-sp-app": "申請專長",
  "si-check-attach": "查看已上傳資料",
  "si-open-attach": "查看資料",
  "si-agreement-cd": "我同意使用 PADI 標準大綱或由我的課程總監所提供的大綱，同時了解我僅可以在收到 PADI 書面同意和我的專長教練證明文件後，方得執行上列的專長課程。此外，我已經在我所申請的每一項專長領域完成並記錄了至少 10 次潛水(半密閉式呼吸循環器需要 20 次)，若 PADI 要求我出示這些潛水記錄，我將會配合。",
  "si-cd-course": "課程信息",
  "si-store-num": "請輸入潛店編號",
  "si-course-location": "請輸入課程地點",
  "si-course-location-title": "課程地點",
  "si-app-name": "申請人：",
  "si-cd-instructor-num": "會員編號",
  "si-cd-instructor-num-tip": "請輸入會員編號",
  "si-uploadAttach-error": "最多上傳五個附件資料。",
  "si-instructor-number": "會員編號：",
  "si-cd-instructor-num-error": "請輸入正確的會員編號。",
  "si-refuse-reason": "拒絕理由：",
  "si-no-direct-sp": "没有可申請的專長。",
  "si-no-attach-sp": "沒有可申請的專長。",
  "si-agreement-cd-instructor": "我同意使用 PADI 標準大綱或由我的課程總監所提供的大綱，同時了解我僅可以在收到 PADI 書面同意和我的專長教練證明文件後，方得執行上列的專長課程。此外，我已經在我所申請的每一項專長領域完成並記錄了至少 10 次潛水(半密閉式呼吸循環器需要 20 次)，若 PADI 要求我出示這些潛水記錄，我將會配合。",
  "si-credits": "簽證要求",
  "si-credits-text": "需要至少簽證至少25名潛水員才能申請專長教練。",
  "si-wait-cd-to-pay": "等待總監付款",
  "si-back": "返回",
  "si-paypal": '您已提交 {count} 個專長，單價 {price} 澳幣，折扣後價格為 {total} 澳幣，點擊確定前往付款。',

  "book-search": "輸入搜索關鍵字，如代碼、名稱",
  "book-total": "合計：",
  "book-checkout": "去結算",
  "book-checkout-hint": "結算查看折扣",
  "book-submit-success": "訂單提交成功！",
  "book-offline-instruction": "您的訂單已經成功創建，請匯款 {price} 到以下賬戶，並將付款憑證電郵到chinese.sales@padi.com，我們將在收到您的付款憑證並核對成功後安排發貨。",
  "book-result-bank": "銀行: ",
  "book-result-address": "地址: ",
  "book-result-account-name": "賬戶名: ",
  "book-result-account-number": "賬戶編號: ",
  "book-remittance": "匯款信息",

  "owsi-cd-info": "課程總監信息",
  "owsi-cd-tips": "請輸入課程總監編號",
  "owsi-cd-number-error": "請輸入正確的總監編號",
  "owsi-number": "總監編號",
  "owsi-store-info-tips": "任選以下一項填寫即可",
  "owsi-store-select": "選擇潛店",
  "owsi-alternative-location": "備選地點",
  "owsi-age-conditions": "申請要求",
  "owsi-age-accord": "您的年齡已滿18歲，符合申請要求。",
  "owsi-age-not-accord": "您的年齡未滿18歲，不符合申請要求。",
  "owsi-cert-conditions": "證書條件",
  "owsi-application": "開放水域水肺教練申請",
  "owsi-location-tips": "請輸入地點（限20字以內）",
  "owsi-message-review": "課程總監以及PADI將會審核您的申請。您可以隨時訪問電子卡系統查看申請進展。",
  "owsi-training-records-tips": "請上傳訓練記錄。",
  "owsi-pay-tips": "請選擇支付方式。",
  "owsi-candidate-pay": "候選人支付",
  "owsi-cd-pay": "總監支付",
  "owsi-ie-location": "考試地點",
  "owsi-ie-date": "考試時間",
  "owsi-ie": "IE考試信息",
  "owsi-candidate-list": "候選人列表",
  "owsi-selected-ie": "選擇IE考試場次",
  "owsi-ie-list": "IE考試場次",
  "owsi-training-records": "訓練資料",
  "owsi-upload-traning-records": "上傳訓練資料",
  "owsi-agreement-text": "本人已閱讀最新{agreement}，認可並同意全部條款與條件。",
  "owsi-agreement": " PADI 會員協議書和 PADI 授權協議書",
  "owsi-criminal-record-statement": "我已瞭解並且同意，無論是在具有 PADI 會員資格的期間或在此之前，如有任何涉及未成年虐待或性虐待之刑事定罪，本人的 PADI 會員資格將自動終止或被否決。根據本人所知所信，我保證上述資料皆真實且正確。",
  "owsi-cd-agreement-one": "本人作為課程總監特此確認此候選人滿足參加PADI 教練發展課程和 PADI 教練考試(IE)的潛水經驗要求（教練培訓開始之日已有60次潛水記錄且獲得潛水證書已超過6個月，教練考試開始之日已有100次潛水記錄）",
  "owsi-cd-agreement-two": "本人作為課程總監在此確認以上所列之教練候選人擁有所有課程必須教材包括水中提示卡。",
  "owsi-idc-error": "請選擇IDC開始日期",
  "owsi-idc-end-error": "請選擇IDC結束日期",
  "owsi-idc-date": "IDC開始日期",
  "owsi-idc-end-date": "IDC結束日期",
  "owsi-not-pass": "考試未通過",
  "owsi-not-pass-reason": "未通過原因：",
  "owsi-paypal-reminder": '即將支付 IDC教練發展課程申請費 和 教練考試費，總計 {currency} {price}。',
  "owsi-paypal-attempt-all": '即將支付 教練考試費，總計 {currency} {price}。',
  "owsi-paypal-attempt": "即將支付 教練考試單科重考費，總計 {currency} {price}。",
  "owsi-extensions": "IE筆試考試時間延長",
  "owsi-extensions-reminder": "請聯繫教練發展部告知詳情。",
  "owsi-ie-times": "IE考試次數",
  "owsi-ie-first": "首次考試",
  "owsi-ie-second": "第二次考試",
  "owsi-ie-third": "第三次考試",
  "owsi-ie-fourth": "第四次考試",
  "owsi-ie-resit": "IE考試補考科目",
  "owsi-ie-written-exam": "理論考試",
  "owsi-ie-knowledge": "知識發展",
  "owsi-ie-confined-water": "平靜水域",
  "owsi-ie-open-water": "開放水域",
  "owsi-id-resits": "補考",
  "owsi-ie-resits-app": "IE補考申請",
  "owsi-cd-other-files": "請上傳教練候選人資料及訓練記錄/健康聲明副本請帶至考場",

  "items-list": "購買清單",
  "price-and-shipment": "價格及運費",
  "discount-level": "折扣級別：",
  "discount-price": "折扣價：",
  "discount-coupon": "優惠券：",
  "item-price": "商品總價：",
  "shipment-fee": "運費：",
  "total-price": "總價：",
  "checkout": "支付",
  "change-order": "返回修改",
  "payment-method": "選擇付款方式",
  "online-pay": "線上付款",
  "offline-pay": "線下匯款",
  "coupon-tips": "您有{count}張數碼產品優惠券可以使用。",
  "has-coupon": "您有{count}張優惠券可以使用",
  "coupon": "優惠券",
  "coupon-description": "滿{description}可用",
  "coupon-conditions": "僅限elearning商品使用",
  "coupon-conditions-2023": "僅限elearning商品使用",
  "coupon-use-error": "您購買的elearning商品的支付金額需大於優惠券金額。",
  "coupon-use-error-2023": "當前訂單沒有可以抵扣的商品。",
  "coupon-not-to-use": "暫不使用優惠券",
  "coupon-used": "已使用{count}張優惠券",
  "coupon-without": "暫無可使用的優惠券",
  "coupon-voucher": "兌換券",
  "coupon-useless": "不可用原因：",
  "coupon-expired": "優惠券已過期。",

  "paypal-callback": "支付中，請稍候。。。",
  "paypal-callback-success": "支付成功。",
  "paypal-callback-error": "支付失敗。",
  "paypal-order-detail": "訂單信息",
  "paypal-order-number": "訂單號：",
  "paypal-order-price": "總金額：",
  "paypal-goods-name": "商品名稱：",
  "paypal-goods-quantity": "商品數量：",
  "paypal-reminder": '即將支付 {currency} {price}。',
  "paypal-to-pay": "是否現在付款？",
  "paypal-pay-now": "提交成功！是否立即付款？",
  "paypal-pay": "去付款",
  "paypal-pay-later": "暫不付款",

  "credit-card-saveHint": "請填寫正確的信用卡信息",
  "credit-card-title": "信用卡信息",
  "credit-card-number": "信用卡卡號",
  "credit-card-date-year": "有效期年份（YY）",
  "credit-card-date-month": "有效期月份（MM）",
  "credit-card-ccv": "安全碼",
  "credit-card-pay-success": "支付成功",
  "credit-card-accepted-types": "接受Visa，MasterCard，American Express.",

  "efri-agreement": "我了解在尚未獲得EFR 緊急第一反應授權之前，我還不能執行EFR 緊急第一反應首要/次要救護和兒童救護課程，我也同意在執行 EFR 系列課程時，會遵守《EFR 緊急第一反應教練指引手冊》、《反應員》季刊和其他最新資料公佈的所有EFR 標準和程序。我會對EFR 教育教材保持熟悉，其中包括既有教材的改版和新教材的推出。我證實我已閱讀並將遵守《EFR 緊急第一反應教練指引手冊》附錄中的<EFR 授權協議書>。",
  "efri-course-date-error": "請選擇課程完成日期",
  "efri-application": "EFR 緊急第一反應教練申請",
  "efrt-list": "EFRI申請列表",
  "efri-message-review": "訓練官以及PADI將會審核您的申請。您可以隨時訪問電子卡系統查看申請進展。",
  "efri-age-not-accord": "您的年齡未滿18歲，不符合 EFR 緊急第一反應教練的申請要求。",
  "efri-age-accord": "您的年齡已滿18歲，符合 EFR 緊急第一反應教練的申請要求。",
  "efri-form-wait-review": "等待訓練官審核",
  "efri-wait-efrt-pay": "等待訓練官付款",
  "efri-efrt-pay": "訓練官支付",
  "efrt-number": "訓練官編號",
  "efrt-number-warning": "請輸入訓練官編號",
  "efrt-number-error": "請輸入正確的訓練官編號。",
  "efri-valid": "申請人為 EFR 教練",

  "agent-elearning-header-email": "為此學員代購",
  "agent-elearning-header-list": "可代購的elearning",
  "agent-elearning-email-hint": "請先輸入學員郵箱以查找可用的elearning。",
  "agent-elearning-email-placeholder": "輸入郵箱搜索學員",
  "agent-elearning-new-student": "在 PADI 系統中未查找到 {inputVal} 相關賬號。如確認為新註冊學員，請繼續提供以下信息，我們將根據您提供的信息創建 PADI 統一登錄賬號，並郵件通知學員訪問 elearning 系統。",
  "agent-elearning-not-available": "沒有可用的elearning。目前僅支持Open Water, Advanced Open Water elearning 的代購。",

  "BMI-app": "基礎美人魚教練申請",
  "BMI-agreement1": "本人同意遵守 PADI 美人魚系列課程標準並了解只有在當地的 PADI 地區總部核發書面核准之後才能進行授課。",
  "BMI-agreement2": "本人已閱讀會員協議書和授權協議書（附於 PADI 美人魚教練指引附錄），認可並同意全部條款與條件。",
  "BMI-agreement3": "本人了解並同意，若於取得PADI 會員資格期間或之前受判虐待未成年者或性侵成年者罪名成立，本人PADI 會員資格將自動據此遭到拒絕或終止。本人確認上述聲明完全屬實且正確。",
  "BMI-advanced-mermaid": "進階美人魚證書",
  "BMI-profects": "完成以下項目",
  "BMI-profect1": "美人魚教練課程的知識發展部分",
  "BMI-profect2": "美人魚教練期末考試，最低通過分數為75分",
  "BMI-profect3": "水中技能評估",
  "BMI-profect4": "展示示範等級的技巧演示",
  "BMI-profect5": "協助美人魚教練執行至少1次體驗美人魚活動和2次基礎美人魚/美人魚課程的平靜水域潛水1",
  "BMI-pay-reminder": "如果您個人支付申請費用，請點擊下方按鈕完成支付申請費用。或者之後回到申請頁面完成付款。",
  "BMI-pay-reminder-t": "如果訓練官支付申請費用，您無需操作，訓練官審核時我們提示完成付款。",
  "BMI-message-review": "訓練官以及PADI將會審核您的申請。您可以隨時訪問電子卡系統查看申請進展。",
  "BMI-trainer-review-reminder": "審核成功！是否現在付款？",
  "BMI-t-list-title": "基礎美人魚教練申請列表",

  "Mermaidi-app": "美人魚教練申請",
  "mermaid-t-list-title": "美人魚教練申請列表",
  "mermaid-upload-reminder": "請上傳 PADI 進階美人魚證書或等同級別證書，或提供15次美人魚潛水經驗證明，其中包括至少3次開放水域美人魚潛水",
  "mermaid-other-files": "其他材料",
  "mermaid-choice": "2選1",
  "mermaid-choice1": "1，和一位具備教學身份的 PADI 美人魚教練訓練官完成 PADI 美人魚教練課程，或者",
  "mermaid-choice2": "2，持有其他組織的美人魚教練證書或更高等級證書。",
  "mermaid-choice2-1": "完成 PADI 美人魚教練介紹課程",
  "mermaid-choice2-2": "已經教授或協助過至少 2 次美人魚課程，課程等同達到 PADI 美人魚課程要求， 或協助執行過 1 次 PADI 美人魚課程",

  "fdi-app": "自由潛水員教練申請",
  "fdi-master-cert": "PADI名仕自由潛水員",
  "fdi-owsi-cert": "PADI 開放水域水肺教練",
  "fdi-not-padi-cert": "其他組織的合格自由潛水員教練證書",
  "fdi-advance-cert": "PADI 進階自由潛水員",
  "fdi-owsi-reminder": "上傳其他組織的合格自由潛水員教練證書。",
  "fdi-app-list": "自由潛教練申請列表",
  "fdi-agreement1": "我同意遵守 PADI 自由潛水員系列課程標準並知曉我僅可以在收到 PADI 地區總部的書面同意後，才可以執行本課程。",
  "fdi-agreement2": "我已閱讀完畢 PADI 會員資格協議書和授權協議書（可以在 PADI 自由潛水員系列課程教練指引附錄中找到），並特此同意全部條款內容。",
  "fdi-agreement3": "我已了解並且同意，無論是在具有PADI 會員資格的期間或在此之前，如有任何涉及未成年虐待或性虐待之刑事定罪，本人的PADI 會員資格將自動終止或被否決。根據本人所知所信，我保證上述資料皆真實且正確。",

  "afdi-app": "進階自由潛水員教練申請",
  "afdi-padi-fdi": "PADI自由潛水員教練",
  "afdi-satisfy": "（符合申請要求）",
  "afdi-master-fd": "名仕自由潛水員",
  "afdi-certs-Requirement": "簽證要求",
  "afdi-satisfy-certs": "已簽發 25 張自由潛水員證書",
  "afdi-upload-cert-requirement": "需上傳 25 張自由潛水員簽證記錄",
  "afdi-prepare": "PADI自由潛水員教練預備課程",
  "afdi-prepare-others": "PADI自由潛水員教練預備課程/其他材料",
  "afdi-t-title": "進階自由潛教練申請列表",
  "afdi-agreement1": "本人同意遵守 PADI 自由潛水課程標準，並了解在當地的 PADI 地區總部寄發書面核准之後才能授課。",
  "afdi-agreement2": "本人已閱讀會員協議書和授權協議書 (附於 PADI 自由潛水員課程教練指引附錄)，茲此認可並同意全部條款與條件。",
  "afdi-agreement3": "本人了解並同意，若於取得PADI 會員資格期間或之前受判虐待未成年者或性侵成年者罪名成立，本人PADI 會員資格將自動據此遭到拒絕或終止。本人確認上述聲明完全屬實且正確。",

  "mfdi-app": "名仕自由潛水員教練申請",
  "mfdi-satisfy-certs": "已簽發 50 張自由潛水員簽證，其中至少有 10 張為進階 PADI 自由潛水員簽證",
  "mfdi-upload-cert-requirement": "需上傳 50 張自由潛水員簽證記錄，其中至少有 10 張為進階 PADI 自由潛水員簽證",
  "mfd-padi-afd": "PADI進階自由潛水員教練",
  "mfd-afdi": "進階自由潛水員教練",
  "mfd-afdi-upload-reminder": "請上傳其他組織的合格進階自由潛水員教練證書材料。",
  "mfdi-agreement1": "本人同意遵守 PADI 自由潛水課程標準，並了解在當地的 PADI 地區總部寄發書面核准之後才能授課。",
  "mfdi-agreement2": "本人已閱讀會員協議書和授權協議書 (附於 PADI 自由潛水員課程教練指引附錄)，茲此認可並同意全部條款與條件。",
  "mfdi-agreement3": "本人了解並同意，若於取得PADI 會員資格期間或之前受判虐待未成年者或性侵成年者罪名成立，本人PADI 會員資格將自動據此遭到拒絕或終止。本人確認上述聲明完全屬實且正確。",
  "mfdi-t-title": "名仕自由潛教練申請列表",

  "fd-pic-new": "新建自由潛簽證",
  "fd-pic-student-info": "學員信息（可添加多個）",
  "fd-pic-title": "自由潛簽證",
  "fd-pic-email": "郵箱：",
  "fd-pic-status": "簽證狀態：",
  "fd-pic-date": "日期：",
  "fd-pic-level": "簽證級別：",
  "fd-pic-finish": "已簽證",
  "fd-pic-email-input": "輸入您要搜索的郵箱",
  "fd-pic-course-level": "課程級別",

  "efris-apps-title": "EFR Public 代購一覽",
  "efr-public-app-agreement": "在保持 EFR 教練的同時，本人自願申請成為 EFR 公眾救護專長教練。",
  "efr-public-app-done-title": "操作成功",
  "efr-public-app-done-detail": "您的 EFR Public 公眾救護專長教練申請已通過！",
  "efr-public-app-waiting-title": "等待處理",
  "efr-public-app-waiting-detail": "PADI 已經收到您的 EFR Public 公眾救護專長教練申請，請耐心等候，我們將盡快處理您的申請。",
  "efr-public-new-student": "在 PADI 系統中未查找到郵箱相關賬號。如確認為新註冊學員，請繼續提供以下信息，我們將根據您提供的信息創建 PADI 統一登錄賬號並郵件通知學員。",

  "scuba-ow-title": "Scuba Diver 升級 OW 一覽",
  "issue-certs-efrp-name": "EFR Public 代購",
  "issue-certs-confined-pic-name": "Confined Water Diver 簽證購買",
  "issue-certs-scuba-pic-name": "Scuba Diver 簽證購買",
  "issue-certs-scuba2ow-name": "Scuba Diver 升級 OW",
  "issue-certs-confined-name": "Confined Water Diver",
  "issue-certs-scuba-name": "Scuba Diver",
  "issue-certs-confined2ow-name": "Confined Water Diver 升級 OW",
  "issue-certs-pic-history": "購買記錄",
  "issue-certs-pic-buy": "購買簽證",
  "issue-certs-pic-amount": "購買數量",
  "issue-certs-pic-amount-placeholder": "請輸入數量",
  "issue-certs-history": "簽證記錄",
  "issue-certs-pic-left": "簽證點數剩餘",

  "festival-submit-loading": "提交中",
  "festival-ticket-type": "門票類型",
  "festival-ticket-type-error": "門票類型為必選項",
  "festival-name-error": "姓名不能為空",
  "festival-name-hint": "請輸入姓名",
  "festival-phone-error": "手機號不能為空",
  "festival-store-num": "潛店編號（選填）",
  "festival-store-name": "潛店中文名（選填）",
  "festival-store-name-hint": "請輸入潛店中文名",
  "festival-checkin-name": "入住人姓名",
  "festival-checkin-name-error": "入住人姓名不能為空",
  "festival-checkin-name-hint": "請輸入入住人姓名",
  "festival-house-type": "房型",
  "festival-house-type-error": "房型為必選項",
  "festival-double-bed": "雙床房",
  "festival-big-bed": "大床房",
  "festival-arrival-date": "到店日期",
  "festival-arrival-date-error": "請選擇到店日期",
  "festival-leave-date": "離店日期",

  "questionaire-title": "問卷調查",
  "questionaire-message": "恭喜您完成了 {course}，請您填寫問卷，以便我們更好的為您提供服務，感謝您的支持！",
  "questionaire-course-OW": "PADI開放水域潛水員課程",
  "questionaire-course-BASIC_FD": "PADI基礎自由潛課程",
  "questionaire-course-FD": "PADI自由潛課程",
  "questionaire-course-PADI_MERMAID": "PADI美人魚課程",

  "procheck-about": "關於 Pro Check",
  "procheck-about-content": "詳細了解 PADI Pro Check",
  "procheck-about2": "PADI Pro Chek 是一個用於驗證 PADI 專業會員身份狀態的工具，適用於所有人。",
  "procheck-about3": "您可以在下面找到 PADI 會員的姓名，最高核心 PADI 專業等級，目前狀態以及 PADI 會員資格最後更新年份。",
  "procheck-about4": "● 教練通常已被授權，可按照證書中列出的資格教授 PADI 核心課程，指導潛水員並協助 PADI 課程。",
  "procheck-about5": "● 潛水長及助理教練通常已被授權，可指導潛水員並協助 PADI 課程。",
  "procheck-about6": "● 下面列出的信息並不詳盡。 沒有反映出教授某些專業 PADI 課程所必需的額外資質，以及在某些領域教授、指導或協助所必需的保險要求。",
  "procheck-about7": "PADI 會員已被授權使用各種 PADI 商標及進行 PADI 培訓。 個人、潛水中心和度假村會員 不是 PADI 的代理、員工或加盟商。 PADI 會員的商業活動是獨立的，既非 PADI 所有，也非由 PADI 操作經營。",
  "procheck-about8": "雖然 PADI 制定了 PADI 潛水員培訓計劃的標準，但 PADI 對 會員商業活動的運營、日常 PADI 計劃的執行以及會員或其相關人員對潛水員的監督既不承擔責任也無權控制。",
  "procheck-score": "PADI 評分",
  "procheck-auth": "獲得教學授權：",
  "procheck-renew": "已續費：",
  "procheck-renew-date": "續費年份：",
  "procheck": "Pro Check 會員驗證",
  "procheck-captcha": "驗證碼",
  "procheck-captcha-error": "驗證碼不能為空",
  "procheck-captcha-refresh": "點擊驗證碼可刷新",

  "regulator-coupon-success": "核銷成功",
  "regulator-coupon-fail": "核銷失敗",
  "refulator-coupon-check": "核銷",

  "findme-header": "找回賬號",
  "findme-student-num-title": "請提供您的證書編號以幫助我們找回您的賬號",
  "findme-student-num-empty": "證書編號不能為空",
  "findme-student-num-hint": "請輸入證書編號",
  "findme-guide-disc": "可以根據您的潛水證書編號找回賬號，如果您不知道您的潛水證書編號，請和您的教練聯繫；或者提交您的個人信息來找回賬號。",
  "findme-guide-student-num-yes": "我有潛水證書編號",
  "findme-guide-student-num-no": "我不記得潛水證書編號",
  "findme-user-info-desc": "請提供您的個人信息以幫助我們找回您的賬號",
  "findme-contact-service-tips": "請輸入您在使用的郵箱，作為賬號註冊、登錄系統。",
  "findme-not-find": "我們未能找到相關賬號，您可以返回重新填寫、確認信息。或者聯繫客服找回賬號。",
  "findme-contact-service": "聯繫客服找回賬號",
  "findme-fill-in-again": "重新填寫",
  "findme-success-contact-service": "如果這不是您的賬號，您可以聯繫客服找回賬號，或者返回重新填寫信息查找。",
  "findme-login": "立即註冊/登錄",
  "findme-has-email-account": "如果您擁有此郵箱賬號，您可以使用該賬號註冊/登錄。",
  "findme-find-email-tips": "根據您提供的信息，我們在系統中查找到以下賬號：",
  "findme-submit-email": "提交找回賬號請求",
  "findme-desc": "如果您能提供潛水證書編號，將更有助於找回賬號，可以和您的教練聯繫得到證書編號。",
  "findme-email-option": "請輸入證書編號（選填）",
  "findme-submit-tips": "您的賬號找回/更改請求已經成功提交，我們的工作人員將盡快進行處理，請關注您的郵箱，感謝您的耐心等候！",

  "updateUserInfo-title": "修改信息",
  "updateUserInfo-hint": "請輸入新的個人信息",
  "updateUserInfo-success": "您的需求已經提交，預計1~2個工作日處理完畢",

  "pocket-apps-title": "其他會員申請",
  "pocket-apps-type": "申請類型",
  "pocket-apps-type-error": "申請類型不能為空",

  "store-apps": "潛店申請",
  "store-apps-type": "潛店類型",
  "store-apps-error": "潛店類型不能為空",

  "award-title": "個人榮譽",
  "award-anniversary": "週年",
  "award-elite": "精英教練",
  "award-no-elite": "繼續簽發更多證書，成為PADI精英教練",
  "award-year": "年份",
  "award-cert": "證書",
  "award-download-cert": "下載證書",
  "award-download": "下載",
  "award-anniversary-year": "PADI 會員 {year} 週年證書（{currentYear}）",
  "award-no-anniversary": "繼續努力，贏取您的第一個5週年證書",

  "club-certs-list-title": "請選擇您想更換卡面的證書",
  "club-receive-btn": "立即領取",
  "club-success": "領取成功",

  "textbooks-order": "簽證服務歷史訂單",
  "unconventional-order": "非常規商品歷史訂單"
}
export default tc;
