import React from 'react';
import {
  List,
  InputItem
} from 'antd-mobile';
import { FormattedMessage, useIntl } from 'react-intl';
import { getCookie } from '../../components/common/cookie';
import PickerComponent from '../../components/common/Picker'
import { usePicOrderAddress } from '../../services/hooks';
import Loading from '../../components/Loading';
import { textbooksRegions } from '../../components/common/utils'


const DEFAULT_ADDRESS_TYPE = 1;

export default function ShippingAddress({
  addressType,
  onRegionChange,
  address,
  setAddress,
  userName,
  setUserName,
  userMobile,
  setUserMobile,
}) {
  const { formatMessage } = useIntl();

  const { loading } = usePicOrderAddress({
    onSuccess: (data) => {
      if (data.addressType) {
        onRegionChange(data.addressType);
        setUserMobile(data.userMobile);
        setUserName(data.userName);
        setAddress(data.address);
      } else {
        onRegionChange(DEFAULT_ADDRESS_TYPE);
      }
    }
  });

  return <List renderHeader={formatMessage({ id: 'addressForm-add' })}>
    {loading ? <Loading /> :<>
  <PickerComponent
    title={formatMessage({ id: 'setMobile-chooseRegion' })}
    data={textbooksRegions[getCookie('language')]}
    onOk={(addressType) => {
      onRegionChange(addressType);
    }}
    value={addressType}>
    <FormattedMessage id="addressForm-region" />
  </PickerComponent>
  <InputItem
    placeholder={formatMessage({ id: 'addressForm-addressDetail' })}
    value={address}
    onChange={setAddress}
    maxLength={100}
  >
    <FormattedMessage id="addressForm-address" />
  </InputItem>
  <InputItem
    placeholder={formatMessage({ id: 'addressForm-defaultName' })}
    value={userName}
    maxLength={50}
    onChange={setUserName}
  >
    <FormattedMessage id="addressForm-contact" />
  </InputItem>
  <InputItem
    placeholder={formatMessage({ id: 'setMobile-mobileInput' })}
    value={userMobile}
    maxLength={50}
    onChange={setUserMobile}
  >
    <FormattedMessage id="addressForm-mobile" />
  </InputItem></>}
</List>
}