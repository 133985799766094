import React, { Component } from 'react';
// import { Toast, Checkbox, Button, WhiteSpace, Flex, Card, WingBlank } from 'antd-mobile';
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl';
import { submitMemberRenew, submitStoreRenew } from '../../actions';
import { getMemberRenew, getStoreRenew } from '../../reducers/selectors'
import { push } from 'connected-react-router'
import StoreRenew from '../../components/Renew/StoreRenew'
import MemberRenew from '../../components/Renew/MemberRenew'

import './Renew.css'

class RenewPage extends Component {

  render() {
    const { renewType, memberRenewList, storeRenewList, push, submitMemberRenew, submitStoreRenew } = this.props
    return renewType === 'store' ? <StoreRenew storeRenewList={storeRenewList} push={push} submitStoreRenew ={submitStoreRenew} />
    :
    renewType === 'member' ? <MemberRenew memberRenewList={memberRenewList} push={push} submitMemberRenew={submitMemberRenew} />
    :
    null
  }
}

function mapStateToProps(state, ownProps) {
  const renewType = ownProps.location.pathname.replace('/renew/', '')
  return {
    memberRenewList: getMemberRenew(state),
    storeRenewList: getStoreRenew(state),
    renewType
  }
}

export default connect(mapStateToProps, { submitMemberRenew, submitStoreRenew, push })(injectIntl(RenewPage))
