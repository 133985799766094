import React from 'react';
import { MyInformation } from './MyInformation'
import { InstructorInformation } from './InstructorInformation'
import { DiveShopInformation } from './DiveShopInformation'
import { Qualification } from './Qualification'
import { isCert } from '../../utils/cert';

const CertDetailList = ({ cert, user }) => {
  if(!user || !cert) {return null;}
  return <div>
    <MyInformation
      user={user}
      studentNumber={cert.studentNumber ? cert.studentNumber : cert.memberNumber}
      certDate={isCert(cert) ? cert.certificationDate : cert.credentialDate}
      inWaterDate={isCert(cert) ? cert.inWaterDate : ''} />
    <Qualification cert={cert} />
    <InstructorInformation cert={cert} />
    <DiveShopInformation cert={cert} />
  </div>
};

export { CertDetailList }
