import React from 'react';
import { List, Accordion, WhiteSpace } from 'antd-mobile';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import styles from './Menu.module.css'

const ScubaMenu = ({ dm, ie, efri, insDm, si, cd, efrt, efr }) => {

  const history = useHistory();

  if (!dm && !ie && !efri && !insDm && !si && !cd && !efrt && !efr) return null

  return <>
    <Accordion defaultActiveKey="0">
      <Accordion.Panel header={<FormattedMessage id="profile-scuba-app" />} className="profile-list member-renew">
        <List>
          {dm && <List.Item arrow="horizontal" onClick={() => history.push('/dmForm')}>
            <span className={styles.menuItem}>
              <FormattedMessage id="profile-dm" />
            </span>
          </List.Item>}
          {ie && <List.Item
            arrow="horizontal"
            onClick={() => history.push('/owsi_app')}
          >
            <span className={styles.menuItem}>
              <FormattedMessage id="profile-owsi" />
            </span>
          </List.Item>}
          {efri && <List.Item
            arrow="horizontal"
            onClick={() => history.push('/efri_app')}
          >
            <span className={styles.menuItem}>
              <FormattedMessage id="efri-application" />
            </span>
          </List.Item>}
          {si && <List.Item
            arrow="horizontal"
            onClick={() => history.push('/siAppList')}
          >
            <span className={styles.menuItem}>
              <FormattedMessage id="profile-si" />
            </span>
          </List.Item>}
          {insDm && <List.Item
            arrow="horizontal"
            onClick={() => history.push('/confirmApps')}
          >
            <span className={styles.menuItem}>
              <FormattedMessage id="profile-app" />
            </span>
          </List.Item>}
          {cd && <List.Item
            arrow="horizontal"
            onClick={() => history.push('/cdAppList')}
          >
            <span className={styles.menuItem}>
              <FormattedMessage id="profile-si-cd" />
            </span>
          </List.Item>}
          {cd && <List.Item
            arrow="horizontal"
            onClick={() => history.push('/owsi_cd_apps')}
          >
            <span className={styles.menuItem}>
              <FormattedMessage id="profile-owsi-cd" />
            </span>
          </List.Item>}
        </List>
      </Accordion.Panel>
    </Accordion>
    <WhiteSpace size="sm" />
  </>
}

export default ScubaMenu