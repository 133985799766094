import React from 'react'
import { NavBar } from 'antd-mobile-v5';
import { UnorderedListOutline, FillinOutline } from 'antd-mobile-icons'

const Header = ({ code, text, rightContent, type }) => <NavBar
  style={{ background: '#fff' }}
  right={rightContent}
  left={type === 'list' ? <UnorderedListOutline fontSize={20} /> : <FillinOutline fontSize={20} />}
  back={null}
>
  <span>
    {text}
    {code && <span>({code})</span>}
  </span>
</NavBar>

export default Header