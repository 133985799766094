import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { List, WingBlank } from 'antd-mobile';
import CardLayout from '../DiveMasterForm/CardLayout'
import { FormattedMessage, useIntl } from 'react-intl';
import UploadOssFile from '../../components/common/UploadOssFile'
import UploadFileWrapper from '../../components/common/UploadFileWrapper'
import { findMermaidCert } from '../../components/common/findCerts'
import { uploadMermaidOssFile, removeMermaidFile } from '../../reducers/mermaid'
import UploadEfrOrEfri from '../../components/common/UploadEfrOrEfri'

const Item = List.Item

const CheckCerts = ({ efrfiles, mermaidFiles, otherFiles, editable, advancedMermaid, efr, efri }) => {

  const { certifications, ossSignature } = useSelector((state) => ({
    certifications: state.data.certifications,
    ossSignature: state.oss.ossSignature,
  }));
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const filename = (type) => `${ossSignature.dir}${type}/${new Date().getTime()}`

  const complete = (advancedMermaid || (mermaidFiles && mermaidFiles.length > 0)) && (efr || (efrfiles && efrfiles.length > 0) || efri) && (otherFiles && otherFiles.length > 0)

  return <CardLayout title={<FormattedMessage id="dm-form-required-certs" />} status={complete}>
    {findMermaidCert(certifications) ? <Item>
      <FormattedMessage id="BMI-advanced-mermaid" /> {`（${findMermaidCert(certifications).studentNumber}）`}
    </Item> : <UploadFileWrapper 
      labelName={formatMessage({ id: 'BMI-advanced-mermaid' })} 
      editable={editable}>
      <UploadOssFile
        fileList={mermaidFiles || []}
        fileName={filename('mermaid')}
        onChange={(formData) => {
          dispatch(uploadMermaidOssFile({ formData }))
        }}
        onDelete={(index) => dispatch(removeMermaidFile(mermaidFiles[index]))}
        editable={editable} />
      {editable && <WingBlank>
        <div className="small-font gray">
          <FormattedMessage id="mermaid-upload-reminder" />
        </div>
      </WingBlank>}
    </UploadFileWrapper>}
    <UploadEfrOrEfri
      uploadFn={(formData) => {
        dispatch(uploadMermaidOssFile({ formData }))
      }}
      removeFn={(index) => dispatch(removeMermaidFile(efrfiles[index]))}
      filename={filename('efr')}
      editable={editable}
      efrfiles={efrfiles} />
    <UploadFileWrapper 
      labelName={formatMessage({ id: 'mermaid-other-files' })} 
      editable={editable}>
      <UploadOssFile
        fileList={otherFiles || []}
        fileName={filename('other')}
        onChange={(formData) => {
          dispatch(uploadMermaidOssFile({ formData }))
        }}
        onDelete={(index) => dispatch(removeMermaidFile(otherFiles[index]))}
        editable={editable} />
      {editable && <WingBlank>
        <div className="small-font gray">
          <div><FormattedMessage id="app-health-and-others" /></div>
          <div><FormattedMessage id='mermaid-choice' /></div>
          <div><FormattedMessage id='mermaid-choice1' /></div>
          <div><FormattedMessage id='mermaid-choice2' /></div>
          <ul>
            <li>
              <FormattedMessage id='mermaid-choice2-1' />
            </li>
            <li>
              <FormattedMessage id='mermaid-choice2-2' />
            </li>
          </ul>
        </div>
      </WingBlank>}
    </UploadFileWrapper>
  </CardLayout>
}

export default CheckCerts