import React from 'react';

import { getCookie } from '../../components/common/cookie';

import styles from './Campaign.module.css'

export default function CardOptions({ selectedCardId, onChange }) {
  const language = getCookie('language');

  return <div className={styles["single-card"]}>
    <div className={styles["card-img-wrapper"]}>
      <img
        language={getCookie('language')}
        src={require(`../../assets/images/ecard_img/year2024_1-${language === 'en' ? 'sc' : language}.jpg`)}
        alt=""
        onLoad={() => {
          window.dispatchEvent(new Event('resize'));
        }}
      />
    </div>
  </div>
}
