import React from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl, FormattedMessage } from 'react-intl';
import { Pagination, WhiteSpace } from 'antd-mobile'
import FormHeader from './FormHeader'
import AppListItem from './AppListItem'
import { Empty, List } from 'antd-mobile-v5'
import Loading from '../../components/Loading'
import { LeftOutline, RightOutline } from 'antd-mobile-icons'

const ListContent = ({ data, isStore, pagination, loading, detailUrl }) => {

  const { formatMessage } = useIntl()
  const { push } = useHistory()

  if (loading) return <Loading />
  if (data.length === 0) return <Empty description={formatMessage({ id: 'form-apps-empty' })} />
  return <>
      {data.map((item) => (
        <AppListItem
          key={item.code}
          confirmItem={item}
          onClick={() => {;push(`${detailUrl}?code=${item.code}`, {
            detail: item,
            isStore: isStore
          })}}
          isStore={isStore}
        />
      ))}

    <WhiteSpace />
    <Pagination
      total={pagination.totalPage}
      current={pagination.current}
      locale={{
        prevText: (<span><LeftOutline /><FormattedMessage id="form-pre-page" /></span>),
        nextText: (<span><FormattedMessage id="form-next-page" /><RightOutline /></span>),
      }}
      onChange={pagination.onChange} />
  </>
}

const AppList = ({
  data,
  detailUrl,
  pagination,
  title,
  rightContent,
  isStore,
  loading
}) => {

  const { formatMessage } = useIntl()

  return (
    <>
      <FormHeader
        text={formatMessage({ id: title })}
        rightContent={rightContent}
        type={"list"}
      />
      <ListContent
        data={data}
        pagination={pagination}
        isStore={isStore}
        loading={loading}
        detailUrl={detailUrl} />
    </>
  )
}

export default AppList