import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { List, WingBlank } from 'antd-mobile';
import { FormattedMessage, useIntl } from 'react-intl';
import { uploadOwsiFile, removeOwsiFile } from '../../reducers/owsi'
import UploadOssFile from '../../components/common/UploadOssFile'
import UploadFileWrapper from '../../components/common/UploadFileWrapper'
import UploadEfrOrEfri from '../../components/common/UploadEfrOrEfri'

const Item = List.Item

const CertsConditionsItem = ({ cert, attachFileList, type, editable }) => {

  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const { ossSignature, userInfo } = useSelector(state => ({
    ossSignature: state.oss.ossSignature,
    userInfo: state.data.userInfo
  }));

  const filename = (type) => `${ossSignature.dir}${type}/${new Date().getTime()}`

  if (type === 'EFR') {
    return <UploadEfrOrEfri
      uploadFn={(formData) => {
        dispatch(uploadOwsiFile({ formData }))
      }}
      removeFn={(index) => dispatch(removeOwsiFile(attachFileList[index]))}
      filename={filename('EFR')}
      editable={editable}
      efrfiles={attachFileList} />
  }

  if (type === 'other') {
    return <UploadFileWrapper
      labelName={formatMessage({ id: 'mermaid-other-files' })}
      editable={editable}>
      <UploadOssFile
        fileList={attachFileList || []}
        fileName={filename('other')}
        onChange={(formData) => {
          dispatch(uploadOwsiFile({ formData }))
        }}
        onDelete={(index) => dispatch(removeOwsiFile(attachFileList[index]))}
        editable={editable} />
      {editable && <WingBlank>
        <div className="small-font gray">
          <FormattedMessage id="app-owsi-other-files" />
        </div>
      </WingBlank>}
    </UploadFileWrapper>
  }

  return !cert ? <UploadFileWrapper labelName={type} editable={editable}>
    <UploadOssFile
      fileList={attachFileList || []}
      editable={editable}
      type={"OWSI"}
      fileName={filename(type)}
      onChange={(formData) => {
        dispatch(uploadOwsiFile({ formData }))
      }}
      onDelete={(index) => dispatch(removeOwsiFile(attachFileList[index]))} />
    {editable && <WingBlank>
      <div className="small-font gray">
        <FormattedMessage id="common-upload-file-reminder" />
        {type === 'AI/DM' && <FormattedMessage id="dm-dmai-text" />}
      </div>
    </WingBlank>}
  </UploadFileWrapper> : <Item>
      {`${type} ${cert && type !== 'AI/DM' ? `（${cert.studentNumber}）` : ''}`}
      {cert && type === 'AI/DM' ? `（${userInfo.memberNumber}）` : ''}
    </Item>
}

export default CertsConditionsItem