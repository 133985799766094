const sc = {
  "common-errorTitle": "错 误",
  "common-codeInput": "6位数字验证码",
  "common-getCode": "获取验证码",
  "common-validate": "验证",
  "common-confirm": "确 认",
  "common-close": "关 闭",
  "common-isNotEmail": "请输入正确的邮箱地址",
  "common-select": "请选择",
  "common-errorStatus": "系统出错，请稍后再试，谢谢您的理解。",
  "common-passwordInput": "请输入您设置的密码",
  "common-confirmPassword": "请再次输入您设置的密码",
  "common-reset": "请输入8-20位、包含大写字母、小写字母、数字以及符号的密码",
  "common-confirmRequest": "提 交",
  "common-errorMobile": "请输入正确的手机号",
  "common-prompt": '提 示',
  "common-cancel": "取消",
  "common-comfirmBtn": "确定",
  "common-updateCerts": "您有新的证书",
  "common-search-error": "输入信息有误，请重新输入",
  "common-search-title": "查询",
  "common-back": "返回",
  "common-check-file": "查看材料",
  "common-agreement": "隐私政策",
  "common-agreement-hint": "使用 PADI 提供的服务，即表示您同意我们的{link}",
  "common-info-agreement-hint": "我已认真阅读、理解并同意{link}",
  "common-info-agreement": "个人信息跨境传输授权书",
  "common-back-home": "返回首页",
  "common-upload-file-reminder": "请上传潜水证书的正反面照片，或者电子证书的截图。",
  "common-applicant-pay": "申请人支付",
  "common-instructor-pay": "教练支付",
  "common-course-date": "课程完成日期",
  "common-email": "邮箱",
  "common-name": "姓名",
  "common-address-detail": "地址信息",
  "common-country": "国家",
  "common-address": "详细地址",
  "common-address-reminder": "请输入详细地址",
  "common-Province": "省市",
  "common-yes": "是",
  "common-no": "否",
  "common-skip": "跳过",
  "common-submit-loading": "提交中",
  "common-submit-success": "提交成功",
  "common-search-loading": "查询中",
  "common-loading": "加载中",
  "common-know": "我知道了",
  "common-instructor-name": "教练姓名",
  "common-error-verification-code": "请输入正确的6位数字验证码",
  "order-status-toship": "待发货",
  "order-status-shipped": "已发货",
  "order-status-completed": "已完成",
  "register-email-not-verified": "请输入验证码并点击\"验证\"完成邮箱验证。",
  "register-mobile-not-verified": "请输入验证码并点击\"验证\"完成手机验证。",
  "common-check-agreement": "请先勾选同意协议",
  "common-agree": "我已阅读并同意",
  "common-not-agree": "我不同意",
  "common-agreement-update": "协议更新",
  "common-load-no-more": "没有更多了",
  "common-return-agreement1": "我已详阅",
  "common-return-agreement2": " PADI会员规范",
  "common-return-and": "及",
  "common-return-agreement3": "退换货办法",
  "common-return-title": "退换货协议",

  "notification-title": "温馨提示",

  "student-search-list": "学员一览",
  "student-search-list-empty": "请输入邮箱查找添加学员。",
  "student-search-added": "邮箱已添加。",

  "form-email-empty-error": "邮箱不能为空",
  "form-email-format-error": "邮箱格式有误",
  "form-email-verify-error": "未验证邮箱，请点击“查询”进行邮箱验证",
  "form-region-empty-error": "地区不能为空",
  "form-stateid-hint": "请选择省市",
  "form-stateid-empty-error": "省市不能为空",
  "form-date-hint": "请选择日期",
  "form-course-date-empty-error": "课程完成日期不能为空",
  "form-storenum-empty-error": "潜店编号不能为空",
  "form-storenum-format-error": "潜店编号有误",
  "form-storenum-verify-error": '未验证潜店信息，请点击“查询”进行潜店信息验证',
  "form-instructornum-empty-error": "教练编号为空或未验证",
  "form-instructornum-verify-error": '未验证教练信息，请点击“查询”进行教练信息验证',
  "form-store-address": "潜店地址",
  "form-student-info": "学员信息",
  "form-firstname-hint": "请输入姓",
  "form-lastname-hint": "请输入名",
  "form-firstname-error": "姓不能为空",
  "form-lastname-error": "名不能为空",
  "form-gender-empty-error": "性别不能为空",
  "form-birthdate-hint": "请选择出生日期",
  "form-birthdate-empty-error": "出生日期不能为空",
  "form-address-empty-error": "地址不能为空",
  "form-app-info": "申请信息",
  "form-app-num": "申请编号",
  "form-app-status": "申请状态",
  "form-app-submit-time": "申请提交时间：",
  "form-apps-empty": "暂无申请",
  "form-pre-page": "上一页",
  "form-next-page": "下一页",
  "form-app-submit-date": "提交日期：",
  "form-membernum-empty-error": "会员编号不能为空",
  "form-name-format-error": "格式有误，请输入拼音或英文字母组合",
  "form-name-format-hint": "拼音或英文字母组合不区分大小写",
  "form-name-nochange-error": "提交的修改无变化，姓名拼音或英文字母组合不区分大小写",
  "form-confirm-cert": "确认发证",
  "form-cert-success": "发证成功",
  "form-instructor-info": "教练信息",
  "form-current-status": "当前状态：",
  "form-not-approved-reason": "不通过原因：",
  "form-upload-error": "请上传图片或者PDF文件",

  "youzan-binding-success": "绑定成功！",
  "youzan-binding-fail": "绑定失败！",

  "customize-cert-ctm-btn": "自定义电子卡面",
  "customize-cert-title": "感谢为公益捐赠，自定义您的电子卡面",
  "customize-cert-create-pre": "创作属于您的",
  "customize-cert-create-suffix": "电子卡面",
  "customize-cert-crop-btn": "确认上传",
  "customize-cert-change-btn": "更换图片",
  "customize-cert-upload-success": "图片上传成功，我们将尽快审核，请耐心等候。",
  "customize-cert-upload-failed": "图片上传遇到了问题，请稍候再试。",
  "customize-cert-upload-rejected": "您之前上传的图片因为以下原因未通过审核，请更换图片重新上传。",
  "customize-cert-hint1": "感谢您对海洋环保的关注和奉献！您的捐助将用于支持海洋环保行动。请在捐助成功后点击“我的”查看您的公益电子证书。",
  "customize-cert-hint2": "PADI 将对您上传的图片进行审核，待审核确认后，您的电子卡面将替换为您上传的图片。",
  "customize-cert-hint3": "请勿上传违反法律规定的色情、暴力、虚假图片，一旦发现，您的卡片被替换为默认卡面图片。",
  "customize-cert-hint4": "我同意相关{link}。",
  "customize-cert-hint5": "最终解释权归 PADI 中国所有。",
  "customize-cert-hint6": "您可以两指缩放、旋转图片。",
  "customize-cert-hint7": "点击logo切换不同logo。",
  "customize-cert-agree-first": "请阅读并勾选同意以上协议",
  "customize-upload-success": "上传成功！",
  "customize-select-amount": "请选择公益捐款金额。",

  "login-forgetEmail": "忘记邮箱？请联系你的教练或发邮件至",
  "login-bottommsg": "目前 PADI 电子潜水证书，仅支持地址在中国大陆、台湾、香港、澳门的潜水员",

  "userHeader-userHeaderMsg": "我是 PADI 潜水员！",
  "userHeader-userheaderamount": "自 {year} 年起，我已考取 {certAmount} 张 PADI 潜水证书!",

  "certlist-noCert": "欢迎加入 PADI 大家庭！如果你刚刚完成 PADI 课程，你的证书正从海底深处向你飞奔而来，请耐心等待。",
  "certlist-certCountry": "发证地区",
  "certlist-storeName": "潜店名称",
  "certlist-sendEmail": "没有找到你的证书？或发现电子潜水证书不全，请发邮件至 {email} 获得更多帮助。",
  "certlist-merge-certs": "证书缺失请点这里",
  "certlist-merge-loading": "我们正在处理您的证书查找请求，感谢您的耐心等候。",
  "certlist-shareTextOne": "我是 PADI 潜水员！我要影响身边更多的人!",
  "certlist-shareTextTwo": "点击右上角，速速{share}给你的朋友们吧!",
  "certlist-shareOnceForEcards": "我是 PADI 潜水员！自 {year} 年起，我已考取 {length} 张 PADI 潜水证书！",
  "certlist-shareOnceForNoEcards": "我也想学潜水，考取 PADI 潜水证书， 大家一起吧！",
  "certlist-shareTwice": "{year} 新年小目标就 TA 了！",
  "certlist-nextCard": "考取你的下一张",
  "certlist-scuba": "水肺潜水证书",
  "certlist-freediver": "自由潜水证书",
  "certlist-mermaid": "美人鱼证书",

  "sharecerts-isDiver": "如果你是潜水员，请扫描以下二维码",
  "sharecerts-padiWeChat": "关注 PADI 官方微信公众帐号：PADIDIVING",
  "sharecerts-checkCerts": "查看自己的潜水证书",
  "sharecerts-isNotDiver": "如果你不是潜水员，欢迎关注 PADI",
  "sharecerts-endMsg": "一起探索地球另外71%的美",

  "certdetail-storeMsg": "潜店",
  "certdetail-address": "地址",
  "certdetail-phone": "电话",
  "certdetail-instructorInfo": "教练",
  "certdetail-name": "姓名",
  "certdetail-instructorNum": "PADI 编号",
  "certdetail-myInfo": "学员详情",
  "certdetail-diverNum": "PADI 编号",
  "certdetail-birth": "出生日期",
  "certdetail-certDate": "证书日期",
  "certdetail-expireDate": "证书有效至",
  "certdetail-switchCoverImage": "左右滑动选择您想展示的电子卡面图案",
  "certdetail-qualification": "资格",
  "certdetail-update-photo": "更新照片",
  "certdetail-reactivate": "再激活日期",

  "certDetailShareOnce": "我已是 PADI {ecardName}， 立即加入我们一起潜游全世界！",
  "certDetailShareTwice": "{year} 新年小目标就 TA 了！",

  "cardOptions-replaceCardHint": "温馨提示",
  "cardOptions-replaceCardMsg": "您当前证书申请中已添加此级别的“{currentLevelInCart}”。是否确认更改为此“{currentCardOption}”？",
  "cardOptions-addToCart": "加入申请列表",
  "cardOptions-existing": "已加入申请列表",
  "cardOptions-buy": "立即申请",

  "cardRequest-non-mainland": "如需申请实体证书，请前往{url}。",
  "cardRequest-non-mainland-diver": "全球官网",
  "cardRequest-non-mainland-pro": "专业人士网站",
  "cardRequest-statusLoading": "正在查询实体潜水证书申请记录。。。",
  "cardRequest-statusError": "查询实体潜水证书申请记录时发生错误，请稍候再试。。。",

  "qrCodeWrapper-attention": "欢迎关注 PADI 官方微信账号 PADIDIVING",
  "qrCodeWrapper-getMoreMsg": "获取最新潜水资讯和潜水培训课程活动信息",
  "qrCodeWrapper-service": "及享受最新潜水服务。",
  "qrCodeWrapper-dev": "此电子潜水证书服务由 PADI 官方开发完成",
  "qrCodeWrapper-Authorize": "请与PADI官方联系，获取转载授权！",
  "qrCodeWrapper-belongToPADI": "最终解释权，归 PADI 官方所有。",

  "emailLogin-login": "登 录",
  "emailLogin-emailInput": "请输入邮箱",
  "emailLogin-errorCode": "请输入正确的验证码",

  "loginComponent-signUp": "立即注册",
  "loginComponent-signUpTitle": "注册",
  "loginComponent-errorPassword": "请输入正确的邮箱地址及密码",
  "loginComponent-passwordInput": "请输入密码",
  "loginComponent-forgotPassword": "找回密码",
  "loginComponent-footer": "请输入您的 PADI 统一登录账号和密码登录",
  "loginComponent-padiIntro": "PADI 全世界学习潜水的途径，蓝色星球的保护者大本营，和PADI一起择善而行，了解更多请关注 PADIDIVING。",
  "reset-pwd-attention": "注意",
  "reset-pwd-desc": "重新设定密码成功后，页面可能没有变化。此时您可以返回电子卡登录页面，使用新密码进行登录。",
  "reset-pwd-skip-now": "立即跳转",

  "setEmail-errorEmail": "请输入正确的邮箱地址以及6位数字验证码",
  "setEmail-title": "验证邮箱",
  "setEmail-login": "立即登录",

  "setPassword-errorConfirmPassword": "请确认您输入的密码是否一致",
  "setPassword-title": "设置密码",

  "setProfile-errorName": "请输入姓名（拼音或英文字母组合）",
  "setProfile-defaultName": "拼音或英文字母组合",
  "setProfile-errorGender": "请选择性别",
  "setProfile-errorBirthDate": "请选择出生年月日",
  "setProfile-name": "姓名",
  "setProfile-gender": "性别",
  "setProfile-chooseDate": "选择日期",
  "setProfile-birthDate": "出生日期",
  "setProfile-skip": "跳过此步",
  "setProfile-footer": "如您愿意，请协助完成您的个人信息，以便您可正常使用后续其他服务。谢谢您的支持！",
  "setProfile-title": "个人信息",
  "setProfile-firstName": "名",
  "setProfile-lastName": "姓",

  "setMobile-China": "大陆地区",
  "setMobile-Hongkong": "香港地区",
  "setMobile-Taiwan": "台湾地区",
  "setMobile-Macau": "澳门地区",
  "setMobile-errorCode": "请输入正确的手机号以及6位数字验证码",
  "setMobile-chooseRegion": "选择地区",
  "setMobile-regions": "地区",
  "setMobile-mobileInput": "请输入手机号",
  "setMobile-title": "验证手机",

  "forgotPassword-reset": "重置密码",
  "forgotPassword-title": "请输入您的邮箱地址",
  "forgotPassword-emailInput": "请输入邮箱",
  "forgotPassword-sendEmail": "请登录您的邮箱，查看重设密码邮件，并进行对应操作。",
  "forgotPassword-sendSuccess": "发送成功",
  "forgotPassword-footer": "如您重设密码，您的所有 PADI 相关账号，包含PADI 专业人士网站，Scuba Earth， My PADI Club， eLearning 账号密码均会被同步更改。",

  "getCode-sendMobile": "验证码已发送。如未收到短信，请查看是否在被屏蔽的短信中。输入收到的验证码并点击\"验证\"完成手机验证。",
  "getCode-sendEmail": "验证码已发送。如验证码未在收件箱中，请查看垃圾邮箱。请输入收到的验证码并点击\"验证\"完成邮箱验证。",

  "campaign-loadingEcards": "正在查询您的证书。。。",
  "campaign-noEcardSelected": "请选择证书。",
  "campaign-tips": "请选择一张欲更换龙年卡的潜水证书",

  "cardRequest-cart": "公益卡申请",
  "cardRequest-count": "共有 {number} 张 {padi} 公益卡",
  "cardRequest-continueToBuy": " 继续申请",
  "cardRequest-checkedAll": "全选",
  "cardRequest-total": "合计：",
  "cardRequest-goToPay": "去结算",
  "cardRequest-empty": "您还没有申请公益卡~",
  "cardRequest-goToCerts": " 去申请",
  "cardRequest-title": "潜水证书",
  "cardRequest-postage": "邮费：",

  "profile-profile": "个人信息",
  "profile-name": "姓名：",
  "profile-birthDate": "出生日期：",
  "profile-mobile": "电话：",
  "profile-addressEdit": "收货地址管理",
  "profile-language": "设置语言",
  "profile-setLanguage": "请设置语言",
  "profile-youzan": "绑定 PADI 有赞商城账号",
  "profile-book": "购买签证服务",
  "profile-agent-elearning": "elearning 代购",
  "profile-efr-public": "EFR Public 公众救护代购",
  "profile-changeYouzan": "更换 PADI 有赞商城账号",
  "profile-mobileTips": "仅限中国大陆的手机号码",
  "profile-currentMobile": "当前绑定的手机号：",
  "profile-binding": "绑定",
  "profile-apply-manager": "申请潜店管理者",
  "profile-reviewing": "审核中",
  "profile-change-manager": "潜店管理者变更",
  "profile-remove-manager": "解除潜店管理者",
  "profile-action-sheet-title": "您目前管理的潜店为：",
  "profile-change-or-remove": "，您可以进行变更或者移除对该潜店的管理。",
  "profile-remove": "解 除",
  "profile-comfirm-text": "确认解除潜店管理者身份？",
  "profile-remove-success": "解除成功",
  "profile-store-number-error": "请输入正确的潜店编号。",
  "profile-change-manager-title": "更改潜店管理",
  "profile-store-number-input": "请输入潜店编号。",
  "profile-store-list-title": "您是潜店({storeNum})的管理者",
  "profile-store-title": "潜店信息",
  "profile-store-name": "潜店名称: ",
  "profile-store-num": "潜店编号: ",
  "profile-copyright": "版权声明",
  "profile-email": "更换邮箱",
  "profile-dm": "潜水长申请",
  "profile-app": "潜水长申请",
  "profile-si": "专长教练申请",
  "profile-si-cd": "签发专长教练",
  "profile-owsi-cd": "IE 报名审核",
  "profile-owsi": "IE 考试报名及教练申请",
  "profile-ie": "IE 考试",
  "profile-efrt": "EFRI 审核",
  "profile-BMIT": "基础美人鱼教练审核",
  "profile-MIT": "美人鱼教练审核",
  "profile-fdi": "自由潜水员教练审核",
  "profile-afdi": "进阶自由潜水员教练审核",
  "profile-mfdi": "名仕自由潜水员教练审核",
  "profile-fd-pic": "自由潜升级签证",
  "profile-scuba-app": "水肺专业级别申请",
  "profile-efr-app": "EFR 专业级别申请",
  "profile-efr-public-app": "EFR Public 公众救护教练申请",
  "profile-mermaid-app": "美人鱼专业级别申请",
  "profile-fd-app": "自由潜专业级别申请",
  "profile-course-pic": "预定课程签证",
  "profile-course-insurance": "选购潜水保险服务",
  "profile-insurance-shopPublic": "俱乐部/潜店责任险",
  "profile-insurance-instructor": "教练职业责任险",
  "profile-insurance-diver": "潜水学员个人意外险（成为推广俱乐部/教练）",

  "addressList-hintMsg": "确定要删除该地址？",
  "addressList-title": "我的收货地址",
  "addressList-edit": "编辑",
  "addressList-delete": "删除",
  "addressList-noAddress": "您还没有添加新地址哦~",
  "addressList-add": "新建收货地址",

  "addressForm-edit": "编辑地址",
  "addressForm-add": "填写地址",
  "addressForm-contact": "联系人",
  "addressForm-mobile": "手机号码",
  "addressForm-region": "所在地区",
  "addressForm-address": "地址",
  "addressForm-save": "保 存",
  "addressForm-defaultName": "张三",
  "addressForm-selectRegion": "选择省市",
  "addressForm-addressDetail": "街道，门牌号",
  "addressForm-saveFail": "保存失败",
  "addressForm-saveHint": "请填写正确的联系信息和地址信息。",

  "home": '电子证书',
  "navi-campaign": '最新活动',
  "navi-store": 'PADI 有赞商城',
  "cardRequest": "PADI 公益卡",
  "download-wall-cert": "下载墙上证书",
  "wechat-wall-cert": "请在浏览器中打开，下载墙上证书",
  "wechat-cert": "请在浏览器中打开，下载证书",
  "apply-for-free-card": "申请免费塑料卡",
  "apply-for-free-card-ok": "申请成功，请耐心等候。",
  "profile": "我的",

  "success-title": "注册成功!",
  "success-hasSso": "请使用以下 PADI 统一登录账号登录：",
  "success-login": "前往登录",
  "failure-title": "注册失败",
  "failure-hint": "注册失败！请稍后再试",
  "failure-service": "联系客服：chinese.services@padi.com",
  "success-SignIn": "返回注册",
  "success-hasYouzan": "您的PADI有赞商城手机账户：",
  "success-hasnoYouzan": "您的手机号未能绑定 PADI 有赞商城账户，请前往个人中心进行手动绑定",

  "renew-member-title": "个人会员更新",
  "renew-status-ok": "您的2024会员资格已经续费",
  "renew-status-in-progress": "处理中",
  "renew-subtitle": "证书和费用信息",
  "renew-exclude-efr": "若要排除EFRI续订，请单击复选框取消",
  "renew-statement": "结算单",
  "renew-back": "返回个人中心",
  "renew-store-title": "潜店会员更新",
  "renew-choose-item": "请选择您要续费的项目（可多选）",
  "renew-hint": "请选择您要续费的项目",
  "renew-statement-tips": "2023年1月1日前更新获得elearning优惠券",

  "store-list-no-more-store": "暂无更多潜店",
  "store-list-freedive": "自由潜",

  "dm-form-title": "PADI潜水长申请",
  "dm-app-list": 'PADI潜水长申请列表',
  "dm-form-diver-info": "申请人信息",
  "dm-form-name": "姓名",
  "dm-form-birth": "生日",
  "dm-form-confirm-birth": "请确认生日",
  "dm-form-required-certs": "先决条件",
  "dm-form-training-info": "训练信息",
  "dm-form-instructor-warning": "请输入教练编号",
  "dm-form-instructor-number": "教练编号",
  "dm-form-store-warning": "请输入潜店编号（可选）",
  "dm-form-store-number": "潜店编号",
  "dm-form-complete-date": "完成日期",
  "dm-form-date-warning": "请选择完成日期",
  "dm-form-skip-to": "将证书寄回",
  "dm-form-agreement-60-dives": "我已完成至少60次潜水记录。",
  "dm-form-agree": "同意",
  "dm-form-reject": "拒绝",
  "dm-form-agreement-link": "会员资格协议书和授权协议书",
  "dm-form-agreement-text": "我已阅读完毕 PADI {link}，并特此同意全部条款内容。我已了解并且同意，无论是在具有 PADI 会员资格的期间或在此之前，如有任何涉及未成年虐待或成人性虐待之刑事定罪，我的 PADI 会员资格将自动终止或被否决。此外，我已阅读完毕「自动更新条款」，若我选择注册自动更新，代表我同意全部条款内容。 (请见第二页「条款」)根据本人所知所信，我在此保证上述资料皆真实且正确。递交本申请表不代表已成为 PADI 会员，只有通过 PADI 的复审及批准后才能成为 PADI 的活跃会员。",
  "dm-form-instructor-agreement": "本人证明此申请人已具备至少 60 次的潜水纪录。本人保证所有 PADI 教练手册中所述的先决条件和签证规定均已符合。",
  "dm-form-submit": "提交申请",
  "dm-form-searching": "正在搜索...",
  "dm-form-instructor-error": "请输入正确的教练编号。",
  "dm-form-submit-error": "提交失败",
  "dm-form-submit-error-info": "请确保所有信息输入正确，并同意相关协议和授权。",
  "dm-form-submit-error-info-no-agreement": "请确保所有信息输入正确。",
  "dm-form-entrance-title": "DM申请",
  "dm-form-applicationNo": "您的申请号为：",
  "dm-form-message-review": "您的教练以及PADI将会审核您的申请。您可以随时访问电子卡系统查看申请进展。",
  "dm-form-message-address": "为了加快您的审核，请尽快将潜水长课程提供的激光防伪贴，附注您的申请号或邮箱或任意证书编号，按以下地址邮寄到PADI北京办公室：",
  "dm-form-message-address-detail": "北京市朝阳区光华路22号SOHO一期2单元1515",
  "dm-form-pay": "继续付款",
  "dm-form-signature": "签名",
  "dm-form-signature-clear": "清除",
  "dm-form-signature-save": "保存",
  "dm-form-wait-review": "等待教练审核",
  "dm-form-wait-cert": "等待签证",
  "dm-form-wait-pay-student": "等待申请人付款",
  "dm-form-wait-pay-instructor": "等待教练付款",
  "dm-form-processing": "PADI处理中",
  "dm-form-resubmit": "重新提交",
  "dm-form-signature-confirm": "请确认您已签名。",
  "dm-form-instructor-create": "新建申请",
  "dm-form-search-applicant": "输入候选人任意潜水证书编号",
  "dm-form-student-number": "证书编号",
  "dm-form-studentno-error": "请输入正确的证书编号。",
  "dm-form-studentno-not-found": "未找到申请人，请确认输入的证书编号正确。",
  "dm-form-student-added": "申请人已添加。",
  "dm-form-storeno-not-found": "未找到潜店，请确认输入的潜店编号正确。",
  "dm-application-empty-list": "没有进行中的申请。",
  "dm-application-add-app": "开始申请",
  "dm-app-not-approved-tips": "您提交的申请被拒绝，请重新提交申请。拒绝理由：",
  "dm-refuse-reason": "拒绝理由",
  "dm-refuse-tips": "请输入拒绝理由（必填）",
  "dm-refuse-error": "请输入拒绝理由。",
  "dm-region-and-lang-title": "教学地址及语言",
  "dm-select-region-error": "请选择地区",
  "dm-region": "教学地址",
  "dm-lang": "选择语言",
  "dm-lang-error": "请选择语言",
  "dm-lang-title": "语言",
  "dm-efr-expired": "EFR证书已过期，请重新上传相关材料进行审核。",
  "dm-dmai-text": "如果您同期提交了DM申请，可以上传DM申请的截图。",
  "dm-cert-info": "证书信息",

  "dm-form-instructor-reject-confirm": "确认拒绝该申请？",
  "dm-form-instructor-accept-confirm": "确认同意该申请？",

  "app-status-wait-confirm": "待确认",
  "app-status-instructor-approved": "已确认",
  "app-status-instructor-not-approved": "已拒绝",
  "app-status-success": "已完成",
  "app-status-instructor-created": "已创建",
  "app-status-submit": "申请人提交",
  "app-status-paid": "已付款",
  "app-status-to-pay": "待付款",
  "app-to-pay": "去支付",
  "app-wait-pay": "待支付",
  "app-wait-cs-review": "等待客服审核",
  "app-wait-candidate-pay": "等待候选人支付",
  "app-cs-not-approved": "客服审核未通过",
  "app-refuse-reason": "拒绝原因：",
  "app-wait-cert": "等待发证",
  "app-success": "已录入",
  "app-close": "已关闭",
  "app-wait-cd-review": "等待总监审核",
  "app-wait-cd-pay": "等待总监支付",
  "app-has-efri": "有符合申请条件的EFRI证书",
  "app-efr-or-efri": "EFR或EFRI",
  "app-health": "上传健康声明等材料",
  "app-health-and-others": "上传健康声明、跟课证明等材料",
  "app-owsi-other-files": "如有其他机构证书副本请上传/健康声明电子版请上传并将纸本带至考场",
  "app-efr-or-efri-files": "EFR/EFRI材料",
  "app-not-has-right-cert": "未查询到此证书",
  "app-wait-ins-review": "等待教练审核",
  "app-wait-trainer-review": "等待训练官审核",
  "app-resubmit": "重新提交",
  "app-submit": "提交申请",
  "app-wait-applicant-confirm": "等待申请人确认",
  "app-pay-error": "支付异常",
  "app-prosite": "需前往pro site自行修改",
  "app-delete": "取消申请",
  "app-cancel": "确认取消该申请？",

  "resources-marketing-hub": "市场营销资源中心",
  "resources-training-hub": "训练资源中心",
  "resources-mainTitle": "PADI 中文素材库",
  "resources-link": "请您将需要注册PADI CANVA 的邮箱账号发邮件到chinese.member@padi.com，我们会为您发送定向邀请邮件，您通过收到的邮件邀请链接进行注册",
  "resources-introduction": "欢迎来到 PADI 中文素材库，您可在此查询/下载有关 PADI 中文市场宣传素材、课程培训资料及 PADI 产品资料等。如您未拥有 PADI CANVA 账号，{link}，以便访问各类图片素材。",
  "resources-canva-tips": "需要注册Canva查看此内容。",
  "resources-cana-registered": "已注册",
  "resources-cana-to-register": "现在注册",

  "campaign-more": "更多精彩，敬请期待！",
  "campaign-title-festival": "2021嘉年华门票",
  "campaign-festival-ticket-one": "1张",
  "campaign-festival-ticket-two": "2张",
  "campaign-festival-max-tickets": "每人限购1张。",
  "campaign-festival-error": "请至少购买一张门票。",
  "campaign-festival-max-tickets-error": "每人限购2张。",
  "campaign-festival-ticket": "门票",
  "campaign-title-coupon": "成功",
  "campaign-title-coupon-success": "优惠券发放成功。",
  "campaign-title-coupon-success-go": "马上使用",
  "campaign-title-coupon-success-later": "稍后再说",
  "card-requested": "您已经领取过该卡片。",

  "si-profile": "个人信息",
  "si-member-number": "会员号",
  "si-level": "级别",
  "si-conditions": "已满足条件",
  "si-upload-attach": "需上传材料",
  "si-upload-btn": "上传材料",
  "si-form-title": "专长教练申请",
  "si-agreement-content": "我已经签证过至少 25 位潜水员，并在我所申请的每一项专长领域中完成并记录至少 20 次潜水，若 PADI 要求我出示这些潜水记录，我将会配合。此外，我同意使用 PADI 标准大纲或经由 PADI 审阅并批准的教练撰写的课程大纲，同时了解我仅可以在收到 PADI 书面同意和我的专长教练证明文件后，才可以执行上列专长课程。",
  "si-app-number": "申请编号：",
  "si-check": "查看",
  "si-reSubmit": "重新申请",
  "si-sp": "专长：",
  "si-app-status": "申请状态：",
  "si-sp-app": "申请专长",
  "si-check-attach": "查看已上传材料",
  "si-open-attach": "查看材料",
  "si-agreement-cd": "我同意使用 PADI 标准大纲或由我的课程总监所提供的大纲，同时了解我仅可以在收到 PADI 书面同意和我的专长教练证明文件后，方可执行上列的专长课程。此外，我已经在我所申请的每一项专长领域完成并记录了至少 10 次潜水(半密闭式呼吸循环器需要 20 次)，若 PADI 要求我出示这些潜水记录，我将会配合。",
  "si-cd-course": "课程信息",
  "si-store-num": "请输入潜店编号",
  "si-course-location": "请输入课程地点",
  "si-course-location-title": "课程地点",
  "si-app-name": "申请人：",
  "si-cd-instructor-num": "会员编号",
  "si-cd-instructor-num-tip": "请输入会员编号",
  "si-uploadAttach-error": "最多上传五个附件材料。",
  "si-instructor-number": "会员编号：",
  "si-cd-instructor-num-error": "请输入正确的会员编号。",
  "si-refuse-reason": "拒绝理由：",
  "si-no-direct-sp": "没有可申请的专长。",
  "si-no-attach-sp": "没有可申请的专长。",
  "si-agreement-cd-instructor": "我同意使用 PADI 标准大纲或由我的课程总监所提供的大纲，同时了解我仅可以在收到 PADI 书面同意和我的专长教练证明文件后，方可执行上列的专长课程。此外，我已经在我所申请的每一项专长领域完成并记录了至少 10 次潜水(半密闭式呼吸循环器需要 20 次)，若 PADI 要求我出示这些潜水记录，我将会配合。",
  "si-credits": "签证要求",
  "si-credits-text": "需要至少签证至少25名潜水员才能申请专长教练。",
  "si-wait-cd-to-pay": "等待总监付款",
  "si-back": "返回",
  "si-paypal": '您已提交 {count} 个专长，单价 {price} 澳币，折扣后价格为 {total} 澳币，点击确定前往付款。',

  "book-search": "输入一个搜索关键字，如代码、名称",
  "book-total": "合计：",
  "book-checkout": "去结算",
  "book-checkout-hint": "结算查看折扣",
  "book-submit-success": "订单提交成功！",
  "book-offline-instruction": "您的订单已经成功创建，请汇款 {price} 到以下账户，并将付款凭证电邮到chinese.sales@padi.com，我们将在收到您的付款凭证并核对成功后安排发货。",
  "book-result-bank": "银行: ",
  "book-result-address": "地址: ",
  "book-result-account-name": "账户名: ",
  "book-result-account-number": "账户编号: ",
  "book-remittance": "汇款信息",

  "owsi-cd-info": "课程总监信息",
  "owsi-cd-tips": "请输入课程总监编号",
  "owsi-cd-number-error": "请输入正确的总监编号",
  "owsi-number": "总监编号",
  "owsi-store-info-tips": "任选以下一项填写即可",
  "owsi-store-select": "选择潜店",
  "owsi-alternative-location": "备选地点",
  "owsi-age-conditions": "申请要求",
  "owsi-age-accord": "您的年龄已满18岁，符合申请要求。",
  "owsi-age-not-accord": "您的年龄未满18岁，不符合申请要求。",
  "owsi-cert-conditions": "证书条件",
  "owsi-application": "开放水域水肺教练申请",
  "owsi-location-tips": "请输入地点（限20字以内）",
  "owsi-message-review": "课程总监以及PADI将会审核您的申请。您可以随时访问电子卡系统查看申请进展。",
  "owsi-training-records-tips": "请上传训练记录。",
  "owsi-pay-tips": "请选择支付方式。",
  "owsi-candidate-pay": "候选人支付",
  "owsi-cd-pay": "总监支付",
  "owsi-ie-location": "考试地点",
  "owsi-ie-date": "考试时间",
  "owsi-ie": "IE考试信息",
  "owsi-candidate-list": "候选人列表",
  "owsi-selected-ie": "选择IE考试场次",
  "owsi-ie-list": "IE考试场次",
  "owsi-training-records": "训练材料",
  "owsi-upload-traning-records": "上传训练材料",
  "owsi-agreement-text": "本人已阅读最新{agreement}，认可并同意全部条款与条件。",
  "owsi-agreement": " PADI 会员协议书和 PADI 授权协议书",
  "owsi-criminal-record-statement": "我已了解并且同意，无论是在具有 PADI 会员资格的期间或在此之前，如有任何涉及未成年虐待或性虐待之刑事定罪，本人的 PADI 会员资格将自动终止或被否决。根据本人所知所信，我保证上述资料皆真实且正确。",
  "owsi-cd-agreement-one": "本人作为课程总监特此确认此候选人满足参加PADI 教练发展课程和 PADI 教练考试(IE)的潜水经验要求（教练培训开始之日已有60次潜水记录且获得潜水证书已超过6个月，教练考试开始之日已有100次潜水记录）",
  "owsi-cd-agreement-two": "本人作为课程总监在此确认以上所列之教练候选人拥有所有课程必须教材包括水中提示卡。",
  "owsi-idc-error": "请选择IDC开始日期",
  "owsi-idc-end-error": "请选择IDC结束日期",
  "owsi-idc-date": "IDC开始日期",
  "owsi-idc-end-date": "IDC结束日期",
  "owsi-not-pass": "考试未通过",
  "owsi-not-pass-reason": "未通过原因：",
  "owsi-paypal-reminder": '即将支付 IDC教练发展课程申请费 和 教练考试费，总计 {currency} {price}。',
  "owsi-paypal-attempt-all": '即将支付 教练考试费，总计 {currency} {price}。',
  "owsi-paypal-attempt": "即将支付 教练考试单科重考费，总计 {currency} {price}。",
  "owsi-extensions": "IE笔试考试时间延长",
  "owsi-extensions-reminder": "请联系教练发展部告知详情。",
  "owsi-ie-times": "IE考试次数",
  "owsi-ie-first": "首次考试",
  "owsi-ie-second": "第二次考试",
  "owsi-ie-third": "第三次考试",
  "owsi-ie-fourth": "第四次考试",
  "owsi-ie-resit": "IE考试补考科目",
  "owsi-ie-written-exam": "理论考试",
  "owsi-ie-knowledge": "知识发展",
  "owsi-ie-confined-water": "平静水域",
  "owsi-ie-open-water": "开放水域",
  "owsi-id-resits": "补考",
  "owsi-ie-resits-app": "IE补考申请",
  "owsi-cd-other-files": "请上传教练候选人资料及训练记录/健康声明副本请带至考场",

  "items-list": "购买清单",
  "price-and-shipment": "价格及运费",
  "discount-level": "折扣级别：",
  "discount-price": "折扣价：",
  "discount-coupon": "优惠券：",
  "item-price": "商品总价：",
  "shipment-fee": "运费：",
  "total-price": "总价：",
  "checkout": "支付",
  "change-order": "返回修改",
  "payment-method": "选择付款方式",
  "online-pay": "线上付款",
  "offline-pay": "线下汇款",
  "coupon-tips": "您有{count}张elearning优惠券可以使用。",
  "has-coupon": "您有{count}张优惠券可以使用",
  "coupon": "优惠券",
  "coupon-description": "满{description}可用",
  "coupon-conditions": "仅限elearning商品使用",
  "coupon-conditions-2023": "仅限部分elearning商品使用",
  "coupon-use-error": "您购买的elearning商品的支付金额需大于优惠券金额。",
  "coupon-use-error-2023": "当前订单没有可以抵扣的商品。",
  "coupon-not-to-use": "暂不使用优惠券",
  "coupon-used": "已使用{count}张优惠券",
  "coupon-without": "暂无可使用的优惠券",
  "coupon-voucher": "兑换券",
  "coupon-useless": "不可用原因：",
  "coupon-expired": "优惠券已过期。",

  "paypal-callback": "支付中，请稍候。。。",
  "paypal-callback-success": "支付成功。",
  "paypal-callback-error": "支付失败。",
  "paypal-order-detail": "订单信息",
  "paypal-order-number": "订单号：",
  "paypal-order-price": "总金额：",
  "paypal-goods-name": "商品名称：",
  "paypal-goods-quantity": "商品数量：",
  "paypal-reminder": '即将支付 {currency} {price}。',
  "paypal-to-pay": "是否现在付款？",
  "paypal-pay-now": "提交成功！是否立即付款？",
  "paypal-pay": "去付款",
  "paypal-pay-later": "暂不付款",

  "credit-card-saveHint": "请填写正确的信用卡信息",
  "credit-card-title": "信用卡信息",
  "credit-card-number": "信用卡卡号",
  "credit-card-date-year": "有效期年份（YY）",
  "credit-card-date-month": "有效期月份（MM）",
  "credit-card-ccv": "安全码",
  "credit-card-pay-success": "支付成功",
  "credit-card-accepted-types": "支持Visa，MasterCard，American Express.",

  "efri-agreement": "我了解在尚未获得 EFR 紧急第一反应授权之前，我还不能执行 EFR 紧急第一反应首要/次要救护和儿童救护课程，我也同意在执行 EFR 系列课程时，会遵守《EFR 紧急第一反应教练指引手册》、《反应员》季刊和其他最新资料公布的所有 EFR 标准和程序。我会对 EFR 教育教材保持熟悉，其中包括既有教材的改版和新教材的推出。我证实我已阅读并将遵守《EFR 紧急第一反应教练指引手册》附录中的<EFR 授权协议书>。",
  "efri-course-date-error": "请选择课程完成日期",
  "efri-application": "EFR 紧急第一反应教练申请",
  "efrt-list": "EFRI 申请列表",
  "efri-message-review": "训练官以及PADI将会审核您的申请。您可以随时访问电子卡系统查看申请进展。",
  "efri-age-not-accord": "您的年龄未满18岁，不符合 EFR 紧急第一反应教练的申请要求。",
  "efri-age-accord": "您的年龄已满18岁，符合 EFR 紧急第一反应教练的申请要求。",
  "efri-form-wait-review": "等待训练官审核",
  "efri-wait-efrt-pay": "等待训练官付款",
  "efri-efrt-pay": "训练官支付",
  "efrt-number": "训练官编号",
  "efrt-number-warning": "请输入训练官编号",
  "efrt-number-error": "请输入正确的训练官编号。",
  "efri-valid": "申请人为 EFR 教练",

  "agent-elearning-header-email": "为此学员代购",
  "agent-elearning-header-list": "可代购的elearning",
  "agent-elearning-email-hint": "请先输入学员邮箱以查找可用的elearning。",
  "agent-elearning-email-placeholder": "输入邮箱搜索学员",
  "agent-elearning-new-student": "在 PADI 系统中未查找到 {inputVal} 相关账号。如确认为新注册学员，请继续提供以下信息，我们将根据您提供的信息创建 PADI 统一登录账号，并邮件通知学员访问elearning系统。",
  "agent-elearning-not-available": "没有可用的elearning。目前仅支持Open Water, Advanced Open Water elearning的代购。",

  "BMI-app": "基础美人鱼教练申请",
  "BMI-agreement1": "本人同意遵守 PADI 美人鱼系列课程标准并了解只有在当地的 PADI 地区总部核发书面核准之后才能进行授课。",
  "BMI-agreement2": "本人已阅读会员协议书和授权协议书（附于 PADI 美人鱼教练指引附录），认可并同意全部条款与条件。",
  "BMI-agreement3": "本人了解并同意，若于取得 PADI 会员资格期间或之前受判虐待未成年者或性侵成年者罪名成立，本人 PADI 会员资格将自动据此遭到拒绝或终止。本人确认上述声明完全属实且正确。",
  "BMI-advanced-mermaid": "进阶美人鱼证书",
  "BMI-profects": "完成以下项目",
  "BMI-profect1": "美人鱼教练课程的知识发展部分",
  "BMI-profect2": "美人鱼教练期末考试，最低通过分数为75分",
  "BMI-profect3": "水中技能评估",
  "BMI-profect4": "展示示范等级的技巧演示",
  "BMI-profect5": "协助美人鱼教练执行至少1次体验美人鱼活动和2次基础美人鱼/美人鱼课程的平静水域潜水1",
  "BMI-pay-reminder": "如果您个人支付申请费用，请点击下方按钮完成支付申请费用。或者之后回到申请页面完成付款。",
  "BMI-pay-reminder-t": "如果训练官支付申请费用，您无需操作，训练官审核时我们提示完成付款。",
  "BMI-message-review": "训练官以及PADI将会审核您的申请。您可以随时访问电子卡系统查看申请进展。",
  "BMI-trainer-review-reminder": "审核成功！是否现在付款？",
  "BMI-t-list-title": "基础美人鱼教练申请列表",

  "Mermaidi-app": "美人鱼教练申请",
  "mermaid-t-list-title": "美人鱼教练申请列表",
  "mermaid-upload-reminder": "请上传 PADI 进阶美人鱼证书或等同级别证书，或提供15次美人鱼潜水经验证明，其中包括至少3次开放水域美人鱼潜水",
  "mermaid-other-files": "其他材料",
  "mermaid-choice": "2选1",
  "mermaid-choice1": "1，和一位具备教学身份的 PADI 美人鱼教练训练官完成 PADI 美人鱼教练课程，或者",
  "mermaid-choice2": "2，持有其他组织的美人鱼教练证书或更高等级证书。",
  "mermaid-choice2-1": "完成 PADI 美人鱼教练介绍课程",
  "mermaid-choice2-2": "已经教授或协助过至少 2 次美人鱼课程，课程等同达到 PADI 美人鱼课程要求， 或协助执行过 1 次 PADI 美人鱼课程",

  "fdi-app": "自由潜水员教练申请",
  "fdi-master-cert": "PADI名仕自由潜水员",
  "fdi-owsi-cert": "PADI 开放水域水肺教练",
  "fdi-not-padi-cert": "其他组织的合格自由潜水员教练证书",
  "fdi-advance-cert": "PADI 进阶自由潜水员",
  "fdi-owsi-reminder": "上传其他组织的合格自由潜水员教练证书。",
  "fdi-app-list": "自由潜教练申请列表",
  "fdi-agreement1": "我同意遵守 PADI 自由潜水员系列课程标准并知晓我仅可以在收到 PADI 地区总部的书面同意后，才可以执行本课程。",
  "fdi-agreement2": "我已阅读完毕 PADI 会员资格协议书和授权协议书（可以在 PADI 自由潜水员系列课程教练指引附录中找到），并特此同意全部条款内容。",
  "fdi-agreement3": "我已了解并且同意，无论是在具有PADI 会员资格的期间或在此之前，如有任何涉及未成年虐待或性虐待之刑事定罪，本人的PADI 会员资格将自动终止或被否决。根据本人所知所信，我保证上述资料皆真实且正确。",

  "afdi-app": "进阶自由潜水员教练申请",
  "afdi-padi-fdi": "PADI自由潜水员教练",
  "afdi-satisfy": "（符合申请要求）",
  "afdi-master-fd": "名仕自由潜水员",
  "afdi-certs-Requirement": "签证要求",
  "afdi-satisfy-certs": "已签发 25 张自由潜水员证书",
  "afdi-upload-cert-requirement": "需上传 25 张自由潜水员签证记录",
  "afdi-prepare": "PADI自由潜水员教练预备课程",
  "afdi-prepare-others": "PADI自由潜水员教练预备课程/其他材料",
  "afdi-t-title": "进阶自由潜教练申请列表",
  "afdi-agreement1": "本人同意遵守 PADI 自由潜水课程标准，并了解在当地的 PADI 地区总部寄发书面核准之后才能授课。",
  "afdi-agreement2": "本人已阅读会员协议书和授权协议书 (附于 PADI 自由潜水员课程教练指引附录)，兹此认可并同意全部条款与条件。",
  "afdi-agreement3": "本人了解并同意，若于取得PADI 会员资格期间或之前受判虐待未成年者或性侵成年者罪名成立，本人PADI 会员资格将自动据此遭到拒绝或终止。本人确认上述声明完全属实且正确。",

  "mfdi-app": "名仕自由潜水员教练申请",
  "mfdi-satisfy-certs": "已签发 50 张自由潜水员签证，其中至少有 10 张为进阶 PADI 自由潜水员签证",
  "mfdi-upload-cert-requirement": "需上传 50 张自由潜水员签证记录，其中至少有 10 张为进阶 PADI 自由潜水员签证",
  "mfd-padi-afd": "PADI进阶自由潜水员教练",
  "mfd-afdi": "进阶自由潜水员教练",
  "mfd-afdi-upload-reminder": "请上传其他组织的合格进阶自由潜水员教练证书材料。",
  "mfdi-agreement1": "本人同意遵守 PADI 自由潜水课程标准，并了解在当地的 PADI 地区总部寄发书面核准之后才能授课。",
  "mfdi-agreement2": "本人已阅读会员协议书和授权协议书 (附于 PADI 自由潜水员课程教练指引附录)，兹此认可并同意全部条款与条件。",
  "mfdi-agreement3": "本人了解并同意，若于取得 PADI 会员资格期间或之前受判虐待未成年者或性侵成年者罪名成立，本人 PADI 会员资格将自动据此遭到拒绝或终止。本人确认上述声明完全属实且正确。",
  "mfdi-t-title": "名仕自由潜教练申请列表",

  "fd-pic-new": "新建自由潜签证",
  "fd-pic-student-info": "学员信息（可添加多个）",
  "fd-pic-title": "自由潜签证",
  "fd-pic-email": "邮箱：",
  "fd-pic-status": "签证状态：",
  "fd-pic-date": "日期：",
  "fd-pic-level": "签证级别：",
  "fd-pic-finish": "已签证",
  "fd-pic-email-input": "输入您要搜索的邮箱",
  "fd-pic-course-level": "课程级别",

  "efris-apps-title": "EFR Public 代购一览",
  "efr-public-app-agreement": "在保持 EFR 教练的同时，本人自愿申请成为 EFR 公众救护专长教练。",
  "efr-public-app-done-title": "操作成功",
  "efr-public-app-done-detail": "您的 EFR Public 公众救护专长教练申请已通过！",
  "efr-public-app-waiting-title": "等待处理",
  "efr-public-app-waiting-detail": "PADI 已经收到您的 EFR Public 公众救护专长教练申请，请耐心等候，我们将尽快处理您的申请。",
  "efr-public-new-student": "在 PADI 系统中未查找到邮箱相关账号。如确认为新注册学员，请继续提供以下信息，我们将根据您提供的信息创建 PADI 统一登录账号并邮件通知学员。",

  "scuba-ow-title": "Scuba Diver 升级 OW 一览",
  "issue-certs-efrp-name": "EFR Public 代购",
  "issue-certs-confined-pic-name": "Confined Water Diver 签证购买",
  "issue-certs-scuba-pic-name": "Scuba Diver 签证购买",
  "issue-certs-scuba2ow-name": "Scuba Diver 升级 OW",
  "issue-certs-confined-name": "Confined Water Diver",
  "issue-certs-scuba-name": "Scuba Diver",
  "issue-certs-confined2ow-name": "Confined Water Diver 升级 OW",
  "issue-certs-pic-history": "购买记录",
  "issue-certs-pic-buy": "购买签证",
  "issue-certs-pic-amount": "购买数量",
  "issue-certs-pic-amount-placeholder": "请输入数量",
  "issue-certs-history": "签证记录",
  "issue-certs-pic-left": "签证名额剩余",

  "festival-submit-loading": "提交中",
  "festival-ticket-type": "门票类型",
  "festival-ticket-type-error": "门票类型为必选项",
  "festival-name-error": "姓名不能为空",
  "festival-name-hint": "请输入姓名",
  "festival-phone-error": "手机号不能为空",
  "festival-store-num": "潜店编号（选填）",
  "festival-store-name": "潜店中文名（选填）",
  "festival-store-name-hint": "请输入潜店中文名",
  "festival-checkin-name": "入住人姓名",
  "festival-checkin-name-error": "入住人姓名不能为空",
  "festival-checkin-name-hint": "请输入入住人姓名",
  "festival-house-type": "房型",
  "festival-house-type-error": "房型为必选项",
  "festival-double-bed": "双床房",
  "festival-big-bed": "大床房",
  "festival-arrival-date": "到店日期",
  "festival-arrival-date-error": "请选择到店日期",
  "festival-leave-date": "离店日期",

  "questionaire-title": "问卷调查",
  "questionaire-message": "恭喜您完成了 {course}，请您填写问卷，以便我们更好的为您提供服务，感谢您的支持！",
  "questionaire-course-OW": "PADI开放水域潜水员课程",
  "questionaire-course-BASIC_FD": "PADI基础自由潜课程",
  "questionaire-course-FD": "PADI自由潜课程",
  "questionaire-course-PADI_MERMAID": "PADI美人鱼课程",

  "procheck-about": "关于 Pro Check",
  "procheck-about-content": "详细了解 PADI Pro Check",
  "procheck-about2": "PADI Pro Chek 是一个用于验证 PADI 专业会员身份状态的工具，适用于所有人。",
  "procheck-about3": "您可以在下面找到 PADI 会员的姓名，最高核心 PADI 专业等级，目前状态以及 PADI 会员资格最后更新年份。",
  "procheck-about4": "● 教练通常已被授权，可按照证书中列出的资格教授 PADI 核心课程，指导潜水员并协助 PADI 课程。",
  "procheck-about5": "● 潜水长及助理教练通常已被授权，可指导潜水员并协助 PADI 课程。",
  "procheck-about6": "● 下面列出的信息并不详尽。 没有反映出教授某些专业 PADI 课程所必需的额外资质，以及在某些领域教授、指导或协助所必需的保险要求。",
  "procheck-about7": "PADI 会员已被授权使用各种 PADI 商标及进行 PADI 培训。 个人、潜水中心和度假村会员 不是 PADI 的代理、员工或加盟商。 PADI 会员的商业活动是独立的，既非 PADI 所有，也非由 PADI 操作经营。",
  "procheck-about8": "虽然 PADI 制定了 PADI 潜水员培训计划的标准，但 PADI 对 会员商业活动的运营、日常 PADI 计划的执行以及会员或其相关人员对潜水员的监督既不承担责任也无权控制。",
  "procheck-score": "PADI 评分",
  "procheck-auth": "获得教学授权：",
  "procheck-renew": "已续费：",
  "procheck-renew-date": "续费年份：",
  "procheck": "Pro Check 会员验证",
  "procheck-captcha": "验证码",
  "procheck-captcha-error": "验证码不能为空",
  "procheck-captcha-refresh": "点击验证码可刷新",

  "regulator-coupon-success": "核销成功",
  "regulator-coupon-fail": "核销失败",
  "refulator-coupon-check": "核销",

  "findme-header": "找回账号",
  "findme-student-num-title": "请提供您的证书编号以帮助我们找回您的账号",
  "findme-student-num-empty": "证书编号不能为空",
  "findme-student-num-hint": "请输入证书编号",
  "findme-guide-disc": "可以根据您的潜水证书编号找回账号，如果您不知道您的潜水证书编号，请和您的教练联系；或者提交您的个人信息来找回账号。",
  "findme-guide-student-num-yes": "我有潜水证书编号",
  "findme-guide-student-num-no": "我不记得潜水证书编号",
  "findme-user-info-desc": "请提供您的个人信息以帮助我们找回您的账号",
  "findme-contact-service-tips": "请输入您在使用的邮箱，作为账号注册、登录系统。",
  "findme-not-find": "我们未能找到相关账号，您可以返回重新填写、确认信息。或者联系客服找回账号。",
  "findme-contact-service": "联系客服找回账号",
  "findme-fill-in-again": "重新填写",
  "findme-success-contact-service": "如果这不是您的账号，您可以联系客服找回账号，或者返回重新填写信息查找。",
  "findme-login": "立即注册/登录",
  "findme-has-email-account": "如果您拥有此邮箱账号，您可以使用该账号注册/登录。",
  "findme-find-email-tips": "根据您提供的信息，我们在系统中查找到以下账号：",
  "findme-submit-email": "提交找回账号请求",
  "findme-desc": "如果您能提供潜水证书编号，将更有助于找回账号，可以和您的教练联系得到证书编号。",
  "findme-email-option": "请输入证书编号（选填）",
  "findme-submit-tips": "您的账号找回/更改请求已经成功提交，我们的工作人员将尽快进行处理，请关注您的邮箱，感谢您的耐心等候！",

  "updateUserInfo-title": "修改信息",
  "updateUserInfo-hint": "请输入新的个人信息",
  "updateUserInfo-success": "您的需求已经提交，预计1~2个工作日处理完毕",

  "pocket-apps-title": "其他会员申请",
  "pocket-apps-type": "申请类型",
  "pocket-apps-type-error": "申请类型不能为空",

  "store-apps": "潜店申请",
  "store-apps-type": "潜店类型",
  "store-apps-error": "潜店类型不能为空",

  "award-title": "个人荣誉",
  "award-anniversary": "周年",
  "award-elite": "精英教练",
  "award-no-elite": "继续签发更多证书，成为PADI精英教练",
  "award-year": "年份",
  "award-cert": "证书",
  "award-download-cert": "下载证书",
  "award-download": "下载",
  "award-anniversary-year": "PADI 会员 {year} 周年证书（{currentYear}）",
  "award-no-anniversary": "继续努力，赢取您的第一个5周年证书",

  "club-certs-list-title": "请选择您想更换卡面的证书",
  "club-receive-btn": "立即领取",
  "club-success": "领取成功",

  "textbooks-order": "签证服务历史订单",
  "unconventional-order": "非常规商品历史订单"
}
export default sc;
