import React from 'react'
import { Result, Icon, WhiteSpace } from 'antd-mobile';

const ResultPage = () => {
  return <Result
  img={<Icon type="check-circle" className="spe" style={{ fill: '#1F90E6', width: '60px', height: '60px' }} />}
  title="提交成功"
/>
}

export default ResultPage