import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getStoreFullAddress } from '../../components/common/utils'
import styles from './CertDetailList.module.css'

const DiveShopInformation = ({ cert }) => cert.storeNumber && cert.storeNumber !== 24351 && (
  <div className={styles.store}>
    <h3 className={styles.title}><FormattedMessage id="certdetail-storeMsg" /></h3>
    <div className={styles.storeInfo}>
      {cert.storeName && <div className={styles.storeName}>{`${cert.storeName}（${cert.storeNumber}）`}</div>}
      <div className={styles.storeAddress}>
        <span className={styles.itemTitle}><FormattedMessage id="certdetail-address" /></span>
        <span className={styles.address}>{getStoreFullAddress(cert)}</span>
      </div>
      <div className={styles.phone}>
        <span className={styles.itemTitle}><FormattedMessage id="certdetail-phone" /></span>
        <span className={styles.phoneNum}>{cert.storePhoneNumber}</span>
      </div>
    </div>
  </div>
);

export { DiveShopInformation }
