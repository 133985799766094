import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Modal } from 'antd-mobile-v5'
import { Input } from 'antd';
import FindSuccess from './FindSuccess'
import FindFailure from './FindFailure'
import { useIntl } from 'react-intl'
import { useSubmitFindMeByRequest } from '../../services/hooks'
import { useHistory } from 'react-router-dom'

const ContactMeDialog = ({ visible, setVisible, params }) => {
  const { push } = useHistory()
  const { formatMessage } = useIntl()
  const [emailInput, setEmail] = useState()
  const { loading, run } = useSubmitFindMeByRequest({
    onSuccess: (data) => {
      if(data) {
        if (data) push('/find_account/submit_result')
      }
    }
  });

  const submit = () => {
    const { email, lang, ...otherParams } = params
    const data = { ...otherParams, email: emailInput }
    run(data)
  }

  return <Modal
    visible={visible}
    header={formatMessage({ id: 'findme-contact-service-tips' })}
    content={<Input onChange={e => setEmail(e.target.value)} />}
    closeOnAction
    onClose={() => {
      setVisible(false)
    }}
    actions={[
      {
        key: 'confirm',
        text: formatMessage({ id: 'common-comfirmBtn' }),
        disabled: !emailInput,
        loading,
        onClick: () => submit()
      },
      {
        key: 'cancel',
        text: formatMessage({ id: 'common-cancel' }),
      }
    ]}
  />
}

const FindResult = () => {

  const { state } = useLocation()
  const [ModalVisible, setModalVisible] = useState(false)

  return <>
    {state.email 
      ? <FindSuccess 
        email={state.email} 
        setVisible={setModalVisible} /> 
      : <FindFailure setVisible={setModalVisible} />}
    <ContactMeDialog 
      visible={ModalVisible}
      setVisible={setModalVisible}
      params={state}
    />
  </>
}

export default FindResult