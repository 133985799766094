import React from 'react';
import { List } from 'antd-mobile';
import { FormattedMessage } from 'react-intl';
import { getCookie } from '../../components/common/cookie';
import OrderPrice from './OrderPrice';
import { formatMoney } from '../../components/common/utils';

import styles from './PriceList.module.css';

const memberType = {
  sc: {
    IM: '个人会员',
    CD: '总监',
    PRRA: '潜店',
    PRRA5: '5星潜店',
  },
  tc: {
    IM: '個人會員',
    CD: '總監',
    PRRA: '潛店',
    PRRA5: '5星潛店',
  },
  en: {
    IM: 'IM',
    CD: 'CD',
    PRRA: 'PRRA',
    PRRA5: 'PRRA5',
  },
};
const getDiscountLevel = (level) => {
  const language = getCookie('language');
  const [type, discountLevel] = level.split(' ');
  return memberType[language][type] + ' ' + discountLevel;
};

export default function PriceSummary({
  order,
  selectedCoupon,
  currencyType,
}) {
  return <List renderHeader={<FormattedMessage id="price-and-shipment" />} className={styles.container}>
    <List.Item>
      <OrderPrice
        labelId="discount-level"
          value={order ? getDiscountLevel(order.level) : ''}
      />
      <OrderPrice
        labelId="discount-price"
        value={order ? formatMoney(order.itemsPrice, currencyType) : '-.--'}
      />
      {selectedCoupon && <OrderPrice
        labelId="discount-coupon"
        value={'-' + formatMoney(order.couponPrice, currencyType)}
      />}
      <OrderPrice
        labelId="shipment-fee"
        value={order ? formatMoney(order.expressPrice, currencyType) : '-.--'}
      />
      <OrderPrice
        labelId="total-price"
          value={order ? formatMoney(order.totalPayment, currencyType) : '-.--'}
      />
    </List.Item>
  </List>
}