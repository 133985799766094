import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Tag, Empty, Button } from 'antd';
import { List, Popover, Flex, WhiteSpace } from 'antd-mobile';
import { formatDate, showAlert } from '../../components/common/utils';
import { dsdIssueCerts } from '../../services/api'
import { useIntl } from 'react-intl';
const Item = Popover.Item;
function getFutureDate() {
  const today = new Date()
  let dateList = []
  for (let i = 0; i < 10; i++) {
    let date = today.getTime() + 1000 * 60 * 60 * 24 * i
    dateList.push(formatDate(date, 'YYYY-MM-DD'))
  }
  return dateList
}
const DatePop = ({ date, setDate, disabled }) => {
  const [visible, setVisible] = useState(false)
  function onSelect(opt) {
    setVisible(false)
    setDate(opt.props.value)
  }
  return disabled ? <Tag>{date}</Tag> : <Popover mask
    overlayClassName="fortest"
    overlayStyle={{ color: 'currentColor' }}
    visible={visible}
    overlay={<>
      <div style={{ textAlign: 'center', color: '#888', padding: '5px 0' }}>请选择体验时间</div>
      {getFutureDate().map((date, index) => <Item key={index} value={date}>{date}</Item>)}
    </>}
    align={{
      overflow: { adjustY: 0, adjustX: 0 },
      offset: [-10, 0],
    }}
    onVisibleChange={(visible => { if (!disabled) setVisible(visible) })}
    onSelect={onSelect}
  >
    <Tag color="geekblue">{date || '体验时间'}</Tag>
  </Popover>
}
const InstructorPop = ({ setInstructNum, instructorNumber, disabled }) => {
  const [visible, setVisible] = useState(false)
  const [name, setName] = useState('')
  const { insturctors } = useSelector((state) => ({
    insturctors: state.dsd.insturctors,
  }))
  function onSelect(opt) {
    setVisible(false)
    setName(opt.props.value.name)
    setInstructNum(opt.props.value.number)
  }
  useEffect(() => {
    if (instructorNumber) {
      const initInstruct = insturctors.find(item => item.instructorNumber === instructorNumber)
      setName(initInstruct.instructorName)
    }
  }, [instructorNumber])
  if (!insturctors) return null
  return disabled ? <Tag>{name}</Tag> : <Popover mask
    overlayClassName="fortest"
    overlayStyle={{ color: 'currentColor' }}
    visible={visible}
    overlay={<>
      <div style={{ textAlign: 'center', color: '#888', padding: '5px 0' }}>请选择教练</div>
      {insturctors.map((item, index) => <Item key={index} value={{ number: item.instructorNumber, name: item.instructorName }}>{item.instructorName}</Item>)}
    </>}
    align={{
      overflow: { adjustY: 0, adjustX: 0 },
      offset: [-10, 0],
    }}
    onVisibleChange={(visible => { if (!disabled) setVisible(visible) })}
    onSelect={onSelect}
  >
    <Tag color="volcano">{name || '教练'}</Tag>
  </Popover>
}
const SubmitBtn = ({ date, instructorNum, id, email, status, setStatus }) => {
  const [loading, setLoading] = useState(false)
  const { formatMessage } = useIntl()

  const submit = async () => {
    setLoading(true)
    const submitDate = {
      id,
      email,
      dsddate: date,
      instructorNumber: instructorNum
    }
    try {
      const res = await dsdIssueCerts(submitDate);
      if (res.error) {
        showAlert(
          res.error.clientMsg,
          formatMessage({ id: 'common-close' })
        );
        return
      } else {
        setStatus('WAIT_PADI')
      }
    } catch (e) {
      //TODO: handle error
      console.log(e);
    } finally {
      setLoading(false)
    }
  }
  if (status === 'WAIT_PADI') {
    return <Button
      type="primary"
      size="small"
      style={{ fontSize: '12px' }}
      disabled={true}
    >
      等待 PADI 处理
    </Button>
  }
  return <Button
    type="primary"
    loading={loading}
    size="small"
    style={{ fontSize: '12px' }}
    onClick={submit}
    disabled={!instructorNum || !date}
  >
    发证
  </Button>
}
const TraineeItem = ({ item }) => {
  const [date, setDate] = useState(item.dsddate || formatDate(new Date(), 'YYYY-MM-DD'))
  const [instructNum, setInstructNum] = useState('')
  const [status, setStatus] = useState(item.status)
  return <List.Item>
    <Flex justify="between">
      <Flex.Item>{item.firstName} {item.lastName}</Flex.Item>
      <Flex.Item>
        <DatePop
          date={date}
          setDate={setDate}
          disabled={status !== 'NEW'} />
        <InstructorPop
          setInstructNum={setInstructNum}
          instructorNumber={item.instructorNumber}
          disabled={status !== 'NEW'} />
        <WhiteSpace />
        <SubmitBtn
          date={date}
          instructorNum={instructNum}
          email={item.email}
          id={item.id}
          status={status}
          setStatus={setStatus} />
      </Flex.Item>
    </Flex>
  </List.Item>
}

const TraineeList = () => {
  const { dsdList } = useSelector((state) => ({
    dsdList: state.dsd.dsdList,
  }));
  if (!dsdList) return null
  return dsdList.content.length > 0 ? <>
    <List renderHeader={() => '学员列表'}>
      {dsdList.content.map(item => <TraineeItem item={item} />)}
    </List>
  </> : <Empty description="暂无数据" />
}
export default TraineeList