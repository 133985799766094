import { Image } from 'antd-mobile-v5';
import { Link, useLocation } from 'react-router-dom';
import React from 'react';

import styles from './Torcher.module.css';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Torcher() {
  const cert = useQuery();
  return (
    <>
      <div className={styles.imgBg}>
        <Image src={require('../../assets/images/pw_cert/bg_top.jpg')} />
        <div className={styles.nickName}>{cert.get('nickName')}</div>
      </div>
      <div className={styles.imgBg}>
        <Image src={require('../../assets/images/pw_cert/bg_btm.jpg')} />
        <div className={styles.no}>{cert.get('no')}</div>
        <Link to={cert.get('from') ? '/' + cert.get('from'): '/certs'} className={styles.back}>
          返回
        </Link>
      </div>
    </>
  );
}
