import React from 'react'
import { useDispatch } from 'react-redux'
import { useIntl, FormattedMessage } from 'react-intl';
import { List, WingBlank, Button } from 'antd-mobile';
import PaymentBtn from '../../components/FormTools/PaymentBtn'
import { closeFdPic } from '../../services/api'
import { pageLoading, pageLoaded } from '../../actions';
import { usePayFdpic } from '../../services/hooks'

import styles from '../SpecialtyInstructor/SpecialtyInstructor.module.css';

const Item = List.Item;

const FDPICItems = ({ data, setList, list }) => {

  const intl = useIntl();
  const dispatch = useDispatch()
  const { runAsync: toPayPic } = usePayFdpic();

  const certStatus = (status, paymentMethod) => {
    switch (status) {
      case 'SUBMIT':
        if (paymentMethod) return intl.formatMessage({ id: 'dm-form-wait-cert' })
        return intl.formatMessage({ id: 'app-status-to-pay' })
      case 'SUCCESS':
        return intl.formatMessage({ id: 'fd-pic-finish' })
      case 'CLOSED':
        return intl.formatMessage({ id: 'app-close' })
      case 'PAY_ERROR':
        return intl.formatMessage({ id: 'app-pay-error' })
    }
  }

  const close = async (code, email) => {
    try {
      dispatch(pageLoading());
      const result = await closeFdPic({ code, email })
      if (result.data) {
        const { fdApplicatonDetailDTOS, ...details } = list
        const newFdAppDTOS = fdApplicatonDetailDTOS.map(detail => {
          if (detail.code === code) {
            const { emails, ...itemDetail } = detail
            const newEmails = emails.filter(e => e.email !== email)
            return { emails: newEmails, ...itemDetail }
          }
          return detail
        })
        const filterEmptyDTOS = newFdAppDTOS.filter(item => item.emails.length !== 0)
        setList({ fdApplicatonDetailDTOS: filterEmptyDTOS, ...details })
      }
    } finally {
      dispatch(pageLoaded());
    }
  }

  const readyForPay = (apps) =>
    apps && apps.filter((app) => app.status !== 'SUBMIT').length === 0;

  return <>
    <List
      renderHeader={() => `${intl.formatMessage({ id: 'si-app-number' })}${data.code}`}
      className={styles.siList}
    >
      {data.emails.map(item => <Item
        wrap
        extra={item.status === 'SUBMIT' && !data.paymentMethod && <Button type="ghost" inline size="small" onClick={() => close(data.code, item.email)}><FormattedMessage id="common-cancel" /></Button>}
      >
        <div className={styles.siBox}>
          <span><FormattedMessage id="profile-name" /></span>{item.userName}
        </div>
        <div className={styles.siBox}>
          <span><FormattedMessage id="fd-pic-email" /></span>{item.email}
        </div>
        <div className={styles.siBox}>
          <span><FormattedMessage id="fd-pic-status" /></span>{certStatus(item.status, data.paymentMethod)}
        </div>
        <div className={styles.siBox}>
          <span><FormattedMessage id="fd-pic-date" /></span>{data.createDate}
        </div>
        <div className={styles.siBox}>
          <span><FormattedMessage id="fd-pic-level" /></span>{data.memberApplicationType}
        </div>
      </Item>)}
    </List>
    {readyForPay(data.emails) && !data.paymentMethod && <WingBlank>
      <PaymentBtn code={data.code} paymentFn={toPayPic} payApplicationType={'FD'} />
    </WingBlank>}
  </>
}

export default FDPICItems