import React from 'react'
import QRCode from 'qrcode.react'
import Loading from '../../components/Loading';
import { Descriptions } from 'antd'
import { useGetRegulatorCoupon } from '../../services/hooks';
import { useIntl } from 'react-intl';

export default function RegulatorCouponPage () {

  const { formatMessage } = useIntl();
  const { loading, data } = useGetRegulatorCoupon();

  if (loading) return <Loading />;

  return <>
    <div style={{ textAlign: 'center' }}>
      <QRCode
        value={data.code}
        size={320}
        includeMargin={true} />
    </div>
    <Descriptions title={formatMessage({ id: 'form-instructor-info' })} style={{margin: '0 30px'}}>
      <Descriptions.Item label={formatMessage({ id: 'certdetail-name' })}>{data.instructorName}</Descriptions.Item>
      <Descriptions.Item label={formatMessage({ id: 'dm-form-instructor-number' })}>{data.instructorNumber}</Descriptions.Item>
    </Descriptions>
  </>
}
