import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import React from 'react';

import {
  getCoverImagePath,
  getPlasticCardImagePath,
  getEcardImagePathArr,
} from '../../services/utilities';
import { WingBlank } from 'antd-mobile';
import {
  submitCancelEcardImageBinding,
  submitEcardImageReBinding,
} from '../../actions';
import CardImageShow from '../CertShow/CertShow';
import { Swiper } from 'antd-mobile-v5';

const CertShowWrapped = ({
  cert,
  submitCancelEcardImageBinding,
  submitEcardImageReBinding,
}) => {
  const currentCoverImg = getCoverImagePath(cert);
  const plasticImgPath = getPlasticCardImagePath(cert);
  const ecardImgPath = getEcardImagePathArr(cert);
  const allImgs = [...ecardImgPath, plasticImgPath];
  const onIndexChange = (index) => {
    const ecardId = cert.affiliateCertificationId || cert.affiliateCredentialId;
    const newPath = allImgs[index];
    if (newPath === plasticImgPath) {
      //切换为标准卡
      submitCancelEcardImageBinding({ ecardId });
    } else if (ecardImgPath.indexOf(newPath) >= 0) {
      // 切换为电子卡
      if (newPath.indexOf('ECARD_CUSTOMIZATION') > 0) {
        const bindingId = cert.customizedImages.find(
          (item) => ecardImgPath[index] === item.imgUrl
        )['id'];
        submitEcardImageReBinding({ ecardId, bindingId });
      }else {
        const ecardImageBindingId = cert.ecardImages.find(
          (e) =>
            newPath === e.imgFile || newPath.indexOf(e.imgFile.split('.')[0]) > 0
        )['ecardImageBindingId'];
        submitEcardImageReBinding({ ecardId, ecardImageBindingId });
      }
    } else {
      // 切换为自定义卡
      const bindingId = cert.customizedImages.find(
        (item) => ecardImgPath[index] === item.imgUrl
      )['id'];
      submitEcardImageReBinding({ ecardId, bindingId });
    }
  };

  if (cert.customizedImages || cert.ecardImages) {
    const activeImageIndex = allImgs.findIndex(
      (path) => path === currentCoverImg
    );
    return (
      <>
        <div
          style={{
            paddingLeft: '0',
            margin: '5px 0 0 16px',
            fontSize: '.8rem',
          }}
        >
          <FormattedMessage id="certdetail-switchCoverImage" />
        </div>
        <Swiper
          style={{ '--track-padding': '8px 0 16px' }}
          defaultIndex={activeImageIndex}
          onIndexChange={onIndexChange}
        >
          {allImgs.map((imgPath, index) => (
            <Swiper.Item key={imgPath}>
              <div style={{ padding: '0 16px' }}>
                <CardImageShow path={imgPath} cert={cert} key={index} />
              </div>
            </Swiper.Item>
          ))}
        </Swiper>
      </>
    );
  } else {
    return (
      <WingBlank>
        <CardImageShow path={currentCoverImg} cert={cert} />
      </WingBlank>
    );
  }
};

export default connect(null, {
  submitCancelEcardImageBinding,
  submitEcardImageReBinding,
})(CertShowWrapped);
