import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, WhiteSpace, WingBlank } from 'antd-mobile';
import { Col, Input, Row } from 'antd';
import { postTextBookCheckout } from '../../services/api';
import { FormattedMessage, useIntl } from 'react-intl';
import { errorDialog, pageLoaded, pageLoading } from '../../actions/index';
import { getParameterByName } from '../../components/common/utils';
import styles from './CreditCard.module.css'

const CreditCard = () => {

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const history = useHistory()

  const [acct, setAcct] = useState('')
  const [expYear, setExpYear] = useState('')
  const [expMonth, setExpMonth] = useState('')
  const [ccv2, setCcv] = useState('')
  const id = getParameterByName('id')
  const type = getParameterByName('type')

  const toPay = async () => {
    if (!acct || !expYear || !expMonth || !ccv2) {
      dispatch(errorDialog(formatMessage({ id: 'credit-card-saveHint' })));
      return;
    }
    try {
      dispatch(pageLoading());
      const { data, error } = await postTextBookCheckout({
        stripePaymentMethodCardDTO: {
          acct,
          expMonth,
          expYear,
          cvc: ccv2
        },
        type,
        id
      });
      if (data && data.id) {
        history.push('/pay_success', { payResult: data })
      }
      if (error) {
        dispatch(errorDialog(error.clientMsg))
      }
    } catch (e) {
      //TODO: handle error
      console.log(e);
    } finally {
      dispatch(pageLoaded());
    }
  }

  return (
    <div className={styles.cardBox}>
      <WhiteSpace size="lg" />
      <h3 className={styles.title}>
        <FormattedMessage id="credit-card-title" />
      </h3>
      <WhiteSpace size="lg" />
      <WingBlank>
        <Input
          size="large"
          placeholder={formatMessage({ id: 'credit-card-number' })}
          type="number"
          onChange={e => setAcct(e.target.value)} />
        <Input.Group size="large">
          <WhiteSpace />
          <Row gutter={8}>
            <Col span={8}>
              <Input
                placeholder={formatMessage({ id: 'credit-card-date-year' })}
                type="number"
                onChange={e => setExpYear(e.target.value)} />
            </Col>
            <Col span={8}>
              <Input
                placeholder={formatMessage({ id: 'credit-card-date-month' })}
                type="number"
                onChange={e => setExpMonth(e.target.value)} />
            </Col>
            <Col span={8}>
              <Input
                placeholder={formatMessage({ id: 'credit-card-ccv' })}
                type="number"
                onChange={e => setCcv(e.target.value)} />
            </Col>
          </Row>
        </Input.Group>
        <WhiteSpace size="lg" />
        <p className="small-font gray">
          <FormattedMessage id="credit-card-accepted-types" />
        </p>
        <p className="small-font gray">Powered by Stripe</p>
        <WhiteSpace size="lg" />
        <Button
          type="primary"
          onClick={() => toPay()}>
          <FormattedMessage id="common-confirmRequest" />
        </Button>
      </WingBlank>
    </div>
  )
}

export default CreditCard