import { combineReducers } from 'redux'
import { createSlice } from "@reduxjs/toolkit";

export const applicantDetailSlice = createSlice({
  name: "applicantDetail",
  initialState: null,
  reducers: {
    applicantDetailRequest: {
      reducer() {
        return { loading: true }
      }
    },
    applicantDetailSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    applicantDetailFailed: {
      reducer() {
        return {}
      }
    },
    uploadOwsiFile: {
      reducer(state) {
        return state
      }
    },
    uploadOwsiFileRequest: {
      reducer(state) {
        return state
      }
    },
    uploadOwsiFileSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return state
      }
    },
    uploadOwsiFileFailed: {
      reducer(state) {
        return state
      }
    },
    getOwsiOssFileRequest: {
      reducer(state) {
        return state
      }
    },
    getOwsiOssFileSuccess: {
      reducer(state, action) {
        const data = action.payload;
        const url = data.replace('https://files.padi.com.cn/', '')
        const certType = url.split('/')[2]
        if (certType === 'OW') {
          const { owfiles, ...detail } = state
          return { owfiles: owfiles ? [data, ...owfiles] : [data], ...detail }
        } else if (certType === 'AOW') {
          const { aowfiles, ...detail } = state
          return { aowfiles: aowfiles ? [data, ...aowfiles] : [data], ...detail }
        } else if (certType === 'RESCUE') {
          const { rescuefiles, ...detail } = state
          return { rescuefiles: rescuefiles ? [data, ...rescuefiles] : [data], ...detail }
        } else if (certType === 'EFR') {
          const { efrfiles, ...detail } = state
          return { efrfiles: efrfiles ? [data, ...efrfiles] : [data], ...detail }
        } else if (certType === 'AI') {
          const { aidmfiles, ...detail } = state
          return { aidmfiles: aidmfiles ? [data, ...aidmfiles] : [data], ...detail }
        } else if (certType === 'other') {
          const { otherFiles, ...detail } = state
          return { otherFiles: otherFiles ? [data, ...otherFiles] : [data], ...detail }
        }
      }
    },
    getOwsiOssFileFailed: {
      reducer(state) {
        return state
      }
    },
    removeOwsiFile: {
      reducer(state, action) {
        const data = action.payload;
        const url = data.replace('https://files.padi.com.cn/', '')
        const certType = url.split('/')[2]
        if (certType === 'OW') {
          const { owfiles, ...detail } = state
          const newOwfiles = owfiles.filter(item => item !== data)
          return { owfiles: newOwfiles, ...detail }
        } else if (certType === 'AOW') {
          const { aowfiles, ...detail } = state
          const newAowfiles = aowfiles.filter(item => item !== data)
          return { aowfiles: newAowfiles, ...detail }
        } else if (certType === 'RESCUE') {
          const { rescuefiles, ...detail } = state
          const newRescuefiles = rescuefiles.filter(item => item !== data)
          return { rescuefiles: newRescuefiles, ...detail }
        } else if (certType === 'EFR') {
          const { efrfiles, ...detail } = state
          const newEfrfiles = efrfiles.filter(item => item !== data)
          return { efrfiles: newEfrfiles, ...detail }
        } else if (certType === 'AI') {
          const { aidmfiles, ...detail } = state
          const newAidmfiles = aidmfiles.filter(item => item !== data)
          return { aidmfiles: newAidmfiles, ...detail }
        } else if (certType === 'other') {
          const { otherFiles, ...detail } = state
          const newOtherFiles = otherFiles.filter(item => item !== data)
          return { otherFiles: newOtherFiles, ...detail }
        }
      }
    },
  }
})

export const {
  applicantDetailRequest,
  applicantDetailSuccess,
  applicantDetailFailed,
  uploadOwsiFile,
  uploadOwsiFileRequest,
  uploadOwsiFileSuccess,
  uploadOwsiFileFailed,
  getOwsiOssFileRequest,
  getOwsiOssFileSuccess,
  getOwsiOssFileFailed,
  removeOwsiFile } = applicantDetailSlice.actions

export const applicantDetail = {
  request: (data) => applicantDetailRequest(data),
  success: (data) => applicantDetailSuccess(data),
  failure: (error) => applicantDetailFailed(error),
}

export const uploadOwsiAttachFile = {
  request: () => uploadOwsiFileRequest(),
  success: (data) => uploadOwsiFileSuccess(data),
  failure: (error) => uploadOwsiFileFailed(error),
}

export const getOwsiOssFile = {
  request: () => getOwsiOssFileRequest(),
  success: (data) => getOwsiOssFileSuccess(data),
  failure: (error) => getOwsiOssFileFailed(error),
}


export const cdListSlice = createSlice({
  name: "cdList",
  initialState: {},
  reducers: {
    skipCdApplicationsPage: {
      reducer(state) {
        return state
      }
    },
    cdListRequest: {
      reducer(state) {
        return state
      }
    },
    cdListSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    cdListFailed: {
      reducer(state) {
        return state
      }
    }
  }
});

export const { cdListRequest, cdListSuccess, cdListFailed, skipCdApplicationsPage } = cdListSlice.actions

export const getCdListRequest = {
  request: () => cdListRequest(),
  success: (data) => cdListSuccess(data),
  failure: (error) => cdListFailed(error),
}

export const owsiCdDetailSlice = createSlice({
  name: "owsiCdDetail",
  initialState: {},
  reducers: {
    requestOwsiCdDetail: {
      reducer() {
        return {}
      }
    },
    owsiCdDetailRequest: {
      reducer() {
        return {}
      }
    },
    owsiCdDetailSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    owsiCdDetailFailed: {
      reducer() {
        return {}
      }
    },
    uploadTrainingRecords: {
      reducer(state) {
        return state
      }
    },
    uploadTrainingRecordsRequest: {
      reducer(state) {
        return state
      }
    },
    uploadTrainingRecordsSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return state
      }
    },
    uploadTrainingRecordsFailed: {
      reducer(state) {
        return state
      }
    },
    getTrainingRecordsRequest: {
      reducer(state) {
        return state
      }
    },
    getTrainingRecordsSuccess: {
      reducer(state, action) {
        const data = action.payload;
        const { trainingRecord, ...detail } = state
        return { trainingRecord: [...trainingRecord, data], ...detail }
      }
    },
    getTrainingRecordsFailed: {
      reducer(state) {
        return state
      }
    },
    uploadTrainingRecordsToServerRequest: {
      reducer(state) {
        return state
      }
    },
    uploadTrainingRecordsToServerSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return state
      }
    },
    uploadTrainingRecordsToServerFailed: {
      reducer(state) {
        return state
      }
    },
    removeOwsiTraniningRecords: {
      reducer(state, action) {
        const data = action.payload;
        const { trainingRecord, ...detail } = state
        const newTrainingRecord = trainingRecord.filter(item => item !== data)
        return { trainingRecord: newTrainingRecord, ...detail }
      }
    }
  }
})

export const {
  requestOwsiCdDetail,
  owsiCdDetailRequest,
  owsiCdDetailSuccess,
  owsiCdDetailFailed,
  uploadTrainingRecords,
  uploadTrainingRecordsRequest,
  uploadTrainingRecordsSuccess,
  uploadTrainingRecordsFailed,
  getTrainingRecordsRequest,
  getTrainingRecordsSuccess,
  getTrainingRecordsFailed,
  uploadTrainingRecordsToServerRequest,
  uploadTrainingRecordsToServerSuccess,
  uploadTrainingRecordsToServerFailed,
  removeOwsiTraniningRecords,
} = owsiCdDetailSlice.actions

export const owsiCdDetail = {
  request: () => owsiCdDetailRequest(),
  success: (data) => owsiCdDetailSuccess(data),
  failure: (error) => owsiCdDetailFailed(error),
}

export const uploadOwsiTraining = {
  request: () => uploadTrainingRecordsRequest(),
  success: (data) => uploadTrainingRecordsSuccess(data),
  failure: (error) => uploadTrainingRecordsFailed(error),
}

export const getOwsiTraining = {
  request: () => getTrainingRecordsRequest(),
  success: (data) => getTrainingRecordsSuccess(data),
  failure: (error) => getTrainingRecordsFailed(error),
}

export const uploadCdTrainingToServer = {
  request: () => uploadTrainingRecordsToServerRequest(),
  success: (data) => uploadTrainingRecordsToServerSuccess(data),
  failure: (error) => uploadTrainingRecordsToServerFailed(error),
}

export const ieListSlice = createSlice({
  name: "ieList",
  initialState: [],
  reducers: {
    ieListRequest: {
      reducer() {
        return []
      }
    },
    ieListSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    ieListFailed: {
      reducer() {
        return []
      }
    }
  }
})

export const { ieListRequest, ieListSuccess, ieListFailed } = ieListSlice.actions

export const ieList = {
  request: () => ieListRequest(),
  success: (data) => ieListSuccess(data),
  failure: (error) => ieListFailed(error),
}

export const ieListForExaminerSlice = createSlice({
  name: "ieListForExaminer",
  initialState: [],
  reducers: {
    ieListForExaminerRequest: {
      reducer() {
        return []
      }
    },
    ieListForExaminerSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    ieListForExaminerFailed: {
      reducer() {
        return []
      }
    }
  }
})

export const { ieListForExaminerRequest, ieListForExaminerSuccess, ieListForExaminerFailed } = ieListForExaminerSlice.actions

export const ieListForExaminer = {
  request: () => ieListForExaminerRequest(),
  success: (data) => ieListForExaminerSuccess(data),
  failure: (error) => ieListForExaminerFailed(error),
}

export const ieCandidateListSlice = createSlice({
  name: "ieCandidate",
  initialState: {},
  reducers: {
    ieCandidateRequest: {
      reducer() {
        return {}
      }
    },
    ieCandidateSuccess: {
      reducer(state, action) {
        const data = action.payload;
        const { owsis, ...detail } = data
        const newOwsis = owsis.map((item) => {
          return { paymentType: 'CANDIDATE', ...item }
        })
        return { owsis: newOwsis, ...detail }
      }
    },
    ieCandidateFailed: {
      reducer() {
        return {}
      }
    },
    checkedCandidate: {
      reducer(state, action) {
        const { checked, ieIndex } = action.payload
        const { owsis, ...detail } = state
        const newOwsis = owsis.map((item, index) => {
          if (index === ieIndex) {
            const { radio, ...owsiDetail } = item
            return { radio: checked, ...owsiDetail }
          }
          return item
        })
        return { owsis: newOwsis, ...detail }
      }
    },
    setPaymentType: {
      reducer(state, action) {
        const { type, ieIndex } = action.payload
        const { owsis, ...detail } = state
        const newOwsis = owsis.map((item, index) => {
          if (index === ieIndex) {
            const { paymentType, ...payment } = item
            return { paymentType: type, ...payment }
          }
          return item
        })
        return { owsis: newOwsis, ...detail }
      }
    },
    submitIeList: {
      reducer(state) {
        return state
      }
    },
    submitIeListRequest: {
      reducer(state) {
        return state
      }
    },
    submitIeListSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return state
      }
    },
    submitIeListFailed: {
      reducer(state) {
        return state
      }
    },
  }
})

export const {
  ieCandidateRequest,
  ieCandidateSuccess,
  ieCandidateFailed,
  checkedCandidate,
  setPaymentType,
  submitIeList,
  submitIeListRequest,
  submitIeListSuccess,
  submitIeListFailed } = ieCandidateListSlice.actions

export const ieCandidate = {
  request: () => ieCandidateRequest(),
  success: (data) => ieCandidateSuccess(data),
  failure: (error) => ieCandidateFailed(error),
}

export const submitIeRequest = {
  request: () => submitIeListRequest(),
  success: (data) => submitIeListSuccess(data),
  failure: (error) => submitIeListFailed(error),
}

const owsiReducer = combineReducers({
  cdList: cdListSlice.reducer,
  cdDetail: owsiCdDetailSlice.reducer,
  ieList: ieListSlice.reducer,
  ieCandidateList: ieCandidateListSlice.reducer,
  applicantDetail: applicantDetailSlice.reducer,
  ieListForExaminer: ieListForExaminerSlice.reducer
})

export default owsiReducer