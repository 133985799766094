import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getDivingHistory } from '../../components/common/utils'

import './MyProfile.css'

const ProfileHeader = ({ wechat, ecards }) => (
    <div className="profile-header">
        <img
            className={'profile-header-bg'}
            src={require('../../assets/images/profile-header.jpg')}
            alt="bg"
        />
        <div className="profile-info">
            <div className={'profile-header-nickname'}>
                <img src={wechat ? wechat.headimgurl : require('../../assets/images/avatar.jpg')} alt="avatar" />
                <h6>{wechat && wechat.nickname}</h6>
            </div>
            { ecards.length > 0 && <div className="profile-header-info">
                <p><FormattedMessage id="userHeader-userHeaderMsg" /></p>
                <p><FormattedMessage 
                        id="userHeader-userheaderamount" 
                        values={{ 
                            year: <span className="profile-header-count">{getDivingHistory(ecards) || ''}</span>, 
                            certAmount: <span className="profile-header-count">{ecards.length}</span>,
                            count: ecards.length > 1 ? 's' : ''
                        }} /></p>
            </div> }
            
        </div>
    </div>
)

export default injectIntl(ProfileHeader);