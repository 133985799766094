import React, { useState } from 'react'
import CardLayout from './CardLayout'
import { FormattedMessage, useIntl } from 'react-intl';
import { languagesMapping } from '../../components/common/utils'
import PickerComponent from '../../components/common/Picker'
import { isMember } from '../../reducers/selectors'
import { useSelector } from 'react-redux';
import { List } from 'antd-mobile'
import { useGetStandardRegions } from '../../services/hooks'


let AddressAndLangOption = ({ onValueChange, languageId, regionId, editable }) => {

  const intl = useIntl()
  const { ismember } = useSelector((state) => ({
    ismember: isMember(state)
  }));

  const [regionData, setRegionData] = useState([])
  const { loading } = useGetStandardRegions({
    onSuccess: (data) => {
      const formatData = data.map(item => {
        return {
          label: item.region,
          value: item.id
        }
      })
      setRegionData(formatData)
    },
  });

  const status = ismember ? languageId : languageId && regionId

  return <CardLayout title={intl.formatMessage({ id: "dm-region-and-lang-title" })} status={status}>
    {!ismember ? <PickerComponent
      title={intl.formatMessage({ id: "setMobile-chooseRegion" })}
      extra={intl.formatMessage({ id: "dm-select-region-error" })}
      data={regionData}
      disabled={!editable}
      onOk={v => onValueChange({ regionId: parseInt(v) })}
      value={regionId || ''}>
      <FormattedMessage id="dm-region" />
    </PickerComponent> : <List>
        <List.Item>
          <FormattedMessage id="dm-region" />
          <List.Item.Brief>
            <FormattedMessage id="app-prosite" />
          </List.Item.Brief>
        </List.Item>
      </List>}
    <PickerComponent
      title={intl.formatMessage({ id: "dm-lang" })}
      extra={intl.formatMessage({ id: "dm-lang-error" })}
      data={languagesMapping}
      disabled={!editable}
      onOk={v => onValueChange({ languageId: parseInt(v) })}
      value={languageId || ''}>
      <FormattedMessage id="dm-lang-title" />
    </PickerComponent>
  </CardLayout>
}

export default AddressAndLangOption