import { List, Input, Space } from 'antd-mobile-v5';
import { Link, useHistory } from 'react-router-dom'
import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { getParameterByName } from '../../components/common/utils';
import { issueCertsOptions, useErrorModal, usePicOrder, usePicOrderCreate } from '../../services/hooks';
import PriceSummary from '../PriceList/PriceSummary';
import ShippingAddress from '../PriceList/ShippingAddress';
import {WingBlank, WhiteSpace, Button, Flex, Modal} from 'antd-mobile';

export default function BuyPicPage() {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const type = getParameterByName('type');
  const [addressType, setAddressType] = useState();
  const [address, setAddress] = useState('');
  const [userName, setUserName] = useState('');
  const [userMobile, setUserMobile] = useState('');
  const [order, setOrder] = useState();

  const [amount, setAmount] = useState(1);
  const showErrDialog = useErrorModal();

  const { run } = usePicOrder({
    onSuccess: data => setOrder(data),
  });

  const {loading, run: submit} = usePicOrderCreate({
    onSuccess: (data, [{paymentMethodType}]) => {
      if (paymentMethodType === 'ONLINE') window.location.href = data
      else if (paymentMethodType === 'OFFLINE') history.replace('/books_submit_success', { price: order.total });
    }
  });
  const onRegionChange = (region) => {
    setAddressType(region);
    run({
      addressType: region,
      address,
      userMobile,
      userName,
      number: amount,
      type: type==='CONFINED_WATER_DIVER_PIC' ? 'CONFINED_WATER_DIVER': 'SCUBA_DIVER',
    });
  }
  const checkout = () => {
    if (!addressType || !address || !userName || !userMobile) {
      showErrDialog(formatMessage({ id: 'addressForm-saveHint' }));
      return;
    }
    Modal.alert('', formatMessage({ id: 'payment-method' }), [
      { text: formatMessage({ id: 'online-pay' }), onPress: () => submitCheckoutData('ONLINE') },
      { text: formatMessage({ id: 'offline-pay' }), onPress: () => submitCheckoutData('OFFLINE') },
      { text: formatMessage({ id: 'common-cancel' }), onPress: () => { } },
    ])
  };

  const submitCheckoutData = async (paymentMethodType) => {
    submit({
      addressType,
      address,
      userMobile,
      userName,
      number: amount,
      paymentMethodType,
      type: type==='CONFINED_WATER_DIVER_PIC' ? 'CONFINED_WATER_DIVER': 'SCUBA_DIVER',
    });
  };
  return (
    <>
      <List header={formatMessage({ id: 'issue-certs-pic-buy' })}>
        <List.Item
          extra={
            <Input
              id="amount"
              style={{
                '--text-align': 'right',
              }}
              type="number"
              placeholder={formatMessage({
                id: 'issue-certs-pic-amount-placeholder',
              })}
              value={amount}
              onChange={(value) => setAmount(value)}
              onBlur={() => addressType && amount && run({
                addressType,
                address,
                userMobile,
                userName,
                number: amount,
                type: type==='CONFINED_WATER_DIVER_PIC' ? 'CONFINED_WATER_DIVER': 'SCUBA_DIVER',
              })}
            />
          }
        >
          <FormattedMessage id="issue-certs-pic-amount" />
        </List.Item>
      </List>
      <ShippingAddress
        address={address}
        setAddress={setAddress}
        addressType={addressType}
        onRegionChange={onRegionChange}
        userName={userName}
        userMobile={userMobile}
        setUserName={setUserName}
        setUserMobile={setUserMobile}
      />
      <PriceSummary order={order} currencyType={order.currencyType} />
      <WingBlank>
          <Button type="primary" onClick={checkout} loading={loading}>
            <FormattedMessage id="checkout" />
          </Button>
          <WhiteSpace />
          <Flex justify="center">
            <Link to={`/issue_certs/home?type=${type}`} >
              <FormattedMessage id="common-back" />
            </Link>
          </Flex>
        </WingBlank>
    </>
  );
}
