import React from 'react'
import { useSelector } from 'react-redux';
import AppList from '../../components/common/AppList'
import { skipAFDITListPage } from '../../reducers/afdi'

const AFDITList = () => {

  const { confirmRes } = useSelector((state) => ({
    confirmRes: state.AFDI.AFDITList,
  }));

  return <AppList confirmRes={confirmRes} detailUrl="/afdi_app_detail" paging={skipAFDITListPage} listHeader="afdi-t-title"/>
}

export default AFDITList