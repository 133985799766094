import React, { useRef } from 'react'
import { formatDate, IsNotPc } from './utils'
import { DatePicker, List } from 'antd-mobile'

import styles from './Picker.module.css'

const DatePickerComponent = ({ 
  onChange=() => {}, 
  value, 
  children, 
  maxDate, 
  minDate,
  disabled,
  extra,
  title
}) => {

  const dateRef = useRef()

  function change(val) {
    onChange(val.target.value)
  }

  return IsNotPc() ? (
    <DatePicker
      mode="date"
      extra={extra}
      title={title}
      maxDate={maxDate}
      minDate={minDate}
      value={value ? new Date(value) : ''}
      disabled={disabled}
      onChange={date => onChange(formatDate(date, 'YYYY-MM-DD'))}
    >
      <List.Item arrow="horizontal">
        {children}
      </List.Item>
    </DatePicker>
  ) : (
    <List.Item arrow="horizontal">
      <div className={styles.wrapper}>
        <span className={styles.label}>{children}</span>
        <input 
          type="date" 
          max={maxDate ? formatDate(maxDate, 'YYYY-MM-DD') : ''}
          min={minDate ? formatDate(minDate, 'YYYY-MM-DD') : ''}
          disabled={disabled}
          className={styles.datePicker}
          ref={dateRef} 
          value={value} 
          onChange={change} />
      </div>
    </List.Item>
  )
}

export default DatePickerComponent