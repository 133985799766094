import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { List, WingBlank } from 'antd-mobile';
import CardLayout from '../DiveMasterForm/CardLayout'
import { FormattedMessage, useIntl } from 'react-intl';
import UploadOssFile from '../../components/common/UploadOssFile'
import UploadFileWrapper from '../../components/common/UploadFileWrapper'
import { findMermaidCert } from '../../components/common/findCerts'
import { uploadBasicMermaidOssFile, removeBasicMermaidFile } from '../../reducers/mermaid'
import UploadEfrOrEfri from '../../components/common/UploadEfrOrEfri'

const Item = List.Item

const CheckCerts = ({ 
  efrfiles, 
  mermaidFiles, 
  editable,
  certComplete, 
  otherFiles 
}) => {

  const { certifications, ossSignature } = useSelector((state) => ({
    certifications: state.data.certifications,
    ossSignature: state.oss.ossSignature,
  }));
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const filename = (type) => `${ossSignature.dir}${type}/${new Date().getTime()}`

  return <CardLayout title={<FormattedMessage id="dm-form-required-certs" />} status={certComplete}>
    {findMermaidCert(certifications) ? <Item>
      <FormattedMessage id="BMI-advanced-mermaid" /> {`（${findMermaidCert(certifications).studentNumber}）`}
    </Item> : <UploadFileWrapper 
      labelName={formatMessage({ id: 'BMI-advanced-mermaid' })} 
      editable={editable}>
      <UploadOssFile
        fileList={mermaidFiles || []}
        fileName={filename('mermaid')}
        onChange={(formData) => {
          dispatch(uploadBasicMermaidOssFile({ formData }))
        }}
        onDelete={(index) => dispatch(removeBasicMermaidFile(mermaidFiles[index]))}
        editable={editable} />
      {editable && <WingBlank>
        <div className="small-font gray">
          <FormattedMessage id="common-upload-file-reminder" />
        </div>
      </WingBlank>}
    </UploadFileWrapper>}
    <UploadEfrOrEfri
      uploadFn={(formData) => {
        dispatch(uploadBasicMermaidOssFile({ formData }))
      }}
      removeFn={(index) => dispatch(removeBasicMermaidFile(efrfiles[index]))}
      filename={filename('efr')}
      editable={editable}
      efrfiles={efrfiles} />
    <UploadFileWrapper 
      labelName={formatMessage({ id: 'mermaid-other-files' })} 
      editable={editable}>
      <UploadOssFile
        fileList={otherFiles || []}
        fileName={filename('other')}
        onChange={(formData) => {
          dispatch(uploadBasicMermaidOssFile({ formData }))
        }}
        onDelete={(index) => dispatch(removeBasicMermaidFile(otherFiles[index]))}
        editable={editable} />
      {editable && <WingBlank>
        <div className="small-font gray">
          <FormattedMessage id="app-health-and-others" />
        </div>
      </WingBlank>}
    </UploadFileWrapper>
  </CardLayout>
}

export default CheckCerts