import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import { List, InputItem } from 'antd-mobile';
import GetCode from '../../components/common/getCode';
import { requestMobileCode } from '../../actions';
import PickerComponent from '../../components/common/Picker'
import { region_sc, region_tc, region_en, isMobile, isCode } from '../../components/common/utils';
import { getCookie } from '../../components/common/cookie';
const MobileVerify = ({
  updateFormData,
  countryCode,
  mobile,
  code,
  pending,
  errors
}) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const lang = getCookie('language');
  function verifyMobile() {
    if (!isMobile(mobile)) {
      updateFormData({ errors: { ...errors, mobile: true } })
    } else {
      const { mobile, ...otherErrors } = errors
      updateFormData({ errors: { ...otherErrors } })
    }
  }
  return (
    <List>
      <PickerComponent
        title={formatMessage({ id: 'setMobile-chooseRegion' })}
        disabled={pending}
        data={lang === 'tc' ? region_tc : lang === 'en' ? region_en : region_sc}
        onOk={countryCode => updateFormData({ countryCode: countryCode || '86' })}
        value={countryCode}>
        <FormattedMessage
          id="setMobile-regions"
        />
      </PickerComponent>
      <InputItem
        type="number"
        name="mobile"
        className="mobile-input"
        disabled={pending}
        onBlur={verifyMobile}
        error={errors.mobile}
        onChange={mobile => updateFormData({ mobile })}
        value={mobile}
        placeholder={formatMessage({ id: 'setMobile-mobileInput' })}>
        手机号
      </InputItem>
      <InputItem
        type="number"
        name="code"
        disabled={pending}
        error={errors.mobile}
        onChange={code => updateFormData({ mobileCode: code })}
        value={code}
        maxLength="6"
        placeholder={formatMessage({ id: 'common-codeInput' })}
        extra={
          <GetCode requestFn={(args) => dispatch(requestMobileCode(args))} mobile={mobile} countryCode={countryCode} />}>
        验证码
      </InputItem>
    </List>
  )
}
export default MobileVerify