import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { ImagePicker, WingBlank } from 'antd-mobile';
import { getOssSignature } from '../../reducers/oss';

import styles from './UploadOssFile.module.css'

const pfdDefaultImg = 'https://assets.padi.com.cn/assets/images/pdf.jpg'

const UploadOssFile = ({
  fileList,
  editable,
  type,
  fileName,
  onChange = () => { },
  onDelete = () => { }
}) => {

  const dispatch = useDispatch()
  const [showPic, setShowPic] = useState('')
  const { ossSignature } = useSelector(state => ({
    ossSignature: state.oss.ossSignature
  }))
  const [files, setFiles] = useState([])

  useEffect(() => {
    if (editable && !Object.keys(ossSignature).length > 0 && type) {
      dispatch(getOssSignature(type))
    }
  }, [])

  useEffect(() => {
    if (fileList.length > 0) {
      const fileListMapping = fileList.map(item => {
        if (item.indexOf('pdf') >= 0) return { url: pfdDefaultImg, path: item }
        return { url: item }
      })
      setFiles(fileListMapping)
    } else {
      setFiles()
    }
  }, [fileList])

  const uploadFile = (fs, type, index) => {
    if (type === 'remove') {
      onDelete(index)
    } else if (type === 'add') {
      const file = fs[fs.length - 1].file
      const formData = getUploadData(file)
      onChange(formData)
    } else {
      setFiles([])
    }
  }

  const getUploadData = (file) => {
    let filename = fileName;
    if (file.type.indexOf('pdf') >= 0) filename = filename + 'pdf'
    const formData = new FormData();
    formData.append('key', filename); //存储在oss的文件路径
    formData.append('OSSAccessKeyId', ossSignature.accessid); //accessKeyId
    formData.append('policy', ossSignature.policy); //policy
    formData.append('Signature', ossSignature.signature); //签名
    formData.append("file", file)
    formData.append('success_action_status', 200);
    return formData
  }

  const previewFile = (index, fs) => {
    if (fs[index].path) window.open(fs[index].path)
    else setShowPic(fs[index].url)
  }

  return (
    <WingBlank>
      <ImagePicker
        files={files}
        onChange={(fs, type, index) => uploadFile(fs, type, index)}
        onImageClick={previewFile}
        selectable={editable}
        disableDelete={!editable}
      />
      {showPic && <div className={styles.picBox}>
        <img
          src={require('../../assets/images/close-white.png')}
          alt="close icon"
          className={styles.closeIcon}
          onClick={() => setShowPic('')} />
        <img src={showPic} alt="attach" className={styles.pic} />
      </div>}
    </WingBlank>
  )
}

export default UploadOssFile