import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl';
import { TextareaItem } from 'antd-mobile';
import PickerComponent from './Picker'
import { getCookie } from '../../components/common/cookie'
import areaDataSc from '../../assets/pca-code-m2.json';
import areaDataTc from '../../assets/pca-code-m2-tc.json'
import areaDataEn from '../../assets/pca-code-m2-en.json'
import { useGetStandardRegions } from '../../services/hooks'

const country = [{
  'label': '中国',
  'value': 'cn'
}]

const AddressForm = ({
  onRegionOk = () => { },
  onAddressOk = () => { },
  onCountryOk = () => { },
  address,
  stateId,
  countryId,
  editable,
  onlycn,
  hideAddress
}) => {

  const { formatMessage } = useIntl()
  const language = getCookie('language')
  const [regionData, setRegionData] = useState([])

  const matchLangArea = () => {
    switch (language) {
      case 'sc':
        return areaDataSc
      case 'tc':
        return areaDataTc
      case 'en':
        return areaDataEn
    }
  }

  const { loading } = useGetStandardRegions({
    onSuccess: (data) => {
      const formatData = data.map(item => {
        return {
          label: item.region,
          value: item.id
        }
      })
      setRegionData(formatData)
    },
  });

  return (
    <>
      {!onlycn ? (
        <PickerComponent
          disabled={!editable}
          extra={formatMessage({ id: 'common-select' })}
          data={regionData}
          onOk={id => onCountryOk(id)}
          value={countryId || ''}>
          <FormattedMessage id="setMobile-regions" />
        </PickerComponent>
      ) : (
          <PickerComponent
            disabled={!editable}
            extra={formatMessage({ id: 'common-select' })}
            data={country}
            value={"cn"}>
            <FormattedMessage id="common-country" />
          </PickerComponent>
        )}
      {countryId === 51 && <PickerComponent
        disabled={!editable}
        extra={formatMessage({ id: 'common-select' })}
        onOk={region => onRegionOk(region)}
        data={matchLangArea()}
        value={stateId > 0 ? stateId : '' }>
        <FormattedMessage id="common-Province" />
      </PickerComponent>}
      {!hideAddress && <TextareaItem
        title={formatMessage({ id: 'common-address' })}
        placeholder={formatMessage({ id: 'common-address-reminder' })}
        autoHeight
        value={address}
        disabled={!editable}
        onChange={val => onAddressOk(val)}
      />}
    </>
  )
}

export default AddressForm