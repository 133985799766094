import React from 'react';
import { List, Accordion, WhiteSpace } from 'antd-mobile';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import styles from './Menu.module.css';

const EFRMenu = ({ efrt, efri, efrpi }) => {
  const history = useHistory();

  if (!efrt && !efri && !efrpi) return null;

  return (
    <>
      <Accordion defaultActiveKey="0">
        <Accordion.Panel
          header={<FormattedMessage id="profile-efr-app" />}
          className="profile-list member-renew"
        >
          <List>
            {efri && <List.Item
              arrow="horizontal"
              onClick={() => history.push('/efri_app')}
            >
              <span className={styles.menuItem}>
                <FormattedMessage id="efri-application" />
              </span>
            </List.Item>}
            {efrpi && <List.Item
              arrow="horizontal"
              onClick={() => history.push('/efr_public/app')}
            >
              <span className={styles.menuItem}>
                <FormattedMessage id="profile-efr-public-app" />
              </span>
            </List.Item>}
            {efrt && (
              <List.Item
                arrow="horizontal"
                onClick={() => history.push('/efrt_list')}
              >
                <span className={styles.menuItem}>
                  <FormattedMessage id="profile-efrt" />
                </span>
              </List.Item>
            )}
          </List>
        </Accordion.Panel>
      </Accordion>
      <WhiteSpace size="sm" />
    </>
  );
};

export default EFRMenu;
