import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import React, { useState } from 'react';

import { getCookie } from '../../components/common/cookie';
import { localizeResource } from '../../services/utilities';
import { useHasActivity, useErrorModal } from '../../services/hooks';
import Loading from '../../components/Loading';

import styles from '../NewYearCampaign/Campaign.module.css';

export default function PublicWelfareEcardPage(props) {
  const { push } = useHistory();
  const showErrorModal = useErrorModal();
  const { formatMessage } = useIntl();
  const { loading, data } = useHasActivity(41);

  if (loading) return <Loading />;
  if(!loading && !data) return null

  const { hasCard, binded } = data;
  const getCard = () => {
    if (binded) {
      showErrorModal(formatMessage({ id: 'card-requested' }));
    } else if (hasCard) {
      push('/free_pub_welfare_certs');
    } else {
      window.location = 'https://www.padi.com.cn/dive-shops/nearby';
    }
  };
  return <div>
    <div className={styles["card-post"]}>
      <img src={require('../../assets/images/publicWelfareEcard/home_bg.jpg')} alt="pub-welfare" />
      <input
        type="image"
        name="imgBtn"
        className={styles["btn"]}
        src={require(`../../assets/images/publicWelfareEcard/${hasCard || binded ? 'bottom_btn' : 'study_btn'}.png`)}
        alt="imgBtn"
        onClick={getCard}
      />
    </div>
  </div>
}
