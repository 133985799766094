import React, { useEffect, useState } from 'react';
import { Flex, Icon, Result } from 'antd-mobile';
import { FormattedMessage, useIntl } from 'react-intl';
import { payPalCallback } from '../../services/api';
import { getParameterByName } from '../common/utils';

export default () => {
  const [paymentResult, setPaymentResult] = useState();
  const { formatMessage } = useIntl();

  useEffect(() => {
    const pay = async () => {
      const result = await payPalCallback({
        paymentId: getParameterByName('paymentId'),
        token: getParameterByName('token'),
        payerID: getParameterByName('PayerID'),
        type: getParameterByName('type'),
      });
      if (result && result.data) {
        setPaymentResult(true);
      } else {
        setPaymentResult(false);
      }
    };
    pay();
  }, []);

  return (
    <Flex
      style={{ width: '100%', height: '100%' }}
      justify="center"
      align="center"
    >
      {paymentResult === true ? (
        <FormattedMessage id="paypal-callback-success" />
      ) : paymentResult === false ? (
        <Result
          img={<Icon type="cross-circle-o" className="spe" style={{ fill: '#F13642' }} />}
          title="paypal-callback-error"
          message={formatMessage({id: 'paypal-callback-error'})}
        />
      ) : (
        <FormattedMessage id="paypal-callback" />
      )}
    </Flex>
  );
};
