import React, { useState } from 'react';
import { Form, Input, Button } from 'antd-mobile-v5';
import { MailOutline, LockOutline, CheckCircleFill } from 'antd-mobile-icons';
import { useIntl, FormattedMessage } from 'react-intl';
import { useCountDown } from 'ahooks';
import {
  useGetEmailVerificationCode,
  useVerifyEmail,
} from '../../services/hooks/user';
import { useShowMessage } from '../../services/hooks/ui';

import styles from './Register.module.css';

export default function EmailVerify({ form, onVerifyResult = () => {} }) {
  const { formatMessage } = useIntl();
  const showMessage = useShowMessage();
  const [targetDate, setTargetDate] = useState();
  const [emailVerified, setEmailVerified] = useState(false);

  const { loading: getCodeLoading, run: submitGetCode } =
    useGetEmailVerificationCode({
      onSuccess: (data) => {
        setTargetDate(Date.now() + 99000);
        showMessage('getCode-sendEmail');
      },
    });

  const { loading: verifying, run: verifyEmail } = useVerifyEmail({
    onSuccess: (data, [{ email }]) => {
      onVerifyResult({ 
        email, 
        verified: true, 
        needUserInfo: data.needUserInfo,
        needPassword: data.needPassword
      });
      setEmailVerified(true);
    },
    onError: (error, [{ email }]) => {
      if(error.code === 'email.invalid.code') {
        form.setFields([
          {
            name: 'emailCode',
            errors: [error.clientMsg],
          },
        ]);
        return true;
      }
      onVerifyResult({email, verified: false});
    }
  });

  const [countdown] = useCountDown({
    targetDate,
  });

  const getCode = async () => {
    await form.validateFields(['email']);
    submitGetCode(form.getFieldValue('email'));
    setEmailVerified(false);
  };

  const validate = async () => {
    await form.validateFields(['email', 'emailCode']);
    verifyEmail({
      email: form.getFieldValue('email'),
      code: form.getFieldValue('emailCode'),
    });
  };

  return (
    <>
      <Form.Item
        label={<MailOutline />}
        name="email"
        className={styles.formItem}
        rules={[
          {
            required: true,
            message: formatMessage({ id: 'form-email-empty-error' }),
          },
          {
            type: 'email',
            message: formatMessage({ id: 'form-email-format-error' }),
          },
        ]}
        extra={
          <Button
            disabled={getCodeLoading || countdown > 0}
            loading={getCodeLoading}
            size="small"
            fill="none"
            onClick={getCode}
          >
            {countdown > 0 ? (
              Math.round(countdown / 1000) + 's'
            ) : (
              <FormattedMessage id="common-getCode" />
            )}
          </Button>
        }
      >
        <Input placeholder={formatMessage({ id: 'emailLogin-emailInput' })} />
      </Form.Item>
      <Form.Item
        label={<LockOutline />}
        name="emailCode"
        className={styles.formItem}
        rules={[
          {
            required: true,
            message: formatMessage({ id: 'common-error-verification-code' }),
          },
          {
            pattern: /^\d{6}$/,
            message: formatMessage({ id: 'common-error-verification-code' }),
          },
        ]}
        extra={
          emailVerified ? (
            <CheckCircleFill fontSize={24} color='var(--adm-color-success)' style={{marginRight: '12px'}} />
          ) : (
            <Button
              disable={verifying}
              loading={verifying}
              size="small"
              fill="none"
              onClick={validate}
            >
              <FormattedMessage id="common-validate" />
            </Button>
          )
        }
      >
        <Input
          type="password"
          placeholder={formatMessage({ id: 'common-codeInput' })}
        />
      </Form.Item>
    </>
  );
}
