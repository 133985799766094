import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl';
import { showModal, getSubmitOssFormat, removeExpires } from '../../components/common/utils'
import ApplicantDetailStatus from '../../components/common/ApplicantDetailStatus'
import { isMember } from '../../reducers/selectors'
import { usePayAfdi, useSubmitAfdi } from '../../services/hooks'
import { useHistory } from 'react-router-dom';

const ApplicationStatus = ({
  detail,
  formData,
  checkAge,
  checked,
  certComplete,
  addressComplete
}) => {

  const { formatMessage } = useIntl()
  const { push } = useHistory()
  const { runAsync: submitAfdi, loading: submitting } = useSubmitAfdi();
  const { runAsync: toPay } = usePayAfdi();

  const { signatureUrl, ismember } = useSelector((state) => ({
    signatureUrl: state.ui.signatureUrl,
    ismember: isMember(state)
  }));

  const verifyData = () => {
    const {
      languageId,
      regionId,
      teachingCount,
      teachingCountFiles,
      cdinstructorNumber,
      courseLocation,
      finishDate,
      fdi,
      owsi
    } = formData

    const regionStatus = ismember ? true : regionId
    const trainingInfoComplete = (fdi || owsi) ? true : Boolean(cdinstructorNumber && courseLocation && finishDate)
    const regionAndLangCmplete = Boolean(regionStatus && languageId)
    const teachingComplete = teachingCount || (teachingCountFiles && teachingCountFiles.length > 0)

    return trainingInfoComplete &&
      addressComplete &&
      regionAndLangCmplete &&
      checkAge &&
      checked &&
      signatureUrl &&
      certComplete &&
      teachingComplete
  }

  const submit = () => {
    if (verifyData()) {
      const { finishDate, cdinstructorNumber, code, regionId, ...data } = formData
      let submitData = {
        ...data,
        efrifiles: data.efrifiles ? removeExpires(data.efrifiles) : [],
        fdifiles: data.fdifiles ? removeExpires(data.fdifiles) : [],
        teachingCountFiles: data.teachingCountFiles ? removeExpires(data.teachingCountFiles) : [],
        mfdfiles: data.mfdfiles ? removeExpires(data.mfdfiles) : [],
        fdipreFiles: data.fdipreFiles ? removeExpires(data.fdipreFiles) : [],
        courseDate: finishDate,
        trainerInstructorNumber: cdinstructorNumber,
        handwrittenSignatureUrl: getSubmitOssFormat(signatureUrl),
      }
      if (!ismember) submitData.regionId = regionId
      if (code) submitData.code = code
      submitAfdi(submitData).then(res => {
        if (res.code) {
          push('/application_submit_success?type=AFDI', { code: res.code, paid: res.paid, paymentType: 'MEMBER_APPLICATION_AFDI' });
        }
      })
    } else {
      showModal(
        formatMessage({ id: 'dm-form-submit-error' }),
        formatMessage({ id: 'dm-form-submit-error-info' }),
        () => { },
        () => { },
        formatMessage({ id: 'common-comfirmBtn' }),
        formatMessage({ id: 'common-cancel' })
      )
    }
  }

  return <ApplicantDetailStatus
    detail={detail}
    idType={'trainer'}
    submitFn={submit}
    payFn={toPay}
    applicationType={'AFDI'}
    payApplicationType={'MEMBER_APPLICATION_AFDI'}
    loading={submitting}
  />
}

export default ApplicationStatus