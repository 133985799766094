import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { Button } from 'antd-mobile';
import { FormattedMessage } from 'react-intl';
import { pageLoading, pageLoaded } from '../../actions';
import { formatMoney } from '../../components/common/utils'
import { agentElearningSubmit, fetchProfiles } from '../../services/api'

import styles from '../PriceList/PriceList.module.css'
import BindingYouzan from '../../components/MyProfile/BindingYouzan';

const getSelectedGoodsList = (data, submitItems) => {
  const goodsListArr = []
  data.map(goodsList => {
    goodsListArr.push(...goodsList.skus)
  })
  const selectedItemsList = submitItems.map(selectedItem => {
    let selectedGood = goodsListArr.find(
      item => item.skuId === selectedItem.skuId && item.goodsId === selectedItem.goodsId
    )
    selectedGood = {
      ...selectedGood,
      number: selectedItem.number
    }
    return selectedGood
  })
  return selectedItemsList
}

const getTotalPrice = (data, submitItems) => {
  //TODO
  const currency = 'CNY';
  const selectedGoodsList = getSelectedGoodsList(data, submitItems)
  let total = 0;
  for( let item of selectedGoodsList ) {
    total += item.price * item.number;
  }
  return formatMoney(total, currency, true);
}

const getTotalAmout = (data, submitItems) => {
  const selectedGoodsList = getSelectedGoodsList(data, submitItems)
  let amount = 0;
  for( let item of selectedGoodsList ) {
    amount += item.number
  }
  return amount
}

const CheckoutCounter = ({ toCheckout, disabled, amount, total }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  //TODO
  // const [profile, setProfile] = useState();

  // useEffect(() => {
  //   effect
  //   return () => {
  //     cleanup
  //   }
  // }, [input])
  // const profile  = {teachingLocation: 'CN'};

  return <div className={styles.checkout}>
    <div className={styles.price}>
      <div className={styles.checkout}>
        <span><FormattedMessage id="book-total" />{total}</span>
        <span className={styles.instructions}>
        <FormattedMessage id="book-checkout-hint" />
          {/* <i className="fa fa-question-circle-o fa-lg" onClick={() => setStatus(!instructStatus)}></i>
          {instructStatus && <span className={styles.instrucInfo}>价格说明...</span>} */}
        </span>
      </div>
    </div>
    <div className={styles.pay}>
       {/*  <BindingYouzan onHandleFn={toCheckout}>
          <Button 
            type="primary" 
            className={styles.payBtn} 
            disabled={disabled}
          >
            <FormattedMessage id="book-checkout" />({amount})
          </Button>
        </BindingYouzan>
      ) : ( */}
          <Button 
            type="primary" 
            className={styles.payBtn} 
            disabled={disabled}
            onClick={toCheckout}
          >
            <FormattedMessage id="book-checkout" />({amount})
          </Button>
      )
    </div>
  </div>
}

export default CheckoutCounter