import React from 'react'
import { Dialog } from 'antd-mobile-v5'
import { ExclamationCircleFill } from 'antd-mobile-icons'

export const genderOptions = {
  sc: [
    {
      'label': '男',
      'value': 'M'
    },
    {
      'label': '女',
      'value': 'F'
    },
    {
      'label': '其他',
      'value': 'N'
    },
    {
      'label': '保密',
      'value': 'P'
    }
  ],
  tc: [
    {
      'label': '男',
      'value': 'M'
    },
    {
      'label': '女',
      'value': 'F'
    },
    {
      'label': '其他',
      'value': 'N'
    },
    {
      'label': '保密',
      'value': 'P'
    }
  ],
  en: [
    {
      'label': 'Male',
      'value': 'M'
    },
    {
      'label': 'Female',
      'value': 'F'
    },
    {
      'label': 'Non-conforming',
      'value': 'N'
    },
    {
      'label': 'Prefer not to say',
      'value': 'P'
    }
  ]
}

export const displayGenderLabel = (lang, gender) => {
  const genderList = genderOptions[lang]
  const genderItem = genderList.find(item => item.value === gender)
  return genderItem ? genderItem.label : ''
}

export const regionOptions = {
  sc: [
    {
      "value": 225,
      "label": "北京市"
    },
    {
      "value": 226,
      "label": "天津市"
    },
    {
      "value": 227,
      "label": "河北省"
    },
    {
      "value": 228,
      "label": "山西省"
    },
    {
      "value": 229,
      "label": "内蒙古自治区"
    },
    {
      "value": 230,
      "label": "辽宁省"
    },
    {
      "value": 231,
      "label": "吉林省"
    },
    {
      "value": 232,
      "label": "黑龙江省"
    },
    {
      "value": 233,
      "label": "上海市"
    },
    {
      "value": 234,
      "label": "江苏省"
    },
    {
      "value": 235,
      "label": "浙江省"
    },
    {
      "value": 236,
      "label": "安徽省"
    },
    {
      "value": 237,
      "label": "福建省"
    },
    {
      "value": 238,
      "label": "江西省"
    },
    {
      "value": 239,
      "label": "山东省"
    },
    {
      "value": 240,
      "label": "河南省"
    },
    {
      "value": 241,
      "label": "湖北省"
    },
    {
      "value": 242,
      "label": "湖南省"
    },
    {
      "value": 243,
      "label": "广东省"
    },
    {
      "value": 244,
      "label": "广西壮族自治区"
    },
    {
      "value": 245,
      "label": "海南省"
    },
    {
      "value": 246,
      "label": "重庆市"
    },
    {
      "value": 247,
      "label": "四川省"
    },
    {
      "value": 248,
      "label": "贵州省"
    },
    {
      "value": 249,
      "label": "云南省"
    },
    {
      "value": 250,
      "label": "西藏自治区"
    },
    {
      "value": 251,
      "label": "陕西省"
    },
    {
      "value": 252,
      "label": "甘肃省"
    },
    {
      "value": 253,
      "label": "青海省"
    },
    {
      "value": 254,
      "label": "宁夏回族自治区"
    },
    {
      "value": 255,
      "label": "新疆维吾尔自治区"
    },
    {
      "value": 256,
      "label": "台湾省"
    },
    {
      "value": 257,
      "label": "香港特别行政区"
    },
    {
      "value": 258,
      "label": "澳门特别行政区"
    }
  ],
  tc: [
    {
      "value": 225,
      "label": "北京市"
    },
    {
      "value": 226,
      "label": "天津市"
    },
    {
      "value": 227,
      "label": "河北省"
    },
    {
      "value": 228,
      "label": "山西省"
    },
    {
      "value": 229,
      "label": "內蒙古自治區"
    },
    {
      "value": 230,
      "label": "遼寧省"
    },
    {
      "value": 231,
      "label": "吉林省"
    },
    {
      "value": 232,
      "label": "黑龍江省"
    },
    {
      "value": 233,
      "label": "上海市"
    },
    {
      "value": 234,
      "label": "江蘇省"
    },
    {
      "value": 235,
      "label": "浙江省"
    },
    {
      "value": 236,
      "label": "安徽省"
    },
    {
      "value": 237,
      "label": "福建省"
    },
    {
      "value": 238,
      "label": "江西省"
    },
    {
      "value": 239,
      "label": "山東省"
    },
    {
      "value": 240,
      "label": "河南省"
    },
    {
      "value": 241,
      "label": "湖北省"
    },
    {
      "value": 242,
      "label": "湖南省"
    },
    {
      "value": 243,
      "label": "廣東省"
    },
    {
      "value": 244,
      "label": "廣西壯族自治區"
    },
    {
      "value": 245,
      "label": "海南省"
    },
    {
      "value": 246,
      "label": "重慶市"
    },
    {
      "value": 247,
      "label": "四川省"
    },
    {
      "value": 248,
      "label": "貴州省"
    },
    {
      "value": 249,
      "label": "雲南省"
    },
    {
      "value": 250,
      "label": "西藏自治區"
    },
    {
      "value": 251,
      "label": "陝西省"
    },
    {
      "value": 252,
      "label": "甘肅省"
    },
    {
      "value": 253,
      "label": "青海省"
    },
    {
      "value": 254,
      "label": "寧夏回族自治區"
    },
    {
      "value": 255,
      "label": "新疆維吾爾自治區"
    },
    {
      "value": 256,
      "label": "台灣省"
    },
    {
      "value": 257,
      "label": "香港特別行政區"
    },
    {
      "value": 258,
      "label": "澳門特別行政區"
    }
  ],
  en: [
    {
      "value": 225,
      "label": "Beijing"
    },
    {
      "value": 226,
      "label": "Tianjin"
    },
    {
      "value": 227,
      "label": "Hebei"
    },
    {
      "value": 228,
      "label": "Shanxi"
    },
    {
      "value": 229,
      "label": "Inner Mongolia"
    },
    {
      "value": 230,
      "label": "Liaoning"
    },
    {
      "value": 231,
      "label": "Jilin"
    },
    {
      "value": 232,
      "label": "Heilongjiang"
    },
    {
      "value": 233,
      "label": "Shanghai"
    },
    {
      "value": 234,
      "label": "Jiangsu"
    },
    {
      "value": 235,
      "label": "Zhejiang"
    },
    {
      "value": 236,
      "label": "Anhui"
    },
    {
      "value": 237,
      "label": "Fujian"
    },
    {
      "value": 238,
      "label": "Jiangxi"
    },
    {
      "value": 239,
      "label": "Shandong"
    },
    {
      "value": 240,
      "label": "Henan"
    },
    {
      "value": 241,
      "label": "Hubei"
    },
    {
      "value": 242,
      "label": "Hunan"
    },
    {
      "value": 243,
      "label": "Guangdong"
    },
    {
      "value": 244,
      "label": "Guangxi"
    },
    {
      "value": 245,
      "label": "Hainan"
    },
    {
      "value": 246,
      "label": "Chongqing"
    },
    {
      "value": 247,
      "label": "Sichuan"
    },
    {
      "value": 248,
      "label": "Guizhou"
    },
    {
      "value": 249,
      "label": "Yunnan"
    },
    {
      "value": 250,
      "label": "Tibet"
    },
    {
      "value": 251,
      "label": "Shaanxi"
    },
    {
      "value": 252,
      "label": "Gansu"
    },
    {
      "value": 253,
      "label": "Qinghai"
    },
    {
      "value": 254,
      "label": "Ningxia"
    },
    {
      "value": 255,
      "label": "Xinjiang"
    },
    {
      "value": 256,
      "label": "Taiwan"
    },
    {
      "value": 257,
      "label": "Hong Kong"
    },
    {
      "value": 258,
      "label": "Macao"
    }
  ]
}

export const showErrorDialog = (title, message, btnLabel, confirmFn) => {
  Dialog.alert({
    header: (
      <ExclamationCircleFill
        style={{
          fontSize: 64,
          color: 'var(--adm-color-warning)',
        }}
      />
    ),
    title,
    content: <>{message || '系统错误，请稍后再试。'}</>,
    confirmText: btnLabel,
    onConfirm: confirmFn
  })
};

export const matchLabel = (list, val) => {
  const confirmLabel = list.find(item => item.value === val)
  return confirmLabel.label
}

export const efrCerts = [
  {
    "value": 15,
    "label": "EFR - Care for Children"
  },
  {
    "value": 20,
    "label": "EFR-Primary Care (CPR) & Sec Care (1st aid)"
  },
  {
    "value": 21,
    "label": "EFR - Secondary Care (First Aid)"
  },
  {
    "value": 27,
    "label": "EFR - Care for Children w/AED"
  },
  {
    "value": 31,
    "label": "EFR - Primary Care (CPR) & Sec Care (1st) w/ AED"
  },
  {
    "value": 43,
    "label": "EFR - Primary Care (CPR) only"
  },
  {
    "value": 53,
    "label": "EFR - Adult CPR"
  },
  {
    "value": 54,
    "label": "EFR - Adult Child Infant CPR"
  },
  {
    "value": 56,
    "label": "EFR - Primary Care (CPR) with AED"
  },
  {
    "value": 68,
    "label": "EFR - CPR/FirstAid/Care for Children"
  },
  {
    "value": 76,
    "label": "EFR - CPR/First Aid/Care for Children w/ AED"
  }
]