import { InputItem, Modal, WingBlank } from 'antd-mobile';
import { useIntl, FormattedMessage } from 'react-intl';
import React, { useState } from 'react';

import { agentElearningList } from '../../services/api';
import { showAlert } from '../../components/common/utils';
import SearchLoading from '../../components/common/SearchLoading';
import ProfileInfo from '../../components/Login/ProfileInfo'

import styles from '../../components/common/Search.module.css';
import isEmail from 'validator/lib/isEmail';

const prompt = Modal.prompt;

const SearchResultStudent = ({ searchResult }) => {
  if (!searchResult) return null;
  return (
    <div className={styles.searchResult}>
      <div className={styles.searchIcon}>
        <i className="fa fa-address-card-o"></i>
      </div>
      <div className={styles.searchContent}>
        <span>{searchResult.englishName}</span>
      </div>
    </div>
  );
};
const SearchStudent = ({
  text,
  placeholder,
  editable,
  profile,
  setProfile,
  searchResult: oldSearchResult,
  email,
  onSuccess = () => {},
  onError = () => {},
}) => {
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);
  const [inputVal, setInputVal] = useState(email);
  const [searchResult, setSearchResult] = useState(oldSearchResult);

  const showInput = () => {
    if (!editable) return;
    prompt(
      formatMessage({ id: 'common-search-title' }),
      placeholder,
      [
        { text: formatMessage({ id: 'common-cancel' }) },
        {
          text: formatMessage({ id: 'common-comfirmBtn' }),
          onPress: (studentEmail) =>
            searchInfo(studentEmail)
        },
      ]
    );
  };

  const searchInfo = (studentEmail) => {
    if (!isEmail(studentEmail)) {
      showAlert(
        formatMessage({ id: 'common-isNotEmail' }),
        formatMessage({ id: 'common-close' })
      );
      return;
    }
    excuteSearch(studentEmail);
  };

  const excuteSearch = async (studentEmail) => {
    const oldInputVal = inputVal;
    setInputVal(studentEmail);
    setLoading(true);
    try {
      const response = await agentElearningList(studentEmail);
      console.log("RESP: ", response)
      if (response.error) {
        setInputVal(oldInputVal);
        showAlert(
          response.error.clientMsg,
          formatMessage({ id: 'common-close' })
        );
        onError(response);
      } else {
        setSearchResult(response.data);
        onSuccess(studentEmail, response.data);
      }
    } catch (e) {
      setInputVal(oldInputVal);
      showAlert(
        formatMessage({ id: 'common-errorStatus' }),
        formatMessage({ id: 'common-close' })
      );
      onError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <InputItem
        clear
        type="email"
        placeholder={placeholder}
        editable={editable}
        value={inputVal}
        onClick={() => showInput()}
      >
        {text}
      </InputItem>

      {loading ? (
        <SearchLoading />
      ) : 
      searchResult && !searchResult.userExists 
          ? (
      
            <><WingBlank><p><FormattedMessage id="agent-elearning-new-student" values={{inputVal}} /></p></WingBlank>
              <ProfileInfo profile={profile} setProfile={setProfile} /></>
                  )
          :<SearchResultStudent searchResult={searchResult} />
            }
    </>
  );
};

export default SearchStudent;
