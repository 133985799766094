import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Flex } from 'antd-mobile';
import UploadPhoto from '../common/UploadPhoto'
import ModifyUserInfo from '../common/ModifyUserInfo'

import styles from './CertDetailList.module.css'

const MyInformation = ({ user, studentNumber, certDate, inWaterDate }) => {

  const [hideBod, setHideBod] = useState(true);
  const [hideStudentNo, setHideStudentNo] = useState(false);
  const [visible, setVisible] = useState(false);

  return <div className={styles.detail}>
    <div className={styles.titleWrapper}>
      <h3 className={styles.title}><FormattedMessage id="certdetail-myInfo" /></h3>
      <span className={styles.editInfo} onClick={() => setVisible(true)}>
        <FormattedMessage id="updateUserInfo-title" />
      </span>
      <ModifyUserInfo visible={visible} setVisible={setVisible} userInfo={user} />
    </div>
    <div className={styles.info}>
      <div className={styles.left}>
        <h4 className={styles.name}>{user.englishName}</h4>
        <div className={styles.infos}>
          <div className={styles.items}>
            <span className={styles.itemTitle}><FormattedMessage id="certdetail-diverNum" /></span>
            <Flex><span className={styles.itemContent}>{hideStudentNo ? 'XXXXXXXXXX' : studentNumber}</span><i className={`icon fa ${hideStudentNo ? 'fa-eye-slash' : 'fa-eye'} fa-lg`} onClick={() => setHideStudentNo(!hideStudentNo)}></i></Flex>
          </div>
          <div className={styles.items}>
            <span className={styles.itemTitle}><FormattedMessage id="certdetail-birth" /></span>
            <Flex><span className={styles.itemContent}>{hideBod ? 'XX-XX-XXXX' : user.birthDate}</span><i className={`icon fa ${hideBod ? 'fa-eye-slash' : 'fa-eye'} fa-lg`} onClick={() => setHideBod(!hideBod)}></i></Flex>
          </div>
          <div className={styles.items}>
            <span className={styles.itemTitle}><FormattedMessage id="certdetail-certDate" /></span>
            <span className={styles.itemContent}>{certDate}</span>
          </div>
          {inWaterDate && <div className={styles.items}>
            <span className={styles.itemTitle}><FormattedMessage id="certdetail-reactivate" /></span>
            <span className={styles.itemContent}>{inWaterDate}</span>
          </div>}
        </div>
      </div>
      <div className={styles.right}>
        <UploadPhoto />
      </div>
    </div>
  </div>
}

export { MyInformation }
