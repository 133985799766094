import React, { useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { List, WingBlank } from 'antd-mobile';
import CardLayout from '../../pages/DiveMasterForm/CardLayout'
import { uploadDmFile, removeDmFile } from '../../reducers/ui'
import UploadOssFile from '../../components/common/UploadOssFile'
import UploadFileWrapper from '../../components/common/UploadFileWrapper'
import { certInfoEditableContext } from './DMFormPage'
import UploadEfrOrEfri from '../../components/common/UploadEfrOrEfri'

const Item = List.Item

const RequiredCerts = ({
  aow,
  efr,
  rescue,
  aowFiles,
  efrfiles,
  rescueFiles,
  efri
}) => {

  const dispatch = useDispatch()
  let certInfoEditable = useContext(certInfoEditableContext)
  const status = (aow || aowFiles.length > 0) &&
    (rescue || rescueFiles.length > 0) &&
    ((efr || efrfiles.length > 0) || efri)

  const { ossSignature } = useSelector(state => ({
    ossSignature: state.oss.ossSignature
  }));

  return <CardLayout
    title={<FormattedMessage id="dm-form-required-certs" />}
    status={status}>
    {aow ? (
      <Item>{`AOW (${aow.studentNumber})`}</Item>
    ) : (
        <UploadFileWrapper labelName={"AOW"} editable={certInfoEditable}>
          <UploadOssFile
            fileList={aowFiles || []}
            editable={certInfoEditable}
            type={"AOW"}
            fileName={`${ossSignature.dir}AOW/${new Date().getTime()}`}
            onChange={(formData) => {
              dispatch(uploadDmFile({ formData }))
            }}
            onDelete={(index) => dispatch(removeDmFile(aowFiles[index]))} />
          {certInfoEditable && <WingBlank>
            <div className="small-font gray">
              <FormattedMessage id="common-upload-file-reminder" />
            </div>
          </WingBlank>}
        </UploadFileWrapper>
      )}
    {rescue ? (
      <Item>{`RESCUE (${rescue.studentNumber})`}</Item>
    ) : (
        <UploadFileWrapper labelName={"RESCUE"} editable={certInfoEditable}>
          <UploadOssFile
            fileList={rescueFiles || []}
            editable={certInfoEditable}
            type={"RESCUE"}
            fileName={`${ossSignature.dir}RESCUE/${new Date().getTime()}`}
            onChange={(formData) => {
              dispatch(uploadDmFile({ formData }))
            }}
            onDelete={(index) => dispatch(removeDmFile(rescueFiles[index]))} />
          {certInfoEditable && <WingBlank>
            <div className="small-font gray">
              <FormattedMessage id="common-upload-file-reminder" />
            </div>
          </WingBlank>}
        </UploadFileWrapper>
      )}
    <UploadEfrOrEfri
      uploadFn={(formData) => {
        dispatch(uploadDmFile({ formData }))
      }}
      removeFn={(index) => dispatch(removeDmFile(efrfiles[index]))}
      filename={`${ossSignature.dir}EFR/${new Date().getTime()}`}
      editable={certInfoEditable}
      efrfiles={efrfiles} />
  </CardLayout>
}

export default RequiredCerts