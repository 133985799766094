import React, {useState} from 'react';
import {useIntl} from 'react-intl'
import dayjs from 'dayjs';
import {List, WingBlank, Flex, WhiteSpace} from 'antd-mobile';
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { pageLoading, pageLoaded } from '../../actions';
import { formatMoney, formatDate,showAlert } from '../../components/common/utils'
import { agentElearningSubmit, agentElearningOrder } from '../../services/api'
import InvoiceInfo from '../PriceList/InvoiceInfo'
import { useGetInvoice } from '../../services/hooks';


import SearchStudent from './SearchStudent'
import PriceItem from './PriceItem';
import CheckoutCounter from './CheckoutCounter';

const getSubmitItems = (items, inCart) => items.filter(item => inCart.indexOf(item.itemNo) > -1)
       .map(item => ({...item, number: 1}))

const getTotal = (result, inCart) => {

  const price = result ? result.skus.filter(item => inCart.indexOf(item.itemNo) > -1).reduce((sum, item) => item.price - 0 + sum, 0) : 0;
  const currency = result && result.skus && result.skus[0] ? result.skus[0].currencyType : '';
  return formatMoney(price, currency, true);  
}


const ElearningList = ({result, inCart, toggleSelect}) => {
  if(!result) return <WingBlank><WhiteSpace /><WhiteSpace /><p><FormattedMessage id='agent-elearning-email-hint' /></p><WhiteSpace /></WingBlank>;
  const { skus } = result;
  if(skus.length === 0) return <WingBlank><WhiteSpace /><FormattedMessage id='agent-elearning-not-available' /><WhiteSpace /></WingBlank>;
  return skus.map((item) => (
    <PriceItem item={item} key={item.itemNo} toggleSelect={toggleSelect} checked={inCart.indexOf(item.itemNo) > -1}/>
  ));
}
export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { state } = useLocation();
  const { submitData, searchResult: oldSearchResult } = state || {};
  const {formatMessage} = useIntl();
  const [email, setEmail] = useState(submitData && submitData.email);
  if(submitData && submitData.profile) {
    submitData.profile.birthDate = dayjs(submitData.profile.birthDate).format('YYYY-MM-DD');
  }
  const [searchResult, setSearchResult] = useState(oldSearchResult);

  const [inCart, setInCart] = useState([]);
  const [profile, setProfile] = useState((submitData && submitData.profile) || {});
  const [invoiceInfo, setInvoiceInfo] = useState()
  const { profile: mainProfile } = useSelector(state => ({
    profile: state.data.profile,
  }));
  const { loading: invoiceLoading } = useGetInvoice({
    onSuccess: data => {
      setInvoiceInfo(data)
    }
  });
  const toggleSelect = (id, checked) => {
    if(checked) {
      setInCart([...inCart, id]);
    } else {
      setInCart(inCart.filter(c => c !== id));
    }
  }

  const toCheckout = async () => {
    if(!searchResult || inCart.length === 0) return;
    if(!searchResult.userExists) {
      if(!profile.firstName) {
        showAlert(formatMessage({id: 'setProfile-errorName'}), formatMessage({id: 'common-close'}));
        return;
      }else if(!profile.lastName) {
        showAlert(formatMessage({id: 'setProfile-errorName'}), formatMessage({id: 'common-close'}));
        return;
      }else if(!profile.gender) {
        showAlert(formatMessage({id: 'setProfile-errorGender'}), formatMessage({id: 'common-close'}));
        return;
      }else if(!profile.birthDate) {
        showAlert(formatMessage({id: 'setProfile-errorBirthDate'}), formatMessage({id: 'common-close'}));
        return;
      }
    }

    const submitData = {
      email, 
      skus: getSubmitItems(searchResult.skus, inCart), 
    }
    if(profile.firstName && profile.lastName && profile.birthDate && profile.gender) {
      submitData.profile = {
        ...profile, 
        birthDate: formatDate(profile.birthDate, 'MM/DD/YYYY'),
      }
    }
    try {
      dispatch(pageLoading());
      if(searchResult.mainland) {
        const { data } = await agentElearningSubmit(submitData);
        if(data && data.url) window.location.href = data.url;
      } else {
        const subData = { ...submitData, invoiceDTO: invoiceInfo }
        const { data: order } = await agentElearningOrder(subData);
        history.push('/agent_elearning_checkout', { order, submitData: subData, searchResult }); 
      }
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      dispatch(pageLoaded());
    }
  }

  return <Flex direction="column" justify="between" align="stretch" style={{width: '100%', height: '100%'}}>
    <div style={{width: '100%', height: '100%', overflow: 'hidden'}}>
      <div style={{width: '100%', height: '100%', overflowY: 'scroll'}}>
    <List renderHeader={formatMessage({ id: 'agent-elearning-header-email'})}>
      <SearchStudent email={submitData && submitData.email} text={formatMessage({ id: 'common-email'})} placeholder={formatMessage({ id: 'agent-elearning-email-placeholder'})} editable profile={profile} setProfile={setProfile} onSuccess={(email, searchResult) => {setEmail(email); setInCart([]);setSearchResult(searchResult)}} searchResult={searchResult}/>
    </List>
    {!invoiceLoading && mainProfile && mainProfile.teachingLocation === 'TW' && <InvoiceInfo invoiceInfo={invoiceInfo} />}
    <List renderHeader={formatMessage({ id: 'agent-elearning-header-list'})}>
      <ElearningList result={searchResult} inCart={inCart} toggleSelect={toggleSelect}/>
    </List></div></div>
    
    <CheckoutCounter 
      disabled={inCart.length === 0 || (mainProfile && mainProfile.teachingLocation === 'TW' && !invoiceInfo)} 
      toCheckout={toCheckout} 
      amount={inCart.length} 
      total={getTotal(searchResult, inCart)} />
  </Flex>
}