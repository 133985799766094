import { Image } from 'antd-mobile-v5';
import { Link } from 'react-router-dom';
import React from 'react';

import './Cert.css';

export default function TorcherCert({ torcher }) {
  const { nickName, no } = torcher;
  return (
    <div>
      <div className="cert-details">
        <div className="time-axis">
          <div className="time-spot" />
          <div className="time-line" />
        </div>
        <div className="cert-list-header">
          <h3>PADI 海洋守蔚者</h3>
        </div>
        <div className="card-wrapper">
          <Link to={`/pw_cert?nickName=${nickName}&no=${no}`}>
            <div className={`diver_card`}>
              <div className="diver_card__bd">
                <div className="torcher-name">{nickName}</div>
                <div className="torcher-no">{no}</div>
              </div>
              <div className="diver_card__bg">
                <Image src={require('../../assets/images/pw_cert/cert.jpg')} />
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
