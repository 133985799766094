import React from 'react'
import { useDispatch } from 'react-redux'
import { useIntl, FormattedMessage } from 'react-intl';
import { List, InputItem } from 'antd-mobile';
import GetCode from '../../components/common/getCode';
import { requestDsdEmailCode } from '../../actions';
import isEmail from 'validator/lib/isEmail';
import { isCode } from '../../components/common/utils'
const EmailVerify = ({ updateFormData, email, code, pending, errors }) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  function verifyEmail() {
    if (!isEmail(email)) {
      updateFormData({ errors: { ...errors, email: true } })
    } else {
      const { email, ...otherErrors } = errors
      updateFormData({ errors: { ...otherErrors } })
    }
  }
  return (
    <List>
      <InputItem
        type="text"
        name="email"
        disabled={pending}
        onBlur={verifyEmail}
        error={errors.email}
        onChange={email => updateFormData({ email })}
        value={email}
        placeholder={formatMessage({ id: 'emailLogin-emailInput' })}
        maxLength="80" >
        邮箱
      </InputItem>
      <InputItem
        type="number"
        name="code"
        disabled={pending}
        // error={this.state.errors.code}
        onChange={code => updateFormData({ emailCode: code })}
        value={code}
        maxLength="6"
        placeholder={formatMessage({ id: 'common-codeInput' })}
        extra={
          <GetCode requestFn={(args) => dispatch(requestDsdEmailCode(args))} email={email} />}>
        验证码
      </InputItem>
    </List>
  )
}
export default EmailVerify