import React, { Component } from 'react';
import { Flex, List, InputItem, Button, Toast } from 'antd-mobile';
import isEmail from 'validator/lib/isEmail';
import { injectIntl, FormattedMessage } from 'react-intl';
import { InputOnBlur, showAlert } from '../common/utils';

class SetEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      pending: false,
      errors: {},
    }
  }

  setStateAndShowAlert = (isNotEmail, message, btnLabel) => {
    Toast.hide();
    this.setState({
      pending: false,
      errors: { email: isNotEmail }
    });
    showAlert(message, btnLabel);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.verifyEmail();
  }

  verifyEmail = () => {
    const {intl:{formatMessage}} = this.props;
    this.setState({ pending: true });
    const email = this.state.email.toLowerCase().trim();
    if(!isEmail(email)) {
      this.setStateAndShowAlert(!isEmail(email), formatMessage({id: 'common-isNotEmail'}), formatMessage({id: 'common-close'}));
      return;
    }
    this.props.forgotPassword({email});
    this.setState({ pending: false });
  }


  render() {
    const {intl:{formatMessage}} = this.props;
    return (
      <div className="frame" style={{marginTop: '8vh'}}>
        <form>
          <Flex
            direction="column"
            justify="center"
            align="center" >
            <List className="login-list" renderHeader={() => formatMessage({id: 'forgotPassword-title'})}>
              <InputItem
                type="text"
                name="email"
                disabled={this.state.pending}
                error={this.state.errors.email}
                onChange={email => this.setState({ email, errors: { email: false } })}
                value={this.state.email}
                placeholder={formatMessage({id: 'forgotPassword-emailInput'})}
                maxLength="80"
                onBlur={() => { InputOnBlur() }} >
                <i className="fa fa-envelope-o fa-lg"></i>
              </InputItem>
            </List>
          </Flex>
        </form>
        <Button
          type="primary"
          className="btnConfirm"
          onClick={this.handleSubmit}>
            <FormattedMessage
              id="common-confirmRequest"
            />
          </Button>
      </div>
    )
  }
}

export default injectIntl(SetEmail);
