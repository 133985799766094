import React, { useState } from 'react'
import { InputItem, Modal, List, Icon } from 'antd-mobile';
import SearchLoading from './SearchLoading';
import { useIntl, FormattedMessage } from 'react-intl';
import isEmail from 'validator/lib/isEmail';
import { showAlert } from './utils';
import { searchStudentByEmail } from '../../services/api'

const prompt = Modal.prompt;

const SearchResultInstructor = ({ searchResult }) => {
  if (!searchResult) return null;
  return (
    <List.Item
      thumb={<i className="fa fa-user-o"></i>}
      multipleLine
      key={searchResult.email}
    >
      {searchResult.userName} <List.Item.Brief>{searchResult.email}</List.Item.Brief>
    </List.Item>
  );
};

const SearchResultListInstructor = ({ searchResultList, remove }) => {
  if (!searchResultList || searchResultList.length === 0) return null;
  return searchResultList.map(({ userName, email }) => (
    <List.Item
      thumb={<i className="fa fa-user-o"></i>}
      extra={<Icon type="cross" onClick={() => remove(email)} />}
      multipleLine
      key={email}
    >
      {userName} <List.Item.Brief>{email}</List.Item.Brief>
    </List.Item>
  ));
};

const SearchUserByEmail = ({
  editable = true,
  type,
  onSuccess = () => { },
  onError = () => { },
  multiple
}) => {

  const [loading, setLoading] = useState(false);
  const [inputVal, setInputVal] = useState();
  const [searchResult, setSearchResult] = useState();
  const [searchResultList, setSearchResultList] = useState([]);
  const { formatMessage } = useIntl()

  const showInput = () => {
    if (!editable) return;
    prompt(
      formatMessage({ id: 'common-search-title' }),
      formatMessage({ id: 'emailLogin-emailInput' }),
      [
        { text: formatMessage({ id: 'common-cancel' }) },
        {
          text: formatMessage({ id: 'common-comfirmBtn' }),
          onPress: (email) => searchInfo(email)
        },
      ]
    );
  };

  const searchInfo = (email) => {
    if (!isEmail(email)) {
      showAlert(
        formatMessage({ id: 'common-isNotEmail' }),
        formatMessage({ id: 'common-close' })
      );
      return;
    }
    excuteSearch(email);
  };

  const excuteSearch = async (email) => {
    const oldInputVal = inputVal;
    setInputVal(email);
    setLoading(true);
    try {
      const response = await searchStudentByEmail(email, type);
      if (response.error) {
        setInputVal(oldInputVal);
        showAlert(
          response.error.clientMsg,
          formatMessage({ id: 'common-close' })
        );
        onError(response);
      } else {
        if (multiple) {
          if (searchResultList.length > 0) {
            const hasSameStudent = searchResultList.find((s) => s.email === response.data.email)
            if (hasSameStudent) {
              showAlert(
                formatMessage({ id: 'dm-form-student-added' }),
                formatMessage({ id: 'common-close' })
              );
              return;
            }
            setSearchResultList([...searchResultList, response.data]);
            onSuccess([...searchResultList, response.data]);
          } else {
            setSearchResultList([response.data]);
            onSuccess([response.data]);
          }
        } else {
          setSearchResult(response.data);
          onSuccess(response.data);
        }
      }
    } catch (e) {
      setInputVal(oldInputVal);
      showAlert(
        formatMessage({ id: 'common-errorStatus' }),
        formatMessage({ id: 'common-close' })
      );
      onError(e);
    } finally {
      if (multiple) setInputVal(oldInputVal);
      setLoading(false);
    }
  };

  const removeStudent = (email) => {
    const list = searchResultList.filter((s) => s.email !== email);
    setSearchResultList(list);
    onSuccess(list)
  }

  return <>
    <InputItem
      clear
      type="number"
      placeholder={formatMessage({ id: 'fd-pic-email-input' })}
      editable={editable}
      value={inputVal}
      onClick={() => showInput()}
    >
      <FormattedMessage id="common-email" />
    </InputItem>
    {multiple ? (
      <SearchResultListInstructor
        searchResultList={searchResultList}
        loading={loading}
        remove={removeStudent} />
    ) : (
        <SearchResultInstructor
          searchResult={searchResult}
          loading={loading} />
      )
    }
    {loading && <SearchLoading />}
  </>
}

export default SearchUserByEmail