import React from 'react'
import { useSelector } from 'react-redux';
import AppList from '../../components/common/AppList'
import { skipMFDITListPage } from '../../reducers/mfdi'

const MFDITList = () => {
  const { confirmRes } = useSelector((state) => ({
    confirmRes: state.MFDI.MFDITList,
  }));

  return <AppList confirmRes={confirmRes} detailUrl="/mfdi_app_detail" paging={skipMFDITListPage} listHeader="mfdi-t-title"/>
}

export default MFDITList