import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { WingBlank } from 'antd-mobile';
import UploadOssFile from '../../components/common/UploadOssFile'
import UploadFileWrapper from '../../components/common/UploadFileWrapper'
import { uploadMFDIOssFile, removeMFDIFile } from '../../reducers/mfdi'

const AfdiAndPrepare = ({ afdifiles, editable, fdipreFiles }) => {

  const { ossSignature } = useSelector((state) => ({
    ossSignature: state.oss.ossSignature
  }));

  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const filename = (type) => `${ossSignature.dir}${type}/${new Date().getTime()}`

  return <>
    <UploadFileWrapper 
      labelName={formatMessage({ id: 'mfd-afdi' })} 
      editable={editable}>
      <UploadOssFile
        fileList={afdifiles || []}
        fileName={filename('afdi')}
        onChange={(formData) => {
          dispatch(uploadMFDIOssFile({ formData }))
        }}
        onDelete={(index) => dispatch(removeMFDIFile(afdifiles[index]))}
        editable={editable} />
      {editable && <WingBlank>
        <div className="small-font gray">
          <FormattedMessage id="mfd-afdi-upload-reminder" />
        </div>
      </WingBlank>}
    </UploadFileWrapper>
    <UploadFileWrapper 
      labelName={formatMessage({ id: 'mermaid-other-files' })} 
      editable={editable}>
      <UploadOssFile
        fileList={fdipreFiles || []}
        fileName={filename('prepare')}
        onChange={(formData) => {
          dispatch(uploadMFDIOssFile({ formData }))
        }}
        onDelete={(index) => dispatch(removeMFDIFile(fdipreFiles[index]))}
        editable={editable} />
      {editable && <WingBlank>
        <div className="small-font gray">
          <FormattedMessage id="app-health" />
        </div>
      </WingBlank>}
    </UploadFileWrapper>
  </>
}

export default AfdiAndPrepare