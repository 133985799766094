import React from 'react'
import { WhiteSpace } from 'antd-mobile'
import { Radio } from 'antd';
import { FormattedMessage } from 'react-intl';
import CardLayout from '../DiveMasterForm/CardLayout'

import styles from '../OwsiApplication/OwsiApplication.module.css'

const IeDetail = ({ ieAttempt, resitList }) => {

  const findResit = (resitKey) => {
    return !!resitList.find(item => item === resitKey)
  }

  return (
    <CardLayout title={<FormattedMessage id="profile-ie" />} status={"none"}>
      <div className={styles['ie']}>
        <div className={styles['ie-title']}>
          <FormattedMessage id="owsi-ie-times" />
        </div>
        <WhiteSpace />
        <Radio.Group value={ieAttempt} disabled={true}>
          <div>
            <Radio value={1}><FormattedMessage id="owsi-ie-first" /></Radio>
            <Radio value={2}><FormattedMessage id="owsi-ie-second" /></Radio>
          </div>
          <WhiteSpace />
          <div>
            <Radio value={3}><FormattedMessage id="owsi-ie-third" /></Radio>
            <Radio value={4}><FormattedMessage id="owsi-ie-fourth" /></Radio>
          </div>
        </Radio.Group>
        {ieAttempt !== 1 && <>
          <WhiteSpace />
          <div>
            <div className={styles['ie-title']}>
              <FormattedMessage id="owsi-ie-resit" />
            </div>
            <WhiteSpace />
            <div>
              <Radio 
                checked={findResit(1)} 
                disabled={true}><FormattedMessage id="owsi-ie-written-exam" /></Radio>
              <Radio 
                checked={findResit(2)} 
                disabled={true}><FormattedMessage id="owsi-ie-knowledge" /></Radio>
            </div>
            <WhiteSpace />
            <div>
              <Radio 
                checked={findResit(3)}
                disabled={true} ><FormattedMessage id="owsi-ie-confined-water" /></Radio>
              <Radio 
                checked={findResit(4)} 
                disabled={true}><FormattedMessage id="owsi-ie-open-water" /></Radio>
            </div>
          </div>
        </>}
      </div>
    </CardLayout>
  )
}

export default IeDetail