import React from 'react'
import { useLocation } from 'react-router-dom';
import { Result, Button, Icon, WingBlank, WhiteSpace, Card } from 'antd-mobile'
import { useHistory } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl';

const CreditResult = () => {

  const history = useHistory()
  const { formatMessage } = useIntl();
  const { state: {payResult} } = useLocation();

  if(!payResult) return null

  return <div className="result">
    <Result
      img={<Icon type="check-circle" className="spe" style={{ fill: '#1F90E6' }} />}
      title={formatMessage({ id: 'credit-card-pay-success' })}
    />
    <WhiteSpace />
    <Card>
      <Card.Header
        title={formatMessage({ id: "paypal-order-detail" })}
      />
      <Card.Body>
        <div><FormattedMessage id="paypal-order-number" />{payResult.id}</div>
        <WhiteSpace />
        {payResult.items.map(item=> (
          <>
            <div><FormattedMessage id="paypal-goods-name" />{item.name}</div>
            {item.quantity && <div><FormattedMessage id="paypal-goods-quantity" />{item.quantity}</div>}
            <WhiteSpace />
          </>
        ))}
      </Card.Body>
      <Card.Footer extra={<div><FormattedMessage id="paypal-order-price" />{payResult.price}</div>} />
    </Card>
    <WhiteSpace size="xl" />
    <WingBlank>
      <Button type="primary" onClick={() => history.push('/certs')}>
        <FormattedMessage id="common-back-home" />
      </Button>
    </WingBlank>
  </div>
}

export default CreditResult