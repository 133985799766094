import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { List } from 'antd-mobile';
import UploadOssFile from '../../components/common/UploadOssFile'
import UploadFileWrapper from '../../components/common/UploadFileWrapper'
import { uploadAFDIOssFile, removeAFDIFile } from '../../reducers/afdi'

const Item = List.Item

const OwsiAndPrepare = ({ editable, fdifiles }) => {

  const { ossSignature } = useSelector((state) => ({
    ossSignature: state.oss.ossSignature
  }));
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const filename = (type) => `${ossSignature.dir}${type}/${new Date().getTime()}`

  return <>
    <Item>
      <FormattedMessage id="fdi-owsi-cert" />  <FormattedMessage id="afdi-satisfy" />
    </Item>
    <UploadFileWrapper 
      labelName={formatMessage({ id: 'fdi-not-padi-cert' })} 
      editable={editable}>
      <UploadOssFile
        fileList={fdifiles || []}
        fileName={filename('fdi')}
        onChange={(formData) => {
          dispatch(uploadAFDIOssFile({ formData }))
        }}
        onDelete={(index) => dispatch(removeAFDIFile(fdifiles[index]))}
        editable={editable} />
    </UploadFileWrapper>
  </>
}

export default OwsiAndPrepare