import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { NavBar } from 'antd-mobile-v5'
import { useIntl } from 'react-intl';

import { getProfile, getUserInfo } from '../../reducers/selectors';

import {
  requestProfiles,
} from '../../actions';

import './MyProfile.css';
import MemberMenus from './MemberMenus';

function MemberZone({ profiles, userInfo, requestProfiles }) {
  const { formatMessage } = useIntl();
  useEffect(() => {
    requestProfiles();
  }, []);

  if (!profiles || !userInfo) return null;

  return <>
    <NavBar back={formatMessage({ id: "common-back" })} onBack={() => window.location.href = "https://padiclub.zhibo.tv/pages/home/home"}></NavBar>
    <div>
      <img
        className={'user-header-bg'}
        src={require('../../assets/images/profile-header.jpg')}
        alt="bg"
      />
    </div>
    <div className="my-profile">
       <MemberMenus profiles={profiles} />
    </div>
  </>
}

function mapStateToProps(state) {
  return {
    profiles: getProfile(state),
    userInfo: getUserInfo(state),
  };
}

export default connect(mapStateToProps, {
  requestProfiles,
})(MemberZone);
