import React from "react";
import { useSelector } from 'react-redux';
import {
  InputItem,
  WhiteSpace
} from 'antd-mobile';
import { FormattedMessage, useIntl } from 'react-intl';
import CardLayout from '../DiveMasterForm/CardLayout'
import Signature from '../../components/common/Signature'
import SearchStore from '../../components/common/SearchStore';
import SearchInstructor from '../../components/common/SearchInstructor';
import UploadOssFile from '../../components/common/UploadOssFile'
import UploadFileWrapper from '../../components/common/UploadFileWrapper'
import UserPhoto from '../../components/common/UserPhoto'
import DetailStatus from '../../components/common/DetailStatus'
import {
  formatDate,
  filterTeachingLanguage,
  filterStateId
} from "../../components/common/utils";
import DatePickerComponent from '../../components/common/DatePicker'
import { afdiTApproved } from '../../services/api';
import { usePayAfdi } from '../../services/hooks'

import styles from '../DiveMasterForm/DiveMasterForm.module.css'
import { getCookie } from "../../components/common/cookie";

const FdiTDetail = () => {

  const { formatMessage } = useIntl()
  const { detail } = useSelector(state => ({
    detail: state.AFDI.AFDITDetail,
  }));
  const { runAsync: toPay } = usePayAfdi();
  const lang = getCookie('language');

  const editable = !!(detail.status === 'WAIT_CONFIRM')

  if (!Object.keys(detail).length > 0) return null
  return <>
    <CardLayout title={<FormattedMessage id="dm-form-diver-info" />} status={"none"}>
      <div className={styles["diver-info"]}>
        <div>
          <InputItem
            type="text"
            editable={false}
            value={detail.userInfoDTO.englishName}
          >
            <FormattedMessage id="dm-form-name" />
          </InputItem>
          <InputItem
            type="text"
            editable={false}
            value={formatDate(detail.userInfoDTO.birthDate, 'YYYY-MM-DD')}
          >
            <FormattedMessage id="dm-form-birth" />
          </InputItem>
        </div>
        <UserPhoto id={detail.userInfoDTO.affiliateId} />
      </div>
    </CardLayout>
    <WhiteSpace />
    <CardLayout title={<FormattedMessage id="dm-form-required-certs" />} status={"none"}>
      <UploadFileWrapper
        labelName={detail.efri ? `EFRI ${formatMessage({ id: 'afdi-satisfy' })}` : "EFRI"}
        editable={false}>
        <UploadOssFile
          fileList={detail.efrifiles || []}
          editable={false} />
      </UploadFileWrapper>
      <UploadFileWrapper
        labelName={detail.mfd ? `${formatMessage({ id: 'afdi-master-fd' })} （${detail.mfd}）` : formatMessage({ id: 'afdi-master-fd' })}
        editable={false}>
        <UploadOssFile
          fileList={detail.mfdfiles || []}
          editable={false} />
      </UploadFileWrapper>
      {detail.fdipreFiles && <UploadFileWrapper
        labelName={formatMessage({ id: 'afdi-prepare-others' })}
        editable={false}>
        <UploadOssFile
          fileList={detail.fdipreFiles || []}
          editable={false} />
      </UploadFileWrapper>}
      {detail.fdifiles && <UploadFileWrapper
        labelName={formatMessage({ id: 'fdi-not-padi-cert' })}
        editable={false}>
        <UploadOssFile
          fileList={detail.fdifiles || []}
          editable={false} />
      </UploadFileWrapper>}
      {detail.owsi ? (
        <UploadFileWrapper
          labelName={formatMessage({ id: 'fdi-owsi-cert' }) + formatMessage({ id: 'afdi-satisfy' })}
          editable={false}>
        </UploadFileWrapper>
      ) : (
          <UploadFileWrapper
            labelName={formatMessage({ id: 'fdi-not-padi-cert' })}
            editable={false}>
            <UploadOssFile
              fileList={detail.fdifiles || []}
              editable={false} />
          </UploadFileWrapper>
        )}
    </CardLayout><WhiteSpace />
    <CardLayout title={<FormattedMessage id="afdi-certs-Requirement" />} status={"none"}>
      {detail.teachingCount ? (
        <div className={styles.certBox}>
          <FormattedMessage id="mfdi-satisfy-certs" />
        </div>
      ) : (
          <UploadOssFile
            fileList={detail.teachingCountFiles || []}
            editable={false} />
        )}
    </CardLayout>
    <WhiteSpace />
    <CardLayout title={<FormattedMessage id="dm-form-training-info" />} status={"none"}>
      {detail.instructorInfoDTO && <SearchInstructor
        id={"trainer"}
        editable={false}
        instructor={detail.instructorInfoDTO}
        instructorNumber={detail.instructorInfoDTO.instructorNumber}
      />}
      {detail.storeInfoDTO && <SearchStore
        editable={false}
        store={detail.storeInfoDTO}
        storeNumber={detail.storeInfoDTO.storeNumber}
      />}
      <InputItem
        type="text"
        editable={false}
        value={detail.courseLocation}
      >
        <FormattedMessage id="si-course-location-title" />
      </InputItem>
      <DatePickerComponent
        extra={formatMessage({ id: 'common-select' })}
        title={formatMessage({ id: 'efri-course-date-error' })}
        maxDate={new Date()}
        disabled={true}
        value={detail.courseDate}>
        <FormattedMessage id="common-course-date" />
      </DatePickerComponent>
    </CardLayout>
    <WhiteSpace />
    <CardLayout title={<FormattedMessage id="dm-region-and-lang-title" />} status={"none"}>
      {detail.regionDTO && <InputItem
        type="text"
        editable={false}
        value={detail.regionDTO.region}
      >
        <FormattedMessage id="dm-region" />
      </InputItem>}
      <InputItem
        type="text"
        editable={false}
        value={filterTeachingLanguage(detail.languageId)}
      >
        <FormattedMessage id="dm-lang-title" />
      </InputItem>
    </CardLayout>
    <WhiteSpace />
    <CardLayout title={formatMessage({ id: 'common-address-detail' })} status={"none"}>
      <InputItem
        type="text"
        editable={false}
        value={detail.addressRegionDTO.region}
      >
        <FormattedMessage id="setMobile-regions" />
      </InputItem>
      {detail.addressStateId > 0 && <InputItem
        type="text"
        editable={false}
        value={filterStateId(detail.addressStateId, lang)}
      >
        <FormattedMessage id="common-Province" />
      </InputItem>}
      <InputItem
        type="text"
        editable={false}
        value={detail.address}
      >
        <FormattedMessage id="common-address" />
      </InputItem>
    </CardLayout>
    <WhiteSpace />
    <Signature
      modified={editable}
      filePath={'signature'}
      initSignature={detail.handwrittenSignatureUrlTrainer} />
    <WhiteSpace size="lg" />
    <DetailStatus 
      detail={detail} 
      approveFn={afdiTApproved} 
      payFn={toPay} 
      listUrl="/afdi_app_list"
      payApplicationType="MEMBER_APPLICATION_AFDI" />
    <WhiteSpace size="lg" />
  </>
}

export default FdiTDetail