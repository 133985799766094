import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import sc from '../locale/sc';
import tc from '../locale/tc';
import en from '../locale/en';
import { getParameterByName } from './common/utils';
import { getCookie, setCookie } from './common/cookie';

export const Context = React.createContext();

function getMessagesByLanguage(lang) {
  if (lang === 'en') return en;
  if (lang === 'tc') return tc;
  return sc;
}

function getLocaleByLanguage(lang) {
  if(lang === 'en') return 'en';
  return 'zh';
}

let initLanguage = getParameterByName('lang') || getCookie('language');

if (['sc', 'tc', 'en'].indexOf(initLanguage) < 0) {
  const browserLocale = navigator.language;
  if (browserLocale.indexOf('en') === 0) {
    initLanguage = 'en';
  } else {
    if (
      browserLocale === 'zh-HK' ||
      browserLocale === 'zh-TW' ||
      browserLocale === 'zh-MO' ||
      browserLocale === 'zh-CHT'
    ) {
      initLanguage = 'tc';
    } else {
      initLanguage = 'sc';
    }
  }
}

export function IntlWrapper(props) {
  const [language, setLanguage] = useState(initLanguage);

  const messages = getMessagesByLanguage(language);

  useEffect(() => {
    setCookie('language', language);
  }, [language]);

  return (
    <Context.Provider value={{ language, toggleLanguage: setLanguage }}>
      <IntlProvider messages={messages} locale={getLocaleByLanguage(language)}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
}
