import React, { Suspense, lazy } from 'react';
import { ProtectedRoute } from '../components/ProtectedRoute/ProtectedRoute';
import PicCollectionEditer from '../pages/Admin/PicCollectionEditer/PicCollectionEditer';
import PicTasksList from '../pages/Admin/PicCollectionEditer/PicTasksList';
import TaskPicList from '../pages/Admin/PicCollectionEditer/TaskPicList';
const DmApplicationPage = lazy(() =>
  import('../pages/Admin/DmAppilcation/DmAppilcation')
);
const SiApplicationPage = lazy(() =>
  import('../pages/Admin/SiApplication/SiApplication')
);
const OwsiApplicationPage = lazy(() =>
  import('../pages/Admin/OwsiApplication/OwsiApplication')
);
const EfriApplicationPage = lazy(() =>
  import('../pages/Admin/EfriApplication/EfriApplication')
);
const BasicMermaidApplicationPage = lazy(() =>
  import('../pages/Admin/BasicMermaid/BasicMermaidApplication')
);
const MermaidApplicationPage = lazy(() =>
  import('../pages/Admin/Mermaid/MermaidApplication')
);
const FDIAdminApplication = lazy(() =>
  import('../pages/Admin/FDI/FDIApplication')
);
const AFDIAdminApplication = lazy(() =>
  import('../pages/Admin/AFDI/AFDIApplication')
);
const MFDIAdminApplication = lazy(() =>
  import('../pages/Admin/MFDI/MFDIApplication')
);
const DSDAdminPage = lazy(() => import('../pages/Admin/DSD/DSD'));
const DMDAdminPage = lazy(() => import('../pages/Admin/DMD/DMD'));
const MergeCertsAdminPage = lazy(() => import('../pages/Admin/MergeCerts'));
const FindAccountAdminPage = lazy(() => import('../pages/Admin/FindAccount'))
const UpdateUserInfoPage = lazy(() => import('../pages/Admin/UpdateUserInfo'))
const AdminWenjuanListPage = lazy(() => import('../pages/Admin/Wenjuan'))
const OtherApplicationsAdminPage = lazy(() =>
  import('../pages/Admin/otherApplications')
);
const StoreApplicationsAdminPage = lazy(() =>
  import('../pages/Admin/StoreApplications')
);
const CreateOrderPage = lazy(() => import('../pages/Admin/TextbooksOrder/CreateOrder'));
const NonRoutineOrdersPage = lazy(() => import('../pages/Admin/TextbooksOrder/NonRoutineOrders'));
const TextbookOrdersPage = lazy(() => import('../pages/Admin/TextbooksOrder/TextbookOrders'))
const ApplicationFeeOrdersPage = lazy(() => import('../pages/Admin/TextbooksOrder/ApplicationFeeOrders'))
const InvoiceInfoPage = lazy(() => import('../pages/Admin/TextbooksOrder/InvoiceInfoOrders'))
const AdminOrdersListPage = lazy(() => import('../pages/Admin/TextbooksOrder/AdminOrderList'))

const PadiAdminRoutes = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <ProtectedRoute
      path="/admin/dm"
      component={DmApplicationPage}
      admin={true}
    />
    <ProtectedRoute
      path="/admin/si"
      component={SiApplicationPage}
      admin={true}
    />
    <ProtectedRoute
      path="/admin/owsi"
      component={OwsiApplicationPage}
      admin={true}
    />
    <ProtectedRoute
      path="/admin/efri"
      component={EfriApplicationPage}
      admin={true}
    />
    <ProtectedRoute
      path="/admin/basic_mermaid"
      component={BasicMermaidApplicationPage}
      admin={true}
    />
    <ProtectedRoute
      path="/admin/mermaid"
      component={MermaidApplicationPage}
      admin={true}
    />
    <ProtectedRoute
      path="/admin/fdi"
      component={FDIAdminApplication}
      admin={true}
    />
    <ProtectedRoute
      path="/admin/afdi"
      component={AFDIAdminApplication}
      admin={true}
    />
    <ProtectedRoute
      path="/admin/mfdi"
      component={MFDIAdminApplication}
      admin={true}
    />
    <ProtectedRoute path="/admin/dsd" component={DSDAdminPage} admin={true} />
    <ProtectedRoute path="/admin/dmd" component={DMDAdminPage} admin={true} />
    <ProtectedRoute
      path="/admin/collection/create"
      component={PicCollectionEditer}
      admin={true}
    />
    <ProtectedRoute
      path="/admin/collection/task_list"
      component={PicTasksList}
      admin={true}
    />
    <ProtectedRoute
      path="/admin/collection/task_detail_list"
      component={TaskPicList}
      admin={true}
    />
    <ProtectedRoute
      path="/admin/merge_certs"
      component={MergeCertsAdminPage}
      admin={true}
    />
    <ProtectedRoute
      path="/admin/find_account"
      component={FindAccountAdminPage}
      admin={true}
    />
    <ProtectedRoute
      path="/admin/update_user_info"
      component={UpdateUserInfoPage}
      admin={true}
    />
    <ProtectedRoute
      path="/admin/wenjuan"
      component={AdminWenjuanListPage}
      admin={true}
    />
    <ProtectedRoute
      path="/admin/pockets_apps"
      component={OtherApplicationsAdminPage}
      admin={true}
    />
    <ProtectedRoute
      path="/admin/store_apps"
      component={StoreApplicationsAdminPage}
      admin={true}
    />
    <ProtectedRoute
      path="/admin/create_order"
      component={CreateOrderPage}
      admin={true}
    />
    <ProtectedRoute
      path="/admin/non_routine_orders"
      component={NonRoutineOrdersPage}
      admin={true}
    />
    <ProtectedRoute
      path="/admin/textbook_orders"
      component={TextbookOrdersPage}
      admin={true}
    />
    <ProtectedRoute
      path="/admin/application_fee_orders"
      component={ApplicationFeeOrdersPage}
      admin={true}
    />
    <ProtectedRoute
      path="/admin/invoice_info_orders"
      component={InvoiceInfoPage}
      admin={true}
    />
    <ProtectedRoute
      path="/admin/orders_list"
      component={AdminOrdersListPage}
      admin={true}
    />
  </Suspense>
);

export default PadiAdminRoutes;
