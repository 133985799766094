import React from 'react';
import { List, Accordion, WhiteSpace } from 'antd-mobile';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import styles from './Menu.module.css'

const FreediveMenu = ({ fd, afd, mfd, fdPic, fdit }) => {

  const history = useHistory();

  if (!fd && !afd && !mfd && !fdPic && !fdit) return null

  return <>
    <Accordion defaultActiveKey="0">
      <Accordion.Panel header={<FormattedMessage id="profile-fd-app" />} className="profile-list member-renew">
        <List>
          {fd && <List.Item arrow="horizontal" onClick={() => history.push('/fdi_app')}>
            <span className={styles.menuItem}>
              <FormattedMessage id="fdi-app" />
            </span>
          </List.Item>}
          {afd && <List.Item
            arrow="horizontal"
            onClick={() => history.push('/afdi_app')}
          >
            <span className={styles.menuItem}>
              <FormattedMessage id="afdi-app" />
            </span>
          </List.Item>}
          {mfd && <List.Item
            arrow="horizontal"
            onClick={() => history.push('/mfdi_app')}
          >
            <span className={styles.menuItem}>
              <FormattedMessage id="mfdi-app" />
            </span>
          </List.Item>}
          {fdit && <List.Item
            arrow="horizontal"
            onClick={() => history.push('/fdi_app_list')}
          >
            <span className={styles.menuItem}>
              <FormattedMessage id="profile-fdi" />
            </span>
          </List.Item>}
          {fdit && <List.Item
            arrow="horizontal"
            onClick={() => history.push('/afdi_app_list')}
          >
            <span className={styles.menuItem}>
              <FormattedMessage id="profile-afdi" />
            </span>
          </List.Item>}
          {fdit && <List.Item
            arrow="horizontal"
            onClick={() => history.push('/mfdi_app_list')}
          >
            <span className={styles.menuItem}>
              <FormattedMessage id="profile-mfdi" />
            </span>
          </List.Item>}
          {fdPic && <List.Item
            arrow="horizontal"
            onClick={() => history.push('/fd_pic_list')}
          >
            <span className={styles.menuItem}>
              <FormattedMessage id="profile-fd-pic" />
            </span>
          </List.Item>}
        </List>
      </Accordion.Panel>
    </Accordion>
    <WhiteSpace size="sm" />
  </>
}

export default FreediveMenu