import React from 'react'
import CardLayout from '../DiveMasterForm/CardLayout'
import { InputItem } from 'antd-mobile';
import { FormattedMessage } from 'react-intl';
import UserPhoto from '../../components/common/UserPhoto'

import styles from './SpecialtyInstructor.module.css'

const DiverInfo = ({ userInfo }) => {

  const { englishName, birthDate, memberNumber, rating } = userInfo

  return <CardLayout title={<FormattedMessage id="si-profile" />} status={"none"}>
    <div className={styles["diver-info"]}>
      <div>
        <InputItem
          type="text"
          editable={false}
          value={englishName}
        >
          <FormattedMessage id="dm-form-name" />
        </InputItem>
        <InputItem
          type="text"
          editable={false}
          value={birthDate}
        >
          <FormattedMessage id="dm-form-birth" />
        </InputItem>
        <InputItem
          type="text"
          editable={false}
          value={memberNumber}
        >
          <FormattedMessage id="si-member-number" />
        </InputItem>
        <InputItem
          type="text"
          editable={false}
          value={rating}
        >
          <FormattedMessage id="si-level" />
        </InputItem>
      </div>
      <UserPhoto />
    </div>
  </CardLayout>
}

export default DiverInfo