import React, { Component } from 'react';
import { connect } from 'react-redux'
import { List, InputItem, Picker, Button, WhiteSpace, Modal, WingBlank } from 'antd-mobile';
import areaDataSc from '../../assets/pca-code.json';
import areaDataTc from '../../assets/pca-code-tc.json';
import { credentialRequest } from '../../actions';
import { getAddressList } from '../../reducers/selectors'
import querystring from 'querystring'
import { injectIntl, FormattedMessage } from 'react-intl';
import { InputOnBlur, getParameterByName, region_sc, region_tc, region_en } from '../../components/common/utils';
import { getCookie } from '../../components/common/cookie';

import './AddressForm.css';

const areaData = getCookie('language') === 'tc' ? areaDataTc : areaDataSc

class AddressFormPage extends Component {
    constructor(props) {
        super(props);
        this.addressList = this.props.addressList
        this.state = {
            provinceId: '',
            cityId: '',
            districtId: '',
            name: '',
            regions: '',
            mobile: '',
            addressLine: '',
            location: {
                province: '',
                district: '',
                city: '',
                provinceLabel: '',
                districtLabel: '',
                cityLabel: '',
            },
            errors: {},
            pending: false,
        }
    }

    componentWillReceiveProps(nextProps) {
        const id = this.props.id
        if(id && nextProps.addressList) {
            const currentAddress = nextProps.addressList.find(address => address.addressId.toString() === id)
            this.setState({
                name: currentAddress.userName,
                provinceId: currentAddress.provinceId,
                cityId: currentAddress.cityId,
                districtId: currentAddress.districtId,
                mobile: currentAddress.userMobile,
                addressLine: currentAddress.addressLine,
                location: {
                    province: currentAddress.provinceId,
                    district: currentAddress.districtId,
                    city: currentAddress.cityId,
                    provinceLabel: currentAddress.provinceLabel,
                    districtLabel: currentAddress.districtLabel,
                    cityLabel: currentAddress.cityLabel,
                },
            })
        }
    }

    selectedLocation(e) {
        const [provinceId, cityId, districtId] = e;
        const province = areaData.find(x => x.value === provinceId);
        const city = province.children.find(x => x.value === cityId);
        const district = city ? city.children.find(x => x.value === districtId) : '';
        this.setState({
            provinceId,
            cityId,
            districtId,
            location: {
                province: province.value,
                city: city ? city.value : '',
                district: district ? district.value : '',
                provinceLabel: province.label,
                cityLabel: city ? city.label : '',
                districtLabel: district ? district.label : '',
            },
            errors: { province: false },
        });
    }

    async submitRequest() {
        const { intl:{formatMessage} } = this.props;
        const id = this.props.id
        this.setState({ pending: true });
        // validate the fields in the form
        let valid = true;
        const errors = {};
        ['name', 'mobile', 'addressLine', 'province'].forEach(field => {
        const value =
            field !== 'province'
            ? this.state[field]
            : this.state['location'][field];
        if (
            !value ||
            !value.trim()
        ) {
            errors[field] = true;
            valid = false;
        }
        });
        if (!valid) {
            this.setState({ errors, pending: false });
            Modal.alert(formatMessage({id: 'addressForm-saveFail'}), formatMessage({id: 'addressForm-saveHint'}), [
                { text: formatMessage({id: 'common-close'}) },
            ]);
            return;
        }
        const payload = {
            ecardId: getParameterByName('ecardId'),
            addressLine: this.state.addressLine,
            cityId: this.state.cityId,
            cityLabel: this.state.location.cityLabel,
            districtId: this.state.districtId,
            districtLabel: this.state.location.districtLabel,
            provinceId: this.state.provinceId,
            provinceLabel: this.state.location.provinceLabel,
            userName: this.state.name,
            regions: this.state.regions,
            userMobile: this.state.mobile
        }
        if(id) {
            const currentAddress = this.props.addressList.find(address => address.addressId.toString() === id)
            payload.addressId = currentAddress.addressId
        }
        this.props.credentialRequest(payload)
        
    }

    render(){
        const { regions } = this.state;
        const { intl:{formatMessage}, id, language } = this.props;
        return (
            <div className="address-form">
                {id ? <h2><FormattedMessage id="addressForm-edit" /></h2> : <h2><FormattedMessage id="addressForm-add" /></h2>}
                <List>
                    <InputItem 
                        placeholder={formatMessage({id: 'addressForm-defaultName'})}
                        value={this.state.name}
                        error={this.state.errors.name}
                        onBlur={() => { InputOnBlur() }}
                        onChange={e =>
                            this.setState({
                              name: e,
                              errors: { name: false },
                            })
                        }><List.Item><FormattedMessage id="addressForm-contact" /></List.Item></InputItem>
              <Picker
                title={formatMessage({id: 'setMobile-chooseRegion'})}
                data={language === 'tc' ? region_tc : language === 'en' ? region_en : region_sc}
                cols={1}
                disabled={this.state.pending}
                onOk={regions => this.setState({ regions: regions.toString(), errors: { regions: false } })}
                value={[regions]}
                >
                <List.Item arrow="horizontal">
                  <FormattedMessage
                    id="addressForm-mobile"
                  />
                </List.Item>
              </Picker>
              <InputItem
                type="mobile"
                name="mobile"
                error={this.state.errors.mobile}
                onChange={mobile => this.setState({ mobile, errors: { mobile: false } })}
                value={this.state.mobile}
                onBlur={() => { InputOnBlur() }}
                placeholder={formatMessage({id: 'setMobile-mobileInput'})}>
                {/* <i className="fa fa-mobile fa-lg"></i>
                <span style={{lineHeight: 'normal', paddingRight: '15px'}}>{regions ? `+${regions}` : '+86'}</span> */}
                              <Picker
                // title={formatMessage({id: 'setMobile-chooseRegion'})}
                data={language === 'tc' ? region_tc : language === 'en' ? region_en : region_sc}
                cols={1}
                disabled={this.state.pending}
                onOk={regions => this.setState({ regions: regions.toString(), errors: { regions: false } })}
                value={[regions]}
                />

              </InputItem>
              </List>
              <WhiteSpace size="lg" />
                <List>
                    <Picker
                        extra={formatMessage({id: 'common-select'})}
                        data={areaData}
                        title={formatMessage({id: 'addressForm-selectRegion'})}
                        error={this.state.errors.province}
                        value={[
                            this.state.location.province,
                            this.state.location.city,
                            this.state.location.district,
                        ]}
                        onChange={e => this.selectedLocation(e)}
                    >
                        <List.Item arrow="horizontal"><FormattedMessage id="addressForm-region" /></List.Item>
                        </Picker>
                    <InputItem 
                        placeholder={formatMessage({id: 'addressForm-addressDetail'})}
                        value={this.state.addressLine}
                        error={this.state.errors.addressLine}
                        onBlur={() => { InputOnBlur() }}
                        onChange={e =>
                            this.setState({
                              addressLine: e,
                              errors: { addressLine: false },
                            })
                        }><List.Item><FormattedMessage id="addressForm-address" /></List.Item></InputItem>
                </List>
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WingBlank>
                    <Button type="primary" onClick={() => this.submitRequest()}><FormattedMessage id="cardOptions-buy" /></Button>
                </WingBlank>
            </div>
        )   
    }
}

function mapStateToProps(state, ownProps) {
    const urlStr = ownProps.location.search.split('?')[1]
    const addressId = querystring.parse(urlStr).id
    return {
        language: getCookie('language'),
        id: addressId,
        addressList: getAddressList(state),
    }
}

export default connect(mapStateToProps, { credentialRequest })(injectIntl(AddressFormPage));