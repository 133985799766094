import React from 'react'
import { useLocation } from 'react-router-dom';
import {
  Input,
  TextArea
} from 'antd-mobile-v5'
import { useIntl } from 'react-intl';
import { matchLabel, regionOptions } from '../../components/FormTools/utils'
import { getStoreFullAddress, getParameterByName } from '../../components/common/utils'
import ProgressStatus from './ProgressStatus'
import { useIssueCertsPay } from '../../services/hooks';
import { Form } from 'antd-mobile-v5';
import StudentsList from './StudentsList';
import { getCookie } from '../../components/common/cookie';

// const testData = {
//   "code": 800001,
//   "storeInfoDTO": null,
//   "status": "SUBMIT",
//   "courseDate": "2022-02-25",
//   "countryId": 51,
//   "stateId": 241,
//   "certificationId": 5097,
//   "lastSubmitDate": "2022-03-02",
//   "paymentMethod": null,
//   "paymentType": "YOUZAN",
//   "efrPublicApplicationListItemDTOList": [
//       {
//           "email": "chenyao+940@aragoncs.com",
//           "firstName": "Yao_940",
//           "lastName": "Chen",
//           "userName": "Yao_940 Chen",
//           "gender": "M",
//           "birthDate": "1998-03-11",
//           "addressCountryId": 51,
//           "addressStateId": 225,
//           "address": "Beijing",
//           "status": "SUBMIT"
//       },
//       {
//           "email": "chenyao+943@aragoncs.com",
//           "firstName": "Yao_943",
//           "lastName": "Chen",
//           "userName": "Yao_943 Chen",
//           "gender": "M",
//           "birthDate": "1998-03-11",
//           "addressCountryId": 51,
//           "addressStateId": 225,
//           "address": "Beijing",
//           "status": "SUBMIT"
//       }
//   ]
// }

export default function EFRPublicDetailPage() {
  const type = getParameterByName('type');
  const { formatMessage } = useIntl()
  const { state: { detail } } = useLocation();
  // const detail = testData;
  const lang = getCookie('language');

  const { runAsync: pay } = useIssueCertsPay(type);

  return <>
      <StudentsList students={detail.students} />
      <Form layout='horizontal'>
        <Form.Header>{formatMessage({ id: 'dm-form-training-info' })}</Form.Header>
        <Form.Item label={formatMessage({ id: 'dm-form-instructor-number' })}>
          <Input readOnly value={detail.instructorInfoDTO.instructorNumber} />
        </Form.Item>
        <Form.Item label={formatMessage({ id: 'common-instructor-name' })}>
          <Input readOnly value={detail.instructorInfoDTO.instructorName} />
        </Form.Item>
        {detail.storeInfoDTO && <>
          <Form.Item label={formatMessage({ id: 'dm-form-store-number' })}>
            <Input readOnly value={detail.storeInfoDTO.storeNumber} />
          </Form.Item>
          <Form.Item label={formatMessage({ id: 'certlist-storeName' })}>
            <Input readOnly value={detail.storeInfoDTO.storeName} />
          </Form.Item>
          <Form.Item label={formatMessage({ id: 'form-store-address' })}>
            <TextArea readOnly autoSize value={getStoreFullAddress(detail.storeInfoDTO)} />
          </Form.Item>
        </>}
        <Form.Item label={formatMessage({ id: 'setMobile-regions' })}>
          <Input readOnly value={detail.regionDTO.region} />
        </Form.Item>
        {detail.stateId !== -1 && <Form.Item label={formatMessage({ id: 'common-Province' })}>
          <Input readOnly value={matchLabel(regionOptions[lang], detail.stateId)} />
        </Form.Item>}
        <Form.Item label={formatMessage({ id: 'common-course-date' })}>
          <Input readOnly value={detail.courseDate} />
        </Form.Item>
        </Form>
    <ProgressStatus
      status={detail.status}
      paymentMethod={detail.paymentMethod}
      lastSubmitDate={detail.lastSubmitDate}
      payFn={pay}
      code={detail.code}
      type={type} />
  </>
}