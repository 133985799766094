
import { createAction } from '@reduxjs/toolkit'

export const APP_INITED = 'APP_INITED'

export const REQUEST = 'REQUEST'
export const SUCCESS = 'SUCCESS'
export const FAILURE = 'FAILURE'

export const ENTITY_REQUESTING = 'Requesting'
export const ENTITY_FAILURE = 'Failure'

function action(type, payload = {}) {
  return { type, ...payload }
}

function createRequestTypes(base) {
  return [REQUEST, SUCCESS, FAILURE].reduce((acc, type) => {
    acc[type] = `${base}_${type}`
    return acc
  }, {})
}

export const ECARDS = createRequestTypes('ECARDS')
export const CARDREQUESTOPTIONS = createRequestTypes('CARDREQUESTOPTIONS')
export const SHAREECARDS = createRequestTypes('SHAREECARDS')
export const WECHATUSER = createRequestTypes('WECHATUSER')
export const ECARD = createRequestTypes('ECARD')
export const WECHAT_CONFIG = createRequestTypes('WECHAT_CONFIG')
export const FORGOT_PASSWORD = createRequestTypes('FORGOT_PASSWORD')
export const SUBMIT_FORGOT_PASSWORD = 'SUBMIT_FORGOT_PASSWORD'
export const UPDATE_ROUTER_STATE = 'UPDATE_ROUTER_STATE'
export const NAVIGATE = 'NAVIGATE'
export const LOAD_ECARDS_PAGE = 'LOAD_ECARDS_PAGE'
export const LOAD_ECARD_DETAIL_PAGE = 'LOAD_ECARD_DETAIL_PAGE'
export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE'
export const PAGE_LOADING = 'PAGE_LOADING'
export const PAGE_LOADED = 'PAGE_LOADED'
export const USERINFO = createRequestTypes('USERINFO')
export const LOGIN = 'LOGIN'
export const SET_EMAIL = 'SET_EMAIL'
export const ERRORDIALOG = 'ERRORDIALOG'
export const CODEERROR = 'CODEERROR'
export const SET_LANGUAGE = 'SET_LANGUAGE'
export const SKIP_ROUTER = 'SKIP_ROUTER'

export const REQUEST_MOBILE_CODE = 'REQUEST_MOBILE_CODE'
export const VERIFY_MOBILE_CODE = 'VERIFY_MOBILE_CODE'
export const REQUEST_EMAIL_CODE = 'REQUEST_EMAIL_CODE'
export const VERIFY_EMAIL_CODE = 'VERIFY_EMAIL_CODE'
export const EMAIL_LOGIN = 'EMAIL_LOGIN'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const PROFILE = createRequestTypes('PROFILE')
export const POST_PASSWORD = 'POST_PASSWORD'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const ADD_TO_CART = 'ADD_TO_CART'
export const DELECT_CART_ITEM = 'DELECT_CART_ITEM'
export const CHECKED_ALL = 'CHECKED_ALL'
export const ADD_TO_CHECKED = 'ADD_TO_CHECKED'
export const REMOVE_CHECKED = 'REMOVE_CHECKED'
export const REMOVE_ALL = 'REMOVE_ALL'
export const REPLACE_CARD = 'REPLACE_CARD'
export const SHOPPINGCART = createRequestTypes('SHOPPINGCART')
export const ADDRESSLIST = createRequestTypes('ADDRESSLIST')
export const SUBMIT_ADDRESS = 'SUBMIT_ADDRESS'
export const CREDENTIAL_REQUEST = 'CREDENTIAL_REQUEST'
export const SUBMIT_SUCCESS = 'SUBMIT_SUCCESS'
export const DELECT_ADDRESS = 'DELECT_ADDRESS'
export const DELECT_ADDRESS_SUCCESS = 'DELECT_ADDRESS_SUCCESS'
export const SELECT_ADDRESS = 'SELECT_ADDRESS'
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS'
export const SUBMIT_PIG_CARD = 'SUBMIT_PIG_CARD'
export const HAS_PIG_CARD = createRequestTypes('HAS_PIG_CARD')
export const SUBMIT_ECARD_IMG_BINDING = createRequestTypes('SUBMIT_ECARD_IMG_BINDING')
export const SUBMIT_ECARD_IMG_REBINDING = 'SUBMIT_ECARD_IMG_REBINDING'
export const CANCEL_ECARD_IMG_BINDING = createRequestTypes('CANCEL_ECARD_IMG_BINDING')
export const ECARD_IMG_REBINDING = createRequestTypes('ECARD_IMG_REBINDING')
export const SUBMIT_CANCEL_ECARD_IMG_BINDING = 'SUBMIT_CANCEL_ECARD_IMG_BINDING'
export const TO_CARD_REQUEST_OPTIONS = 'TO_CARD_REQUEST_OPTIONS'
export const REQUEST_HAS_PIG_YEAR_CARD = 'REQUEST_HAS_PIG_YEAR_CARD'
export const REGISTRATION_RESULT = 'REGISTRATION_RESULT'
export const SUBMIT_LIMIT_CARD = 'SUBMIT_LIMIT_CARD'
export const TO_ADD_ADDRESS = 'TO_ADD_ADDRESS'
export const TO_PIG_CARD = 'TO_PIG_CARD'
export const REFRESH_ECARDS = 'REFRESH_ECARDS'
export const NOTICE_DISPLAY = 'NOTICE_DISPLAY'
export const NOTICE_HIDE = 'NOTICE_HIDE'
export const MATCH_ECARDS = 'MATCH_ECARDS'
export const SHOW_TOAST = 'SHOW_TOAST'
export const CLEAR_TOAST = 'CLEAR_TOAST'
export const REQUEST_YOUZAN_STATUS = 'REQUEST_YOUZAN_STATUS'
export const CHECKOUT_YOUZAN_STATUS = createRequestTypes('CHECKOUT_YOUZAN_STATUS')
export const REQUEST_YOUZAN_MOBILE_CODE = 'REQUEST_YOUZAN_MOBILE_CODE'
export const SHOW_ADD_MANAGER = 'SHOW_ADD_MANAGER'
export const HIDE_ADD_MANAGER = 'HIDE_ADD_MANAGER'
export const APPLY_STORE_MANAGER = 'APPLY_STORE_MANAGER'
export const SUBMIT_STORE_NUM = createRequestTypes('SUBMIT_STORE_NUM')
export const REMOVE_STORE_MANAGER = 'REMOVE_STORE_MANAGER'
export const DELETE_STORE = createRequestTypes('DELETE_STORE')
export const REQUEST_PROFILES = 'REQUEST_PROFILES'
export const VERIFY_MOBILE = createRequestTypes('VERIFY_MOBILE')
export const REQUEST_SHOPPING_CART = 'REQUEST_SHOPPING_CART'
export const SUBMIT_SHOPPING_CART = createRequestTypes('SUBMIT_SHOPPING_CART')
export const GET_ORDER_YOUZAN_URL = createRequestTypes('GET_ORDER_YOUZAN_URL')
export const REQUEST_ORDER_YOUZAN_URL = 'REQUEST_ORDER_YOUZAN_URL'
export const GO_TO_YOUZAN = 'GO_TO_YOUZAN'
export const REQUEST_CARD_OPTIONS_PAGE = 'REQUEST_CARD_OPTIONS_PAGE'
export const SUBMIT_MEMBER_RENEW = 'SUBMIT_MEMBER_RENEW'
export const SUBMIT_STORE_RENEW = 'SUBMIT_STORE_RENEW'
export const REQUEST_MEMBER_RENEW = 'REQUEST_MEMBER_RENEW'
export const REQUEST_STORE_RENEW = 'REQUEST_STORE_RENEW'
export const MEMBER_RENEW = createRequestTypes('MEMBER_RENEW')
export const STORE_RENEW = createRequestTypes('STORE_RENEW')
export const MEMBER_RENEW_YOUZAN_URL = createRequestTypes('MEMBER_RENEW_YOUZAN_URL')
export const STORE_RENEW_YOUZAN_URL = createRequestTypes('STORE_RENEW_YOUZAN_URL')
export const CLEAR_CARDREQUEST_INFO = 'CLEAR_CARDREQUEST_INFO'
export const ADD_ECARD_DATA = 'ADD_ECARD_DATA'
export const REQUEST_WECHAT_USER = 'REQUEST_WECHAT_USER'
export const QUESTIONNAIRE = createRequestTypes('QUESTIONNAIRE')
export const RESET_APP = 'RESET_APP'
export const SAVE_WECHAT_JS_CONFIG = 'SAVE_WECHAT_JS_CONFIG'
export const ELEARNING_URL = createRequestTypes('ELEARNING_URL')
export const SUBMIT_BOOK_SALE_LIST = 'SUBMIT_BOOK_SALE_LIST'
export const TEXT_BOOK_SALE_URL = createRequestTypes('TEXT_BOOK_SALE_URL')
export const REQUEST_MEMBER_LIBRARY = 'REQUEST_MEMBER_LIBRARY'
export const REQUEST_QUALIFICATION = 'REQUEST_QUALIFICATION'
export const SKIP_PATH = 'SKIP_PATH'
export const INSTRUCTOR_APPROVED = 'INSTRUCTOR_APPROVED'
export const SAVE_PROFILE = createRequestTypes('SAVE_PROFILE')
export const CD_SUBMIT_SI = 'CD_SUBMIT_SI'
export const CD_SUBMIT_APP = createRequestTypes('CD_SUBMIT_APP')
export const REFRESH_TOKEN = createRequestTypes('REFRESH_TOKEN')
export const POST_PROFILE = 'POST_PROFILE'
export const SUBMIT_DSD = 'SUBMIT_DSD'
export const SUBMIT_DSD_REQUEST = createRequestTypes('SUBMIT_DSD_REQUEST')
export const REQUEST_DSD_EMAIL_CODE = 'REQUEST_DSD_EMAIL_CODE'

export const login = (credential) => action(LOGIN, credential)

export const requestMobileCode = (data) => action(REQUEST_MOBILE_CODE, data)

export const verifyMobileCode = (data) => action(VERIFY_MOBILE_CODE, { data })

export const requestEmailCode = (data) => action(REQUEST_EMAIL_CODE, data)

export const verifyEmailCode = (data) => action(VERIFY_EMAIL_CODE, { data })

export const emailLogin = (data) => action(EMAIL_LOGIN, data)

export const toCardRequestOptions = (data) => action(TO_CARD_REQUEST_OPTIONS, { data })

export const resetPassword = (data) => action(RESET_PASSWORD, data)

export const postPassword = (data) => action(POST_PASSWORD, { data })

export const postProfile = (data) => action(POST_PROFILE, { data })

export const loginSuccess = () => action(LOGIN_SUCCESS)

export const submitForgotPassword = (data) => action(SUBMIT_FORGOT_PASSWORD, data)

export const addToCart = (data) => action(ADD_TO_CART, { data })

export const delectCartItem = (data) => action(DELECT_CART_ITEM, { data })

export const checkedAll = (data) => action(CHECKED_ALL, { data })

export const removeAll = () => action(REMOVE_ALL)

export const addToChecked = (data) => action(ADD_TO_CHECKED, { data })

export const removeChecked = (data) => action(REMOVE_CHECKED, { data })

export const replaceCard = (data) => action(REPLACE_CARD, { data })

export const submitAddress = (data) => action(SUBMIT_ADDRESS, { data })

export const credentialRequest = (data) => action(CREDENTIAL_REQUEST, { data })

export const submitSuccess = () => action(SUBMIT_SUCCESS)

export const delectAddress = (data) => action(DELECT_ADDRESS, { data })

export const delectAddressSuccess = (data) => action(DELECT_ADDRESS_SUCCESS, { data })

export const selectAddress = (data) => action(SELECT_ADDRESS, { data })

export const addToCartSuccess = (data) => action(ADD_TO_CART_SUCCESS, { data })

export const submitPigCard = (data) => action(SUBMIT_PIG_CARD, { data })

export const submitCancelEcardImageBinding = (data) => action(SUBMIT_CANCEL_ECARD_IMG_BINDING, { data })

export const submitEcardImageReBinding = (data) => action(SUBMIT_ECARD_IMG_REBINDING, { data })

export const requestHasPigYearCard = () => action(REQUEST_HAS_PIG_YEAR_CARD)

export const registrationResult = (data) => action(REGISTRATION_RESULT, { data })

export const submitLimitCard = (data) => action(SUBMIT_LIMIT_CARD, { data })

export const toAddAddress = (data) => action(TO_ADD_ADDRESS, { data })

export const toPigCard = (data) => action(TO_PIG_CARD, { data })

export const refreshEcards = (data) => action(REFRESH_ECARDS, { data })

export const matchEcards = (data) => action(MATCH_ECARDS, { data })

export const requestYouzanStatus = () => action(REQUEST_YOUZAN_STATUS)

export const requestYouzanMobileCode = (data) => action(REQUEST_YOUZAN_MOBILE_CODE, { data })

export const showAddManager = () => action(SHOW_ADD_MANAGER)

export const hideAddManager = () => action(HIDE_ADD_MANAGER)

export const applyStoreManager = (data) => action(APPLY_STORE_MANAGER, { data })

export const removeStoreManager = () => action(REMOVE_STORE_MANAGER)

export const requestProfiles = () => action(REQUEST_PROFILES)

export const requestShoppingCart = () => action(REQUEST_SHOPPING_CART)

export const requestOrderYouzanUrl = (data) => action(REQUEST_ORDER_YOUZAN_URL, { data })

export const goToYouzan = () => action(GO_TO_YOUZAN)

export const requestCardOptionsPage = (data) => action(REQUEST_CARD_OPTIONS_PAGE, { data })

export const submitMemberRenew = (data) => action(SUBMIT_MEMBER_RENEW, { data })

export const submitStoreRenew = (data) => action(SUBMIT_STORE_RENEW, { data })

export const requestMemberRenew = () => action(REQUEST_MEMBER_RENEW)

export const requestStoreRenew = () => action(REQUEST_STORE_RENEW)

export const addEcardData = (data) => action(ADD_ECARD_DATA, { data })

export const requestWechatUser = () => action(REQUEST_WECHAT_USER)

export const submitBookSaleList = (data) => action(SUBMIT_BOOK_SALE_LIST, { data })

export const requestMemberLibrary = () => action(REQUEST_MEMBER_LIBRARY)

export const requestQualification = () => action(REQUEST_QUALIFICATION)

export const instructorApproved = (data) => action(INSTRUCTOR_APPROVED, { data })

export const cdSubmitSiApp = (data) => action(CD_SUBMIT_SI, { data })

export const submitDsd = (data) => action(SUBMIT_DSD, { data })

export const requestDsdEmailCode = (data) => action(REQUEST_DSD_EMAIL_CODE, data)

export const submitDsdRequest = {
  request: (data) => action(SUBMIT_DSD_REQUEST[REQUEST], data),
  success: (data) => action(SUBMIT_DSD_REQUEST[SUCCESS], { data }),
  failure: (error) => action(SUBMIT_DSD_REQUEST[FAILURE], { error }),
}

export const forgotPassword = {
  request: (data) => action(FORGOT_PASSWORD[REQUEST], data),
  success: (data) => action(FORGOT_PASSWORD[SUCCESS], { data }),
  failure: (error) => action(FORGOT_PASSWORD[FAILURE], { error }),
}

export const userInfo = {
  request: () => action(USERINFO[REQUEST]),
  success: (data) => action(USERINFO[SUCCESS], { data }),
  failure: (error) => action(USERINFO[FAILURE], { error }),
}

export const ecards = {
  request: () => action(ECARDS[REQUEST]),
  success: (data) => action(ECARDS[SUCCESS], { data }),
  failure: (error) => action(ECARDS[FAILURE], { error }),
}

export const cardRequestOptions = {
  request: (id) => action(CARDREQUESTOPTIONS[REQUEST]),
  success: (data) => action(CARDREQUESTOPTIONS[SUCCESS], { data }),
  failure: (error) => action(CARDREQUESTOPTIONS[FAILURE], { error }),
}

export const shareEcards = {
  request: () => action(SHAREECARDS[REQUEST]),
  success: (data) => action(SHAREECARDS[SUCCESS], { data }),
  failure: (error) => action(SHAREECARDS[FAILURE], { error }),
}

export const wechatUser = {
  request: () => action(WECHATUSER[REQUEST]),
  success: (data) => action(WECHATUSER[SUCCESS], { data }),
  failure: (error) => action(WECHATUSER[FAILURE], { error }),
}

export const ecard = {
  request: (type, id) => action(ECARD[REQUEST]),
  success: (data) => action(ECARD[SUCCESS], { data }),
  failure: (error) => action(ECARD[FAILURE], { error }),
}

export const profile = {
  request: () => action(PROFILE[REQUEST]),
  success: (data) => action(PROFILE[SUCCESS], { data }),
  failure: (error) => action(PROFILE[FAILURE], { error }),
}

export const shoppingCart = {
  request: () => action(SHOPPINGCART[REQUEST]),
  success: (data) => action(SHOPPINGCART[SUCCESS], { data }),
  failure: (error) => action(SHOPPINGCART[FAILURE], { error })
}

export const addressList = {
  request: () => action(ADDRESSLIST[REQUEST]),
  success: (data) => action(ADDRESSLIST[SUCCESS], { data }),
  failure: (error) => action(ADDRESSLIST[FAILURE], { error })
}

export const hasPigYearCard = {
  request: () => action(HAS_PIG_CARD[REQUEST]),
  success: (data) => action(HAS_PIG_CARD[SUCCESS], { data }),
  failure: (error) => action(HAS_PIG_CARD[FAILURE], { error })
}

export const submitEcardImgBinding = {
  request: () => action(SUBMIT_ECARD_IMG_BINDING[REQUEST]),
  success: (data) => action(SUBMIT_ECARD_IMG_BINDING[SUCCESS], { data }),
  failure: (error) => action(SUBMIT_ECARD_IMG_BINDING[FAILURE], { error })
}

export const cancelEcardImgBinding = {
  request: () => action(CANCEL_ECARD_IMG_BINDING[REQUEST]),
  success: (data) => action(CANCEL_ECARD_IMG_BINDING[SUCCESS], { data }),
  failure: (error) => action(CANCEL_ECARD_IMG_BINDING[FAILURE], { error })
}

export const ecardImgReBinding = {
  request: () => action(ECARD_IMG_REBINDING[REQUEST]),
  success: (data) => action(ECARD_IMG_REBINDING[SUCCESS], { data }),
  failure: (error) => action(ECARD_IMG_REBINDING[FAILURE], { error })
}

export const checkoutYouzanStatus = {
  request: () => action(CHECKOUT_YOUZAN_STATUS[REQUEST]),
  success: (data) => action(CHECKOUT_YOUZAN_STATUS[SUCCESS], { data }),
  failure: (error) => action(CHECKOUT_YOUZAN_STATUS[FAILURE], { error })
}

export const submitStoreNum = {
  request: () => action(SUBMIT_STORE_NUM[REQUEST]),
  success: (data) => action(SUBMIT_STORE_NUM[SUCCESS], { data }),
  failure: (error) => action(SUBMIT_STORE_NUM[FAILURE], { error })
}

export const deleteStore = {
  request: () => action(DELETE_STORE[REQUEST]),
  success: (data) => action(DELETE_STORE[SUCCESS], { data }),
  failure: (error) => action(DELETE_STORE[FAILURE], { error })
}

export const verifyMobile = {
  request: () => action(VERIFY_MOBILE[REQUEST]),
  success: (data) => action(VERIFY_MOBILE[SUCCESS], { data }),
  failure: (error) => action(VERIFY_MOBILE[FAILURE], { error })
}

export const submitShoppingCart = {
  request: () => action(SUBMIT_SHOPPING_CART[REQUEST]),
  success: (data) => action(SUBMIT_SHOPPING_CART[SUCCESS], { data }),
  failure: (error) => action(SUBMIT_SHOPPING_CART[FAILURE], { error })
}

export const getOrderYouzanUrl = {
  request: () => action(GET_ORDER_YOUZAN_URL[REQUEST]),
  success: (data) => action(GET_ORDER_YOUZAN_URL[SUCCESS], { data }),
  failure: (error) => action(GET_ORDER_YOUZAN_URL[FAILURE], { error })
}

export const memberRenew = {
  request: () => action(MEMBER_RENEW[REQUEST]),
  success: (data) => action(MEMBER_RENEW[SUCCESS], { data }),
  failure: (error) => action(MEMBER_RENEW[FAILURE], { error })
}

export const storeRenew = {
  request: () => action(STORE_RENEW[REQUEST]),
  success: (data) => action(STORE_RENEW[SUCCESS], { data }),
  failure: (error) => action(STORE_RENEW[FAILURE], { error })
}

export const memberRenewYouzanUrl = {
  request: () => action(MEMBER_RENEW_YOUZAN_URL[REQUEST]),
  success: (data) => action(MEMBER_RENEW_YOUZAN_URL[SUCCESS], { data }),
  failure: (error) => action(MEMBER_RENEW_YOUZAN_URL[FAILURE], { error })
}

export const storeRenewYouzanUrl = {
  request: () => action(STORE_RENEW_YOUZAN_URL[REQUEST]),
  success: (data) => action(STORE_RENEW_YOUZAN_URL[SUCCESS], { data }),
  failure: (error) => action(STORE_RENEW_YOUZAN_URL[FAILURE], { error })
}

export const questionnaire = {
  request: () => action(QUESTIONNAIRE[REQUEST]),
  success: (data) => action(QUESTIONNAIRE[SUCCESS], { data }),
  failure: (error) => action(QUESTIONNAIRE[FAILURE], { error })
}

export const elearningUrl = {
  request: () => action(ELEARNING_URL[REQUEST]),
  success: (data) => action(ELEARNING_URL[SUCCESS], { data }),
  failure: (error) => action(ELEARNING_URL[FAILURE], { error })
}

export const textBookSaleUrl = {
  request: () => action(TEXT_BOOK_SALE_URL[REQUEST]),
  success: (data) => action(TEXT_BOOK_SALE_URL[SUCCESS], { data }),
  failure: (error) => action(TEXT_BOOK_SALE_URL[FAILURE], { error })
}

export const saveProfile = {
  request: () => action(SAVE_PROFILE[REQUEST]),
  success: (data) => action(SAVE_PROFILE[SUCCESS], { data }),
  failure: (error) => action(SAVE_PROFILE[FAILURE], { error })
}

export const cdSubmitApp = {
  request: (data) => action(CD_SUBMIT_APP[REQUEST], data),
  success: (data) => action(CD_SUBMIT_APP[SUCCESS], { data }),
  failure: (error) => action(CD_SUBMIT_APP[FAILURE], { error }),
}

export const refreshToken = {
  request: (data) => action(REFRESH_TOKEN[REQUEST], data),
  success: (data) => action(REFRESH_TOKEN[SUCCESS], { data }),
  failure: (error) => action(REFRESH_TOKEN[FAILURE], { error }),
}

export const pageLoading = () => action(PAGE_LOADING)
export const pageLoaded = () => action(PAGE_LOADED)
export const loadEcards = () => action(LOAD_ECARDS_PAGE)
export const resetErrorMessage = () => action(RESET_ERROR_MESSAGE)
export const errorDialog = (error) => action(ERRORDIALOG, { error })
export const codeError = () => action(CODEERROR)
export const skipRouter = (router) => action(SKIP_ROUTER, { router })
export const noticeDisplay = (mes) => action(NOTICE_DISPLAY, { mes })
export const noticeHide = () => action(NOTICE_HIDE)
export const showToast = (mes) => action(SHOW_TOAST, { mes })
export const clearToast = () => action(CLEAR_TOAST)
export const clearCardRequestInfo = () => action(CLEAR_CARDREQUEST_INFO)
export const resetApp = () => action(RESET_APP)
export const saveWechatJsConfig = (config) => action(SAVE_WECHAT_JS_CONFIG, { config })
export const skipPath = (path) => action(SKIP_PATH, { path })
