import React from 'react';
import { WhiteSpace } from 'antd-mobile';
import { FormattedMessage } from 'react-intl';

import officialQrcode from '../../assets/images/official_qrcode.jpg';



class OfficialQrCodeWrapper extends React.Component {
  render() {
    return (
      <div className="official-wrapper">
        <img src={require('../../assets/images/close.png')} alt='close' className='close-icon' onClick={() => this.props.toogleShowOfficalQrCode()} />
        <div className="official-content">
          <div className="QRCode-img">
            <img src={officialQrcode} alt="PADI QRCODE" width="30%" />
          </div>
          <WhiteSpace size="xl" />
          <WhiteSpace size="xl" />
          <p className="hint"><FormattedMessage id="qrCodeWrapper-attention" /></p>
          <p className="hint"><FormattedMessage id="qrCodeWrapper-getMoreMsg" /></p>
          <p className="hint"><FormattedMessage id="qrCodeWrapper-service" /></p>
          <WhiteSpace size="xl" />
          <p className="hint hint-text"><FormattedMessage id="qrCodeWrapper-dev" /></p>
          <p className="hint hint-text"><FormattedMessage id="qrCodeWrapper-Authorize" /></p>
          <p className="hint hint-text"><FormattedMessage id="qrCodeWrapper-belongToPADI" /></p>
          <WhiteSpace size="xl" />
          <WhiteSpace size="xl" />
          <WhiteSpace size="xl" />
        </div>
      </div>
    );
  }
}

export default OfficialQrCodeWrapper;
