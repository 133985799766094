import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl';
import AgreementCheckbox from '../common/AgreementCheckbox'
import { useLanguage } from '../../services/hooks/user';
import { Modal } from 'antd-mobile-v5'

const AgreementPopup = ({ visible, setVisible, toPay }) => {
  const { formatMessage } = useIntl()
  const lang = useLanguage();
  const [checked, setChecked] = useState(false)
  return <Modal
    visible={visible}
    title={formatMessage({ id: "common-return-title" })}
    content={
      <AgreementCheckbox
        checked={checked}
        setChecked={(checked) => setChecked(checked)}>
        <FormattedMessage id="common-return-agreement1" />
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={'https://www.padi.com.tw/zh-hant/privacy'}
        >
          <FormattedMessage id="common-return-agreement2" />
        </a>
        <FormattedMessage id="common-return-and" />
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={`https://assets.padi.com.cn/return_agreement_${lang}.html`}
        >
          <FormattedMessage id="common-return-agreement3" />
        </a>
      </AgreementCheckbox>
    }
    closeOnAction
    onClose={() => {
      setVisible(false)
    }}
    actions={[
      {
        key: 'confirm',
        text: formatMessage({ id: "common-comfirmBtn" }),
        primary: true,
        disabled: !checked,
        onClick: () => toPay()
      },
      {
        text: formatMessage({ id: "common-cancel" }),
      }
    ]}
  />
}

export default AgreementPopup