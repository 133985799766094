import React, { Component } from 'react';
import { Button, WhiteSpace } from 'antd-mobile';
import { injectIntl, FormattedMessage } from 'react-intl';

class StoreRenew extends Component {

  render() {
    const { storeRenewList } = this.props
    return JSON.stringify(storeRenewList) !== "{}" &&<div className='renew'>
    <WhiteSpace size="xl" />
    <h2><FormattedMessage id='renew-store-title' /></h2>
    <WhiteSpace size="xl" />
    <div className='checkbox-list'>
      {/* <p className='subtitle'><FormattedMessage id='renew-choose-item' /></p> */}
      <div>{storeRenewList.renewalName}</div>
    </div> 
    <div className='renew-footer'>
      <Button type="ghost" inline className='renew-footer-btn am-button-borderfix' onClick={() => this.props.push('/myProfile')}><FormattedMessage id='renew-back' /></Button>
      <Button type="primary" inline className='renew-footer-btn' onClick={() => this.props.submitStoreRenew({goodsId: storeRenewList.goodsId})}><FormattedMessage id='common-confirmRequest' /></Button>
    </div>
  </div>
  }
}

export default injectIntl(StoreRenew)