import * as ActionTypes from '../actions'
import { combineReducers } from 'redux'
import { getCookie } from '../components/common/cookie';
import { SUCCESS } from '../actions'

function errorMsgIntl() {
  const lang = getCookie('language')
  switch (lang) {
    case 'sc':
      return '系统出错，请稍后再试，谢谢您的理解。'
    case 'tc':
      return '系統出錯，請稍後再試，謝謝您的理解。'
    case 'en':
      return 'Error message, Please try it later. Thanks for your understanding.'
    default:
      return '系统出错，请稍后再试，谢谢您的理解。'
  }
}

function pageLoading(pageLoading = false, { type }) {
  switch (type) {
    case ActionTypes.PAGE_LOADING:
      return true
    case ActionTypes.PAGE_LOADED:
      return false
    default:
      return pageLoading
  }
}

// Updates error message to notify about the failed fetches.
function errorMessage(state = null, action) {
  const { type, error } = action

  if (type === ActionTypes.RESET_ERROR_MESSAGE) {
    return null
  } else if (/_FAILURE$/.test(type)) {
    return error.clientMsg
  } else if (type === ActionTypes.ERRORDIALOG) {
    return error ? error : errorMsgIntl()
  } else if (error) {
    return action.error
  }
  return state
}

// function language(state = null, { type }) {
//   const languageCookie = getCookie('language');
//   switch (type) {
//     case ActionTypes.SET_LANGUAGE:
//       return languageCookie
//     default:
//       return languageCookie
//   }
// }

function skipRouter(state = null, action) {
  const { type, router } = action
  if (type === ActionTypes.SKIP_ROUTER) {
    return router
  } else if (type === ActionTypes.RESET_ERROR_MESSAGE) {
    return null
  }
  return state
}

function skipPath(state = null, action) {
  const { type, path } = action
  if (type === ActionTypes.SKIP_PATH) {
    return path
  } else if (type === ActionTypes.RESET_ERROR_MESSAGE) {
    return null
  }
  return state
}

function noticeMessage(state = '', { type, mes }) {
  switch (type) {
    case ActionTypes.NOTICE_DISPLAY:
      return mes
    case ActionTypes.NOTICE_HIDE:
      return ''
    default:
      return state
  }
}

function toastMessage(state = '', { type, mes }) {
  switch (type) {
    case ActionTypes.SHOW_TOAST:
      return mes
    case ActionTypes.CLEAR_TOAST:
      return ''
    default:
      return state
  }
}

function wechatJsConfig(state = {}, { type, config }) {
  switch (type) {
    case ActionTypes.SAVE_WECHAT_JS_CONFIG:
      return config || {}
    default:
      return state
  }
}

const commonReducer = combineReducers({
  pageLoading,
  errorMessage,
  // language,
  skipRouter,
  noticeMessage,
  toastMessage,
  wechatJsConfig,
  skipPath
})

export default commonReducer