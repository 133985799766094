import React from 'react';
import { TabBar } from 'antd-mobile-v5';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { BankcardOutline, UserOutline } from 'antd-mobile-icons';
import { useDispatch } from 'react-redux';
import { goToYouzan } from '../actions';

export default function BottomNav({ isMainlandUser }) {
  const { formatMessage } = useIntl();
  const { push } = useHistory();
  const { pathname, search } = useLocation();
  const dispatch = useDispatch();

  if (pathname === '/members' || pathname === '/ecards' || ~search.indexOf('no_nav')) {
    return <div></div>;
  }

  return (
    <TabBar
      activeKey={pathname}
      onChange={(value) => {
        if (value === 'youzan') {
          dispatch(goToYouzan());
        } else {
          push(value);
        }
      }}
      style={{
        position: 'fixed',
        width: '100%',
        maxWidth: '600px',
        bottom: 0,
        backgroundColor: 'white',
        borderTop: '1px solid #ddd',
        zIndex: 999,
      }}
    >
      <TabBar.Item
        key="/campaign"
        title={formatMessage({ id: 'navi-campaign' })}
        icon={<i className="icon fa fa-bullhorn" />}
      ></TabBar.Item>
      <TabBar.Item
        title={formatMessage({ id: 'home' })}
        key="/certs"
        icon={<BankcardOutline />}
        onPress={() => push('/certs')}
      ></TabBar.Item>
      {isMainlandUser && (
        <TabBar.Item
          title={<span style={{ color: '#ea3340' }}>成为海洋守蔚者</span>}
          key="/buy_cards"
          icon={
            <img
              src={require('../assets/images/cards/torcher.png')}
              alt="active"
              style={{ height: '22px', marginBottom: '8px' }}
            />
          }
          onPress={() => push('/buy_cards')}
        />
      )}
      <TabBar.Item
        title={formatMessage({ id: 'navi-store' })}
        key="youzan"
        icon={<i className="icon fa fa-thumbs-o-up" />}
        // onPress={() => props.goToYouzan()}
      ></TabBar.Item>

      <TabBar.Item
        title={formatMessage({ id: 'profile' })}
        key="/myProfile"
        icon={<UserOutline />}
        onPress={() => push('/myProfile')}
      ></TabBar.Item>
    </TabBar>
  );
}
