import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl';
import AgreementCheckbox from '../../components/common/AgreementCheckbox'
import { getCookie } from '../../components/common/cookie';

const mappingLang = {
  'sc': 'SC',
  'tc': 'TC',
  'en': 'EN'
}

export default function Agreement({ changeChecked }) {

  const lang = getCookie('language');
  const [agreementChecked, setAgreementChecked] = useState(false)
  const [criminalChecked, setCriminalChecked] = useState(false)

  useEffect(() => {
    changeChecked(agreementChecked && criminalChecked)
  }, [agreementChecked, criminalChecked])

  return <>
  <AgreementCheckbox setChecked={(checked) => setCriminalChecked(checked)} checked={criminalChecked}>
      <FormattedMessage 
        id="owsi-criminal-record-statement"
      />
    </AgreementCheckbox>
    <AgreementCheckbox setChecked={(checked) => setAgreementChecked(checked)} checked={agreementChecked}>
      <FormattedMessage 
        id="owsi-agreement-text"
        values={{
          agreement: (
            <a 
              href={`http://assets.padi.com.cn/agreements/725DT_Instructor_Membership_License_Agreement_Long_Form_2021_${mappingLang[lang]}.pdf`} 
              rel="noreferrer noopener" 
              target="_blank">
              <FormattedMessage id="owsi-agreement" />
            </a>
          )
        }} 
      />
    </AgreementCheckbox>
  </>
}