import React from 'react'
import { Accordion } from 'antd-mobile';

import styles from './DiveMasterForm.module.css'

const CardLayout = ({ title, status, children }) => <Accordion defaultActiveKey="0">
  <Accordion.Panel header={<div className={styles.title}>
      <span>{title}</span>
      {status !== "none" && <img src={require(`../../assets/images/${status ? "complete" : "warning"}.png`)} className={styles['title-icon']} />}
    </div>}>
    {children}
  </Accordion.Panel>
</Accordion>

export default CardLayout