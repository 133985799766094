
import React from 'react'
import { connect } from 'react-redux'
import { getEcards, getUserInfo, getWechatUser, getProfile, getNextCerts } from '../../reducers/selectors'
import EcardsList from '../../components/EcardsList/EcardsList';
import './EcardsPage.css';

const EcardsPage = ({ ecards, userInfo, wechatUser, profile, nextCerts }) => userInfo && ecards ? 
      <EcardsList 
        wechatUser={wechatUser} 
        ecards={ecards} 
        userInfo={userInfo} 
        profile={profile} 
        nextCerts={nextCerts} /> : null
  
function mapStateToProps(state) {
  return {
    ecards: getEcards(state),
    userInfo: getUserInfo(state),
    wechatUser: getWechatUser(state),
    profile: getProfile(state),
    nextCerts: getNextCerts(state),
  }
}

export default connect(mapStateToProps, null)(EcardsPage)
