import { connect } from 'react-redux';
import { List, WhiteSpace, Tag } from 'antd-mobile';
import React from 'react';

import { requestMemberRenew } from '../../actions';
import { FormattedMessage } from 'react-intl';
import { isMainlandPro } from '../../../src/utils/user';
import AppMenus from './AppMenus';
import CoursePicMenu from './CoursePicMenu';
import CourseInsuranceMenu from './CourseInsuranceMenu';
import HonorMenu from './HonorMenu';

import './MyProfile.css';
import { useHistory } from 'react-router-dom';

const MemberMenu = ({ profiles, requestMemberRenew }) => {
  const { push } = useHistory();

  if (!profiles.member) return <AppMenus {...profiles} />;

  return (
    <>
      <List className="profile-list member-renew">
        {/**
         * Member Renewal
         * memberRenewal
         * -1: not member,
         * 0: renewed,
         * 1: not renewed,
         * 2: in progress,
         */}
        <List.Item
          arrow={profiles.memberRenewal === 1 && 'horizontal'}
          extra={
            profiles.memberRenewal === 2 ? (
              <Tag small className="apply-tag">
                <FormattedMessage id="renew-status-in-progress" />
              </Tag>
            ) : profiles.memberRenewal === 0 ? (
              <Tag small className="apply-tag">
                <FormattedMessage id="renew-status-ok" />
              </Tag>
            ) : null
          }
          onClick={() => profiles.memberRenewal === 1 && requestMemberRenew()}
        >
          <i className="icon fa fa-user-circle-o fa-lg fa-fw"></i>
          <FormattedMessage id="renew-member-title" />
        </List.Item>

        {/**
         * Resources for member
         */}
        <List.Item
          arrow="horizontal"
          onClick={() =>
            (window.location = 'https://pro.padi.com/me/training-hub')
          }
        >
          <i className="fa fa-folder-open-o fa-lg fa-fw"></i>
          <FormattedMessage id="resources-training-hub" />
        </List.Item>
        <List.Item
          arrow="horizontal"
          onClick={() => (window.location = 'https://pro.padi.com/marketing')}
        >
          <i className="fa fa-folder-open-o fa-lg fa-fw"></i>
          <FormattedMessage id="resources-marketing-hub" />
        </List.Item>
        {profiles.teachingLocation === 'TW' && (
          <List.Item arrow="horizontal" onClick={() => push('/invoice_form')}>
            發票資訊維護
          </List.Item>
        )}
      </List>

      <WhiteSpace size="sm" />

      {/** 购买签证 */}
      <CoursePicMenu profiles={profiles} />
      <WhiteSpace size="sm" />

      {/** 携保 */}
      {isMainlandPro(profiles) && (
        <CourseInsuranceMenu
          isStore={profiles.storeInfo}
          isInstructor={profiles.instructorInfo}
        />
      )}

      {/** 会员申请 */}
      <AppMenus {...profiles} />
      <WhiteSpace size="sm" />

      {/** 个人荣誉 */}
      <HonorMenu />
      <WhiteSpace size="sm" />

    </>
  );
};

export default connect(null, {
  requestMemberRenew,
})(MemberMenu);
