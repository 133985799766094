import { Form, DatePicker } from 'antd-mobile-v5';
import { useIntl } from 'react-intl';
import React, { useState } from 'react';

import { formatDate, IsNotPc } from '../common/utils';

import styles from './tools.module.css';

const DatePickerTool = (props) => {
  const { name, rules, label, max, min, disabled, placeholder } = props;

  const [visible, setVisible] = useState(false);
  const { formatMessage } = useIntl();

  return IsNotPc() ? (
    <Form.Item
      label={label}
      name={name}
      rules={rules}
      arrow={false}
      trigger="onConfirm"
      onClick={() => setVisible(!disabled)}
      {...props}
    >
      <DatePicker
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        min={min}
        max={max}
      >
        {(value) =>
          value ? (
            formatDate(value, 'YYYY-MM-DD')
          ) : (
            <span className={styles.picker}>
              {placeholder}
            </span>
          )
        }
      </DatePicker>
    </Form.Item>
  ) : (
    <Form.Item name={name} rules={rules} label={label}>
      <input
        type="date"
        placeholder={placeholder}
        max={max ? formatDate(max, 'YYYY-MM-DD') : ''}
        min={min ? formatDate(min, 'YYYY-MM-DD') : ''}
        disabled={disabled}
        className={styles['date-picker']}
      />
    </Form.Item>
  );
};

export default DatePickerTool;
