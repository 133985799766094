import React, { useState } from 'react';
import { Form, Button, Input, List } from 'antd-mobile-v5';
import { useIntl, FormattedMessage } from 'react-intl';
import { useInstructorSearch } from '../../services/hooks';

const AddInstructor = ({
  form,
  onSearchResult,
  initialInstructor = { instructorName: '' },
  type,
}) => {
  const { formatMessage } = useIntl();
  const [instructor, setInstructor] = useState(initialInstructor);
  const { loading, run } = useInstructorSearch({
    onSuccess: (data) => {
      onSearchResult(data);
      form.setFields([
        {
          name: 'instructorNumber',
          errors: [],
        },
      ]);
      setInstructor(data);
    },
    onError: (error) => {
      if (error.clientMsg) {
        form.setFields([
          {
            name: 'instructorNumber',
            errors: [error.clientMsg],
          },
        ]);
        setInstructor({});
        return true;
      } else {
        return false;
      }
    },
  });
  const search = () => {
    const instructorNumber = form.getFieldValue('instructorNumber');
    if (!instructorNumber) {
      form.setFields([
        {
          name: 'instructorNumber',
          errors: [formatMessage({ id: 'dm-form-instructor-warning' })],
        },
      ]);
      return;
    }
    if (!/^\d+$/.test(instructorNumber)) {
      form.setFields([
        {
          name: 'instructorNumber',
          errors: [formatMessage({ id: 'dm-form-instructor-warning' })],
        },
      ]);
      return;
    }
    form.setFields([
      {
        name: 'instructorNumber',
        errors: [],
      },
    ]);
    run(instructorNumber, type);
  };

  return (
    <>
      <Form.Item
        label={formatMessage({ id: 'dm-form-instructor-number' })}
        required
        name="instructorNumber"
        extra={
          <Button
            size="small"
            color="primary"
            fill="none"
            loading={loading}
            onClick={search}
            loadingText={formatMessage({ id: 'common-search-loading' })}
          >
            <FormattedMessage id="common-search-title" />
          </Button>
        }
      >
        <Input
          placeholder={formatMessage({ id: 'dm-form-instructor-warning' })}
        />
      </Form.Item>
      {instructor.instructorName && (
        <Form.Item label={formatMessage({ id: 'common-instructor-name' })}>
          {instructor.instructorName}
        </Form.Item>
      )}
    </>
  );
};

export default AddInstructor;
