import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { WingBlank } from 'antd-mobile';
import UploadOssFile from '../../components/common/UploadOssFile'
import UploadFileWrapper from '../../components/common/UploadFileWrapper'
import { uploadAFDIOssFile, removeAFDIFile } from '../../reducers/afdi'

const FdiAndPrepare = ({ fdiFiles, editable, fdipreFiles }) => {

  const { ossSignature } = useSelector((state) => ({
    ossSignature: state.oss.ossSignature
  }));

  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const filename = (type) => `${ossSignature.dir}${type}/${new Date().getTime()}`

  return <>
    <UploadFileWrapper 
      labelName={formatMessage({ id: 'fdi-not-padi-cert' })} 
      editable={editable}>
      <UploadOssFile
        fileList={fdiFiles || []}
        fileName={filename('fdi')}
        onChange={(formData) => {
          dispatch(uploadAFDIOssFile({ formData }))
        }}
        onDelete={(index) => dispatch(removeAFDIFile(fdiFiles[index]))}
        editable={editable} />
      {editable && <WingBlank>
        <div className="small-font gray">
          <FormattedMessage id="common-upload-file-reminder" />
        </div>
      </WingBlank>}
    </UploadFileWrapper>
    <UploadFileWrapper 
      labelName={formatMessage({ id: 'mermaid-other-files' })} 
      editable={editable}>
      <UploadOssFile
        fileList={fdipreFiles || []}
        fileName={filename('prepare')}
        onChange={(formData) => {
          dispatch(uploadAFDIOssFile({ formData }))
        }}
        onDelete={(index) => dispatch(removeAFDIFile(fdipreFiles[index]))}
        editable={editable} />
      {editable && <WingBlank>
        <div className="small-font gray">
          <FormattedMessage id="app-health" />
        </div>
      </WingBlank>}
    </UploadFileWrapper>
  </>
}

export default FdiAndPrepare