import React from 'react'
import { Flex } from 'antd-mobile'
import {FormattedMessage} from 'react-intl'

import styles from './OrderPrice.module.css';

const OrderPrice = ({ labelId, value }) => (
  <Flex justify="end" className={styles.order_price}>
    <span className={styles.label}><FormattedMessage id={labelId}></FormattedMessage></span>
    <span className={styles.value} >{value}</span>
  </Flex>
);

export default OrderPrice;