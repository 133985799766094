import { List } from 'antd-mobile-v5';
import { UserOutline, DeleteOutline } from 'antd-mobile-icons';
import { useIntl } from 'react-intl';
import React from 'react';

export default ({ students = [], remove }) => {
  const { formatMessage } = useIntl();
  let body;
  if (students.length > 0) {
    body = students.map((student) => (
      <List.Item
        description={student.email}
        key={student.email}
        prefix={<UserOutline />}
        extra={
          remove ? (
            <DeleteOutline onClick={() => remove(student.email)} />
          ) : (
            <></>
          )
        }
      >
        {student.userName}
      </List.Item>
    ));
  } else {
    body = (
      <List.Item
        description={formatMessage({ id: 'student-search-list-empty' })}
      />
    );
  }
  return (
    <List
      style={{ '--padding-left': '16px' }}
      header={formatMessage({ id: 'student-search-list' })}
    >
      {body}
    </List>
  );
};
