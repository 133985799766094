import { connect } from 'react-redux';
import { List, WhiteSpace } from 'antd-mobile';
import React, { useState, useEffect } from 'react';

import { getProfile, getEcards, getUserInfo } from '../../reducers/selectors';
import ProfileHeader from '../../components/MyProfile/ProfileHeader';

import {
  requestYouzanStatus,
  requestMemberRenew,
  requestProfiles,
  resetApp,
} from '../../actions';
import { FormattedMessage } from 'react-intl';
import BindingYouzan from '../../components/MyProfile/BindingYouzan';
import BasicInfo from '../../components/MyProfile/BasicInfo';
import OfficialQrCodeWrapper from '../../components/EcardsList/OfficialQrCodeWrapper';
import LanguageMenu from './LanguageMenu';
import BottomNav from '../../components/BottomNav';

import './MyProfile.css';
import { isMainlandUser } from '../../utils/user';
import { useHistory } from 'react-router-dom';
import MemberMenus from './MemberMenus';

function MyProfilePage({ profiles, ecards, userInfo, requestProfiles, requestYouzanStatus, resetApp }) {
  const { push } = useHistory();
  const [showOfficalQrCode, setShowOfficalQrCode] = useState(false);

  useEffect(() => {
    requestProfiles();
  }, []);

  if (!profiles || !userInfo) return null;

  return (
    <div className="my-profile">
      <ProfileHeader wechat={profiles.wechat} ecards={ecards} />

      <BasicInfo userInfo={userInfo} storeInfo={profiles.storeInfo} />

      {showOfficalQrCode && (
        <OfficialQrCodeWrapper
          toogleShowOfficalQrCode={() =>
            setShowOfficalQrCode(!showOfficalQrCode)
          }
        />
      )}

      <WhiteSpace size="sm" />
      <List className="profile-list">
        <BindingYouzan pageEntry={'profile'}>
          <List.Item
            arrow="horizontal"
            onClick={() => requestYouzanStatus()}
          >
            <i className="fa fa-handshake-o fa-lg fa-fw"></i>
            <FormattedMessage id="profile-youzan" />
          </List.Item>
        </BindingYouzan>
        {!profiles.storeInfo && isMainlandUser(userInfo) && (
          <>
            <List.Item arrow="horizontal" onClick={() => push('/cart')}>
              <i className="fa fa-vcard-o fa-lg fa-fw"></i>
              <FormattedMessage id="cardRequest" />
            </List.Item>
            <List.Item
              arrow="horizontal"
              onClick={() => push('/public_welfare_cert')}
            >
              <i className="fa fa-diamond fa-lg fa-fw"></i>
              我的公益证书
            </List.Item>
          </>
        )}
        <List.Item arrow="horizontal" onClick={() => push('/pro_check')}>
          <i className="fa fa-check-circle-o fa-lg fa-fw"></i>
          <FormattedMessage id="procheck" />
        </List.Item>
      </List>
      <WhiteSpace size="sm" />

      {/**
       * Member Zone
       */}
       <MemberMenus profiles={profiles} />

      {/**
       * Language and Copyright
       */}
      <List className="profile-list">
        <LanguageMenu />
        <List.Item
          arrow="horizontal"
          onClick={() => {
            resetApp();
            push('/emailLogin');
          }}
        >
          <i className="fa fa-envelope-o fa-lg fa-fw"></i>
          <FormattedMessage id="profile-email" />
        </List.Item>
        <List.Item
          arrow="horizontal"
          onClick={() => setShowOfficalQrCode(!showOfficalQrCode)}
        >
          <i className="fa fa-copyright fa-lg fa-fw"></i>
          <FormattedMessage id="profile-copyright" />
        </List.Item>
      </List>
      <WhiteSpace size="sm" />
      <div style={{ width: '100%', height: '60px' }}></div>
      <BottomNav isMainlandUser={isMainlandUser(userInfo)} />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    profiles: getProfile(state),
    ecards: getEcards(state),
    userInfo: getUserInfo(state),
  };
}

export default connect(mapStateToProps, {
  requestYouzanStatus,
  requestMemberRenew,
  requestProfiles,
  resetApp,
})(MyProfilePage);
