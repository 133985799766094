import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { Modal, Button } from 'antd-mobile';
import { FormattedMessage, useIntl } from 'react-intl';
import { removeExpires, getSubmitOssFormat, } from '../../components/common/utils'
import { useSubmitOwsi } from '../../services/hooks'

const IeSubmitBtn = ({
  formData,
  agreementChecked,
  certStatus }) => {

  const { formatMessage } = useIntl();
  const { push } = useHistory()
  const { runAsync: submitOwsi, loading: submitting } = useSubmitOwsi();

  const { signatureUrl } = useSelector((state) => ({
    signatureUrl: state.ui.signatureUrl
  }));

  const verifySubmit = () => {
    const {
      ieId,
      ieExamModules,
      alternateLocation,
      storeNumber
    } = formData;
    return (
      (alternateLocation || storeNumber) &&
      ieId &&
      agreementChecked &&
      ieExamModules.length > 0
    );
  };

  const submit = () => {
    if (verifySubmit()) {
      const { code, ...data } = formData
      let submitData = {
        ...data,
        ieAttempt: formData.ieAttempt === 1 ? 2 : formData.ieAttempt,
        aidmfiles: formData.aidmfiles ? removeExpires(formData.aidmfiles) : [],
        aowfiles: formData.aowfiles ? removeExpires(formData.aowfiles) : [],
        efrfiles: formData.efrfiles ? removeExpires(formData.efrfiles) : [],
        owfiles: formData.owfiles ? removeExpires(formData.owfiles) : [],
        rescuefiles: formData.rescuefiles ? removeExpires(formData.rescuefiles) : [],
        otherFiles: formData.otherFiles ? removeExpires(formData.otherFiles) : [],
        handwrittenSignatureUrl: getSubmitOssFormat(signatureUrl),
      };
      submitOwsi(submitData).then(res => {
        if (res.code) {
          push('/application_submit_success?type=OWSI', { code: res.code, paid: res.paid, paymentType: 'MEMBER_APPLICATION_OWSI' });
        }
      })
    } else {
      Modal.alert(
        formatMessage({ id: 'dm-form-submit-error' }),
        formatMessage({ id: 'dm-form-submit-error-info' }),
        [{ text: formatMessage({ id: 'common-close' }) }]
      );
    }
  }

  return (
    <Button type="primary" onClick={() => submit()} loading={submitting}>
      <FormattedMessage id="dm-form-submit" />
    </Button>
  );
};

export default IeSubmitBtn;
