//Note: Api instance is created once and wechatID can't be altered after creation
import querystring from 'querystring'
import { getUnionId } from '../components/common/cookie';
import * as config from './config';
import { getCookie } from '../components/common/cookie';
import 'isomorphic-fetch';
import dayjs from 'dayjs';

const API_ROOT = getApiRoot()
const ossHost = "https://ecards-confirm-apps.oss-cn-beijing.aliyuncs.com"
const CERT_API_ROOT = getCertApiRoot()
const fetchWrapper = async (method, endpoint, data, notJson, postFn, cache, noResult) => {
  const language = getCookie('language') === 'tc' ? 'zh_TW' : getCookie('language') === 'en' ? 'en' : 'zh_CN'
  const options = {
    method,
    headers: {
      Authorization: getUnionId(),
      'content-type': 'application/json; charset=utf-8',
    },
    cache: cache || 'default'
  }
  if (method === 'POST' || method === 'PUT') {
    options.body = JSON.stringify(data)
  }
  if (method === 'GET' && endpoint.indexOf('getWxJsSign') < 0) data.lang = language;
  const url = (method === 'POST' || method === 'PUT') ? `${endpoint}?lang=${language}` : `${endpoint}?${querystring.stringify(data)}`
  try {
    const response = await fetch(url, options)
    if (!response.ok) throw new Error(`HTTP code: ${response.status}, ${response.statusText}`)
    const data = notJson ? await response.text() : await response.json()
    if (data.error) return { error: data.error }
    if (noResult) return { data: true }
    return { data: postFn ? postFn(data) : data }
  } catch (error) {
    return { error }
  }
}

const fetchFileWrapper = async (endpoint, data, notJson, noResult, outPoint) => {
  const token = 'Bearer ' + getCookie('wechatToken')
  const { formData, params } = data
  const options = {
    method: 'POST',
    headers: {
      Authorization: outPoint ? token : getUnionId()
    },
    body: formData
  }
  const url = params ? `${endpoint}?${querystring.stringify(params)}` : `${endpoint}`
  try {
    const response = await fetch(url, options)
    if (response.status === 401) {
      return { error: { status: response.status } }
    }
    if (!response.ok) throw new Error(`HTTP code: ${response.status}, ${response.statusText}`)
    if (noResult) {
      return { data: true }
    } else {
      const data = notJson ? await response.text() : await response.json()
      if (data.error) return { error: data.error }
      return { data }
    }
  } catch (error) {
    return { error }
  }
}

const fetchFileReturnResult = async (endpoint, data) => {
  const { formData } = data
  const options = {
    method: 'POST',
    headers: {
      Authorization: getUnionId()
    },
    body: formData
  }
  const url = endpoint
  try {
    const response = await fetch(url, options)
    if (!response.ok) throw new Error(`HTTP code: ${response.status}, ${response.statusText}`)
    const data = { credentialId: parseInt(formData.get('key').split('/')[2]), url: `files.padi.com.cn/${formData.get('key')}` }
    if (data.error) return { error: data.error }
    return { data }
  } catch (error) {
    return { error }
  }
}

export const fetchOssFile = async (endpoint, formData) => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: formData.get('policy')
    },
    body: formData
  }
  const url = endpoint
  try {
    console.log('fetchOssFile', '0000', url)
    const response = await fetch(url, options)
    console.log('fetchOssFile', '1111')
    if (!response.ok) throw new Error(`HTTP code: ${response.status}, ${response.statusText}`)
    const data = { credentialId: parseInt(formData.get('key').split('/')[2]), url: formData.get('key') }
    console.log('fetchOssFile', data)
    return data
  } catch (error) {
    return { error }
  }
}

const fetchMultiParams = async (method, endpoint, data, paramsName, params) => {
  const language = getCookie('language') === 'tc' ? 'zh_TW' : getCookie('language') === 'en' ? 'en' : 'zh_CN'
  const options = {
    method,
    headers: {
      Authorization: getUnionId(),
      'content-type': 'application/json; charset=utf-8',
    }
  }
  options.body = JSON.stringify(data)
  const url = `${endpoint}?lang=${language}&${paramsName}=${params}`
  try {
    const response = await fetch(url, options)
    if (!response.ok) throw new Error(`HTTP code: ${response.status}, ${response.statusText}`)
    const data = await response.json()
    if (data.error) return { error: data.error }
    return { data }
  } catch (error) {
    return { error }
  }
}

const deleteWrapper = async (method, endpoint, data) => {
  const options = {
    method,
    headers: {
      Authorization: getUnionId(),
      'content-type': 'application/json; charset=utf-8',
    }
  }
  options.body = JSON.stringify(data)
  const url = endpoint
  try {
    const response = await fetch(url, options)
    if (!response.ok) throw new Error(`HTTP code: ${response.status}, ${response.statusText}`)
    const data = await response.json()
    if (data.error) return { error: data.error }
    return { data }
  } catch (error) {
    return { error }
  }
}

const fetchByPathWrapper = async (endpoint, data, notJson, cache) => {
  const token = 'Bearer ' + getCookie('wechatToken')
  const options = {
    method: 'GET',
    headers: {
      Authorization: token,
      'content-type': 'application/json; charset=utf-8',
    },
    cache: cache || 'default'
  }
  const url = endpoint.replace('key', data)
  try {
    const response = await fetch(url, options)
    if (!response.ok) throw new Error(`HTTP code: ${response.status}, ${response.statusText}`)
    const data = notJson ? await response.text() : await response.json()
    if (data.error) return { error: data.error }
    return { data: url }
  } catch (error) {
    return { error }
  }
}

export const getData = async (endpoint, data, notJson, postFn, cache) => fetchWrapper('GET', `${API_ROOT}${endpoint}`, data, notJson, postFn, cache)
export const postData = async (endpoint, data, notJson, postFn, cache, noResult) => fetchWrapper('POST', `${API_ROOT}${endpoint}`, data, notJson, postFn, cache, noResult)
const postMulitiParamsData = async (endpoint, data, paramsName, params) => fetchMultiParams('POST', `${API_ROOT}${endpoint}`, data, paramsName, params)
const putData = async (endpoint, data, notJson, postFn, cache, noResult) => fetchWrapper('PUT', `${API_ROOT}${endpoint}`, data, notJson, postFn, cache, noResult)
const deleteData = async (endpoint, data) => deleteWrapper('DELETE', `${API_ROOT}${endpoint}`, data)
const postFileData = async (endpoint, data, notJson, noResult) => fetchFileWrapper(`${API_ROOT}${endpoint}`, data, notJson, noResult)
const postFileDataOutPoint = async (endpoint, data, notJson, noResult, outPoint) => fetchFileWrapper(`${endpoint}`, data, notJson, noResult, outPoint)

export function getApiRoot() {
  const origin = document.location.origin
  if (origin === config.URL_prod) {
    return config.baseURL_prod;
  } else if (origin === config.URL_stage) {
    return config.baseURL_stage;
    // } else if (origin.indexOf('localhost') > 0){
    //   return 'http://localhost:8081';
  } else {
    return config.baseURL_qa;
  }
}

function getCertApiRoot() {
  const origin = document.location.origin
  if (origin === config.URL_prod) {
    return config.certURL_prod;
  } else {
    return config.certURL_qa;
  }
}

// api services
export const fetchEcards = async () => getData(config.ecards, {}, false, false, 'no-cache')

export const fetchWechatUser = async () => getData(config.wechatUser, { unionid: getUnionId() })

export const resetPassword = ({ email, password, resetToken }) => postData(config.resetPassword, { email, password, resetToken }, true)

export const getMobileCode = ({ countryCode, mobile }) => postData(config.getMobileCode, { countryCode, mobile }, true)

export const verifyMobileCode = ({ code, countryCode, mobile, email }) => postData(config.verifyMobileCode, { code, countryCode, mobile, email })

export const saveProfile = (profile) => postData(config.saveOrUpdateProfile, profile)

export const getEmailCode = (email) => postData(config.getEmailCode, { email })

export const verifyEmailCode = ({ email, code }) => postData(config.verifyEmailCode, { email, code })

export const emailLogin = ({ email, password }) => postData(config.emailLogin, { username: email, password })

export const forgotPassword = (email) => getData(config.forgotPassword, { email })

export const fetchProfiles = (email) => getData(config.profiles, { email })

//TODO: cleanup
export const cardRequestOptions = (id) => getData(config.cardOptions, { ecardId: id }, false,
  (data) => {
    const result = { [id]: data }
    return result
  })

export const addToCart = (items) => postData(config.addToCart, items)

export const fetchShoppingCart = () => getData(config.getShoppingCart, {}, false, false, "no-cache")

export const fetchAddressList = () => getData(config.getAddressList, {})

export const postAddressList = (address) => postData(config.postAddressList, address)

export const credentialRequest = (ecardId) => postMulitiParamsData(config.credentialRequest, {}, 'ecardId', ecardId)

export const delectAddress = (id) => getData(config.delectAddress, { id }, true)

export const ecardImgBinding = (data) => postData(config.ecardImgBinding, data)

export const cancelEcardImgBinding = (data) => putData(config.cancelEcardImgBinding, data, true)

export const ecardImgReBinding = (data) => putData(config.ecardImgReBinding, data)

export const hasActivityCard = (activityCode) => getData(config.hasActivityCard, { activityCode })

export const checkoutYouzanStatus = () => getData(config.checkoutYouzanStatus, {})

export const getYouzanMobileCode = (mobile) => postMulitiParamsData(config.getYouzanMobileCode, {}, 'mobile', mobile)

export const bindingYouzan = ({ code, mobile }) => postData(config.bindingYouzan, { code, mobile })

export const submitStore = (storeNum) => postMulitiParamsData(config.submitStore, {}, 'storeNumber', storeNum)

export const removeStore = () => deleteData(config.removeStore, {})

export const getOrderYouzanUrl = (data) => postData(config.getOrderYouzanUrl, data)

export const getMemberRenew = () => getData(config.memberRenew, {})

export const getStoreRenew = () => getData(config.storeRenew, {})

export const getMemberRenewYouzanUrl = (data) => postData(config.getMemberRenewYouzanUrl, data)

export const getStoreRenewYouzanUrl = (data) => postData(config.getStoreRenewYouzanUrl, data)

export const getWechatJSConfig = (url) => getData(config.wechatJSConfigUrl, { url });

export const getInstructorInfo = (instructorNumber, instructorType) => getData(config.instructorInfo, { instructorNumber, instructorType })

export const getStoreInfo = (storeNumber) => getData(config.storeInfo, { storeNumber })

export const getActivityList = () => getData(config.activityList, {})

export const getCampFestival = (number) => postData(config.campFestival, number, true)

export const getCampRenewal = () => getData(config.campRenewal, {})

export const getElearningUrl = () => getData(config.elearningUrl, {})

export const submitDmForm = (data) => postData(config.submitDmForm, data)

export const getTextBookSaleList = () => getData(config.textBookSaleList, {})

export const getTextBookSaleUrl = (data) => postData(config.textBookSaleUrl, data)

export const postTextBookCheckout = (data) => postData(config.textBookSaleCheckout, data)

export const payPalCallback = (data) => postData(config.payPalCallback, data)

function parseDate(dateStr) {
  dateStr = dateStr || '00年1月';
  const year = dateStr.slice(0, 2);
  const month = dateStr.split('年')[1].replace('月', '');
  return dayjs(`20${year}-${month}-01`);
}

function transform(libraryItem, level = 0) {
  const { memberLibraryDTOS, memberLibraryDetailDTOS } = libraryItem;
  libraryItem.level = level;
  let dtoUpdateTime = '00年1月', detailUpdateTime = '00年1月';
  if(memberLibraryDTOS && memberLibraryDTOS.length > 0) {
    libraryItem.memberLibraryDTOS = memberLibraryDTOS.map(dto => {
      transform(dto, level + 1);
      const updateTime = parseDate(dto.updateTime);
      dtoUpdateTime = updateTime.isAfter(dtoUpdateTime) ? updateTime : dtoUpdateTime;
      return dto;
    })
  }
  if(memberLibraryDetailDTOS && memberLibraryDetailDTOS.length > 0) {
    memberLibraryDetailDTOS.sort((d1, d2) => parseDate(d2.updateTime).isAfter(parseDate(d1.updateTime)) ? 1 : -1);
    detailUpdateTime = memberLibraryDetailDTOS[0].updateTime;
  }
  libraryItem.updateTime = parseDate(dtoUpdateTime).isAfter(parseDate(detailUpdateTime)) ? dtoUpdateTime : detailUpdateTime;
  return libraryItem;
}

export const getMemeberLibrary = async () => {
  const result = await getData(config.memberLibrary, {});
  if(result && result.data) result.data.map(item => transform(item, 0));
  return result;
}

export const dmFormCounter = () => getData(config.dmFormCounter, {})

export const instructorApplication = ({ page }) => getData(config.instructorApplication, { page })

export const instructorApplicationDetail = ({ code }) => getData(config.instructorApplicationDetail, { code })

export const instructorApproved = (data) => putData(config.instructorApproved, data)

export const submitSignature = (formData) => postFileData(`${API_ROOT}${config.submitSignature}`, formData)

export const submitInstructorSignature = ({ formData, params }) => postFileData(`${API_ROOT}${config.submitInstructorSignature}`, { formData, params })

export const searchStudent = (studentNo) => getData(config.searchStudent, { studentNo })

export const instructorCreate = (data) => postData(config.instructorCreate, data)

export const getOssSignature = (type) => getData(config.ossSignature, { type })

export const uploadSignature = (formData) => postFileDataOutPoint(ossHost, formData, false, true)

export const getLigtenElearning = (elearningCourseType) => getData(config.elearningLignten, { elearningCourseType }, true)

export const uploadSiFile = (formData) => fetchFileReturnResult(ossHost, formData)

export const getSiAppDetail = () => getData(config.siAppDetail, {})

export const uploadPhoto = (formData) => postFileDataOutPoint(`${CERT_API_ROOT}${config.uploadPhoto}`, formData, false, false, true)

export const getSiAppList = () => getData(config.siAppList, {})

export const getSiDetailInProcess = ({ code, credentialId }) => getData(config.getDetailInProcress, { code, credentialId })

export const submitSiInprocess = (data) => postData(config.submitSiInProcress, data, true, false, false, true)

export const getInstructorInfoByNumber = (instructorNumber) => getData(config.intructorInfoByNumber, { instructorNumber })

export const cdSubmitSiApp = (data) => postData(config.cdSubmitSiApp, data)

export const cdSiAppList = () => getData(config.cdSiAppList, {})

export const getCdSiDetail = ({ code, credentialId }) => getData(config.getCdSiDetail, { code, credentialId })

export const reSubmitCdSiApp = (data) => postData(config.reSubmitCdSiApp, data, true, false, false, true)

export const getOssFile = (key) => getData(config.getOssFile, { key }, true)

export const checkPrerequisiteRequirements = () => getData(config.checkPrerequisiteRequirements, {}, true)

export const refreshToken = () => postData(config.refreshWechatToken, {})

export const getSIAppYouzanUrl = (code) => getData(config.getSIAppYouzanUrl, { code })

export const getOwsiDetail = () => getData(config.owsiDetail, {})

export const getOwsiCdList = ({ page }) => getData(config.owsiCdList, { page })

export const getOwsiCdDetail = ({ code }) => getData(config.owsiCdDetail, { code })

export const owsiCdApproved = (data) => putData(config.owsiCdApproved, data)

export const owsiCdPay = (code) => getData(config.owsiCdPay, { code })

export const getStoreInfoWithLevel = (storeNumber) => getData(config.searchStoreWithLevel, { storeNumber })

export const getAdminDmList = (pageData) => getData(config.getApplicationList, pageData)

export const getAdminSiList = (pageData) => getData(config.getSiApplication, pageData)

export const getAdminOwsiList = (pageData) => getData(config.getOwsiApplication, pageData)

export const getDmExcel = () => getData(config.downloadExcel, {}, true)

export const csDmApproved = (data) => putData(config.dmApproved, data)

export const csSiApproved = (data) => putData(config.siApproved, data)

export const csOwsiApproved = (data) => putData(config.owsiApproved, data)

export const getIeList = () => getData(config.ieList, {})

export const getIeCandidateList = (ieId) => getData(config.ieCandidateList, { ieId })

export const submitIe = (data) => postData(config.submitIe, data)

export const getDmPay = (code) => getData(config.dmPay, { code })

export const getCollectionDetail = (alias) => getData(config.collectionDetail, { alias })

export const postCollectionTemplate = (data) => postData(config.submitCollectionTemplate, data, true)

export const getCollectionList = () => getData(config.collectionList, {})

export const postCollection = (data) => postData(config.submitCollection, data, true)

export const getAnswersByAlias = (alias) => getData(config.getAnswersByAlias, { alias })
export const agentElearningList = (email) => getData(config.agentElearningList, { email })

export const agentElearningSubmit = (data) => postData(config.agentElearningSubmit, data)
export const agentElearningOrder = (data) => postData(config.agentElearningOrder, data)
export const agentElearningCreateOrder = (data) => postData(config.agentElearningCreateOrder, data)

export const getEfriDetail = () => getData(config.efriDetail, {})

export const getEfrtList = ({ page }) => getData(config.efrtList, { page })

export const getEFRTDetailByCode = (code) => getData(config.efrtDetailByCode, { code })

export const efriApproved = (data) => putData(config.efriApproved, data)

export const getEfriPay = (code) => getData(config.efriPay, { code })

export const adminEfriAppList = (pageData) => getData(config.adminEfriAppList, pageData)

export const adminEfriApproved = (data) => putData(config.adminEfriApproved, data)

export const collectionEditerDetail = (alias) => getData(config.collectionEditerDetail, { alias })

export const ieListForExaminer = () => getData(config.ieListForExaminer, {})

export const ieDetailListForExaminer = (data) => getData(config.ieDetailListForExaminer, data)

export const downloadExamAttempts = (ieId) => getData(config.downloadExamAttempts, { ieId })

export const uploadExamAttempts = ({ formData, ieId }) => fetchFileReturnResult(getApiRoot() + config.uploadExamAttempts + '&ieId=' + ieId, { formData })

export const uploadTrainingRecord = (data) => putData(config.uploadTrainingRecord, data)

export const storeInfoWithoutAuth = (storeNumber) => getData(config.storeInfoWithoutAuth, { storeNumber })

export const dsdSubmit = (data) => postData(config.submitDsd, data)

export const dsdList = () => getData(config.dsdList, {})

export const dsdInstList = () => getData(config.dsdInstList, {})

export const dsdIssueCerts = (data) => putData(config.dsdCerts, data, false, false, false, true)

export const getDsdEmailCode = (email) => postData(config.dsdEmailCode, { email })

export const basicMermaidDetail = () => getData(config.basicMermaidDetail, {})

export const getBasicMermaidPay = (code) => getData(config.paybasicMermaid, { code })

export const getBasicMermaidTList = ({ page }) => getData(config.basicMermaidTList, { page })

export const getBasicMermaidTDetail = (code) => getData(config.basicMermaidTDetail, { code })

export const basicMermaidTApproved = (data) => putData(config.basicMermaidTApproved, data)

export const adminBasicMermaidAppList = (pageData) => getData(config.adminBasicMermaidAppList, pageData)

export const basicMermaidCsReview = (data) => putData(config.basicMermaidCsApproved, data)

export const mermaidiDetail = () => getData(config.mermaidiDetail, {})

export const getMermaidiPay = (code) => getData(config.payMermaidi, { code })

export const getMermaidTList = ({ page }) => getData(config.mermaidTList, { page })

export const getMermaidTDetail = (code) => getData(config.mermaidTDetail, { code })

export const mermaidTApproved = (data) => putData(config.mermaidTApproved, data)

export const adminMermaidAppList = (pageData) => getData(config.adminMermaidAppList, pageData)

export const mermaidCsReview = (data) => putData(config.mermaidCsApproved, data)

export const getFdiDetail = () => getData(config.fdiDetail, {})

export const getFdiTList = ({ page }) => getData(config.fdiTList, { page })

export const getFdiTDetail = (code) => getData(config.fdiTDetail, { code })

export const getFdiPay = (code) => getData(config.payFdi, { code })

export const fdiTApproved = (data) => putData(config.fdiTApproved, data)

export const adminFdiAppList = (pageData) => getData(config.adminFdiAppList, pageData)

export const fdiCsReview = (data) => putData(config.fdiCsApproved, data)

export const getAfdiDetail = () => getData(config.afdiDetail, {})

export const getAfdiTList = ({ page }) => getData(config.afdiTList, { page })

export const getAfdiTDetail = (code) => getData(config.afdiTDetail, { code })

export const getAfdiPay = (code) => getData(config.payAfdi, { code })

export const afdiTApproved = (data) => putData(config.afdiTApproved, data)

export const adminAfdiAppList = (pageData) => getData(config.adminAfdiAppList, pageData)

export const afdiCsReview = (data) => putData(config.afdiCsApproved, data)

export const getMfdiDetail = () => getData(config.mfdiDetail, {})

export const getMfdiTList = ({ page }) => getData(config.mfdiTList, { page })

export const getMfdiTDetail = (code) => getData(config.mfdiTDetail, { code })

export const getMfdiPay = (code) => getData(config.payMfdi, { code })

export const mfdiTApproved = (data) => putData(config.mfdiTApproved, data)

export const adminMfdiAppList = (pageData) => getData(config.adminMfdiAppList, pageData)

export const mfdiCsReview = (data) => putData(config.mfdiCsApproved, data)

export const getAllecardCustomization = () => getData(config.pwInfo, {})

export const searchStudentByEmail = (email, type) => getData(config.searchStudentByEmail, { email, type })

export const getFdPicList = ({ page }) => getData(config.fdPicList, { page })

export const getFdPicPay = (code) => getData(config.fdPicPay, { code })

export const closeFdPic = (data) => putData(config.closeFdPic, data, true, false, false, true)

export const uploadDsdExcel = ({ formData }) => fetchFileReturnResult(getApiRoot() + config.uploadDsd, { formData })

export const getDslStoreList = (data) => getData(config.dslStoreList, data)

export const getLatLngByIp = () => getData(config.getLatLngByIp, {})

export const festivalTickets = () => getData(config.festivalTickets, {})

export const procheck = (data) => getData(config.procheck, data)

export const getProcheckCaptcha = () => getData(config.procheckCaptcha, {})

export const adminCertMergeList = (pageData) => getData(config.certsMergeList, pageData)

export const adminFindAccountList = (pageData) => getData(config.findAccountList, pageData)

export const adminUpdateUserInfoList = (pageData) => getData(config.adminUpdateUserInfoList, pageData)

export const adminGetAdminWenjuanList = (pageData) => getData(config.adminWenjuanList, pageData)

export const adminGetOtherAppsList = (pageData) => getData(config.adminGetOtherAppsList, pageData)

export const adminGetStoreAppsList = (pageData) => getData(config.adminGetStoreAppsList, pageData)

export const adminGetAdminOrdersList = (pageData) => getData(config.adminOrdersList, pageData)