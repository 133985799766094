import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import Header from '../../components/common/FormHeader'
import DiverInfo from '../DiveMasterForm/DiverInfo'
import CheckAge from '../OwsiApplication/CheckAge'
import { WhiteSpace } from 'antd-mobile';
import CheckCerts from './CheckCerts'
import RequiredProjects from './RequiredProjects'
import AddressAndLangOption from '../DiveMasterForm/AddressAndLangOption'
import Signature from '../../components/common/Signature';
import AgreementCheckbox from '../../components/common/AgreementCheckbox'
import TrainingInfo from './TrainingInfo'
import { getAge, formatDate, showModal } from '../../components/common/utils'
import { clearSignature, getUserPhoto } from '../../reducers/ui';
import AddressForm from '../../components/common/AddressForm'
import CardLayout from '../../pages/DiveMasterForm/CardLayout';
import { findEfrCert, findMermaidCert, findEfriCert } from '../../components/common/findCerts'
import ApplicationStatus from './ApplicationStatus'
import Notification from '../../components/Notification';

const BasicMermaidApplication = () => {

  const { userInfo, detail, certifications, credentials } = useSelector(state => ({
    userInfo: state.data.userInfo,
    detail: state.mermaid.basicMermaidDetail,
    certifications: state.data.certifications,
    credentials: state.data.credentials,
  }));

  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const [showDialog, setDialogStatus] = useState(false);
  const [checkAge, setCheckAge] = useState(false)
  const [checked, setChecked] = useState(false)
  const [projectsStatus, setProfectsStatus] = useState(false)
  const [formData, setFormData] = useState({
    cdinstructorNumber: '',
    code: 0,
    regionId: 0,   //教学地址
    languageId: 0,   //教学语言
    storeNumber: '',
    finishDate: '',   //课程日期courseDate
    courseLocation: '',   //课程地点
    address: '',   //地址信息-详细地址
    addressRegionId: 0,   //地址信息-国家
    addressStateId: -1,   //地址信息-地区
    efr: '',
    efrfiles: [],
    efri: false,
    padiadvancedMermaid: '',
    padiadvancedMermaidFiles: [],
    otherFiles: []
  });

  useEffect(() => {
    if (userInfo) {
      setCheckAge(getAge(userInfo.birthDate) >= 18)
    }
  }, [userInfo]);

  const updateFormData = (update) => setFormData({ ...formData, ...update })

  const isFormEditable = () =>
    detail.status === 'ADMIN_NOT_APPROVED' ||
    !detail.status ||
    detail.status === 'SUBMIT' ||
    detail.status === 'INSTRUCTOR_NOT_APPROVED'

  useEffect(() => {
    return () => dispatch(clearSignature());
  }, [])

  useEffect(() => {
    if (certifications && detail) {
      setFormData({
        ...formData,
        code: detail.code,
        padiadvancedMermaid: findMermaidCert(certifications) ? findMermaidCert(certifications).studentNumber : '',
        efr: findEfrCert(certifications) ? findEfrCert(certifications).studentNumber : '',
        efri: findEfriCert(credentials) ? true : false,
        otherFiles: detail.otherFiles || [],
        languageId: detail.languageId || formData.languageId,
        regionId: detail.regionId || formData.regionId,
        finishDate: detail.courseDate || formData.finishDate,
        cdinstructorNumber: detail.instructorInfoDTO ? detail.instructorInfoDTO.instructorNumber : formData.cdinstructorNumber,
        storeNumber: detail.storeInfoDTO ? detail.storeInfoDTO.storeNumber : formData.storeNumber,
        efrfiles: detail.efrfiles || [],
        padiadvancedMermaidFiles: detail.padiadvancedMermaidFiles || [],
        address: detail.address || formData.address,
        addressRegionId: detail.addressRegionId || formData.addressRegionId,
        addressStateId: detail.addressStateId || formData.addressStateId,
        courseLocation: detail.courseLocation || formData.courseLocation,
      })
    }
  }, [certifications, detail])

  useEffect(() => {
    if (detail.note) {
      if (!showDialog) {
        showModal(
          formatMessage({ id: 'cardOptions-replaceCardHint' }),
          `${formatMessage({ id: 'dm-app-not-approved-tips' })}${
          detail.note
          }`,
          () => { },
          () => { },
          formatMessage({ id: 'common-comfirmBtn' }),
          formatMessage({ id: 'common-cancel' })
        );
      }
      setDialogStatus(true);
    }
  }, [detail, formatMessage, showDialog]);

  const addressComplete = () => {
    if (formData.addressRegionId === 51) return formData.addressRegionId && formData.address && formData.addressStateId > 0
    else if (formData.addressRegionId && formData.addressRegionId !== 51) return formData.addressRegionId && formData.address
  }

  const setCountryId = (country) => {
    if (country !== 51) {
      updateFormData({ addressRegionId: country, addressStateId: -1 })
    } else {
      updateFormData({ addressRegionId: country })
    }
  }

  const efrComplete = formData.efr || (formData.efrfiles && formData.efrfiles.length > 0) || formData.efri

  const mermaidCertComplete = formData.padiadvancedMermaid || (formData.padiadvancedMermaidFiles && formData.padiadvancedMermaidFiles.length > 0)

  const certComplete = mermaidCertComplete && efrComplete && formData.otherFiles.length > 0

  if (!userInfo) return null

  return <>
    <Notification position="MEMBER_APP" />
    <Header
      text={<FormattedMessage id="BMI-app" />}
      code={detail.code}
    />
    <DiverInfo diver={userInfo} />
    <WhiteSpace />
    <TrainingInfo
      editable={isFormEditable()}
      updateFormData={updateFormData}
      cdinstructorNumber={formData.cdinstructorNumber}
      storeNumber={formData.storeNumber}
      finishDate={formData.finishDate}
      courseLocation={formData.courseLocation} />
    <WhiteSpace />
    <CheckAge
      checkAge={checkAge} />
    <WhiteSpace />
    <CheckCerts
      certComplete={certComplete}
      efrfiles={formData.efrfiles}
      otherFiles={formData.otherFiles}
      mermaidFiles={formData.padiadvancedMermaidFiles}
      editable={isFormEditable()} />
    <WhiteSpace />
    <RequiredProjects
      status={projectsStatus}
      setStatus={setProfectsStatus}
      formStatus={detail.status} />
    <WhiteSpace />
    <AddressAndLangOption
      onValueChange={updateFormData}
      languageId={formData.languageId}
      regionId={formData.regionId}
      editable={isFormEditable()} />
    <WhiteSpace />
    <CardLayout title={formatMessage({ id: 'common-address-detail' })} status={addressComplete()}>
      <AddressForm
        onRegionOk={(region => updateFormData({ addressStateId: parseInt(region) }))}
        onAddressOk={address => updateFormData({ address })}
        onCountryOk={country => setCountryId(parseInt(country))}
        address={formData.address}
        stateId={formData.addressStateId}
        countryId={formData.addressRegionId}
        editable={isFormEditable()} />
    </CardLayout>
    <WhiteSpace />
    <Signature
      filePath={'signature'}
      initSignature={detail.handwrittenSignatureUrl}
      modified={isFormEditable()}
    />
    {isFormEditable() && (
      <AgreementCheckbox
        checked={checked}
        setChecked={(checked) => setChecked(checked)}>
        <FormattedMessage id="BMI-agreement1" />
        <FormattedMessage id="BMI-agreement2" />
        <FormattedMessage id="BMI-agreement3" />
      </AgreementCheckbox>
    )}
    <WhiteSpace size="xl" />
    <ApplicationStatus
      detail={detail}
      formData={formData}
      checkAge={checkAge}
      checked={checked}
      certComplete={certComplete}
      projectsStatus={projectsStatus}
      addressComplete={addressComplete()} />
    <WhiteSpace size="xl" />
  </>
}

export default BasicMermaidApplication