import { Dialog } from 'antd-mobile-v5';
import { Modal } from 'antd-mobile-v5';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { usePagination, useRequest } from 'ahooks';

import { debug } from '../utils/env';
import { getCookie } from '../components/common/cookie';
import * as config from './config';
import { isCert } from '../utils/cert';
import { getParameterByName, formatDate } from '../components/common/utils';

export function getApiRoot() {
  const origin = document.location.origin;
  if (origin === config.URL_prod) {
    return config.baseURL_prod;
  } else {
    return config.baseURL_qa;
  }
}

function getCertApiRoot() {
  const origin = document.location.origin
  if (origin === config.URL_prod) {
    return config.certURL_prod;
  } else {
    return config.certURL_qa;
  }
}

function deepLoop(obj) {
  if (typeof obj !== 'object' || obj == null) {
    return obj
  }
  if (Object.prototype.toString.call(obj).slice(8, -1) === 'Date') {
    return formatDate(obj, 'YYYY-MM-DD')
  }
  let result
  if (obj instanceof Array) {
    result = []
  } else {
    result = {}
  }
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      result[key] = deepLoop(obj[key])
    }
  }
  return result
}

export const API_ROOT = getApiRoot();
const ossHost = 'https://ecards-confirm-apps.oss-cn-beijing.aliyuncs.com';
const CERT_API_ROOT = getCertApiRoot();
const fetchWrapper = async (
  method,
  withToken,
  endpoint,
  request = {},
  responseType = 'json'
) => {
  const cookieLanguage = getCookie('language');
  const language =
    cookieLanguage === 'tc'
      ? 'zh_TW'
      : cookieLanguage === 'en'
      ? 'en'
      : 'zh_CN';
  const options = {
    method,
    headers: {
      'content-type': 'application/json; charset=utf-8',
    },
  };
  if (withToken && getCookie('padiWxId')) options.headers.Authorization = getCookie('padiWxId');

  let url = `${API_ROOT}${endpoint}`;
  const newRequest = deepLoop(request)
  if (method === 'POST' || (method === 'PUT' && Object.keys(newRequest).length >1)) {
    options.body = JSON.stringify(newRequest);
    url += `?lang=${language}`;
  } else if ((method === 'GET' || method === 'PUT' || method === 'DELETE') && endpoint.indexOf('getWxJsSign') < 0) {
    newRequest.lang = language;
    const queryObj = new URLSearchParams(newRequest);
    url += `?${queryObj.toString()}`;
  }
  debug('Fetch url: ', url);
  debug('Fetch options: ', options);
  const result = await fetch(url, options).then((response) => {
    debug('Reponse type: ', response.type);
    return response[responseType]();
  }).catch(err => {
    debug('Fetch error: ', err)
    throw err
    // return err
  });
  debug('Fetch Result: ', result);
  if (result.error) throw result.error;
  return result;
};

const fetchByPathWrapper = async (withToken, endpoint, data, responseType = 'json') => {
  const options = {
    method: 'GET',
    headers: {
      'content-type': 'application/json; charset=utf-8',
    }
  }
  if (withToken) options.headers.Authorization = 'Bearer ' + getCookie('wechatToken');
  console.log(endpoint, data)
  const url = endpoint.replace('key', data)
  debug('Fetch url: ', url);
  debug('Fetch options: ', options);
  const result = await fetch(url, options).then((response) => {
    debug('Reponse type: ', response.type);
    return { url };
  });
  debug('Fetch Result: ', result);
  if (result.error) throw result.error;
  return result;
}

export const fetchOssFile = async (endpoint, formData) => {
  console.log(endpoint, formData)
  const options = {
    method: 'POST',
    headers: {
      Authorization: formData.get('policy')
    },
    body: formData
  }
  const url = endpoint
  try {
    console.log('fetchOssFile', '0000', url)
    const response = await fetch(url, options)
    console.log('fetchOssFile', '1111')
    if (!response.ok) throw new Error(`HTTP code: ${response.status}, ${response.statusText}`)
    const data = { credentialId: parseInt(formData.get('key').split('/')[2]), url: formData.get('key') }
    console.log('fetchOssFile', data)
    return data
  } catch (error) {
    return { error }
  }
}

export const getService = (url, params, responseType = 'json') => fetchWrapper('GET', false, url, params, responseType);
export const postService = (url, params, responseType = 'json') => fetchWrapper('POST', false, url, params, responseType);
export const authedGetService = (url, params, responseType = 'json') =>
  fetchWrapper('GET', true, url, params, responseType);
export const authedPostService = (url, params, responseType = 'json') =>
  fetchWrapper('POST', true, url, params, responseType);
export const authedPutService = (url, params, responseType = 'json') =>
  fetchWrapper('PUT', true, url, params, responseType);
export const authedDeleteService = (url, params, responseType = 'json') =>
  fetchWrapper('DELETE', true, url, params, responseType);
export const authedPagedService =
  (url) =>
  async ({ current, pageSize }, params) => {
    const { totalElements, content } = await fetchWrapper('GET', true, url, {
      page: current || 1,
      size: pageSize || 20,
      ...params,
    });
    return { total: totalElements, list: content };
  };
export const getDataByPath = (url, params, responseType = 'json') =>
  fetchByPathWrapper(false, url, params, responseType)

export const useErrorModal = (modalOptions = {}) => {
  const { formatMessage } = useIntl();
  return (content, url, hideTitle) => {
    Modal.alert({
      title: hideTitle ? '' : formatMessage({ id: 'common-errorTitle' }),
      content: content || formatMessage({ id: 'common-errorStatus' }),
      confirmText: formatMessage({ id: 'common-know' }),
      onConfirm: () => {
        if (url) window.location.href = url;
      },
      ...modalOptions,
    });
  };
};
export const useRequestCommon = (service, options = {}, noDialog) => {
  const showErrorModal = useErrorModal();
  return useRequest(service, {
    manual: true,
    // staleTime: 1000 * 60 * 30, // 30 mins
    ...options,
    onError: (error, params) => {
      const { code, clientMsg } = error;
      if (noDialog) return
      if (code === 'auth.required' || code === 'expired.token') {
        showErrorModal(clientMsg, '/emailLogin');
        return;
      } else if (code === 'email.not.verified') {
        showErrorModal(clientMsg, '/register');
        return;
      } else if (options.onError) {
        const handled = options.onError(error, params);
        if (!handled) {
          if (code === 'mobile.not.verified') {
            showErrorModal(clientMsg, false, true);
            return;
          }
          showErrorModal(clientMsg);
        }
      } else if (code === 'sys.error.0') {
        showErrorModal(clientMsg, '/myProfile');
        return;
      } else {
        console.error(error);
        showErrorModal(clientMsg);
      }
    },
  });
};

export const usePaginationCommon = (pagedService, options = {}) => {
  const showErrorModal = useErrorModal();
  return usePagination(pagedService, {
    manual: false,
    // staleTime: 1000 * 60 * 30, // 30 mins
    ...options,
    onError: (error, params) => {
      const { code, clientMsg } = error;
      if (code === 'auth.required') {
        showErrorModal(clientMsg, '/emailLogin');
        return;
      } else if (options.onError) {
        const handled = options.onError(error, params);
        if (!handled) {
          showErrorModal(clientMsg);
        }
      } else {
        showErrorModal(clientMsg);
      }
    },
  });
};

export const useStudentSearchByEmail = (options) =>
  useRequestCommon(
    (email, type) =>
      authedGetService(config.searchStudentByEmail, { email, type }),
    options
  );

export const useStoreSearch = (options) =>
  useRequestCommon(
    (storeNumber) => authedGetService(config.storeInfo, { storeNumber }),
    options
  );

export const useInstructorSearch = (options) =>
  useRequestCommon(
    (instructorNumber, instructorType) =>
      authedGetService(config.instructorInfo, {
        instructorNumber,
        instructorType,
      }),
    options
  );

export const useEFRPublicSubmit = (options) =>
  useRequestCommon(
    (data) => authedPostService(config.efrPublicSubmit, data),
    options
  );

export const useIssueCertsCreate = (type, options) =>
  useRequestCommon(
    (data) => authedPostService(issueCertsOptions[type].submit, data),
    options
  );

export const issueCertsOptions = {
  EFRP: {
    pay: '/api/EFRPublicApplication/getPaymentUrl',
    confirm: '/api/EFRPublicApplication/confirm',
    list: '/api/EFRPublicApplication/list',
    submit: '/api/EFRPublicApplication/submit',
    instructorType: 'EFRPI',
    nameId: 'issue-certs-efrp-name',
  },
  SCUBA_DIVER_UPGRADE: {
    pay: '/api/scubaDiverUpgrade/getPaymentUrl',
    confirm: '/api/scubaDiverUpgrade/confirm',
    list: '/api/scubaDiverUpgrade/list',
    submit: '/api/scubaDiverUpgrade/submit',
    instructorType: 'OWSI',
    nameId: 'issue-certs-scuba2ow-name',
  },
  CONFINED_WATER_DIVER_PIC: {
    pay: '/api/confinedWaterDiver/PICOrder',
    list: '/api/confinedWaterDiver/PICOrder',
    summary: '/api/confinedWaterDiver/PICDetail',
    nameId: 'issue-certs-confined-pic-name',
    toCert: 'CONFINED_WATER_DIVER',
  },
  SCUBA_DIVER_PIC: {
    pay: '/api/scubaDiver/PICOrder',
    list: '/api/scubaDiver/PICOrder',
    summary: '/api/scubaDiver/PICDetail',
    nameId: 'issue-certs-scuba-pic-name',
    toCert: 'SCUBA_DIVER',
  },
  CONFINED_WATER_DIVER: {
    confirm: '/api/confinedWaterDiver/confirm',
    list: '/api/confinedWaterDiver/list',
    submit: '/api/confinedWaterDiver/submit',
    instructorType: 'OWSI',
    nameId: 'issue-certs-confined-name',
  },
  SCUBA_DIVER: {
    confirm: '/api/scubaDiver/confirm',
    list: '/api/scubaDiver/list',
    submit: '/api/scubaDiver/submit',
    instructorType: 'OWSI',
    nameId: 'issue-certs-scuba-name',
  },
  CONFINED_WATER_DIVER_UPGRADE: {
    pay: '/api/confinedWaterDiverUpgrade/getPaymentUrl',
    confirm: '/api/confinedWaterDiverUpgrade/confirm',
    list: '/api/confinedWaterDiverUpgrade/list',
    submit: '/api/confinedWaterDiverUpgrade/submit',
    instructorType: 'OWSI',
    nameId: 'issue-certs-confined2ow-name',
  },
};

export const usePicSummary = (type, options) =>
  useRequestCommon(
    type ? () => authedGetService(issueCertsOptions[type].summary) : () => {},
    { manual: false, ...options }
  );
export const useIssueCertsList = (type, options) => {
  return usePaginationCommon(
    type ? authedPagedService(issueCertsOptions[type].list) : () => {},
    options
  );
};

const getPaymentService = (type) => {
  const url = issueCertsOptions[type]?.pay;
  if (!url) return () => () => {};
  const result =
    type === 'CONFINED_WATER_DIVER_PIC' || type === 'SCUBA_DIVER_PIC'
      ? (codeOrAmount) => authedPostService(url, { number: codeOrAmount })
      : (codeOrAmount) => authedGetService(url, { code: codeOrAmount });

  return result;
};
export const useIssueCertsPay = (type, options) => {
  const { replace } = useHistory();
  const { formatMessage } = useIntl();
  const url = issueCertsOptions[type]?.pay;
  return useRequestCommon(getPaymentService(type), {
    // onSuccess: (data, params) => {
    //   const code = data.code || params[0];
    //   if (data.url) {
    //     window.location.replace(data.url);
    //   } else {
    //     Dialog.confirm({
    //       content: formatMessage(
    //         { id: 'paypal-reminder' },
    //         { price: data.price, currency: data.currency }
    //       ),
    //       onConfirm: () => replace(`/credit_card?id=${code}&type=${type}`),
    //       confirmText: formatMessage({ id: 'common-comfirmBtn' }),
    //       cancelText: formatMessage({ id: 'common-close' }),
    //     });
    //   }
    // },
    ...options,
  });
};

// export const useIssueCertsPay = (type, options) => {
//   const url = issueCertsOptions[type] ?.pay;
//   return useRequestCommon(
//     (code) => authedGetService(url, { code }), {
//       ...options
//     }
//   );
// }

export const usePicOrderAddress = (options) =>
  useRequestCommon(
    () =>
      authedGetService(
        getParameterByName('type')
          ? config.picOrderAddress
          : config.textBookSaleAddress
      ),
    {
      manual: false,
      ...options,
    }
  );

export const usePicOrder = (options) =>
  useRequestCommon(
    (params) =>
      authedPostService(
        getParameterByName('type') ? config.picOrder : config.textBookSaleOrder,
        params
      ),
    options
  );

export const usePicOrderCreate = (options) =>
  useRequestCommon(
    (params) => authedPostService(
      getParameterByName('type') ? config.picOrderCreate : config.createTextBookSaleOrder,
      params
    ),
    options
  );

export const useBuyPic = (options) =>
  useRequestCommon(
    (amount) => authedPostService(config.buyPic, { number: amount }),
    options
  );

export const useIssueCertsConfirm = (type, options) =>
  useRequestCommon(
    (code) => authedPostService(issueCertsOptions[type].confirm, { code }),
    options
  );

export const useEFRPublicPay = (options) =>
  useRequestCommon(
    (code) => authedGetService(config.efrPublicPay, { code }),
    options
  );

export const useEFRPublicConfirm = (options) =>
  useRequestCommon(
    (code) => authedPostService(config.efrPublicConfirm, { code }),
    options
  );

export const useEFRPublicAppSubmit = (options) =>
  useRequestCommon(() => authedPostService(config.efrPublicAppSubmit), options);

export const useEFRPublicAppDetail = (options) =>
  useRequestCommon(
    () => authedGetService(config.efrPublicAppDetail, {}),
    options
  );

export const useEcards = (options) =>
  useRequestCommon(() => authedGetService(config.ecards, {}), {
    manual: false,
    cacheKey: 'ecards',
    ...options,
  });

export const useAddToCart = (options) =>
  useRequestCommon(
    (items) => authedPostService(config.addToCart, items),
    options
  );

export const useBuyCards = (options) =>
  useRequestCommon(
    (items) => authedPostService(config.getOrderYouzanUrl, items),
    options
  );

export const useShopCart = (options) =>
  useRequestCommon(() => authedGetService(config.getShoppingCart), {
    manual: false,
    ...options,
  });

export const useCardOptions = (options) =>
  useRequestCommon(() => authedGetService(config.cardOptions), {
    manual: false,
    ...options,
  });

export const useGetFreeCred = (options) =>
  useRequestCommon((ecardId) => authedPostService(config.credentialRequest, { ecardId }), {
    ...options,
  });

export const useHasLogin = (options) =>
  useRequestCommon(() => authedGetService(config.hasLogin), {
    manual: false,
    ...options,
  });

export const usePwInfo = (options) =>
  useRequestCommon(() => authedGetService(config.pwInfo), {
    manual: false,
    ...options,
  });

export const useProfile = (options) =>
  useRequestCommon(() => authedGetService(config.profiles), {
    manual: false,
    ...options,
  });

export const useDownloadWallCert = (options) =>{
  const showErrorModal = useErrorModal();

  return useRequestCommon(
    (data) =>
      authedGetService(
        config.downloadWallCert,
        data,
        'blob'
      ),
    {
      onSuccess: (blob) => {
        if(blob.type === 'application/json') {
          blob.text().then(text => {
            const result = JSON.parse(text);
            showErrorModal(result?.error?.clientMsg);
          })
          return;
        }
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        // a.target = '_blank';
        a.download = 'download.jpg';
        document.body.appendChild(a);
        a.click();
        a.remove();
      },
      ...options,
    }
  )};

export const useQualification = (cert, options) =>
  useRequestCommon(
    isCert(cert)
      ? () =>
          authedGetService(
            config.certificationQualifications,
            {
              certificationId: cert.certificationNumber,
            },
            'text'
          )
      : () =>
          authedGetService(
            config.credentialQualifications,
            {
              affiliateCredentialId: cert.affiliateCredentialId,
            },
            'text'
          ),
    {
      manual: false,
      ...options,
    }
  );

  export const useHasActivity = (activityCode, options) =>
    useRequestCommon(() => authedGetService(config.hasActivityCard, { activityCode }), {
      manual: false,
      ...options,
    });

  export const useBindEcard = (options) =>
    useRequestCommon((data) => authedPostService(config.ecardImgBinding, data), {
      ...options,
    });

  export const useNotification = (position, options) =>
    useRequestCommon(() => authedGetService(config.notification + position), {
      manual: false,
      ...options,
    });

  export const useGetRegulatorCoupon = (options) =>
    useRequestCommon(
      () => authedGetService(config.getRegulatorCoupon), {
        manual: false,
        ...options
      }
    );

  export const useCheckRegulatorCoupon = (options) =>
    useRequestCommon(
      (data) => postService(config.checkRegulatorCoupon, data), {
        ...options
      }
    );

  export const useGetStandardRegions = (options) =>
    useRequestCommon(
      () => authedGetService(config.getStandardRegions), {
        manual: false,
        ...options
      }
    );

  export const useMergeCerts = (options) =>
    useRequestCommon(
      () => authedPostService(config.certsMerge, {}), {
        ...options
      }
    );

export const useGetCertsMerge = (options) =>
  useRequestCommon(
    ({current, size}) => authedGetService(config.certsMergeList, { page: current, size }), {
      // manual: false,
      ...options
    }
  );

export const useAdminMergeCerts = (options) =>
  useRequestCommon(
    (data) => authedPostService(config.mergeCerts, data), {
      ...options
    }
  );

export const useMergeRefind = (options) =>
  useRequestCommon(
    (findId) => authedPutService(config.refindMergeCerts, { findId }), {
      ...options
    }
  );

export const useDeleteMergeCerts = (options) =>
  useRequestCommon(
    (findId) => authedDeleteService(config.deleteMergeCerts, { findId }), {
      ...options
    }
  );

export const useGetUserPhoto = (options, id) =>
  useRequestCommon(
    () => getDataByPath(CERT_API_ROOT + config.userPhoto, id), {
      manual: false,
      ...options
    }, 'text', true
  );

export const useAdminMergeComplete = (options) =>
  useRequestCommon(
    (data) => authedPostService(config.mergeComplete, data), {
      ...options
    }
  );

export const useGetUserPhotoManual = (options) =>
  useRequestCommon(
    (id) => getDataByPath(CERT_API_ROOT + config.userPhoto, id), {
      ...options
    }, 'text', true
  );

export const useFindMeSearch = (options) =>
  useRequestCommon(
    (data) => authedGetService(config.findMeSearch, data), {
      // manual: false,
      ...options
    }
  );

export const useDeleteMemberApplication = (options) =>
  useRequestCommon(
    (data) => authedDeleteService(config.cancelApplication, data), {
      ...options
    }
  );

export const useGetFindAccountList = (options) =>
  useRequestCommon(
    ({ current, size }) => authedGetService(config.findAccountList, { page: current, size }), {
      // manual: false,
      ...options
    }
  );

export const useFindAccountRefind = (options) =>
  useRequestCommon(
    (findId) => authedPutService(config.findAccountRefind, { findId }), {
      ...options
    }
  );

export const useDeleteFindAccount = (options) =>
  useRequestCommon(
    (findId) => authedDeleteService(config.findAccountDelete, { findId }), {
      ...options
    }
  );

export const useAdminMergeAccount = (options) =>
  useRequestCommon(
    (data) => authedPostService(config.findAccuntMerge, data), {
      ...options
    }
  );

export const useAdminFindAccountComplete = (options) =>
  useRequestCommon(
    (data) => authedPostService(config.findAccountMergeComplete, data), {
      ...options
    }
  );

export const useSubmitFindMeByRequest = (options) =>
  useRequestCommon(
    (data) => authedPostService(config.submitFindMeRequest, data), {
      ...options
    }
  );

export const useUpdateUserInfo = (options) =>
  useRequestCommon(
    (data) => authedPostService(config.updateUserInfo, data), {
      ...options
    }
  );

export const useUpdateUserInfoApproved = (options) =>
  useRequestCommon(
    (data) => authedPutService(config.adminApproveUpdateUserInfo, data), {
      ...options
    }
  );
export const useAdminDeleteMemberApplication = (options) =>
  useRequestCommon(
    (data) => authedDeleteService(config.adminCancelApplication, data), {
      ...options
    }
  );

export const useAdminUpdateComplete = (options) =>
  useRequestCommon(
    (data) => authedPostService(config.adminCompleteUpdate, data), {
      ...options
    }
  )

export const useSubmitUserAgreement = (options) =>
  useRequestCommon(
    (data) => authedPostService(config.UserAgreementSubmit, data), {
      ...options
    }
  )

export const useCheckAgreement = (options) =>
  useRequestCommon(
    () => authedGetService(config.checkAgreement),
    {
      manual: false,
      ...options,
    }
  );

export const useAdminFindmeRegiste = (options) =>
  useRequestCommon(
    (id) => authedPostService(config.adminFindmeRegiste, { id }), {
      ...options
    }
  );

export const useGetAdminWenjuanList = (options) =>
  useRequestCommon(
    () => authedGetService(config.adminWenjuanList),
    {
      manual: false,
      ...options,
    }
  );

export const useAdminWenjuanApproved = (options) =>
  useRequestCommon(
    (data) => authedPutService(config.adminWenjuanApproved, data), {
      ...options
    }
  );

export const useGetOssSignature = (type, options) =>
  useRequestCommon(
    () => authedGetService(config.ossSignature, { type }), {
      manual: false,
      ...options
    }
  );

export const useGetOssFullUrl = (key, options) =>
  useRequestCommon(
    (key) => authedGetService(config.getOssFile, { key }, 'text'), {
      ...options
    }
  );

export const useGetOtherAppsTypeList = (manual, options) =>
  useRequestCommon(
    () => authedGetService(config.getOtherAppsTypeList, {}), {
      manual: !manual,
      ...options
    }
  );

export const useAdminOtherAppsApproved = (options) =>
  useRequestCommon(
    (data) => authedPutService(config.adminOtherAppsApproved, data), {
      ...options
    }
  );

export const useGetOtherAppsList = (current, options) =>
  useRequestCommon(
    () => authedGetService(config.getOtherAppsList, { page: current, size: 15 }), {
      // manual: false,
      ...options
    }
  );

export const useUploadFileToOss = (options) =>
    useRequestCommon(
      (ossHost, formData) => fetchOssFile(ossHost, formData), {
        ...options
      }
    );

export const useSubmitOtherApps = (options) =>
  useRequestCommon(
    (data) => authedPostService(config.submitOtherApps, data), {
      ...options
    }
  );

export const usePayOtherApps = (options) =>
  useRequestCommon(
    (code) => authedGetService(config.otherAppsPay, { code }), {
      ...options
    }
  );

export const useGetAdsList = (options) =>
  useRequestCommon(
    () => authedGetService(config.adsList, {}), {
      manual: false,
      ...options
    }
  );

export const useGetStoreAppsList = (current, options) =>
  useRequestCommon(
    () => authedGetService(config.getStoreAppsList, { page: current, size: 15 }), {
      // manual: false,
      ...options
    }
  );

export const useGetUserAdsList = (options) =>
  useRequestCommon(
    () => authedGetService(config.userAdsList, {}), {
      manual: false,
      ...options
    }
  );

export const useSubmitStoreApps = (options) =>
  useRequestCommon(
    (data) => authedPostService(config.submitStoreApps, data), {
      ...options
    }
  );

export const useSubmitAds = (options) =>
  useRequestCommon(
    (data) => authedPostService(config.submitAds, data), {
      ...options
    }
  );

export const useAdminStoreAppsApproved = (options) =>
  useRequestCommon(
    (data) => authedPutService(config.adminStoreAppsApproved, data), {
      ...options
    }
  );

export const useDeletePocketApplication = (options) =>
  useRequestCommon(
    (data) => authedDeleteService(config.deleteOtherApps, data), {
      ...options
    }
  );

export const useDeleteStoreApplication = (options) =>
  useRequestCommon(
    (data) => authedDeleteService(config.deleteStoreApps, data), {
      ...options
    }
  );

export const usePayDm = (options) =>
  useRequestCommon(
    (code) => authedGetService(config.dmPay, { code }), {
      ...options
    }
  );

export const useSaveInvoice = (options) =>
  useRequestCommon(
    (data) => authedPostService(config.saveInvoice, data), {
      ...options
    }
  );

export const usePayOwsi = (options) =>
  useRequestCommon(
    (code) => authedGetService(config.owsiCdPay, { code }), {
      ...options
    }
  );

export const useGetInvoice = (options) =>
  useRequestCommon(
    () => authedGetService(config.getInvoice, {}), {
      manual: false,
      ...options
    }
  );

export const usePayBasicMermaid = (options) =>
  useRequestCommon(
    (code) => authedGetService(config.paybasicMermaid, { code }), {
      ...options
    }
  );

export const usePayMermaid = (options) =>
  useRequestCommon(
    (code) => authedGetService(config.payMermaidi, { code }), {
      ...options
    }
  );

export const usePayEfri = (options) =>
  useRequestCommon(
    (code) => authedGetService(config.efriPay, { code }), {
      ...options
    }
  );

export const usePayFdi = (options) =>
  useRequestCommon(
    (code) => authedGetService(config.payFdi, { code }), {
      ...options
    }
  );

export const usePayAfdi = (options) =>
  useRequestCommon(
    (code) => authedGetService(config.payAfdi, { code }), {
      ...options
    }
  );

export const usePayMfdi = (options) =>
  useRequestCommon(
    (code) => authedGetService(config.payMfdi, { code }), {
      ...options
    }
  );

export const usePayFdpic = (options) =>
  useRequestCommon(
    (code) => authedGetService(config.fdPicPay, { code }), {
      ...options
    }
  );

export const usePaySi = (options) =>
  useRequestCommon(
    (code) => authedGetService(config.getSIAppYouzanUrl, { code }), {
      ...options
    }
  );

export const useUseInvoice = (options) =>
  useRequestCommon(
    (data) => authedPostService(config.useInvoice, data), {
      ...options
    }
  );

export const useAnniversaryInstructor = (options) =>
  useRequestCommon(
    () => authedGetService(config.anniversaryInstructor, {}), {
      manual: false,
      ...options
    }
  );

export const useEliteInstructor = (options) =>
  useRequestCommon(
    () => authedGetService(config.eliteInstructor, {}), {
      manual: false,
      ...options
    }
  );

export const useDownloadEliteAward = (options) => {
  const showErrorModal = useErrorModal();

  return useRequestCommon(
    (data) =>
      authedGetService(
        config.downEliteAward,
        data,
        'blob'
      ),
    {
      onSuccess: (blob) => {
        if (blob.type === 'application/json') {
          blob.text().then(text => {
            const result = JSON.parse(text);
            showErrorModal(result ?.error ?.clientMsg);
          })
          return;
        }
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        // a.target = '_blank';
        a.download = 'download.jpg';
        document.body.appendChild(a);
        a.click();
        a.remove();
      },
      ...options,
    }
  )
};

export const useDownloadAnniversaryAward = (options) => {
  const showErrorModal = useErrorModal();

  return useRequestCommon(
    (data) =>
      authedGetService(
        config.downAnniversaryAward,
        data,
        'blob'
      ),
    {
      onSuccess: (blob) => {
        if (blob.type === 'application/json') {
          blob.text().then(text => {
            const result = JSON.parse(text);
            showErrorModal(result ?.error ?.clientMsg);
          })
          return;
        }
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        // a.target = '_blank';
        a.download = 'download.jpg';
        document.body.appendChild(a);
        a.click();
        a.remove();
      },
      ...options,
    }
  )
};

export const useInstructorCreate = (options) =>
  useRequestCommon(
    (data) => authedPostService(config.instructorCreate, data), {
      ...options
    }
  );

export const useSubmitEfri = (options) =>
  useRequestCommon(
    (data) => authedPostService(config.submitEfri, data), {
      ...options
    }
  );

export const useStripeResult = (options) =>
  useRequestCommon(
    (sessionId) => authedGetService(config.stripeResult, { sessionId }), {
      // manual: false,
      ...options
    }
  );

export const useSubmitOwsi = (options) =>
  useRequestCommon(
    (data) => authedPostService(config.submitOwsi, data), {
      ...options
    }
  );

export const useSubmitBasicMermaid = (options) =>
  useRequestCommon(
    (data) => authedPostService(config.submitBasicMermaid, data), {
      ...options
    }
  );

export const useSubmitMermaid = (options) =>
  useRequestCommon(
    (data) => authedPostService(config.submitMermaidi, data), {
      ...options
    }
  );

export const useSubmitFdi = (options) =>
  useRequestCommon(
    (data) => authedPostService(config.submitFdi, data), {
      ...options
    }
  );

export const useSubmitAfdi = (options) =>
  useRequestCommon(
    (data) => authedPostService(config.submitAfdi, data), {
      ...options
    }
  );

export const useSubmitMfdi = (options) =>
  useRequestCommon(
    (data) => authedPostService(config.submitMfdi, data), {
      ...options
    }
  );

export const useSubmitSiApp = (options) =>
  useRequestCommon(
    (data) => authedPostService(config.submitSi, data), {
      ...options
    }
  );
export const useTaiwanIp = (options) =>
  useRequestCommon(
    () => authedGetService(config.getTaiWanIP, {}), {
      manual: false,
      ...options
    }
  );

export const useSubmitFdPic = (options) =>
  useRequestCommon(
    (data) => authedPostService(config.submitFdPic, data), {
      ...options
    }
  );

export const useSubmitDm = (options) =>
  useRequestCommon(
    (data) => authedPostService(config.submitDmForm, data), {
      ...options
    }
  );

export const useMainlandIp = (options) =>
  useRequestCommon(
    () => authedGetService(config.getMainlandIp, {}), {
      manual: false,
      ...options
    }
  );

export const useGetM2PhoneNumber = (options) =>
  useRequestCommon(
    (email) => authedGetService(config.getM2PhoneNumber, { email }), {
      ...options
    }
  );

export const useTradeVanResult = (options) =>
  useRequestCommon(
    (payToken) => authedGetService(config.tradeVanPayResult, { payToken }), {
      // manual: false,
      ...options
    }
  );

export const useWxPayJssdk = (options) =>
  useRequestCommon(
    (data) => authedPostService(config.wxPayJssdk, data), {
      ...options
    }
  );

export const useGetPicOrderList = (current, options) =>
  useRequestCommon(
    () => authedGetService(config.textbookOrderList, { page: current, size: 15 }), {
      ...options
    }
  );

export const useGetUnconventionalOrderList = (current, options) =>
  useRequestCommon(
    () => authedGetService(config.unconventionalOrderList, { page: current, size: 15 }), {
      ...options
    }
  );

export const useGetInstructorInfo = (options) =>
  useRequestCommon(
    (memberNumber) => authedGetService(config.getInstructorInfo, { memberNumber }), {
      ...options
    }
  );

export const useGetPicOrderDetail = (id, options) =>
  useRequestCommon(
    () => authedGetService(config.textbookOrderDetail, { id }), {
      manual: false,
      ...options
    }
  );
export const useGetUnconventionalOrderDetail = (id, options) =>
  useRequestCommon(
    () => authedGetService(config.unconventionalOrderDetail, { id }), {
      manual: false,
      ...options
    }
  );

export const useGetStoreInfo = (options) =>
  useRequestCommon(
    (memberNumber) => authedGetService(config.getStoreInfo, { memberNumber }), {
      ...options
    }
  );

  export const useTextbookOrderPayment = (options) =>
    useRequestCommon(
      (id) => authedGetService(config.textbookOrderPayment, { id }), {
        ...options
      }
  );

  export const useUnconventionalOrderPayment = (options) =>
    useRequestCommon(
      (id) => authedGetService(config.unconventionalOrderPayment, { id }), {
        ...options
      }
  );

export const useChangeOrderStatus = (options) =>
  useRequestCommon(
    (data) => authedPutService(config.changeOrderStatus, data), {
      ...options
    }
  );

export const useCreateOrder = (options) =>
  useRequestCommon(
    (data) => authedPostService(config.createOrder, data), {
      ...options
    }
  );