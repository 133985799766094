import React, { useState } from 'react';
import { Form, Input, Button } from 'antd-mobile-v5';
import { CheckCircleFill, LockOutline } from 'antd-mobile-icons';
import { useIntl, FormattedMessage } from 'react-intl';
import { useCountDown } from 'ahooks';
import {
  useGetMobileVerificationCode,
  useVerifyMobile,
} from '../../services/hooks/user';
import { useShowMessage } from '../../services/hooks/ui';
import { Picker, Space } from 'antd-mobile-v5'
import { DownOutline } from 'antd-mobile-icons'
import { countryCodeList } from '../../components/common/country-code-utils'
import { useMainlandIp } from '../../services/hooks'

import styles from './Register.module.css';

export default function MobileVerify({ 
  form, 
  emailVerified, 
  onVerifyResult = () => { }, 
  countryCode,
  setCountryCode
}) {
  const { formatMessage } = useIntl();
  const showMessage = useShowMessage();
  const [targetDate, setTargetDate] = useState();
  const [mobileVerified, setMobileVerified] = useState(false);
  const [visible, setVisible] = useState(false)

  const { loading: getCodeLoading, run: submitGetCode } =
    useGetMobileVerificationCode({
      onSuccess: (data) => {
        setTargetDate(Date.now() + 99000);
        setMobileVerified(false);
        showMessage('getCode-sendMobile');
      },
    });

  const { loading: verifying, run: verifyMobile } = useVerifyMobile({
    onSuccess: (data) => {
      if (data?.verifyMobileCode) {
        setMobileVerified(true);
        onVerifyResult({ verified: true, youzanVerified: data.verifyYouzan});
      }
    },
    onError: (error, [{ countryCode, mobile }]) => {
      if(error.code === 'mobile.invalid.code') {
        form.setFields([
          {
            name: 'mobileCode',
            errors: [error.clientMsg],
          },
        ]);
        return true;
      }
      onVerifyResult({countryCode, mobile, verified: false});
    },
  });


  const { data: isMainlandIp } = useMainlandIp({});

  const [countdown] = useCountDown({
    targetDate,
  });

  const getCode = async () => {
    onVerifyResult({ verified: false });
    if(!emailVerified) {
      form.setFields([
        {
          name: 'email',
          errors: [formatMessage({id: 'register-email-not-verified'})],
        },
      ]);
      return
    }
    await form.validateFields(['mobile']);
    submitGetCode({
      countryCode: form.getFieldValue('countryCode'),
      mobile: form.getFieldValue('mobile'),
    });
  };

  const validate = async () => {
    await form.validateFields(['email', 'mobile', 'mobileCode']);
    const mobile = form.getFieldValue('mobile')
    if(!mobile) {
      form.setFields([
        {
          name: 'mobile',
          errors: ['请输入正确的手机号'],
        },
      ]);
      return
    }
    verifyMobile({
      email: form.getFieldValue('email'),
      countryCode: form.getFieldValue('countryCode'),
      mobile: form.getFieldValue('mobile'),
      code: form.getFieldValue('mobileCode'),
    });
  };

  const onPreValueChange = (value) => {
    form.setFieldsValue({ countryCode: value[0] });
    setCountryCode(value[0])
  }
  
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: 'var(--padding-left)' }}>
          <Space align='center' onClick={() => setVisible(true)}>
            <div>+{countryCode}</div>
            <DownOutline />
          </Space>
          <Form.Item
            name="mobile"
            form={form}
            style={{ '--prefix-width': '3.6em', flex: 1 }}
            extra={isMainlandIp && countryCode === '86' && 
              <Button
                disabled={getCodeLoading || countdown > 0}
                loading={getCodeLoading}
                size="small"
                fill="none"
                onClick={getCode}
              >
                {countdown > 0 ? (
                  Math.round(countdown / 1000) + 's'
                ) : (
                    <FormattedMessage id="common-getCode" />
                  )}
              </Button>
            }
            rules={[
              {
                required: true,
                message: formatMessage({ id: 'setMobile-mobileInput' }),
              },
              {
                pattern: /^[0-9]*$/,
                message: formatMessage({ id: 'common-errorMobile' }),
              }
            ]}
          >
            <Input placeholder={formatMessage({ id: 'setMobile-mobileInput' })} />
          </Form.Item>
        </div>
        <Form.Item name="countryCode" noStyle>
          <Picker
            columns={[countryCodeList]}
            visible={visible}
            onClose={() => {
              setVisible(false)
            }}
            onConfirm={onPreValueChange}
          />
        </Form.Item>
        {isMainlandIp && countryCode === '86'  && <Form.Item
          label={<LockOutline />}
          name="mobileCode"
          className={styles.formItem}
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'common-error-verification-code' }),
            },
            {
              pattern: /^\d{6}$/,
              message: formatMessage({ id: 'common-error-verification-code' }),
            },
          ]}
          extra={
            mobileVerified ? (
              <CheckCircleFill fontSize={24} color="var(--adm-color-success)" style={{ marginRight: '12px' }} />
            ) : (
                <Button
                  disable={verifying}
                  loading={verifying}
                  size="small"
                  fill="none"
                  onClick={validate}
                >
                  <FormattedMessage id="common-validate" />
                </Button>
              )
          }
        >
          <Input
            type="password"
            placeholder={formatMessage({ id: 'common-codeInput' })}
          />
        </Form.Item>}
      </>
    );
}
