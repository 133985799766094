import React from 'react'
import { WhiteSpace, WingBlank } from 'antd-mobile';
import { CheckCircleFill } from 'antd-mobile-icons'
import styles from './FindAccount.module.css'
import { useIntl } from 'react-intl'; 
import { Button } from 'antd-mobile-v5'
import { useHistory } from 'react-router-dom'

const SubmitResult = () => {

  const { formatMessage } = useIntl()
  const { push } = useHistory()

  return <WingBlank size="lg">
    <WhiteSpace size="lg" />
    <WhiteSpace size="lg" />
    <WhiteSpace size="lg" />
    <div className={styles['status-icon']}>
      <CheckCircleFill fontSize={58} color='var(--adm-color-primary)' />
    </div>
    <WhiteSpace size="lg" />
    <WhiteSpace size="lg" />
    <WhiteSpace size="lg" />
    <div>{formatMessage({ id: "findme-submit-tips" })}</div>
    <WhiteSpace size="lg" />
    <WhiteSpace size="lg" />
    <WhiteSpace size="lg" />
    <Button
      color='primary'
      fill='solid'
      block
      onClick={() => push('/emailLogin')}
    >
      {formatMessage({ id: 'common-back' })}
    </Button>
  </WingBlank>
}

export default SubmitResult