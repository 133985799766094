import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl';
import Header from '../../components/FormTools/FormHeader'
import { useLanguage } from '../../services/hooks/user';
import { CheckList, Form, Toast } from 'antd-mobile-v5';
import UploadOssFile from '../../components/FormTools/UploadOssFile'
import { useGetOssSignature, useSubmitStoreApps } from '../../services/hooks'
import { removeExpires } from '../../components/common/utils'
import { storeLevel } from '../../components/common/StoreLevel'
import Status from './Status'

const StoreApplicationPage = () => {

  const lang = useLanguage();
  const [form] = Form.useForm();
  const { push } = useHistory()
  const { formatMessage } = useIntl();
  const { state: { detail } } = useLocation()
  const required = true

  const { data: ossSignature } = useGetOssSignature('STORE_APPLICATION');
  const { runAsync: submit, loading: submitting } = useSubmitStoreApps();

  useEffect(() => {
    if (detail) form.setFieldsValue({ files: detail.files, type: [detail.type] })
  }, [detail])

  const filename = (dir, type) => `${dir}${type}/${new Date().getTime()}`

  const onFinish = (values) => {
    const data = { type: values.type[0], files: removeExpires(values.files) }
    if (detail && detail.code) data.code = detail.code
    submit(data).then(res => {
      if (res.code) {
        Toast.show({
          icon: 'success',
          content: formatMessage({ id: 'common-submit-success' }),
          afterClose: () => {
            push('/store_apps_list')
          },
        })
      }
    })
  }

  const isFormEditable = () => {
    if (!detail || detail && detail.status === 'ADMIN_NOT_APPROVED') return true
    else return false
  }

  const changeFiles = (files) => {
    form.setFieldsValue({ files })
  }

  const formatFileList = () => {
    const newFileList = detail.files.map(file => {
      return { url: file }
    })
    return newFileList
  }

  return <>
    <Header
      text={<FormattedMessage id="store-apps" />}
      code={detail ?.code}
    />
    <Form
      disabled={submitting}
      form={form}
      validateTrigger="onSubmit"
      onFinish={onFinish}
    >
      <Form.Item
        name='type'
        label={formatMessage({ id: "store-apps-type" })}
        rules={
          required && [
            {
              required: true,
              message: formatMessage({ id: 'store-apps-error' }),
            },
          ]
        }>
          <CheckList readOnly={!isFormEditable()}>
          {storeLevel.map(type => <CheckList.Item 
            value={type['name_en']} 
            key={type[`name_${lang}`]}>
              {type[`name_${lang}`]}
            </CheckList.Item>)}
          </CheckList>
      </Form.Item>
      {ossSignature && <Form.Item
        name='files'
        label={formatMessage({ id: "si-upload-btn" })}
        form={form}
        rules={
          required && [
            {
              required: true,
              message: formatMessage({ id: 'form-upload-error' }),
            },
          ]
        }
      >
        <UploadOssFile
          form={form}
          fileName={filename(ossSignature.dir, 'store')}
          ossSignature={ossSignature}
          onSuccess={changeFiles}
          editable={isFormEditable()}
          fileList={detail ? formatFileList() : null}
        />
      </Form.Item>}
      <Status
        status={detail ?.status}
        form={form}
        code={detail ?.code}
        submitting={submitting}
        note={detail ?.note} />
    </Form>
  </>
}

export default StoreApplicationPage