import React from 'react';
import { WhiteSpace, Flex, Card } from 'antd-mobile';
import { injectIntl } from 'react-intl';

import styles from './Renew.module.css'

const getTotal = (selectedEfr, memberRenewList) => {
  let total
  if (selectedEfr && JSON.stringify(selectedEfr) !== '{}') {
    total = selectedEfr.price
  } else if (excludeEfrPrice(memberRenewList)) {
    total = excludeEfrPrice(memberRenewList)
  }
  return total
}

const excludeEfrPrice = (memberRenewList) => {
  const { renewalMemberDTOS } = memberRenewList
  const freediver = renewalMemberDTOS.find(item => !item.isEfr)
  return freediver.price
}

const getEfrPrice = (selectedEfr, memberRenewList) => {
  if (selectedEfr.isEfr && !selectedEfr.isFreediver && !selectedEfr.isScubadiver) {
    return selectedEfr.price
  } else if (JSON.stringify(selectedEfr) !== '{}' && excludeEfrPrice(memberRenewList)) {
    return selectedEfr.price - excludeEfrPrice(memberRenewList)
  } else {
    return 0
  }
}

const getDiscountPrice = (memberRenewList) => {
  const { renewalMemberDTOS } = memberRenewList
  const discount = renewalMemberDTOS.find(item => item.discountPrice && !item.isEfr)
  return discount ? discount.discountPrice : ''
}

const getEfrDiscountPrice = (selectedEfr, memberRenewList) => {
  if (selectedEfr.isEfr && !selectedEfr.isFreediver && !selectedEfr.isScubadiver) {
    return selectedEfr.discountPrice
  } else if (JSON.stringify(selectedEfr) !== '{}' && excludeEfrPrice(memberRenewList)) {
    return selectedEfr.discountPrice - getDiscountPrice(memberRenewList)
  }
}

const getDiscountTotal = (selectedEfr, memberRenewList) => {
  let total
  if (selectedEfr && JSON.stringify(selectedEfr) !== '{}') {
    total = selectedEfr.discountPrice
  } else if (excludeEfrPrice(memberRenewList)) {
    total = getDiscountPrice(memberRenewList)
  }
  return total
}

const RenewStatements = ({ memberRenewList, memberRenewList: { isScubadiver, isFreediver, isEfr }, selectedEfr, intl: { formatMessage } }) => {
  return <Card>
    <Card.Header
      title={formatMessage({ id: 'renew-statement' })}
    />
    <Card.Body>
      <div className='renew-price'>
        {isScubadiver && <Flex justify="center">
          <Flex.Item className='renew-price-title'>Instructor:</Flex.Item>
          <Flex.Item>
            <span className={getDiscountPrice(memberRenewList) ? styles.crossPrice : ''}>
              ¥ {excludeEfrPrice(memberRenewList) / 100}
            </span>
            {getDiscountPrice(memberRenewList) && <span>¥ {getDiscountPrice(memberRenewList) / 100}</span>}
          </Flex.Item>
        </Flex>}
        {isFreediver && <div>
          <WhiteSpace />
          <Flex>
            <Flex.Item className='renew-price-title'>Freediver:</Flex.Item>
            <Flex.Item>
              <span className={!(isScubadiver && isFreediver) && getDiscountPrice(memberRenewList) ? styles.crossPrice : ''}>
                ¥ {isScubadiver && isFreediver ? 0 : excludeEfrPrice(memberRenewList) / 100}
              </span>
              {!(isScubadiver && isFreediver) && getDiscountPrice(memberRenewList) && <span>¥ {getDiscountPrice(memberRenewList) / 100}</span>}
            </Flex.Item>
          </Flex>
        </div>}
        {isEfr && <div>
          <WhiteSpace />
          <Flex>
            <Flex.Item className='renew-price-title'>EFRI:</Flex.Item>
            <Flex.Item>
              <span className={getEfrDiscountPrice(selectedEfr, memberRenewList) ? styles.crossPrice : ''}>
                ¥ {getEfrPrice(selectedEfr, memberRenewList) / 100}
              </span>
              {getEfrDiscountPrice(selectedEfr, memberRenewList) && <span>¥ {getEfrDiscountPrice(selectedEfr, memberRenewList) / 100}</span>}
            </Flex.Item>
          </Flex>
        </div>}
        <WhiteSpace />
        <Flex className='renew-total'>
          <Flex.Item className='renew-price-title renew-total-title'>Total:</Flex.Item>
          <Flex.Item>
            <span className={getDiscountTotal(selectedEfr, memberRenewList) ? styles.crossPrice : ''}>
              ¥ {getTotal(selectedEfr, memberRenewList) / 100}
            </span>
            {getDiscountTotal(selectedEfr, memberRenewList) && <span>¥ {getDiscountTotal(selectedEfr, memberRenewList) / 100}</span>}
          </Flex.Item>
        </Flex>
      </div>
      {/*<div className={styles.tips}>{formatMessage({ id: 'renew-statement-tips' })}</div>*/}
    </Card.Body>
  </Card>
}

export default injectIntl(RenewStatements)