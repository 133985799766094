import React from 'react';
import { List, WhiteSpace } from 'antd-mobile';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom'
import ScubaMenu from './ScubaMenu'
import EFRMenu from './EFRMenu'
import MermaidMenu from './MermaidMenu'
import FreediveMenu from './FreediveMenu'

export default ({
  showSIApplication,
  showInstructorDMApplication,
  showCDApplication,
  showOWSIApplication,
  showDMApplication,
  showEFRIApplication,
  showEFRITApplication,
  showEFRApplication,
  showPADIBasicMermaidInstructorApplication,
  showPADIMermaidTApplication,
  showPADIBasicMermaidTApplication,
  showPADIMermaidInstructorApplication,
  showFDIApplication,
  showAFDIApplication,
  showMFDITApplication,
  showFDITApplication,
  showEFRPIApplication,
  showFDApplication,
  showStoreApplication,
  showOtherApplication
}) => {

  const { push } = useHistory()

  return (
    <>
      <ScubaMenu
        dm={showDMApplication}
        ie={showOWSIApplication}
        insDm={showInstructorDMApplication}
        si={showSIApplication}
        cd={showCDApplication}
        efr={showEFRApplication} />
      <EFRMenu
        efrpi={showEFRPIApplication}
        efri={showEFRIApplication}
        efrt={showEFRITApplication} />
      <MermaidMenu
        basic={showPADIBasicMermaidInstructorApplication}
        mermaid={showPADIMermaidInstructorApplication}
        basicT={showPADIBasicMermaidTApplication}
        mermaidT={showPADIMermaidTApplication} />
      <FreediveMenu
        fd={showFDIApplication}
        afd={showAFDIApplication}
        mfd={showMFDITApplication}
        fdPic={showFDApplication}
        fdit={showFDITApplication} />
      {(showOtherApplication || showStoreApplication) && <List className="profile-list">
        {showOtherApplication && <List.Item
          arrow="horizontal"
          onClick={() => push('/pockets_apps_list')}
        >
          <FormattedMessage id="pocket-apps-title" />
        </List.Item>}
        {showStoreApplication && <List.Item
          arrow="horizontal"
          onClick={() => push('/store_apps_list')}
        >
          <FormattedMessage id="store-apps" />
        </List.Item>}
      </List>}
    </>
  );
};
