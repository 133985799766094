import imageFiles from '../components/certImages.json';
import { getCookie } from '../components/common/cookie';
import {
  isCred,
  isMermaid,
  isMermaidPerformancer,
  isMermaidPerformancerInstructor,
  isMidwayDiver
} from '../utils/cert';

const defaultImage = '-1';

export const getImageFileById = (id) => {
  if ('rd' === id) return id;
  const match = imageFiles.filter((i) => i.id === '' + id);
  return match.length > 0 ? match[0].file || defaultImage : defaultImage;
};

export const localizeResource = (path, language) => {
  if (!path) return path;
  const [tail, ...rest] = path.split('.').reverse();
  return `${rest.reverse().join('.')}-${
    language === 'en' ? 'sc' : language
  }.${tail}`;
};

export const getImageIdFromCardStockId = (ecard) => {
  let id;
  // if(isJuniorMermaid(ecard)) return 1;
  if (isMermaid(ecard)) {
    id = (isMermaidPerformancer(ecard) || isMermaidPerformancerInstructor(ecard))
      ? 5245
      : ecard.typeNumber;
  } else if (isMidwayDiver(ecard)) {
    id = ecard.typeNumber;
  } else if ('Resort Diver' === ecard.ecardName) {
    id = 'rd';
  } else {
    id = ecard.printCardTypeId || -1;
    if (isCred(ecard) && id === -1) id = 1;
  }
  return id;
};

export const getPlasticCardImagePath = (ecard) => {
  const id = getImageIdFromCardStockId(ecard);
  return `certs/cert_${getImageFileById(id)}.jpg`;
};

export const getEcardImagePathArr = (ecard) => {
  const customizedImages = ecard.customizedImages || [];
  const ecardImages = ecard.ecardImages || [];
  return customizedImages
    .map((c) => c.imgUrl)
    .concat(ecardImages.map(getEcardImagePath));
}

export const getEcardImagePath = ({ imgFile }) => {
  if (imgFile.indexOf('http') === 0) {
    return imgFile;
  }
  return `ecard_img/${localizeResource(imgFile, getCookie('language'))}`;
}

export const getCoverImagePath = (ecard) => {
  // 自定义卡面
  const customizedImg = ecard?.customizedImages?.find((c) => c.active === true);
  if (customizedImg) {
    return customizedImg.imgUrl;
  }
  // 电子卡面
  const ecardImg = ecard?.ecardImages?.find(
    (ecardImg) => ecardImg.active === true
  );
  if (ecardImg) {
    return getEcardImagePath(ecardImg);
  }
  // 其他卡面
  return getPlasticCardImagePath(ecard);
};

// export const isNotBlueStandardCard = (cardStockId) => {
//     const id = parseInt(cardStockId);
//     return id === 4
//     || id === 5
//     || id === 8
//     || id === 9
//     || id === 10
//     || id === 11
//     || id === 12
//     || id === 48
//     || id === 77
//     || id === 83;
// }

// export const getCardImgByType = (type, stockId) => {
//     return getImageFileById(getImageIdFromCardStockId(type, parseInt(stockId)))
// }

const certStockIdMappings = {
  CERTIFICATION: [
    { id: 4160, cardStockTypeId: 77 },
    { id: 4161, cardStockTypeId: 77 },
    { id: 4162, cardStockTypeId: 77 },
    { id: 4163, cardStockTypeId: 77 },
    { id: 17, cardStockTypeId: 8 },
    { id: 29, cardStockTypeId: 11 },
    { id: 38, cardStockTypeId: 8 },
    { id: 51, cardStockTypeId: 8 },
    { id: 62, cardStockTypeId: 8 },
    { id: 74, cardStockTypeId: 8 },
    { id: 3147, cardStockTypeId: 8 },
    { id: 3148, cardStockTypeId: 8 },
    { id: 3149, cardStockTypeId: 8 },
    { id: 3150, cardStockTypeId: 8 },
    { id: 4, cardStockTypeId: 9 },
    { id: 85, cardStockTypeId: 10 },
    { id: 3151, cardStockTypeId: 83 },
  ],
  CREDENTIAL: [
    { id: 9430, cardStockTypeId: 83 },
    { id: 9403, cardStockTypeId: 83 },
    { id: 11362, cardStockTypeId: 77 },
    { id: 11363, cardStockTypeId: 77 },
    { id: 11364, cardStockTypeId: 77 },
    { id: 11365, cardStockTypeId: 77 },
  ],
};

export const getStockTypeIdByCertId = (type, certId) => {
  return certStockIdMappings[type]
    .filter((m) => m.id === certId)
    .map((m) => m.cardStockTypeId)[0];
};
