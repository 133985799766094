import React from 'react'
import { useSelector } from 'react-redux';
import { Button, Modal, Toast, WingBlank } from 'antd-mobile';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { owsiCdApproved } from '../../services/api';
import {
  formatDate,
  getSubmitOssFormat,
  removeExpires,
  showAlert,
  showModal
} from '../../components/common/utils'
import PaymentBtn from '../../components/FormTools/PaymentBtn'
import { usePayOwsi, useProfile } from '../../services/hooks'

import styles from '../DiveMasterForm/DiveMasterForm.module.css'

const moduleMapping = {
  module1: 'Written Exam (Pass)',
  module2: 'Knowledge Development (Pass)',
  module3: 'Confined Water (Pass)',
  module4: 'Open Water (Pass)'
}

const Status = ({ cdDetail, agreement, idcDate, extensions, idcEndDate }) => {

  const {
    status,
    note,
    paymentMethod,
    paymentType,
    payerType,
    code,
    module1,
    module2,
    module3,
    module4
  } = cdDetail
  const { formatMessage } = useIntl()
  const { replace, push } = useHistory(); 
  const { runAsync: toPay } = usePayOwsi();
  const { signatureUrl } = useSelector(state => ({
    signatureUrl: state.ui.signatureUrl,
  }));
  const { data: profile } = useProfile();

  const approvedAction = (approve) => {
    if (!signatureUrl) {
      showModal(formatMessage({ id: 'common-errorTitle' }), formatMessage({ id: 'dm-form-signature-confirm' }), () => {}, () => {}, formatMessage({ id: 'common-comfirmBtn' }), formatMessage({ id: 'common-cancel' }))
      return
    }
    if (!agreement) {
      Modal.alert(formatMessage({ id: 'dm-form-submit-error' }), formatMessage({ id: 'dm-form-submit-error-info' }), [{ text: formatMessage({ id: 'common-close' }) }]);
      return;
    }
    if (!idcDate) {
      Modal.alert(formatMessage({ id: 'dm-form-submit-error' }), formatMessage({ id: 'owsi-idc-error' }), [{ text: formatMessage({ id: 'common-close' }) }]);
      return;
    }if (!idcEndDate) {
      Modal.alert(formatMessage({ id: 'dm-form-submit-error' }), formatMessage({ id: 'owsi-idc-end-error' }), [{ text: formatMessage({ id: 'common-close' }) }]);
      return;
    }
    if (approve) {
      if (!cdDetail.trainingRecord.length > 0) {
        Modal.alert(formatMessage({ id: 'dm-form-submit-error' }), formatMessage({ id: 'owsi-training-records-tips' }), [{ text: formatMessage({ id: 'common-close' }) }]);
        return;
      }
      if (!cdDetail.payerType) {
        Modal.alert('', formatMessage({ id: 'owsi-pay-tips' }), [
          { text: formatMessage({ id: 'owsi-candidate-pay' }), onPress: () => submitApprovedFn('CANDIDATE') },
          { text: formatMessage({ id: 'owsi-cd-pay' }), onPress: () => submitApprovedFn('CD') },
          { text: formatMessage({ id: 'common-cancel' }), onPress: () => console.log('cancel') },
        ])
      } else {
        submitApprovedFn()
      }
    } else {
      Modal.prompt(
        formatMessage({ id: 'dm-refuse-reason' }),
        formatMessage({ id: 'dm-refuse-tips' }),
        [
          { text: formatMessage({ id: 'common-cancel' }) },
          {
            text: formatMessage({ id: 'common-comfirmBtn' }), onPress: async value => {
              if (!value) {
                showModal(
                  '',
                  formatMessage({ id: 'dm-refuse-error' }),
                  () => {},
                  () => {},
                  formatMessage({ id: 'common-comfirmBtn' }),
                  formatMessage({ id: 'common-cancel' })
                );
                return
              }
              const approvedData = {
                approve,
                code: cdDetail.code,
                handwrittenSignatureUrl: getSubmitOssFormat(signatureUrl),
                note: value,
                idcdate: formatDate(idcDate, 'DDMMYYYY'),
                idcendDate: formatDate(idcEndDate, 'DDMMYYYY'),
                writtenExamTimeExtensions: extensions
              }
              try {
                Toast.loading()
                const response = await owsiCdApproved(approvedData);
                if (response.error) {
                  showAlert(
                    response.error.clientMsg,
                    formatMessage({ id: 'common-close' })
                  );
                } else {
                  replace('/owsi_cd_apps')
                }
              } catch (e) {
                showAlert(
                  formatMessage({ id: 'common-errorStatus' }),
                  formatMessage({ id: 'common-close' })
                );
              } finally {
                Toast.hide()
              }
            }
          },
        ]
      )
    }
  };

  const toPayIe = async (code) => {
    const result = await toPay(code)
    if (result.error) {
      showAlert(
        result.error.clientMsg,
        formatMessage({ id: 'common-close' })
      );
      return
    }
    if (result.url) {
      if (profile.teachingLocation === 'TW') return push('/invoice_form', { code, nextUrl: result.url, type: 'MEMBER_APPLICATION_OWSI' })
      window.location.replace(result.url)
    }
  }

  const submitApprovedFn = (payerType) => {
    showModal(
      '',
      formatMessage({ id: 'dm-form-instructor-accept-confirm' }),
      async () => {
        const approvedData = {
          approve: true,
          code: cdDetail.code,
          idcdate: formatDate(idcDate, 'DDMMYYYY'),
          idcendDate: formatDate(idcEndDate, 'DDMMYYYY'),
          handwrittenSignatureUrl: getSubmitOssFormat(signatureUrl),
          writtenExamTimeExtensions: extensions
        }
        if (payerType) approvedData.payerType = payerType
        if (cdDetail.trainingRecord.length > 0) {
          approvedData.trainingRecord = removeExpires(cdDetail.trainingRecord)
        }
        try {
          Toast.loading()
          const response = await owsiCdApproved(approvedData);
          if (response.error) {
            showAlert(
              response.error.clientMsg,
              formatMessage({ id: 'common-close' })
            );
          } else {
            if (payerType === 'CD') toPayIe(cdDetail.code)
            else replace('/owsi_cd_apps')
          }
        } catch (e) {
          showAlert(
            formatMessage({ id: 'common-errorStatus' }),
            formatMessage({ id: 'common-close' })
          );
        } finally {
          Toast.hide()
        }
      },
      () => {}, formatMessage({ id: 'common-comfirmBtn' }),
      formatMessage({ id: 'common-cancel' })
    )
  }

  switch (status) {
    case 'WAIT_CONFIRM':
      return (
        <div className={styles.btnBox}>
          <Button
            inline
            type="primary"
            className={styles.btn}
            onClick={() => approvedAction(true)}
          ><FormattedMessage id="dm-form-agree" /></Button>
          <Button
            inline
            type="warning"
            className={styles.btn}
            onClick={() => approvedAction(false)}
          ><FormattedMessage id="dm-form-reject" /></Button>
        </div>
      )
    case 'INSTRUCTOR_APPROVED':
      if (paymentMethod) {
        return <div className={styles.statusBtn}>
          <FormattedMessage id="app-wait-cs-review" />
        </div>
      }
      if (paymentType) {
        if (payerType === 'CD') {
          return (
            <WingBlank>
              <PaymentBtn code={code} paymentFn={toPay} payApplicationType={'MEMBER_APPLICATION_OWSI'} />
            </WingBlank>
          )
        } else if (payerType === 'CANDIDATE') {
          return <div className={styles.statusBtn}>
            <FormattedMessage id="app-wait-candidate-pay" />
          </div>
        }
      }
      return null
    case 'INSTRUCTOR_NOT_APPROVED':
      return <div className={styles.statusBtn}>
        <FormattedMessage id="app-status-instructor-not-approved" />
      </div>
    case 'ADMIN_NOT_APPROVED':
      return <div className={styles.statusBtn}>
        <div>
          <FormattedMessage id="app-cs-not-approved" />
        </div>
        {note && <div>
          <FormattedMessage id="app-refuse-reason" />{note}
        </div>}
      </div>
    case 'NOT_PASS':
      return <>
        <div className={styles.statusBtn}>
          <FormattedMessage id="owsi-not-pass" />
        </div>
        <div>
          <FormattedMessage id="owsi-not-pass-reason" />
          {module1 && <div>{moduleMapping.module1}</div>}
          {module2 && <div>{moduleMapping.module2}</div>}
          {module3 && <div>{moduleMapping.module3}</div>}
          {module4 && <div>{moduleMapping.module4}</div>}
        </div>
      </>
    case 'PASS':
      return <div className={styles.statusBtn}>
        <FormattedMessage id="app-wait-cert" />
      </div>
    case 'SUCCESS':
      return <div className={styles.statusBtn}>
        <FormattedMessage id="app-success" />
      </div>
    case 'CLOSE':
      return <div className={styles.statusBtn}>
        <FormattedMessage id="app-close" />
      </div>
    default:
      return null
  }
}

export default Status