import React, { useRef, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import Avatar from '../../components/common/Avatar'
import { Toast, WhiteSpace } from "antd-mobile";
import * as html2canvas from "html2canvas";
import { pageLoading, pageLoaded } from '../../actions'
import { fetchWechatUser } from '../../services/api'
import styles from './Poster2022.module.css'

const Poster2021 = () => {

  const dispatch = useDispatch()
  const posterRef = useRef();
  const [showImg, setShow] = useState(false)
  const [imgUrl, setImgUrl] = useState('')
  const [avatar, setAvatar] = useState('')

  useEffect(() => {
    const getWechatUser = async () => {
      try {
        dispatch(pageLoading());
        const wechat = await fetchWechatUser()
        console.log(wechat)
        if (!wechat) return
        setAvatar(wechat.data.headimgurl)
      } catch (e) {
        console.error(e);
      } finally {
        dispatch(pageLoaded());
      }
    }
    getWechatUser();
  }, [])


  const handleImg = () => {
    const width = posterRef.current.offsetWidth;
    const height = posterRef.current.offsetHeight - 1;
    dispatch(pageLoading());
    html2canvas(posterRef.current, {
      useCORS: true,
      width,
      height,
      dpi: window.devicePixelRatio * 2,
    }).then(canvas => {
      dispatch(pageLoaded());
      canvas.getContext("2d").translate(0, 50);
      setImgUrl(canvas.toDataURL("image/jpeg", 0.3))
      setShow(true)
      Toast.hide()
      Toast.info('图片已生成，请长按保存', 2)
    });
  };

  return <div style={{ position: 'relative' }}>
    <div ref={posterRef}>
      <img alt="poster" src={require('../../assets/images/campaign/aware2022/post.jpg')} style={{ width: '100%' }} />
      <div className={styles.content}>
        {/* <img alt="title" className={styles.title} src={require('../../assets/images/campaign/aware2022/title.png')} /> */}
        <div className={styles.avatar}>
          <Avatar avatarImg={avatar} />
        </div>
        {/* <WhiteSpace />
        <img alt="content" className={styles.text} src={require('../../assets/images/campaign/aware2022/content.png')} />
        <WhiteSpace /> */}
        {/* <div className={styles.qrcode}>
          <div className={styles.qrBox}>
            <img alt="poster" src={require('../../assets/images/campaign/aware2022/qrcode-poster.png')} />
            <span>制作我的海报</span>
          </div>
          <div className={styles.qrBox}>
            <img alt="qrcode" src={require('../../assets/images/campaign/aware2022/qrcode.png')} />
            <span>为海洋助力</span>
          </div>
        </div> */}
      </div>
    </div>
    <div className={styles.save} onClick={handleImg}>
    （点击生成海报）
    </div>
    {showImg && <div className={styles.wrapper}>
      <div className="share-img-box">
        <img src={imgUrl} className={styles.posterImg} alt='share' />
        <img src={require('../../assets/images/close.png')} alt='close' className='close-icon' onClick={() => this.setState({ shareImg: false, showImg: false })} />
      </div>
    </div>}
  </div>
}

export default Poster2021