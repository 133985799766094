import * as ActionTypes from '../actions'
import { combineReducers } from 'redux'
import { SUCCESS } from '../actions'

function cartList(state = [], { type, data }) {
    switch(type) {
        case ActionTypes.ADD_TO_CART:
            const cartList = [data, ...state]
            return cartList
        case ActionTypes.DELECT_CART_ITEM:
            const otherGoods = state.filter(item => item.skuId !== data.skuId || item.ecardId !== data.ecardId)
            return otherGoods
        case ActionTypes.REPLACE_CARD:
            const otherLevelCard = state.filter(item => item.ecardId !== data.ecardId)
            const newCartList = [...otherLevelCard, data]
            return newCartList
        case ActionTypes.SHOPPINGCART[SUCCESS]:
            let getcartList = []
            data.data.map(item => getcartList.push({ecardId: item.ecardId, skuId: item.skuId, goodsId: item.goodsId}))
            return getcartList
        default:
            return state
      }
}

function checked(state=[], { type, data }) {
    switch(type) {
      case ActionTypes.ADD_TO_CHECKED:
      case ActionTypes.ADD_TO_CART:
        const checkedList = [data, ...state]
        return checkedList
      case ActionTypes.REMOVE_CHECKED:
      case ActionTypes.DELECT_CART_ITEM:
        const removeCard = state.filter(item => item.skuId !== data.skuId || item.ecardId !== data.ecardId)
        return removeCard
      case ActionTypes.REPLACE_CARD:
        const filterCard = state.filter(item => item.ecardId !== data.ecardId)
        const newCheckedList = [...filterCard, data]
        return newCheckedList
      case ActionTypes.CHECKED_ALL:
        return data
      case ActionTypes.REMOVE_ALL:
        return []
      case ActionTypes.SHOPPINGCART[SUCCESS]:
        let getCheckedList = []
        data.data.map(item => getCheckedList.push({ecardId: item.ecardId, skuId: item.skuId, goodsId: item.goodsId}))
        return getCheckedList
      default:
        return state
    }
  }

const shopCartReducer = combineReducers({
    checked,
    cartList,
  })
  
  export default shopCartReducer