import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { ImagePicker, WingBlank } from 'antd-mobile';
import { getOssSignature } from '../../reducers/oss';
import { useUploadFileToOss, useGetOssFullUrl } from '../../services/hooks'

import styles from '../common/UploadOssFile.module.css'

const pfdDefaultImg = 'https://assets.padi.com.cn/assets/images/pdf.jpg'

const UploadOssFile = ({
  form,
  fileList,
  editable,
  fileName,
  ossSignature,
  isPublic,
  maxLength,
  onSuccess = () => { }
}) => {
  const [showPic, setShowPic] = useState('')
  const [files, setFiles] = useState([])
  const { runAsync } = useUploadFileToOss();
  const { runAsync: getUrl } = useGetOssFullUrl();

  useEffect(() => {
    if (fileList) {
      const newFileList = fileList.map(item => {
        if (item.url.indexOf('pdf') > 0) {
          return { url: pfdDefaultImg, path: item.url }
        }
        return item
      })
      setFiles(newFileList)
    }
  }, [])

  const formatFileList = (files) => {
    return files.map(file => {
      if(file.path) return file.path
      return file.url
    })
  }

  const uploadFile = (fs, type, index) => {
    if (type === 'remove') {
      const newFiles = files
      newFiles.splice(index, 1)
      setFiles(newFiles)
      onSuccess(formatFileList(newFiles))
    } else if (type === 'add') {
      const file = fs[fs.length - 1].file
      const formData = getUploadData(file)
      runAsync(ossSignature.host, formData).then(res => {
        if (isPublic) {
          let urlObj
          if (res.url.indexOf('pdf') >= 0) {
            urlObj = { url: pfdDefaultImg, path: ossSignature.host + '/' + res.url }
          }else {
            urlObj = { url: ossSignature.host + '/' + res.url }
          }
          const finalFiles = [...files, urlObj]
          setFiles(finalFiles)
          onSuccess(formatFileList(finalFiles))
          return
        }
        getUrl(res.url).then(url => {
          let urlObj
          if (url.indexOf('pdf') >= 0) {
            urlObj = { url: pfdDefaultImg, path: url }
          } else {
            urlObj = { url }
          }
          const finalFiles = [...files, urlObj]
          setFiles(finalFiles)
          onSuccess(formatFileList(finalFiles))
        })
      })
    } else {
      setFiles([])
    }
  }

  const getUploadData = (file) => {
    let filename = fileName + new Date().getTime();
    if (file.type.indexOf('pdf') >= 0) filename = filename + 'pdf'
    const formData = new FormData();
    formData.append('key', filename); //存储在oss的文件路径
    formData.append('OSSAccessKeyId', ossSignature.accessid); //accessKeyId
    formData.append('policy', ossSignature.policy); //policy
    formData.append('Signature', ossSignature.signature); //签名
    formData.append("file", file)
    formData.append('success_action_status', 200);
    return formData
  }

  const previewFile = (index, fs) => {
    if (fs[index].path) window.open(fs[index].path)
    else setShowPic(fs[index].url)
  }

  return (
    <WingBlank>
      <ImagePicker
        form={form}
        files={files}
        onChange={(fs, type, index) => uploadFile(fs, type, index)}
        onImageClick={previewFile}
        selectable={maxLength ? files.length < maxLength : editable}
        disableDelete={!editable}
      />
      {showPic && <div className={styles.picBox}>
        <img
          src={require('../../assets/images/close-white.png')}
          alt="close icon"
          className={styles.closeIcon}
          onClick={() => setShowPic('')} />
        <img src={showPic} alt="attach" className={styles.pic} />
      </div>}
    </WingBlank>
  )
}

export default UploadOssFile