import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { InputItem, WhiteSpace, Checkbox, WingBlank } from 'antd-mobile';
import { Toast } from 'antd-mobile-v5'
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import CardLayout from '../DiveMasterForm/CardLayout'
import Signature from '../../components/common/Signature'
import SearchStore from '../../components/common/SearchStore';
import UploadOssFile from '../../components/common/UploadOssFile'
import Status from './DetailStatus'
import DatePickerComponent from '../../components/common/DatePicker'
import EFRConfirm from '../../components/common/EFRConfirm'
import { showAlert, removeExpires } from '../../components/common/utils'
import IeDetail from './IeDetail'
import UploadFileWrapper from '../../components/common/UploadFileWrapper'
import { uploadTrainingRecord } from '../../services/api'
import { pageLoading, pageLoaded } from '../../actions'
import dayjs from 'dayjs';

import styles from '../DiveMasterForm/DiveMasterForm.module.css'
import {
  filterTeachingLanguage,
  formatDate
} from '../../components/common/utils'
import { getOssSignature } from '../../reducers/oss'
import UserPhoto from '../../components/common/UserPhoto'
import IeExamList from "./IeExamList";
import { uploadTrainingRecords, removeOwsiTraniningRecords } from '../../reducers/owsi'
import Agreement from './Agreement'
import { getCookie } from "../../components/common/cookie";

const CheckboxItem = Checkbox.CheckboxItem;

const OwsiCdAppDetailPage = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const [agreement, setAgreement] = useState(false);
  const [attachUrl, setAttachUrl] = useState('')
  const [IDCDate, setIDCDate] = useState('')
  const [IDCEndDate, setIDCEndDate] = useState('')
  const [extensions, setExtensions] = useState(false)

  const { cdDetail, ossSignature } = useSelector(state => ({
    cdDetail: state.owsi.cdDetail,
    ossSignature: state.oss.ossSignature,
  }));

  const lang = getCookie('language');

  useEffect(() => {
    dispatch(getOssSignature('OWSI'))
  }, []);

  useEffect(() => {
    if(IDCDate && IDCEndDate) {
      const formatStartDate = dayjs(IDCDate)
      const formatEndDate = dayjs(IDCEndDate)
      const yearsDifference = formatEndDate.diff(formatStartDate, 'year');
      if(IDCEndDate && yearsDifference > 0) {
        Toast.show({
          content: 'IDC开始日期和IDC结束日期不能超过一年。',
          maskClickable: false,
        })
        setIDCDate('')
        setIDCEndDate('')
        return
      }
      if(formatStartDate.isAfter(formatEndDate)) {
        Toast.show({
          content: 'IDC开始日期不能大于IDC结束日期。',
          maskClickable: false,
        })
        setIDCDate('')
        setIDCEndDate('')
        return
      }
    }
  },[IDCDate, IDCEndDate])

  useEffect(() => {
    if (cdDetail && cdDetail.idcdate && !IDCDate) setIDCDate(formatIdcDate(cdDetail.idcdate))
    if (cdDetail && cdDetail.idcendDate && !IDCEndDate) setIDCEndDate(formatIdcDate(cdDetail.idcendDate))
    if (cdDetail && cdDetail.writtenExamTimeExtensions) setExtensions(cdDetail.writtenExamTimeExtensions)
  }, [cdDetail])

  if (!Object.keys(cdDetail).length > 0) return null

  const filename = (code) => `${ossSignature.dir}${code}/${new Date().getTime()}`

  const formatIdcDate = (date) => {
    if(!date) return ''
    const day = date.substr(0, 2)
    const month = date.substr(2, 2)
    const year = date.substr(4, 4)
    return `${year}-${month}-${day}`
  }

  const formatIEDate = (date) => {
    const newDate = date.replace('年', '-').replace('月', '-').replace('日', '')
    return newDate
  }

  const getIDCMinDate = () => {
    const { ieDate } = cdDetail.ieScheduleDTO
    const scheduleDate = formatIEDate(ieDate)
    const subDate = dayjs(scheduleDate).subtract(1, 'year').subtract(1, 'day')
    const formatSubDate = formatDate(subDate, 'YYYY-MM-DD')
    return new Date(formatSubDate)
  }

  const getIDCMaxDate = () => {
    const { ieDate } = cdDetail.ieScheduleDTO
    const nextDate = dayjs(formatIEDate(ieDate)).add(1, 'year').subtract(1, 'day')
    const formatNextDate = formatDate(nextDate, 'YYYY-MM-DD')
    return new Date(formatNextDate)
  }

  const getIDCMinStartDate = () => {
    const { preIDCEndDate, ieAttempt } = cdDetail
    if(ieAttempt > 1) {
      const year = preIDCEndDate.slice(-4)
      const month = preIDCEndDate.slice(2, 4)
      const day = preIDCEndDate.slice(0, 2)
      const formatPreIDCEndDate = `${year}-${month}-${day}`
      return new Date(formatPreIDCEndDate)
    }
    return null
  }

  const changeExtensions = (checked) => {
    showAlert(formatMessage({ id: 'owsi-extensions-reminder' }), formatMessage({ id: "common-comfirmBtn" }))
    setExtensions(checked)
  }

  const removeTrainingFileToServer = async (removeItem) => {
    try {
      dispatch(pageLoading())
      const filterTraining = cdDetail.trainingRecord.filter(item => item !== removeItem)
      const data = {
        code: cdDetail.code,
        trainingRecord: removeExpires(filterTraining)
      }
      await uploadTrainingRecord(data)
    } finally {
      dispatch(pageLoaded())
    }
  }

  const isConfirm = !!(cdDetail.status === 'WAIT_CONFIRM')

  const showTrainingFiles = cdDetail.status === 'INSTRUCTOR_APPROVED' || cdDetail.status === 'WAIT_CONFIRM'

  return <>
    <CardLayout title={<FormattedMessage id="dm-form-diver-info" />} status={"none"}>
      <div className={styles["diver-info"]}>
        <div>
          <InputItem
            type="text"
            editable={false}
            value={cdDetail.userInfoDTO.englishName}
          >
            <FormattedMessage id="dm-form-name" />
          </InputItem>
          <InputItem
            type="text"
            editable={false}
            value={formatDate(cdDetail.userInfoDTO.birthDate, 'YYYY-MM-DD')}
          >
            <FormattedMessage id="dm-form-birth" />
          </InputItem>
        </div>
        <UserPhoto id={cdDetail.userInfoDTO.affiliateId} />
      </div>
    </CardLayout>
    <WhiteSpace />
    <IeDetail ieAttempt={cdDetail.ieAttempt} resitList={cdDetail.ieExamModules || []} />
    <WhiteSpace />
    <CardLayout title={<FormattedMessage id="dm-form-required-certs" />} status={"none"}>
      {attachUrl && <div className={styles.attachBox}>
        <img
          src={require('../../assets/images/close-white.png')}
          alt="close icon"
          className={styles.closeIcon}
          onClick={() => setAttachUrl('')} />
        <img src={attachUrl} alt="attach" className={styles.attachImg} />
      </div>}
      <InputItem
        type="text"
        editable={false}
        value={cdDetail.ow}
      >OW</InputItem>
      {cdDetail.owfiles && <UploadOssFile
        fileList={cdDetail.owfiles}
        editable={false}
        type={"OWSI"}
      />}
      <InputItem
        type="text"
        editable={false}
        value={cdDetail.aow}
      >AOW</InputItem>
      {cdDetail.aowfiles && <UploadOssFile
        fileList={cdDetail.aowfiles}
        editable={false}
        type={"OWSI"}
      />}
      <InputItem
        type="text"
        editable={false}
        value={cdDetail.rescue}
      >RESCUE</InputItem>
      {cdDetail.rescuefiles && <UploadOssFile
        fileList={cdDetail.rescuefiles}
        editable={false}
        type={"OWSI"}
      />}
      <EFRConfirm appDetail={cdDetail} />
      <InputItem
        type="text"
        editable={false}
        value={cdDetail.aidm ? cdDetail.userInfoDTO.memberNumber : ''}
      >AI/DM</InputItem>
      {cdDetail.aidmfiles && <UploadOssFile
        fileList={cdDetail.aidmfiles}
        editable={false}
        type={"OWSI"}
      />}
      <UploadFileWrapper
        labelName={formatMessage({ id: 'mermaid-other-files' })}
        editable={false}>
        <UploadOssFile
          fileList={cdDetail.otherFiles || []}
          editable={false} />
      </UploadFileWrapper>
    </CardLayout>
    <WhiteSpace />
    <CardLayout title={<FormattedMessage id="profile-store-title" />} status={"none"}>
      {cdDetail.alternateLocation ? <InputItem
        type="text"
        editable={false}
        value={cdDetail.alternateLocation}
      >
        <FormattedMessage id="owsi-alternative-location" />
      </InputItem> : <SearchStore
        editable={false}
        store={cdDetail.storeInfoDTO}
        storeNumber={cdDetail.storeInfoDTO.storeNumber}
      />}
    </CardLayout>
    <WhiteSpace />
    <CardLayout title={<FormattedMessage id="dm-region-and-lang-title" />} status={"none"}>
      {cdDetail.regionDTO && <InputItem
        type="text"
        editable={false}
        value={cdDetail.regionDTO.region}
      >
        <FormattedMessage id="dm-region" />
      </InputItem>}
      <InputItem
        type="text"
        editable={false}
        value={filterTeachingLanguage(cdDetail.languageId)}
      >
        <FormattedMessage id="dm-lang-title" />
      </InputItem>
    </CardLayout>
    <WhiteSpace />
    <IeExamList cdDetail={cdDetail} />
    <WhiteSpace />
    {showTrainingFiles ? (
      <CardLayout
        title={formatMessage({ id: "owsi-upload-traning-records" })}
        status={"none"}>
        <UploadOssFile
          fileList={cdDetail.trainingRecord || []}
          editable={true}
          type={"OWSI"}
          fileName={filename(cdDetail.code)}
          onChange={(formData) => {
            dispatch(uploadTrainingRecords({ formData }))
          }}
          onDelete={(index) => {
            dispatch(removeOwsiTraniningRecords(cdDetail.trainingRecord[index]))
            removeTrainingFileToServer(cdDetail.trainingRecord[index])
          }}
        />
        <WingBlank>
          <div className="small-font gray">
            <FormattedMessage id="owsi-cd-other-files" />
          </div>
        </WingBlank>
      </CardLayout>
    ) : cdDetail.trainingRecord.length > 0 ? (
      <CardLayout
        title={formatMessage({ id: "owsi-training-records" })}
        status={"none"}>
        <UploadOssFile
          fileList={cdDetail.trainingRecord || []}
          editable={false}
          type={"OWSI"}
        />
      </CardLayout>
    ) : null}
    <WhiteSpace />
    <DatePickerComponent
      minDate={cdDetail.preIDCEndDate ? getIDCMinStartDate() : null}
      maxDate={new Date()}
      disabled={!isConfirm}
      extra={formatMessage({ id: 'common-select' })}
      title={formatMessage({ id: 'setProfile-chooseDate' })}
      onChange={date => setIDCDate(date)}
      value={isConfirm ? IDCDate : formatIdcDate(cdDetail.idcdate)}>
      <FormattedMessage id="owsi-idc-date" />
    </DatePickerComponent>
    <WhiteSpace />
    {(cdDetail.status !== 'WAIT_CONFIRM' && !cdDetail.idcendDate) ? null : <>
      <DatePickerComponent
        maxDate={getIDCMaxDate()}
        minDate={getIDCMinDate()}
        disabled={!isConfirm}
        extra={formatMessage({ id: 'common-select' })}
        title={formatMessage({ id: 'setProfile-chooseDate' })}
        onChange={date => setIDCEndDate(date)}
        value={isConfirm ? IDCEndDate : formatIdcDate(cdDetail.idcendDate)}>
        <FormattedMessage id="owsi-idc-end-date" />
      </DatePickerComponent>
      <WhiteSpace />
    </>}
    <CheckboxItem
      disabled={!isConfirm}
      checked={extensions}
      onChange={(e) => changeExtensions(e.target.checked)}>
      <FormattedMessage id="owsi-extensions" />
    </CheckboxItem>
    <WhiteSpace size="lg" />
    {(cdDetail.status === 'WAIT_CONFIRM') && (
      <>
        <Signature
          modified={true}
          filePath={'signature'} />
        <WhiteSpace size="lg" />
        <Agreement changeChecked={setAgreement} />
      </>
    )}
    <Status
      cdDetail={cdDetail}
      agreement={agreement}
      idcDate={IDCDate}
      idcEndDate={IDCEndDate}
      extensions={extensions}
    />
    <WhiteSpace size="lg" />
  </>
}

export default OwsiCdAppDetailPage