import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import { capitalize } from '../components/common/utils';
import { courseName } from '../components/common/utils';

const allCourses = Object.values(courseName).reduce((all, c) => {
  all[c + 'Sc'] = lazy(() => import(`../components/CourseInfo/${c}Sc`));
  all[c + 'Tc'] = lazy(() => import(`../components/CourseInfo/${c}Tc`));
  return all;
}, {});
const CourseInfoRoutes = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Route
      path="/course/:course/:language"
      component={({
        match: {
          params: { course, language },
        },
      }) => {
        const index = course + capitalize(language === 'tc' ? 'tc' : 'sc');
        const CourseInfo = allCourses[index];
        return <CourseInfo course={course} />;
      }}
    />
  </Suspense>
);
export default CourseInfoRoutes;
