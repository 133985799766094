export const STANDARD_CARD_STR = '普通';
export const REQUEST_TYPE_CRED = 'CREDENTIAL';
export const REQUEST_TYPE_CERT = 'CREDENTIAL';
export const FIRST_REQUEST = 'CARD_REQUEST';
export const CARD_TYPE_PLASTIC = '实体卡';
export const CARD_TYPE_E = '电子卡';

export const getCardOptions = (cardOptions) =>
  cardOptions
    .filter(
      (o) => !(o.ecardType === REQUEST_TYPE_CRED && o.styleName.indexOf(STANDARD_CARD_STR) >= 0)
    )
    .reduce((res, cur) => {
      if (!res.find((o) => o.styleName === cur.styleName)) {
        if(cur.styleName.indexOf(STANDARD_CARD_STR) >= 0 && res.length > 0) {
          const temp = res[0];
          res[0] = cur;
          res.push(temp);
        } else {
          res.push(cur);
        }
      }
      return res;
    }, []);

export const getCardOptionNames = (cardOptions) =>
  getCardOptions(cardOptions)
    .map((o) => o.styleName)
    // .filter((n) => n.indexOf(STANDARD_CARD_STR) < 0);

export const getPostage = (skus) => {
  skus = skus.filter(s => s.typeName === CARD_TYPE_PLASTIC);
  if(skus.length > 0) {
    if(skus.find(s => s.price > 1)) {
      return 0;
    }
    return 2000;
  } else {
    return 0;
  }
}
