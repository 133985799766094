import { FormattedMessage } from 'react-intl';
import { Accordion, List } from 'antd-mobile';
import { useHistory } from 'react-router-dom';
import React from 'react';

import styles from './Menu.module.css';

const CoursePicMenu = ({ profiles: {showEFRPApplication, showTextbookOrderList, storeInfo} }) => {
  const history = useHistory();
  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Panel
        header={<FormattedMessage id="profile-course-pic" />}
        className="profile-list member-renew"
      >
        <List>
          <List.Item
            arrow="horizontal"
            onClick={() => history.push('/bookSaleList')}
          >
            <span className={styles.menuItem}>
              <FormattedMessage id="profile-book" />
            </span>
          </List.Item>
          {showTextbookOrderList && <List.Item
            arrow="horizontal"
            onClick={() => history.push('/pic_order_list')}
          >
            <span className={styles.menuItem}>
              <FormattedMessage id="textbooks-order" />
            </span>
          </List.Item>}
          {showTextbookOrderList && <List.Item
            arrow="horizontal"
            onClick={() => history.push('/unconventional_order_list')}
          >
            <span className={styles.menuItem}>
              <FormattedMessage id="unconventional-order" />
            </span>
          </List.Item>}
          <List.Item
            arrow="horizontal"
            onClick={() => history.push('/agent_elearning')}
          >
            <span className={styles.menuItem}>
              <FormattedMessage id="profile-agent-elearning" />
            </span>
          </List.Item>
          {/** isPgcPro(profiles) && (!showOWSIApplication || storeInfo) && (<><List.Item
            arrow="horizontal"
            onClick={() => history.push('/issue_certs/home?type=CONFINED_WATER_DIVER_PIC')}
          >
            <span className={styles.menuItem}>
              <FormattedMessage id="issue-certs-confined-pic-name" />
            </span>
          </List.Item>
          <List.Item
            arrow="horizontal"
            onClick={() => history.push('/issue_certs/list?type=CONFINED_WATER_DIVER_UPGRADE')}
          >
            <span className={styles.menuItem}>
              <FormattedMessage id="issue-certs-confined2ow-name" />
            </span>
          </List.Item>
          <List.Item
            arrow="horizontal"
            onClick={() => history.push('/issue_certs/home?type=SCUBA_DIVER_PIC')}
          >
            <span className={styles.menuItem}>
              <FormattedMessage id="issue-certs-scuba-pic-name" />
            </span>
          </List.Item>
          <List.Item
            arrow="horizontal"
            onClick={() => history.push('/issue_certs/list?type=SCUBA_DIVER_UPGRADE')}
          >
            <span className={styles.menuItem}>
              <FormattedMessage id="issue-certs-scuba2ow-name" />
            </span>
          </List.Item></>) */}
          {(showEFRPApplication || storeInfo) && (
            <List.Item
              arrow="horizontal"
              onClick={() => history.push('/issue_certs/list?type=EFRP')}
            >
              <span className={styles.menuItem}>
                <FormattedMessage id="profile-efr-public" />
              </span>
            </List.Item>
          )}
        </List>
      </Accordion.Panel>
    </Accordion>
  );
};

export default CoursePicMenu;
