import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AddOutline } from 'antd-mobile-icons'
import Header from '../../components/FormTools/FormHeader'
import { InfiniteScroll, List } from 'antd-mobile-v5'
import { useGetOtherAppsList, useGetOtherAppsTypeList } from '../../services/hooks'
import { useLanguage } from '../../services/hooks/user';
import { useIntl } from 'react-intl';
import InfiniteScrollContent from '../../components/FormTools/InfiniteScrollContent'

const PocketsAppsList = () => {

  const lang = useLanguage();
  const { push } = useHistory()
  const { formatMessage } = useIntl()
  const [page, setPage] = useState(1)
  const [list, setList] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const { data: appTypes, runAsync: loadTypes } = useGetOtherAppsTypeList();
  const { runAsync } = useGetOtherAppsList(page);

  async function loadMore() {
    setHasMore(true)
    if (!appTypes) {
      const types = await loadTypes()
      if(types) {
        const append = await runAsync(page)
        setList(val => [...val, ...append.content])
        setHasMore(page < append.totalPages)
        setPage(page + 1)
      }
    }else{
      const append = await runAsync(page)
      setList(val => [...val, ...append.content])
      setHasMore(page < append.totalPages)
      setPage(page + 1)
    }
    
  }

  const findTypeItem = (type) => {
    return appTypes.find(item => item.type === type)
  }

  return <>
    <Header
      text={formatMessage({ id: "pocket-apps-title" })}
      type={"list"}
      rightContent={<AddOutline fontSize={24} onClick={() => push('/pockets_apps', { detail: null })} />}
    />
    <List>
      {list.map(item => (
        <List.Item 
          title={formatMessage({ id: "si-app-number" }) + item.code} 
          description={findTypeItem(item.memberApplicationType)['name_desc_' + lang]}
          clickable
          onClick={() => push(`/pockets_apps?code=${item.code}`, { detail: item })}
        >
          {findTypeItem(item.memberApplicationType)['name_'+lang]}
        </List.Item>
      ))}
    </List>
    <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={300}>
      <InfiniteScrollContent hasMore={hasMore} />
    </InfiniteScroll>
  </>
}

export default PocketsAppsList