import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'antd-mobile';
import { useIntl } from 'react-intl';
import { getSubmitOssFormat, removeExpires } from '../../components/common/utils'
import ApplicantDetailStatus from '../../components/common/ApplicantDetailStatus'
import { isMember } from '../../reducers/selectors'
import { usePayDm, useSubmitDm } from '../../services/hooks'
import { useHistory } from 'react-router-dom';

const ApplicationStatus = ({ formData, dmFormInfo }) => {
  const { formatMessage } = useIntl();
  const { push } = useHistory()
  const { runAsync: toPay } = usePayDm();
  const { runAsync: submitDmRequest, loading: submitting } = useSubmitDm();

  const { signatureUrl, ismember } = useSelector((state) => ({
    signatureUrl: state.ui.signatureUrl,
    ismember: isMember(state)
  }));

  const verifySubmit = () => {
    const {
      checkedTimes,
      checkedAgreement,
      aow,
      efr,
      rescue,
      instructorNumber,
      languageId,
      regionId,
      aowFiles,
      efrfiles,
      rescueFiles,
      efri
    } = formData;
    const regionStatus = ismember ? true : regionId
    return (
      signatureUrl &&
      checkedTimes &&
      checkedAgreement &&
      (aow || aowFiles.length > 0) &&
      ((efr || efrfiles.length > 0) || efri) &&
      (rescue || rescueFiles.length > 0) &&
      instructorNumber &&
      languageId &&
      regionStatus
    );
  };

  const submitDm = () => {
    if (verifySubmit()) {
      const { regionId, ...data } = formData
      const submitData = {
        ...data,
        aowFiles: formData.aowFiles.length > 0 ? removeExpires(formData.aowFiles) : [],
        efrfiles: formData.efrfiles.length > 0 ? removeExpires(formData.efrfiles) : [],
        rescueFiles: formData.rescueFiles.length > 0 ? removeExpires(formData.rescueFiles) : [],
        handwrittenSignatureUrl: getSubmitOssFormat(signatureUrl),
      };
      if (!ismember) submitData.regionId = regionId
      submitDmRequest(submitData).then(res => {
        if (res.code) {
          push('/application_submit_success?type=DM', { code: res.code, paid: res.paid, paymentType: 'MEMBER_APPLICATION_DM' });
        }
      })
    } else {
      Modal.alert(
        formatMessage({ id: 'dm-form-submit-error' }),
        formatMessage({ id: 'dm-form-submit-error-info' }),
        [{ text: formatMessage({ id: 'common-close' }) }]
      );
    }
  };

  return <ApplicantDetailStatus
    detail={dmFormInfo}
    idType={'ins'}
    submitFn={submitDm}
    payFn={toPay}
    applicationType={"DM"}
    payApplicationType={'MEMBER_APPLICATION_DM'}
    loading={submitting}
  />
};

export default ApplicationStatus;
