import React from 'react';
import { Route, Switch } from 'react-router-dom';
// import ScubaOWListPage from '../pages/ScubaOW/list';
import ScubaOWNewPage from '../pages/ScubaOW/new';
// import ScubaOWDetailPage from '../pages/ScubaOW/detail';
// import ScubaOWInstructorAppPage from '../pages/ScubaOW/app';

export default function ScubaOWRoutes() {
    // <Route path="/scuba_ow/list" component={ScubaOWListPage} />
    // <Route path="/scuba_ow/detail" component={ScubaOWDetailPage} />
    // <Route path="/scuba_ow/app" component={ScubaOWInstructorAppPage} />
    return  <Switch>
    <Route path="/scuba_ow/new" component={ScubaOWNewPage} />
  </Switch>
}