const en = {
  "common-errorTitle": "Error",
  "common-codeInput": "6 digital verification code",
  "common-getCode": "Get verification code",
  "common-validate": "Validate",
  "common-confirm": "Confirm",
  "common-close": "Close",
  "common-isNotEmail": "Please enter your correct email address",
  "common-select": "Please choose ",
  "common-errorStatus": "Error message, Please try it later. Thanks for your understanding.",
  "common-passwordInput": "Please enter your password",
  "common-confirmPassword": "Please enter your password again",
  "common-reset": "Please set your password (At least 8 characters including one uppercase letter, one lowercase letter, one number, and one symbol)",
  "common-confirmRequest": "Submit",
  "common-errorMobile": "Please enter your mobile phone number",
  "common-prompt": 'Prompt',
  "common-cancel": "Cancel",
  "common-comfirmBtn": "Confirm",
  "common-updateCerts": "You have a new certificate",
  "common-search-error": "Please verify the input is correct",
  "common-search-title": "Search",
  "common-back": "Back",
  "common-check-file": "View Documents",
  "common-agreement": "Privacy Policy",
  "common-agreement-hint": "Using service provided by PADI, it means you agree with our {link}",
  "common-info-agreement-hint": "I have carefully read, understood and agree to {link}",
  "common-info-agreement": "Authorization letter for cross-border transfer of personal information",
  "common-back-home": "Back",
  "common-upload-file-reminder": "Please upload the front and back photos of the diving certificate, or the screenshot of the e-certificate.",
  "common-applicant-pay": "Applicant To Pay",
  "common-instructor-pay": "Instructor To Pay",
  "common-course-date": "Course Completion Date",
  "common-email": "Email",
  "common-name": "Name",
  "common-address-detail": "Address Information",
  "common-country": "Country",
  "common-address": "Detailed Address",
  "common-address-reminder": "Please enter the detailed address",
  "common-Province": "Province",
  "common-yes": "Yes",
  "common-no": "No",
  "common-skip": "Skip",
  "common-submit-loading": "Submitting",
  "common-submit-success": "Submit successfully",
  "common-search-loading": "Searching",
  "common-loading": "Loading",
  "common-know": "I know",
  "common-instructor-name": "Instructor Name",
  "common-error-verification-code": "Please input correct code of 6 digits",
  "order-status-toship": "Handling",
  "order-status-shipped": "Shipped",
  "order-status-completed": "Completed",
  "register-email-not-verified": "Please input code and click \"Validate\" to verify your email first",
  "register-mobile-not-verified": "Please input code and click \"Validate\" to verify the phone first",
  "common-check-agreement": "Please check the agreement to the authorization statement",
  "common-agree": "I have read and agree",
  "common-not-agree": "I disagree",
  "common-agreement-update": "Agreement update",
  "common-load-no-more": "no more",
  "common-return-agreement1": "I have read it carefully",
  "common-return-agreement2": "PADI membership regulations",
  "common-return-and": " and ",
  "common-return-agreement3": "return and exchange methods",
  "common-return-title": "Return and Exchange Agreement",

  "notification-title": "Reminder",

  "student-search-list": "Students List",
  "student-search-added": "Email is already in list.",
  "student-search-list-empty": "Please enter email address to find and add students.",

  "form-email-empty-error": "Email cannot be empty",
  "form-email-format-error": "The email format is wrong",
  "form-email-verify-error": 'Unverified email, please click "query" to verify email',
  "form-region-empty-error": "Region cannot be empty",
  "form-stateid-hint": "Please select a province or city",
  "form-stateid-empty-error": "Province and city cannot be empty",
  "form-date-hint": "Please select a date",
  "form-course-date-empty-error": "The course completion date cannot be empty",
  "form-storenum-empty-error": "The store number cannot be empty",
  "form-storenum-format-error": "The hidden store number is wrong",
  "form-storenum-verify-error": 'The store information is not verified, please click "query" to verify the store information',
  "form-instructornum-empty-error": "Instructor number cannot be empty, or not be verified.",
  "form-instructornum-verify-error": 'The instructor information is not verified, please click "query" to verify the instructor information',
  "form-store-address": "Form-store-address",
  "form-student-info": "Student Information",
  "form-firstname-hint": "Please enter the last name",
  "form-lastname-hint": "Please enter the name",
  "form-firstname-error": "The last name cannot be empty",
  "form-lastname-error": "The name cannot be empty",
  "form-gender-empty-error": "Gender cannot be empty",
  "form-birthdate-hint": "Please select the date of birth",
  "form-birthdate-empty-error": "Birth date cannot be empty",
  "form-address-empty-error": "Address cannot be empty",
  "form-app-info": "Application Information",
  "form-app-num": "Application Number",
  "form-app-status": "Application Status",
  "form-app-submit-time": "Application submission time:",
  "form-apps-empty": "No application yet",
  "form-pre-page": "Previous Page",
  "form-next-page": "Next Page",
  "form-app-submit-date": "Submit Date:",
  "form-membernum-empty-error": "The member number cannot be empty",
  "form-name-format-error": "The format is wrong, please enter pinyin or a combination of English characters",
  "form-name-format-hint": "case insensetive",
  "form-name-nochange-error": "There is no change of your submission, first name and last name are case insensetive.",
  "form-confirm-cert": "Confirm Issuance",
  "form-cert-success": "Certificate issuance success",
  "form-instructor-info": "Instructor Info",
  "form-current-status": "Current Status:",
  "form-not-approved-reason": "Reason not approved:",
  "form-upload-error": "Please upload an image or PDF file",

  "youzan-binding-success": "Binding successful!",
  "youzan-binding-fail": "Binding failed!",

  "customize-cert-ctm-btn": "Customize the ecard cover image",
  "customize-cert-title": "Thank you for donating for charity, customize your e-card face",
  "customize-cert-create-pre": "Create your",
  "customize-cert-create-suffix": "ecard cover image",
  "customize-cert-crop-btn": "Submit",
  "customize-cert-change-btn": "Change Picture",
  "customize-cert-upload-success": "The image was uploaded successfully. We will review it as soon as possible.",
  "customize-cert-upload-failed": "There was a problem with the image upload, please try again later.",
  "customize-cert-upload-rejected": "The picture you uploaded previously failed the review for the following reasons. Please replace the picture and upload it again.",
  "customize-cert-hint1": "Thank you for your attention and dedication to marine environmental protection! Your donation will be used to support marine environmental protection actions. Please click “mine” to view your public welfare e-certificate after successful donation.",
  "customize-cert-hint2": "PADI will review the pictures you uploaded. After the review is confirmed, your ecard cover image will be replaced with the pictures you uploaded.",
  "customize-cert-hint3": "Please do not upload pornographic, violent, or false pictures that violate the law. Once found, your card will be replaced with the default card cover image.",
  "customize-cert-hint4": "I agreed to the {link}.",
  "customize-cert-hint5": "The final interpretation right belongs to PADI China.",
  "customize-cert-hint6": "You can zoom and rotate the picture with two fingers.",
  "customize-cert-hint7": "Click the logo to switch between different logos.",
  "customize-cert-agree-first": "Please read and tick to agree to the above agreement",
  "customize-upload-success": "Upload success!",
  "customize-select-amount": "Please select the amount of charity donation.",

  "login-forgetEmail": "Forget your email address? Please contact with your instructor or email to ",
  "login-bottommsg": "So far, PADI eCard is only available to the diver whose home address is in Greater China",

  "userHeader-userHeaderMsg": "I’m PADI diver!",
  "userHeader-userheaderamount": "Since {year}, I already got {certAmount} PADI certification{count}!",

  "certlist-noCert": "Welcome to PADI Family! If you just finish your PADI diving course, your certification is still in progress. Please stay tuned.",
  "certlist-certCountry": "Cert Region",
  "certlist-storeName": "Dive Shop Name",
  "certlist-sendEmail": "If miss your certifications, please send email to {email}.",
  "certlist-merge-certs": "If the certificate is missing, please click here",
  "certlist-merge-loading": "We are processing your certificate lookup request, thank you for your patience.",
  "certlist-shareTextOne": "I’m a PADI diver! I want to influence more others.",
  "certlist-shareTextTwo": "Click the top right corner, Share to your friends.",
  "certlist-shareOnceForEcards": "I’m a PADI diver! I have already got {length} diving certification{count} since {year} ",
  "certlist-shareOnceForNoEcards": "I want to learn to dive, get my PADI diving certification. Join with us right now!",
  "certlist-shareTwice": "It’s my {year} resolution!",
  "certlist-nextCard": "Get your next diving certification",
  "certlist-scuba": "Scuba Diver Certification",
  "certlist-freediver": "Freediver Certification",
  "certlist-mermaid": "Mermaid Certification",

  "sharecerts-isDiver": "Please scan the QR code if you’re a diver.",
  "sharecerts-padiWeChat": "Please follow PADI Official WeChat public account: PADIDIVING",
  "sharecerts-checkCerts": "Check your PADI certification",
  "sharecerts-isNotDiver": "Please follow PADI if you’re not a diver yet",
  "sharecerts-endMsg": "to broaden your horizon and discover the ocean world",

  "certdetail-storeMsg": "DIVE SHOP",
  "certdetail-address": "ADDRESS",
  "certdetail-phone": "PHONE",
  "certdetail-instructorInfo": "INSTRUCTOR",
  "certdetail-name": "NAME",
  "certdetail-instructorNum": "PADI NO.",
  "certdetail-myInfo": "STUDENT DETAILS",
  "certdetail-diverNum": "PADI NO.",
  "certdetail-birth": "BIRTHDATE",
  "certdetail-certDate": "CERT.DATE",
  "certdetail-expireDate": "The validity of certification card is available until",
  "certdetail-switchCoverImage": "Slide around to choose eCard image",
  "certdetail-qualification": "QUALIFICATION",
  "certdetail-update-photo": "Update the photo",
  "certdetail-reactivate": "REACTIVATE DATE",

  "certDetailShareOnce": "I’m a PADI {ecardName} diver, please join us to dive！",
  "certDetailShareTwice": "It’s my {year} Target！",

  "cardOptions-replaceCardHint": "Reminder",
  "cardOptions-replaceCardMsg": " This \"{currentLevelInCart}\" is already in your request queue. Do you want to replace it with \"{currentCardOption}\"?",
  "cardOptions-addToCart": "Add to request queue",
  "cardOptions-existing": "Added this card to request queue",
  "cardOptions-buy": "Apply now",

  "cardRequest-non-mainland": "To apply for a plastic card, please go to {url}.",
  "cardRequest-non-mainland-diver": "global official website",
  "cardRequest-non-mainland-pro": "pro site",
  "cardRequest-statusLoading": "We are searching the entity card record…",
  "cardRequest-statusError": "There’s error when searching the data, please try it later……",

  "qrCodeWrapper-attention": "Welcome to follow PADI Official WeChat account PADIDIVING",
  "qrCodeWrapper-getMoreMsg": "Get the newest diving information and diving courses",
  "qrCodeWrapper-service": "Enjoy the diving service.",
  "qrCodeWrapper-dev": "This eCard is developed by PADI Official",
  "qrCodeWrapper-Authorize": "Please contact with PADI Official to get the transfer permission.",
  "qrCodeWrapper-belongToPADI": "PADI Official reserves the right of final interpretation.",

  "emailLogin-login": "Log In",
  "emailLogin-emailInput": "Please enter Email address",
  "emailLogin-errorCode": "Please enter the correct verification code",

  "loginComponent-signUp": "Sign Up",
  "loginComponent-signUpTitle": "Sign Up",
  "loginComponent-errorPassword": "Enter correct email address and password",
  "loginComponent-passwordInput": "Please enter the password",
  "loginComponent-forgotPassword": "Forget the password",
  "loginComponent-footer": "Please enter your PADI single sign on account to login.",
  "loginComponent-padiIntro": "PADI is the way the world learns to dive, base camp to protect our blue planet, and force for good. Following PADIDIVING to learn more.",
  "reset-pwd-attention": "Attention",
  "reset-pwd-desc": "After successfully resetting the password, the page may not change. At this point, you can return to the electronic card login page and log in with the new password.",
  "reset-pwd-skip-now": "Skip now",

  "setEmail-errorEmail": "Please enter your email address and 6 digital verification code",
  "setEmail-title": "Verify your email address",
  "setEmail-login": "Log In",

  "setPassword-errorConfirmPassword": "Please confirm the two passwords that you typed is match",
  "setPassword-title": "Set password",

  "setProfile-errorName": "Please enter your name (Pinyin or English name)",
  "setProfile-defaultName": "Pinyin or English name",
  "setProfile-errorGender": "Please choose gender",
  "setProfile-errorBirthDate": "Please choose birth date",
  "setProfile-name": "Name",
  "setProfile-gender": "Gender",
  "setProfile-chooseDate": "Choose Date",
  "setProfile-birthDate": "Birth Date",
  "setProfile-skip": "Skip this step",
  "setProfile-footer": "Please complete your personal information so that we could provide more service to you in the future. Thanks for your understanding",
  "setProfile-title": "Personal information",
  "setProfile-firstName": "First Name",
  "setProfile-lastName": "Last Name",

  "setMobile-China": "Mainland Region",
  "setMobile-Hongkong": "HongKong Region",
  "setMobile-Taiwan": "Taiwan Region",
  "setMobile-Macau": "Macau Region",
  "setMobile-errorCode": "Please enter your correct mobile number and 6 digital verification code",
  "setMobile-chooseRegion": "Choose region",
  "setMobile-regions": "Region",
  "setMobile-mobileInput": "Input mobile number",
  "setMobile-title": "Verify Mobile",

  "forgotPassword-reset": "Reset password",
  "forgotPassword-title": "Please enter your email address",
  "forgotPassword-emailInput": "Email address",
  "forgotPassword-sendEmail": "Please log in with your email address, check the reset password, and do the next step.",
  "forgotPassword-sendSuccess": "Sent out successful",
  "forgotPassword-footer": "If you reset your password, your PADI accounts will also be changed included PADI Pro Site, Scuba Earth, My PADI Club, eLearning.",

  "getCode-sendMobile": "Verification code has been sent.",
  "getCode-sendEmail": "Verification code has been sent. Please check your Trash if you didn’t find the code in your inbox. Input the code and click \"Validate\" to validate your email.",

  "campaign-loadingEcards": "Searching your certification...",
  "campaign-noEcardSelected": "Please choose your certification",
  "campaign-tips": "请选择一张欲更换龙年卡的潜水证书",

  "cardRequest-cart": "Card Requests",
  "cardRequest-count": "Total {number} {padi} card{count}",
  "cardRequest-continueToBuy": " Apply more",
  "cardRequest-checkedAll": "Select all",
  "cardRequest-total": "Total: ",
  "cardRequest-goToPay": "Checkout",
  "cardRequest-empty": " There is no card request~",
  "cardRequest-goToCerts": " Choose",
  "cardRequest-title": "Card",
  "cardRequest-postage": "Shipping fee: ",

  "profile-profile": "Personal Information",
  "profile-name": "Name: ",
  "profile-birthDate": "Birthdate: ",
  "profile-mobile": "Phone: ",
  "profile-addressEdit": "Shipping Address Management",
  "profile-language": "Set Language",
  "profile-setLanguage": "Set Language",
  "profile-youzan": "Binding with PADI Youzan Shop account",
  "profile-book": "Purchase course materials",
  "profile-agent-elearning": "Buy elearning for student",
  "profile-efr-public": "Issue EFR Public",
  "profile-changeYouzan": "Change PADI Youzan Shop account",
  "profile-mobileTips": "Only for Chinese mainland phone number.",
  "profile-currentMobile": "Current binding phone number: ",
  "profile-binding": "Confirm",
  "profile-apply-manager": "Apply for the manager of dive store",
  "profile-reviewing": "Under review",
  "profile-change-manager": "Change dive store manager",
  "profile-remove-manager": "Remove the dive store manager",
  "profile-action-sheet-title": "You are the manager of the dive store ",
  "profile-change-or-remove": " currently, you could change or remove the dive store management.",
  "profile-remove": "Remove",
  "profile-comfirm-text": "Confirm to remove dive store manager?",
  "profile-remove-success": "Remove successfully",
  "profile-store-number-error": "Please enter the correct dive store number.",
  "profile-change-manager-title": "Change dive store management",
  "profile-store-number-input": "Please enter the dive store number.",
  "profile-store-list-title": "You are the manager of the dive store({storeNum})",
  "profile-store-title": "Store Information",
  "profile-store-name": "Store Name: ",
  "profile-store-num": "Store No.: ",
  "profile-copyright": "Copyright",
  "profile-email": "Change Email Account",
  "profile-dm": "Dive Master Application",
  "profile-app": "Dive Master Application",
  "profile-si": "Specialty Instructor Application",
  "profile-si-cd": "Issue Specialty Instructor",
  "profile-owsi-cd": "IE Enrollment Review(Beta)",
  "profile-owsi": "IE Enrollment and OWSI Application(Beta)",
  "profile-ie": "IE",
  "profile-efrt": "EFRI Review",
  "profile-BMIT": "Basic Mermaid Instructor Review",
  "profile-MIT": "Mermaid Instructor Review",
  "profile-fdi": "Free Diver Instructor Review",
  "profile-afdi": "Advanced Freediver Instructor Review",
  "profile-mfdi": "Master Free Diver Instructor Review",
  "profile-fd-pic": "Issue Advance Level Freediver",
  "profile-scuba-app": "Scuba professional level application",
  "profile-efr-app": "EFR professional level application",
  "profile-efr-public-app": "EFR Public Instructor Application",
  "profile-mermaid-app": "Mermaid Professional Level Application",
  "profile-fd-app": "Free Diving Professional Level Application",
  "profile-course-pic": "PIC and Courses",
  "profile-course-insurance": "Purchase Dive Insurance Service",
  "profile-insurance-shopPublic": "ShopPublic liability insurance",
  "profile-insurance-instructor": "Instructor Professional Liability Insurance",
  "profile-insurance-diver": "Diver Personal accident insurance(Become a promotion club/Instructor)",

  "addressList-hintMsg": "Confirm to delete the address?",
  "addressList-title": "My shipping Address",
  "addressList-edit": "Edit",
  "addressList-delete": "Remove",
  "addressList-noAddress": "Please input your shipping address",
  "addressList-add": "Set a New address",

  "addressForm-edit": "Edit Address",
  "addressForm-add": "Input Address",
  "addressForm-contact": "Contact",
  "addressForm-mobile": "Phone",
  "addressForm-region": "Region",
  "addressForm-address": "Address",
  "addressForm-save": "Save",
  "addressForm-defaultName": "Name",
  "addressForm-selectRegion": "State",
  "addressForm-addressDetail": "Street",
  "addressForm-saveFail": "Failed",
  "addressForm-saveHint": "Please fill in the correct phone number and address information.",

  "home": 'ECARD',
  "navi-campaign": 'Campaign',
  "navi-store": 'PADI Youzan Mall',
  "cardRequest": "Plastic card Requests",
  "download-wall-cert": "Download Wall Certification",
  "wechat-wall-cert": "Please open in browser to download Wall Certification.",
  "wechat-cert": "Please open in browser to download.",
  "apply-for-free-card": "Get Free Plastic Card",
  "apply-for-free-card-ok": "Your request submit successfully.",
  "profile": "MY",

  "success-title": "Registration succeeded! ",
  "success-hasSso": "Please use your PADI single sign on account to login: ",
  "success-login": "Login",
  "failure-title": "Register failed",
  "failure-hint": "Failed, please try it later",
  "failure-service": "Contact with customer services: chinese.services@padi.com",
  "success-SignIn": "Back to sign up",
  "success-hasYouzan": "You PADI Youzan Shop account: ",
  "success-hasnoYouzan": "Please go to 'MY' menu to fill in your phone number and register your PADI Youzan Shop account.",

  "renew-member-title": "Individual Membership Update",
  "renew-status-ok": "Year 2024 Renewed",
  "renew-status-in-progress": "In Progress",
  "renew-subtitle": "Certification and Fee",
  "renew-exclude-efr": "Please click button to cancel if you don’t want to renew",
  "renew-statement": "Final statement",
  "renew-back": "Back to My Account",
  "renew-store-title": "Update PRRA Membership",
  "renew-choose-item": "Please choose your renew items (multiple) ",
  "renew-hint": "Please choose item to renew",
  "renew-statement-tips": "Renew before January 1, 2023 to get elearning coupons",

  "store-list-no-more-store": "No more dive stores",
  "store-list-freedive": "Freedive",

  "dm-form-title": "PADI DM Application",
  "dm-app-list": 'PADI DM Application List',
  "dm-form-diver-info": "Applicant",
  "dm-form-name": "Name",
  "dm-form-birth": "Birth Date",
  "dm-form-confirm-birth": "Confirm birth date",
  "dm-form-required-certs": "Prerequisites",
  "dm-form-training-info": "Training Info",
  "dm-form-instructor-warning": "instructor number",
  "dm-form-instructor-number": "Instructor",
  "dm-form-store-warning": "store number(optional)",
  "dm-form-store-number": "Store",
  "dm-form-complete-date": "Completion Date",
  "dm-form-date-warning": "Please input completion date.",
  "dm-form-skip-to": "Send certs to",
  "dm-form-agreement-60-dives": "I have finished more than 60 dives.",
  "dm-form-agree": "Agree",
  "dm-form-reject": "Reject",
  "dm-form-agreement-link": "Membership Agreement and License Agreement",
  "dm-form-agreement-text": "I have read the {link}, and hereby consent and agree to the terms and conditions in their entirety. I understand and agree that any criminal conviction on my part involving abuse of a minor or sexual abuse of an adult occurring either during or prior to my membership with PADI, will be automatic grounds for denial or termination of my PADI Membership. I hereby certify that all the above statements are true and correct to the best of my knowledge. This application does not constitute membership. Membership is activated only upon review and approval of this application by PADI.",
  "dm-form-instructor-agreement": "I certify that all prerequisites and certification requirements have been met as outlined in the PADI Instructor Manual. I verify the applicant has logged 60 dives.",
  "dm-form-submit": "Submit",
  "dm-form-searching": "Searching...",
  "dm-form-instructor-error": "Please input instructor number.",
  "dm-form-submit-error": "Submission failed.",
  "dm-form-submit-error-info": "Please confirm all required info input correctly, and accept agreement.",
  "dm-form-submit-error-info-no-agreement": "Please confirm all required info input correctly.",
  "dm-form-entrance-title": "Dive Master Application",
  "dm-form-applicationNo": "Your applicaiton id is ",
  "dm-form-message-review": "Your instructor and PADI will review your application. You can go back later and check the status.",
  "dm-form-message-address": "Please post your decal to the following address, annotated with your application number, or your email, or any of your PADI cert number: ",
  "dm-form-message-address-detail": "Room 1515, Unit 2, SOHO, GuangHua Road 22, Chaoyang, Beijing",
  "dm-form-pay": "Continue to Pay",
  "dm-form-signature": "Signature",
  "dm-form-signature-clear": "Clear",
  "dm-form-signature-save": "Save",
  "dm-form-wait-review": "Wait for instructor's review",
  "dm-form-wait-cert": "Cert issuing",
  "dm-form-wait-pay-student": "Applicant to Pay",
  "dm-form-wait-pay-instructor": "Instructor to Pay",
  "dm-form-processing": "Processing by PADI",
  "dm-form-resubmit": "Resubmit",
  "dm-form-signature-confirm": "Please confirm your signature.",
  "dm-form-instructor-create": "New Application",
  "dm-form-search-applicant": "Input student number of any level",
  "dm-form-student-number": "Student Number",
  "dm-form-studentno-error": "Please input correct student number.",
  "dm-form-studentno-not-found": "No student found, please confirm student number is correct.",
  "dm-form-student-added": "The applicant is already added.",
  "dm-form-storeno-not-found": "No store found, please confirm store number is correct.",
  "dm-application-empty-list": "No application in progress.",
  "dm-application-add-app": "Start application",
  "dm-app-not-approved-tips": "The Application is rejected: ",
  "dm-refuse-reason": "Reason for rejection",
  "dm-refuse-tips": "The reason for rejection is required.",
  "dm-refuse-error": "Please input reason for rejection.",
  "dm-region-and-lang-title": "Teach Location and Language",
  "dm-select-region-error": "Choose Region",
  "dm-region": "Teach Location",
  "dm-lang": "Choose Language",
  "dm-lang-error": "Please choose language",
  "dm-lang-title": "Language",
  "dm-efr-expired": "Your EFR is expired, please upload documents.",
  "dm-dmai-text": "You can upload a screenshot of the DM application if you submit a DM application at the same time.",
  "dm-cert-info": "Certificate Information",

  "dm-form-instructor-reject-confirm": "Continue to reject?",
  "dm-form-instructor-accept-confirm": "Continue to accept?",

  "app-status-wait-confirm": "To Confirm",
  "app-status-instructor-approved": "Confirmed",
  "app-status-instructor-not-approved": "Rejected",
  "app-status-success": "Completed",
  "app-status-instructor-created": "Created",
  "app-status-submit": "Applicant Submit",
  "app-status-paid": "Paid",
  "app-status-to-pay": "Not Paid",
  "app-to-pay": "Checkout",
  "app-wait-pay": "Waiting for Payment",
  "app-wait-cs-review": "Waiting for PADI Review",
  "app-wait-candidate-pay": "Waiting for Payment",
  "app-cs-not-approved": "Rejected",
  "app-refuse-reason": "Rejected: ",
  "app-wait-cert": "Issuing Certification",
  "app-success": "Completed",
  "app-close": "Closed",
  "app-wait-cd-review": "Course Director to Review",
  "app-wait-cd-pay": "Course Director to Pay",
  "app-has-efri": "Have an EFRI certificate that meets the application requirements",
  "app-efr-or-efri": "EFR or EFRI",
  "app-health": "Upload health declaration and other materials",
  "app-health-and-others": "Upload materials such as health declarations, certificate of follow-up classes, etc.",
  "app-owsi-other-files": "please upload None PADI certification Copy If have/Upload Medical Form copy and bring Paper version to IE",
  "app-efr-or-efri-files": "EFR/EFRI materials",
  "app-not-has-right-cert": "This certificate was not found",
  "app-wait-ins-review": "Waiting for coach review",
  "app-wait-trainer-review": "Waiting for review by the trainer",
  "app-resubmit": "Resubmit",
  "app-submit": "Submit Application",
  "app-wait-applicant-confirm": "Waiting for the applicant to confirm",
  "app-pay-error": "Pay Error",
  "app-prosite": "Need to go to the pro site to modify by yourself",
  "app-delete": "Cancel application", "app-cancel": "Confirm to cancel the application?",

  "resources-marketing-hub": "Marketing Hub",
  "resources-training-hub": "Training Hub",
  "resources-mainTitle": "PADI Chinese Material Library",
  "resources-link": "Please send an email to chinese.member@padi.com with the email account you need to register with PADI CANVA, and we will send you a targeted invitation email. You can register through the email invitation link you received",
  "resources-introduction": "Welcome to PADI Chinese Material Library! You can search and download PADI Chinese Marketing Materials, Training Materials and Guildlines, etc. {link} to access image files.",
  "resources-canva-tips": "You need to register with Canva to view this content.",
  "resources-cana-registered": "Registered",
  "resources-cana-to-register": "Register Now",

  "campaign-more": "More is coming.",
  "campaign-title-festival": "2021 Festival Tickets",
  "campaign-festival-ticket-one": "1 Ticket",
  "campaign-festival-ticket-two": "2 Tickets",
  "campaign-festival-max-tickets": "Limited to 1 ticket per person.",
  "campaign-festival-error": "Please purchase at least one ticket.",
  "campaign-festival-max-tickets-error": "Limit to 2 tickets per person.",
  "campaign-festival-ticket": "Tickets",
  "campaign-title-coupon": "Success",
  "campaign-title-coupon-success": "The coupon was issued successfully.",
  "campaign-title-coupon-success-go": "Use Now",
  "campaign-title-coupon-success-later": "Use Later",
  "card-requested": "You have already claimed this card.",

  "si-profile": "Applicant",
  "si-member-number": "Member",
  "si-level": "Rating",
  "si-conditions": "Apply Directly",
  "si-upload-attach": "Document Required",
  "si-upload-btn": "Upload",
  "si-form-title": "Specialty Instructor Application",
  "si-agreement-content": "I have certified at least 25 divers and have completed and logged at least 20 dives in each of the specialty area(s) that I am applying for, and if asked by PADI to show these logged dives, I can do so. Additionally, I agree to use the PADI standardized Specialty Course Instructor Guide or the reviewed and approved Instructor-authored Specialty Course Instructor Guide and understand that I may conduct the course(s) only after receiving written approval from PADI along with my Specialty Instructor certification materials.",
  "si-app-number": "Application No:",
  "si-check": "View",
  "si-reSubmit": "Apply",
  "si-sp": "Specialty: ",
  "si-app-status": "Applicaiton Status: ",
  "si-sp-app": "Specialty Instructor Application",
  "si-check-attach": "View Uploaded Documents",
  "si-open-attach": "View Documents",
  "si-agreement-cd": "I agree to use PADI’s Standardized guide or the guide provided by my Course Director and understand that I may conduct the specialty course(s) listed only after receiving written approval from PADI along with my Specialty Instructor certification materials. Additionally, I have completed and logged at least 10 dives (20 for Semiclosed Rebreather applications) in each of the specialty area(s) that I am applying for, and if asked by PADI to show these logged dives, I can do so.",
  "si-cd-course": "Course Info",
  "si-store-num": "Input store number",
  "si-course-location": "Course Location",
  "si-course-location-title": "Course Location",
  "si-app-name": "Applicant: ",
  "si-cd-instructor-num": "Member Number",
  "si-cd-instructor-num-tip": "Input member number",
  "si-uploadAttach-error": "Maximum 5 attachments allowed.",
  "si-instructor-number": "Member Number:",
  "si-cd-instructor-num-error": "Please input valid member number.",
  "si-refuse-reason": "Reject: ",
  "si-no-direct-sp": "No specialty available for apply.",
  "si-no-attach-sp": "No specialty available for apply.",
  "si-agreement-cd-instructor": "I agree to use PADI’s Standardized guide or the guide provided by my Course Director and understand that I may conduct the specialty course(s) listed only after receiving written approval from PADI along with my Specialty Instructor certification materials. Additionally, I have completed and logged at least 10 dives (20 for Semiclosed Rebreather applications) in each of the specialty area(s) that I am applying for, and if asked by PADI to show these logged dives, I can do so.",
  "si-credits": "Prerequisite",
  "si-credits-text": "At lease 25 divers certified by you before you can apply for specialty instructor.",
  "si-wait-cd-to-pay": "Waiting Course Director to Pay",
  "si-back": "Back",
  "si-paypal": 'You submit {count} specialities，$AUD {price} each，$AUD {total} total after discount，click "Confirm" to pay.',

  "book-search": "Search product",
  "book-total": "Total: ",
  "book-checkout": "Checkout",
  "book-checkout-hint": "Checkout to see discount",
  "book-submit-success": "You order submit successfully.",
  "book-offline-instruction": "Please transfer {price} to the following account, and email your remittance proof to chinese.sales@padi.com. We'll arrange the shipment after payment review.",
  "book-result-bank": "BANK: ",
  "book-result-address": "ADDRESS: ",
  "book-result-account-name": "ACCOUNT NAME: ",
  "book-result-account-number": "ACCOUNT NUMBER: ",
  "book-remittance": "Transfer Info",

  "owsi-cd-info": "Course Director",
  "owsi-cd-tips": "Course Director's member number",
  "owsi-cd-number-error": "Please input correct course director's number",
  "owsi-number": "Course Director Number",
  "owsi-store-info-tips": "Choose either of: ",
  "owsi-store-select": "Dive Shop",
  "owsi-alternative-location": "Alternative Location",
  "owsi-age-conditions": "Age Requirement",
  "owsi-age-accord": "You are over 18 years old and meet the application requirements.",
  "owsi-age-not-accord": "You are under the age of 18 and do not meet the application requirements.",
  "owsi-cert-conditions": "Prerequisites",
  "owsi-application": "OWSI Application",
  "owsi-location-tips": "Input location, up to 20 characters",
  "owsi-message-review": "Your course director and PADI will review your application. You can visit the system to check the progress of the application at any time.",
  "owsi-training-records-tips": "Please upload training records.",
  "owsi-pay-tips": "Please choose a payment method.",
  "owsi-candidate-pay": "Candidate to Pay",
  "owsi-cd-pay": "Course Director to Pay",
  "owsi-ie-location": "IE Location",
  "owsi-ie-date": "IE time",
  "owsi-ie": "IE Information",
  "owsi-candidate-list": "Candidates List",
  "owsi-selected-ie": "Select IE",
  "owsi-ie-list": "IE List",
  "owsi-training-records": "Training Materials",
  "owsi-upload-traning-records": "Upload training records",
  "owsi-agreement-text": "I have read the {agreement}, and hereby consent and agree to the terms and conditions in their entirety.",
  "owsi-agreement": "Membership Agreement and License Agreement",
  "owsi-criminal-record-statement": "I understand and agree that any criminal conviction on my part involving abuse of a minor or sexual abuse of an adult occurring either during or prior to my membership with PADI, will be automatic grounds for denial or termination of my PADI Membership. I hereby certify that all the above statements are true and correct to the best of my knowledge.",
  "owsi-cd-agreement-one": "I, as Course Director, hereby confirm that this candidate has met the diving experience requirements for the PADI Instructor Training and the PADI Instructor Examination (IE) (have been a certified diver for at least 6 months and have logged at least 60 dives before Instructor training, have logged at least 100 dives before Instructor Examination).",
  "owsi-cd-agreement-two": "I, as Course Director have verified that the person identified has all instructor candidate required materials including Aquatic Cue Cards.",
  "owsi-idc-error": "Please select IDC start date",
  "owsi-idc-end-error": "Please select IDC end date",
  "owsi-idc-date": "IDC start date",
  "owsi-idc-end-date": "IDC end date",
  "owsi-not-pass": "Failed the IE",
  "owsi-not-pass-reason": "Failed reason:",
  "owsi-paypal-reminder": 'The IDC course application fee and the IE fee will be paid, totaling {currency} {price}. ',
  "owsi-paypal-attempt-all": 'The IE fee will be paid, totaling {currency} {price}.',
  "owsi-paypal-attempt": "The re-examination fee for the coaching exam is about to be paid, totaling {currency} {price}.",
  "owsi-extensions": "IE Written Exam Time Extensions",
  "owsi-extensions-reminder": "Please contact the Instructor Development Department for details.",
  "owsi-ie-times": "IE Exam Attempts",
  "owsi-ie-first": "Attempt 1",
  "owsi-ie-second": "Attempt 2",
  "owsi-ie-third": "Attempt 3",
  "owsi-ie-fourth": "Attempt 4",
  "owsi-ie-resit": "IE exam make-up subjects",
  "owsi-ie-written-exam": "Written Exam",
  "owsi-ie-knowledge": "Knowledge Development",
  "owsi-ie-confined-water": "Confined Water",
  "owsi-ie-open-water": "Open Water",
  "owsi-id-resits": "Resits",
  "owsi-ie-resits-app": "IE resits application",
  "owsi-cd-other-files": "Please upload Instructor Candidate Information And Training Record/ Bring Medical Form copy to IE",

  "items-list": "Items List",
  "price-and-shipment": "Price and Shipment",
  "discount-level": "Discount Level: ",
  "discount-price": "Discount Price: ",
  "discount-coupon": "Coupon: ",
  "item-price": "Items Price: ",
  "shipment-fee": "Shipment Fee: ",
  "total-price": "Total: ",
  "checkout": "Checkout",
  "change-order": "Update Order",
  "payment-method": "Select payment method",
  "online-pay": "Online payment",
  "offline-pay": "Offline payment",
  "coupon-tips": "You have {count} elearning coupon to use.",
  "has-coupon": "You have {count} coupon to use",
  "coupon": "Coupon",
  "coupon-description": "Full {description} available",
  "coupon-conditions": "Use only for elearning products",
  "coupon-conditions-2023": "Use only for some elearning products",
  "coupon-use-error": "The payment amount of the elearning products you purchase must be greater than the coupon amount.",
  "coupon-use-error-2023": "The current order has no goods that can be deducted.",
  "coupon-not-to-use": "Do not use coupons temporarily",
  "coupon-used": "{count} coupon has been used",
  "coupon-without": "No coupon available",
  "coupon-voucher": "Voucher",
  "coupon-useless": "Unavailable reason:",
  "coupon-expired": "The coupon has expired.",

  "paypal-callback": "Please wait a while for the payment...",
  "paypal-callback-success": "Payment was successful.",
  "paypal-callback-error": "Payment failed.",
  "paypal-order-detail": "Order Information",
  "paypal-order-number": "Order number:",
  "paypal-order-price": "Total amount:",
  "paypal-goods-name": "Product name:",
  "paypal-goods-quantity": "Product quantity:",
  "paypal-reminder": 'Click "Confirm" to pay ${currency} {price} . ',
  "paypal-to-pay": "Do you want to pay now?",
  "paypal-pay-now": "Submitted successfully! Do you want to pay now?",
  "paypal-pay": "Go to pay",
  "paypal-pay-later": "No payment temporarily",

  "credit-card-saveHint": "Please fill in the correct credit card information",
  "credit-card-title": "Credit Card Information",
  "credit-card-number": "Credit Card Number",
  "credit-card-date-year": "Validity Year (YY)",
  "credit-card-date-month": "Validity Month (MM)",
  "credit-card-ccv": "Security Code",
  "credit-card-pay-success": "Payment successful",
  "credit-card-accepted-types": "Accepted card types: Visa，MasterCard，American Express.",

  "efri-agreement": "I have obtained the required current EFR Instructor materials and have made myself familiar with the contents. I understand Icannot conduct any Emergency First Response (EFR) courses until I receive authorization from EFR. I further agree that when conducting EFR courses I will abide by allEFR Standards and procedures as published and updated by EFR. I will maintain familiarity with EFR educational materials, includinrevisions to existing materials andthe introduction of new materials. I affirm that I have read and will abide with the EFR License Agreement found in the AppendiSection of the EFR Instructor Guide. Iunderstand and agree that any criminal conviction on my part involving abuse of a minor or sexual abuse of an adult, occurrineither during or prior to my certificationas an EFR Instructor, will be automatic grounds for denial or revocation of my credential. I also understand EFR may refuse taccept my application or rescind any EFRInstructor credentials I may have if EFR determines my certification is not in the best interest of Emergency First Response.",
  "efri-course-date-error": "Please select the course completion date",
  "efri-application": "EFR emergency first response instructor application",
  "efrt-list": "EFRI Application List",
  "efri-message-review": "The trainer and PADI will review your application. You can visit the ecard system to check the progress of the application at any time.",
  "efri-age-not-accord": "You are under 18 years old and do not meet the EFR emergency first response instructor application requirements.",
  "efri-age-accord": "You are over 18 years old and meet the EFR emergency first response instructor application requirements.",
  "efri-form-wait-review": "Waiting for review by the trainer",
  "efri-wait-efrt-pay": "Waiting for payment by trainer",
  "efri-efrt-pay": "Trainer pays",
  "efrt-number": "Trainer Number",
  "efrt-number-warning": "Please enter the trainer number",
  "efrt-number-error": "Please enter the correct trainer number.",
  "efri-valid": "The applicant is EFRI",

  "agent-elearning-header-email": "Buy for this student",
  "agent-elearning-header-list": "Elearning available for purchase",
  "agent-elearning-email-hint": "Please enter the student's email address first to find available elearning.",
  "agent-elearning-email-placeholder": "Enter email to search for student",
  "agent-elearning-new-student": "The {inputVal} related account was not found in the PADI system. If you are buying for a new student, please continue to provide the following information, and we will create a PADI SSO login account based on the information you provide. And notify students by email to access the elearning system.",
  "agent-elearning-not-available": "There is no elearning available. Currently, only Open Water and Advanced Open Water elearning purchases are supported.",

  "BMI-app": "Basic Mermaid Instructor Application",
  "BMI-agreement1": "I agree to abide by the PADI Mermaid series of course standards and understand that only the local PADI regional headquarters can issue a written approval to teach.",
  "BMI-agreement2": "I have read the membership agreement and authorization agreement (attached to the PADI Mermaid Instructor Guide appendix), and I agree and agree to all terms and conditions.",
  "BMI-agreement3": "I understand and agree that if I am convicted of abusing a minor or sexually assaulting an adult during or before obtaining the PADI membership, my PADI membership will be automatically rejected or terminated accordingly. Confirm that the above statement is completely true and correct.",
  "BMI-advanced-mermaid": "Advanced Mermaid",
  "BMI-profects": "Complete the following items",
  "BMI-profect1": "Knowledge Development Part of the Mermaid Instructor Course",
  "BMI-profect2": "Mermaid instructor final exam, the minimum passing score is 75 points",
  "BMI-profect3": "Water Skill Assessment",
  "BMI-profect4": "Demonstration of skills to show demonstration level",
  "BMI-profect5": "Assist the mermaid instructor to perform at least one mermaid experience activity and two basic mermaid/mermaid courses in confined water diving 1",
  "BMI-pay-reminder": "If you pay the application fee personally, please click the button below to complete the payment. Or you can complete the payment later by returning to the application page.",
  "BMI-pay-reminder-t": "If the trainer pay the fee, you don’t need to do anything. We will prompt trainer to complete the payment.",
  "BMI-message-review": "The trainer and PADI will review your application. You can visit the electronic card system to check the progress of the application at any time.",
  "BMI-trainer-review-reminder": "The review is successful! Do you want to pay now?",
  "BMI-t-list-title": "Basic Mermaid instructor Application List",

  "Mermaidi-app": "Mermaid Instructor Application",
  "mermaid-t-list-title": "Mermaid Instructor Application List",
  "mermaid-upload-reminder": "Please upload a PADI Advanced Mermaid Certificate or equivalent certificate, or provide a certificate of 15 mermaid diving experience, including at least 3 open water mermaid diving",
  "mermaid-other-files": "Other Materials",
  "mermaid-choice": "2 choose 1",
  "mermaid-choice1": "1, complete the PADI Mermaid Instructor course with a PADI Mermaid Instructor Trainer with teaching status, or",
  "mermaid-choice2": "2, hold a mermaid instructor certificate or higher certificate from another organization.",
  "mermaid-choice2-1": "Complete the PADI Mermaid Instructor Introduction Course",
  "mermaid-choice2-2": "Have taught or assisted at least 2 mermaid courses, which is equivalent to meeting the PADI Mermaid course requirements, or assisted in the implementation of PADI Mermaid courses once",

  "fdi-app": "Free Diver Instructor Application",
  "fdi-master-cert": "PADI Master Freediver",
  "fdi-owsi-cert": "PADI Open Water Scuba Instructor",
  "fdi-not-padi-cert": "Qualified free diver instructor certificate of other organizations",
  "fdi-advance-cert": "PADI Advanced Freediver",
  "fdi-owsi-reminder": "Upload qualified free diver instructor certificates from other organizations.",
  "fdi-app-list": "Free diving instructor application list",
  "fdi-agreement1": "I agree to follow the PADI Freediver Program standards and understand that I may conduct the course(s) only after receiving written approval from my PADI Regional Headquarters.",
  "fdi-agreement2": "I have read the Membership Agreement and License Agreement (found in the Appendix of the PADI Freediver Program Instructor Guide) and hereby consent and agree to the terms and conditions in their entirety. I understand and agree that any criminal conviction on my part involving abuse of a minor or sexual abuse of an adult occurring either during or prior to my membership with PADI, will be automatic grounds for denial or termination of my PADI Membership. ",
  "fdi-agreement3": "I hereby certify that all the above statements are true and correct to the best of my knowledge.",

  "afdi-app": "Advanced Free Diver Instructor Application",
  "afdi-padi-fdi": "PADI Freediver Instructor",
  "afdi-satisfy": "(Meet the application requirements)",
  "afdi-master-fd": "Master Freediver",
  "afdi-certs-Requirement": "Number of Certification Issued",
  "afdi-satisfy-certs": "25 free diver certificates have been issued",
  "afdi-upload-cert-requirement": "25 free diver visa records need to be uploaded",
  "afdi-prepare": "PADI Freediver Instructor Preparation Course",
  "afdi-prepare-others": "PADI Freediver Instructor Preparation Course/Other Materials",
  "afdi-t-title": "Advanced Freediving Instructor Application List",
  "afdi-agreement1": "I agree to follow the PADI Freediver Program standards and understand that I may conduct the course(s) only after receiving written approval from my PADI Regional Headquarters. ",
  "afdi-agreement2": "I have read the Membership Agreement and License Agreement (found in the Appendix of the PADI Freediver Program Instructor Guide) and hereby consent and agree to the terms and conditions in their entirety.",
  "afdi-agreement3": "I understand and agree that any criminal conviction on my part involving abuse of a minor or sexual abuse of an adult occurring either during or prior to my membership with PADI, will be automatic grounds for denial or termination of my PADI Membership. I hereby certify that all the above statements are true and correct to the best of my knowledge.",

  "mfdi-app": "Master Free Diver Instructor Application",
  "mfdi-satisfy-certs": "50 free diver visas have been issued, at least 10 of which are advanced PADI free diver visas",
  "mfdi-upload-cert-requirement": "50 free diver visa records need to be uploaded, at least 10 of which are advanced PADI free diver visas",
  "mfd-padi-afd": "PADI Advanced Freediver Instructor",
  "mfd-afdi": "Advanced Freediver Instructor",
  "mfdi-agreement1": "I agree to follow the PADI Freediver Program standards and understand that I may conduct the course(s) only after receiving written approval from my PADI Regional Headquarters. ",
  "mfdi-agreement2": "I have read the Membership Agreement and License Agreement (found in the Appendix of the PADI Freediver Program Instructor Guide) and hereby consent and agree to the terms and conditions in their entirety.",
  "mfdi-agreement3": "I understand and agree that any criminal conviction on my part involving abuse of a minor or sexual abuse of an adult occurring either during or prior to my membership with PADI, will be automatic grounds for denial or termination of my PADI Membership. I hereby certify that all the above statements are true and correct to the best of my knowledge.",
  "mfdi-t-title": "Master Free Diving Coach Application List",

  "fd-pic-new": "New Free Diving certificate",
  "fd-pic-student-info": "Student information (multiple can be added)",
  "fd-pic-title": "Free Diving certificate",
  "fd-pic-email": "Email:",
  "fd-pic-status": "Status:",
  "fd-pic-date": "Date:",
  "fd-pic-level": "Level:",
  "fd-pic-finish": "Issued",
  "fd-pic-email-input": "Enter the email address you want to search",
  "fd-pic-course-level": "Course level",

  "efris-apps-title": "EFR Public Certs List",
  "efr-public-app-agreement": "While remaining an EFR Instructor, I applied to be an EFR Public Specialty Instructor.",
  "efr-public-app-done-title": "Operation successful",
  "efr-public-app-done-detail": "Your EFR Public Public Specialty Instructor application has been approved!",
  "efr-public-app-waiting-title": "Waiting to be processed",
  "efr-public-app-waiting-detail": "PADI has received your EFR Public Public Specialty Instructor application, please be patient, we will process your application as soon as possible.",
  "efr-public-new-student": "The email-related account is not found in the PADI system. If you are confirmed as a newly registered student, please continue to provide the following information, and we will create a PADI unified login account based on the information you provide and notify by email students.",

  "scuba-ow-title": "Scuba to OW Certs List",
  "issue-certs-efrp-name": "EFR Public Purchasing",
  "issue-certs-confined-pic-name": "Purchase Confined Water Diver PIC",
  "issue-certs-scuba-pic-name": "Purchase Scuba Diver PIC",
  "issue-certs-scuba2ow-name": "Scuba Diver to OW",
  "issue-certs-confined-name": "Confined Water Diver",
  "issue-certs-scuba-name": "Scuba Diver",
  "issue-certs-confined2ow-name": "Confined Water Diver to OW",
  "issue-certs-pic-history": "Purchase History",
  "issue-certs-pic-buy": "Buy PIC",
  "issue-certs-pic-amount": "amount",
  "issue-certs-pic-amount-placeholder": "Input amount",
  "issue-certs-history": "Certs History",
  "issue-certs-pic-left": "PIC in stock",

  "festival-submit-loading": "Submitting",
  "festival-ticket-type": "Ticket Type",
  "festival-ticket-type-error": "Ticket type is required",
  "festival-name-error": "Name cannot be empty",
  "festival-name-hint": "Input your name",
  "festival-phone-error": "Mobile phone number cannot be empty",
  "festival-store-num": "Diving store number (optional)",
  "festival-store-name": "Chinese name of the dive store (optional)",
  "festival-store-name-hint": "Input Chinese name of the dive store",
  "festival-checkin-name": "Checkin's name",
  "festival-checkin-name-error": "Checkin's name cannot be empty",
  "festival-checkin-name-hint": "Input checkin name",
  "festival-house-type": "Room Type",
  "festival-house-type-error": "Room type is required",
  "festival-double-bed": "Twin Room",
  "festival-big-bed": "Big Bed Room",
  "festival-arrival-date": "Arrival date",
  "festival-arrival-date-error": "Please select the date of arrival",
  "festival-leave-date": "Departure Date",

  "questionaire-title": "Questionnaire",
  "questionaire-message": "Congratulations on completing {course}. Please fill in the questionnaire so that we can better serve you. Thank you for your support!",
  "questionaire-course-OW": "PADI Open Water Diver Course",
  "questionaire-course-BASIC_FD": "PADI Basic Freediving Course",
  "questionaire-course-FD": "PADI Freediving Course",
  "questionaire-course-PADI_MERMAID": "PADI Mermaid Course",

  "procheck-about": "About Pro Check",
  "procheck-about-content": "Learn more about PADI Pro Check",
  "procheck-about2": "PADI Pro Chek is a tool to verify the membership status of PADI Professional Member.",
  "procheck-about3": "You'll find the PADI Member's name, highest core PADI professional rating, current status and the year that PADI Membership was last renewed.",
  "procheck-about4": "● Instructors are generally authorized to teach PADI core courses, guide divers and assist on PADI courses per their listed credential.",
  "procheck-about5": "● Divemasters and Assistant Instructors are generally authorized to guide divers and assist on PADI courses.",
  "procheck-about6": "● The information listed below is not all-inclusive. Not reflected are any additional credentials necessary to teach some specialized PADI courses and insurance requirements necessary to teach, guide or assist in some areas.",
  "procheck-about7": "PADI Membership are licensed to use various PADI Trademarks and to conduct PADI training. Individual, dive center and resort Members are not agents, employees or franchisees of PADI. Member business activities are indepentdent, and are neither owned nor operated by PADI.",
  "procheck-about8": "While PADI establishes the standards for PADI diver training programs, it is not responsible for, nor does it have the right to control, the operation of the Member's business activities and the day-to-day conduct of PADI programs abd supervision of divers by the Members or their associated staff.",
  "procheck-score": "PADI Score",
  "procheck-auth": "Get teaching authorization:",
  "procheck-renew": "Renewed:",
  "procheck-renew-date": "Renewal Year:",
  "procheck": "Pro Check Member Verification",
  "procheck-captcha": "Verification Code",
  "procheck-captcha-error": "The verification code cannot be empty",
  "procheck-captcha-refresh": "Click the verification code to refresh",

  "regulator-coupon-success": "Verification successed",
  "regulator-coupon-fail": "Verification Failed",
  "refulator-coupon-check": "validation",

  "findme-header": "Find me account",
  "findme-student-num-title": "Please provide your certificate number to help us retrieve your account",
  "findme-student-num-empty": "The certificate number cannot be empty",
  "findme-student-num-hint": "Please enter the certificate number",
  "findme-guide-disc": "You can retrieve your account based on your diving certificate number. If you don't know your diving certificate number, please contact your instructor; or submit your personal information to retrieve your account.",
  "findme-guide-student-num-yes": "I have a diving certificate number",
  "findme-guide-student-num-no": "I don't remember the dive certificate number",
  "findme-user-info-desc": "Please provide your personal information to help us retrieve your account",
  "findme-contact-service-tips": "Please enter the email address you are using to register as an account and log in to the system.",
  "findme-not-find": "We failed to find the relevant account number, you can go back and refill and confirm the information. Or contact customer service to retrieve the account number.",
  "findme-contact-service": "Contact customer service to retrieve account",
  "findme-fill-in-again": "Fill in again",
  "findme-success-contact-service": "If this is not your account, you can contact customer service to retrieve the account, or go back and fill in the information again to search.",
  "findme-login": "Register/login now",
  "findme-has-email-account": "If you have this email account, you can use it to register/login.",
  "findme-find-email-tips": "According to the information you provided, we found the following accounts in the system:",
  "findme-submit-email": "Submit a request to retrieve an account",
  "findme-desc": "If you can provide the diving certificate number, it will be more helpful to retrieve the account. You can contact your instructor to get the certificate number.",
  "findme-email-option": "Please enter the certificate number (optional)",
  "findme-submit-tips": "Your account retrieval/change request has been successfully submitted, our staff will process it as soon as possible, please pay attention to your email, thank you for your patience!",

  "updateUserInfo-title": "Modify Information",
  "updateUserInfo-hint": "Please enter new personal information",
  "updateUserInfo-success": "Your request has been submitted and it is expected to be processed within 1-2 working days",

  "pocket-apps-title": "Other membership applications",
  "pocket-apps-type": "Application Type",
  "pocket-apps-type-error": "The application type cannot be empty",

  "store-apps": "Store Application",
  "store-apps-type": "Store Type",
  "store-apps-error": "The store type cannot be empty",

  "award-title": "Personal Honor",
  "award-anniversary": "anniversary",
  "award-elite": "elite coach",
  "award-no-elite": "Certify more students to become a PADI Elite Instructor.",
  "award-year": "year",
  "award-cert": "certificate",
  "award-download-cert": "Download certificate",
  "award-download": "download",
  "award-anniversary-year": "PADI Member {year} Anniversary Certificate ({currentYear})",
  "award-no-anniversary": "Go with PADI for your first 5th anniversary.",

  "club-certs-list-title": "Please select the certificate you want to change the card surface",
  "club-receive-btn": "Receive now",
  "club-success": "Successfully claimed",

  "textbooks-order": "Visa service historical orders",
  "unconventional-order": "Unconventional service historical orders"
}
export default en;

