import React from 'react'
import CardLayout from '../DiveMasterForm/CardLayout'
import { FormattedMessage } from 'react-intl';

import styles from './OwsiApplication.module.css'

const CheckAge = ({ checkAge }) => {

  return <CardLayout title={<FormattedMessage id="owsi-age-conditions" />} status={checkAge}>
    {checkAge ? <div className={styles.age}>
      <FormattedMessage id="owsi-age-accord" />
    </div> : <div className={styles.age}>
      <FormattedMessage id="owsi-age-not-accord" />
    </div>}
  </CardLayout>
}

export default CheckAge