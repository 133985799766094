import React from 'react';
import { List, Accordion, WhiteSpace } from 'antd-mobile';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import styles from './Menu.module.css'

const MermaidMenu = ({ basic, mermaid, basicT, mermaidT }) => {

  const history = useHistory();

  if (!basic && !mermaid && !basicT && !mermaidT) return null

  return <>
    <Accordion defaultActiveKey="0">
      <Accordion.Panel header={<FormattedMessage id="profile-mermaid-app" />} className="profile-list member-renew">
        <List>
          {basic && <List.Item arrow="horizontal" onClick={() => history.push('/basic_mermaid_app')}>
            <span className={styles.menuItem}>
              <FormattedMessage id="BMI-app" />
            </span>
          </List.Item>}
          {mermaid && <List.Item
            arrow="horizontal"
            onClick={() => history.push('/mermaidi_app')}
          >
            <span className={styles.menuItem}>
              <FormattedMessage id="Mermaidi-app" />
            </span>
          </List.Item>}
          {basicT && <List.Item
            arrow="horizontal"
            onClick={() => history.push('/basic_mermaid_app_list')}
          >
            <span className={styles.menuItem}>
              <FormattedMessage id="profile-BMIT" />
            </span>
          </List.Item>}
          {mermaidT && <List.Item
            arrow="horizontal"
            onClick={() => history.push('/mermaid_app_list')}
          >
            <span className={styles.menuItem}>
              <FormattedMessage id="profile-MIT" />
            </span>
          </List.Item>}
        </List>
      </Accordion.Panel>
    </Accordion>
    <WhiteSpace size="sm" />
  </>
}

export default MermaidMenu