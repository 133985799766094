import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import React from 'react';

import { getCookie } from '../../components/common/cookie';
import { localizeResource } from '../../services/utilities';
import { useHasActivity, useErrorModal } from '../../services/hooks';
import Loading from '../../components/Loading';

import styles from './Campaign.module.css';

export default function CampaignPostWrapper(props) {
  const { push } = useHistory();
  const showErrorModal = useErrorModal();
  const { formatMessage } = useIntl();
  const { loading, data } = useHasActivity(14);

  if (loading) return <Loading />;
  if(!loading && !data) return null

  const { hasCard, binded } = data;
  const language = getCookie('language');
  // const cardAreaImg = localizeResource('card-display-area.png', language);
  // const sloganImg = localizeResource('slogan.png', language);
  const bottomBtnImg = localizeResource(
    hasCard ? 'option-btn.png' : 'non-diver-btn.png',
    language
  );
  // const bgImg = localizeResource('bg.jpg', language);
  const getCard = () => {
    if (binded) {
      showErrorModal(formatMessage({ id: 'card-requested' }));
    } else if (hasCard) {
      push('/newYearCampaign');
    } else {
      window.location = 'https://www.padi.com.cn/dive-shops/nearby';
    }
  };
  return <div className={styles.wrapper}>
    <div className={styles["card-post"]}>
      <img src={require('../../assets/images/campaign/newyear/bg-sc.jpg')} alt="new year" />
      <input
        type="image"
        name="imgBtn"
        className={styles["btn"]}
        src={require(`../../assets/images/campaign/newyear/${bottomBtnImg}`)}
        alt="imgBtn"
        onClick={getCard}
      />
    </div>
  </div>
}
