import React from 'react'
import { useSelector } from 'react-redux';
import AppList from '../../components/common/AppList'
import { skipFDITListPage } from '../../reducers/fdi'

const FDITList = () => {
  const { confirmRes } = useSelector((state) => ({
    confirmRes: state.FDI.FDITList,
  }));

  return <AppList confirmRes={confirmRes} detailUrl="/fdi_app_detail" paging={skipFDITListPage} listHeader="fdi-app-list"/>
}

export default FDITList