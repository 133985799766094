import { combineReducers } from 'redux'
import {createSlice} from "@reduxjs/toolkit";

export const FDIDetailSlice = createSlice({
  name: "FDIDetail",
  initialState: null,
  reducers: {
    FDIDetailRequest: {
      reducer(){
        return {loading: true}
      }
    },
    FDIDetailSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    FDIDetailFailed: {
      reducer(){
        return {}
      }
    },
    uploadFDIOssFile: {
      reducer(state){
        return state
      }
    },
    uploadFDIOssFileRequest: {
      reducer(state){
        return state
      }
    },
    uploadFDIOssFileSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return state
      }
    },
    uploadFDIOssFileFailed: {
      reducer(state){
        return state
      }
    },
    getFDIOssFileRequest: {
      reducer(state){
        return state
      }
    },
    getFDIOssFileSuccess: {
      reducer(state, action) {
        const data = action.payload;
        const url = data.replace('https://files.padi.com.cn/', '')
        const certType = url.split('/')[2]
        if(certType === 'efri') {
          const { efrifiles, ...detail } = state
          return { efrifiles: efrifiles ? [ data, ...efrifiles ] : [data], ...detail }
        }else if(certType === 'fdi') {
          const { fdifiles, ...detail } = state
          return { fdifiles: fdifiles ? [ data, ...fdifiles ] : [data], ...detail }
        }else if(certType === 'other') {
          const { otherFiles, ...detail } = state
          return { otherFiles: otherFiles ? [ data, ...otherFiles ] : [data], ...detail }
        }
      }
    },
    getFDIOssFileFailed: {
      reducer(state){
        return state
      }
    },
    removeFDIFile: {
      reducer(state, action) {
        const data = action.payload;
        const url = data.replace('https://files.padi.com.cn/', '')
        const certType = url.split('/')[2]
        if(certType === 'efri') {
          const { efrifiles, ...detail } = state
          const newEfrifiles = efrifiles.filter(item => item !== data)
          return { efrifiles: newEfrifiles, ...detail }
        }else if(certType === 'fdi') {
          const { fdifiles, ...detail } = state
          const newFdifiles = fdifiles.filter(item => item !== data)
          return { fdifiles: newFdifiles, ...detail }
        }else if(certType === 'other') {
          const { otherFiles, ...detail } = state
          const newOtherFiles = otherFiles.filter(item => item !== data)
          return { otherFiles: newOtherFiles, ...detail }
        }
      }
    }
  }
})

export const { 
  FDIDetailRequest,
  FDIDetailSuccess,
  FDIDetailFailed,
  uploadFDIOssFile,
  uploadFDIOssFileRequest,
  uploadFDIOssFileSuccess,
  uploadFDIOssFileFailed,
  getFDIOssFileRequest,
  getFDIOssFileSuccess,
  getFDIOssFileFailed,
  removeFDIFile } = FDIDetailSlice.actions

export const FDIDetail = {
  request: (data) => FDIDetailRequest(data),
  success: (data) => FDIDetailSuccess(data),
  failure: (error) => FDIDetailFailed(error),
}

export const uploadFDIAttachFile = {
  request: () => uploadFDIOssFileRequest(),
  success: (data) => uploadFDIOssFileSuccess(data),
  failure: (error) => uploadFDIOssFileFailed(error),
}

export const getFDIOssFile = {
  request: () => getFDIOssFileRequest(),
  success: (data) => getFDIOssFileSuccess(data),
  failure: (error) => getFDIOssFileFailed(error),
}

export const FDITListSlice = createSlice({
  name: "FDITList",
  initialState: {},
  reducers: {
    skipFDITListPage: {
      reducer(state, action) {
        return state
      }
    },
    FDITListRequest: {
      reducer(state){
        return state
      }
    },
    FDITListSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    FDITListFailed: {
      reducer(state){
        return state
      }
    }
  }
});

export const { FDITListRequest, FDITListSuccess, FDITListFailed, skipFDITListPage } = FDITListSlice.actions

export const getFDITListRequest = {
  request: () => FDITListRequest(),
  success: (data) => FDITListSuccess(data),
  failure: (error) => FDITListFailed(error),
}

export const FDITDetailSlice = createSlice({
  name: "FDITDetail",
  initialState: {},
  reducers: {
    FDITDetailRequest: {
      reducer(){
        return {}
      }
    },
    FDITDetailSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    FDITDetailFailed: {
      reducer(){
        return {}
      }
    }
  }
})

export const { 
  FDITDetailRequest, 
  FDITDetailSuccess, 
  FDITDetailFailed
} = FDITDetailSlice.actions

export const FDITDetail = {
  request: () => FDITDetailRequest(),
  success: (data) => FDITDetailSuccess(data),
  failure: (error) => FDITDetailFailed(error),
}

const freediveriReducer = combineReducers({
  FDIDetail: FDIDetailSlice.reducer,
  FDITList: FDITListSlice.reducer,
  FDITDetail: FDITDetailSlice.reducer,
})

export default freediveriReducer