import React from 'react'
import { List } from 'antd-mobile';
import { formatDate } from './utils';
import { FormattedMessage } from 'react-intl';

import styles from '../../pages/OwsiCdApps/OwsiCdApps.module.css'

const Item = List.Item;

const AppStatus = ({ status, paid, payerType, isStore }) => {
  switch (status) {
    case 'WAIT_CONFIRM':
      if (isStore) {
        return (
          <span className={styles.confirm}>
            <FormattedMessage id="app-wait-ins-review" />
          </span>
        )
      }
      return (
        <span className={styles.confirm}>
          <FormattedMessage id="app-status-wait-confirm" />
        </span>
      )
    case 'INSTRUCTOR_CREATED':
      return (
        <span className={styles.confirm}>
          <FormattedMessage id="app-status-instructor-created" />
        </span>
      )
    case 'INSTRUCTOR_NOT_APPROVED':
      return (
        <span className={styles.refused}>
          <FormattedMessage id="app-status-instructor-not-approved" />
        </span>
      )
    case 'INSTRUCTOR_APPROVED':
      if (paid) {
        return (
          <span className={styles.confirm}>
          <FormattedMessage id="app-wait-cs-review" />
        </span>
        )
      } else {
        if (payerType && payerType === 'CD') {
          return <span className={styles.confirmed}>
          <FormattedMessage id="app-to-pay" />
        </span>
        } else if (payerType && payerType === 'CANDIDATE') {
          return <span className={styles.confirmed}>
          <FormattedMessage id="app-wait-pay" />
        </span>
        } else {
          return (
            <span className={styles.confirm}>
            <FormattedMessage id="app-status-to-pay" />
          </span>
          )
        }
      }

    case 'ADMIN_NOT_APPROVED':
      return (
        <span className={styles.refused}>
          <FormattedMessage id="app-cs-not-approved" />
        </span>
      )
    case 'ADMIN_APPROVED':
    case 'PASS':
      if (!paid) return <FormattedMessage id="app-status-to-pay" />
      return (
        <span className={styles.confirm}>
          <FormattedMessage id="app-wait-cert" />
        </span>
      )
    case 'NOT_PASS':
      return <div className={styles.statusBtn}>
        <FormattedMessage id="owsi-not-pass" />
      </div>
    case 'SUCCESS':
      return (
        <span className={styles.confirm}>
          <FormattedMessage id="app-success" />
        </span>
      )

    case 'CLOSE':
      return (
        <span className={styles.confirm}>
          <FormattedMessage id="app-close" />
        </span>
      )
    case 'PAY_ERROR':
      return (
        <span className={styles.confirm}>
          <FormattedMessage id="app-pay-error" />
        </span>
      )
    default:
      return null
  }
}

const ListItem = ({ confirmItem, onClick, isStore }) => {

  return <Item
    extra={<AppStatus status={confirmItem.status} paid={confirmItem.paymentMethod} payerType={confirmItem.payerType}
                      isStore={isStore} />}
    onClick={onClick}
  >
    <>
      <div className={styles.name}>{confirmItem.userName}</div>
      <span>{confirmItem.email}</span>
      <div>{formatDate(confirmItem.lastSubmitDate, 'YYYY-MM-DD')}</div>
      {confirmItem.note &&
        <div className={styles.note}><FormattedMessage id="si-refuse-reason" />{confirmItem.note}</div>}
    </>
  </Item>
}

export default ListItem