import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import AdminContainer from '../../AdminContainer';
import { isAuthenticated } from '../../utils/user';
import { getReferer } from '../../utils/env';

function ProtectedRoute({
  component: Component,
  admin,
  ...rest
}) {

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated()) {
          return admin ? (
            <AdminContainer>
              <Component {...props} />
            </AdminContainer>
          ) : (
            <Component {...props} />
          );
        }
        const referer = getReferer();
        const redirectUrl = `/emailLogin?referer=${referer}`;
        return <Redirect to={redirectUrl} />;
      }}
    />
  );
}

export { ProtectedRoute };
