import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'antd-mobile-v5'
import { FormattedMessage, useIntl } from 'react-intl';
import { useDeleteStoreApplication } from '../../services/hooks';
import { showModal } from "../../components/common/utils";
import AppStatus from '../../components/FormTools/AppStatus'

const Status = ({
  status,
  form,
  code,
  submitting,
  note
}) => {

  const { formatMessage } = useIntl()
  const { goBack } = useHistory()
  const { loading, run } = useDeleteStoreApplication({
    onSuccess: () => {
      goBack();
    },
  });

  const deleteApplication = () => {
    showModal(
      '',
      formatMessage({ id: "app-cancel" }),
      () => run({ code }),
      () => { },
      formatMessage({ id: 'common-comfirmBtn' }),
      formatMessage({ id: 'common-cancel' })
    )
  }

  return <>
    <AppStatus
      status={status}
      form={form}
      submitting={submitting}
      note={note}
      noPayment={true} />
    {status && status !== 'ADMIN_APPROVED' && (
      <Button
        block
        color="primary"
        fill='none'
        onClick={deleteApplication}
        loading={loading}
        disabled={loading}
      >
        <FormattedMessage id="app-delete" />
      </Button>
    )}
  </>
}

export default Status