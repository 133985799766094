import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { resetErrorMessage, noticeHide, clearToast } from './actions';
import ErrorDialog from './components/common/ErrorDialog';
import NoticeBarForEcards from './components/common/NoticeBar';
import PageLoading from './components/common/PageLoading';
import Toast from './components/common/Toast';
import { useHasLogin } from './services/hooks';
import Loading from './components/Loading';

function AppContainer(props) {
  // const {pathname} = useLocation();
  useEffect(() => {
    function handleWechatTouchIssue() {
      if (
        /iPhone OS 12_1.*MicroMessenger\/6\.7\.4/.test(
          window.navigator.userAgent
        )
      ) {
        window.scrollBy(0, 1);
        window.scrollBy(0, -1);
      }
    }
    window.addEventListener('focusout', handleWechatTouchIssue);

    return () => {
      window.removeEventListener('focusout', handleWechatTouchIssue);
    };
  }, []);
  // const { data } = useHasLogin();

  // if(!data) return <Loading />

  // const { hasLogin, cardPopup, userAgreement } = data;
  // if(!userAgreement && pathname!=='/emailLogin') return <Redirect to='/emailLogin' />;
  // if(cardPopup && pathname!=='/pw_cert') return <Redirect to={`/pw_cert?nickName=${cardPopup.nickName}&no=${cardPopup.no}`} />;
  // if(hasLogin) return <Redirect to='/certs' />;

  const {
    pageLoading,
    errorMessage,
    children,
    resetErrorMessage,
    router,
    noticeHide,
    noticeMessage,
    toastMessage,
    clearToast,
    path,
  } = props;
  return (
    <>
      <Toast toastMessage={toastMessage} clearToast={clearToast} />
      <PageLoading pageLoading={pageLoading} />
      <ErrorDialog
        errorMessage={errorMessage}
        resetErrorMessage={resetErrorMessage}
        router={router}
        path={path}
      />
      {noticeMessage && (
        <NoticeBarForEcards
          noticeHide={noticeHide}
          noticeMessage={noticeMessage}
        />
      )}
      {children}
    </>
  );
}

function mapStateToProps(state) {
  return {
    errorMessage: state.common.errorMessage,
    pageLoading: state.common.pageLoading,
    router: state.common.skipRouter,
    path: state.common.skipPath,
    noticeMessage: state.common.noticeMessage,
    toastMessage: state.common.toastMessage,
  };
}

export default connect(mapStateToProps, {
  resetErrorMessage,
  noticeHide,
  clearToast,
})(AppContainer);
