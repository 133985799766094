import React, { useEffect } from 'react'
import { TextArea, Form, Button, Toast, DotLoading } from 'antd-mobile-v5'
import { AddCircleOutline } from 'antd-mobile-icons'
import UploadOssFile from '../../../components/FormTools/UploadOssFile'
import {
  useGetOssSignature,
  useGetAdsList,
  useSubmitAds
} from '../../../services/hooks'

const AdsEditorPage = () => {

  const [form] = Form.useForm();
  const required = true
  const { data: ossSignature } = useGetOssSignature('ADS_MANAGE');
  const { data } = useGetAdsList();
  const { run, loading } = useSubmitAds({
    onSuccess: () => Toast.show({
      icon: 'success',
      content: '保存成功',
    }),
  })
  const filename = (dir, type) => {
    return `${dir}${type}/`
  }

  const changeFiles = (files, type) => {
    if(type === 'open') {
      form.setFieldsValue({ imageUrl: files[0] })
      return
    }
    if(type.indexOf('banner') >= 0) {
      const bannerList = form.getFieldValue('banner')
      const newBanner = bannerList.map(banner => {
        if(banner.type === type) {
          return { ...banner, imageUrl: files[0] }
        }
        return banner
      })
      form.setFieldsValue({ banner: newBanner })
      return
    }
  }

  const formatFileList = (type) => {
    let typeList = data.filter(item => item.type === type)
    return typeList.length === 0 ? [] : [{ url: typeList[0].imageUrl }]
  }

  const submit = (values) => {
    const { banner, imageUrl, youzanUrl } = values
    const openData = {
      type: 'open',
      imageUrl: imageUrl,
      youzanUrl: youzanUrl
    }
    const data = [ openData, ...banner ]
    run(data)
  }

  useEffect(() => {
    if (data) {
      const open = data.find(item => item.type === 'open')
      const banner = data.filter(item => item.type.indexOf('banner') >= 0)
      form.setFieldsValue({ youzanUrl: open.youzanUrl, imageUrl: open.imageUrl, banner })
    }
  }, [data])

  if (!data || (data && data.length <= 0)) return <DotLoading color='primary' />

  return <>
    <Form
      mode='card'
      form={form}
      footer={
        <Button 
          block 
          type='submit' 
          color='primary' 
          loading={loading}
          size='large'>
          保存
        </Button>
      }
      onFinish={submit}
    >
      <Form.Header>开屏页</Form.Header>
      <Form.Item
        name='youzanUrl'
        label='商品链接'
        rules={[{ required: true, message: '商品链接不能为空' }]}
      >
        <TextArea />
      </Form.Item>
      {ossSignature && <Form.Item
        name='imageUrl'
        label={"上传图片"}
        form={form}
        rules={
          required && [
            {
              required: true,
              message: '请上传图片',
            },
          ]
        }
      >
        <UploadOssFile
          form={form}
          fileName={filename(ossSignature.dir, 'ads')}
          ossSignature={ossSignature}
          onSuccess={(files) => changeFiles(files, 'open')}
          editable={true}
          isPublic={true}
          maxLength={1}
          fileList={data ? formatFileList('open') : null}
        />
      </Form.Item>}
      <Form.Array
        name='banner'
        onAdd={operation => operation.add({ type: 'banner' + (form.getFieldValue('banner').length + 1) })}
        // renderAdd={() => (
        //   <span>
        //     <AddCircleOutline /> 添加
        //   </span>
        // )}
        renderHeader={({ index }, { remove }) => (
          <>
            <span>banner{index + 1}</span>
            {/* <a onClick={() => remove(index)} style={{ float: 'right' }}>
              删除
            </a> */}
          </>
        )}
      >
        {fields =>
          fields.map(({ index }) => (
            <>
              <Form.Item
                name={[index, 'youzanUrl']}
                label='商品链接'
                rules={[{ required: true, message: '商品链接不能为空' }]}
              >
                <TextArea />
              </Form.Item>
              {ossSignature && <Form.Item
                name={[index, 'imageUrl']}
                label={"上传图片"}
                form={form}
                rules={
                  required && [
                    {
                      required: true,
                      message: '请上传图片',
                    },
                  ]
                }
              >
                <UploadOssFile
                  form={form}
                  fileName={filename(ossSignature.dir, 'ads')}
                  ossSignature={ossSignature}
                  onSuccess={(files) => changeFiles(files, `banner${index + 1}`)}
                  editable={true}
                  isPublic={true}
                  maxLength={1}
                  fileList={data ? formatFileList(`banner${index+1}`) : null}
                />
              </Form.Item>}
            </>
          ))
        }
      </Form.Array>
    </Form>
  </>
}

export default AdsEditorPage