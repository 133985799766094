import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import { ProtectedRoute } from '../components/ProtectedRoute/ProtectedRoute';
const DSL = lazy(() => import('../pages/DSL/dsl'));
const DiveStorePage = lazy(() => import('../pages/DSL/DiveStore'));
const LocalGuide = lazy(() => import('../pages/DSL/LocalGuide'));
const DiveMap = lazy(() => import('../pages/DSL/DiveMap'));
const StoresPage = lazy(() => import('../pages/DSL/'));
const DSLEditorPage = lazy(() => import('../pages/DSLEditor/'));

const DslRoutes = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Route path="/dsl/home" component={DSL} />
    <Route path="/dsl/stores" component={StoresPage} />
    <Route path="/dsl/store" component={DiveStorePage} />
    <Route path="/dsl/local/:region" component={LocalGuide} />
    <Route path="/dsl/map" component={DiveMap} />
    <ProtectedRoute path="/dsl/editor" component={DSLEditorPage} />
  </Suspense>
)

export default DslRoutes