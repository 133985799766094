import React from 'react';
import { List, Accordion } from 'antd-mobile';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import styles from './Menu.module.css'

const HonorMenu = () => {

  const history = useHistory();

  return <Accordion defaultActiveKey="0">
      <Accordion.Panel header={<FormattedMessage id="award-title" />} className="profile-list member-renew">
        <List>
          <List.Item arrow="horizontal" onClick={() => history.push('/anniversary')}>
            <span className={styles.menuItem}>
              <FormattedMessage id="award-anniversary" />
            </span>
          </List.Item>
          <List.Item arrow="horizontal" onClick={() => history.push('/elite_instructor')}>
            <span className={styles.menuItem}>
              <FormattedMessage id="award-elite" />
            </span>
          </List.Item>
        </List>
      </Accordion.Panel>
    </Accordion>
}

export default HonorMenu