import { InputItem, Modal } from 'antd-mobile';
import { useIntl, FormattedMessage } from 'react-intl';
import React, { useState, useEffect } from 'react';
import { isNumber, showAlert } from './utils';
import SearchLoading from './SearchLoading';
import { getInstructorInfo } from '../../services/api'

import styles from './Search.module.css';

const prompt = Modal.prompt;

const SearchResultInstructor = ({ searchResult }) => {
  if (!searchResult) return null;
  return (
    <div className={styles.searchResult}>
      <div className={styles.searchIcon}>
        <i className="fa fa-address-card-o"></i>
      </div>
      <div className={styles.searchContent}>
        <span>{searchResult.instructorName}</span>
      </div>
    </div>
  );
};
const SearchInstructor = ({
  type,
  id,
  editable,
  instructor,
  initNum,
  onSuccess = () => { },
  onError = () => { },
}) => {
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);
  const [inputVal, setInputVal] = useState();
  const [searchResult, setSearchResult] = useState();

  useEffect(() => {
    if (initNum) {
      excuteSearch(initNum)
    }
  }, [initNum])

  const showInput = () => {
    if (!editable) return;
    prompt(
      formatMessage({ id: 'common-search-title' }),
      getPlaceholder(),
      [
        { text: formatMessage({ id: 'common-cancel' }) },
        {
          text: formatMessage({ id: 'common-comfirmBtn' }),
          onPress: (instructorNumber) =>
            searchInfo(instructorNumber)
          // setTimeout(() => searchInfo(instructorNumber), 0),
        },
      ]
    );
  };

  const searchInfo = (instructorNumber) => {
    if (!isNumber(instructorNumber)) {
      showAlert(
        getErrorDialog(),
        formatMessage({ id: 'common-close' })
      );
      return;
    }
    excuteSearch(instructorNumber);
  };

  const excuteSearch = async (instructorNumber) => {
    const oldInputVal = inputVal;
    setInputVal(instructorNumber);
    setLoading(true);
    try {
      const response = await getInstructorInfo(instructorNumber, type);
      if (response.error) {
        setInputVal(oldInputVal);
        showAlert(
          response.error.clientMsg,
          formatMessage({ id: 'common-close' })
        );
        onError(response);
      } else {
        setSearchResult(response.data);
        onSuccess(response.data);
      }
    } catch (e) {
      setInputVal(oldInputVal);
      showAlert(
        formatMessage({ id: 'common-errorStatus' }),
        formatMessage({ id: 'common-close' })
      );
      onError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (instructor && instructor.instructorNumber) {
      setInputVal(instructor.instructorNumber);
      setSearchResult(instructor);
    }
  }, [instructor]);

  const getPlaceholder = () => {
    switch (id) {
      case 'cd':
        return formatMessage({ id: 'owsi-cd-tips' })
      case 'trainer':
        return formatMessage({ id: 'efrt-number-warning' })
      case 'instructor':
        return formatMessage({ id: 'dm-form-instructor-warning' })
      default:
        return ''
    }
  }

  const getInputTitle = () => {
    switch (id) {
      case 'cd':
        return <FormattedMessage id="owsi-number" />
      case 'trainer':
        return <FormattedMessage id={'efrt-number'} />
      case 'instructor':
        return <FormattedMessage id={'dm-form-instructor-number'} />
      default:
        return ''
    }
  }

  const getErrorDialog = () => {
    switch (id) {
      case 'cd':
        return formatMessage({ id: 'owsi-cd-number-error' })
      case 'trainer':
        return formatMessage({ id: 'efrt-number-error' })
      case 'instructor':
        return formatMessage({ id: 'dm-form-instructor-error' })
      default:
        return ''
    }
  }

  return (
    <>
      <InputItem
        clear
        type="number"
        placeholder={getPlaceholder()}
        editable={editable}
        value={inputVal}
        onClick={() => showInput()}
      >
        {getInputTitle()}
      </InputItem>

      {loading ? (
        <SearchLoading />
      ) : (
          <SearchResultInstructor searchResult={searchResult} />
        )}
    </>
  );
};

export default SearchInstructor;
