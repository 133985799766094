import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
const Procheck = lazy(() => import('../pages/Procheck/'));
const ProcheckDetail = lazy(() => import('../pages/Procheck/detail'));

const ProcheckRoutes = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Route path="/pro_check" component={Procheck} />
    <Route path="/pro_check_detail" component={ProcheckDetail} />
  </Suspense>
)

export default ProcheckRoutes