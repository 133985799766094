import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { skipApplicationPage } from '../../reducers/ui'
import AppList from '../../components/common/AppList'

const ConfirmAppsPage = () => {
  const { listContent, resContent } = useSelector((state) => ({
    listContent: state.ui.instructorApplication.details,
    resContent: state.ui.instructorApplication
  }));

  const { push } = useHistory()

  return <AppList
    confirmRes={listContent}
    detailUrl="/dmForInstructor"
    paging={skipApplicationPage}
    listHeader="dm-app-list"
    isStore={resContent.storeInfoDTO}
    rightContent={
      <i
        className="fa fa-plus-square-o fa-lg"
        style={{ color: '#fff' }}
        onClick={() => push('/new_app', {
          instructor: resContent.userTypeDTO ? { instructorNumber: resContent.userTypeDTO.memberNumber, instructorName: resContent.userTypeDTO.userName } : null,
          store: resContent.storeInfoDTO
        })}>
      </i>
    } />
};

export default ConfirmAppsPage;
