import { combineReducers } from 'redux'
import { createSlice } from "@reduxjs/toolkit";
export const dsdListSlice = createSlice({
  name: "dsdList",
  initialState: null,
  reducers: {
    dsdListRequest: {
      reducer(state, action) {
        return state
      }
    },
    dsdListSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return { ...data }
      }
    },
    dsdListFailed: {
      reducer(state, action) {
        return state
      }
    }
  }
});
export const { dsdListRequest, dsdListSuccess, dsdListFailed } = dsdListSlice.actions
export const getDsdListRequest = {
  request: () => dsdListRequest(),
  success: (data) => dsdListSuccess(data),
  failure: (error) => dsdListFailed(error),
}
export const insturctorsListSlice = createSlice({
  name: "insturctorsList",
  initialState: null,
  reducers: {
    insturctorsListRequest: {
      reducer(state, action) {
        return state
      }
    },
    insturctorsListSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    insturctorsListFailed: {
      reducer(state, action) {
        return state
      }
    }
  }
});
export const { insturctorsListRequest, insturctorsListSuccess, insturctorsListFailed } = insturctorsListSlice.actions
export const getInsturctorsListRequest = {
  request: () => insturctorsListRequest(),
  success: (data) => insturctorsListSuccess(data),
  failure: (error) => insturctorsListFailed(error),
}
const dsdReducer = combineReducers({
  dsdList: dsdListSlice.reducer,
  insturctors: insturctorsListSlice.reducer
})
export default dsdReducer