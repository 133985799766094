import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { WhiteSpace, List, WingBlank, Button, Modal } from 'antd-mobile';
import Header from '../../components/common/FormHeader'
import CardLayout from '../DiveMasterForm/CardLayout'
import Signature from '../../components/common/Signature'
import CourseInfo from './CourseInfo'
import { uploadDetailAttach, submitCdDetailInProcess, clearDetailAttach } from '../../reducers/ui'
import { FormattedMessage, useIntl } from 'react-intl';
import { specialtyMapping, specialtyCd, removeExpires, getSubmitOssFormat } from '../../components/common/utils'
import { getCookie } from '../../components/common/cookie'
import UploadOssFile from '../../components/common/UploadOssFile'
import UploadFileWrapper from '../../components/common/UploadFileWrapper'

const Item = List.Item

const CdSiAppDetail = () => {

  const { ossSignature, signatureUrl, siDetailInProcess } = useSelector(state => ({
    ossSignature: state.oss.ossSignature,
    signatureUrl: state.ui.signatureUrl,
    siDetailInProcess: state.ui.siDetailInProcess
  }));

  const [completionDate, setCompletionDate] = useState('');
  const [location, setLocation] = useState('')

  const [needAttach, setNeedAttach] = useState(false)
  const dispatch = useDispatch()
  const intl = useIntl()
  const lang = getCookie('language')

  useEffect(() => {
    if (siDetailInProcess && siDetailInProcess.attachFile) setNeedAttach(true)
    if (siDetailInProcess) {
      setCompletionDate(new Date(siDetailInProcess.courseDate))
      setLocation(siDetailInProcess.courseLocation)
    }
  }, [siDetailInProcess])

  const submitSiApp = () => {
    if (needAttach && !siDetailInProcess.attachFile.length > 0 || !signatureUrl) {
      Modal.alert(
        intl.formatMessage({ id: 'dm-form-submit-error' }),
        intl.formatMessage({ id: 'dm-form-submit-error-info-no-agreement' }),
        [{ text: intl.formatMessage({ id: 'common-close' }) }]
      );
      return
    }
    let submitData = {}
    if (siDetailInProcess.attachFile) {
      const { status, credentialId, code, attachFile } = siDetailInProcess
      submitData = {
        status,
        attachFile: removeExpires(attachFile),
        credentialId,
        code,
        handwrittenSignatureUrlCD: getSubmitOssFormat(signatureUrl)
      }
    } else {
      const { status, credentialId, code } = siDetailInProcess
      submitData = {
        status,
        credentialId,
        code,
        handwrittenSignatureUrlCD: getSubmitOssFormat(signatureUrl)
      }
    }
    dispatch(submitCdDetailInProcess(submitData))
  }

  const getApplicableList = () => {
    return specialtyCd.filter(item => item.spi === siDetailInProcess.credentialId)
  }

  const findAttachById = () => {
    return siDetailInProcess.attachFile || null
  }

  const fileName = (type) => `${ossSignature.dir}${type}/${new Date().getTime()}`

  return siDetailInProcess ? <>
    <Header
      text={<FormattedMessage id="si-form-title" />}
      code={siDetailInProcess.code} />
    <WhiteSpace />
    <CourseInfo
      storeInfo={siDetailInProcess.storeInfoDTO}
      completionDate={completionDate}
      location={location}
      setCompletionDate={setCompletionDate}
      setLocation={setLocation}
      disabled={true}
      storeNumber={siDetailInProcess.storeInfoDTO ? siDetailInProcess.storeInfoDTO.storeNumber : ''} />
    <WhiteSpace />
    {siDetailInProcess.attachFile ? <CardLayout title={<FormattedMessage id="si-upload-attach" />} status={"none"}>
      {getApplicableList().map((item) => <UploadFileWrapper labelName={specialtyMapping[item.spi][lang]}>
        <UploadOssFile
          fileList={findAttachById() || []}
          editable={siDetailInProcess.status === 'ADMIN_NOT_APPROVED'}
          type={"SI"}
          fileName={fileName(item.spi)}
          onChange={(formData) => {
            dispatch(uploadDetailAttach({ formData }))
          }}
          onDelete={(index) => dispatch(clearDetailAttach({ credentialId: item.spi, url: findAttachById()[index] }))} />
        {siDetailInProcess.status === 'ADMIN_NOT_APPROVED' && <WingBlank>
          <div className="small-font gray">{item.desc[lang]}</div>
        </WingBlank>}
      </UploadFileWrapper>)}
    </CardLayout>
      :
      <CardLayout title={<FormattedMessage id="si-sp-app" />} status={"none"}>
        <List>
          <Item>
            {specialtyMapping[siDetailInProcess.credentialId][lang]}
          </Item>
        </List>
      </CardLayout>}
    <WhiteSpace />
    <Signature
      filePath={'signature'}
      initSignature={siDetailInProcess.handwrittenSignatureUrlCD}
      modified={siDetailInProcess.status === 'ADMIN_NOT_APPROVED'}
    />
    <WhiteSpace size="xl" />
    {siDetailInProcess.status === 'ADMIN_NOT_APPROVED' && <WingBlank>
      <Button type="primary" onClick={() => submitSiApp()}><FormattedMessage id="dm-form-submit" /></Button>
    </WingBlank>}
    {/* {siDetailInProcess.status === 'WAIT_PAY' && <WingBlank>
      <Button type="primary" onClick={() => window.location.href = siDetailInProcess.payUrl}><FormattedMessage id="dm-form-pay" /></Button>
    </WingBlank>} */}
    <WhiteSpace size="xl" />
  </> : null
}

export default CdSiAppDetail