import { Form, Input, Button, TextArea } from 'antd-mobile-v5';
import { useIntl, FormattedMessage } from 'react-intl';
import React, {useState} from 'react';

import { getStoreFullAddress } from '../common/utils';
import { useStoreSearch } from '../../services/hooks';

const AddStore = ({ form, onSearchResult, readOnly, initialStore = { storeName: ''} }) => {
  const { formatMessage } = useIntl();

  const [store, setStore] = useState(initialStore);
  const { loading, run, data } = useStoreSearch({
    onSuccess: (data) => {
      onSearchResult(data);
      form.setFields([
        {
          name: 'storeNumber',
          errors: [],
        },
      ]);
      setStore(data);
    },
    onError: (error) => {
      form.setFields([
        {
          name: 'storeNumber',
          errors: [error.clientMsg],
        },
      ]);
      onSearchResult('');
      setStore({});
      return true;
    }
  });
  const search = () => {
    const storeNumber = form.getFieldValue('storeNumber');
    if (!storeNumber) {
      form.setFields([
        {
          name: 'storeNumber',
          errors: [formatMessage({ id: 'form-storenum-empty-error' })],
        },
      ]);
      return;
    }
    if (!/^\d+$/.test(storeNumber)) {
      form.setFields([
        {
          name: 'storeNumber',
          errors: [formatMessage({ id: 'form-storenum-format-error' })],
        },
      ]);
      return;
    }
    form.setFields([
      {
        name: 'storeNumber',
        errors: [],
      },
    ]);
    run(storeNumber);
  };

  return (
    <>
      <Form.Item
        label={formatMessage({ id: 'dm-form-store-number' })}
        name="storeNumber"
        extra={!readOnly &&
          <Button
            size="small"
            color="primary"
            fill="none"
            loading={loading}
            onClick={search}
            loadingText={formatMessage({ id: 'common-search-loading' })}
          >
            <FormattedMessage id="common-search-title" />
          </Button>
        }
      >
        <Input placeholder={formatMessage({ id: 'si-store-num' })} readOnly={readOnly}/>
      </Form.Item>
      {store && store.storeName && (
        <>
          <Form.Item label={formatMessage({ id: 'certlist-storeName' })}>
            <Input readOnly value={store.storeName} />
          </Form.Item>
          <Form.Item label={formatMessage({ id: 'form-store-address' })}>
            <TextArea readOnly autoSize value={getStoreFullAddress(store)} />
          </Form.Item>
        </>
      )}
    </>
  );
};

export default AddStore;
