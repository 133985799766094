import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import React from 'react';

import DatePickerTool from '../../components/FormTools/DatePicker';
import SelectRegion from '../../components/FormTools/SelectRegion';

const TrainingForm = ({
  form,
  setData,
  data,
  errors,
  setErrors,
  setStore,
  store,
  disabled,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <SelectRegion
        form={form}
        countryIdFormKey="countryId"
        stateIdFormKey="stateId"
        required
        disabled={disabled}
      />
      <DatePickerTool
        disabled={disabled}
        name="courseDate"
        placeholder={formatMessage({ id: 'form-date-hint' })}
        label={formatMessage({ id: 'common-course-date' })}
        rules={[
          {
            required: true,
            message: formatMessage({ id: 'form-course-date-empty-error' }),
          },
        ]}
        min={dayjs().subtract(2, 'year').toDate()}
        max={dayjs().toDate()}
      />
    </>
  );
};

export default TrainingForm;
