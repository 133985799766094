export const setCookie = (key, value) => {
  document.cookie = key + '=' + value;
};

export const getCookie = (sKey) => {
  if (!sKey) { return null; }
  /* eslint-disable */
  return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
  /* eslint-enable */
};

export const getUnionId = () => {
  return getCookie('padiWxId');
};

export const delCookie = (key) => {
  const current = new Date();
  current.setTime(current.getTime() - 1);
  const value = getCookie(key);
  if(value !== null) document.cookie= `${key}=${value};expires=${current.toGMTString()}`
}

export const saveStorage = (key, value) => {
  localStorage.setItem(key, value);
}

export const getDataFromStorage = (key) => {
  return localStorage.getItem(key)
}

export const removeDataFromStorage = (key) => {
  return localStorage.removeItem(key)
}
