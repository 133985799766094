import React, { Component } from 'react';
import { Flex, List, InputItem, Button, Toast } from 'antd-mobile';
import { injectIntl, FormattedMessage } from 'react-intl';
import { InputOnBlur, showAlert } from '../common/utils';

const isPassword = (value) => {
  return  /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20})/.test(value);
}

const isSamePassWord = (password, confirmPassword) => {
  return password === confirmPassword;
}

class SetNewPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      confirmPassword: '',
      pending: false,
      password: '',
      errors: {},
      secret: true,
      iconClose: true,
    }
  }

  setStateAndShowAlert(isNotPassword, isNotSamePassword, message, btnLabel) {
    Toast.hide();
    this.setState({
      pending: false,
      errors: { password: isNotPassword, confirmPassword: isNotSamePassword } });
    showAlert(message, btnLabel);
  }

  handleSubmit = (event) =>{
    event.preventDefault();
    this.verifyPassword();
  }

  verifyPassword = () => {
    const {intl:{formatMessage}} = this.props;
    this.setState({ pending: true });
    const password = this.state.password.trim();
    const confirmPassword = this.state.confirmPassword.trim();
    const resetToken = window.location.href.split('resetPassword?resetToken=')[1];
    if(isPassword(password) && !isSamePassWord(password,confirmPassword)) {
      this.setStateAndShowAlert(!isPassword(password), !isSamePassWord(password,confirmPassword), formatMessage({id: 'setPassword-errorConfirmPassword'}), formatMessage({id: 'common-close'}));
      return;
    } else if (!isPassword(password) || !isSamePassWord(password,confirmPassword)) {
      this.setStateAndShowAlert(!isPassword(password), !isSamePassWord(password,confirmPassword), formatMessage({id: 'common-reset'}), formatMessage({id: 'common-close'}));
      return;
    }
    this.setState({ pending: false });
    this.props.resetPassword({password, resetToken});
  };

  render() {
    const {intl:{formatMessage}} = this.props;
    return (
      <div className="frame" style={{marginTop: '8vh'}}>
        <form>
          <Flex
            direction="column"
            justify="center"
            align="center" >
            <List className="login-list" renderHeader={() => formatMessage({id: 'common-reset'})}>
              <InputItem
                type={this.state.secret ? 'password' : 'text'}
                name="password"
                disabled={this.state.pending}
                error={this.state.errors.password}
                onChange={password => this.setState({ password, errors: { password: false } })}
                value={this.state.password}
                placeholder={formatMessage({id: 'common-passwordInput'})}
                onBlur={() => { InputOnBlur() }}
                maxLength="20">
                <i className="fa fa-lock fa-lg"></i>
              </InputItem>
              <InputItem
                type={this.state.secret ? 'password' : 'text'}
                name="confirmPassword"
                disabled={this.state.pending}
                error={this.state.errors.confirmPassword}
                onChange={confirmPassword => this.setState({ confirmPassword, errors: { confirmPassword: false } })}
                value={this.state.confirmPassword}
                onBlur={() => { InputOnBlur() }}
                maxLength="20"
                placeholder={formatMessage({id: 'common-confirmPassword'})}
                extra={
                  <i className={`fa ${this.state.iconClose ? 'fa-eye-slash' : 'fa-eye'} fa-lg`} onClick={() => this.setState({ secret: !this.state.secret, iconClose: !this.state.iconClose })}></i>}>
                <i className="fa fa-lock fa-lg"></i>
              </InputItem>
            </List>
          </Flex>
        </form>
        <Button
          type="primary"
          className="btnConfirm"
          onClick={this.handleSubmit}>
          <FormattedMessage
            id="common-confirmRequest"
          />  
        </Button>
        <div className="small-font gray">
          <FormattedMessage id="forgotPassword-footer" />
        </div>
      </div>
    )
  }
}

export default injectIntl(SetNewPassword);
