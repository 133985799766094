import dayjs from 'dayjs';

export const findEfrCert = (certs) => {
  return certs.find(item => {
    return !efrExpired(item) && (item.certificationNumber === 76 ||
      item.certificationNumber === 68 ||
      item.certificationNumber === 31 ||
      item.certificationNumber === 20)
  })
}

export const findEfriCert = (certs) => {
  return certs.find(item => {
    return item.credentialNumber === 35 || item.credentialNumber === 38;
  })
}

export const findEfrOrEfri = (certificates, credentials) => 
credentials.find(item => item.credentialNumber === 35 || item.credentialNumber === 38) 
|| certificates.find(item => (item.certificationNumber === 76 ||
      item.certificationNumber === 68 ||
      item.certificationNumber === 31 ||
      item.certificationNumber === 20) && !efrExpired(item));

export const findMasterFD = (certs) => {
  return certs.find(item => {
    return item.certificationNumber === 4163
  })
}

export const findAdvancedFD = (certs) => {
  return certs.find(item => {
    return item.certificationNumber === 4162
  })
}

export const findFD = (certs) => {
  return certs.find(item => {
    return item.certificationNumber === 4161
  })
}

export const findOwsi = (certs) => {
  return certs.find(item => {
    return item.credentialNumber === 30
  })
}

export const findFDI = (certs) => {
  return certs.find(item => {
    return item.credentialNumber === 11362
  })
}

export const findAFDI = (certs) => {
  return certs.find(item => {
    return item.credentialNumber === 11363
  })
}

export const findMermaidCert = (certs) => {
  return certs.find(item => item.certificationNumber === 4997)
}

const EFR_VALID_MONTH = 24;
const efrExpired = (efr) => dayjs(efr.issueDate).add(EFR_VALID_MONTH, 'month').isBefore(dayjs());
// 判断efr证书是否过期
export const isEfrExpired = (certs) => {
  const efr = certs.find((cert) => cert.printCardTypeId === 48)
  return efr ? dayjs(efr.issueDate).add(EFR_VALID_MONTH, 'month').isAfter(dayjs()) : false;
}