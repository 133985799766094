import React from 'react'
import DatePickerComponent from '../../components/common/DatePicker'
import CardLayout from '../../pages/DiveMasterForm/CardLayout';
import { FormattedMessage, useIntl } from 'react-intl';
import AddressForm from '../../components/common/AddressForm'
import PickerComponent from '../../components/common/Picker'
import SearchInstructor from '../../components/common/SearchInstructor';
import SearchStore from '../../components/common/SearchStore';

const level = [
  {
    'label': 'Freediver',
    'value': 'FD'
  }
]

const TrainingInfo = ({
  editable,
  updateFormData,
  complete,
  instructorInfo,
  storeInfo,
  formData: {
    courseDate,
    addressStateId,
    addressCountryId,
    courseLevel,
    instructorNumber,
    storeNumber
  }
}) => {

  const { formatMessage } = useIntl()

  return (
    <CardLayout
      title={formatMessage({ id: 'dm-form-training-info' })}
      status={complete}
    >
      <DatePickerComponent
        extra={formatMessage({ id: 'common-select' })}
        title={formatMessage({ id: 'efri-course-date-error' })}
        disabled={!editable}
        maxDate={new Date()}
        onChange={date => updateFormData({ courseDate: date })}
        value={courseDate}
      >
        <FormattedMessage id="common-course-date" />
      </DatePickerComponent>
      <AddressForm
        onRegionOk={(region => updateFormData({ addressStateId: parseInt(region) }))}
        onCountryOk={country => updateFormData({ addressCountryId: parseInt(country) })}
        stateId={addressStateId}
        countryId={addressCountryId}
        editable={editable}
        hideAddress={true} />
      <PickerComponent
        disabled={!editable}
        extra={formatMessage({ id: 'common-select' })}
        onOk={level => updateFormData({ courseLevel: level })}
        data={level}
        value={courseLevel}>
        <FormattedMessage id="fd-pic-course-level" />
      </PickerComponent>
      <SearchInstructor
        type={'FDI'}
        id={"instructor"}
        editable={editable}
        instructor={instructorInfo}
        instructorNumber={instructorNumber}
        onSuccess={(result) => {
          updateFormData({ instructorNumber: result.instructorNumber });
        }}
      />
      <SearchStore
        placeholder={formatMessage({ id: 'dm-form-store-warning' })}
        editable={editable}
        store={storeInfo}
        storeNumber={storeNumber}
        onSuccess={(result) => {
          updateFormData({ storeNumber: result.storeNumber });
        }}
      />
    </CardLayout>
  )
}

export default TrainingInfo