import React, { useEffect, useRef, useState } from 'react';
import { Button, Flex, WhiteSpace, WingBlank } from 'antd-mobile';
import * as fx from 'glfx';
import Tag from '../../components/common/Tag';
import Pica from "pica";
import ImageBlobReduce from 'image-blob-reduce';

const pica = Pica({ features: ["js", "wasm", "cib"] });
const reducer = new ImageBlobReduce({ pica });
const reduceImg = async file => {
  const blob = await reducer.toBlob(
    file,
    {
      max: 4096,
    }
  )
      return URL.createObjectURL(blob);
}

const filters = [
  {
    label: '蓝水效果',
    filter: {
      brightness: '0',
      contrast: '0.05',
      hue: '0.08',
      saturation: '0.03',
      vibrance: '0.05',
      sepia: '0.1',
    },
  },
  {
    label: '高清质感',
    filter: {
      brightness: '0',
      contrast: '0.35',
      hue: '0',
      saturation: '0.05',
      vibrance: '0.1',
      sepia: '0.05',
    },
  },
  {
    label: '场景提亮',
    filter: {
      brightness: '0.05',
      contrast: '0.15',
      hue: '0',
      saturation: '0',
      vibrance: '0',
      sepia: '0.1',
    },
  },
  {
    label: '色彩增强',
    filter: {
      brightness: '0.02',
      contrast: '0.02',
      hue: '0',
      saturation: '0.3',
      vibrance: '0.1',
      sepia: '0',
    },
  },
  {
    label: '动感光效',
    filter: {
      brightness: '0.02',
      contrast: '0.35',
      hue: '0.03',
      saturation: '0.01',
      vibrance: '0.01',
      sepia: '-0.05',
    },
  },
];

export default () => {
  const currentFileRef = useRef();
  const originalImgRef = useRef();
  const originalHiddenRef = useRef();
  const correctedPosition = useRef();
  const iosCanvasRef = useRef();
  const fxCanvasRef = useRef();
  const fxTextureRef = useRef();
  const [fileExists, setFileExists] = useState(false);
  const [options, setOptions] = useState(filters[0]);
  const [exporting, setExporting] = useState(false);
  const [filter, setFilter] = useState(filters[0]);
  const [resultImage, setResultImage] = useState();
  const [exportPage, setExportPage] = useState(false);
  const fileUpload = async (e) => {
    const [uploaded] = currentFileRef.current.files;
    if (uploaded) {
      const image = await reduceImg(uploaded);
      setFileExists(true);
      setTimeout(() => {
        originalHiddenRef.current.src = image;
        originalImgRef.current.src = image;
      }, 10);
    }
  };

  const originalLoaded = () => {
    fxTextureRef.current = fxCanvasRef.current.texture(
      originalHiddenRef.current
    );
    // fxCanvasRef.current
    //   .draw(fxTextureRef.current)
    //   .brightnessContrast(options.brightness, options.contrast)
    //   .update();
    updateOptions(filter.filter);
    fxCanvasRef.current.style.zoom = `${
      (originalImgRef.current.width / fxCanvasRef.current.width) * 100
    }%`;

    correctedPosition.current.parentNode.insertBefore(
      fxCanvasRef.current,
      correctedPosition.current
    );
  };

  const updateOptions = (opt) => {
    const newFilter = { ...options, ...opt };
    setOptions(newFilter);
    const canvas = fxCanvasRef.current;
    const texture = fxTextureRef.current;
    if (!texture) return;
    canvas
      .draw(texture)
      .brightnessContrast(newFilter.brightness, newFilter.contrast)
      .hueSaturation(newFilter.hue, newFilter.saturation)
      .vibrance(newFilter.vibrance)
      .sepia(newFilter.sepia)
      .update();
  };

  const download = () => {
    setExporting(true);
    setTimeout(() => {
      const canvas = fxCanvasRef.current;
      const texture = fxTextureRef.current;
      canvas
        .draw(texture)
        .brightnessContrast(options.brightness, options.contrast)
        .hueSaturation(options.hue, options.saturation)
        .vibrance(options.vibrance)
        .sepia(options.sepia)
        .update();
      if (!iosCanvasRef.current) {
        console.log('init canvas for ios');
        const canvas2d = document.createElement('canvas');
        canvas2d.width = canvas.width;
        canvas2d.height = canvas.height;
        iosCanvasRef.current = canvas2d;
      }

      const destCtx = iosCanvasRef.current.getContext('2d');
      if (!destCtx) {
        console.error('无法下载图片。');
        return;
      }
      destCtx.drawImage(canvas, 0, 0);
      setExportPage(true);
      setResultImage(iosCanvasRef.current.toDataURL('image/jpeg'));

      setExporting(false);
    });
  };

  useEffect(() => {
    try {
      fxCanvasRef.current = fx.canvas();
      console.log('图片修改初始化成功。');
    } catch (e) {
      console.log(e);
      alert('抱歉，您的设备不支持。');
      return;
    }
  }, []);

  return (
    <><div style={{display: exportPage ? 'none' : 'block'}}>
        {fileExists ? (
          <Flex
          direction="column"
          justify="center"
          style={{ maxWidth: '100%' }}
        >
            <img
              style={{
                position: 'absolute',
                maxWidth: '4096px',
                left: '-5000px',
                zIndex: -1,
              }}
              src="#"
              alt="hidden"
              ref={originalHiddenRef}
              onLoad={originalLoaded}
            />
            <img
              style={{ maxWidth: '100%' }}
              ref={originalImgRef}
              src="#"
              alt="原图"
            />
            <p>原图</p>
            <div style={{ maxWidth: '100%' }} ref={correctedPosition}></div>
            <p>还原效果</p>
            <Flex wrap="wrap">
              {filters.map((f) => (
                <Tag
                  key={f.label}
                  selected={f.label === filter.label}
                  onClick={() => {
                    if (f.label === filter.label) return;
                    setFilter(f);
                    updateOptions(f.filter);
                  }}
                >
                  {f.label}
                </Tag>
              ))}
            </Flex>
            <Flex wrap="wrap">
        <div style={{ marginRight: '10px' }}>
          <label htmlFor="bright">bright</label>
          <input
            type="range"
            name="bright"
            min="-1"
            max="1"
            step="0.01"
            onChange={(e) => updateOptions({ brightness: e.target.value })}
            value={options.brightness}
          />
        </div>

        <div style={{ marginRight: '10px' }}>
          <label htmlFor="contrast">contrast</label>
          <input
            type="range"
            name="contrast"
            min="-1"
            max="1"
            step="0.01"
            onChange={(e) => updateOptions({ contrast: e.target.value })}
            value={options.contrast}
          />
        </div>

        <div style={{ marginRight: '10px' }}>
          <label htmlFor="hue">hue</label>
          <input
            type="range"
            name="hue"
            min="-1"
            max="1"
            step="0.01"
            onChange={(e) => updateOptions({ hue: e.target.value })}
            value={options.hue}
          />
        </div>
        <div style={{ marginRight: '10px' }}>
          <label htmlFor="saturation">saturation</label>
          <input
            type="range"
            name="saturation"
            min="-1"
            max="1"
            step="0.01"
            onChange={(e) => updateOptions({ saturation: e.target.value })}
            value={options.saturation}
          />
        </div>

        <div style={{ marginRight: '10px' }}>
          <label htmlFor="vibrance">vibrance</label>
          <input
            type="range"
            name="vibrance"
            min="-1"
            max="1"
            step="0.01"
            onChange={(e) => updateOptions({ vibrance: e.target.value })}
            value={options.vibrance}
          />
        </div>

        <div style={{ marginRight: '10px' }}>
          <label htmlFor="sepia">sepia</label>
          <input
            type="range"
            name="sepia"
            min="-1"
            max="1"
            step="0.01"
            onChange={(e) => updateOptions({ sepia: e.target.value })}
            value={options.sepia}
          />
        </div>
      </Flex>
      <WingBlank><p>{JSON.stringify(options)}</p></WingBlank>
          </Flex>
        ) : (
          <Flex justify="center" style={{ height: '200px' }}>
            <p>请上传需要处理的潜水图片。</p>
          </Flex>
        )}
      <input
          id="upload"
          type="file"
          ref={currentFileRef}
          onChange={fileUpload}
          style={{ visibility: 'hidden', width: 0, height: 0 }}
        />
      <WingBlank>
        <label htmlFor="upload" class="am-button am-button-primary">
          上传图片
        </label>
      </WingBlank>
      <WhiteSpace />
      <WingBlank>
        <Button
          type="primary"
          disabled={!fileExists || exporting}
          loading={exporting}
          onClick={download}
        >
          保存图片
        </Button>
      </WingBlank></div>
      <Flex
        direction="column"
        align="center"
        style={{ display: exportPage ? 'flex' : 'none' }}
      >
        <p>长按保存{filter.label}的图片</p>
        <img style={{ maxWidth: '100%' }} src={resultImage} alt="" />
        <WhiteSpace />

        <p onClick={() => setExportPage(false)}>返回</p>
      </Flex>
    </>
  );
};
