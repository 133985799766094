import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Redirect, Route, Router } from 'react-router-dom';
import React from 'react';

import 'react-animated-router/animate.css';

import { history } from './services';
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute';
import { saveWechatJsConfig } from './actions';
import AddressFormPage from './pages/AddressForm/AddressForm';
import AddressPage from './pages/Address/Address';
import AFDIApplication from './pages/AFDI/AFDIApplication';
import AFdiTDetail from './pages/AFDIT/AppDetail';
import AFDITList from './pages/AFDIT/AppList';
import AgentElearning from './pages/AgentElearning/AgentElearning';
import AppContainer from './AppContainer';
import AuthorizePage from './pages/Authorization/Authorize';
import BasicMermaidApplication from './pages/BasicMermaid/BasicMermaidApplication';
import BasicMermaidTDetail from './pages/BasicMermaidT/AppDetail';
import BasicMermaidTList from './pages/BasicMermaidT/AppList';
import BooksCheckout from './pages/PriceList/BooksCheckout';
import BooksCheckoutResult from './pages/PriceList/BooksCheckoutResult';
import BuyCards from './pages/BuyCards/buy_cards';
import CampaignPage from './pages/Campaign/Campaign';
import CartPage from './pages/Cart/Cart';
import CdApplication from './pages/CdApplication/CdApplication';
import CdAppListPage from './pages/CdApplication/CdAppList';
import CdSiAppDetail from './pages/CdApplication/CdAppDetail';
import CertDetailPage from './pages/CertDetail/CertDetailPage';
import Checkout from './pages/AgentElearning/Checkout';
import ColorCorrect from './pages/ColorCorrect/ColorCorrect';
import ConfirmAppsPage from './pages/DmForInstructor/ConfirmAppsPage';
import CreditCard from './pages/CreditCard/CreditCard';
import CreditResult from './pages/CreditCard/CreditResult';
import DMForInstructorPage from './pages/DmForInstructor/DmForInstructor';
import DMFormPage from './pages/DiveMasterForm/DMFormPage';
import DMFormResultPage from './pages/DiveMasterForm/DMFormResultPage';
import DsdRegister from './pages/Dsd/Register';
import EcardsPage from './pages/EcardsPage/EcardsPage';
import EFRIApplication from './pages/EFRI/EFRIApplication';
import EFRIAppList from './pages/EFRIT/EFRIAppList';
import EFRITDetail from './pages/EFRIT/EFRITDetail';
import FDIApplication from './pages/FDI/FDIApplication';
import FdiTDetail from './pages/FDIT/AppDetail';
import FDITList from './pages/FDIT/AppList';
import FDPICList from './pages/FDPIC/FDPICList';
import IeExamAttempts from './pages/IeExamAttempts/IeExamAttempts';
import IeResit from './pages/IeResit/IeResit';
import MermaidAppPage from './pages/MermaidApp/MermaidAppPage';
import MermaidTDetail from './pages/MermaidT/AppDetail';
import MermaidTList from './pages/MermaidT/AppList';
import MFDIApplication from './pages/MFDI/MFDIApplication';
import MFdiTDetail from './pages/MFDIT/AppDetail';
import MFDITList from './pages/MFDIT/AppList';
import MyProfile from './pages/MyProfile/MyProfile';
import NewApp from './pages/FDPIC/NewApp';
import NewAppPage from './pages/DiveMasterForm/NewAppPage';
import NewYearCampaignPage from './pages/NewYearCampaign/Campaign';
import NewYearCampaignPostPage from './pages/NewYearCampaign/CampaignHomePage';
import OwsiApplication from './pages/OwsiApplication/OwsiApplication';
import OwsiCdAppDetailPage from './pages/OwsiCdApps/OwsiCdAppDetail';
import OwsiCdAppsPage from './pages/OwsiCdApps/OwsiCdApps';
import PadiPromotionPage from './pages/PadiPromotion/PadiPromotionPage';
import PayPalCallback from './components/common/PayPalCallback';
import PicCollectionPage from './pages/PicCollection/PicCollectionPage';
import Poster2021 from './pages/PadiAware/Poster2021';
import Poster2022 from './pages/PadiAware/Poster2022';
import PriceListPage from './pages/PriceList/PriceListPage';
import PublicWelfareCert from './pages/CardImgUpload/PublicWelfareCert';
import RenewPage from './pages/Renew/Renew';
import ResourcesListPage from './pages/ResourcesList/ResourcesListPage';
import ResultPage from './components/common/ResultPage';
import SetProfilePage from './pages/SetProfile/SetProfile';
import SpecialtyAppDetail from './pages/SpecialtyInstructor/SpecialtyAppDetail';
import SpecialtyAppListPage from './pages/SpecialtyInstructor/SpecialtyAppList';
import SpecialtyInstructor from './pages/SpecialtyInstructor/SpecialtyInstructor';
import Torcher from './pages/Torcher/cert';
import TraineeList from './pages/Dsd/TraineeList';
import RegulatorCouponPage from './pages/RegulatorCoupon/RegulatorCoupon'
import DecodeQrcodePage from './pages/RegulatorCoupon/DecodeQrcode'
import PocketsApplication from './pages/PocketsApplication/PocketsApplication'
import PocketsAppsList from './pages/PocketsApplication/PocketsAppsList'
import PocketsApplicationResult from './pages/PocketsApplication/PocketsApplicationResult'
import StoreApplicationPage from './pages/StoreApplication/StoreApplication'
import StoreAppsList from './pages/StoreApplication/StoreAppsList'
import InvoiceFormPage from './pages/Invoice/InvoiceFormPage'
import AnniversaryAwards from './pages/Awards/Anniversary'
import EliteInsturtorAwards from './pages/Awards/EliteInstructor'

import './App.css';
import './assets/css/common.css';

import CourseInfoRoutes from './routes/CourseInfoRoutes';
import DslRoutes from './routes/DslRoutes';
import UserAdminRoutes from './routes/UserAdminRoutes';
import PadiAdminRoutes from './routes/PadiAdminRoutes';
import ScubaOW from './routes/ScubaOWRoutes';
import ProcheckRoutes from './routes/ProcheckRoutes';
import IssueCertsNewPage from './pages/IssueCerts/new';
import IssueCertsListPage from './pages/IssueCerts/list';
import IssueCertsDetailPage from './pages/IssueCerts/detail';
import PICHomePage from './pages/IssueCerts/home';
import PicHistoryPage from './pages/IssueCerts/pic_history';
import BuyPicPage from './pages/IssueCerts/buy_pic';
import { IntlWrapper } from './components/IntlWrapper';

import EmailLoginPage from './pages/emailLogin/emailLogin';
import RegisterPage from './pages/Register/register';
import ForgetPassword from './pages/ForgetPassword/ForgetPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import ResetResult from './pages/ResetResult/ResetResult';
import Success from './pages/RegistrationResult/Success';
import Failure from './pages/RegistrationResult/Failure';
import WechatAuth from './WechatAuth';
import EFRPublicInstructorAppPage from './pages/MemberApplications/efrpi';
import MergeFeedbackPage from './pages/EcardsPage/MergeFeedbackPage'
import ResetPwd from './pages/emailLogin/resetPwd'
import FindResult from './pages/FindAccount/FindResult'
import PersonalInfo from './pages/FindAccount/PersonalInfo'
import SubmitResult from './pages/FindAccount/SubmitResult'
import AdsEditorPage from './pages/Admin/AdsEditor'
import MemberZone from './pages/MyProfile/MemberZone';
import ApplicationSubmitResult from './pages/ApplicationSubmitResult/ApplicationSubmitResult'
import PaymentResultPage from './pages/ApplicationSubmitResult/PaymentResultPage'
import ClubEcards from './pages/ClubEcards/ClubEcards'
import RedirectedeUri from './pages/TravelRedirected/RedirectedeUri'
import PublicWelfareEcardPage from './pages/PublicWelfareEcard/PublicWelfareEcardPage'
import SelectCertsPage from './pages/PublicWelfareEcard/SelectCertsPage'
import PicOrderList from './pages/CoursePicOrder/OrderListPage'
import PicOrderDetail from './pages/CoursePicOrder/OrderDetailPage'
import UnconventionalOrderList from "./pages/UnconventionalOrder/OrderListPage";
import UnconventionalOrderDetail from "./pages/UnconventionalOrder/OrderDetailPage";

function App(props) {
  // const defaultLocale = () => {
  //   const navLanguage = navigator.language;
  //   if (!getCookie('language')) {
  //     if (navLanguage === 'zh-TW' || navLanguage === 'zh-HK') {
  //       setCookie('language', 'tc');
  //     } else if (navLanguage.indexOf('en') === 0) {
  //       setCookie('language', 'en');
  //     } else {
  //       setCookie('language', 'sc');
  //     }
  //   }
  //   props.setLanguage();
  // };

  const { pathname } = props.history.location;
  return (<WechatAuth>
    <IntlWrapper>
      <ConnectedRouter history={history}>
        <Router history={history}>
          <Route exact path="/authorize">
            <AuthorizePage />
          </Route>
          <Route
            path="/lx"
            component={() => {
              window.location.replace(
                'https://assets.padi.com.cn/lx/index.html'
              );
              return null;
            }}
          />
          <Route path="/pw_cert">
            <Torcher />
          </Route>
          <Route path="/color_correct" component={ColorCorrect} />
          <div className="limited-width">
            {/* <Splash /> */}
            <Route path="/emailLogin" component={EmailLoginPage} />
            <Route path="/register" component={RegisterPage} />
            <Route path="/forgotPassword" component={ForgetPassword} />
            <Route path="/resetPassword" component={ResetPassword} />
            <Route path="/resetResult" component={ResetResult} />
            <Route path="/registrationSuccess" component={Success} />
            <Route path="/registrationFailure" component={Failure} />
            <Route path="/reset_pwd" component={ResetPwd} />
            <Route path="/find_account/result" component={FindResult} />
            <Route path="/find_account/user_info" component={PersonalInfo} />
            <Route path="/find_account/submit_result" component={SubmitResult} />

            <Route path="/dsd_register" component={DsdRegister} />
            <Route path="/submit_success" component={ResultPage} />
            <Route exact path="/">
              <Redirect to="/certs" />
            </Route>
            <AppContainer>
              <Route
                path="/resources_collection"
                component={PicCollectionPage}
              />
              <CourseInfoRoutes />
              <UserAdminRoutes />
              <DslRoutes />
              <ScubaOW />
              <ProcheckRoutes />
              <ProtectedRoute path="/members" component={MemberZone} />
              <ProtectedRoute path="/buy_cards" component={BuyCards} />
              <ProtectedRoute path="/myProfile" component={MyProfile} />
              <ProtectedRoute
                path="/cert/:type/:certId"
                component={CertDetailPage}
              />
              {/* <AuthRequired> */}
              <ProtectedRoute path="/campaign" component={CampaignPage} />
              <ProtectedRoute path="/certs" component={EcardsPage} />
              <ProtectedRoute path="/ecards" component={EcardsPage} />
              {/* </AuthRequired> */}

              {/* <ProtectedRoute path="/campaign" component={CampaignPage} /> */}
              {/* without tabbar */}
              <ProtectedRoute
                path="/newYearCampaign"
                component={NewYearCampaignPage}
              />
              <ProtectedRoute
                path="/newYearCampaignPost"
                component={NewYearCampaignPostPage}
              />

              <ProtectedRoute path="/address" component={AddressPage} />
              <ProtectedRoute
                path="/addressForm"
                component={AddressFormPage}
              />
              <ProtectedRoute path="/cart" component={CartPage} />
              <ProtectedRoute path="/renew/:type" component={RenewPage} />
              <ProtectedRoute path="/dmForm" component={DMFormPage} />
              <ProtectedRoute
                path="/dmFormResult"
                component={DMFormResultPage}
              />
              <ProtectedRoute
                path="/bookSaleList"
                component={PriceListPage}
              />
              <ProtectedRoute
                path="/books_checkout"
                component={BooksCheckout}
              />
              <ProtectedRoute
                path="/padiPromotion"
                component={PadiPromotionPage}
              />
              <ProtectedRoute
                path="/memberLibrary"
                component={ResourcesListPage}
              />
              <ProtectedRoute
                path="/dmForInstructor"
                component={DMForInstructorPage}
              />
              <ProtectedRoute
                path="/confirmApps"
                component={ConfirmAppsPage}
              />
              <ProtectedRoute path="/new_app" component={NewAppPage} />
              <ProtectedRoute
                path="/specialtyApp"
                component={SpecialtyInstructor}
              />
              <ProtectedRoute
                path="/siAppList"
                component={SpecialtyAppListPage}
              />
              <ProtectedRoute
                path="/siAppDetail"
                component={SpecialtyAppDetail}
              />
              <ProtectedRoute path="/cdAppForm" component={CdApplication} />
              <ProtectedRoute path="/cdAppList" component={CdAppListPage} />
              <ProtectedRoute path="/cdAppDetail" component={CdSiAppDetail} />
              <ProtectedRoute path="/owsi_app" component={OwsiApplication} />
              <ProtectedRoute
                path="/paypal_book_cb"
                component={PayPalCallback}
              />
              <ProtectedRoute
                path="/owsi_cd_apps"
                component={OwsiCdAppsPage}
              />
              <ProtectedRoute path="/ie_resit" component={IeResit} />
              <ProtectedRoute
                path="/set_profile"
                component={SetProfilePage}
              />
              <ProtectedRoute
                path="/owsi_cd_app_detail"
                component={OwsiCdAppDetailPage}
              />
              <ProtectedRoute
                path="/ie_exam_attempts"
                component={IeExamAttempts}
              />
              <ProtectedRoute path="/credit_card" component={CreditCard} />
              <ProtectedRoute path="/pay_success" component={CreditResult} />
              <ProtectedRoute
                path="/books_submit_success"
                component={BooksCheckoutResult}
              />
              <ProtectedRoute
                path="/agent_elearning"
                component={AgentElearning}
              />
              <ProtectedRoute path="/efri_app" component={EFRIApplication} />
              <ProtectedRoute path="/efrt_list" component={EFRIAppList} />
              <ProtectedRoute path="/efrt_detail" component={EFRITDetail} />
              <ProtectedRoute
                path="/dsd_trainee_list"
                component={TraineeList}
              />
              <ProtectedRoute
                path="/basic_mermaid_app"
                component={BasicMermaidApplication}
              />
              <ProtectedRoute
                path="/basic_mermaid_app_list"
                component={BasicMermaidTList}
              />
              <ProtectedRoute
                path="/basic_mermaid_app_detail"
                component={BasicMermaidTDetail}
              />
              <ProtectedRoute
                path="/mermaidi_app"
                component={MermaidAppPage}
              />
              <ProtectedRoute
                path="/mermaid_app_list"
                component={MermaidTList}
              />
              <ProtectedRoute
                path="/mermaid_app_detail"
                component={MermaidTDetail}
              />
              <ProtectedRoute path="/fdi_app" component={FDIApplication} />
              <ProtectedRoute path="/fdi_app_list" component={FDITList} />
              <ProtectedRoute path="/fdi_app_detail" component={FdiTDetail} />
              <ProtectedRoute path="/afdi_app" component={AFDIApplication} />
              <ProtectedRoute path="/afdi_app_list" component={AFDITList} />
              <ProtectedRoute
                path="/afdi_app_detail"
                component={AFdiTDetail}
              />
              <ProtectedRoute path="/mfdi_app" component={MFDIApplication} />
              <ProtectedRoute path="/mfdi_app_list" component={MFDITList} />
              <ProtectedRoute
                path="/mfdi_app_detail"
                component={MFdiTDetail}
              />
              <ProtectedRoute
                path="/public_welfare_cert"
                component={PublicWelfareCert}
              />
              <ProtectedRoute path="/aware/2021" component={Poster2021} />
              <ProtectedRoute path="/aware/2022" component={Poster2022} />
              <ProtectedRoute path="/fd_pic_list" component={FDPICList} />
              <ProtectedRoute path="/new_fd_pic" component={NewApp} />
              <ProtectedRoute
                path="/agent_elearning_checkout"
                component={Checkout}
              />
              <ProtectedRoute path="/efr_public/app" component={EFRPublicInstructorAppPage} />
              <ProtectedRoute path={'/issue_certs/new'} component={IssueCertsNewPage} />
              <ProtectedRoute path={'/issue_certs/list'} component={IssueCertsListPage} />
              <ProtectedRoute path={'/issue_certs/detail'} component={IssueCertsDetailPage} />
              <ProtectedRoute path={'/issue_certs/home'} component={PICHomePage} />
              <ProtectedRoute path={'/issue_certs/pic_history'} component={PicHistoryPage} />
              <ProtectedRoute path={'/issue_certs/buy_pic'} component={BuyPicPage} />
              <ProtectedRoute path={'/regulator_coupon'} component={RegulatorCouponPage} />
              <Route path='/decode_qrcode' component={DecodeQrcodePage} />
              <ProtectedRoute path="/merge_certs_success" component={MergeFeedbackPage} />
              <ProtectedRoute path="/pockets_apps" component={PocketsApplication} />
              <ProtectedRoute path="/pockets_apps_list" component={PocketsAppsList} />
              <ProtectedRoute path="/pockets_apps_result" component={PocketsApplicationResult} />
              <ProtectedRoute path="/ads_editor" component={AdsEditorPage} />
              <ProtectedRoute path="/admin/ads_editor" component={AdsEditorPage} />
              <ProtectedRoute path="/store_apps" component={StoreApplicationPage} />
              <ProtectedRoute path="/store_apps_list" component={StoreAppsList} />
              <ProtectedRoute path="/invoice_form" component={InvoiceFormPage} />
              <ProtectedRoute path="/anniversary" component={AnniversaryAwards} />
              <ProtectedRoute path="/elite_instructor" component={EliteInsturtorAwards} />
              <ProtectedRoute path="/application_submit_success" component={ApplicationSubmitResult} />
              <ProtectedRoute path="/paymentSuccess" component={PaymentResultPage} />
              <ProtectedRoute path="/club_ecards" component={ClubEcards} />
              <ProtectedRoute path="/free_pub_welfare_ecard" component={PublicWelfareEcardPage} />
              <ProtectedRoute path="/free_pub_welfare_certs" component={SelectCertsPage} />
              <Route path="/redirected_uri" component={RedirectedeUri} />
              <ProtectedRoute path="/pic_order_list" component={PicOrderList} />
              <ProtectedRoute path="/pic_order_detail" component={PicOrderDetail} />
              <ProtectedRoute path="/unconventional_order_list" component={UnconventionalOrderList} />
              <ProtectedRoute path="/unconventional_order_detail" component={UnconventionalOrderDetail} />
            </AppContainer>
          </div>
          <PadiAdminRoutes />
        </Router>
      </ConnectedRouter>
    </IntlWrapper></WechatAuth>
  );
}

export default connect(null, { saveWechatJsConfig })(App);
