import React from 'react'
import { Result } from 'antd-mobile-v5'

const MergeFeedbackPage = () => {
  return <Result
    status='success'
    title='操作成功'
    description='您的请求提交成功。我们将尽快完成相应的处理，感谢您的耐心等候。'
  />
}

export default MergeFeedbackPage