import React from 'react'
import { Button, Divider, DotLoading } from 'antd-mobile-v5'
import { Table } from 'antd';
import { isWechat } from '../../utils/wechat';
import { useDownloadEliteAward, useErrorModal, useEliteInstructor } from '../../services/hooks';
import { useIntl, FormattedMessage } from 'react-intl';

const { Column } = Table;

const EliteInsturtorAwards = () => {

  const bodyHeight = document.body.clientHeight - 200
  const showErrorModal = useErrorModal();
  const { data, loading } = useEliteInstructor({});
  const { formatMessage } = useIntl();
  const { loading: downLoading, run: download } = useDownloadEliteAward();

  if (loading) return <DotLoading color='primary' />
  if (data && data.length === 0) {
    return <Divider><FormattedMessage id="award-no-elite" /></Divider>
  } else {
    return <>
      <Table dataSource={data} pagination={false} scroll={{ x: 'max-content', y: bodyHeight }}>
        <Column title={formatMessage({ id: 'award-year' })} dataIndex="year" key="year" />
        <Column title={formatMessage({ id: 'award-cert' })} dataIndex="name" key="name" />
        <Column title={formatMessage({ id: 'award-download-cert' })} dataIndex="id" key="id"
          render={
            (id) => <>
              {id && (<Button
                loading={downLoading}
                color="primary"
                fill='none'
                size='mini'
                onClick={() => {
                  if (isWechat()) {
                    showErrorModal(formatMessage({ id: 'wechat-cert' }));
                  } else {
                    download({ awardId : id});
                  }
                }}
              >
                <FormattedMessage id="award-download" />
              </Button>)}
            </>
          } />
      </Table>
    </>
  }
}

export default EliteInsturtorAwards