import { InputItem, Modal } from 'antd-mobile';
import { useIntl, FormattedMessage } from 'react-intl';
import React, { useState, useEffect } from 'react';

import { getStoreInfo, getStoreInfoWithLevel } from '../../services/api';
import { isNumber, showAlert, getStoreFullAddress } from './utils';
import SearchLoading from './SearchLoading';

import styles from './Search.module.css';

const prompt = Modal.prompt;

const SearchResultStore = ({ searchResult }) => {
  if (!searchResult) return null;
  return (
    <div className={styles.searchResult}>
      <div className={styles.searchIcon}>
        <i className="fa fa-map-marker"></i>
      </div>
      <div className={styles.searchContent}>
        <p><strong>{searchResult.storeName}</strong></p>
        <p>{getStoreFullAddress(searchResult)}</p>
      </div>
    </div>
  );
};
const SearchStore = ({
  editable,
  store,
  withLevel,
  placeholder,
  initNum,
  onSuccess = () => { },
  onError = () => { },
}) => {
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);
  const [inputVal, setInputVal] = useState();
  const [searchResult, setSearchResult] = useState();

  useEffect(() => {
    if (initNum) {
      excuteSearch(initNum)
    }
  }, [initNum])

  const showInput = () => {
    if (!editable) return;
    prompt(
      formatMessage({ id: 'common-search-title' }),
      placeholder,
      [
        { text: formatMessage({ id: 'common-cancel' }) },
        {
          text: formatMessage({ id: 'common-comfirmBtn' }),
          onPress: (storeNumber) =>
            setTimeout(() => searchInfo(storeNumber), 0),
        },
      ]
    );
  };

  const searchInfo = (storeNumber) => {
    if (!isNumber(storeNumber)) {
      showAlert(
        formatMessage({ id: 'profile-store-number-error' }),
        formatMessage({ id: 'common-close' })
      );
      return;
    }
    excuteSearch(storeNumber);
  };

  const excuteSearch = async (storeNumber) => {
    const oldInputVal = inputVal;
    setInputVal(storeNumber);
    setLoading(true);
    try {
      const response = withLevel ? await getStoreInfoWithLevel(storeNumber) : await getStoreInfo(storeNumber);
      if (response.error) {
        setInputVal(oldInputVal);
        showAlert(
          response.error.clientMsg,
          formatMessage({ id: 'common-close' })
        );
        onError(response);
      } else {
        setInputVal(storeNumber);
        setSearchResult(response.data);
        onSuccess(response.data);
      }
    } catch (e) {
      setInputVal(oldInputVal);
      showAlert(
        formatMessage({ id: 'common-errorStatus' }),
        formatMessage({ id: 'common-close' })
      );
      onError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (store && store.storeNumber) {
      setInputVal(store.storeNumber);
      setSearchResult(store);
    }
  }, [store]);

  return (
    <>
      <InputItem
        clear
        type="number"
        placeholder={placeholder}
        editable={editable}
        value={inputVal}
        onClick={() => showInput()}
      >
        <FormattedMessage id={'dm-form-store-number'} />
      </InputItem>

      {loading ? (
        <SearchLoading />
      ) : (
          <SearchResultStore searchResult={searchResult} />
        )}
    </>
  );
};

export default SearchStore;
