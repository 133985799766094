import { combineReducers } from 'redux'
import { createSlice } from "@reduxjs/toolkit";

export const storeListSlice = createSlice({
  name: "storeList",
  initialState: null,
  reducers: {
    saveStoreList: {
      reducer(state, action) {
        const data = action.payload;
        return { ...data }
      }
    }
  }
});


export const { saveStoreList } = storeListSlice.actions

const DSLReducer = combineReducers({
  storeList: storeListSlice.reducer
})

export default DSLReducer