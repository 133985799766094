import { WingBlank } from 'antd-mobile';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'antd-mobile-v5'
import { useMergeCerts } from '../../services/hooks'
import { showAlert } from "../../components/common/utils";

const EcardsListFooter = () => {
  const { formatMessage } = useIntl()
  const { loading, run } = useMergeCerts({
    onSuccess: (data) => {
      showAlert(
        '已成功提交申请。',
        formatMessage({ id: 'common-close' })
      );
    },
  });
  return (
    <WingBlank>
      <div className="change-email">
        <Button 
          color='primary' 
          fill='none'
          size="mini"
          loading={loading}
          loadingText={formatMessage({ id: 'certlist-merge-loading' })}
          onClick={run}
        >
          <FormattedMessage
            id="certlist-merge-certs"
          />
        </Button>
      </div>
      <div style={{ width: '100%', height: '50px' }}></div>
    </WingBlank>
  )
};

export default EcardsListFooter;
