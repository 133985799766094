import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useQualification } from '../../services/hooks';
import Loading from '../Loading';
import styles from './CertDetailList.module.css';

const Qualification = ({ cert }) => {
  const q = cert.certificationName === 'Confined Water Diver'
    ? <ConfinedWaterQualification dateStr={dayjs(cert.issueDate, 'YYYY-MM-DD').format('D/M/YYYY')} />
    : <RemoteQualification cert={cert} />
  return (
    <div className={styles.qualification}>
      <h3 className={styles.title}>
        <FormattedMessage id="certdetail-qualification" />
      </h3>
      <div className={styles.info}>
        {q}
      </div>
    </div>
  );
};

const ConfinedWaterQualification = ({dateStr}) => <div>As of this date {dateStr} (D/M/Y) has satisfactorily completed all required knowledge development, confined water dives, applicable exams and watermanship assessment and may participate in open water dives for the PADI Scuba Diver or Open Water Diver course.</div>
const RemoteQualification = ({ cert }) => {
  const { loading, data } = useQualification(cert);

  return loading ? <Loading /> : <div>{data}</div>
};

export { Qualification };
