import React from 'react'
import { InputItem } from 'antd-mobile';
import { FormattedMessage, injectIntl } from 'react-intl';
import CardLayout from '../../pages/DiveMasterForm/CardLayout'
import { formatDate } from '../../components/common/utils';
import UserPhoto from '../../components/common/UserPhoto'

import styles from './DiveMasterForm.module.css'
//update: delete
const DiverInfo = ({ diver }) => {

  const { englishName, birthDate } = diver
  const status = Boolean(englishName && birthDate)

  return <CardLayout title={<FormattedMessage id="dm-form-diver-info" />} status={status}>
    <div className={styles["diver-info"]}>
      <div style={{ flex: 1 }}>
        <InputItem
          type="text"
          editable={false}
          value={englishName}
        >
          <FormattedMessage id="dm-form-name" />
        </InputItem>
        <InputItem
          type="text"
          editable={false}
          value={formatDate(birthDate, 'YYYY-MM-DD')}
        >
          <FormattedMessage id="dm-form-birth" />
        </InputItem>
      </div>
      <UserPhoto />
    </div>
  </CardLayout>
}

export default injectIntl(DiverInfo)