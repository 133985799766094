import { Button } from 'antd-mobile-v5';
import { useHistory } from 'react-router-dom';
import React from 'react';

import { gtm } from '../common/utils';
import BindingYouzan from '../MyProfile/BindingYouzan';

const RequestOptionsBtn = () => {
  const { push } = useHistory();
  return (
    <BindingYouzan onHandleFn={() => push(`/buy_cards`)}>
      <Button
        block
        color="primary"
        onClick={() => {
          gtm('Click - Card Request', 'Cert Details');
        }}
      >
        获取 PADI 公益卡
      </Button>
    </BindingYouzan>
  );
};

export default RequestOptionsBtn;
