import React, { createRef, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { List, SearchBar, NoticeBar } from 'antd-mobile';
import { useIntl } from 'react-intl';
import PriceItem from './PriceItem';
import CheckoutCounter from './CheckoutCounter';
import Tag from '../../components/common/Tag';
import { FormattedMessage } from 'react-intl'

import styles from './PriceList.module.css';
import Notification from '../../components/Notification';

const filterBooks = (all, tagsActive, filterBy) => {
  const results = tagsActive.length
    ? all.filter((b) => tagsActive.find((t) => t === b.itemTagName))
    : all;
  return results
    .filter((t) =>
      t.textbooksSaleListGoodsDTOS.find(
        (i) => ~i.title.toLowerCase().indexOf(filterBy.toLowerCase())
      )
    )
    .map((t) => {
      return {
        ...t,
        textbooksSaleListGoodsDTOS: t.textbooksSaleListGoodsDTOS.filter(
          (i) => ~i.title.toLowerCase().indexOf(filterBy.toLowerCase())
        ),
      };
    });
};

const PriceListPage = () => {
  const { formatMessage } = useIntl();

  const { profile, saleList } = useSelector((state) => ({
    profile: state.data.profile,
    saleList: state.ui.bookSaleList,
  }));
  const { data, submitItems } = saleList;
  const { textbooksSaleListTagsDTOS, couponDTO } = data

  const [tagsActive, setTagsActive] = useState([]);
  const [filterBy, setFilterBy] = useState('');
  const [keyWord, setKeyWord] = useState('');
  const listContainer = useRef();
  const tagsContainer = useRef();

  const sectionRefs = {};

  if (!profile || !textbooksSaleListTagsDTOS || textbooksSaleListTagsDTOS.length === 0) return null;

  const bookToDisplay = filterBooks(textbooksSaleListTagsDTOS, tagsActive, filterBy);

  const getCoupon = (coupons) => {
    return coupons.filter(coupon => !coupon.expired)
  }

  return (
    <div className={styles.saleContent}>
      <div className={styles.tagList} ref={tagsContainer}>
        <SearchBar
          placeholder={formatMessage({ id: 'book-search' })}
          onSubmit={setFilterBy}
          onChange={setKeyWord}
          onCancel={() => {
            setFilterBy('');
            setKeyWord('');
            setTagsActive([]);
          }}
          value={keyWord}
          cancelText={formatMessage({ id: 'common-cancel' })}
        />
        {textbooksSaleListTagsDTOS.map((item, key) => (
          <Tag
            key={item.itemTagName}
            onClick={() => {
              tagsActive.find((t) => t === item.itemTagName)
                ? setTagsActive(
                  tagsActive.filter((t) => t !== item.itemTagName)
                )
                : setTagsActive([...tagsActive, item.itemTagName]);
            }}
            selected={tagsActive.find((t) => t === item.itemTagName)}
          >
            {item.itemTagName}
          </Tag>
        ))}
      </div>
      <div className={styles.saleList} ref={listContainer}>
        {bookToDisplay.map(
          ({ itemTagName, textbooksSaleListGoodsDTOS }, key) => {
            const currentRef = createRef();
            sectionRefs[itemTagName] = currentRef;
            return (
              <List
                renderHeader={() => <span ref={currentRef}>{itemTagName}</span>}
                key={key}
                className="price-items"
              >
                {textbooksSaleListGoodsDTOS.map((item, key) => (
                  <PriceItem item={item} key={key} submitItems={submitItems} />
                ))}
              </List>
            );
          }
        )}
      </div>
      {couponDTO && couponDTO.length > 0 && <NoticeBar marqueeProps={{ loop: true, style: { padding: '0 7.5px' } }} className={styles.notice}>
        <FormattedMessage
          id="coupon-tips"
          values={{
            count: getCoupon(couponDTO).length
          }} />
      </NoticeBar>}
      <CheckoutCounter submitItems={submitItems} priceListData={textbooksSaleListTagsDTOS} />
      <Notification position="PIC_SALE" />
    </div>
  );
};

export default PriceListPage;
