import { Button, Form, Input } from 'antd-mobile-v5';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { MailOutline, LockOutline } from 'antd-mobile-icons';
import React, { useState, useEffect } from 'react';

import { emailLogin } from '../../actions';
import { getParameterByName, isWechat } from '../../components/common/utils';
import { setCookie } from '../../components/common/cookie';
import { useEmailLogin, useLanguage, useSaveProfile } from '../../services/hooks/user';
import SelectLanguage from '../../components/Login/SelectLanguage';
import MobileVerify from '../Register/MobileVerify';
import { useGetM2PhoneNumber } from '../../services/hooks'
import { useShowMessage } from '../../services/hooks/ui';

import './emailLogin.css';
import styles from '../Register/Register.module.css';

function EmailLoginPage(props) {

  const [form] = Form.useForm();
  const [mobileForm] = Form.useForm();
  const { formatMessage } = useIntl();
  const lang = useLanguage();
  const { push } = useHistory();
  const [account, setAccount] = useState(null)
  const [showMobileVerify, setMobileVerifyStatus] = useState(false)
  const [countryCode, setCountryCode] = useState('86')
  const [mobileVerified, setMobileVerified] = useState(false);
  const showMessage = useShowMessage();
  const referer = getParameterByName('referer') || ''
  const { run: getM2PhoneNumber, data } = useGetM2PhoneNumber({
    onSuccess: (data) => {
      if (data) {
        mobileForm.setFieldsValue({ email: account.username });
        setMobileVerifyStatus(true)
        if (data.phoneNumber) {
          setCountryCode(data.countryCode || '86')
          mobileForm.setFieldsValue({ mobile: data.phoneNumber, countryCode: data.countryCode || '86' });
        }
      }
    }
  });
  const { loading, run: login } = useEmailLogin({
    onSuccess: (data) => {
      if (!isWechat()) setCookie('padiWxId', data.token);
      const nextPage = getParameterByName('referer') || '/certs';
      if(nextPage.indexOf('http') === 0) {
        if (nextPage.indexOf('t100-padi-rest.zhibo.tv') > 0 || nextPage.indexOf('padi-rest.zhibo.tv') > 0) {
          let refererUrl
          if(nextPage.indexOf('?') > 0) {
            refererUrl = nextPage + '&padiToken=' + data.token
          }else {
            refererUrl = nextPage + '?padiToken=' + data.token
          }
          window.location.href = refererUrl;
          return
        }
        window.location.href = nextPage;
      } else {
        push(nextPage);
      }
    },
    onError: (error, params) => {
      if (error.code === 'mobile.not.verified') {
        setAccount(params[0])
        getM2PhoneNumber(params[0].username)
      }
    },
  });

  const { loading: profileSaving, run: saveProfile } = useSaveProfile({
    onSuccess: () => {
      login(account)
    },
    onError: () => {
      showMessage('"common-errorStatus')
    }
  });

  const onMobileVerified = ({ verified }) => {
    setMobileVerified(verified);
  };

  const loginAfterVerifyMobile = (values) => {
    const { countryCode, mobile } = values
    if (mobileVerified || countryCode !== '86') {
      let saveData = { 
        email: account.username, 
        countryCode, 
        mobile
      }
      saveProfile(saveData)
    }
    else showMessage('register-mobile-not-verified')
  }

  return (
    <div className="home-page">
      <SelectLanguage />
      <div className="logo">
        <img src={require('../../assets/images/PADI-logo.png')} alt="logo" />
      </div>
      {!showMobileVerify ? <>
        <div className="sign-in">
          <FormattedMessage id="emailLogin-login" />
        </div>
        <Form
          layout="horizontal"
          form={form}
          onFinish={values => login(values)}
          validateTrigger="submit"
          footer={
            <div>
              <div
                className="forget-password"
                style={{ textAlign: 'end', paddingBottom: '6px' }}
              >
                <Link to={referer ? `/register?referer=${referer}` : "/register"}>
                  <FormattedMessage id="loginComponent-signUp" />
                </Link>
                <span style={{color: '#1890ff'}}>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                <span onClick={() => push('/find_account/user_info')} style={{ color: '#1890ff' }}>
                  <FormattedMessage id="findme-header" />
                </span>
                <span style={{ color: '#1890ff' }}>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                <span onClick={() => push('/reset_pwd')} style={{color: '#1890ff'}}>
                  <FormattedMessage id="loginComponent-forgotPassword" />
                </span>
              </div>
              <Button block type="submit" color="primary" loading={loading}>
                {formatMessage({ id: 'emailLogin-login' })}
              </Button>
            </div>
          }
        >
          <Form.Item
            label={<MailOutline />}
            name="username"
            className={styles.formItem}
            rules={[
              {
                required: true,
                message: formatMessage({ id: 'form-email-empty-error' }),
              },
              {
                type: 'email',
                message: formatMessage({ id: 'form-email-format-error' }),
              },
            ]}
          >
            <Input placeholder={formatMessage({ id: 'emailLogin-emailInput' })} />
          </Form.Item>
          <Form.Item
            label={<LockOutline />}
            name="password"
            className={styles.formItem}
            rules={[
              {
                required: true,
                message: formatMessage({ id: 'common-reset' }),
              },
            ]}
          >
            <Input
              type="password"
              placeholder={formatMessage({ id: 'common-passwordInput' })}
            />
          </Form.Item>
          {/* <Form.Item
            name="agreement"
            rules={[
              {
                required: true,
                message: formatMessage({ id: 'customize-cert-agree-first' }),
              },
            ]}
          >
            <Checkbox className={styles.agreement} checked={agreement1} onChange={val => setAgreement1(val)}>
              <FormattedMessage
                id="common-agreement-hint"
                values={{
                  link: (
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={`https://assets.padi.com.cn/agreement_${lang}.html`}
                    >
                      <FormattedMessage id="common-agreement" />
                    </a>
                  ),
                }}
              />
            </Checkbox>
            <Checkbox className={styles.agreement} checked={agreement2} onChange={val => setAgreement2(val)}>
              <FormattedMessage
                id="common-info-agreement-hint"
                values={{
                  link: (
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={`https://assets.padi.com.cn/personalInfo_agreement_${lang}.html`}
                    >
                      <FormattedMessage id="common-info-agreement" />
                    </a>
                  ),
                }}
              />
            </Checkbox>
          </Form.Item> */}
        </Form>
        <div className="small-font gray" style={{ paddingLeft: '12px' }}>
          <FormattedMessage id="loginComponent-footer" />
        </div> 
      </> : 
      <Form
        layout="horizontal"
        form={mobileForm}
        onFinish={loginAfterVerifyMobile}
        onFinishFailed={(values => console.log(values))}
        validateTrigger="submit"
        footer={
          <Button block type="submit" color="primary" loading={loading || profileSaving}>
            {formatMessage({ id: 'common-comfirmBtn' })}
          </Button>
        }>
        <Form.Header>
          <FormattedMessage id="setMobile-title" />
        </Form.Header>
        <MobileVerify
          form={mobileForm}
          emailVerified={true}
          onVerifyResult={onMobileVerified}
          setCountryCode={setCountryCode}
          countryCode={countryCode} 
        />
      </Form>}

      {/* <LoginComponent emailLogin={emailLogin} history={props.history} /> */}
    </div>
  );
}

export default connect(null, { emailLogin })(EmailLoginPage);
