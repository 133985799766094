import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { NavBar, WingBlank, WhiteSpace, Toast, Button } from 'antd-mobile'
import { Input, Empty } from 'antd'
import dayjs from 'dayjs'
import { getParameterByName } from '../../../components/common/utils'
import { postCollectionTemplate, collectionEditerDetail } from '../../../services/api'

const PicCollectionEditer = () => {

  const history = useHistory()
  const [tasks, setTasks] = useState([])
  const [taskName, setTaskName] = useState('')
  const [note, setNote] = useState('')
  const [loading, setLoading] = useState(false)
  const [url, setUrl] = useState('')
  const alias = getParameterByName('alias')

  useEffect(() => {
    if(alias) requestDetail()
  }, [])

  const requestDetail = async () => {
    try {
      const rs = await collectionEditerDetail(alias)
      if(rs.data) {
        setTaskName(rs.data.title)
        const describeMapping = rs.data.items.map((item, index) => {
          return {
            label: `描述${index+1}`,
            describe: item.title
          }
        })
        setTasks(describeMapping)
      }
    } finally {
      console.log(111)
    }
  }

  const createTask = () => {
    if(tasks.length === 3) {
      Toast.info('创建任务已达上限', 2.5)
      return
    }
    const newTask = {
      label: `描述${tasks.length+1}`,
      describe: ''
    }
    setTasks([...tasks, newTask])
  }

  const changeDescribe = (value, index) => {
    let newTasks;
    newTasks = tasks.map(item => {
      if(item.label === tasks[index].label) {
        return {
          label: item.label,
          describe: value
        }
      }
      return item
    })
    setTasks(newTasks)
  }

  const createTemplate = async () => {
    if(!taskName) {
      Toast.info('请填写任务名', 2.5)
      return
    }else {
      const findEmptyDescribe = tasks.find(item => !item.describe)
      if(findEmptyDescribe) {
        Toast.info('请填写问题描述', 2.5)
        return
      }
    }
    const getTasks = tasks.map(item => {
      return { title: item.describe }
    })
    const postData = {
      items: getTasks,
      note: note,
      title: taskName
    }
    if(alias) postData.alias = alias
    try {
      setLoading(true)
      const id = await postCollectionTemplate(postData)
      setUrl(`${window.location.origin}/resources_collection?alias=${id.data}`)
    }finally {
      setLoading(false)
    }
  }

  return <>
    <NavBar
      mode="light"
      rightContent={<i className="fa fa-plus-square-o fa-lg" onClick={createTask}></i>}
    >
      创建素材收集
    </NavBar>
    <WingBlank>
      <WhiteSpace />
      <span>任务名</span>
      <WhiteSpace />
      <Input 
        value={taskName}
        onChange={(e) => setTaskName(e.target.value)} />
    </WingBlank>
    <WhiteSpace />
    <WingBlank>
      <span>更新时间</span>
      <WhiteSpace />
      <Input 
        value={dayjs(new Date()).format('YYYY-MM-DD')}
        disabled={true} />
    </WingBlank>
    <WhiteSpace />
    <WingBlank>
      <span>备注（选填）</span>
      <WhiteSpace />
      <Input 
        value={note}
        onChange={(e) => setNote(e.target.value)} />
    </WingBlank>
    {tasks.length > 0 ? tasks.map((item, index) => <div key={index}>
      <WingBlank>
        <WhiteSpace />
        <span>{item.label}</span>
        <WhiteSpace />
        <Input 
          value={tasks[index].describe}
          onChange={(e) => changeDescribe(e.target.value, index)} />
      </WingBlank>
      <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
        <WingBlank>
          {url ? (
            <>
              <a href={url}>{url}</a>
              <WhiteSpace size="sm" />
              <Button 
                type="primary"
                onClick={() => history.push('/admin/collection/task_list')}>返回任务列表</Button>
            </>
          ) : (
            <Button 
              type="primary" 
              loading={loading}
              onClick={createTemplate}>创建链接</Button>
          )}
        </WingBlank>
        <WhiteSpace size='xl' />
      </div>
    </div>
    ) : (
      <>
        <WhiteSpace size="xl" />
        <Empty description="暂无描述" />
      </>
    )}
  </>
}

export default PicCollectionEditer