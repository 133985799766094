import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { WhiteSpace, WingBlank, Button, Modal } from 'antd-mobile';
import Header from '../../components/common/FormHeader';
import SearchUserByEmail from '../../components/common/SearchUserByEmail'
import CardLayout from '../../pages/DiveMasterForm/CardLayout';
import TrainingInfo from './TrainingInfo'
import { showAlert, formatDate } from '../../components/common/utils';
import { useIntl, FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { usePayFdpic, useSubmitFdPic } from '../../services/hooks'

const NewApp = () => {

  const { profile, userInfo } = useSelector((state) => ({
    profile: state.data.profile,
    userInfo: state.data.userInfo,
  }));

  const [formData, setFormData] = useState({
    courseDate: '',
    addressCountryId: 0,
    addressStateId: -1,
    courseLevel: '',
    instructorNumber: '',
    storeNumber: '',
    students: []
  });
  const { formatMessage } = useIntl()
  const { push } = useHistory()
  const { runAsync: toPayPic, loading } = usePayFdpic();
  const { state: { instructor, store } } = useLocation();
  const { runAsync: submitFdPic, loading: submitting } = useSubmitFdPic();

  useEffect(() => {
    if (instructor) {
      updateFormData({ instructorNumber: instructor.instructorNumber })
    } else if (store) {
      updateFormData({ storeNumber: store.storeNumber })
    }
  }, [instructor, store])

  const updateFormData = (update) => setFormData({ ...formData, ...update })

  const addressComplete = () => {
    if (formData.addressCountryId === 51) return formData.addressCountryId && formData.addressStateId > 0
    else if (formData.addressCountryId && formData.addressCountryId !== 51) return formData.addressCountryId
  }

  const trainingComplete = formData.instructorNumber &&
    formData.courseLevel &&
    formData.courseDate &&
    addressComplete()

  const submit = async () => {
    if (!trainingComplete || formData.students.length <= 0) {
      Modal.alert(
        formatMessage({ id: 'dm-form-submit-error' }),
        formatMessage({ id: 'dm-form-submit-error-info-no-agreement' }),
        [{ text: formatMessage({ id: 'common-close' }) }]
      );
      return
    }
    const submitData = {
      courseDate: formatDate(formData.courseDate, 'YYYY-MM-DD'),
      instructorNumber: formData.instructorNumber,
      emails: formData.students.map(i => i.email),
      countryId: formData.addressCountryId,
      stateId: formData.addressStateId,
      memberApplicationType: formData.courseLevel
    };
    if (formData.storeNumber) submitData.storeNumber = formData.storeNumber
    submitFdPic(submitData).then(res => {
      if (res.code) {
        push('/application_submit_success?type=FD', { code: res.code, paid: false, paymentType: 'FD' });
      }
    })
  }

  if (!profile || !userInfo) return null

  return <>
    <Header text={formatMessage({ id: 'fd-pic-new' })} />
    <CardLayout
      title={formatMessage({ id: 'fd-pic-student-info' })}
      status={formData.students && formData.students.length > 0}
    >
      <SearchUserByEmail
        editable={true}
        multiple={true}
        type={'FD'}
        onSuccess={(result) => updateFormData({ students: result })}
      />
    </CardLayout>
    <WhiteSpace />
    <TrainingInfo
      updateFormData={updateFormData}
      editable={true}
      formData={formData}
      complete={trainingComplete}
      instructorInfo={instructor}
      storeInfo={store}
    />
    <WhiteSpace size="xl" />
    <WingBlank>
      <Button
        type="primary"
        loading={submitting || loading}
        disabled={submitting || loading}
        onClick={() => submit()}
      >
        <FormattedMessage id="dm-form-submit" />
      </Button>
    </WingBlank>
  </>
}

export default NewApp