import React from 'react';
import Cert from './Cert'

import './LightenCert.css';

export default function LightenCert({ nextCerts }) {
  if(!nextCerts) return null;
  return Object.keys(nextCerts).map(type => <div className="lighten-cert" key={type}>
           <Cert key={type} cert={nextCerts[type]} certType={type} />
         </div>)
}
