import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { WingBlank } from 'antd-mobile';
import CardLayout from '../DiveMasterForm/CardLayout'
import { FormattedMessage } from 'react-intl';
import UploadOssFile from '../../components/common/UploadOssFile'
import { uploadMFDIOssFile, removeMFDIFile } from '../../reducers/mfdi'

import styles from '../OwsiApplication/OwsiApplication.module.css'

const CheckTeachingCerts = ({ 
  teachingCertsStatus, 
  editable, 
  teachingCountFiles, 
  teachingCerts 
}) => {

  const { ossSignature } = useSelector((state) => ({
    ossSignature: state.oss.ossSignature
  }));
  const dispatch = useDispatch()

  const filename = (type) => `${ossSignature.dir}${type}/${new Date().getTime()}`

  return <CardLayout 
    title={<FormattedMessage id="afdi-certs-Requirement" />} 
    status={teachingCertsStatus}>
    {teachingCerts ? <div className={styles.age}>
      <FormattedMessage id="mfdi-satisfy-certs" />
    </div> : <div>
      <UploadOssFile
        fileList={teachingCountFiles || []}
        fileName={filename('teachingCerts')}
        onChange={(formData) => {
          dispatch(uploadMFDIOssFile({ formData }))
        }}
        onDelete={(index) => dispatch(removeMFDIFile(teachingCountFiles[index]))}
        editable={editable} />
      {editable && <WingBlank>
        <div className="small-font gray">
          <FormattedMessage id="mfdi-upload-cert-requirement" />
        </div>
      </WingBlank>}
    </div>}
  </CardLayout>
}

export default CheckTeachingCerts