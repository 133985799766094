import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Checkbox, InputItem, WingBlank } from 'antd-mobile';
import CardLayout from '../../pages/DiveMasterForm/CardLayout';
import { FormattedMessage, useIntl } from 'react-intl';
import { clearStoreInfo } from '../../reducers/ui'
import SearchStore from '../../components/common/SearchStore';

import styles from './OwsiApplication.module.css'

const CheckboxItem = Checkbox.CheckboxItem;

const StoreInfo = ({ 
  editable,
  storeType, 
  storeInfo, 
  updateFormData, 
  storeNumber,
  alternateLocation 
}) => {

  const intl = useIntl()
  const dispatch = useDispatch()

  const [activeKey, setActiveKey] = useState()

  useEffect(() => {
    if(storeType) setActiveKey(storeType)
  }, [storeType])

  const checkedStore = (e) => {
    if(e.target.checked) {
      setActiveKey(1); 
      updateFormData({ alternateLocation: '', storeType: 1 })
    }else {
      setActiveKey()
    }
  }

  const checkedLocation = (e) => {
    if(e.target.checked) {
      setActiveKey(2); 
      updateFormData({ storeNumber: '', storeType: 2 })
    }else {
      setActiveKey()
    }
  }

  return <CardLayout
    title={<FormattedMessage id="profile-store-title" />}
    status={alternateLocation || storeNumber}
  >
    <WingBlank>
      <span className={styles.tips}><FormattedMessage id="owsi-store-info-tips" /></span>
    </WingBlank>
    <CheckboxItem 
      disabled={!editable}
      checked={activeKey === 1} 
      onChange={e => checkedStore(e)}>
      <FormattedMessage id="owsi-store-select" />
    </CheckboxItem>
    {activeKey === 1 && <SearchStore
      editable={editable}
      store={storeInfo}
      withLevel={true}
      storeNumber={storeInfo ? storeInfo.storeNumber : ''}
      onSuccess={(result) => {
        updateFormData({ storeNumber: result.storeNumber });
      }}
    />}
    <CheckboxItem 
      checked={activeKey === 2} 
      disabled={!editable}
      onChange={e => checkedLocation(e)}>
      <FormattedMessage id="owsi-alternative-location" />
    </CheckboxItem>
    {activeKey === 2 && <InputItem
      clear
      // editable={editable}
      maxLength={20}
      placeholder={intl.formatMessage({ id: 'owsi-location-tips' })}
      value={alternateLocation}
      onChange={val => { dispatch(clearStoreInfo()); updateFormData({ alternateLocation: val }) }}
    >
      <FormattedMessage id="owsi-alternative-location" />
    </InputItem>}
  </CardLayout>
}

export default StoreInfo