import React, { useContext } from 'react';
import { Context } from '../IntlWrapper';

import './Login.css';

const languageOptions = [
  {
    label: '简',
    value: 'sc',
  },
  {
    label: '繁',
    value: 'tc',
  },
  {
    label: 'En',
    value: 'en',
  },
];

const languageStyle = {
  fontWeight: 'bold',
  border: '1px solid',
  borderRadius: '5px',
};

export default function SelectLanguage() {
  const intlContext = useContext(Context);

  return (
    <div className="selectLanguage">
      {languageOptions.map((lang) => (
        <span
          key={lang.value}
          className="language-btn"
          style={intlContext.language === lang.value ? languageStyle : null}
          onClick={() => intlContext.toggleLanguage(lang.value)}
        >
          {lang.label}
        </span>
      ))}
    </div>
  );
}
