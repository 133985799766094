import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { List } from 'antd-mobile';
import UploadOssFile from '../../components/common/UploadOssFile'
import UploadFileWrapper from '../../components/common/UploadFileWrapper'
import { uploadMFDIOssFile, removeMFDIFile } from '../../reducers/mfdi'

const Item = List.Item

const OwsiAndPrepare = ({ editable, afdifiles }) => {

  const { ossSignature } = useSelector((state) => ({
    ossSignature: state.oss.ossSignature
  }));
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const filename = (type) => `${ossSignature.dir}${type}/${new Date().getTime()}`

  return <>
    <Item>
      <FormattedMessage id="fdi-owsi-cert" />  <FormattedMessage id="afdi-satisfy" />
    </Item>
    <UploadFileWrapper 
      labelName={formatMessage({ id: 'mfd-afdi' })} 
      editable={editable}>
      <UploadOssFile
        fileList={afdifiles || []}
        fileName={filename('afdi')}
        onChange={(formData) => {
          dispatch(uploadMFDIOssFile({ formData }))
        }}
        onDelete={(index) => dispatch(removeMFDIFile(afdifiles[index]))}
        editable={editable} />
    </UploadFileWrapper>
  </>
}

export default OwsiAndPrepare