import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid } from 'antd-mobile-v5';
import { Modal, WhiteSpace } from 'antd-mobile';
import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';

import { getCookie } from '../../components/common/cookie';
import { getEcards } from '../../reducers/selectors';
import { getEncodedId } from '../../components/common/utils';
import { localizeResource } from '../../services/utilities';
import { useBindEcard } from '../../services/hooks';
import CardOptions from '../NewYearCampaign/CardOptions';
import Cert from '../NewYearCampaign/Cert';

import styles from '../NewYearCampaign/Campaign.module.css';

import { submitEcardImgBinding, resetApp } from '../../actions';

function SelectCertsPage({ ecards, resetApp, submitEcardImgBinding }) {
  const [checkedCertId, setCheckedCertId] = useState();
  const [selectedCardId, setSelectedCardId] = useState('19');
  const { formatMessage } = useIntl();
  const { replace } = useHistory();
  const { loading: submitLoading, run } = useBindEcard({
    onSuccess: (result) => {
      submitEcardImgBinding(result);
      replace('/certs');
    },
  });

  const language = getCookie('language');
  const bottomBtnImg = localizeResource('option-btn.png', language);
  return (
    <div className={styles['content-wrapper']}>
      <img
        src={require('../../assets/images/publicWelfareEcard/bg.jpg')}
        className={styles['bg']}
        alt="new year"
      />
      <div className={styles['content']}>
        <div>
          <WhiteSpace size="xl" />
          <img
            src={require('../../assets/images/publicWelfareEcard/title.png')}
            alt="title"
            className={styles['selected-title']}
          />
          <WhiteSpace size="lg" />
          <div className={styles['selected-display-area']}>
            <img
              src={require('../../assets/images/publicWelfareEcard/display-area.png')}
              alt="area"
              className={styles['selected-area-bg']}
            />
            <div className={styles["single-card"]}>
              <div className={styles["card-img-wrapper"]}>
                <img
                  language={getCookie('language')}
                  src={require(`../../assets/images/ecard_img/penguin-public-${language === 'en' ? 'sc' : language}.jpg`)}
                  alt=""
                  onLoad={() => {
                    window.dispatchEvent(new Event('resize'));
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles['certs-wrapper']}>
          <Ecards onChecked={(id) => setCheckedCertId(id)} ecards={ecards} />
          <input
            type="image"
            src={require('../../assets/images/publicWelfareEcard/bottom_btn.png')}
            alt="imgBtn"
            className={styles['get-year-card']}
            onClick={() => {
              if (submitLoading) return;
              if (!checkedCertId) {
                Modal.alert(
                  formatMessage({ id: 'common-errorTitle' }),
                  formatMessage({ id: 'campaign-noEcardSelected' }),
                  [{ text: formatMessage({ id: 'common-close' }) }]
                );
                return;
              }
              run({
                ecardId: checkedCertId,
                ecardImgId: 41,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
}

function Ecards({ onChecked, ecards }) {
  const [checkedCertId, setCheckedCertId] = useState();

  return (
    <>
      <p className={styles['cert-loading']} style={{ color: '#000000' }}>
        请选择一张欲更换企鹅卡的潜水证书
      </p>
      <Grid columns={3} gap={8} className={styles['cert-list']}>
        {ecards.map((ecard) => (
          <Grid.Item key={ecard.uniqueId}>
            <Cert
              ecard={ecard}
              check={(checkedCertId) => {
                setCheckedCertId(checkedCertId);
                onChecked(checkedCertId);
              }}
              checked={checkedCertId === getEncodedId(ecard)}
            />
          </Grid.Item>
        ))}
      </Grid>
    </>
  );
}

function mapStateToProps(state) {
  return {
    ecards: getEcards(state),
  };
}

export default connect(mapStateToProps, {
  submitEcardImgBinding: submitEcardImgBinding.success,
  resetApp,
})(SelectCertsPage);
