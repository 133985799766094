import React from 'react'
import { DotLoading } from 'antd-mobile-v5'
import { FormattedMessage } from 'react-intl';

const InfiniteScrollContent = ({ hasMore }) => {
  return (
    <>
      {hasMore ? (
        <>
          <span><FormattedMessage id="common-loading" /></span>
          <DotLoading />
        </>
      ) : (
          <span><FormattedMessage id="common-load-no-more" /></span>
      )}
    </>
  )
}

export default InfiniteScrollContent