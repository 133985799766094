import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import Header from '../../components/common/FormHeader'
import DiverInfo from './DiverInfo'
import PendingConditions from './PendingConditions'
import Agreement from './Agreement'
import Signature from '../../components/common/Signature'
import CheckCredits from './CheckCredits'
import { WhiteSpace, Button, WingBlank, Modal } from 'antd-mobile'
import { FormattedMessage, useIntl } from 'react-intl';
import { verifyCredits, removeExpires, getSubmitOssFormat } from '../../components/common/utils'
import { useSubmitSiApp } from '../../services/hooks'
import { useHistory } from 'react-router-dom';

const SpecialtyInstructor = () => {

  const { userInfo, ossSignature, signatureUrl, siDetail, credits } = useSelector(state => ({
    userInfo: state.data.userInfo,
    ossSignature: state.oss.ossSignature,
    signatureUrl: state.ui.signatureUrl,
    siDetail: state.ui.siDetail,
    credits: state.ui.credits
  }));

  const intl = useIntl();
  const { push } = useHistory()
  const { runAsync: submitSi, loading: submitting } = useSubmitSiApp();
  const [checked, setChecked] = useState(false)

  const submitSiApplication = () => {
    if(!signatureUrl || 
      !(siDetail.siApplicationDetailDTOS && siDetail.siApplicationDetailDTOS.length > 0) || 
      !checked || 
      verifyCredits(parseInt(credits))
    ) {
      Modal.alert(
        intl.formatMessage({id: 'dm-form-submit-error'}), 
        intl.formatMessage({id: 'dm-form-submit-error-info-no-agreement'}), 
        [{ text: intl.formatMessage({id: 'common-close'}) }]
      );
      return
    }
    const siAppItemList = siDetail.siApplicationDetailDTOS.map(item => {
      if(item.attachFile) {
          const { credentialId } = item
          return { credentialId, attachFile: removeExpires(item.attachFile) }
      }
      return item
    })
    const submitData = {
      "handwrittenSignatureUrl": getSubmitOssFormat(signatureUrl),
      "memberApplicationType": 'SI_2',
      "number": siDetail.siApplicationDetailDTOS.length,
      "siApplicationSubmitDetailDTOList": siAppItemList
    }
    if(siDetail.code) submitData.code = siDetail.code
    submitSi(submitData).then(res => {
      if (res.code) {
        push('/application_submit_success?type=SI_2', { code: res.code, paid: res.paid, paymentType: 'MEMBER_APPLICATION_SI' });
      }
    })
  }

  return userInfo && siDetail ? <>
    <Header 
      text={<FormattedMessage id="si-form-title" />}
      code={siDetail.code} />
    <DiverInfo userInfo={userInfo} />
    <WhiteSpace />
    <CheckCredits />
    <WhiteSpace />
    <PendingConditions ossSignature={ossSignature} />
    <WhiteSpace />
    <Signature
      filePath={'signature'}
      initSignature={siDetail && siDetail.handwrittenSignatureUrlInstructor}
      modified={!siDetail.code || siDetail && siDetail.status === 'WAIT_ADMIN_APPROVED' || siDetail.status === 'SUBMIT'}
    />
    <WhiteSpace />
    <Agreement setChecked={checked => setChecked(checked)} checked={checked} />
    <WhiteSpace />
    <WingBlank>
      <Button type="primary" onClick={() => submitSiApplication()} loading={submitting}><FormattedMessage id="dm-form-submit" /></Button>
      <WhiteSpace size="xl" />
    </WingBlank>
    <WhiteSpace size="xl" />
  </> : null
}

export default SpecialtyInstructor