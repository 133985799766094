import React from 'react';
import { Result, Button } from 'antd-mobile-v5';
import { useLanguage } from '../../services/hooks/user';
import { FormattedMessage } from 'react-intl';
import config from '../../services/config.json';
import styles from '../Register/Register.module.css';

const ResetPwd = () => {
  const lang = useLanguage();

  const gotoResetPassword = () => {
    let client_id, redirect_uri, host;
    const langForReset =
      lang === 'sc' ? '/zh-CN' : lang === 'tc' ? '/zh-HK' : '';

    if (document.location.origin === config.URL_prod) {
      client_id = 'skrnlhd1e64fs2pbp9uil2e3i';
      redirect_uri = config.URL_prod;
      host = 'https://account.padi.com';
    } else {
      client_id = '1f4jmt4u03ekm1ddneiqm8kuqu';
      redirect_uri = config.URL_qa;
      host = 'https://account.global-np.padi.com';
    }

    window.location.href = `${host}/login/forgot-password${langForReset}?client_id=${client_id}&redirect_uri=${redirect_uri}`;
  };

  return (
    <Result
      status="warning"
      title={
        <span className={styles.resetTitle}>
          <FormattedMessage id="reset-pwd-attention" />
        </span>
      }
      description={
        <div className={styles.resetDesc}>
          <p>
            <FormattedMessage id="reset-pwd-desc" />
          </p>
          <Button Default fill="none" size="small" onClick={gotoResetPassword}>
            <FormattedMessage id="reset-pwd-skip-now" />
          </Button>
        </div>
      }
    />
  );
};

export default ResetPwd;
