import { getCookie } from '../components/common/cookie';
import { getParameterByName } from '../components/common/utils';

export const isProduction = () =>
  document.location.origin === 'https://wx.padi.com.cn';

export const debug = (...args) => {
  if (!isProduction()) console.log(...args);
};

export const getLanguage = () => getCookie('language');

export const getReferer = () =>
  getParameterByName('referer') ||
  encodeURIComponent(window.location.href.replace(window.location.origin, ''));
