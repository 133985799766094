import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { InputItem, WhiteSpace } from 'antd-mobile';
import { FormattedMessage, useIntl } from 'react-intl';
import CardLayout from '../DiveMasterForm/CardLayout'
import Signature from '../../components/common/Signature'
import SearchStore from '../../components/common/SearchStore';
import SearchInstructor from '../../components/common/SearchInstructor';
import UploadOssFile from '../../components/common/UploadOssFile'
import UploadFileWrapper from '../../components/common/UploadFileWrapper'
import UserPhoto from '../../components/common/UserPhoto'
import ApplicationStatus from './ApplicationStatus.js'
import { filterTeachingLanguage, formatDate } from "../../components/common/utils";
import DatePickerComponent from '../../components/common/DatePicker'

import styles from '../DiveMasterForm/DiveMasterForm.module.css'
import { getCookie } from "../../components/common/cookie";

const EFRITDetail = () => {

  const [date, setDate] = useState('')
  const { formatMessage } = useIntl()
  const { efrtDetail } = useSelector(state => ({
    efrtDetail: state.efri.efrtDetail,
  }));

  const editable = !!(efrtDetail.status === 'WAIT_CONFIRM')

  useEffect(() => {
    if (efrtDetail && efrtDetail.courseDate) setDate(efrtDetail.courseDate)
  }, [efrtDetail])

  if (!Object.keys(efrtDetail).length > 0) return null
  return <>
    <CardLayout title={<FormattedMessage id="dm-form-diver-info" />} status={"none"}>
      <div className={styles["diver-info"]}>
        <div>
          <InputItem
            type="text"
            editable={false}
            value={efrtDetail.userInfoDTO.englishName}
          >
            <FormattedMessage id="dm-form-name" />
          </InputItem>
          <InputItem
            type="text"
            editable={false}
            value={formatDate(efrtDetail.userInfoDTO.birthDate, 'YYYY-MM-DD')}
          >
            <FormattedMessage id="dm-form-birth" />
          </InputItem>
        </div>
        <UserPhoto id={efrtDetail.userInfoDTO.affiliateId} />
      </div>
    </CardLayout>
    <WhiteSpace />
    <CardLayout title={<FormattedMessage id="dm-form-required-certs" />} status={"none"}>
      <UploadFileWrapper
        labelName={efrtDetail.efr ? `EFR (${efrtDetail.efr})` : "EFR"}
        editable={false}>
        <UploadOssFile
          fileList={efrtDetail.efrfiles || []}
          type={"EFR"}
          editable={false} />
      </UploadFileWrapper>
    </CardLayout>
    <WhiteSpace />
    <CardLayout title={<FormattedMessage id="dm-form-training-info" />} status={"none"}>
      {efrtDetail.instructorInfoDTO && <SearchInstructor
        id={"trainer"}
        editable={false}
        instructor={efrtDetail.instructorInfoDTO}
        instructorNumber={efrtDetail.instructorInfoDTO.instructorNumber}
      />}
      {efrtDetail.storeInfoDTO && <SearchStore
        editable={false}
        store={efrtDetail.storeInfoDTO}
        storeNumber={efrtDetail.storeInfoDTO.storeNumber}
      />}
    </CardLayout>
    <WhiteSpace />
    <CardLayout title={<FormattedMessage id="dm-region-and-lang-title" />} status={"none"}>
      {efrtDetail.regionDTO && <InputItem
        type="text"
        editable={false}
        value={efrtDetail.regionDTO.region}
      >
        <FormattedMessage id="dm-region" />
      </InputItem>}
      <InputItem
        type="text"
        editable={false}
        value={filterTeachingLanguage(efrtDetail.languageId)}
      >
        <FormattedMessage id="dm-lang-title" />
      </InputItem>
    </CardLayout>
    <WhiteSpace />
    <DatePickerComponent
      extra={formatMessage({ id: 'common-select' })}
      title={formatMessage({ id: 'efri-course-date-error' })}
      maxDate={new Date()}
      disabled={!editable}
      onChange={(date, format) => format ? setDate(formatDate(date, 'YYYY-MM-DD')) : setDate(date)}
      value={date ? date : efrtDetail.courseDate ? efrtDetail.courseDate : ''}>
      <FormattedMessage id="common-course-date" />
    </DatePickerComponent>
    <WhiteSpace />
    <Signature
      modified={editable}
      filePath={'signature'}
      initSignature={efrtDetail.handwrittenSignatureUrlEFRT} />
    <WhiteSpace size="lg" />
    <ApplicationStatus
      courseDate={date}
    />
    <WhiteSpace size="lg" />
  </>
}

export default EFRITDetail