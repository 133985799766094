import React, { Component } from 'react';
import { Result, Icon } from 'antd-mobile';
import { connect } from 'react-redux'
import { getResetEmail } from '../../reducers/selectors'
import { injectIntl, FormattedMessage } from 'react-intl';

import './ResetResult.css'

class ResetResult extends Component {

  render() {
    const { intl:{formatMessage} } = this.props;
    return (
      <div className="result">
        <Result
          img={<Icon type="check-circle" className="spe" style={{ fill: '#1F90E6' }} />}
          title={formatMessage({id: 'forgotPassword-sendSuccess'})}
          message={<div><p>
            <FormattedMessage
              id="forgotPassword-sendEmail"
            />  
          </p><p>{this.props.resetEmail}</p></div>}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resetEmail: getResetEmail(state),
  }
}

export default injectIntl(connect(mapStateToProps, null)(ResetResult));
