import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  Modal,
  Toast,
  WhiteSpace,
  WingBlank
} from 'antd-mobile';
import {
  showModal,
  getSubmitOssFormat,
  showAlert
} from "./utils";
import PaymentBtn from '../FormTools/PaymentBtn'
import AgreementPopup from '../FormTools/AgreementPopup'
import { useTaiwanIp } from '../../services/hooks'

import styles from '../../pages/DiveMasterForm/DiveMasterForm.module.css'

const paymentTypeList = ['MEMBER_APPLICATION_PADI_MERMAID_I', 'MEMBER_APPLICATION_PADI_BASIC_MERMAID_I', 'MEMBER_APPLICATION_FDI', 'MEMBER_APPLICATION_AFDI', 'MEMBER_APPLICATION_MFDI', 'MEMBER_APPLICATION_DM']

const DetailStatusContent = ({ 
  approveFn, 
  payFn, 
  listUrl, 
  detail, 
  isStore, 
  agreement, 
  payApplicationType,
  toPay,
  setVisible
}) => {

  const { replace, push } = useHistory()
  const { formatMessage } = useIntl()
  const { data: isTaiwanIp } = useTaiwanIp({});
  const { signatureUrl, profile } = useSelector(state => ({
    profile: state.data.profile,
    signatureUrl: state.ui.signatureUrl,
  }));

  const { status, note, code, paymentMethod, paymentType } = detail

  const approvedAction = (approve) => {
    if (!signatureUrl) {
      showModal(
        formatMessage({ id: 'common-errorTitle' }), 
        formatMessage({ id: 'dm-form-signature-confirm' }), 
        () => { }, 
        () => { }, 
        formatMessage({ id: 'common-comfirmBtn' }), 
        formatMessage({ id: 'common-cancel' }))
      return
    }
    if (agreement === false) {
      showModal(
        formatMessage({ id: 'common-errorTitle' }), 
        formatMessage({ id: 'common-check-agreement' }), 
        () => { }, 
        () => { }, 
        formatMessage({ id: 'common-comfirmBtn' }), 
        formatMessage({ id: 'common-cancel' }))
      return
    }
    if (approve) {
      submitApprovedFn()
    } else {
      Modal.prompt(
        formatMessage({ id: 'dm-refuse-reason' }),
        formatMessage({ id: 'dm-refuse-tips' }),
        [
          { text: formatMessage({ id: 'common-cancel' }) },
          {
            text: formatMessage({ id: 'common-comfirmBtn' }), onPress: async value => {
              if (!value) {
                showModal(
                  '',
                  formatMessage({ id: 'dm-refuse-error' }),
                  () => { },
                  () => { },
                  formatMessage({ id: 'common-comfirmBtn' }),
                  formatMessage({ id: 'common-cancel' })
                );
                return
              }
              const approvedData = {
                approve,
                code: detail.code,
                handwrittenSignatureUrl: getSubmitOssFormat(signatureUrl),
                note: value
              }
              try {
                Toast.loading()
                const response = await approveFn(approvedData);
                if (response.error) {
                  showAlert(
                    response.error.clientMsg,
                    formatMessage({ id: 'common-close' })
                  );
                } else {
                  replace(listUrl)
                }
              } catch (e) {
                showAlert(
                  formatMessage({ id: 'common-errorStatus' }),
                  formatMessage({ id: 'common-close' })
                );
              } finally {
                Toast.hide()
              }
            }
          },
        ]
      )
    }
  };

  

  const submitApprovedFn = () => {
    showModal(
      '',
      formatMessage({ id: 'dm-form-instructor-accept-confirm' }),
      async () => {
        const approvedData = {
          approve: true,
          code: detail.code,
          handwrittenSignatureUrl: getSubmitOssFormat(signatureUrl)
        }
        try {
          Toast.loading()
          const response = await approveFn(approvedData);
          if (response.error) {
            showAlert(
              response.error.clientMsg,
              formatMessage({ id: 'common-close' })
            );
          } else {
            if (!paymentMethod) {
              if (profile.teachingLocation === 'TW' || isTaiwanIp) {
                if(paymentTypeList.indexOf(payApplicationType) >= 0) {
                  Modal.alert('', formatMessage({ id: 'BMI-trainer-review-reminder' }), [
                    { text: formatMessage({ id: 'common-yes' }), onPress: () => setVisible(true) },
                    { text: formatMessage({ id: 'common-no' }), onPress: () => push(listUrl) },
                  ])
                }
              } else {
                Modal.alert('', formatMessage({ id: 'BMI-trainer-review-reminder' }), [
                  { text: formatMessage({ id: 'common-yes' }), onPress: () => toPay(detail.code) },
                  { text: formatMessage({ id: 'common-no' }), onPress: () => push(listUrl) },
                ])
              }
            } else {
              push(listUrl)
            }
          }
        } catch (e) {
          showAlert(
            formatMessage({ id: 'common-errorStatus' }),
            formatMessage({ id: 'common-close' })
          );
        } finally {
          Toast.hide()
        }
      },
      () => { }, formatMessage({ id: 'common-comfirmBtn' }),
      formatMessage({ id: 'common-cancel' })
    )
  }

  switch (status) {
    case 'WAIT_CONFIRM':
      if (isStore) {
        return <div className={styles.statusBtn}>
          <FormattedMessage id="app-wait-ins-review" />
        </div>
      }
      return (
        <div className={styles.btnBox}>
          <Button
            inline
            type="primary"
            className={styles.btn}
            onClick={() => approvedAction(true)}
          ><FormattedMessage id="dm-form-agree" /></Button>
          <Button
            inline
            type="warning"
            className={styles.btn}
            onClick={() => approvedAction(false)}
          ><FormattedMessage id="dm-form-reject" /></Button>
        </div>
      )
    case 'INSTRUCTOR_APPROVED':
      return paymentMethod ? <div className={styles.statusBtn}>
        <FormattedMessage id="app-wait-cs-review" />
      </div>
        : !paymentType ? null
          : <>
            <WhiteSpace />
            <WingBlank>
              <PaymentBtn code={code} paymentFn={payFn} payApplicationType={payApplicationType} />
            </WingBlank>
          </>
    case 'INSTRUCTOR_NOT_APPROVED':
      return <div className={styles.statusBtn}>
        <FormattedMessage id="app-status-instructor-not-approved" />
      </div>
    case 'ADMIN_NOT_APPROVED':
      return <div className={styles.statusBtn}>
        <div>
          <FormattedMessage id="app-cs-not-approved" />
        </div>
        {note && <div>
          <FormattedMessage id="app-refuse-reason" />{note}
        </div>}
      </div>
    case 'ADMIN_APPROVED':
    case 'PASS':
      return <div className={styles.statusBtn}>
        <FormattedMessage id="app-wait-cert" />
      </div>
    case 'NOT_PASS':
      return <div className={styles.statusBtn}>
        <FormattedMessage id="owsi-not-pass" />
      </div>
    case 'INSTRUCTOR_CREATED':
      return <div className={styles.statusBtn}>
        <FormattedMessage id="app-wait-applicant-confirm" />
      </div>
    case 'SUCCESS':
      return <div className={styles.statusBtn}>
        <FormattedMessage id="app-success" />
      </div>
    case 'CLOSE':
      return <div className={styles.statusBtn}>
        <FormattedMessage id="app-close" />
      </div>
    default:
      return null
  }
}

const DetailStatus = ({ ...props }) => {

  const [visible, setVisible] = useState(false)
  const { formatMessage } = useIntl()
  const { push } = useHistory()
  const { profile } = useSelector(state => ({
    profile: state.data.profile
  }));

  const toPay = async (code) => {
    const result = await props.payFn(code)
    if (result.error) {
      showAlert(
        result.error.clientMsg,
        formatMessage({ id: 'common-close' })
      );
      return
    }
    if (result.url) {
      if (profile.teachingLocation === 'TW') return push('/invoice_form', { code, nextUrl: result.url, type: props.payApplicationType })
      window.location.replace(result.url)
    }
  }

  return <>
    <DetailStatusContent {...props} toPay={toPay} setVisible={setVisible} />
    <AgreementPopup visible={visible} setVisible={setVisible} toPay={() => toPay(props.detail.code)} />
  </>
}

export default DetailStatus