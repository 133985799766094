import React, { useState } from 'react';
import { Form, Input, Modal, Button, Space } from 'antd-mobile-v5';
import { useIntl } from 'react-intl';
import PickerTool from '../../components/FormTools/Picker';
import SelectRegion from '../../components/FormTools/SelectRegion';
import DatePickerTool from '../../components/FormTools/DatePicker';
import { genderOptions } from '../../components/FormTools/utils';
import dayjs from 'dayjs';
import { useForm } from 'antd/lib/form/Form';
import { useStudentSearchByEmail } from '../../services/hooks';
import StudentsList from './StudentsList';
import { getCookie } from '../../components/common/cookie';
import { NAME_PATTERN } from '../../utils/user';
import { useLanguage } from '../../services/hooks/user';

const initialValues = {
  gender: '',
  addressCountryId: '',
  addressStateId: '',
};

// const initialValues = {
//   email: 'a@b11.com',
//   firstName: 'xinhua',
//   lastName: 'huang',
//   gender: 'M',
//   addressCountryId: 51,
//   addressStateId: 225,
//   address: '光华路soho',
// };
export const UserInfo = ({form}) => {
  const {formatMessage} = useIntl();
  const lang = useLanguage();
  return <><Form.Item
  name="lastName"
  label={formatMessage({ id: 'setProfile-lastName' })}
  rules={[
    {
      required: true,
      message: formatMessage({ id: 'form-lastname-error' }),
    },
    {
      pattern: NAME_PATTERN,
      message: formatMessage({ id: 'form-name-format-error' }),
    },
  ]}
>
  <Input placeholder={formatMessage({ id: 'setProfile-defaultName' })} />
</Form.Item>
<Form.Item
  name="firstName"
  label={formatMessage({ id: 'setProfile-firstName' })}
  rules={[
    {
      required: true,
      message: formatMessage({ id: 'form-firstname-error' }),
    },
    {
      pattern: NAME_PATTERN,
      message: formatMessage({ id: 'form-name-format-error' }),
    },
  ]}
>
  <Input placeholder={formatMessage({ id: 'setProfile-defaultName' })} />
</Form.Item>
<PickerTool
  name="gender"
  form={form}
  rules={[
    {
      required: true,
      message: formatMessage({ id: 'form-gender-empty-error' }),
    },
  ]}
  placeholder={formatMessage({ id: 'setProfile-errorGender' })}
  data={genderOptions[lang]}
  label={formatMessage({ id: 'setProfile-gender' })}
/>
<DatePickerTool
  name="birthDate"
  placeholder={formatMessage({ id: 'form-birthdate-hint' })}
  label={formatMessage({ id: 'dm-form-birth' })}
  rules={[
    {
      required: true,
      message: formatMessage({ id: 'form-birthdate-empty-error' }),
    },
  ]}
  min={dayjs().subtract(70, 'year').toDate()}
  max={dayjs().subtract(6, 'year').toDate()}
/>
<SelectRegion
  form={form}
  countryIdFormKey="addressCountryId"
  stateIdFormKey="addressStateId"
  required
/>
<Form.Item
  name="address"
  label={formatMessage({ id: 'common-address' })}
  rules={[
    {
      required: true,
      message: formatMessage({ id: 'form-address-empty-error' }),
    },
  ]}
>
  <Input placeholder={formatMessage({ id: 'common-address-reminder' })} />
</Form.Item></>
}

const StudentForm = ({ layout = 'horizontal', onConfirm, onCancel }) => {
  const { formatMessage } = useIntl();
  const [form] = useForm();
  return (
    <Form
      layout={layout}
      form={form}
      validateTrigger="onSubmit"
      onFinish={(values) => {
        form.resetFields();
        onConfirm(values);
      }}
      initialValues={initialValues}
      footer={
        <Space>
          <Button onClick={onCancel}>
            {formatMessage({ id: 'common-cancel' })}
          </Button>
          <Button type="submit" color="primary">
            {formatMessage({ id: 'common-comfirmBtn' })}
          </Button>
        </Space>
      }
    >
      <Form.Header>
        {formatMessage({
          id: 'efr-public-new-student',
          values: { inputVal: 'testa@b.com' },
        })}
      </Form.Header>
      <UserInfo form={form} />
    </Form>
  );
};

const AddStudents = ({ onChange = () => {}, layout = 'horizontal', type }) => {
  const { formatMessage } = useIntl();
  const [showNewForm, setShowNewForm] = useState(false);
  const [students, setStudents] = useState([]);
  const [form] = useForm();

  const addToList = (student) => {
    const newStudents = [student, ...students];
    onChange(newStudents);
    setStudents(newStudents);
  };

  const remove = (email) => {
    const newStudents = students.filter((s) => s.email !== email);
    onChange(newStudents);
    setStudents(newStudents);
  };

  const { loading, run } = useStudentSearchByEmail({
    onSuccess: (data, [email]) => {
      onSearchResult({ ...data, email, isInM2: true });
    },
    onError: (error, [email]) => {
      const { code, clientMsg } = error;
      if (code === 'email.not.inm2') {
        onSearchResult({ email, isInM2: false });
        return true;
      } else if(clientMsg) {
        onSearchResult({});
        form.setFields([
          {
            name: 'email',
            errors: [clientMsg],
          },
        ]);
        return true;
      }
      return false;
    }
  });
  const search = ({ email }) => {
    if (students.find((s) => s.email === email)) {
      form.setFields([
        {
          name: 'email',
          errors: [formatMessage({ id: 'student-search-added' })],
        },
      ]);
    } else {
      run(email, type);
    }
  };
  const onSearchResult = (result) => {
    if(!result.email) return;
    if (!result.isInM2) {
      setShowNewForm(true);
    } else {
      addToList(result);
    }
  };
  return (
    <>
      <Form
        layout={layout}
        form={form}
        validateTrigger="onSubmit"
        onFinish={search}
      >
        <Form.Header>{formatMessage({ id: 'setEmail-title' })}</Form.Header>
        <Form.Item
          label={formatMessage({ id: 'common-email' })}
          rules={[
            {
              required: true,
              message: formatMessage({ id: 'form-email-empty-error' }),
            },
            {
              type: 'email',
              message: formatMessage({ id: 'form-email-format-error' }),
            },
          ]}
          name="email"
          extra={
            <Button
              size="small"
              color="primary"
              fill="none"
              type="submit"
              loading={loading}
              loadingText={formatMessage({ id: 'common-search-loading' })}
            >
              {formatMessage({ id: 'common-search-title' })}
            </Button>
          }
        >
          <Input
            placeholder={formatMessage({ id: 'forgotPassword-emailInput' })}
          />
        </Form.Item>
      </Form>
      <StudentsList students={students} remove={remove} />
      <Modal
        title={formatMessage({ id: 'form-student-info' })}
        visible={showNewForm}
        content={
          <StudentForm
            onCancel={() => setShowNewForm(false)}
            onConfirm={(student) => {
              setShowNewForm(false);
              addToList({
                ...student,
                userName: `${student.firstName} ${student.lastName}`,
                email: form.getFieldValue('email'),
              });
            }}
          />
        }
      />
    </>
  );
};

export default AddStudents;
