import React, { useState } from 'react'
import { List } from 'antd-mobile';
import { FormattedMessage } from 'react-intl';

const Item = List.Item

const UploadFileWrapper = ({ labelName, children, editable }) => {

  const [active, setActive] = useState(true)

  return <>
    {editable ? (
      <Item extra={<span onClick={() => setActive(!active)}><FormattedMessage id="si-upload-btn" /></span>}>
        {labelName}
      </Item>
    ) : (
      <Item>
        {labelName}
      </Item>
    )}
    {active && <>
      {children}
    </>}
  </>
}

export default UploadFileWrapper