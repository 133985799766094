import React from 'react';
import { Image } from 'antd-mobile-v5';

import './CertShow.css';

// WZZ dsd has its own style, hide ecard name
const hideEcardName = (ecard) => !ecard || (ecard.certificationNumber === 58 && ecard.storeNumber === 23724);

const CardImageShow = ({ path, cert }) => {
  const cardName = cert.cardTextDTOS || cert.certificationName || cert.credentialName
  const imagePath = () => {
    const { certificationNumber, storeNumber } = cert;
    if (path.indexOf('http') === 0) {
      return path;
    } else if(certificationNumber && certificationNumber === 58 && storeNumber && storeNumber === 23724) {
      return require(`../../assets/images/certs/cert_quwan.jpg`);
    } else {
      if(certificationNumber && path === 'certs/cert_1.jpg') return require(`../../assets/images/certs/cert_-1.jpg`);
      return require(`../../assets/images/${path}`);
    }
  }
  return <div className={`diver_card`}>
    {!hideEcardName(cert) && <div className="diver_card__bd">
      {cardName instanceof Array ? cardName.map(item => <h4 key={item.cardTextLine}>{item.cardTextLine}</h4>) : <h4>{cardName}</h4>}
    </div>}
    <div className="diver_card__bg">
      <Image src={imagePath()} lazy />
    </div>
  </div>
}

export default CardImageShow;
