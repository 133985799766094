import { Image, NavBar } from 'antd-mobile-v5';
import { useHistory, useLocation } from 'react-router-dom';
import { WhiteSpace } from 'antd-mobile';
import React, { useState, useEffect } from 'react';

import { CertsList } from '../CertsList/CertsList';
import { isMainlandUser } from '../../utils/user';
import { useCheckAgreement } from '../../services/hooks';
import AgreementPopup from '../../components/common/AgreementPopup';
import BottomNav from '../BottomNav';
import EcardsListFooter from '../../components/EcardsList/EcardsListFooter';
import TorcherCert from '../Cert/TorcherCert';
import UserHeader from '../UserHeader/UserHeader';
import LightenCert from '../LightenCert/LightenCert';
import { useIntl } from 'react-intl';

export default function EcardsList({
  ecards,
  nextCerts,
  profile,
  push,
  resetApp,
  userInfo,
}) {
  const history = useHistory();
  const { data: agreements } = useCheckAgreement();
  const [visible, setVisible] = useState(false);
  const { pathname } = useLocation();
  const { formatMessage } = useIntl();

  useEffect(() => {
    const showAgreements =
      agreements?.filter((agree) => !agree.agree).length > 0 ? true : false;
    if (agreements) setVisible(showAgreements);
  }, [agreements]);

  return (
    <>
      {agreements && (
        <AgreementPopup
          agreements={agreements}
          visible={visible}
          setVisible={setVisible}
        />
      )}
      {pathname === '/ecards' && <NavBar back={formatMessage({ id: "common-back" })} onBack={() => window.location.href = "https://padiclub.zhibo.tv/pages/home/home"}></NavBar>}
      <UserHeader ecards={ecards} userInfo={userInfo} profile={profile} />
      <WhiteSpace size="xl" />
      {profile.publicWelfareCertificateCode && (
        <TorcherCert
          torcher={{
            nickName: profile?.wechat?.nickname,
            no: profile.publicWelfareCertificateCode,
          }}
        />
      )}
      <LightenCert nextCerts={nextCerts} />
      <CertsList ecards={ecards} />
      <EcardsListFooter />
      {isMainlandUser(userInfo) && (
        <Image
          style={{
            position: 'fixed',
            bottom: '200px',
            right: '16px',
            width: '60px',
            zIndex: 200,
          }}
          // src={require('../../assets/images/pw_cert/entry.png')}
          // onClick={() => history.push('/buy_cards')}
          src={require('../../assets/images/common/year-icon.png')}
          onClick={() => history.push('/newYearCampaignPost')}
        />
      )}
      <BottomNav isMainlandUser={isMainlandUser(userInfo)} />
    </>
  );
}
