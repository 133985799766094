import { List, Steps } from 'antd-mobile-v5';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { ClockCircleFill, CheckCircleFill, UserOutline } from 'antd-mobile-icons';
import React from 'react';

import { formatLocalDate } from '../../utils/time';
import { appStatusId } from './ProgressStatus';
import { getParameterByName } from '../../components/common/utils';

const ListItem = ({ confirmItem }) => {
  const type = getParameterByName('type');
  const { push } = useHistory();
  const { formatMessage } = useIntl();

  return (
    <List mode="card" key={confirmItem.code}>
      <List.Item
        nostyle
        description={formatLocalDate(confirmItem.lastSubmitDate)}
        extra={
          <Steps>
            <Steps.Step
              title={formatMessage({
                id: appStatusId(confirmItem.status, confirmItem.paymentMethod),
              })}
              status={'process'}
              icon={
                confirmItem.status === 'SUCCESS' ? (
                  <CheckCircleFill />
                ) : (
                  <ClockCircleFill />
                )
              }
            />
          </Steps>
        }
      >
        {`${formatMessage({ id: 'form-app-num' })} #${confirmItem.code}`}
      </List.Item>
      {confirmItem.students.map((student) => (
        <List.Item
          description={student.email}
          key={student.email}
          onClick={() =>
            push(`/issue_certs/detail?type=${type}&code=${confirmItem.code}`, {
              detail: confirmItem,
            })
          }
          arrow={false}
          prefix={<UserOutline />}
        >
          {student.userName}
        </List.Item>
      ))}
    </List>
  );
};

export default ListItem;
