import React from 'react'
import { List } from 'antd-mobile';
import { useHistory } from 'react-router-dom'
import { formatDate } from '../../components/common/utils';
import { FormattedMessage } from 'react-intl';

import styles from '../OwsiCdApps/OwsiCdApps.module.css'

const Item = List.Item;

const AppStatus = ({ status, payerType, paymentMethod }) => {
  switch (status) {
    case 'WAIT_CONFIRM':
      return (
        <span className={styles.confirm}>
          <FormattedMessage id="app-status-wait-confirm" />
        </span>
      )
    case 'INSTRUCTOR_NOT_APPROVED':
      return (
        <span className={styles.refused}>
          <FormattedMessage id="app-status-instructor-not-approved" />
        </span>
      )
    case 'INSTRUCTOR_APPROVED':
      if (paymentMethod) {
        return (
          <span className={styles.confirmed}>
            <FormattedMessage id="app-wait-cs-review" />
          </span>
        )
      }
      if (payerType === 'EFRT') {
        return <span className={styles.confirmed}>
          <FormattedMessage id="app-to-pay" />
        </span>
      } else if (payerType === 'EFRI') {
        return <span className={styles.confirmed}>
          <FormattedMessage id="app-wait-pay" />
        </span>
      } else {
        return null
      }
    case 'ADMIN_NOT_APPROVED':
      return (
        <span className={styles.refused}>
          <FormattedMessage id="app-cs-not-approved" />
        </span>
      )
    case 'ADMIN_APPROVED':
      return (
        <span className={styles.confirm}>
          <FormattedMessage id="app-wait-cert" />
        </span>
      )
    case 'SUCCESS':
      return (
        <span className={styles.confirm}>
          <FormattedMessage id="app-success" />
        </span>
      )
    case 'CLOSE':
      return (
        <span className={styles.confirm}>
          <FormattedMessage id="app-close" />
        </span>
      )
    default:
      return null
  }
}

const EFRIAppListItem = ({ confirmItem }) => {
  const { push } = useHistory()

  return <Item 
    extra={<AppStatus status={confirmItem.status} payerType={confirmItem.payerType} paymentMethod={confirmItem.paymentMethod} />}
    onClick={() => push(`/efrt_detail?code=${confirmItem.code}`)}
  >
    <>
      <div className={styles.name}>{confirmItem.userName}</div>
      <span>{confirmItem.email}</span>
      <div>{formatDate(confirmItem.lastSubmitDate, 'YYYY-MM-DD')}</div>
      {confirmItem.note && <div className={styles.note}><FormattedMessage id="si-refuse-reason" />{confirmItem.note}</div>}
    </>
  </Item>
}

export default EFRIAppListItem