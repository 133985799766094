import React from 'react';
import { Flex, List, InputItem } from 'antd-mobile';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputOnBlur } from '../common/utils';
import PickerComponent from '../common/Picker'
import DatePickerComponent from '../common/DatePicker'
import { genderOptions } from '../FormTools/utils'

import './Login.css';
import { getCookie } from '../common/cookie';

const getDate = () => {
  const date = new Date()
  return new Date(date.getFullYear() - 100 + '/' + (date.getMonth()+1) + '/' + date.getDate())
}

const ProfileInfo = ({profile = {}, setProfile, pending}) => {

  const lang = getCookie('language');

  const {formatMessage} = useIntl()

  const {birthDate, firstName, lastName, gender } = profile;

  const changeName = (name, type) => {
    const isLetter = /^[A-Za-z ]+$/
    if(isLetter.test(name) || name === '') {
      if(type === 'firstName') setProfile({...profile, firstName: name})
      else setProfile({...profile, lastName: name})
    }
  }

  return (
    <div className="frame">
      <form>
        <Flex
          direction="column"
          justify="center"
          align="center" >
          <List className="login-list">
            <InputItem
              className="name"
              type="text"
              name="name"
              disabled={pending}
              placeholder={formatMessage({ id: "setProfile-defaultName"})}
              onChange={name => changeName(name, 'firstName')}
              value={firstName}
              onBlur={() => { InputOnBlur() }} ><FormattedMessage id="setProfile-firstName" /></InputItem>
            <InputItem
              className="name"
              type="text"
              name="name"
              disabled={pending}
              placeholder={formatMessage({ id: "setProfile-defaultName"})}
              onChange={name => changeName(name, 'lastName')}
              value={lastName}
              onBlur={() => { InputOnBlur() }} ><FormattedMessage id="setProfile-lastName" /></InputItem>
            <PickerComponent 
              disabled={pending}
              extra={formatMessage({id: 'common-select'})}
              data={genderOptions[lang]} 
              onOk={gender => setProfile({...profile, gender})}
              value={gender}>
                <FormattedMessage id="setProfile-gender" />
            </PickerComponent>
            <DatePickerComponent 
              extra={formatMessage({id: 'common-select'})}
              title={formatMessage({id: 'setProfile-chooseDate'})}
              disabled={pending}
              minDate={getDate()}
              onChange={birthDate => setProfile({...profile, birthDate})} 
              value={birthDate}>
                <FormattedMessage id="setProfile-birthDate" />
            </DatePickerComponent>
          </List>
        </Flex>
      </form>
    </div>
  )
}

export default ProfileInfo;
