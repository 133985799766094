import React from 'react'
import { Empty } from 'antd';

const style = {
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  justifyContent: 'center'
}

const EmptyStatus = ({ desc }) => {
  return <div style={style}>
    <Empty description={desc} />
  </div>
}

export default EmptyStatus