import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, Form, Input, Cascader, Toast } from 'antd-mobile-v5';
import CompanyOptions from './CompanyOptions'
import PhoneOptions from './PhoneOptions'
import NaturalPersonOptions from './NaturalPersonOptions'
import InvoiceDonationOptions from './InvoiceDonationOptions'
import { useSaveInvoice, useGetInvoice, useUseInvoice } from '../../services/hooks'
import { getParameterByName } from '../../components/common/utils';

const invoiceTypes = [
  {
    'label': '（個人）電子發票',
    'value': '（個人）電子發票',
    'children': [
      {
        'label': '會員載具',
        'value': '會員載具'
      },
      {
        'label': '手機載具',
        'value': '手機載具'
      },
      {
        'label': '自然人憑證',
        'value': '自然人憑證'
      },
      {
        'label': '發票捐贈',
        'value': '發票捐贈'
      }
    ]
  },
  {
    'label': '（公司二聯）電子發票',
    'value': '（公司二聯）電子發票'
  },
  {
    'label': '（公司三聯）電子發票',
    'value': '（公司三聯）電子發票'
  }
]

const InvoiceFormPage = () => {
  const [form] = Form.useForm();
  const { push, goBack } = useHistory()
  const { state } = useLocation()
  const [visible, setVisible] = useState(false)
  const [invoiceType, setType] = useState([])
  const { run: toUse } = useUseInvoice({
    onSuccess: data => {
      if(data) {
        console.log(state.nextUrl,'*******')
        if (state.nextUrl) {
          if (state.nextUrl.indexOf('/') === '0') push(state.nextUrl)
          else window.location.href = state.nextUrl
        }
      }else {
        Toast.show({
          icon: 'fail',
          content: '提交失敗',
        })
      }
    }
  });
  const { run, loading } = useSaveInvoice({
    onSuccess: data => {
      if (state && state.type) {
        Toast.show({
          icon: 'loading',
          content: '保存成功，正在提交發票資訊...',
          afterClose: () => {
            toUse({
              id: state.code,
              type: state.type,
              invoiceDTO: data
            })
          }
        })
      }else {
        Toast.show({
          icon: 'success',
          content: '保存成功',
          afterClose: () => {
            const referer = getParameterByName('referer')
            if (!referer) goBack()
          }
        })
      }
    },
  })
  const { loading: invoiceLoading } = useGetInvoice({
    onSuccess: data => {
      if (!data.type1) return
      let type = [data.type1]
      if(data.type2) type.push(data.type2)
      let subData = data
      for (let k in subData) {
        if (!subData[k] || k === 'type1' || k === 'type2' || k ==='affiliateId') delete subData[k]
      }
      form.setFieldsValue({ type, ...subData })
      setType(type)
    }
  });

  const setInvoiceType = (value) => {
    form.setFieldsValue({ type: value })
    setType(value)
  }

  const save = (values) => {
    const { 
      invoiceMobileAgain, 
      invoiceCitizenDigitalCertificateAgain, 
      invoiceDonationUnitCodeAgain, 
      type,
      ...otherValues
    } = values
    let data = { ...otherValues, type1: type[0] }
    if(type.length == 2) data.type2 = type[1]
    run(data)
  }

  const checkInvoiceType = () => {
    if(invoiceType.length === 0) return false
    return (invoiceType[0] === '（個人）電子發票' && invoiceType.length === 2) || invoiceType[0].indexOf('公司') >= 0
  }

  return <>
    <Form
      validateTrigger="submit"
      form={form}
      onFinish={save}
      footer={
        <Button 
          block 
          color='primary'
          type='submit'
          loading={loading}
        >
          確認
        </Button>
      }
    >
      <Form.Header>發票資訊</Form.Header>
      <Form.Item 
        name="type" 
        label={'發票形式'} 
        rules={[
          {
            required: true,
            message: '發票形式不能為空。',
          },
          {
            validator: (_, value) => {
              if (!value) return Promise.resolve();
              if (value[0] === '（個人）電子發票' && value.length < 2) {
                return Promise.reject(new Error("請確認選擇發票形式。"));
              }
              return Promise.resolve();
            }
          }
        ]}
        onClick={() => setVisible(true)}>
        <Cascader
          options={invoiceTypes}
          visible={visible}
          onClose={() => {
            setVisible(false)
          }}
          onConfirm={setInvoiceType}
        >
          {items => {
            if (items.every(item => item === null)) {
              return <span style={{ color: 'var(--adm-color-text-secondary)' }}>請選擇發票形式</span>
            } else {
              return <span style={{ color: 'var(--adm-color-text-secondary)' }}>
                {items.map(item => item ?.label ?? '未選擇').join('-')}
              </span>
            }
          }}
        </Cascader>
      </Form.Item>
      {invoiceType.length > 0 && <div>
        {invoiceType[1] === '手機載具' && <PhoneOptions form={form} />}
        {invoiceType[1] === '自然人憑證' && <NaturalPersonOptions form={form} />}
        {invoiceType[1] === '發票捐贈' && <InvoiceDonationOptions form={form} />}
        {invoiceType[0].indexOf('公司') > 0 && <CompanyOptions />}
        {checkInvoiceType() && <Form.Item
          name="invoiceEmail"
          label={'電子信箱'}
          rules={[
            {
              required: true,
              message: '電子信箱不能為空。',
            },
            { type: 'email', warningOnly: true, message: '電子信箱格式不正確。' }
          ]}
        >
          <Input />
        </Form.Item>}
      </div>}
    </Form>
  </>
}

export default InvoiceFormPage