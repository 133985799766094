import React from 'react'
import { ResultPage, Button } from 'antd-mobile-v5'
import { WhiteSpace } from 'antd-mobile'
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import ResultPageContent from './ResultPageContent'
import { getParameterByName } from '../../components/common/utils'
import styles from './applicationSubmitResult.module.css'

const ApplicationSubmitResult = () => {

  const { state: { code, paid, paymentType } } = useLocation()
  const { push } = useHistory()
  const type = getParameterByName('type')

  return <ResultPage
    status='success'
    title='提交成功'
    description={`申请号：${code}`}
  >
    <ResultPageContent 
      type={type} 
      code={code} 
      paymentType={paymentType} 
      paid={paid} 
    />
    <WhiteSpace />
    <Button 
      block 
      color="primary" 
      fill='outline' 
      onClick={() => push('/myProfile')}>
        <FormattedMessage id="common-back" />
      </Button>
  </ResultPage>
}

export default ApplicationSubmitResult