import React from 'react'
import { Button, Space } from 'antd-mobile-v5'
import { formatMoney } from '../../components/common/utils';
import { FormattedMessage } from 'react-intl'
import { usePicOrder } from '../../services/hooks';

import styles from './PriceList.module.css'

const Coupon = ({ selectedCoupon, setSelectedCoupon, setVisiable, setOrder, submitData }) => {

  const { run } = usePicOrder({
    onSuccess: data => {
      setOrder(data)
      setVisiable(false)
    },
  });

  const couponConditions = (coupon) => {
    if (coupon.couponType === 'RENEWAL_2022') {
      return <FormattedMessage id="coupon-conditions" />
    }
    if (coupon.couponType === 'RENEWAL_2023') {
      return <FormattedMessage id="coupon-conditions-2023" />
    }
  }

  const clickSelectCoupon = (coupon) => {
    if (!coupon.available || coupon.expired) return
    const newCoupons = selectedCoupon.map(c => {
      if(JSON.stringify(c) === JSON.stringify(coupon)) {
        return { ...c, selected: !c.selected }
      }
      if (c.couponType === coupon.couponType) {
        return c
      }
      return { ...c, selected: false }
    })
    setSelectedCoupon(newCoupons)
  }

  const skipCoupon = () => {
    const newCoupons = selectedCoupon.map(c => {
      return { ...c, selected: false }
    })
    run({ ...submitData, couponDTOS: newCoupons })
    setSelectedCoupon(newCoupons)
  }

  return <div className={styles.couponWrapper}>
    <div className={styles.couponList}>
      {selectedCoupon.map(coupon => <div className={styles.card + ' ' + (coupon.selected && styles.selected)} onClick={() => clickSelectCoupon(coupon)}>
        <div className={styles.tab}>{coupon.discount ? <FormattedMessage id="coupon" /> : <FormattedMessage id="coupon-voucher" />}</div>
        <div className={styles.content}>
          <div className={styles.left}>{coupon.title}</div>
          {coupon.discount && <div className={styles.right}>
            <span className={styles.amount}>{formatMoney(coupon.discount, coupon.discount)}</span>
          </div>}
        </div>
        {coupon.expired ? <div className={styles.conditions}>
          <span style={{ color: 'red' }}>
            <FormattedMessage id="coupon-useless" />
            <FormattedMessage id="coupon-expired" />
          </span>
        </div> : <div className={styles.conditions}>
          {coupon.available 
            ? couponConditions(coupon) 
            : <span style={{ color: 'red' }}><FormattedMessage id="coupon-useless" />{coupon.description}</span>}
        </div>}
      </div>)}
    </div>
    <Space direction='vertical' block>
      <Button
        block
        shape='rounded'
        onClick={skipCoupon}>
        <FormattedMessage id="coupon-not-to-use" />
      </Button>
      <Button 
        block 
        shape='rounded' 
        color='primary'
        onClick={() => run({ ...submitData, couponDTOS: selectedCoupon })}>
        <FormattedMessage id="common-confirm" />
      </Button>
    </Space>
  </div>
}

export default Coupon