import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl';
import Header from '../../components/FormTools/FormHeader'
import { CheckList, Form, SpinLoading } from 'antd-mobile-v5';
import UploadOssFile from '../../components/FormTools/UploadOssFile'
import { useGetOssSignature, useGetOtherAppsTypeList, usePayOtherApps, useSubmitOtherApps } from '../../services/hooks'
import { useLanguage } from '../../services/hooks/user';
import { removeExpires } from '../../components/common/utils'
import Status from './Status'

const PocketsApplication = () => {

  const lang = useLanguage();
  const { push } = useHistory()
  const { formatMessage } = useIntl(); 
  const { state: { detail } } = useLocation()
  const [form] = Form.useForm();
  const required = true
  const { data: ossSignature } = useGetOssSignature('OTHER');
  const { data: appTypes, loading: typeLoading } = useGetOtherAppsTypeList(true);
  const { runAsync: submit, loading: submitting } = useSubmitOtherApps();

  useEffect(() => {
    if(detail) form.setFieldsValue({ files: detail.files, memberApplicationType: [detail.memberApplicationType] })
  }, [detail])

  const filename = (dir, type) => `${dir}${type}/`

  const changeFiles = (files) => {
    form.setFieldsValue({ files })
  }

  const onFinish = (values) => {
    const data = { memberApplicationType: values.memberApplicationType[0], files: removeExpires(values.files) }
    if (detail && detail.code) data.code = detail.code
    submit(data).then(res => {
      if (res.code) push('/pockets_apps_result', { detail: res })
    })
  }

  const formatFileList = () => {
    const newFileList = detail.files.map(file => {
      return { url: file }
    })
    return newFileList
  }

  const isFormEditable = () => {
    if (!detail || detail && detail.status === 'ADMIN_NOT_APPROVED') return true
    else return false
  }

  return <>
    <Header
      text={<FormattedMessage id="pocket-apps-title" />}
      code={detail?.code}
    />
    <Form
      disabled={submitting}
      form={form}
      validateTrigger="onSubmit"
      onFinish={onFinish}
    >
      <Form.Item 
        name='memberApplicationType' 
        label={formatMessage({ id: "pocket-apps-type" })}
        rules={
          required && [
            {
              required: true,
              message: formatMessage({ id: 'pocket-apps-type-error' }),
            },
          ]
        }>
        {typeLoading ? <SpinLoading color='primary' />
          : <CheckList readOnly={!isFormEditable()}>
          {appTypes && appTypes.map(type => <CheckList.Item value={type.type} key={type.id}>{type['name_'+lang]}</CheckList.Item>)}
        </CheckList>}
      </Form.Item>
      {ossSignature && <Form.Item 
        name='files' 
        label={formatMessage({ id: "si-upload-btn" })}
        form={form}
        rules={
          required && [
            {
              required: true,
              message: formatMessage({ id: 'form-upload-error' }),
            },
          ]
        }
      >
        <UploadOssFile
          form={form}
          fileName={filename(ossSignature.dir, 'other')}
          ossSignature={ossSignature}
          onSuccess={changeFiles}
          editable={isFormEditable()}
          fileList={detail ? formatFileList() : null}
        />
      </Form.Item>}
      <Status
        status={detail ?.status}
        form={form}
        paymentMethod={detail ?.paymentMethod}
        code={detail ?.code}
        applicationType={detail ?.memberApplicationType}
        submitting={submitting}
        note={detail?.note} />
    </Form>
  </>
}

export default PocketsApplication