import React from 'react';
import {Flex, List} from 'antd-mobile';
import { formatMoney } from '../../components/common/utils';

import styles from '../../pages/PriceList/PriceList.module.css';

const OrderItem = ({ item, orderPrice = ''  }) => {

  return (
    <List.Item align="bottom" thumb={item.picUrl} wrap>
          {item.title}<List.Item.Brief>
            <Flex justify="between">
            <div><span className={styles.crossPrice}>
                 {formatMoney(item.price, item.currencyType)}
               </span>
               <span className={styles.price}>
                 {orderPrice ? formatMoney(orderPrice, item.currencyType) : ''}
               </span></div>
               <span>{'x' + item.number}</span>
            </Flex>
               </List.Item.Brief>
    </List.Item>
    // <div className={styles.saleItem}>
    //   <div className={styles.goods}>
    //     <div className={styles.goodsImg}>
    //       <img alt={item.title} src={item.picUrl} />
    //     </div>
    //     <div className={styles.goodsInfo}>
    //       <div className={styles.title}>{item.title}</div>
    //       <div className={styles.bottom}>
    //         <div>
    //           <span className={styles.crossPrice}>
    //             {formatMoney(item.price, item.currencyType)}
    //           </span>
    //           <span className={styles.price}>
    //             {orderPrice ? formatMoney(orderPrice, item.currencyType) : ''}
    //           </span>
    //         </div>
    //         <div>x{item.number}</div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default OrderItem;
