import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl';
import { showModal, getSubmitOssFormat, removeExpires } from '../../components/common/utils'
import ApplicantDetailStatus from '../../components/common/ApplicantDetailStatus'
import { isMember } from '../../reducers/selectors'
import { usePayBasicMermaid, useSubmitBasicMermaid } from '../../services/hooks'
import { useHistory } from 'react-router-dom';

const ApplicationStatus = ({
  detail,
  formData,
  checkAge,
  checked,
  projectsStatus,
  certComplete,
  addressComplete
}) => {

  const { formatMessage } = useIntl()
  const { push } = useHistory()
  const { runAsync: toPay } = usePayBasicMermaid();
  const { runAsync: submitBasicMermaid, loading: submitting } = useSubmitBasicMermaid();
  const { signatureUrl, ismember } = useSelector((state) => ({
    signatureUrl: state.ui.signatureUrl,
    ismember: isMember(state)
  }));

  const verifyData = () => {
    const {
      cdinstructorNumber,
      languageId,
      finishDate,
      regionId,
      address,
      courseLocation
    } = formData
    const regionStatus = ismember ? true : regionId
    return cdinstructorNumber &&
      addressComplete &&
      languageId &&
      finishDate &&
      regionStatus &&
      address &&
      courseLocation &&
      checkAge &&
      projectsStatus &&
      checked &&
      signatureUrl &&
      certComplete
  }

  const submit = () => {
    if (!verifyData()) {
      showModal(
        '',
        formatMessage({ id: 'dm-form-submit-error-info' }),
        () => { },
        () => { },
        formatMessage({ id: 'common-comfirmBtn' }),
        formatMessage({ id: 'common-cancel' })
      );
      return
    }
    const { finishDate, cdinstructorNumber, code, regionId, ...data } = formData
    let submitData = {
      ...data,
      efrfiles: data.efrfiles ? removeExpires(data.efrfiles) : [],
      padiadvancedMermaidFiles: data.padiadvancedMermaidFiles ? removeExpires(data.padiadvancedMermaidFiles) : [],
      otherFiles: removeExpires(data.otherFiles),
      courseDate: finishDate,
      trainerInstructorNumber: cdinstructorNumber,
      handwrittenSignatureUrl: getSubmitOssFormat(signatureUrl),
    }
    if (code) submitData.code = code
    if (!ismember) submitData.regionId = regionId
    submitBasicMermaid(submitData).then(res => {
      if (res.code) {
        push('/application_submit_success?type=PADI_BASIC_MERMAID_INSTRUCTOR', { code: res.code, paid: res.paid, paymentType: 'MEMBER_APPLICATION_PADI_BASIC_MERMAID_I' });
      }
    })
  }

  return <ApplicantDetailStatus
    detail={detail}
    idType={'trainer'}
    submitFn={submit}
    payFn={toPay}
    applicationType={'PADI_BASIC_MERMAID_INSTRUCTOR'}
    payApplicationType={'MEMBER_APPLICATION_PADI_BASIC_MERMAID_I'} 
    loading={submitting}
  />
}

export default ApplicationStatus