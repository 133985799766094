import { combineReducers } from 'redux'
import {createSlice} from "@reduxjs/toolkit";

export const efriDetailSlice = createSlice({
  name: "efriDetail",
  initialState: null,
  reducers: {
    efriDetailRequest: {
      reducer(){
        return {loading: true}
      }
    },
    efriDetailSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    efriDetailFailed: {
      reducer(){
        return {}
      }
    },
    uploadEfrOssFile: {
      reducer(state){
        return state
      }
    },
    uploadEfrOssFileRequest: {
      reducer(state){
        return state
      }
    },
    uploadEfrOssFileSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return state
      }
    },
    uploadEfrOssFileFailed: {
      reducer(state){
        return state
      }
    },
    getEfrOssFileRequest: {
      reducer(state){
        return state
      }
    },
    getEfrOssFileSuccess: {
      reducer(state, action) {
        const data = action.payload;
        const url = data.replace('https://files.padi.com.cn/', '')
        const { efrfiles, ...detail } = state
        return { efrfiles: efrfiles ? [ data, ...efrfiles ] : [data], ...detail }
      }
    },
    getEfrOssFileFailed: {
      reducer(state){
        return state
      }
    },
    removeEfrFile: {
      reducer(state, action) {
        const data = action.payload;
        const url = data.replace('https://files.padi.com.cn/', '')
        const { efrfiles, ...detail } = state
        const newEfrfiles = efrfiles.filter(item => item !== data)
        return { efrfiles: newEfrfiles, ...detail }
      }
    }
  }
})

export const { 
  efriDetailRequest,
  efriDetailSuccess,
  efriDetailFailed,
  uploadEfrOssFile,
  uploadEfrOssFileRequest,
  uploadEfrOssFileSuccess,
  uploadEfrOssFileFailed,
  getEfrOssFileRequest,
  getEfrOssFileSuccess,
  getEfrOssFileFailed,
  removeEfrFile } = efriDetailSlice.actions

export const efriDetail = {
  request: (data) => efriDetailRequest(data),
  success: (data) => efriDetailSuccess(data),
  failure: (error) => efriDetailFailed(error),
}

export const uploadEfrAttachFile = {
  request: () => uploadEfrOssFileRequest(),
  success: (data) => uploadEfrOssFileSuccess(data),
  failure: (error) => uploadEfrOssFileFailed(error),
}

export const getEfrOssFile = {
  request: () => getEfrOssFileRequest(),
  success: (data) => getEfrOssFileSuccess(data),
  failure: (error) => getEfrOssFileFailed(error),
}

export const efrtListSlice = createSlice({
  name: "efrtList",
  initialState: {},
  reducers: {
    skipEfrtApplicationsPage: {
      reducer(state, action) {
        return state
      }
    },
    efrtListRequest: {
      reducer(state){
        return state
      }
    },
    efrtListSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    efrtListFailed: {
      reducer(state){
        return state
      }
    }
  }
});

export const { efrtListRequest, efrtListSuccess, efrtListFailed, skipEfrtApplicationsPage } = efrtListSlice.actions

export const getEfrtListRequest = {
  request: () => efrtListRequest(),
  success: (data) => efrtListSuccess(data),
  failure: (error) => efrtListFailed(error),
}

export const efrtDetailSlice = createSlice({
  name: "efrtDetail",
  initialState: {},
  reducers: {
    efrtDetailRequest: {
      reducer(){
        return {}
      }
    },
    efrtDetailSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    efrtDetailFailed: {
      reducer(){
        return {}
      }
    }
  }
})

export const { 
  efrtDetailRequest, 
  efrtDetailSuccess, 
  efrtDetailFailed
} = efrtDetailSlice.actions

export const efrtDetail = {
  request: () => efrtDetailRequest(),
  success: (data) => efrtDetailSuccess(data),
  failure: (error) => efrtDetailFailed(error),
}

// export const ieCandidateListSlice = createSlice({
//   name: "ieCandidate",
//   initialState: {},
//   reducers: {
//     ieCandidateRequest: {
//       reducer(){
//         return {}
//       }
//     },
//     ieCandidateSuccess: {
//       reducer(state, action) {
//         const data = action.payload;
//         const { owsis, ...detail } = data
//         const newOwsis = owsis.map((item) => {
//           return { paymentType: 'CANDIDATE', ...item }
//         })
//         return {owsis: newOwsis, ...detail}
//       }
//     },
//     ieCandidateFailed: {
//       reducer(){
//         return {}
//       }
//     },
//     checkedCandidate: {
//       reducer(state, action) {
//         const { checked, ieIndex } = action.payload
//         const { owsis, ...detail } = state
//         const newOwsis = owsis.map((item, index) => {
//           if(index === ieIndex) {
//             const { radio, ...owsiDetail } = item
//             return { radio: checked, ...owsiDetail }
//           }
//           return item
//         })
//         return {owsis: newOwsis, ...detail}
//       }
//     },
//     setPaymentType: {
//       reducer(state, action) {
//         const { type, ieIndex } = action.payload
//         const { owsis, ...detail } = state
//         const newOwsis = owsis.map((item, index) => {
//           if(index === ieIndex) {
//             const { paymentType, ...payment } = item
//             return { paymentType: type, ...payment }
//           }
//           return item
//         })
//         return {owsis: newOwsis, ...detail}
//       }
//     },
//     submitIeList: {
//       reducer(state){
//         return state
//       }
//     },
//     submitIeListRequest: {
//       reducer(state){
//         return state
//       }
//     },
//     submitIeListSuccess: {
//       reducer(state, action) {
//         const data = action.payload;
//         return state
//       }
//     },
//     submitIeListFailed: {
//       reducer(state){
//         return state
//       }
//     },
//   }
// })

// export const { 
//   ieCandidateRequest, 
//   ieCandidateSuccess, 
//   ieCandidateFailed, 
//   checkedCandidate, 
//   setPaymentType,
//   submitIeList,
//   submitIeListRequest,
//   submitIeListSuccess,
//   submitIeListFailed } = ieCandidateListSlice.actions

// export const ieCandidate = {
//   request: () => ieCandidateRequest(),
//   success: (data) => ieCandidateSuccess(data),
//   failure: (error) => ieCandidateFailed(error),
// }

// export const submitIeRequest = {
//   request: () => submitIeListRequest(),
//   success: (data) => submitIeListSuccess(data),
//   failure: (error) => submitIeListFailed(error),
// }

const efriReducer = combineReducers({
  efrtList: efrtListSlice.reducer,
  efriDetail: efriDetailSlice.reducer,
  efrtDetail: efrtDetailSlice.reducer
})

export default efriReducer