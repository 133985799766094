import React from 'react';
import styles from './Tag.module.css';

const Tag = ({ selected = false, onClick = () => {}, children }) => {
  const classes = selected
    ? styles.tag + ' ' + styles['tag-selected']
    : styles.tag;
  return <div className={classes} onClick={() => onClick()}>{children}</div>;
};

export default Tag;
