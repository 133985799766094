import React, { useEffect } from "react";
import { Button, Modal } from 'antd-mobile-v5'
import { WhiteSpace } from 'antd-mobile'
import { Html5Qrcode } from "html5-qrcode";
import { useCheckRegulatorCoupon } from '../../services/hooks'
import { useShowMessage } from '../../services/hooks/ui';

const qrConfig = { fps: 10, qrbox: { width: 300, height: 300 } };
const brConfig = { fps: 10, qrbox: { width: 300, height: 300 } };
let html5QrCode;

const DecodeQrcode = (props) => {

  const showMessage = useShowMessage();
  const { run, data, loading } = useCheckRegulatorCoupon({
    onSuccess: () => {
      if (data.check) {
        handleStop();
        showMessage('regulator-coupon-success')
      }
      else {
        handleStop();
        showMessage('regulator-coupon-fail')
      }
    },
    onError: () => {
      handleStop();
    }
  });

  useEffect(() => {
    html5QrCode = new Html5Qrcode("reader");
  }, []);

  const handleClickAdvanced = () => {
    const qrCodeSuccessCallback = async (decodedText, decodedResult) => {
      await handleStop()
      if (decodedText) {
        Modal.confirm({
          content: '确认核销该优惠券？',
          onConfirm: async () => {
            run(decodedText)
          },
        })
        return
      }
    };
    html5QrCode.start(
      { facingMode: "environment" },
      props.type === "QR" ? qrConfig : brConfig,
      qrCodeSuccessCallback
    );
  };

  const handleStop = () => {
    try {
      html5QrCode
        .stop()
        .then((res) => {
          html5QrCode.clear();
        })
        .catch((err) => {
          console.log(err.message);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <WhiteSpace />
      <div id="reader" width="100%" />
      <Button
        block
        color="primary"
        loading={loading}
        onClick={() => {if(!loading) handleClickAdvanced()}}
      >
        扫码核销
      </Button>
      <WhiteSpace />
      <Button
        block
        color="primary"
        disabled={loading}
        onClick={() => handleStop()}
      >
        停止扫码
      </Button>
    </div>
  );
};
export default DecodeQrcode;
