import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl';
import { showModal, getSubmitOssFormat, removeExpires } from '../../components/common/utils'
import ApplicantDetailStatus from '../../components/common/ApplicantDetailStatus'
import { isMember } from '../../reducers/selectors'
import { usePayMermaid, useSubmitMermaid } from '../../services/hooks'
import { useHistory } from 'react-router-dom';

const ApplicationStatus = ({
  detail,
  formData,
  checkAge,
  checked,
  addressComplete,
  fileComplete
}) => {

  const { formatMessage } = useIntl()
  const { push } = useHistory()
  const { runAsync: toPay } = usePayMermaid();
  const { runAsync: submitMermaid, loading: submitting } = useSubmitMermaid();
  const { signatureUrl, ismember } = useSelector((state) => ({
    signatureUrl: state.ui.signatureUrl,
    ismember: isMember(state)
  }));

  const verifyData = () => {
    const {
      cdinstructorNumber,
      languageId,
      finishDate,
      regionId,
      address,
      courseLocation
    } = formData
    const regionStatus = ismember ? true : regionId
    return cdinstructorNumber &&
      addressComplete &&
      languageId &&
      finishDate &&
      regionStatus &&
      address &&
      courseLocation &&
      checkAge &&
      checked &&
      signatureUrl &&
      fileComplete
  }

  const submit = () => {
    if (!verifyData()) {
      showModal(
        '',
        formatMessage({ id: 'dm-form-submit-error-info' }),
        () => { },
        () => { },
        formatMessage({ id: 'common-comfirmBtn' }),
        formatMessage({ id: 'common-cancel' })
      );
      return
    }
    const { finishDate, cdinstructorNumber, code, regionId, ...data } = formData
    let submitData = {
      ...data,
      efrfiles: data.efrfiles ? removeExpires(data.efrfiles) : [],
      padiadvancedMermaidFiles: data.padiadvancedMermaidFiles ? removeExpires(data.padiadvancedMermaidFiles) : [],
      otherFiles: data.otherFiles ? removeExpires(data.otherFiles) : [],
      courseDate: finishDate,
      trainerInstructorNumber: cdinstructorNumber,
      handwrittenSignatureUrl: getSubmitOssFormat(signatureUrl),
    }
    if (!ismember) submitData.regionId = regionId
    if (code) submitData.code = code
    submitMermaid(submitData).then(res => {
      if (res.code) {
        push('/application_submit_success?type=PADI_MERMAID_INSTRUCTOR', { code: res.code, paid: res.paid, paymentType: 'MEMBER_APPLICATION_PADI_MERMAID_I' });
      }
    })
  }

  return <ApplicantDetailStatus
    detail={detail}
    idType={'trainer'}
    submitFn={submit}
    payApplicationType={'MEMBER_APPLICATION_PADI_MERMAID_I'}
    payFn={toPay}
    applicationType={'PADI_MERMAID_INSTRUCTOR'}
    loading={submitting}
  />
}

export default ApplicationStatus