import React from 'react'
import { useSelector } from 'react-redux';
import { WingBlank, List, WhiteSpace } from 'antd-mobile';
import { specialtyMapping, siAppStatus } from '../../components/common/utils'
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { getCookie } from '../../components/common/cookie'
import PaymentBtn from '../../components/FormTools/PaymentBtn'
import Header from '../../components/common/FormHeader'
import { Button } from 'antd-mobile-v5'
import { showModal } from "../../components/common/utils";
import { useDeleteMemberApplication, usePaySi } from '../../services/hooks';

import styles from './SpecialtyInstructor.module.css'
import Notification from '../../components/Notification';

const Item = List.Item;

const SpecialtyAppListPage = () => {

  const history = useHistory();
  const { formatMessage } = useIntl()
  const lang = getCookie('language')
  const { runAsync: toPay } = usePaySi();

  const { siAppList, userInfo } = useSelector(state => ({
    siAppList: state.ui.siAppList,
    userInfo: state.data.userInfo
  }));

  const { loading, run } = useDeleteMemberApplication({
    onSuccess: () => {
      history.push('/siAppList');
    },
  });

  const deleteApplication = () => {
    showModal(
      '', 
      formatMessage({ id: "app-cancel" }), 
      () => run({ code: siAppList.code, type: siAppList.memberApplicationType }), 
      () => { }, 
      formatMessage({ id: 'common-comfirmBtn' }), 
      formatMessage({ id: 'common-cancel' })
    )
  }

    return userInfo ? <>
    <Notification position="MEMBER_APP" />
    <Header 
      text={<FormattedMessage id="si-form-title" />}
      rightContent={siAppList && !siAppList.code && <i className="fa fa-plus-square-o fa-lg" style={{ color: '#fff' }} onClick={() => history.push('/specialtyApp')}></i>} />
    {siAppList && siAppList.code ? <List renderHeader={() => `${formatMessage({id: 'si-app-number'})}${siAppList.code}`} className={styles.siList}>
      {siAppList.siApplicationDetailInProgressDTOS.map(item => (
      <Item 
        wrap 
        extra={item.status === 'ADMIN_NOT_APPROVED' ? formatMessage({id: 'si-reSubmit'}) : formatMessage({id: 'si-check'})} 
        arrow="horizontal"
        onClick={() => history.push(`/siAppDetail?credentialId=${item.credentialId}&code=${siAppList.code}`)}>
        <div className={styles.siBox}>
          <span>
            <FormattedMessage id="si-sp" />
          </span>
          {specialtyMapping[item.credentialId][lang]}
        </div>
        <div className={styles.siBox}>
          <span>
            <FormattedMessage id="si-app-status" />
          </span>
          <span className={item.status === 'ADMIN_NOT_APPROVED' ? styles.refuse : styles.process}>
            {(item.status === 'WAIT_ADMIN_APPROVED' || item.status === 'ADMIN_APPROVED') && !siAppList.paymentMethod 
            ? siAppStatus[siAppList.memberApplicationType][lang]['WAIT_PAY'] 
            : siAppStatus[siAppList.memberApplicationType][lang][item.status]}
          </span>
        </div>
        {item.status === 'ADMIN_NOT_APPROVED' && <div className={styles.siBox}>
          <span>
            <FormattedMessage id="si-refuse-reason" />
          </span>
          <span className={styles.refuse}>
            {item.note}  
          </span>
        </div>}
      </Item>))}
    </List> : <div className={styles.noList}>
      <img src={require('../../assets/images/no-list.png')} alt="no list" />
    </div>}
    <WhiteSpace />
    {siAppList && 
    siAppList.siApplicationDetailInProgressDTOS && 
    !siAppList.paymentMethod && 
    <WingBlank>
      <PaymentBtn 
        code={siAppList.code} 
        paymentFn={toPay}
        payApplicationType={'MEMBER_APPLICATION_SI'} 
      />
    </WingBlank>}
      {siAppList && siAppList.code && !siAppList.paymentMethod && (
      <Button
        block
        color="primary"
        fill='none'
          onClick={deleteApplication}
        loading={loading}
        disabled={loading}
      >
        <FormattedMessage id="app-delete" />
      </Button>
    )}
    <WhiteSpace size="xl" />
  </> : null
}

export default SpecialtyAppListPage