import React from 'react'
import { useSelector } from 'react-redux';
import { InputItem } from 'antd-mobile'
import { FormattedMessage, useIntl } from 'react-intl';
import SearchStore from '../../components/common/SearchStore';
import DatePickerComponent from '../../components/common/DatePicker'
import SearchInstructor from '../../components/common/SearchInstructor';
import CardLayout from '../../pages/DiveMasterForm/CardLayout';

const TrainingInfo = ({
  editable,
  updateFormData,
  cdinstructorNumber,
  storeNumber,
  finishDate,
  courseLocation
}) => {

  const { formatMessage } = useIntl()
  const { detail } = useSelector(state => ({
    detail: state.mermaid.mermaidiDetail
  }));

  const isTrainingInfoComplete = cdinstructorNumber && finishDate && courseLocation

  return <CardLayout
    title={<FormattedMessage id="dm-form-training-info" />}
    status={isTrainingInfoComplete}
  >
    <SearchInstructor
      id={"trainer"}
      type={"PADIMermaidT"}
      editable={editable}
      instructor={detail.instructorInfoDTO}
      instructorNumber={cdinstructorNumber}
      onSuccess={(result) => {
        updateFormData({ cdinstructorNumber: result.instructorNumber });
      }}
    />
    {(editable || storeNumber) && (
      <SearchStore
        editable={editable}
        placeholder={formatMessage({ id: 'dm-form-store-warning' })}
        store={detail.storeInfoDTO}
        storeNumber={storeNumber}
        onSuccess={(result) => {
          updateFormData({ storeNumber: result.storeNumber });
        }}
      />
    )}
    <InputItem
      clear
      editable={editable}
      placeholder={formatMessage({ id: 'si-course-location' })}
      value={courseLocation || ''}
      onChange={(v) => updateFormData({ courseLocation: v })}
    >
      <FormattedMessage id="si-course-location-title" />
    </InputItem>
    <DatePickerComponent
      extra={formatMessage({ id: 'common-select' })}
      title={formatMessage({ id: 'efri-course-date-error' })}
      disabled={!editable}
      maxDate={new Date()}
      onChange={date => updateFormData({ finishDate: date })}
      value={finishDate}
    >
      <FormattedMessage id="common-course-date" />
    </DatePickerComponent>
  </CardLayout>
}

export default TrainingInfo