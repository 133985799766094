import { Card, Space, Image, Loading } from 'antd-mobile-v5';
import React, { useState, useEffect } from 'react';
import { useAddToCart, useShopCart, useCardOptions } from '../../services/hooks';
import CartFooter from '../../components/Cart/CartFooter';
import CartHeader from '../../components/Cart/CartHeader';
import EmptyCart from '../../components/Cart/EmptyCart';
import Goods from '../../components/Cart/Goods';
import LoadingStatus from '../../components/FormTools/Loading';
import { getPostage } from '../BuyCards/cardOptionUtils';

const Cart = ({ cart = [], checked = [], remove, toggleChecked, cardOptions }) => {
  return  <Card
      style={{ margin: '0 12px 30px' }}
      title={
        <Space>
          <Image width={20} src={require('../../assets/images/sm-logo.png')} />
          PADI 公益卡
        </Space>
      }
    >
      {cart.length > 0 ? <>{cart.map((item) => <Goods
          remove={remove}
          checked={
            !!checked.find(
              (c) => c.ecardId === item.ecardId && c.skuId === item.skuId
            )
          }
          cardOptions={cardOptions}
          cartItem={item}
          key={item.skuId + item.ecardId}
          toggleChecked={toggleChecked}
        />)}
      
      <div>邮费：¥ {getPostage(checked) / 100}</div></>
       : 
    <EmptyCart />}
    </Card>
};

const CartPage = () => {
  const { loading: cardOptionsLoading, data: cardOptions} = useCardOptions();
  
  const {
    loading: shopCartLoading,
    run: loadCart,
    mutate,
    data: cartData,
  } = useShopCart({
    manual: true,
    onSuccess: ({ data }) => {
      setChecked(data);
    },
  });
  const { run: updateCart } = useAddToCart();
  const cart = cartData ? cartData.data : [];
  const [checked, setChecked] = useState([...cart]);

  useEffect(() => loadCart(), [loadCart]);
  const toggleChecked = (item) => {
    if (
      checked.find((c) => c.ecardId === item.ecardId && c.skuId === item.skuId)
    ) {
      setChecked(
        checked.filter(
          (c) => c.ecardId !== item.ecardId || c.skuId !== item.skuId
        )
      );
    } else {
      setChecked([item, ...checked]);
    }
  };

  const remove = (item) => {
    setChecked(
      checked.filter(
        (c) => c.ecardId !== item.ecardId || c.skuId !== item.skuId
      )
    );
    const updatedCart = cart.filter(
      (c) => c.ecardId !== item.ecardId || c.skuId !== item.skuId
    );
    mutate({ data: updatedCart });
    updateCart(updatedCart);
  };

  const toggleCheckAll = (checked) => {
    if (checked) {
      setChecked([...cart]);
    } else {
      setChecked([]);
    }
  };

  if(shopCartLoading || cardOptionsLoading) return <LoadingStatus />;
  return (
    <div style={{paddingBottom: '50px'}}>
      <CartHeader total={cart.length} />
      {shopCartLoading ? (
        <Loading />
      ) : (
        <Cart
          cart={cart}
          cardOptions={cardOptions}
          checked={checked}
          toggleChecked={toggleChecked}
          remove={remove}
        />
      )}
      <CartFooter
        cardOptions={cardOptions}
        checkedList={checked}
        toggleCheckAll={toggleCheckAll}
        shoppingCart={cart}
      />
    </div>
  );
};

export default CartPage;
