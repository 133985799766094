import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import { ProtectedRoute } from '../components/ProtectedRoute/ProtectedRoute';
const EmailLoginPage = lazy(() => import('../pages/emailLogin/emailLogin'));
const VerifyEmailPage = lazy(() => import('../pages/VerifyEmail/VerifyEmail'));
const SetMobilePage = lazy(() => import('../pages/SetMobile/SetMobile'));
const SetPasswordPage = lazy(() => import('../pages/SetPassword/SetPassword'));

const UserAdminRoutes = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Route path="/verifyEmail" component={VerifyEmailPage} />
    <Route path="/setMobile" component={SetMobilePage} />
    <Route path="/setPassword" component={SetPasswordPage} />
  </Suspense>
)

export default UserAdminRoutes