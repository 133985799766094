import React, {useState} from 'react'
import { useDispatch } from 'react-redux';
import ProfileInfo from '../../components/Login/ProfileInfo'
import { Button, Toast, WhiteSpace, WingBlank } from 'antd-mobile';
import { showAlert, formatDate } from '../../components/common/utils';
import { FormattedMessage, useIntl } from 'react-intl';
import { postProfile } from '../../actions'

import '../../components/Login/Login.css';

const SetProfilePage = () => {
  const dispatch = useDispatch()
  const {formatMessage} = useIntl()

  const showErrorAlert = ( message, btnLabel) => {
    Toast.hide();
    setPending(false)
    showAlert(message, btnLabel);
  }

  const [pending, setPending] = useState(false);
  const [profile, setProfile] = useState({
    birthDate: '',
    firstName: '',
    lastName: '',
    gender: '',
  })
  const handleSubmit = (event) => {
    event.preventDefault();
    verifyInfo();
  }

  const verifyInfo = () => {
    setPending(true);
    if(!profile.firstName) {
      showErrorAlert(formatMessage({id: 'setProfile-errorName'}), formatMessage({id: 'common-close'}));
      return;
    }else if(!profile.lastName) {
      showErrorAlert(formatMessage({id: 'setProfile-errorName'}), formatMessage({id: 'common-close'}));
      return;
    }else if(!profile.gender) {
      showErrorAlert(formatMessage({id: 'setProfile-errorGender'}), formatMessage({id: 'common-close'}));
      return;
    }else if(!profile.birthDate) {
      showErrorAlert(formatMessage({id: 'setProfile-errorBirthDate'}), formatMessage({id: 'common-close'}));
      return;
    }
    dispatch(postProfile({...profile, birthDate: formatDate(profile.birthDate, 'MM/DD/YYYY')}))
    setPending(false);
  }


  return (
    <div className="home-page">
      <div className="sign-in"><FormattedMessage id="setProfile-title" /></div>
<ProfileInfo profile={profile} setProfile={setProfile} pending={pending} style={{width: '100%'}}/>
        <WhiteSpace />
        <WingBlank>
        <Button
        type="primary"
        className="btnConfirm"
        onClick={(e) => handleSubmit(e)}>
          <FormattedMessage id="common-confirm" />
      </Button>
        </WingBlank>
    </div>
  )
}

export default SetProfilePage