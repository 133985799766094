import React from 'react'
import { FormattedMessage } from 'react-intl';
import AgreementCheckbox from '../../components/common/AgreementCheckbox'

const Agreement = ({ checked, setChecked, type }) => {
  return <AgreementCheckbox setChecked={(checked => setChecked(checked))} checked={checked}>
    {type === 'SI_1' ? <FormattedMessage id="si-agreement-cd-instructor" /> : <FormattedMessage id="si-agreement-content" />}
  </AgreementCheckbox>
}

export default Agreement