import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl, FormattedMessage } from 'react-intl';
import { WingBlank, Button, WhiteSpace } from 'antd-mobile';
import { getParameterByName, showAlert, showModal } from '../../components/common/utils';
import { storeInfoWithoutAuth } from '../../services/api';
import Header from '../../components/common/FormHeader'
import EmailVerify from './EmailVerify'
import MobileVerify from './MobileVerify'
import SetPassword from './SetPassword'
import ProfileInfo from './ProfileInfo'
import { submitDsd } from '../../actions'
const DsdRegister = () => {
  const store = getParameterByName('store')
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const [storeInfo, setStoreInfo] = useState()
  const [formData, setFormData] = useState({
    email: '',
    emailCode: '',
    countryCode: '86',
    mobile: '',
    mobileCode: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    gender: '',
    birthDate: '',
    address: '',
    stateId: '',
    storeNumber: store,
    errors: {}
  })
  const searchStore = async () => {
    try {
      const response = await storeInfoWithoutAuth(store);
      if (response.error) {
        showAlert(
          response.error.clientMsg,
          formatMessage({ id: 'common-close' })
        );
      } else {
        setStoreInfo(response.data)
      }
    } catch (e) {
      showAlert(
        formatMessage({ id: 'common-errorStatus' }),
        formatMessage({ id: 'common-close' })
      );
    } finally {
    }
  };
  useEffect(() => {
    searchStore()
  }, [])
  function verify() {
    const {
      email,
      emailCode,
      mobile,
      countryCode,
      mobileCode,
      password,
      confirmPassword,
      firstName,
      lastName,
      gender,
      birthDate,
      address
    } = formData
    return (
      email &&
      emailCode &&
      mobile &&
      countryCode &&
      mobileCode &&
      password &&
      confirmPassword &&
      firstName &&
      lastName &&
      gender &&
      birthDate &&
      address
    )
  }
  function submit() {
    if (!verify()) {
      showModal(
        '',
        formatMessage({ id: 'dm-form-submit-error-info-no-agreement' }),
        () => { },
        () => { },
        formatMessage({ id: 'common-comfirmBtn' }),
        formatMessage({ id: 'common-cancel' })
      );
      return
    }
    const { confirmPassword, errors, ...data } = formData
    dispatch(submitDsd(data))
  }
  const updateFormData = (update) => setFormData({ ...formData, ...update })
  if (!storeInfo) return null
  return (
    <>
      <Header
        text={storeInfo.storeName}
        code={storeInfo.storeNumber}
      />
      <EmailVerify
        updateFormData={updateFormData}
        email={formData.email}
        code={formData.emailCode}
        errors={formData.errors} />
      <WhiteSpace />
      <MobileVerify
        updateFormData={updateFormData}
        mobile={formData.mobile}
        code={formData.mobileCode}
        countryCode={formData.countryCode}
        errors={formData.errors} />
      <WhiteSpace />
      <SetPassword
        updateFormData={updateFormData}
        password={formData.password}
        errors={formData.errors}
        confirmPassword={formData.confirmPassword} />
      <WhiteSpace />
      <ProfileInfo
        firstName={formData.firstName}
        lastName={formData.lastName}
        updateFormData={updateFormData}
        gender={formData.gender}
        birthDate={formData.birthDate}
        stateId={formData.stateId}
        address={formData.address} />
      <WhiteSpace size="xl" />
      <WingBlank>
        <Button
          type="primary"
          onClick={submit}>
          提交
        </Button>
      </WingBlank>
      <WhiteSpace size="xl" />
    </>
  )
}
export default DsdRegister