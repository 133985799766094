import { authedGetService, getService, useRequestCommon } from '../hooks';
import * as config from '../config';
import { getUnionId } from '../../components/common/cookie';

export const useWechatUserInfo = (code, options) =>
  useRequestCommon(
    () => authedGetService(config.wechatUserInfo + code),
    { manual: false, ...options }
  );

export const useWechatUserByUnionId = (options) =>
  useRequestCommon(
    () => {
      const unionid = getUnionId();
      if(unionid?.length === 28) {
        getService(config.wechatUser, { unionid })
      } else {
        return null;
      }
    },
    { manual: false, ...options }
  );
