import { Link } from 'react-router-dom';
import React from 'react';
import { getCookie } from '../common/cookie';
import { courseName, gtm } from '../common/utils';
import { useIntl, FormattedMessage } from 'react-intl';
import CardImageShow from '../CertShow/CertShow'

import imageFiles from '../certImages.json';

const getCurrentYear = () => {
  const date = new Date();
  return date.getFullYear()
}

const getCourseUrl = certCompactName => {
  const language = getCookie('language');
  if (certCompactName.indexOf('Mermaid') > -1) {
    return `/course/Mermaid/${language}?course=${certCompactName}`;
  }
  return `/course/${certCompactName}/${language}`;
}

const defaultImage = '-1';
const getImageFile = cert => {
  const match = imageFiles.filter(i => i.id === '' + cert.certificationNumber);
  return cert.certificationNumber === 1 && cert.certificationName !== 'Divemaster' ? defaultImage : match[0].file
};



const Cert = ({ cert, certType }) => {
  const certCompactName = courseName[cert.certificationName];
  const { formatMessage } = useIntl();

  const getCertType = (type) => {
    switch (type) {
      case 'scuba':
        return formatMessage({ id: 'certlist-scuba' })
      case 'freediver':
        return formatMessage({ id: 'certlist-freediver' })
      case 'mermaid':
        return formatMessage({ id: 'certlist-mermaid' })
      default: return null;
    }
  }

  return (
    <Link to={getCourseUrl(certCompactName)} onClick={() => gtm('Click - Next Cert Recommendation', certCompactName)}>
      <div className="cert-details">
        <div className="time-axis">
          <div className="time-spot" />
          <div className="time-line" />
        </div>
        <div className="cert-list-header">
          <h3>
            {getCurrentYear()} <FormattedMessage id="certlist-nextCard" /> {getCertType(certType)}
          </h3>
        </div>
        <div className='diver-card-wrapper'>
          <div className="diver-card-shelter">
            <img
              src={require(`../../assets/images/lighten.png`)}
              alt="Highlight"
            />
          </div>
          <CardImageShow path={`certs/cert_${getImageFile(cert)}.jpg`} cert={cert} />
        </div>
      </div>
    </Link>
  )
}

export default Cert;
