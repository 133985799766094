import React from 'react'
import { Modal, Form, Input, Button, Toast } from 'antd-mobile-v5'
import { useIntl } from 'react-intl'
import { NAME_PATTERN } from '../../utils/user';
import PickerTool from '../FormTools/Picker';
import { genderOptions } from '../FormTools/utils';
import { getCookie } from './cookie';
import DatePickerTool from '../FormTools/DatePicker'; 
import dayjs from 'dayjs';
import { useUpdateUserInfo } from '../../services/hooks'
import { formatDate } from './utils';

const ModifyUserInfo = ({ visible, setVisible, userInfo }) => {
  const { formatMessage } = useIntl()
  const [form] = Form.useForm();
  const lang = getCookie('language');

  const { loading, run } = useUpdateUserInfo({
    onSuccess: () => {
      setVisible(false)
      Toast.show({
        icon: 'success',
        content: formatMessage({ id: 'updateUserInfo-success' }),
      })
    }
  });

  const submit = (data) => {
    const {firstName, lastName, gender, birthDate} = data;

    if((firstName + " " + lastName).toLowerCase() === userInfo.englishName.toLowerCase() &&
      gender === userInfo.sex &&
      formatDate(birthDate, 'YYYY-MM-DD') === formatDate(userInfo.birthDate, 'YYYY-MM-DD')
    ) {
      Modal.alert({
        content: formatMessage({id: 'form-name-nochange-error'}),
        confirmText: formatMessage({id: 'common-confirm'}),
      });
      return;
    }
    run(data);
  }

  return <Modal
    visible={visible}
    header={formatMessage({ id: 'updateUserInfo-hint' })}
    content={<Form
      form={form}
      onFinish={submit}
    >
      <Form.Item
        name="lastName"
        label={<>{formatMessage({ id: 'setProfile-lastName' })}<span style={{fontSize: "8px"}}>{formatMessage({ id: 'form-name-format-hint' })}</span></>}
        rules={[
          {
            required: true,
            message: formatMessage({ id: 'form-lastname-error' }),
          },
          {
            pattern: NAME_PATTERN,
            message: formatMessage({ id: 'form-name-format-error' }),
          },
        ]}
      >
        <Input placeholder={formatMessage({ id: 'setProfile-defaultName' })} />
      </Form.Item>
      <Form.Item
        name="firstName"
        label={<>{formatMessage({ id: 'setProfile-firstName' })}<span style={{fontSize: "8px"}}>{formatMessage({ id: 'form-name-format-hint' })}</span></>}
        rules={[
          {
            required: true,
            message: formatMessage({ id: 'form-firstname-error' }),
          },
          {
            pattern: NAME_PATTERN,
            message: formatMessage({ id: 'form-name-format-error' }),
          },
        ]}
      >
        <Input placeholder={formatMessage({ id: 'setProfile-defaultName' })} />
      </Form.Item>
      <PickerTool
        name={"gender"}
        form={form}
        rules={[
          {
            required: true,
            message: formatMessage({ id: 'form-gender-empty-error' }),
          },
        ]}
        placeholder={formatMessage({ id: 'setProfile-errorGender' })}
        data={genderOptions[lang]}
        label={formatMessage({ id: 'setProfile-gender' })}
      />
      <DatePickerTool
        name="birthDate"
        placeholder={formatMessage({ id: 'form-birthdate-hint' })}
        label={formatMessage({ id: 'dm-form-birth' })}
        rules={[
          {
            required: true,
            message: formatMessage({ id: 'form-birthdate-empty-error' }),
          },
        ]}
        min={dayjs().subtract(70, 'year').toDate()}
        max={dayjs().subtract(6, 'year').toDate()}
      />
      <Button
        block
        type="submit"
        color="primary"
        loading={loading}
      >
        {formatMessage({ id: 'common-comfirmBtn' })}
      </Button>
    </Form>}
    closeOnAction
    onClose={() => {
      setVisible(false)
    }}
    actions={[
      {
        key: 'cancel',
        text: formatMessage({ id: 'common-cancel' }),
        onClick: () => setVisible(false)
      }
    ]}
  />
}

export default ModifyUserInfo