import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { List, WhiteSpace } from 'antd-mobile'
import UploadFile from '../../PicCollection/UploadFile'
import { getAnswersByAlias } from '../../../services/api'
import { getParameterByName } from '../../../components/common/utils'
import { Empty } from 'antd'

const Item = List.Item

const Header = () => {

  const { push } = useHistory()
  const alias = getParameterByName('alias')
  const { state: { taskName } } = useLocation()
  return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <span>任务名：{taskName}</span>
    <i className="fa fa-edit fa-lg" onClick={() => push(`/admin/collection/create?alias=${alias}`)}></i>
  </div>
}

const TaskPicList = () => {

  const alias = getParameterByName('alias')
  const [list, setList] = useState([])

  useEffect(() => {
    requestListByAlias()
  },[])

  const requestListByAlias = async () => {
    try {
      const list = await getAnswersByAlias(alias)
      if(list.data) setList(list.data)
    }finally {
      console.log('done')
    }
  }

  return <List renderHeader={() => <Header />}>
    {list.length <= 0 ? (
      <Empty description="暂无素材" />
    ) : (
      list.map((item, index) => <Item key={index}>
        <div>姓名：{item.nickname}</div>
        <WhiteSpace />
        <div>微信名：{item.wechatUserDTO ? item.wechatUserDTO.nickname : ''}</div>
        <WhiteSpace />
        <div>微信号：{item.wxid}</div>
        <WhiteSpace />
        <div>手机号：{item.profile.mobile}</div>
        <WhiteSpace />
        <div>潜水等级：{item.level}</div>
        <WhiteSpace />
        <div>职业：{item.job}</div>
        <WhiteSpace />
        {item.items.map((item, index) => (
          <div key={index}>
            <div style={{whiteSpace: 'normal'}}>{index+1}.{item.title}</div>
            <div style={{whiteSpace: 'normal'}}>{item.answer}</div>
          </div>
        ))}
        <WhiteSpace />
        <div>
          <UploadFile disabled={true} fileList={item.resources} skipOss={true} />
        </div>
      </Item>)
    )}
  </List>
}

export default TaskPicList