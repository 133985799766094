import React from 'react';
import { connect } from 'react-redux';

import { getEcardDetail, getUserInfo } from '../../reducers/selectors';
import { CertDetailList } from '../../components/CertDetailList/CertDetailList';
import RequestOptionsBtn from '../../components/CertDetailList/RequestOptionsBtn';
import { WhiteSpace } from 'antd-mobile';
import { isCert } from '../../utils/cert';
import { isMainlandUser } from '../../utils/user';
import { Button, Space } from 'antd-mobile-v5';
import { isWechat } from '../../utils/wechat';
import { useDownloadWallCert, useErrorModal, useGetFreeCred } from '../../services/hooks';
import { useShowMessage } from '../../services/hooks/ui';
import { useIntl, FormattedMessage } from 'react-intl';
import BottomNav from '../../components/BottomNav';
import CertShowWrapped from '../../components/CertDetail/CertShowWrapped';

const CertDetailPage = ({ ecard, userInfo }) => {
  const showMessage = useShowMessage();

  const { loading, run: getFreeCard } = useGetFreeCred({
    onSuccess: () => showMessage('apply-for-free-card-ok', '/certs'),
  });

  if(!ecard) return null;
  return <>
        <WhiteSpace />
        <CertShowWrapped cert={ecard} />
        <Space block direction="vertical" style={{ padding: '8px 16px' }}>
          {isMainlandUser(userInfo) && <RequestOptionsBtn />}
          <DownloadPaperCert
            affiliateCredentialId={ecard.affiliateCredentialId}
            affiliateCertificationId={ecard.affiliateCertificationId}
              certificationName={ecard.certificationName}
          />
        </Space>
        {!isMainlandUser(userInfo) && (
          ecard.getFreeCard ?
          <Space block direction="vertical" style={{ padding: '0 16px' }}>
            <Button
              block
              color="primary"
              loading={loading}
              onClick={() => getFreeCard(ecard.uniqueId)}
            >
              <FormattedMessage id="apply-for-free-card" />
            </Button>
          </Space>
          : <p style={{ padding: '4px 16px 0', fontSize: '.8rem' }}>
            <FormattedMessage
              id="cardRequest-non-mainland"
              values={{
                url: isCert(ecard) ? (
                  <a href="https://www.padi.com/certification-cards">
                    {' '}
                    PADI{' '}
                    <FormattedMessage id="cardRequest-non-mainland-diver" />
                  </a>
                ) : (
                  <a href="https://pro.padi.com">
                    {' '}
                    PADI <FormattedMessage id="cardRequest-non-mainland-pro" />
                  </a>
                ),
              }}
            ></FormattedMessage>
          </p>
        )}

        <CertDetailList cert={ecard} user={userInfo} />
        <div style={{ width: '100%', height: '60px' }}></div>
        <BottomNav isMainlandUser={isMainlandUser(userInfo)} />

      </>
};

function DownloadPaperCert({ affiliateCredentialId, affiliateCertificationId, certificationName }) {
  const { formatMessage } = useIntl();
  const showErrorModal = useErrorModal();
  const { loading, run: download } = useDownloadWallCert();
  if (!affiliateCredentialId && certificationName !== 'Mermaid Performance Diver') return null;
  return (
    <Button
      loading={loading}
      color="primary"
      fill="outline"
      block
      onClick={() => {
        if(isWechat()) {
          showErrorModal(formatMessage({id: 'wechat-wall-cert'}));
        } else {
          let data
          if (affiliateCertificationId) data = { affiliateCertificationId, ecardType: 'CERTIFICATION' }
          else data = { affiliateCredentialId, ecardType: 'CREDENTIAL' }
          download(data);
        }
      }}
    >
      <FormattedMessage id="download-wall-cert" />
    </Button>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    ecard: getEcardDetail(ownProps.match.params.certId)(state),
    userInfo: getUserInfo(state),
  };
}

export default connect(mapStateToProps, null)(CertDetailPage);
