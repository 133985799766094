import { Dialog } from 'antd-mobile-v5';
import React from 'react';
import { useNotification } from '../services/hooks';
import { useIntl } from 'react-intl';

const INDEXES = ['\u2460', '\u2461', '\u2462', '\u2463', '\u2464', '\u2465', '\u2466', '\u2467', '\u2468', '\u2469', ];
export default function Notification({position}) {
  const { formatMessage }  = useIntl();
  const showNotification = (notifications, index) => {
    Dialog.alert({
      closeOnMaskClick: true,
      title: `${formatMessage({id: 'notification-title'})} ${INDEXES[index]}`,
      content: <>
        <h4>{notifications[index].title}</h4>
      <p>{notifications[index].body}</p>
      </>,
      onConfirm: () => {
        if(notifications.length > index + 1) {
          showNotification(notifications, index + 1);
        }
      },
    })
  };

  useNotification(position, {
    onSuccess: data => {
      data?.length > 0 && showNotification(data, 0);
    }
  });
  
  return null;
}