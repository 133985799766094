import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { getEcards } from '../../reducers/selectors';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Grid, Modal, Toast } from 'antd-mobile-v5';
import Cert from '../NewYearCampaign/Cert';
import { getEncodedId } from '../../components/common/utils';
import { useBindEcard } from '../../services/hooks';
import styles from './ClubEacrds.module.css'

const ClubEcards = () => {

  const [checkedCertId, setCheckedCertId] = useState();
  const { formatMessage } = useIntl();
  const { ecards } = useSelector(state => ({
    ecards: getEcards(state)
  }));
  const { loading: submitLoading, run } = useBindEcard({
    onSuccess: () => Toast.show({
      icon: 'success',
      content: formatMessage({ id: 'club-success' })
    }),
  });

  const bindingCard = () => {
    if (submitLoading) return;
    if (!checkedCertId) {
      Modal.alert({
        title: formatMessage({ id: 'common-errorTitle' }),
        content: formatMessage({ id: 'campaign-noEcardSelected' }),
        confirmText: formatMessage({ id: 'common-close' })
      });
      return;
    }
    run({
      ecardId: checkedCertId,
      ecardImgId: 40,
    });
  }

  return <div className={styles.wrapper}>
    <img src={require('../../assets/images/club/bg1.png')} alt="bg1" className={styles.bg} />
    <div className={styles.bottom}>
      <img src={require('../../assets/images/club/content.png')} alt="bg1" className={styles.content} />
      <div className={styles['bottom-area']}>
      <Ecards onChecked={(id) => setCheckedCertId(id)} ecards={ecards} />
      <Button className={styles['receive-btn']} onClick={bindingCard} loading={submitLoading}>
        <FormattedMessage id="club-receive-btn" />
      </Button>
      </div>
    </div>
  </div>
}

function Ecards({ onChecked, ecards }) {

  const [checkedCertId, setCheckedCertId] = useState();

  return (
    <>
      <p className={styles['cert-loading']}>
        <FormattedMessage id="club-certs-list-title" />
      </p>
      <Grid columns={3} gap={8} className={styles['cert-list']}>
        {ecards.map((ecard) => (
          <Grid.Item key={ecard.uniqueId}>
            <Cert
              ecard={ecard}
              check={(checkedCertId) => {
                setCheckedCertId(checkedCertId);
                onChecked(checkedCertId);
              }}
              checked={checkedCertId === getEncodedId(ecard)}
            />
          </Grid.Item>
        ))}
      </Grid>
    </>
  );
}

export default ClubEcards