import { Button, Checkbox } from 'antd-mobile-v5';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import React from 'react';

import { getPostage } from '../../pages/BuyCards/cardOptionUtils';
import {
  useBuyCards,
} from '../../services/hooks';

import './Cart.css';

// const { goodsIds, options } = cardOptions;

const CartFooter = ({
  toggleCheckAll,
  checkedList,
  shoppingCart,
}) => {

  const { loading: buyCardsLoading, run: buyCardsSubmit } = useBuyCards({
    onSuccess: ({ url }) => (window.location.href = url),
  });

  const checkout = () => {
    buyCardsSubmit(checkedList);
  };

  const totalPrice = () =>
    (checkedList.reduce((total, current) => total + current.price, 0) +
      getPostage(checkedList)) /
    100;
  return (
    <div className="cart-footer">
      <div className="checkout-counter">
        <div className="price">
          <Checkbox
            className='checkAll'
            indeterminate={
              checkedList.length > 0 &&
              checkedList.length !== shoppingCart.length
            }
            checked={checkedList.length === shoppingCart.length}
            onChange={toggleCheckAll}
          >
            全选
          </Checkbox>
          <Link className="continue" to="/buy_cards">
            <FormattedMessage id="cardRequest-continueToBuy" />
          </Link>
        </div>
        <div className="go-to-pay">
          <Button
            className="cart-btn"
            fill="none"
            loading={buyCardsLoading}
            disabled={!checkedList.length > 0}
            onClick={checkout}
          >
            <FormattedMessage id="cardRequest-goToPay" />
            (￥{totalPrice()})
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CartFooter;
