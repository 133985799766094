import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Flex, List } from 'antd-mobile';

import { formatMoney } from '../../components/common/utils'
import { changeAmount } from '../../reducers/ui'

import styles from '../../pages/PriceList/PriceList.module.css'

const PriceItem = ({ item, submitItems }) => {
  const dispatch = useDispatch();
  const [count, setCount] = useState(0)

  const findSubmitItem = submitItems.find(submitItem => submitItem.goodsId === item.goodsId && submitItem.skuId === item.skuId)
  const submitCount = findSubmitItem ? findSubmitItem.number : ''

  useEffect(() => {
    setCount(submitCount)
  }, [submitCount])

  const changeAmountFn = (count, item, handleType) => {
    let num = count ? count : 0
    if(handleType === 'add') num++
    else if(handleType === 'subtract') num--
    if(!Number.isInteger(num)) {
      setCount(0)
      addGoods(0, item)
      return
    }
    if(num < 0) {
      return
    }
    setCount(num)
    addGoods(num, item)
  }

  const addGoods = (number, item) => {
    // const { goodsId, skuId, itemNo, price, currencyType } = item
    // const filterItem = {
    //   goodsId,
    //   skuId,
    //   itemNo,
    //   price,
    //   currencyType,
    //   number: num
    // }
    dispatch(changeAmount({...item, number}))
  }

  return     <List.Item align="bottom" thumb={item.picUrl} wrap>
  {item.title}<List.Item.Brief><Flex justify="between"><span className={styles.price}>
         {formatMoney(item.price, item.currencyType)}
       </span>
       <div>
    <button className={styles.minus} onClick={() => changeAmountFn(count, item, 'subtract')}></button>
      <input 
        name="count"
        type="number"
        placeholder="0"
        className={styles.input}
        value={submitCount}
        onChange={(e) => changeAmountFn(parseInt(e.target.value), item)}></input>
    <button className={styles.plus} onClick={() => changeAmountFn(count, item, 'add')}></button>
  </div></Flex>
       </List.Item.Brief>
</List.Item>

  // <div className={styles.saleItem}>
  //   <div className={styles.goods}>
  //     <div className={styles.goodsImg}>
  //       <img alt={item.title} src={item.picUrl} />
  //     </div>
  //     <div className={styles.goodsInfo}>
  //       <div className={styles.title}>{item.title}</div>
  //       <div className={styles.bottom}>
  //         <div className={styles.price}>{formatMoney(item.price, item.currencyType)}</div>
  //         <div>
  //           <button className={styles.minus} onClick={() => changeAmountFn(count, item, 'subtract')}></button>
  //             <input 
  //               name="count"
  //               type="number"
  //               placeholder="0"
  //               className={styles.input}
  //               value={submitCount}
  //               onChange={(e) => changeAmountFn(parseInt(e.target.value), item)}></input>
  //           <button className={styles.plus} onClick={() => changeAmountFn(count, item, 'add')}></button>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // </div>
}

export default PriceItem