import { Modal } from 'antd-mobile';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import React, { useEffect } from 'react';

export default function ErrorDialog({
  errorMessage,
  resetErrorMessage,
  router,
  path,
}) {
  const history = useHistory();
  const { formatMessage } = useIntl();

  useEffect(() => {
    window.scrollBy(0, 1);
    window.scrollBy(0, -1);
  });
  if (!errorMessage) return null;
  return (
    <Modal
      visible={errorMessage}
      transparent
      maskClosable={true}
      title={
        router
          ? formatMessage({ id: 'common-prompt' })
          : formatMessage({ id: 'common-errorTitle' })
      }
      footer={[
        {
          text: router
            ? formatMessage({ id: 'common-confirm' })
            : formatMessage({ id: 'common-close' }),
          onPress: () => {
            resetErrorMessage();
            if (router) history.push(router);
            else if (path) window.location.href = path;
          },
        },
      ]}
    >
      {errorMessage}
    </Modal>
  );
}
