import React from 'react'
import { List, Button } from 'antd-mobile-v5'
import { WhiteSpace } from 'antd-mobile'
import InvoiceInfo from '../PriceList/InvoiceInfo'
import { FormattedMessage, useIntl } from 'react-intl';
import OrderItem from '../PriceList/OrderItem';
import PriceSummary from '../PriceList/PriceSummary';
import { useGetPicOrderDetail, useTextbookOrderPayment } from '../../services/hooks'
import { getParameterByName, textbooksRegions, showAlert } from '../../components/common/utils';
import { useLanguage } from '../../services/hooks/user';

const RegionSection = ({ detail }) => {

    const lang = useLanguage();

    const findAdressType = (userAddressType) => {
        const adressTypeList = textbooksRegions[lang]
        const currentAdressType = adressTypeList.find(type => type.value === userAddressType)
        return currentAdressType.label
    }

    return <List header={<FormattedMessage id="common-address-detail" />}>
        <List.Item extra={findAdressType(detail.userAddressType)}>
            <FormattedMessage id="addressForm-region" />
        </List.Item>
        <List.Item extra={detail.userAddress}>
            <FormattedMessage id="addressForm-address" />
        </List.Item>
        <List.Item extra={detail.userName}>
            <FormattedMessage id="addressForm-contact" />
        </List.Item>
        <List.Item extra={detail.userMobile}>
            <FormattedMessage id="addressForm-mobile" />
        </List.Item>
    </List>
}

const PaymentBtn = ({ id }) => {

    const { formatMessage } = useIntl()
    const { run, loading } = useTextbookOrderPayment({
        onSuccess: (data) => {
            if(data.error) {
                showAlert(
                  data.error.clientMsg,
                  formatMessage({ id: 'common-close' })
                );
                return
            }
            if (data.url) {
                window.location.replace(data.url)
            }
          }
    })

    return <>
        <WhiteSpace />
        <Button 
            block 
            color='primary' 
            loading={loading} 
            disabled={loading}
            onClick={() => run(id)}>
            <FormattedMessage id="app-to-pay" />
        </Button>
    </>
}

const PicOrderDetail = () => {

    const id = getParameterByName('id')
    const { loading, data } = useGetPicOrderDetail(id);

    const formatOrderData = () => {
        const { itemDTOS, ...details } = data
        const formatItems = itemDTOS.map(item => ({ ...item, currencyType: data.currencyType }) )
        return { ...details, itemDTOS: formatItems }
    }

    const findOrderPrice = (item, order) => {
        if (!order) return null;
        const match = order.itemDTOS.find((i) => item.goodsId === i.goodsId);
        return match ? match.discountPrice : null;
    };

    if(!data || loading) return <>loading</>

    return <>
        <RegionSection detail={data} />
        {data.invoiceDTO.affiliateId && <InvoiceInfo invoiceInfo={data.invoiceDTO} disabled={true} />}
        <List header={<FormattedMessage id="items-list" />} className="price-items">
          {formatOrderData().itemDTOS.map((item) => (
            <OrderItem
              key={item.goodsId}
              item={item}
              orderPrice={findOrderPrice(item, data)}
            />
          ))}
        </List>
        <PriceSummary selectedCoupon={false} currencyType={formatOrderData().itemDTOS[0].currencyType} order={formatOrderData()} />
        {data.status === 'WAIT_PAY' && <PaymentBtn id={data.id} />}
    </>
}

export default PicOrderDetail