import React from 'react'
import { useSelector } from 'react-redux';
import AppList from '../../components/common/AppList'
import { skipBasicMermaidTListPage } from '../../reducers/mermaid'

const BasicMermaidTList = () => {

  const { confirmRes } = useSelector((state) => ({
    confirmRes: state.mermaid.basicMermaidTList,
  }));

  return <AppList
    confirmRes={confirmRes}
    detailUrl="/basic_mermaid_app_detail"
    paging={skipBasicMermaidTListPage}
    listHeader="BMI-t-list-title" />
}

export default BasicMermaidTList