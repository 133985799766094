import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Link, useLocation, useHistory } from 'react-router-dom';
import {
  List,
  Flex,
  WingBlank,
  WhiteSpace,
  Modal
} from 'antd-mobile';
import { Button } from 'antd-mobile-v5'
import { FormattedMessage, useIntl } from 'react-intl';
import { errorDialog } from '../../actions/index';
import OrderItem from './OrderItem';
import Coupon from './Coupon'
import { usePicOrderCreate, usePicOrder, useGetInvoice, useTaiwanIp } from '../../services/hooks';
import AgreementPopup from '../../components/FormTools/AgreementPopup'

import ShippingAddress from './ShippingAddress';
import PriceSummary from './PriceSummary';
import InvoiceInfo from './InvoiceInfo'

const findOrderPrice = (item, order) => {
  if (!order) return null;
  const match = order.items.find((i) => item.goodsId === i.goodsId);
  return match ? match.discountPrice : null;
};

const BooksCheckout = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { state: orderState } = useLocation();
  const { items } = orderState || {};

  const [addressType, setAddressType] = useState();
  const [address, setAddress] = useState('');
  const [userName, setUserName] = useState('');
  const [userMobile, setUserMobile] = useState('');
  const [order, setOrder] = useState();
  const [couponVisiable, setCouponVisiable] = useState(false)
  const [selectedCoupon, setSelectedCoupon] = useState()
  const [invoiceInfo, setInvoiceInfo] = useState()
  const [visible, setVisible] = useState(false)
  const { profile } = useSelector(state => ({
    profile: state.data.profile,
  }));
  const { data: isTaiwanIp } = useTaiwanIp({});

  const history = useHistory();

  const freeItems = items.map(item => {
    return {
      ...item,
      name: item.title,
      quantity: item.number
    }
  })

  const freeData = {
    id: 'Free Order',
    items: freeItems,
    price: 0.00
  }

  const { loading, run: submit } = usePicOrderCreate({
    onSuccess: (data, [{ paymentMethodType }]) => {
      if (paymentMethodType && paymentMethodType !== 'OFFLINE') window.location.href = data.url
      else if (paymentMethodType === 'OFFLINE') history.replace('/books_submit_success', { price: order.totalPayment })
      else history.replace('/pay_success', { payResult: freeData })
    }
  });

  const { run } = usePicOrder({
    onSuccess: data => {
      setOrder(data)
        const couponList = data.couponDTOS.map(coupon => {
          coupon.selected = false
          return coupon
        })
        setSelectedCoupon(couponList)
    }
  });

  const { loading: invoiceLoading } = useGetInvoice({
    onSuccess: data => {
      setInvoiceInfo(data)
    }
  });

  const checkout = () => {
    if (!addressType || !address || !userName || !userMobile) {
      dispatch(errorDialog(formatMessage({ id: 'addressForm-saveHint' })));
      return;
    }
    if (profile.teachingLocation === 'TW' && !invoiceInfo) {
      dispatch(errorDialog('請填寫發票。'));
      return;
    }
    if (order.totalPayment === 0) {
      submitCheckoutData('FREE')
      return
    }
    Modal.alert('', formatMessage({ id: 'payment-method' }), [
      { text: formatMessage({ id: 'online-pay' }), onPress: () => verifyIpBeforePay(order.paymentMethodType) },
      { text: formatMessage({ id: 'offline-pay' }), onPress: () => submitCheckoutData('OFFLINE') },
      { text: formatMessage({ id: 'common-cancel' }), onPress: () => { } },
    ])
  };

  const submitCheckoutData = async (paymentType) => {
    const checkoutData = {
      addressType,
      address,
      userName,
      userMobile,
      items,
      paymentMethodType: paymentType,
      couponDTOS: selectedCoupon
    }

    if (invoiceInfo) {
      checkoutData.invoiceDTO = invoiceInfo
    }
    
    submit(checkoutData)
  };

  const verifyIpBeforePay = (paymentType) => {
    if (profile.teachingLocation === 'TW' || isTaiwanIp) {
      setVisible(true)
    } else {
      submitCheckoutData(paymentType)
    }
  }

  useEffect(() => {
    const updateOrders = async (addressType) => {
      if (!addressType) return;
      run({
        addressType: addressType,
        items,
      })
    };

    updateOrders(addressType);
  }, [addressType, items, dispatch]);

  // const selectCoupon = (coupon) => {
  //   if (getSelectedCoupon(coupon) && !getSelectedCoupon(coupon).available) {
  //     if (coupon.couponType === 'RENEWAL_2023') {
  //       showModal(
  //           '',
  //           formatMessage({ id: 'coupon-use-error-2023' }),
  //           () => setCouponVisiable(!couponVisiable),
  //           () => { },
  //           formatMessage({ id: 'common-comfirmBtn' }),
  //           formatMessage({ id: 'common-cancel' })
  //       );
  //     } else {
  //       showModal(
  //           '',
  //           formatMessage({ id: 'coupon-use-error' }),
  //           () => setCouponVisiable(!couponVisiable),
  //           () => { },
  //           formatMessage({ id: 'common-comfirmBtn' }),
  //           formatMessage({ id: 'common-cancel' })
  //       );
  //     }
  //     return
  //   }
  //   setSelectedCoupon(coupon)
  //   setCouponVisiable(!couponVisiable)
  // }

  const onRegionChange = (region) => {
    setAddressType(region);
    // TODO;
  }

  const getSelectedCouponCount = () => {
    return order.couponDTOS.filter(coupon => coupon.selected).length
  }

  const toSelectCoupon = () => {
    if (order.couponDTOS.length === 0) return
    setCouponVisiable(true)
  }

  if (!items) return <Redirect to="/bookSaleList" />;

  const currencyType = items[0].currencyType;

  const getCoupon = (coupons) => {
    return coupons.filter(coupon => !coupon.expired)
  }

  return (
    <>
      <>
        <ShippingAddress
        address={address}
        setAddress={setAddress}
        addressType={addressType}
        onRegionChange={onRegionChange}
        userName={userName}
        userMobile={userMobile}
        setUserName={setUserName}
        setUserMobile={setUserMobile}
        />
        {!invoiceLoading && profile && profile.teachingLocation === 'TW' && <InvoiceInfo invoiceInfo={invoiceInfo} />}
        <List renderHeader={<FormattedMessage id="items-list" />} className="price-items">
          {items.map((item) => (
            <OrderItem
              key={item.goodsId}
              item={item}
              orderPrice={findOrderPrice(item, order)}
            />
          ))}
        </List>
        <WhiteSpace />
        <WhiteSpace />
        {order && <List>
          <List.Item
            extra={order.couponDTOS.length > 0 ? <span style={{ color: (getSelectedCouponCount() > 0 ? '#000' : '#aaa') }}>
              {getSelectedCouponCount() > 0
                ? <FormattedMessage
                  id="coupon-used"
                  values={{
                    count: getSelectedCouponCount()
                  }} />
                : <FormattedMessage
                  id="has-coupon"
                  values={{
                    count: getCoupon(order.couponDTOS).length
                  }} />
              }
            </span> : <span><FormattedMessage id="coupon-without" /></span>}
            arrow="horizontal"
            onClick={toSelectCoupon}>
            <span>
              <FormattedMessage id="coupon" />
            </span>
          </List.Item>
        </List>}
        <PriceSummary selectedCoupon={selectedCoupon} currencyType={currencyType} order={order} />
        <WhiteSpace />
        <WhiteSpace />
        <WingBlank>
          <Button 
            color='primary'
            block
            onClick={checkout} 
            loading={loading} 
            disabled={loading}>
            <FormattedMessage id="checkout" />
          </Button>
          <WhiteSpace />
          <Flex justify="center">
            <Link
              replace
              to={{
                pathname: '/bookSaleList',
                state: { items },
              }}
            >
              <FormattedMessage id="change-order" />
            </Link>
          </Flex>
        </WingBlank>
      </>
      {couponVisiable && <Coupon
        selectedCoupon={selectedCoupon}
        setSelectedCoupon={setSelectedCoupon}
        setVisiable={setCouponVisiable}
        setOrder={setOrder}
        submitData={{
          addressType,
          address,
          userName,
          userMobile,
          items
        }} />}
      <AgreementPopup visible={visible} setVisible={setVisible} toPay={() => submitCheckoutData(order.paymentMethodType)} />
    </>
  );
};

export default BooksCheckout;
