import React from 'react';
import { useHistory } from 'react-router-dom';
import { Steps, Space, Button, Toast } from 'antd-mobile-v5';
import { ClockCircleFill, CheckCircleFill } from 'antd-mobile-icons';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form } from 'antd-mobile-v5';
import { useIssueCertsConfirm, useDeleteMemberApplication } from '../../services/hooks';
import { showModal } from '../../components/common/utils';
import PaymentBtn from '../../components/FormTools/PaymentBtn'

const { Step } = Steps;

const appStatusId = (status, paymentMethod) => {
  switch (status) {
    case 'SUBMIT':
      if (paymentMethod) return 'dm-form-wait-cert';
      return 'app-wait-pay';
    case 'PAID_EFR':
      return 'dm-form-wait-cert';
    case 'SUCCESS':
      return 'fd-pic-finish';
    case 'CONFIRM':
      return 'dm-form-processing';
    default:
      return 'dm-form-wait-cert';
  }
};

export { appStatusId };

export default function ProgressStatus({
  status,
  paymentMethod,
  lastSubmitDate,
  payFn,
  code,
  type,
}) {
  const { formatMessage } = useIntl();
  const { replace } = useHistory();

  const showPayBtn = () => {
    return status === 'SUBMIT' && !paymentMethod;
  };

  const showCertBtn = () => {
    return status === 'SUBMIT' && paymentMethod;
  };

  const { loading, run } = useIssueCertsConfirm(type, {
    onSuccess: () => {
      Toast.show({
        icon: 'success',
        content: formatMessage({ id: 'form-cert-success' }),
        afterClose: () => {
          replace(`/issue_certs/list?type=${type}`);
        },
      });
    },
  });

  const { loading: deleteLoading, run: deleteApplication } = useDeleteMemberApplication({
    onSuccess: () => {
      replace(`/issue_certs/list?type=${type}`);
    },
  });

  const handleDelete = () => {
    showModal(
      '',
      formatMessage({ id: "app-cancel" }),
      () => deleteApplication({ code, type }),
      () => { },
      formatMessage({ id: 'common-comfirmBtn' }),
      formatMessage({ id: 'common-cancel' })
    )
  }

  return (
    <>
      <Form layout="horizontal">
        <Form.Header>{formatMessage({ id: 'form-app-status' })}</Form.Header>
        <Form.Item>
          <Steps direction="vertical">
            <Step
              title={formatMessage({ id: appStatusId(status, paymentMethod) })}
              status={'process'}
              description={lastSubmitDate}
              icon={
                status === 'SUCCESS' ? <CheckCircleFill /> : <ClockCircleFill />
              }
            />
          </Steps>
        </Form.Item>
      </Form>
      <Space direction="vertical" block style={{padding: '12px 15px'}}>
        {showPayBtn() && (
          <PaymentBtn code={code} paymentFn={payFn} payApplicationType={type} />
        )}
        {showCertBtn() && (
          <Button
            block
            color="primary"
            onClick={() => run(code)}
            loading={loading}
            disabled={loading}
          >
            <FormattedMessage id="form-confirm-cert" />
          </Button>
        )}
        {!paymentMethod && status && (
          <Button
            block
            color="primary"
            fill='none'
            onClick={handleDelete}
            loading={deleteLoading}
            disabled={deleteLoading}
          >
            <FormattedMessage id="app-delete" />
          </Button>
        )}
      </Space>
    </>
  );
}
