import React from 'react'
import { FormattedMessage } from 'react-intl';
import AgreementCheckbox from '../../components/common/AgreementCheckbox'
import { getCookie } from '../../components/common/cookie';

const Agreement = ({ checkedChange, checkedTimes, checkedAgreement }) => {
  const lang = getCookie('language')
  const onCheckedChange = (type, checked) => {
    checkedChange({ [type]: checked })
  }
  return <>
    <AgreementCheckbox 
      checked={checkedTimes} 
      setChecked={(checked) => onCheckedChange('checkedTimes', checked)}>
        <FormattedMessage id="dm-form-agreement-60-dives" />
    </AgreementCheckbox>
    <AgreementCheckbox 
      checked={checkedAgreement} 
      setChecked={(checked) => onCheckedChange('checkedAgreement', checked)}>
        <FormattedMessage id="dm-form-agreement-text" values={{ 
            link: <a href={`https://wx.padi.com.cn/files/10125${lang}.pdf`} rel="noreferrer noopener" target="_blank"><FormattedMessage id="dm-form-agreement-link" /></a>
          }}/>
    </AgreementCheckbox>
  </>}

export default Agreement