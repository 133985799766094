import { combineReducers } from 'redux'
import {createSlice} from "@reduxjs/toolkit";
import shopCartReducer from './shoppingCart'
import * as ActionTypes from '../actions'
import { SUCCESS } from '../actions'

function hasPigCard(state=null, { type, data }) {
  switch(type) {
    case ActionTypes.HAS_PIG_CARD[SUCCESS]:
      return data
    default:
      return state
  }
}

function hasSSO(state=null, { type, data }) {
  switch(type) {
    case ActionTypes.REGISTRATION_RESULT:
      return data
    default:
      return state
  }
}

function verifyMobileResult(state={}, { type, data }) {
  switch(type) {
    case ActionTypes.VERIFY_MOBILE[SUCCESS]:
      return data
    default:
      return state
  }
}

function displayAddManager(state=false, { type }) {
  switch(type) {
    case ActionTypes.SHOW_ADD_MANAGER:
      return true
    case ActionTypes.HIDE_ADD_MANAGER:
      return false
    default:
      return state
  }
}

function memberRenew(state={}, { type, data }) {
  switch(type) {
    case ActionTypes.MEMBER_RENEW[SUCCESS]:
      return data
    default :
      return state
  }
}

function storeRenew(state={}, { type, data }) {
  switch(type) {
    case ActionTypes.STORE_RENEW[SUCCESS]:
      return data
    default :
      return state
  }
}

export const dmFormInfo = createSlice({
  name: "dmForm",
  initialState: {},
  reducers: {
    submitForm: {
      reducer(state, action){
        const data = action.payload;
        return data
      }
    },
    submitFormRequest: {
      reducer(state, action) {
        return state
      }
    },
    submitFormSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return { ...data }
      }
    },
    submitFormFailed: {
      reducer(state, action) {
        return state
      }
    }
  }
});

export const activityListSlice = createSlice({
  name: "activityList",
  initialState: {},
  reducers: {
    activityListRequest: {
      reducer(){
        return {}
      }
    },
    activityListSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    activityListFailed: {
      reducer(){
        return {}
      }
    }
  }
})

export const { 
  submitForm, 
  submitFormRequest, 
  submitFormSuccess, 
  submitFormFailed } = dmFormInfo.actions;

const { activityListRequest, activityListSuccess, activityListFailed } = activityListSlice.actions

export const activityList = {
  request: () => activityListRequest(),
  success: (data) => activityListSuccess(data),
  failure: (error) => activityListFailed(error),
}

export const submitDmForm = {
  request: (data) => submitFormRequest(data),
  success: (data) => submitFormSuccess(data),
  failure: (error) => submitFormFailed(error),
}

export const bookSaleListSlice = createSlice({
  name: "bookSaleList",
  initialState: {
    data: [],
    submitItems: []
  },
  reducers: {
    bookSaleListRequest: () => {},
    bookSaleListSuccess: (state, action) => {
      state.data = action.payload
    },
    bookSaleListFailed: () => {},
    changeAmount: (state, action) => {
      const { payload } = action
      const filterSameItem = state.submitItems.find(item => item.goodsId === payload.goodsId && item.skuId === payload.skuId)
      if(filterSameItem) {
        const newList = state.submitItems.map(item => {
          if(JSON.stringify(item) === JSON.stringify(filterSameItem)) {
            return {
              ...item,
              number: payload.number
            }
          }
          return item
        })
        state.submitItems = newList
      }else {
        state.submitItems.push(payload)
      }
      const newSubmitList = state.submitItems.filter(item => item.number > 0)
      state.submitItems = newSubmitList
    }
  }
})

export const { bookSaleListRequest, bookSaleListSuccess, bookSaleListFailed, changeAmount } = bookSaleListSlice.actions

export const bookSaleList = {
  request: () => bookSaleListRequest(),
  success: (data) => bookSaleListSuccess(data),
  failure: (error) => bookSaleListFailed(error),
}

export const memberLibrarySlice = createSlice({
  name: "memberLibrary",
  initialState: [],
  reducers: {
    memberLibraryRequest: {
      reducer(){
        return []
      }
    },
    memberLibrarySuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    memberLibraryFailed: {
      reducer(){
        return []
      }
    }
  }
})

const { memberLibraryRequest, memberLibrarySuccess, memberLibraryFailed } = memberLibrarySlice.actions

export const memberLibraryList = {
  request: () => memberLibraryRequest(),
  success: (data) => memberLibrarySuccess(data),
  failure: (error) => memberLibraryFailed(error),
}

export const dmFormCounterSlice = createSlice({
  name: "dmFormCounter",
  initialState: {},
  reducers: {
    dmFormCounterRequest: {
      reducer(){
        return {}
      }
    },
    dmFormCounterSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    dmFormCounterFailed: {
      reducer(){
        return {}
      }
    },
    instrucInfoRequest: {
      reducer(state, action){
        const { instructorInfoDTO, ...dmInfo } = state
        return {instructorInfoDTO: {loading: true, instructorNumber: action.payload}, ...dmInfo}
      }
    },
    instrucInfoSuccess: {
      reducer(state, action) {
        const data = action.payload;
        const { instructorInfoDTO, ...dmInfo } = state
        return {instructorInfoDTO: data, ...dmInfo}
      }
    },
    instrucInfoFailed: {
      reducer(state){
        const { instructorInfoDTO, ...dmInfo } = state
        return {instructorInfoDTO: {loading: false}, ...dmInfo}
      }
    },
    uploadDmFile: {
      reducer(state){
        return state
      }
    },
    uploadDmFileRequest: {
      reducer(state){
        return state
      }
    },
    uploadDmFileSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return state
      }
    },
    uploadDmFileFailed: {
      reducer(state){
        return state
      }
    },
    getDmOssFileRequest: {
      reducer(state){
        return state
      }
    },
    getDmOssFileSuccess: {
      reducer(state, action) {
        const data = action.payload;
        const url = data.replace('https://files.padi.com.cn/', '')
        const certType = url.split('/')[2]
        if(certType === 'AOW') {
          const { aowFiles, ...detail } = state
          return { aowFiles: aowFiles ? [ data, ...aowFiles ] : [data], ...detail }
        }else if(certType === 'RESCUE') {
          const { rescueFiles, ...detail } = state
          return { rescueFiles: rescueFiles ? [ data, ...rescueFiles ] : [data], ...detail }
        }else if(certType === 'EFR') {
          const { efrfiles, ...detail } = state
          return { efrfiles: efrfiles ? [ data, ...efrfiles ] : [data], ...detail }
        }
      }
    },
    getDmOssFileFailed: {
      reducer(state){
        return state
      }
    },
    removeDmFile: {
      reducer(state, action) {
        const data = action.payload;
        const url = data.replace('https://files.padi.com.cn/', '')
        const certType = url.split('/')[2]
        if(certType === 'AOW') {
          const { aowFiles, ...detail } = state
          const newAowfiles = aowFiles.filter(item => item !== data)
          return { aowFiles: newAowfiles, ...detail }
        }else if(certType === 'RESCUE') {
          const { rescueFiles, ...detail } = state
          const newRescuefiles = rescueFiles.filter(item => item !== data)
          return { rescueFiles: newRescuefiles, ...detail }
        }else if(certType === 'EFR') {
          const { efrfiles, ...detail } = state
          const newEfrfiles = efrfiles.filter(item => item !== data)
          return { efrfiles: newEfrfiles, ...detail }
        }
      }
    },
  }
})

export const uploadDmAppFile = {
  request: () => uploadDmFileRequest(),
  success: (data) => uploadDmFileSuccess(data),
  failure: (error) => uploadDmFileFailed(error),
}

export const getDmOssFile = {
  request: () => getDmOssFileRequest(),
  success: (data) => getDmOssFileSuccess(data),
  failure: (error) => getDmOssFileFailed(error),
}

export const instructorInfo = {
  request: (data) => instrucInfoRequest(data),
  success: (data) => instrucInfoSuccess(data),
  failure: (error) => instrucInfoFailed(error),
}

// export const storeInfo = {
//   request: (data) => storeInfoRequest(data),
//   success: (data) => storeInfoSuccess(data),
//   failure: (error) => storeInfoFailed(error),
// }

export const { 
  dmFormCounterRequest, 
  dmFormCounterSuccess, 
  dmFormCounterFailed, 
  instrucInfoRequest,
  instrucInfoSuccess,
  instrucInfoFailed,
  // storeInfoRequest,
  // storeInfoSuccess,
  // storeInfoFailed,
  uploadDmFile,
  uploadDmFileRequest, 
  uploadDmFileSuccess,
  uploadDmFileFailed,
  getDmOssFileRequest,
  getDmOssFileSuccess,
  getDmOssFileFailed,
  removeDmFile
} = dmFormCounterSlice.actions

export const dmFormCounter = {
  request: () => dmFormCounterRequest(),
  success: (data) => dmFormCounterSuccess(data),
  failure: (error) => dmFormCounterFailed(error),
}

export const qualificationsSlice = createSlice({
  name: "qualifications",
  initialState: '',
  reducers: {
    certificationRequest: {
      reducer(){
        return ''
      }
    },
    certificationSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    certificationFailed: {
      reducer(){
        return ''
      }
    },
    credentialRequest: {
      reducer(){
        return ''
      }
    },
    credentialSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    credentialFailed: {
      reducer(){
        return ''
      }
    }
  }
})

export const instructorApplicationSlice = createSlice({
  name: "instructorApplication",
  initialState: [],
  reducers: {
    skipApplicationPage: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    instructorApplicationRequest: {
      reducer(){
        return []
      }
    },
    instructorApplicationSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    instructorApplicationFailed: {
      reducer(){
        return []
      }
    }
  }
})

export const { instructorApplicationRequest, instructorApplicationSuccess, instructorApplicationFailed, skipApplicationPage } = instructorApplicationSlice.actions

export const instructorApplication = {
  request: () => instructorApplicationRequest(),
  success: (data) => instructorApplicationSuccess(data),
  failure: (error) => instructorApplicationFailed(error),
}

export const instructorConfirmDetailSlice = createSlice({
  name: "instructorConfirmDetail",
  initialState: {},
  reducers: {
    requestConfirmDetail: {
      reducer(){
        return {}
      }
    },
    instructorConfirmDetailRequest: {
      reducer(){
        return {}
      }
    },
    instructorConfirmDetailSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    instructorConfirmDetailFailed: {
      reducer(){
        return {}
      }
    }
  }
})

export const { requestConfirmDetail, instructorConfirmDetailRequest, instructorConfirmDetailSuccess, instructorConfirmDetailFailed } = instructorConfirmDetailSlice.actions

export const instructorConfirmDetail = {
  request: () => instructorConfirmDetailRequest(),
  success: (data) => instructorConfirmDetailSuccess(data),
  failure: (error) => instructorConfirmDetailFailed(error),
}

export const signatureSlice = createSlice({
  name: "signature",
  initialState: null,
  reducers: {
    submitSignature: {
      reducer(state, action){
        const data = action.payload;
        return data
      }
    },
    submitInstructorSignature: {
      reducer(state, action){
        const data = action.payload;
        return data
      }
    },
    signatureRequest: {
      reducer(){
        return null
      }
    },
    signatureSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    ossSignatureSuccess: {
      reducer() {
        return null
      }
    },
    signatureFailed: {
      reducer(){
        return null
      }
    },
    clearSignature: {
      reducer() {
        return null
      }
    },
    setSignature: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    }
  }
})

export const { submitSignature, signatureRequest, signatureSuccess, signatureFailed, clearSignature, submitInstructorSignature, setSignature, ossSignatureSuccess } = signatureSlice.actions

export const getSignatureRequest = {
  request: () => signatureRequest(),
  success: (data) => signatureSuccess(data),
  failure: (error) => signatureFailed(error),
}

export const submitSignatureRequest = {
  request: () => signatureRequest(),
  success: (data) => ossSignatureSuccess(data),
  failure: (error) => signatureFailed(error),
}

export const lightenElearningSlice = createSlice({
  name: "lightenElearning",
  initialState: null,
  reducers: {
    clearLightenElearning: {
      reducer(){
        return null
      }
    },
    lightenElearningRequest: {
      reducer(){
        return null
      }
    },
    lightenElearningSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    lightenElearningFailed: {
      reducer(){
        return null
      }
    }
  }
})

export const { clearLightenElearning, lightenElearningRequest, lightenElearningSuccess, lightenElearningFailed } = lightenElearningSlice.actions

export const lightenElearning = {
  request: () => lightenElearningRequest(),
  success: (data) => lightenElearningSuccess(data),
  failure: (error) => lightenElearningFailed(error),
}

export const uploadFileRequest = {
  request: () => uploadPhotoRequest(),
  success: (data) => uploadPhotoSuccess(data),
  failure: (error) => uploadPhotoFailed(error),
}

export const userPhotoSlice = createSlice({
  name: "userPhoto",
  initialState: null,
  reducers: {
    getUserPhoto: {
      reducer(state, action){
        const data = action.payload;
        return data
      }
    },
    userPhotoRequest: {
      reducer(){
        return { isLoading: true }
      }
    },
    userPhotoSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    userPhotoFailed: {
      reducer(){
        return null
      }
    },
    uploadPhoto: {
      reducer(){
        return { isLoading: true }
      }
    },
    uploadPhotoRequest: {
      reducer(){
        return { isLoading: true }
      }
    },
    uploadPhotoSuccess: {
      reducer() {
        return { isLoading: true }
      }
    },
    uploadPhotoFailed: {
      reducer(){
        return { isLoading: true }
      }
    }
  }
})

export const { 
  getUserPhoto, 
  userPhotoRequest, 
  userPhotoSuccess, 
  userPhotoFailed, 
  uploadPhoto, 
  uploadPhotoRequest, 
  uploadPhotoSuccess, 
  uploadPhotoFailed } = userPhotoSlice.actions

export const getUserPhotoRequest = {
  request: () => userPhotoRequest(),
  success: (data) => userPhotoSuccess(data),
  failure: (error) => userPhotoFailed(error),
}

export const siAppDetailSlice = createSlice({
  name: "siAppDetail",
  initialState: null,
  reducers: {
    getSidetailRequest: {
      reducer(state){
        return state
      }
    },
    getSidetailSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    getSidetailFailed: {
      reducer(state){
        return state
      }
    },
    addSi: {
      reducer(state, action) {
        const data = action.payload;
        if(state) {
          const { siApplicationDetailDTOS, ...detail } = state
          let newSiAppList = []
          newSiAppList = siApplicationDetailDTOS ? [...siApplicationDetailDTOS, data] : [data]
          return { siApplicationDetailDTOS: newSiAppList, ...detail }
        }else {
          let newSiAppList = []
          newSiAppList = [data]
          return { siApplicationDetailDTOS: newSiAppList }
        }
      }
    },
    removeSi: {
      reducer(state, action) {
        const data = action.payload;
        const { siApplicationDetailDTOS, ...detail } = state
        let newSiAppList = []
        newSiAppList = siApplicationDetailDTOS.filter(i => i.credentialId !== data.credentialId)
        return { siApplicationDetailDTOS: newSiAppList, ...detail }
      }
    },
    uploadSifile: {
      reducer(state){
        return state
      }
    },
    uploadSiFileRequest: {
      reducer(state){
        return state
      }
    },
    uploadSiFileSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return state
      }
    },
    uploadSiFileFailed: {
      reducer(state){
        return state
      }
    },
    removeSiFile: {
      reducer(state, action) {
        const data = action.payload;
        const { siApplicationDetailDTOS, ...detail } = state
        const siDetail = siApplicationDetailDTOS.find(item => item.credentialId === data.credentialId)
        const newAttachList = siDetail.attachFile.filter(item => item !== data.url)
        let newSiAppDetailList = []
        if(!newAttachList.length > 0) {
          newSiAppDetailList = siApplicationDetailDTOS.filter(item => item.credentialId !== data.credentialId)
        }else {
          newSiAppDetailList = siApplicationDetailDTOS.map(item => {
            if(item.credentialId === data.credentialId) return { credentialId: data.credentialId, attachFile: newAttachList }
            return item
          })
        }
        return { siApplicationDetailDTOS: newSiAppDetailList, ...detail }
      }
    },
    getOssFileRequest: {
      reducer(state){
        return state
      }
    },
    getOssFileSuccess: {
      reducer(state, action) {
        const data = action.payload;
        const url = data.replace('https://files.padi.com.cn/', '')
        const id = parseInt(url.split('/')[2])
        if(state) {
          const { siApplicationDetailDTOS, ...detail } = state
          const hasSameSi = siApplicationDetailDTOS && siApplicationDetailDTOS.find(item => item.credentialId === id)
          if(hasSameSi) {
            const newSiAppDetail = siApplicationDetailDTOS.map(item => {
              if(item.credentialId === id) {
                const { attachFile, credentialId } = item
                const newAttachFile = [...attachFile, data]
                return { attachFile: newAttachFile, credentialId }
              }
              return item
            })
            return {...detail, siApplicationDetailDTOS: newSiAppDetail}
          }else {
            return {...detail, siApplicationDetailDTOS: siApplicationDetailDTOS ? [...siApplicationDetailDTOS, { credentialId: id, attachFile: [data] }] : [{ credentialId: id, attachFile: [data] }]}
          }
        }else {
          return { siApplicationDetailDTOS: [{ credentialId: id, attachFile: [data] }] }
        }
      }
    },
    getOssFileFailed: {
      reducer(state){
        return state
      }
    },
    clearSiDetail: {
      reducer(){
        return null
      }
    },
  }
})

export const { 
  getSidetailRequest, 
  getSidetailSuccess, 
  getSidetailFailed, 
  addSi,
  removeSi,
  uploadSifile,
  uploadSiFileRequest,
  uploadSiFileSuccess,
  uploadSiFileFailed,
  removeSiFile,
  getOssFileRequest,
  getOssFileSuccess,
  getOssFileFailed,
  clearSiDetail } = siAppDetailSlice.actions

export const getSiAppDetailRequest = {
  request: () => getSidetailRequest(),
  success: (data) => getSidetailSuccess(data),
  failure: (error) => getSidetailFailed(error),
}

export const uploadAttachFile = {
  request: () => uploadSiFileRequest(),
  success: (data) => uploadSiFileSuccess(data),
  failure: (error) => uploadSiFileFailed(error),
}

export const getOssFile = {
  request: () => getOssFileRequest(),
  success: (data) => getOssFileSuccess(data),
  failure: (error) => getOssFileFailed(error),
}

export const siAppListSlice = createSlice({
  name: "siAppList",
  initialState: null,
  reducers: {
    getSiAppList: {
      reducer(state, action){
        const data = action.payload;
        return data
      }
    },
    siAppListRequest: {
      reducer(state){
        return state
      }
    },
    siAppListSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    siAppListFailed: {
      reducer(state){
        return state
      }
    }
  }
})

export const { getSiAppList, siAppListRequest, siAppListSuccess, siAppListFailed } = siAppListSlice.actions

export const getSiAppListRequest = {
  request: () => siAppListRequest(),
  success: (data) => siAppListSuccess(data),
  failure: (error) => siAppListFailed(error),
}

export const siDetailInProcessSlice = createSlice({
  name: "siDetailInProcess",
  initialState: null,
  reducers: {
    siDetailInProcessRequest: {
      reducer(state){
        return state
      }
    },
    siDetailInProcessSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    siDetailInProcessFailed: {
      reducer(state){
        return state
      }
    },
    uploadDetailAttach: {
      reducer(state){
        return state
      }
    },
    detailAttachRequest: {
      reducer(state){
        return state
      }
    },
    detailAttachSuccess: {
      reducer(state){
        return state
      }
    },
    detailAttachFailed: {
      reducer(state){
        return state
      }
    },
    clearDetailAttach: {
      reducer(state, action){
        const data = action.payload;
        const { attachFile, ...detail } = state
        const newAttachFile = attachFile.filter(item => item !== data.url) 
        return { attachFile: newAttachFile, ...detail }
      }
    },
    submitInProcess: {
      reducer(state){
        return state
      }
    },
    submitInProcessRequest: {
      reducer(state){
        return state
      }
    },
    submitInProcessSuccess: {
      reducer(state){
        return state
      }
    },
    submitInProcessFailed: {
      reducer(state){
        return state
      }
    },
    submitCdDetailInProcess: {
      reducer(state){
        return state
      }
    },
    getDetailOssFileRequest: {
      reducer(state){
        return state
      }
    },
    getDetailOssFileSuccess: {
      reducer(state, action) {
        const data = action.payload;
        const { attachFile, ...detail } = state
        return { attachFile: [...attachFile, data], ...detail }
      }
    },
    getDetailOssFileFailed: {
      reducer(state){
        return state
      }
    },
  }
})

export const { 
  siDetailInProcessRequest, 
  siDetailInProcessSuccess, 
  siDetailInProcessFailed, 
  uploadDetailAttach, 
  detailAttachRequest, 
  detailAttachSuccess, 
  detailAttachFailed,
  clearDetailAttach,
  submitInProcess,
  submitInProcessRequest,
  submitInProcessSuccess,
  submitInProcessFailed, 
  submitCdDetailInProcess,
  getDetailOssFileRequest,
  getDetailOssFileSuccess,
  getDetailOssFileFailed } = siDetailInProcessSlice.actions

export const uploadDetailAttachRequest = {
  request: () => detailAttachRequest(),
  success: (data) => detailAttachSuccess(data),
  failure: (error) => detailAttachFailed(error),
}

export const getSiDetailInProcessRequest = {
  request: () => siDetailInProcessRequest(),
  success: (data) => siDetailInProcessSuccess(data),
  failure: (error) => siDetailInProcessFailed(error),
}

export const submitSiInprocessRequest = {
  request: () => submitInProcessRequest(),
  success: (data) => submitInProcessSuccess(data),
  failure: (error) => submitInProcessFailed(error),
}

export const getDetailOssFile = {
  request: () => getDetailOssFileRequest(),
  success: (data) => getDetailOssFileSuccess(data),
  failure: (error) => getDetailOssFileFailed(error),
}

export const studentInfoSlice = createSlice({
  name: "studentInfo",
  initialState: {},
  reducers: {
    getStudentInfo: {
      reducer(state, action){
        const data = action.payload;
        return data
      }
    },
    studentInfoRequest: {
      reducer(state){
        return {loading: true}
      }
    },
    studentInfoSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return {loading: false, data}
      }
    },
    studentInfoFailed: {
      reducer(state){
        return {loading: false}
      }
    },
    clearStudentInfo: {
      reducer() {
        return null
      }
    }
  }
})

export const { getStudentInfo, studentInfoRequest, studentInfoSuccess, studentInfoFailed, clearStudentInfo } = studentInfoSlice.actions

export const getStudentInfoRequest = {
  request: () => studentInfoRequest(),
  success: (data) => studentInfoSuccess(data),
  failure: (error) => studentInfoFailed(error),
}

export const cdSiAppListSlice = createSlice({
  name: "cdSiAppList",
  initialState: [],
  reducers: {
    cdSiAppListRequest: {
      reducer(state){
        return state
      }
    },
    cdSiAppListSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    cdSiAppListFailed: {
      reducer(state){
        return state
      }
    }
  }
})

export const { cdSiAppListRequest, cdSiAppListSuccess, cdSiAppListFailed } = cdSiAppListSlice.actions

export const getCdSiAppListRequest = {
  request: () => cdSiAppListRequest(),
  success: (data) => cdSiAppListSuccess(data),
  failure: (error) => cdSiAppListFailed(error),
}

export const creditsSlice = createSlice({
  name: "credits",
  initialState: null,
  reducers: {
    creditsRequest: {
      reducer(state){
        return state
      }
    },
    creditsSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    creditsFailed: {
      reducer(state){
        return state
      }
    }
  }
})

export const { creditsRequest, creditsSuccess, creditsFailed } = creditsSlice.actions

export const getCreditsRequest = {
  request: () => creditsRequest(),
  success: (data) => creditsSuccess(data),
  failure: (error) => creditsFailed(error),
}

export const searchStoreInfoSlice = createSlice({
  name: "searchStoreInfo",
  initialState: {},
  reducers: {
    storeInfoRequest: {
      reducer(){
        return {loading: true}
      }
    },
    storeInfoSuccess: {
      reducer(state, action) {
        const data = action.payload;
        return data
      }
    },
    storeInfoFailed: {
      reducer(){
        return {}
      }
    },
    clearStoreInfo: {
      reducer() {
        return {}
      }
    }
  }
})

export const { storeInfoRequest, storeInfoSuccess, storeInfoFailed, clearStoreInfo } = searchStoreInfoSlice.actions

export const storeInfo = {
  request: (data) => storeInfoRequest(data),
  success: (data) => storeInfoSuccess(data),
  failure: (error) => storeInfoFailed(error),
}

const uiReducer = combineReducers({
    shoppingCart: shopCartReducer,
    hasPigCard: hasPigCard,
    hasSSO: hasSSO,
    verifyMobileResult,
    displayAddManager,
    memberRenew,
    storeRenew,
    dmFormInfo: dmFormInfo.reducer,
    activityList: activityListSlice.reducer,
    bookSaleList: bookSaleListSlice.reducer,
    memberLibrary: memberLibrarySlice.reducer,
    dmFormCounter: dmFormCounterSlice.reducer,
    qualifications: qualificationsSlice.reducer,
    instructorApplication: instructorApplicationSlice.reducer,
    instructorConfirmDetail: instructorConfirmDetailSlice.reducer,
    signatureUrl: signatureSlice.reducer,
    lightenElearningUrl: lightenElearningSlice.reducer,
    getUserPhoto: userPhotoSlice.reducer,
    siDetail: siAppDetailSlice.reducer,
    siAppList: siAppListSlice.reducer,
    siDetailInProcess: siDetailInProcessSlice.reducer,
    studentInfo: studentInfoSlice.reducer,
    cdSiAppList: cdSiAppListSlice.reducer,
    credits: creditsSlice.reducer,
    searchStoreInfo: searchStoreInfoSlice.reducer
  })

export default uiReducer
