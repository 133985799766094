import React from 'react'
import { Form, Input } from 'antd-mobile-v5';

const PhoneOptions = ({ form }) => {
  return <>
    <Form.Item
      name="invoiceMobile"
      label={'手機載具'}
      rules={[
        {
          required: true,
          message: '手機載具不能為空。',
        },
        { required: true,
          pattern: /^\/[^a-z]{7}$/, 
          message: '請輸入以”/“開頭且不能為小寫字母的8位字符' 
        }
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name="invoiceMobileAgain"
      label={'再次確認手機載具'}
      rules={[
        {
          required: true,
          message: '請再次輸入手機載具。',
        },
        {
          validator: (_, value) => {
            if (!value || form.getFieldValue('invoiceMobile') === value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error("兩次輸入手機載具不一致，請重新輸入。"));
          }
        }
      ]}
    >
      <Input />
    </Form.Item>
  </>
}

export default PhoneOptions