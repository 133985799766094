import { getCookie } from "../components/common/cookie";

export const PASSWORD_PATTERN = /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{8,20}/;
export const NAME_PATTERN = /^[A-Za-z]+\s*[A-Za-z]+$/;

export const isAuthenticated = () => getCookie('padiWxId');
export const getPadiWxId = () => getCookie('padiWxId');

export const isMainlandUser = (userInfo) => userInfo?.addressDTO?.countryISOCode === 'CN';

export const isMainlandPro = (profile) => profile.teachingLocation === 'CN';
export const isNonMainlandPro = (profile) => ['TW', 'HK', 'MO'].indexOf(profile.teachingLocation) >= 0;
export const isPgcPro = (profile) => isMainlandPro(profile) || isNonMainlandPro(profile);
