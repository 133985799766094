import dayjs from 'dayjs';
import { isProduction } from './env';

const NO_PLASTIC_CARD_BY_NAME = [
  'Discover Scuba Diving',
  'PADI Seal Team',
  'PADI Master Seal Team',
  'Tec',
  'Public Safety',
  'Care for Children w/AED',
  'EFR Public',
  'Midway',
  'PADI Discover Mermaid',
  'PADI Basic Mermaid',
  'PADI Mermaid',
  'PADI Advanced Mermaid',
  'Care For Children Trainer',
  'ClownFish ID',
  'PADI Dolphin AWARE',
  'Dolphin Conservation',
  'PADI Mobulidae AWARE',
  'Mobulidae Conservation',
  'TURTLE AWARE-FREE DIVER',
  'Turtle Protection Freediver',
  'TURTLE AWARE Distinctive Specialty',
  'Turtle Protection Distinctive',
  'SEAHORSE DIVER',
  'Seahorse Conservation',
  'Cleanup of COT Starfish',
  'COT Starfish Cleanup',
  'U/W Surveying and Mapping',
  'Inflatable Dinghy Diving',
  'Youcan Robot U/W',
  'Freshwater Jellyfish',
  'Mermaid Performance',
];
export const hasPlasticCard = (name) => {
  return !NO_PLASTIC_CARD_BY_NAME.find((n) => name.indexOf(n) >= 0);
};

export const isCertId = (id) => id.length === 10;

export const fullAddress = ({
  addressLine1,
  addressLine2,
  addressLine3,
  state,
  country,
}) =>
  addressLine1
    ? `${addressLine1} ${addressLine2} ${addressLine3}
    ${state} ${country}`
    : '';

export const allEcards = ({ certifications = [], credentials = [] } = {}) =>
  certifications
    .concat(credentials)
    .sort((c1, c2) => dayjs(c2.issueDate).diff(dayjs(c1.issueDate)));

export const isCert = (c) => !!c.certificationNumber;
export const isCred = (c) => !isCert(c);
export const isFd = (c) => isCert(c) && c.ecardName.indexOf('Freediver') >= 0;
export const isFdCred = (c) =>
  isCred(c) && c.ecardName.indexOf('Freediver') >= 0;
export const isEfr = (c) =>
  c.ecardName.indexOf('EFR') === 0 || c.ecardName.indexOf('Care ') === 0;
export const isEfrCert = (c) => isCert(c) && isEfr(c);
export const isEfrCred = (c) => isCred(c) && isEfr(c);

export const isMermaidPerformancerInstructor = (c) =>
  isProduction() ? [5245, 12985, 12986].indexOf(c.credentialNumber) >= 0
                 : [5241, 12977, 12978].indexOf(c.credentialNumber) >= 0;
export const isMermaidPerformancer = (c) => isProduction() ? 5245 === c.certificationNumber : 5241 === c.certificationNumber;
export const isMermaidCert = (c) =>
  [4992, 4995, 4996, 4997].indexOf(c.certificationNumber) >= 0;
export const isDiscoverMermaidCert = (c) => [4992].indexOf(c.certificationNumber) >= 0
export const isMidwayDiverCert = (c) => [5125].indexOf(c.certificationNumber) >= 0
export const isJuniorMermaid = (c) => isMermaidCert(c) && c?.cardTextDTOS[0]?.cardTextLine.indexOf("Junior") > -1;
export const isMermaidCred = (c) =>
  [12485, 12487, 12488].indexOf(c.credentialNumber) >= 0;
export const isMidwayDiverCred = (c) =>
  [12745, 12746].indexOf(c.credentialNumber) >= 0;
export const isMermaid = (c) => isDiscoverMermaidCert(c) || isMermaidCred(c) || isMermaidPerformancer(c) || isMermaidPerformancerInstructor(c);
export const isMidwayDiver = (c) => isMidwayDiverCert(c) || isMidwayDiverCred(c);

const EFRForApp = [
  {
    id: 20,
    name: 'EFR-Primary Care (CPR) & Sec Care (1st aid)',
  },
  {
    id: 31,
    name: 'EFR - Primary Care (CPR) & Sec Care (1st) w/ AED',
  },
  {
    id: 68,
    name: 'EFR - CPR/FirstAid/Care for Children',
  },
  {
    id: 76,
    name: 'EFR - CPR/First Aid/Care for Children w/ AED',
  },
];
export const findEcardTypeRequired = (ecards, requiredType) => {
  if (requiredType === 'efr')
    return ecards.find((c) =>
      isCert(c)
        ? EFRForApp.find(
            (e) =>
              e.id === c.typeNumber &&
              dayjs(e.certificationDate).add(24, 'months').isAfter(dayjs())
          )
        : c.typeNumber === 35 || c.typeNumber === 38 // Instructor/Trainer
    );
  if (requiredType === 'aow')
    return ecards.find((c) => c.certificationNumber === 26);
  if (requiredType === 'rescue')
    return ecards.find((c) => c.certificationNumber === 72);
};
