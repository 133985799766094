import React, { useState } from 'react';
import { List, Accordion, WhiteSpace } from 'antd-mobile';
import { Image, Popup } from 'antd-mobile-v5'
import { FormattedMessage } from 'react-intl';

import styles from './Menu.module.css'

const CourseInsuranceMenu = ({ isStore, isInstructor }) => {

  const [visible, setVisible] = useState(false)
  const codeUrl = isStore ? require('../../assets/images/insurance/store_code.jpg') : isInstructor ? require('../../assets/images/insurance/instructor_code.jpg') : ''

  return <>
    <Popup
      visible={visible}
      onMaskClick={() => {
        setVisible(false)
      }}
      bodyStyle={{
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        minHeight: '40vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Image src={codeUrl} width={150} height={150} />
    </Popup>
    <Accordion defaultActiveKey="0">
      <Accordion.Panel header={<FormattedMessage id="profile-course-insurance" />} className="profile-list member-renew">
        <List>
          <List.Item arrow="horizontal" onClick={() => window.location.href = 'https://cps.xiebao18.com/heb2073246/product/detail-104926-131221.html'}>
            <span className={styles.menuItem}>
              <FormattedMessage id="profile-insurance-shopPublic" />
            </span>
          </List.Item>
          <List.Item
            arrow="horizontal"
            onClick={() => window.location.href = 'https://cps.xiebao18.com/heb2073246/product/detail-104928-131227.html'}
          >
            <span className={styles.menuItem}>
              <FormattedMessage id="profile-insurance-instructor" />
            </span>
          </List.Item>
          <List.Item
            arrow="horizontal"
            onClick={() => setVisible(true)}
          >
            <span className={styles.menuItem}>
              <FormattedMessage id="profile-insurance-diver" />
            </span>
          </List.Item>
        </List>
      </Accordion.Panel>
    </Accordion>
    <WhiteSpace size="sm" />
  </>
}

export default CourseInsuranceMenu