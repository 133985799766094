import { connect, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, Radio } from 'antd-mobile';
import { Dialog } from 'antd-mobile-v5'
import { push } from 'connected-react-router';
import { useSelector } from 'react-redux';
import React, { useState } from 'react';

import {
  errorDialog,
  pageLoaded,
  pageLoading,
} from '../../actions';
import { localizeResource } from '../../services/utilities';
import { getCookie } from '../../components/common/cookie';
import { getParameterByName, gtm } from '../../components/common/utils';
import { getCampRenewal } from '../../services/api';

import './Campaign.css';
import { useHistory } from 'react-router-dom';
import { isMainlandUser } from '../../utils/user';
import dayjs from 'dayjs';
import HaiHangLink from '../../components/HaiHangLink';
import { useHasLogin } from '../../services/hooks';

const toPadiGear = (isMmber) => {
  const memberStatus = isMmber ? 'member' : 'diver';
  gtm('Click - PADI Gear Campaign', memberStatus);
  window.location.href =
    'https://shop18875151.youzan.com/v2/showcase/homepage?alias=qh6posnk';
};

const CampaignPage = () => {
  const testonly = getParameterByName('testonly');
  const { formatMessage } = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const [showCouponSuccessDialogue, setShowCouponSuccessDialogue] = useState(
    false
  );
  const [showTicketDialogue, setShowTicketDialogue] = useState(false);
  const [showTicketSuccessDialogue, setShowTicketSuccessDialogue] = useState(
    false
  );
  const [countOfBasic, setCountOfBasic] = useState(1);
  const [countOfHotel, setCountOfHotel] = useState(0);
  const [countOfHomeStay, setCountOfHomeStay] = useState(0);
  const { data } = useHasLogin({
    manual: false
  });

  const updateCouponCount = (count, type) => {
    if (count === 1) {
      if (countOfBasic + countOfHotel + countOfHomeStay === 2) {
        alert(formatMessage({ id: 'campaign-festival-max-tickets-error' }));
      } else {
        if (type === 'basic') setCountOfBasic(countOfBasic + 1);
        if (type === 'hotel') setCountOfHotel(countOfHotel + 1);
        if (type === 'homestay') setCountOfHomeStay(countOfHomeStay + 1);
      }
    } else if (count === -1) {
      if (type === 'basic') setCountOfBasic(countOfBasic ? countOfBasic - 1 : 0);
      if (type === 'hotel') setCountOfHotel(countOfHotel ? countOfHotel - 1 : 0);
      if (type === 'homestay') setCountOfHomeStay(countOfHomeStay ? countOfHomeStay - 1 : 0);
    }
  }

  const { activityList, isMember, activity, userInfo } = useSelector((state) => ({
    activity: state.ui.activityList,
    activityList: state.ui.activityList.activityListItemDTOS,
    isMember: state.ui.activityList.isMember,
    userInfo: state.data.userInfo,
  }));

  const language = getCookie('language');
  const questionnaireImg = localizeResource('questionnaire.jpg', language);
  const newYearImg = localizeResource('new-year.jpg', language);
  const renewalImg = localizeResource('renewal.jpg', language);
  const regulatorImg = localizeResource('regulator-coupon.jpg', language)

  const getRenewalCoupon = async () => {
    try {
      dispatch(pageLoading());
      const results = await getCampRenewal();
      if (results.error) {
        dispatch(errorDialog(results.error.clientMsg));
        return;
      }
      setShowTicketSuccessDialogue(true);
      setShowCouponSuccessDialogue(true);
    } catch (e) {
      dispatch(errorDialog(e));
    } finally {
      setShowTicketDialogue(false);
      dispatch(pageLoaded());
    }
  };

  const showWenjuan = () => {
    Dialog.show({
      title: formatMessage({id: 'questionaire-title'}),
      content: 
        formatMessage(
          { id: 'questionaire-message' },
          { course: formatMessage({id: `questionaire-course-${data.wenJuan.type}`}) },
        ),
      showCloseButton: true,
      closeOnAction: true,
      actions: [[
        {
          key: 'cancel',
          text: formatMessage({id: 'common-cancel'}),
        },
        {
          key: 'open',
          bold: true,
          text: formatMessage({id: 'common-confirm'}),
          primary: true,
          onClick: () => {
            window.location.href = data.wenJuan.url
          }
        },]
      ],
    });
  }

  if (Object.keys(activity).length <= 0) return null;
  return <>
    <div className="campaign-list">
      { dayjs().isAfter('2023-11-23') && 
        activityList.find((activity) => activity.type === 'REGULATOR_COUPON') && (
        <div onClick={() => history.push('/regulator_coupon')}>
          <img
            src={require(`../../assets/images/campaign/campaignPage/${regulatorImg}`)}
            alt="regulator"
          />
        </div>
      )}
      <div>
        <HaiHangLink>
          <img
            style={{width: "calc(100% - 10px)"}}
            src="https://assets.padi.com.cn/hnac.jpg"
            alt="hai hang"
          />
        </HaiHangLink>
      </div>
      <div onClick={() => history.push('/buy_cards')}>
        <img
          src={require(`../../assets/images/campaign/campaignPage/cards.jpg`)}
          alt="gear"
        />
      </div>
      {isMainlandUser(userInfo) && dayjs().isBefore('2022-09-01') && <div onClick={() => history.push('/sea_team/post')}>
        <img
          src={require(`../../assets/images/campaign/campaignPage/seateam-sc.jpg`)}
          alt="gear"
        />
      </div>}
      {isMember && activityList.find(
        activity => activity.type === 'RENEWAL_2021_COUPON'
      ) && (
          <>
            <div onClick={() => getRenewalCoupon()}>
              <img
                src={require(`../../assets/images/campaign/campaignPage/${renewalImg}`)}
                alt="PADI Renewal"
              />
            </div>
            <Modal
              visible={showCouponSuccessDialogue}
              transparent
              maskClosable={true}
              title={formatMessage({ id: 'campaign-title-coupon' })}
              footer={[
                {
                  text: formatMessage({
                    id: 'campaign-title-coupon-success-later',
                  }),
                  onPress: () => {
                    setShowCouponSuccessDialogue(false);
                  },
                },
                {
                  text: formatMessage({
                    id: 'campaign-title-coupon-success-go',
                  }),
                  onPress: () => {
                    setShowCouponSuccessDialogue(false);
                    window.location.href =
                      'https://shop18875151.youzan.com';
                  },
                },
              ]}
            >
              <p>
                <FormattedMessage id="campaign-title-coupon-success" />
              </p>
            </Modal>
          </>
        )}

      {/* 嘉年华购票 */}
      {/* {isMember && <>
        <div onClick={() => window.location.href = 'https://j.youzan.com/8IulCB'}>
          <img
            src={require(`../../assets/images/campaign/campaignPage/${festivalImg}`)}
            alt="PADI Festival"
          />
        </div>
        <Festival
          visible={showTicketDialogue}
          setVisible={setShowTicketDialogue}
          memberNumber={activity.memberNumber}
          youzanMobile={activity.youzanAssociatedMobile} />
      </>} */}

      {/* <div onClick={() => toPadiGear(isMember)}>
        <img
          src={require(`../../assets/images/campaign/campaignPage/${gearImg}`)}
          alt="gear"
        />
      </div> */}
      {data && data.wenJuan.type && (
        <div onClick={() => showWenjuan()}>
          <img
            src={`https://assets.padi.com.cn/assets/images/campaign/campaignPage/${questionnaireImg}`}
            alt="questionnaire"
          />
        </div>
      )}
      <div onClick={() => history.push('/newYearCampaignPost')}>
        <img
          src={require(`../../assets/images/campaign/campaignPage/${newYearImg}`)}
          alt="gear"
        />
      </div>
    </div>
    <p className="campaign-footer">
      <FormattedMessage id="campaign-more" />
    </p>
  </>
};

export default connect(null, { push })(CampaignPage);
