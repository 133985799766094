import React from 'react';
import { useHistory } from 'react-router-dom';
import { Result, Icon, Button, WingBlank } from 'antd-mobile';
import { useIntl, FormattedMessage } from 'react-intl';
import { getParameterByName } from '../../components/common/utils';

import './result.css';

export default function Failure() {
  const { formatMessage } = useIntl();
  const { push } = useHistory();
  const referer = getParameterByName('referer') || ''

  return (
    <div className="result">
      <Result
        img={
          <Icon
            type="cross-circle-o"
            className="spe"
            style={{ fill: '#F13642' }}
          />
        }
        title={formatMessage({ id: 'failure-title' })}
        message={
          <div className="result-detail">
            <div className="failure">
              <div className="failure-text">
                <p>
                  <FormattedMessage id="failure-hint" />
                </p>
                <p>
                  <FormattedMessage id="failure-service" />
                </p>
              </div>
              <WingBlank size="lg">
                <Button
                  icon="left"
                  type="warning"
                  onClick={() => push(referer ? `/register?referer=${referer}` : '/register')}
                >
                  <FormattedMessage id="success-SignIn" />
                </Button>
              </WingBlank>
            </div>
          </div>
        }
      />
    </div>
  );
}
