import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Result, Icon, Button, WingBlank } from 'antd-mobile';
import { FormattedMessage, useIntl } from 'react-intl';
import { getParameterByName } from '../../components/common/utils';

import './result.css';

export default function Success() {
  const { formatMessage } = useIntl();
  const { push } = useHistory();
  const location = useLocation();
  console.log('LOCATION: ', location);

  const { email, mobile, countryCode } = location?.state?.profile;
  const youzanVerified = location ?.state ?.youzanVerified;
  const referer = getParameterByName('referer') || ''
  return (
    <div className="result">
      <Result
        img={
          <Icon
            type="check-circle"
            className="spe"
            style={{ fill: '#1F90E6' }}
          />
        }
        title={formatMessage({ id: 'success-title' })}
        message={
          <div className="result-detail">
            <div className="success">
              <WingBlank size="lg">
                <div className="success-has-sso">
                  <p>
                    <FormattedMessage id="success-hasSso" />
                    <br />
                    <strong>{email}</strong>
                    <br />
                    <br />
                  </p>
                  {countryCode && countryCode === '86' ?
                    youzanVerified ? (
                      <p>
                        <FormattedMessage id="success-hasYouzan" />
                      <br />
                      <strong>{mobile}</strong>
                      </p>
                    ) : <p><FormattedMessage id="success-hasnoYouzan" /></p> 
                    : null}
                </div>
              </WingBlank>
            </div>
            <WingBlank size="lg">
              <Button
                icon="right"
                type="primary"
                onClick={() => push(referer ? `/emailLogin?referer=${referer}` : '/emailLogin')}
              >
                <FormattedMessage id="success-login" />
              </Button>
            </WingBlank>
          </div>
        }
      />
    </div>
  );
};
