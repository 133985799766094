import React from 'react';
import { Loading } from 'antd-mobile-v5';
import { FormattedMessage } from 'react-intl';

export default ({ textKey = 'common-loading' }) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Loading />
      <span>
        <FormattedMessage id={textKey} />
      </span>
    </div>
  );
};
