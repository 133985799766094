import React, { useEffect } from 'react';
import { WingBlank } from 'antd-mobile';
import { FormattedMessage, useIntl } from 'react-intl';

import Cert from '../Cert/Cert';
import { Dialog } from 'antd-mobile-v5';
import { useHasLogin } from '../../services/hooks';
import { useDispatch } from 'react-redux';
import { setCookie, getCookie } from '../../components/common/cookie';

const CertsList = ({ ecards, nextCerts }) => {
  const dispatch = useDispatch();
  const {formatMessage} = useIntl();
  const { run } = useHasLogin({
    manual: true,
    onSuccess: (data) => {
      if(data.wenJuan.type && !getCookie(data.wenJuan.type)) {
        setCookie(data.wenJuan.type, true)
        Dialog.show({
          title: formatMessage({id: 'questionaire-title'}),
          content: 
            formatMessage(
              { id: 'questionaire-message' },
              { course: formatMessage({id: `questionaire-course-${data.wenJuan.type}`}) },
            ),
          showCloseButton: true,
          closeOnAction: true,
          actions: [[
            {
              key: 'cancel',
              text: formatMessage({id: 'common-cancel'}),
            },
            {
              key: 'open',
              bold: true,
              text: formatMessage({id: 'common-confirm'}),
              primary: true,
              onClick: () => {
                window.location.href = data.wenJuan.url
              }
            },]
          ],
        });
      }
    }
  });

  useEffect(() => {
    run();
  }, []);
  return <>
    { (ecards?.length > 0) ?
      ecards.map(ecard => (
        <Cert key={ecard.uniqueId} ecard={ecard} />
      ))
    :
    <WingBlank className="change-email">
      <FormattedMessage
        id="certlist-noCert"
      />
    </WingBlank>
    }
  </>};

export { CertsList }
