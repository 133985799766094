import { Image } from 'antd-mobile-v5';
import React from 'react';

import { usePhoto } from '../../services/hooks/user';

import styles from './UploadPhoto.module.css';

const UserPhoto = ({ id }) => {
  return (
    <div className={styles.avatar}>
      <Photo id={id} />
    </div>
  );
};

export const Photo = ({ id }) => {
  const { data } = usePhoto(id);
  return <Image src={data ? URL.createObjectURL(data) : ''} />;
};

export default UserPhoto;
