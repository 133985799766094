import React from 'react';
import { useIntl } from 'react-intl';
import { InputItem } from 'antd-mobile';
import UploadOssFile from '../../components/common/UploadOssFile';
import UploadFileWrapper from '../../components/common/UploadFileWrapper';

const EFRConfirm = ({ appDetail }) => {
  const { formatMessage } = useIntl();
  return appDetail.efri ? (
    <InputItem
      type="text"
      editable={false}
      value={formatMessage({ id: 'efri-valid' })}
    >
      EFR
    </InputItem>
  ) : (
    <UploadFileWrapper
      labelName={appDetail.efr ? `EFR (${appDetail.efr})` : 'EFR'}
      editable={false}
    >
      <UploadOssFile fileList={appDetail.efrfiles || []} editable={false} />
    </UploadFileWrapper>
  );
};
export default EFRConfirm;
