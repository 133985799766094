import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl';
import { InputItem } from 'antd-mobile';
import CardLayout from '../../pages/DiveMasterForm/CardLayout';
import SearchStore from '../../components/common/SearchStore';
import DatePickerComponent from '../../components/common/DatePicker'
import { formatDate } from '../../components/common/utils'

const CourseInfo = ({
  completionDate,
  location,
  setCompletionDate,
  setLocation,
  disabled,
  setStoreNumber,
  storeNumber,
  storeInfo
}) => {

  const intl = useIntl()

  return <CardLayout
    title={<FormattedMessage id="si-cd-course" />}
    status={completionDate && location}
  >
    {disabled && !storeNumber ? null : <SearchStore
      editable={!disabled}
      placeholder={intl.formatMessage({ id: 'dm-form-store-warning' })}
      store={storeInfo || {}}
      storeNumber={storeInfo ? storeInfo.storeNumber : ''}
      onSuccess={(result) => {
        setStoreNumber(result.storeNumber)
      }}
    />}
    <InputItem
      clear
      editable={!disabled}
      placeholder={intl.formatMessage({ id: 'si-course-location' })}
      value={location || ''}
      onChange={(v) => setLocation(v)}
    >
      <FormattedMessage id="si-course-location-title" />
    </InputItem>
    <DatePickerComponent
      extra={intl.formatMessage({ id: 'common-select' })}
      title={intl.formatMessage({ id: 'dm-form-date-warning' })}
      maxDate={new Date()}
      disabled={disabled}
      onChange={(date) => setCompletionDate(date)}
      value={completionDate ? formatDate(completionDate, 'YYYY-MM-DD') : ''}>
      <FormattedMessage id="dm-form-complete-date" />
    </DatePickerComponent>
  </CardLayout>
}

export default CourseInfo