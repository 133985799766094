import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd-mobile';
import { useIntl } from 'react-intl';
import { getSubmitOssFormat, removeExpires } from '../../components/common/utils'
import { isMember } from '../../reducers/selectors'
import ApplicantDetailStatus from '../../components/common/ApplicantDetailStatus'
import { usePayEfri, useSubmitEfri } from '../../services/hooks'
import { useHistory } from 'react-router-dom';

const ApplicationStatus = ({ formData, checkAge, detail }) => {

  const { formatMessage } = useIntl()
  const dispatch = useDispatch();
  const { push } = useHistory()
  const { runAsync: toPay } = usePayEfri();
  const { runAsync: submitEfri, loading: submitting } = useSubmitEfri();

  const { signatureUrl, ismember } = useSelector((state) => ({
    signatureUrl: state.ui.signatureUrl,
    profile: state.data.profile,
    userInfo: state.data.userInfo,
    ismember: isMember(state)
  }));

  const verifySubmit = () => {
    const {
      checked,
      efr,
      instructorNumber,
      languageId,
      regionId,
      efrfiles
    } = formData;
    const regionStatus = ismember ? true : regionId
    return (
      signatureUrl &&
      checked &&
      checkAge &&
      (efr || efrfiles.length > 0) &&
      instructorNumber &&
      languageId &&
      regionStatus
    );
  };

  const submit = () => {
    if (verifySubmit()) {
      const { checked, regionId, ...data } = formData
      const submitData = {
        ...data,
        efrfiles: formData.efrfiles.length > 0 ? removeExpires(formData.efrfiles) : [],
        handwrittenSignatureUrl: getSubmitOssFormat(signatureUrl),
      };
      if (!ismember) submitData.regionId = regionId
      submitEfri(submitData).then(res => {
        if (res.code) {
          push('/application_submit_success?type=EFRI', { code: res.code, paid: res.paid, paymentType: 'MEMBER_APPLICATION_EFRI' });
        }
      })
    } else {
      Modal.alert(
        formatMessage({ id: 'dm-form-submit-error' }),
        formatMessage({ id: 'dm-form-submit-error-info' }),
        [{ text: formatMessage({ id: 'common-close' }) }]
      );
    }
  }

  return <ApplicantDetailStatus
    detail={detail}
    idType={'trainer'}
    submitFn={submit}
    payFn={toPay}
    applicationType={'EFRI'}
    isEfri={true}
    payApplicationType={'MEMBER_APPLICATION_EFRI'}
    loading={submitting}
  />
}

export default ApplicationStatus