import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { Button } from 'antd-mobile';
import { FormattedMessage } from 'react-intl';
import { submitBookSaleList } from '../../actions';
import { formatMoney } from '../../components/common/utils'

import styles from './PriceList.module.css'
import BindingYouzan from '../../components/MyProfile/BindingYouzan';

const getSelectedGoodsList = (data, submitItems) => {
  const goodsListArr = []
  data.map(goodsList => {
    goodsListArr.push(...goodsList.textbooksSaleListGoodsDTOS)
  })
  const selectedItemsList = submitItems.map(selectedItem => {
    let selectedGood = goodsListArr.find(
      item => item.skuId === selectedItem.skuId && item.goodsId === selectedItem.goodsId
    )
    selectedGood = {
      ...selectedGood,
      number: selectedItem.number
    }
    return selectedGood
  })
  return selectedItemsList
}

const getTotalPrice = (data, submitItems) => {
  const currency = data[0].textbooksSaleListGoodsDTOS[0].currencyType;
  const selectedGoodsList = getSelectedGoodsList(data, submitItems)
  let total = 0;
  for (let item of selectedGoodsList) {
    total += item.price * item.number;
  }
  return formatMoney(total, currency, true);
}

const getTotalAmout = (data, submitItems) => {
  const selectedGoodsList = getSelectedGoodsList(data, submitItems)
  let amount = 0;
  for (let item of selectedGoodsList) {
    amount += item.number
  }
  return amount
}

const CheckoutCounter = ({ priceListData, submitItems }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { profile } = useSelector((state) => ({
    profile: state.data.profile,
  }));

  const toCheckout = () => {
    const currency = priceListData[0].textbooksSaleListGoodsDTOS[0].currencyType;
    if (currency === 'AUD' || currency === 'TWD') {
      history.push('/books_checkout', { priceList: priceListData, items: submitItems })
    } else {
      dispatch(submitBookSaleList(submitItems))
    }
  }
  return <div className={styles.checkout}>
    <div className={styles.price}>
      <div className={styles.checkout}>
        <span><FormattedMessage id="book-total" />{getTotalPrice(priceListData, submitItems)}</span>
        <span className={styles.instructions}>
          <FormattedMessage id="book-checkout-hint" />
          {/* <i className="fa fa-question-circle-o fa-lg" onClick={() => setStatus(!instructStatus)}></i>
          {instructStatus && <span className={styles.instrucInfo}>价格说明...</span>} */}
        </span>
      </div>
    </div>
    <div className={styles.pay}>
      {profile.teachingLocation === 'CN' ? (
        <BindingYouzan onHandleFn={toCheckout}>
          <Button
            type="primary"
            className={styles.payBtn}
            disabled={!submitItems.length > 0 || !submitItems.every(item => item.number)}
          >
            <FormattedMessage id="book-checkout" />({getTotalAmout(priceListData, submitItems)})
          </Button>
        </BindingYouzan>
      ) : (
          <Button
            type="primary"
            className={styles.payBtn}
            disabled={!submitItems.length > 0 || !submitItems.every(item => item.number)}
            onClick={toCheckout}
          >
            <FormattedMessage id="book-checkout" />({getTotalAmout(priceListData, submitItems)})
          </Button>
        )}
    </div>
  </div>
}

export default CheckoutCounter