import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { WhiteSpace, WingBlank, Checkbox } from 'antd-mobile';
import Header from '../../components/common/FormHeader'
import DiverInfo from '../OwsiApplication/DiverInfo'
import CdInfo from '../OwsiApplication/CdInfo'
import StoreInfo from '../OwsiApplication/StoreInfo'
import CheckAge from '../OwsiApplication/CheckAge'
import AddressAndLangOption from '../DiveMasterForm/AddressAndLangOption'
import CheckCertsConditions from '../OwsiApplication/CheckCertsConditions'
import Signature from '../../components/common/Signature'
import { getAge, showModal, findEfrOrEfri } from '../../components/common/utils'
import IeSubmitBtn from './IeResitBtn'
import UploadOssFile from '../../components/common/UploadOssFile'
import CardLayout from '../DiveMasterForm/CardLayout'
import Agreement from '../OwsiApplication/Agreement'
import PickerComponent from '../../components/common/Picker'
import DatePickerComponent from '../../components/common/DatePicker'
import IeTimes from './IeTimes'

const CheckboxItem = Checkbox.CheckboxItem;

const formatIdcDate = (date) => {
  const day = date.substr(0, 2)
  const month = date.substr(2, 2)
  const year = date.substr(4, 4)
  return `${year}-${month}-${day}`
}

const IeResit = () => {

  const { userInfo, owsiDetail, certifications, credentials, ieList } = useSelector(state => ({
    userInfo: state.data.userInfo,
    owsiDetail: state.owsi.applicantDetail,
    certifications: state.data.certifications,
    credentials: state.data.credentials,
    ieList: state.owsi.ieList
  }));
  const { formatMessage } = useIntl()

  const [certStatus, setCertStatus] = useState({
    ow: false,
    aow: false,
    efr: false,
    rescue: false,
    aidm: false,
    other: false
  })
  const updateCertStatus = (update) => setCertStatus({ ...certStatus, ...update })

  const [checkAge, setCheckAge] = useState(false)
  const [ieListPicker, setIeList] = useState([])
  const [checked, setChecked] = useState(false)
  const [showDialog, setDialogStatus] = useState(false);
  const [formData, setFormData] = useState({
    aidmfiles: [],
    alternateLocation: '',
    aowfiles: [],
    cdinstructorNumber: '',
    code: 0,
    regionId: '',
    efrfiles: [],
    languageId: '',
    owfiles: [],
    rescuefiles: [],
    storeNumber: '',
    storeType: '',
    aidm: '',
    ow: '',
    aow: '',
    rescue: '',
    efr: '',
    ieId: '',
    ieAttempt: 1,
    ieExamModules: []
  });

  const updateFormData = (update) => setFormData({ ...formData, ...update })

  useEffect(() => {
    if (owsiDetail && certifications && credentials) {
      setFormData({
        aidmfiles: owsiDetail.aidmfiles,
        alternateLocation: formData.alternateLocation ? formData.alternateLocation : owsiDetail.alternateLocation,
        aowfiles: owsiDetail.aowfiles,
        cdinstructorNumber: formData.cdinstructorNumber ? formData.cdinstructorNumber : owsiDetail.cdinstructorNumber,
        code: owsiDetail.code,
        regionId: formData.regionId ? formData.regionId : owsiDetail.regionId,
        efrfiles: owsiDetail.efrfiles,
        languageId: formData.languageId ? formData.languageId : owsiDetail.languageId,
        owfiles: owsiDetail.owfiles,
        rescuefiles: owsiDetail.rescuefiles,
        otherFiles: owsiDetail.otherFiles,
        storeNumber: formData.storeNumber ? formData.storeNumber : owsiDetail.storeNumber,
        storeType: formData.storeType ? formData.storeType : owsiDetail.storeType,
        aidm: findCert('AI/DM') ? 'AI/DM' : '',
        ow: findCert('OW') ? findCert('OW').studentNumber : '',
        aow: findCert('AOW') ? findCert('AOW').studentNumber : '',
        rescue: findCert('RESCUE') ? findCert('RESCUE').studentNumber : '',
        efr: findEfrOrEfri(certifications, credentials) ? findEfrOrEfri(certifications, credentials) : '',
        ieAttempt: owsiDetail.ieAttempt ? owsiDetail.ieAttempt : formData.ieAttempt,
        ieExamModules: formData.ieExamModules ? formData.ieExamModules : owsiDetail.ieExamModules,
      })
    }
  }, [owsiDetail, certifications, credentials])

  useEffect(() => {
    if (ieList.length > 0) {
      const ieListMapping = ieList.map(item => {
        return {
          label: `${item.location}  ${item.ieDate}`,
          value: item.id
        }
      })
      setIeList(ieListMapping)
    }
  }, [ieList])

  useEffect(() => {
    if (userInfo) {
      setCheckAge(getAge(userInfo.birthDate) >= 18)
    }
  }, [userInfo])

  useEffect(() => {
    if (owsiDetail && owsiDetail.note) {
      if (!showDialog) {
        showModal(
          formatMessage({ id: 'cardOptions-replaceCardHint' }),
          `${formatMessage({ id: 'dm-app-not-approved-tips' })}${
          owsiDetail.note
          }`,
          () => { },
          () => { },
          formatMessage({ id: 'common-comfirmBtn' }),
          formatMessage({ id: 'common-cancel' })
        );
      }
      setDialogStatus(true);
    }
  }, [owsiDetail, formatMessage, showDialog]);


  const findCert = (type) => {
    switch (type) {
      case 'OW':
        return certifications.find(item => item.certificationNumber === 75)
      case 'AOW':
        return certifications.find(item => item.certificationNumber === 26)
      case 'RESCUE':
        return certifications.find(item => item.certificationNumber === 72)
      case 'EFR':
        return certifications.find(item => {
          return !item.expired && (item.certificationNumber === 76 ||
            item.certificationNumber === 68 ||
            item.certificationNumber === 31 ||
            item.certificationNumber === 20)
        })
      case 'AI/DM':
        return credentials.find(item => item.credentialNumber === 25 || item.credentialNumber === 23 || item.credentialNumber === 6092)
    }
  }

  const checkCertStatus = () => {
    const {
      ow,
      aow,
      efr,
      rescue,
      aidm,
      other
    } = certStatus
    return (
      ow &&
      aow &&
      efr &&
      rescue &&
      aidm &&
      other
    )
  }

  return userInfo && owsiDetail ? <>
    <Header text={formatMessage({ id: 'owsi-ie-resits-app' })} />
    <DiverInfo userInfo={userInfo} />
    <WhiteSpace />
    <IeTimes
      resitList={formData.ieExamModules}
      ieAttempt={formData.ieAttempt}
      updateFormData={updateFormData} />
    <WhiteSpace />
    <CdInfo
      cdInfo={owsiDetail ? owsiDetail.cdinfoDTO : {}}
      editable={true}
      setFormData={updateFormData}
      cdinstructorNumber={formData.cdinstructorNumber} />
    <WhiteSpace />
    <StoreInfo
      storeType={owsiDetail.storeType}
      storeInfo={owsiDetail.storeInfoDTO}
      updateFormData={updateFormData}
      editable={true}
      storeNumber={formData.storeNumber}
      alternateLocation={formData.alternateLocation} />
    <WhiteSpace />
    <CheckAge
      checkAge={checkAge} />
    <WhiteSpace />
    <CheckCertsConditions
      aidmfiles={formData.aidmfiles}
      aowfiles={formData.aowfiles}
      efrfiles={formData.efrfiles}
      owfiles={formData.owfiles}
      rescuefiles={formData.rescuefiles}
      otherFiles={formData.otherFiles}
      certStatus={checkCertStatus()}
      updateCertStatus={updateCertStatus}
      editable={true} />
    <WhiteSpace />
    <AddressAndLangOption
      onValueChange={updateFormData}
      languageId={formData.languageId}
      regionId={formData.regionId}
      editable={true} />
    <WhiteSpace />
    <PickerComponent
      title={formatMessage({ id: "owsi-selected-ie" })}
      data={ieListPicker}
      disabled={false}
      onOk={id => updateFormData({ ieId: parseInt(id) })}
      value={formData.ieId}>
      <FormattedMessage id="owsi-ie-list" />
    </PickerComponent>
    <WhiteSpace />
    {owsiDetail.trainingRecord && owsiDetail.trainingRecord.length > 0 && (
      <CardLayout title={formatMessage({ id: "owsi-training-records" })} status={"none"}>
        <UploadOssFile
          fileList={owsiDetail.trainingRecord}
          editable={false}
          type={"OWSI"}
        />
      </CardLayout>
    )}
    <WhiteSpace />
    {owsiDetail.idcdate && (
      <DatePickerComponent
        disabled={true}
        title={formatMessage({ id: 'setProfile-chooseDate' })}
        value={formatIdcDate(owsiDetail.idcdate)}>
        <FormattedMessage id="owsi-idc-date" />
      </DatePickerComponent>
    )}
    {owsiDetail.writtenExamTimeExtensions && <>
      <WhiteSpace />
      <CheckboxItem
        disabled={true}
        checked={owsiDetail.writtenExamTimeExtensions}>
        <FormattedMessage id="owsi-extensions" />
      </CheckboxItem>
      <WhiteSpace />
    </>}
    <Signature
      filePath={'signature'}
      // initSignature={owsiDetail.handwrittenSignatureUrl}
      modified={true}
    />
    <WhiteSpace />
    <Agreement changeChecked={setChecked} />
    <WhiteSpace size="xl" />
    <WingBlank>
      <IeSubmitBtn formData={formData} agreementChecked={checked} certStatus={checkCertStatus()} />
      <WhiteSpace size="xl" />
    </WingBlank>
    <WhiteSpace size="xl" />
  </> : null
}

export default IeResit