import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Pagination, WhiteSpace, Icon } from 'antd-mobile'
import CenterLayout from './CenterLayout';
import Header from './FormHeader'
import AppListItem from './AppListItem'

import styles from '../../pages/DmForInstructor/ConfirmAppsPage.module.css';

const AppList = ({
  confirmRes,
  detailUrl,
  paging,
  listHeader,
  rightContent,
  isStore
}) => {
  const dispatch = useDispatch()
  const { push } = useHistory()
  const { formatMessage } = useIntl()
  const [currentPage, setCurrentPage] = useState(1)

  const skipTo = (page) => {
    setCurrentPage(page)
    dispatch(paging({ page }))
  }

  if (!confirmRes || !confirmRes.content) return null

  const confirmList = confirmRes.content;

  return (
    <>
      <Header text={formatMessage({ id: listHeader })} rightContent={rightContent} />
      {confirmList.length === 0 ? (
        <CenterLayout>
          <p>
            <FormattedMessage id="dm-application-empty-list" />
          </p>
        </CenterLayout>
      ) : (
          <>
            <div className={styles.confirmList}>
              {confirmList.map((item) => (
                <AppListItem
                  key={item.code}
                  confirmItem={item}
                  onClick={() => push(`${detailUrl}?code=${item.code}`, {
                    isStore: isStore
                  })}
                  isStore={isStore}
                />
              ))}
              <WhiteSpace />
              <Pagination
                total={confirmRes.totalPages}
                current={currentPage}
                locale={{
                  prevText: (<span className={styles.arrowAlign}><Icon type="left" /></span>),
                  nextText: (<span className={styles.arrowAlign}><Icon type="right" /></span>),
                }}
                onChange={(page) => skipTo(page, setCurrentPage)} />
            </div>
          </>
        )}
    </>
  )

}

export default AppList