import React from 'react';
import styles from './CardIntros.module.css';

export default () => (
  <div className={styles.intros}>
    <img src={require('../../assets/images/cards/1.jpg')} alt="" />
    <img src={require('../../assets/images/cards/2.jpg')} alt="" />
    <img src={require('../../assets/images/cards/3.jpg')} alt="" />
    <img src={require('../../assets/images/cards/4.jpg')} alt="" />
    <img src={require('../../assets/images/cards/5.jpg')} alt="" />
    {/* <img src={require('../../assets/images/cards/6.jpg')} alt="" /> */}
    {/* <img src={require('../../assets/images/cards/7.jpg')} alt="" /> */}
    {/* <img src={require('../../assets/images/cards/8.jpg')} alt="" /> */}
    {/* <img src={require('../../assets/images/cards/9.jpg')} alt="" /> */}
    {/*<img src={require('../../assets/images/cards/10.jpg')} alt="" />*/}
    <img src={require('../../assets/images/cards/11.jpg')} alt="" />
    {/* <img src={require('../../assets/images/cards/12.jpg')} alt="" /> */}
    {/*<img src={require('../../assets/images/cards/13.jpg')} alt="" />*/}
    <img src={require('../../assets/images/cards/14.jpg')} alt="" />
    <img src={require('../../assets/images/cards/15.jpg')} alt="" />
    <img src={require('../../assets/images/cards/16.jpg')} alt="" />
  </div>
);
