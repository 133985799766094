import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import React from 'react';

import './Cart.css';

const EmptyCart = () => (
  <div className="empty-cart">
    <img src={require('../../assets/images/empty-cart.png')} alt="empty" />
    <p>
      <FormattedMessage id="cardRequest-empty" />
    </p>
    <p className="empty-link">
      <Link to="/buy_cards">
        <i className="fa fa-angle-double-left fa-lg"></i>
        <FormattedMessage id="cardRequest-goToCerts" />
      </Link>
    </p>
  </div>
);

export default EmptyCart;
