import React from 'react'
import CardLayout from '../../pages/DiveMasterForm/CardLayout';
import { FormattedMessage, useIntl } from 'react-intl';
import SearchInstructor from '../../components/common/SearchInstructor';

const CdInfo = ({ editable, cdInfo, setFormData, cdinstructorNumber }) => {

  const { formatMessage } = useIntl()

  return <CardLayout
    title={<FormattedMessage id="owsi-cd-info" />}
    status={cdinstructorNumber}
  >
    <SearchInstructor
      type={"CD"}
      id={"cd"}
      editable={editable}
      instructor={cdInfo}
      instructorNumber={cdinstructorNumber}
      onSuccess={(result) => {
        setFormData({ cdinstructorNumber: result.instructorNumber });
      }}
    />
  </CardLayout>
}

export default CdInfo