import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'antd-mobile';
import SignatureCanvas from 'react-signature-canvas'
import CardLayout from '../../pages/DiveMasterForm/CardLayout'
import { showModal, dataURLtoBlobOrBlodURL } from '../../components/common/utils'
import { setSignature, submitSignature, clearSignature } from '../../reducers/ui'

import styles from './Signature.module.css'

const Signature = ({ initSignature, modified, filePath }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [ signCtrl, setSignCtrl ] = useState()
  const [ showCanvas, setShowCanvas ] = useState(true)

  const { ossSignature, signatureUrl } = useSelector(state => ({
    ossSignature: state.oss.ossSignature,
    signatureUrl: state.ui.signatureUrl,
  }));

  useEffect(() => {
    return () => {
      dispatch(clearSignature())
    }
  },[])

  useEffect(() => {
    if(initSignature) dispatch(setSignature(initSignature))
  },[initSignature])

  useEffect(() => {
    if(signatureUrl) setShowCanvas(false)
  }, [signatureUrl]);

  const getUploadData = file => {
    const filename = `${ossSignature.dir}${filePath}/${new Date().getTime()}`;
    const formData = new FormData();
    formData.append('key', filename);
    formData.append('OSSAccessKeyId', ossSignature.accessid);
    formData.append('policy', ossSignature.policy);
    formData.append('Signature', ossSignature.signature);
    formData.append("file", dataURLtoBlobOrBlodURL(file))
    formData.append('success_action_status', 200);
    return formData
  }

  const uploadSignature = (imgSrc) => {
    if(!signCtrl.isEmpty()) {
      const formData = getUploadData(imgSrc)
      dispatch(submitSignature({formData}))
    }else {
      showModal(
        intl.formatMessage({id: 'common-errorTitle'}), 
        intl.formatMessage({id: 'dm-form-signature-confirm'}), 
        () => {}, 
        () => {}, 
        intl.formatMessage({id: 'common-comfirmBtn'}), 
        intl.formatMessage({id: 'common-cancel'}))
    }
  }

  const clearSignatureImg = () => {
    dispatch(clearSignature());
    if(signatureUrl) {
      setShowCanvas(true);
      return
    }
    signCtrl.clear()
  }

  return <CardLayout title={<FormattedMessage id="dm-form-signature" />} status={signatureUrl}>
    {!showCanvas && signatureUrl ? <div className={styles.signatureImgBox}>
      <img 
        className={styles.signatureImg}
        src={signatureUrl} 
        alt="signature" />
    </div>
    :
    <SignatureCanvas
      canvasProps={{width: 500, height: 200, className: 'sigCanvas'}}
      ref={(ref) => {setSignCtrl(ref)}} />}
    {modified && <div className={styles.signatureBtns}>
      <Button 
        type="ghost" 
        inline size="small" 
        className={styles["confirm-btn"]} 
        onClick={() => clearSignatureImg()}>
          <FormattedMessage id="dm-form-signature-clear" />
      </Button>
      <Button 
        type="ghost" 
        inline size="small" 
        className={styles["confirm-btn"]} 
        onClick={() => uploadSignature(signCtrl.toDataURL('image/png'))}>
          <FormattedMessage id="dm-form-signature-save" />
      </Button>
    </div>}
  </CardLayout>
}

export default Signature