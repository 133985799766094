import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  NavBar,
  WingBlank,
  WhiteSpace,
  Toast,
  Button,
  List,
  InputItem,
  TextareaItem,
} from 'antd-mobile';
import UploadFile from './UploadFile';
import { getCollectionDetail, postCollection } from '../../services/api';
import {
  getParameterByName,
  removeExpires,
  showAlert,
} from '../../components/common/utils';
import { useIntl, FormattedMessage } from 'react-intl';
import AgreementCheckbox from '../../components/common/AgreementCheckbox';

const PicCollectionPage = () => {
  const { formatMessage } = useIntl();
  const [detail, setDetail] = useState();
  const [answer, setAnswer] = useState([]);
  const [wechatNum, setWechatNum] = useState('');
  const [agreed, setAgreed] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [job, setJob] = useState('');
  const getFileList = useRef(null);
  const alias = getParameterByName('alias');

  // const { userInfo, certifications } = useSelector((state) => ({
  //   userInfo: state.data.userInfo,
  //   certifications: state.data.certifications,
  // }));

  useEffect(() => {
    const requestDetail = async () => {
      const rs = await getCollectionDetail(alias);
      if (rs.data) setDetail(rs.data);
      console.log("Request Detail: ", rs.data);
    };
    requestDetail();
  }, [alias]);

  const getDiveLevel = () => {
    return 'Unknown';
    // if (userInfo.memberNumber) {
    //   return userInfo.memberNumber;
    // } else {
    //   // const maxDate = Math.max.apply(
    //   //   Math,
    //   //   certifications.map((item) => new Date(item.certificationDate).getTime())
    //   // );
    //   // const findCert = certifications.find(
    //   //   (item) => new Date(item.certificationDate).getTime() === maxDate
    //   // );
    //   return certifications?.length > 0 ? certifications[0].studentNumber : 'Not Diver';
    // }
  };

  const verifyData = () => {
    return (
      wechatNum &&
      job &&
      answer.length === detail.items.length &&
      getFileList.current.fileList.length > 0
    );
  };

  const submit = async () => {
    const data = {
      alias,
      resources: removeExpires(getFileList.current.fileList),
      items: answer,
      job,
      level: getDiveLevel(),
      nickname: "Unknown", //userInfo.englishName,
      wxid: wechatNum,
    };
    console.log('Data to submit: ', data);
    if (!verifyData()) {
      showAlert(
        formatMessage({ id: 'dm-form-submit-error-info-no-agreement' }),
        formatMessage({ id: 'common-close' })
      );
      return;
    }
    if (!agreed) {
      showAlert(
        formatMessage({ id: 'customize-cert-agree-first' }),
        formatMessage({ id: 'common-confirm' }),
        () => {}
      );
      return;
    }
    const result = await postCollection(data);
    console.log('Submit Results: ', result);
    if (result.data) setSubmitted(true);
  };

  const handleChangeAnswer = (val, id) => {
    const hasAnswer = answer.filter((item) => item.id === id);
    const filterSameAnswer = answer.filter((item) => item.id !== id);
    if (hasAnswer) {
      if (!val) setAnswer([...filterSameAnswer]);
      else setAnswer([...filterSameAnswer, { id, answer: val }]);
    } else {
      setAnswer([...answer, { id, answer: val }]);
    }
  };

  if (!detail) return null;
  if (submitted) return <div style={{
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    
  }}>提交成功，感谢您的参与！</div>

  return (
    <>
      <NavBar icon={<i className="fa fa-list-ul"></i>} mode="light">
        素材征集
      </NavBar>
      <List renderHeader={() => '我的信息'}>
        <InputItem onChange={(val) => setWechatNum(val)}>微信号：</InputItem>
        <InputItem onChange={(val) => setJob(val)}>职业：</InputItem>
      </List>
      {detail.items.map((item, index) => (
        <List key={index} renderHeader={() => item.title}>
          <TextareaItem
            autoHeight
            rows={3}
            onChange={(val) => handleChangeAnswer(val, item.id)}
          />
        </List>
      ))}
      <List renderHeader={() => '上传图片/视频'}>
        <UploadFile ref={getFileList} />
      </List>
      <AgreementCheckbox
        checked={agreed}
        setChecked={(checked) => setAgreed(checked)}
      >
        <FormattedMessage
          id="customize-cert-hint4"
          values={{
            link: (
              <a href="https://assets.padi.com.cn/agreement_sc.html">
                <FormattedMessage id="common-agreement" />
              </a>
            ),
          }}
        />
      </AgreementCheckbox>
      <WhiteSpace size="xl" />
      <WingBlank>
        <Button type="primary" onClick={submit}>
          提交
        </Button>
      </WingBlank>
      <WhiteSpace size="xl" />
    </>
  );
};

export default PicCollectionPage;
