import React, { useEffect } from 'react'
import { WhiteSpace, WingBlank } from 'antd-mobile';
import { useSelector, useDispatch } from 'react-redux';
import { specialty, specialtyMapping } from '../../components/common/utils'
import { getCookie } from '../../components/common/cookie'
import DirectApplySi from './DirectApplySi'
import { removeSiFile, addSi, removeSi, uploadSifile } from '../../reducers/ui'
import UploadOssFile from '../../components/common/UploadOssFile'
import UploadFileWrapper from '../../components/common/UploadFileWrapper'
import { FormattedMessage } from 'react-intl';
import CardLayout from '../DiveMasterForm/CardLayout'

const PendingConditions = ({ ossSignature }) => {

  const dispatch = useDispatch()
  const lang = getCookie('language')

  const { certifications, credentials, siDetail } = useSelector(state => ({
    certifications: state.data.certifications,
    credentials : state.data.credentials,
    siDetail: state.ui.siDetail
  }));

  useEffect(() => {
    if(siDetail && siDetail.code) {
      let appList = []
      let keys = {}
      siDetail.siApplicationDetailDTOS.forEach(item => {
        if(item.attachFile) {
          if (keys[item.credentialId]) {
            keys[item.credentialId].push(item.attachFile)
          } else {
            keys[item.credentialId] = [item.attachFile]
          }
          for(let [key, value] of Object.entries(keys)) {
            appList.push({
              credentialId: key,
              attachFile: value 
            })
          }
        }else {
          appList.push({ credentialId: item.credentialId, attachFile: null })
        }
      })
    }
  },[siDetail])

  const hasNoSiCertList = () => {
    return specialty.filter(item => {
      const credsNumArr = credentials.map(item => item.credentialNumber)
      return credsNumArr.indexOf(item.spi) === -1 && credsNumArr.indexOf(item.spt) === -1
    })
  }

  const getApplicableList = (type) => {
    let directApplyList = []
    let needAttachList = []
    hasNoSiCertList().map(cert => {
      if(!cert.star) directApplyList.push(cert)
      else needAttachList.push(cert)
    })
    const certsNumArr = certifications.map(item => item.certificationNumber)
    for(let i = needAttachList.length-1; i >= 0; i--) {
      // 判断独行侠专长
      if(needAttachList[i].sp === 4255 && (certsNumArr.indexOf(3147) > -1 || 
        certsNumArr.indexOf(4255) > -1 ||
        certsNumArr.indexOf(3148) > -1 || 
        certsNumArr.indexOf(3149) > -1 || 
        certsNumArr.indexOf(62) > -1 || 
        certsNumArr.indexOf(3460) > -1 || 
        certsNumArr.indexOf(3461) > -1)
      ) {
        directApplyList.push(needAttachList[i])
        needAttachList.splice(i, 1)
      }else {
        if(certsNumArr.indexOf(needAttachList[i].sp) > -1) {
          directApplyList.push(needAttachList[i])
          needAttachList.splice(i, 1)
        }
      }
    }
    if(type === 'directApply') return directApplyList
    else return needAttachList
  }

  const selectedSi = (e, val) => {
    if(e.target.checked) {
      dispatch(addSi({ credentialId: val }))
    }else {
      dispatch(removeSi({ credentialId: val }))
    }
  }

  const checkChecked = (item) => {
    if(siDetail && siDetail.siApplicationDetailDTOS){
      const checkedItem = siDetail.siApplicationDetailDTOS.find(i => item.spi === i.credentialId)
      return !!checkedItem
    }
  }

  const findAttachById = (id) => {
    if(siDetail.siApplicationDetailDTOS) {
      const detailData = siDetail.siApplicationDetailDTOS.find(item => item.credentialId === id)
      return detailData ? detailData.attachFile : null
    }
    return null
  }

  const fileName = (type) => `${ossSignature.dir}${type}/${new Date().getTime()}`

  return <>
    <DirectApplySi 
      directData={getApplicableList('directApply')} 
      checkedFn={selectedSi} 
      checked={checkChecked} />
    <WhiteSpace />
    <CardLayout title={<FormattedMessage id="si-upload-attach" />} status={"none"}>
      {getApplicableList().map((item, index) => (
        <UploadFileWrapper 
          labelName={specialtyMapping[item.spi][lang]}
          editable={true}>
          <UploadOssFile
            fileList={findAttachById(item.spi) || []}
            editable={true}
            type={"SI"}
            fileName={fileName(item.spi)}
            onChange={(formData) => {
              dispatch(uploadSifile({ formData }))
            }}
            onDelete={(index) => dispatch(removeSiFile({ credentialId: item.spi, url: findAttachById(item.spi)[index] }))} />
          <WingBlank>
            <div className="small-font gray">{item.desc[lang]}</div>
          </WingBlank>
        </UploadFileWrapper>
      ))}
    </CardLayout>
  </>
}

export default PendingConditions