import React from 'react'
import { Button, Divider, DotLoading } from 'antd-mobile-v5'
import { isWechat } from '../../utils/wechat';
import { useDownloadAnniversaryAward, useErrorModal, useAnniversaryInstructor } from '../../services/hooks';
import { useIntl, FormattedMessage } from 'react-intl';

const AnniversaryAwards = () => {

  const showErrorModal = useErrorModal();
  const { formatMessage } = useIntl();
  const { data, loading } = useAnniversaryInstructor({});
  const { loading: downLoading, run: download } = useDownloadAnniversaryAward();

  if (loading) return <DotLoading color='primary' />

  return data.anniversaryYear ? <>
    <Divider>
      <FormattedMessage
        id="award-anniversary-year"
        values={{
          year: data.anniversaryYear,
          currentYear: data.currentYear
        }}
      />
    </Divider>
    <Button
      loading={downLoading}
      color="primary"
      fill="outline"
      block
      onClick={() => {
        if (isWechat()) {
          showErrorModal(formatMessage({ id: 'wechat-cert' }));
        } else {
          download();
        }
      }}
    >
      <FormattedMessage id="award-download-cert" />
    </Button>
  </> : <Divider><FormattedMessage id="award-no-anniversary" /></Divider>
}

export default AnniversaryAwards