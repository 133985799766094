import React from 'react';
import ReactDOM from 'react-dom';
import { history } from './services'
import './index.scss';

import createStore from './store/configureStore';
import rootSaga from './sagas';
import { Provider } from 'react-redux'

import { isiOS } from './components/common/utils';
import { configWechatJSSDK } from './components/common/withWechat';

const store = createStore(window.__INITIAL_STATE__);
store.runSaga(rootSaga);

const render = () => {
  if (isiOS()) {
    const url = document.location.href;
    console.log("URL for IOS wechat jssdk config: ", url)
    configWechatJSSDK(url);
  }
  const App = require('./App').default
  ReactDOM.render(
    <Provider store={store}>
      <App history={history} />
    </Provider>,
    document.getElementById('root')
  )
}

render()

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', render)
}