 import { Checkbox } from 'antd-mobile-v5';
import { Form, Button } from 'antd-mobile-v5';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import { getCookie } from '../../components/common/cookie';
import { useSaveProfile } from '../../services/hooks/user';
import { UserInfo } from '../IssueCerts/AddStudents';
import EmailVerify from './EmailVerify';
import MobileVerify from './MobileVerify';
import PasswordConfirm from './PasswordConfirm';
import SelectLanguage from '../../components/Login/SelectLanguage';
import { useShowMessage } from '../../services/hooks/ui';
import { useCheckAgreement, useMainlandIp, useTaiwanIp } from '../../services/hooks'
import { getParameterByName } from '../../components/common/utils';

import '../emailLogin/emailLogin.css';
import { debug } from '../../utils/env';

import styles from './Register.module.css';

function defaultCountryCode() {
  return getCookie('language') === 'sc' ? '86' : '886';
} 

export default function RegisterPage() {
  const { formatMessage } = useIntl();
  const { replace } = useHistory();
  const showMessage = useShowMessage();
  const [form] = Form.useForm();
  const lang = getCookie('language') || 'sc';
  const [emailVerified, setEmailVerified] = useState(false);
  const [mobileVerified, setMobileVerified] = useState(false);
  const [showUserInfoForm, setShowUserInfoForm] = useState(false);
  const [showPasswordForm, setShowPasswordForm] = useState(false); 
  const [agreement1, setAgreement1] = useState(false)
  const [agreement2, setAgreement2] = useState(false)
  const [countryCode, setCountryCode] = useState('86')
  const [youzanVerified, setYouzanVerified] = useState(false)
  const referer = getParameterByName('referer') || ''

  const { loading: profileSaving, run: saveProfile } = useSaveProfile({
    onSuccess: (profile) => {
      if(referer) replace(`/registrationSuccess?referer=${referer}`, { profile, youzanVerified });
      else replace('/registrationSuccess', { profile, youzanVerified });
    },
    onError: () => {
      replace('/registrationFailure');
    }
  });

  const { data: agreements } = useCheckAgreement();
  const { data: isMainlandIp } = useMainlandIp({});
  const { data: isTaiwanIp } = useTaiwanIp({});

  const onMobileVerified = ({ verified, youzanVerified}) => {
    setMobileVerified(verified);
    setYouzanVerified(youzanVerified)
  };

  const onFinish = (data) => {
    debug('Register info: ', data);
    if (!emailVerified) {
      showMessage('register-email-not-verified')
      return;
    }
    if (isMainlandIp && countryCode === '86' && !mobileVerified) {
      showMessage('register-mobile-not-verified')
      return;
    }
    if ((showUserInfoForm || showPasswordForm) && data.password !== data.passwordConfirm) {
      form.setFields([
        {
          name: 'passwordConfirm',
          errors: [formatMessage({ id: 'setPassword-errorConfirmPassword' })],
        },
      ]);
      return;
    }
    if (!data.agreement) {
      form.setFields([
        {
          name: 'agreement',
          errors: [formatMessage({ id: 'customize-cert-agree-first' })],
        },
      ]);
      return;
    }
    
    let saveData = { email: form.getFieldValue('email') }
    if(isMainlandIp) {
      saveData.countryCode = countryCode;
      saveData.mobile = form.getFieldValue('mobile')
    }
    if (showPasswordForm) saveData.password = form.getFieldValue('password')
    if (showUserInfoForm) {
      const { email, agreement, emailCode, password, passwordConfirm, mobileCode, ...otherData } = data
      saveData = {
        ...otherData,
        ...saveData
      }
    }
    if(agreement1 && agreement2) {
      const userAgreementDTOS = agreements.map(item => {
        return {
          ...item,
          agree: true
        }
      })
      saveData = {
        ...saveData,
        userAgreementDTOS
      }
    }
    saveProfile(saveData)
  }

  useEffect(() => {
    form.setFieldsValue({ agreement: agreement1 && agreement2 });
  }, [agreement1, agreement2])

  return (
    <div className="home-page">
      <SelectLanguage />
      <div className="logo">
        <img src={require('../../assets/images/PADI-logo.png')} alt="logo" />
      </div>
      <Form
        initialValues={{ countryCode: defaultCountryCode() }}
        layout="horizontal"
        form={form}
        onFinish={onFinish}
        validateTrigger="submit"
        footer={
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Button
            block
            type="submit"
            color="primary"
            loading={profileSaving}
          >
            {formatMessage({ id: 'loginComponent-signUpTitle' })}
          </Button>
          <Link style={{padding: '8px'}} to="/emailLogin"><FormattedMessage id="common-back" /></Link>
          </div>
        }
      >
        <Form.Header>
          <FormattedMessage id="setEmail-title" />
        </Form.Header>

        <EmailVerify 
          form={form} 
          onVerifyResult={({ verified, needPassword, needUserInfo }) => { 
            setEmailVerified(verified); 
            setShowUserInfoForm(needUserInfo)
            setShowPasswordForm(needPassword)
          } }/>
        {emailVerified && isMainlandIp && <>
          <Form.Header>
            <FormattedMessage id="setMobile-title" />
          </Form.Header>
          <MobileVerify 
            form={form} 
            emailVerified={emailVerified} 
            onVerifyResult={onMobileVerified}
            setCountryCode={setCountryCode}
            countryCode={countryCode} />
        </>}
        {showUserInfoForm && (
          <Form.Header>
            <FormattedMessage id="setProfile-title" />
          </Form.Header>
        )}
        {showUserInfoForm && <UserInfo form={form} />}
        {(showUserInfoForm || showPasswordForm) && (
          <Form.Header>
            <FormattedMessage id="setPassword-title" />
          </Form.Header>
        )}
        {(showUserInfoForm || showPasswordForm) && <PasswordConfirm form={form} />}
        <Form.Header>
            <FormattedMessage id="common-agreement" />
          </Form.Header>
        <Form.Item
            name="agreement"
            rules={[
              {
                required: true,
                message: formatMessage({ id: 'customize-cert-agree-first' }),
              },
            ]}
          >
          <Checkbox className={styles.agreement} checked={agreement1} onChange={val => setAgreement1(val)}>
              <FormattedMessage 
                id="common-agreement-hint"
                values={{
                  link: <a 
                          rel="noopener noreferrer" 
                          target="_blank" 
                          href={isTaiwanIp ? 'https://www.padi.com.tw/zh-hant/privacy' : `https://assets.padi.com.cn/agreement_${lang}.html`}
                        >
                          <FormattedMessage id="common-agreement" />
                        </a>
                }}
              />
            </Checkbox>
          <Checkbox className={styles.agreement} checked={agreement2} onChange={val => setAgreement2(val)}>
            <FormattedMessage
              id="common-info-agreement-hint"
              values={{
                link: (
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`https://assets.padi.com.cn/personalInfo_agreement_${lang}.html`}
                  >
                    <FormattedMessage id="common-info-agreement" />
                  </a>
                ),
              }}
            />
          </Checkbox>
        </Form.Item>
      </Form>
    </div>
  );
}
