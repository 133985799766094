import React, { Component } from 'react';
import { FormOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom'

const { Content, Sider } = Layout

class AdminContainer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            collapsed: false,
            mode: "inline",
            current: '0'
        }
    }

    componentDidMount() {
        const url = window.location.href
        if (url.indexOf('/admin/si') !== -1) this.setState({ current: '1' })
        else if (url.indexOf('/admin/owsi') !== -1) this.setState({ current: '2' })
        else if (url.indexOf('/admin/efri') !== -1) this.setState({ current: '4' })
        else if (url.indexOf('/admin/basic_mermaid') !== -1) this.setState({ current: '5' })
        else if (url.indexOf('/admin/mermaid') !== -1) this.setState({ current: '6' })
        else if (url.indexOf('/admin/fdi') !== -1) this.setState({ current: '7' })
        else if (url.indexOf('/admin/afdi') !== -1) this.setState({ current: '8' })
        else if (url.indexOf('/admin/mfdi') !== -1) this.setState({ current: '9' })
        else if (url.indexOf('/admin/dsd') !== -1) this.setState({ current: '10' })
        else if (url.indexOf('/admin/dmd') !== -1) this.setState({ current: '11' })
        else if (url.indexOf('/admin/merge_certs') !== -1) this.setState({ current: '12' })
        else if (url.indexOf('/admin/find_account') !== -1) this.setState({ current: '13' })
        else if (url.indexOf('/admin/update_user_info') !== -1) this.setState({ current: '14' })
        else if (url.indexOf('/admin/wenjuan') !== -1) this.setState({ current: '15' })
        else if (url.indexOf('/admin/pockets_apps') !== -1) this.setState({ current: '16' })
        else if (url.indexOf('/admin/store_apps') !== -1) this.setState({ current: '17' })
        else if (url.indexOf('/admin/create_order') !== -1) this.setState({ current: '18' })
        else if (url.indexOf('/admin/non_routine_orders') !== -1) this.setState({ current: '19' })
        else if (url.indexOf('/admin/textbook_orders') !== -1) this.setState({ current: '20' })
        else if (url.indexOf('/admin/application_fee_orders') !== -1) this.setState({ current: '21' })
        else if (url.indexOf('/admin/invoice_info_orders') !== -1) this.setState({ current: '22' })
        else if (url.indexOf('/admin/orders_list') !== -1) this.setState({ current: '23' })
    }

    toggleCollapsed = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    render() {
        const { children } = this.props
        return (
            <Layout hasSider style={{ height: '100%', position: 'absolute', left: 0, top: 0, right: 0, bottom: 0 }}>
                <Sider 
                    collapsed={this.state.collapsed} 
                    style={{
                        overflow: 'auto',
                        height: '100vh',
                        background: '#fff'
                    }}
                    onCollapse={this.toggleCollapsed}
                >
                    <div type="primary" style={{ textAlign: 'center' }} id="logo">
                        <img src={require('./assets/images/PADI-logo.png')} style={{ width: '60%' }} alt="logo" />
                    </div>
                    <Menu
                        defaultSelectedKeys={['0']}
                        mode={this.state.mode}
                        selectedKeys={[this.state.current]}
                        inlineCollapsed={this.state.collapsed}
                    >
                        <Menu.Item key="0" onClick={() => this.setState({ current: '0' })}>
                            <Link to="/admin/dm">潜水长申请</Link>
                        </Menu.Item>
                        <Menu.Item key="1" onClick={() => this.setState({ current: '1' })}>
                            <Link to="/admin/si">专长申请</Link>
                        </Menu.Item>
                        <Menu.Item key="2" onClick={() => this.setState({ current: '2' })}>
                            <Link to="/admin/owsi">IE考试报名及教练申请</Link>
                        </Menu.Item>
                        <Menu.Item key="4" onClick={() => this.setState({ current: '4' })}>
                            <Link to="/admin/efri">EFRI申请</Link>
                        </Menu.Item>
                        <Menu.Item key="5" onClick={() => this.setState({ current: '5' })}>
                            <Link to="/admin/basic_mermaid">基础美人鱼教练申请</Link>
                        </Menu.Item>
                        <Menu.Item key="6" onClick={() => this.setState({ current: '6' })}>
                            <Link to="/admin/mermaid">美人鱼教练申请</Link>
                        </Menu.Item>
                        <Menu.Item key="7" onClick={() => this.setState({ current: '7' })}>
                            <Link to="/admin/fdi">自由潜教练申请</Link>
                        </Menu.Item>
                        <Menu.Item key="8" onClick={() => this.setState({ current: '8' })}>
                            <Link to="/admin/afdi">进阶自由潜教练申请</Link>
                        </Menu.Item>
                        <Menu.Item key="9" onClick={() => this.setState({ current: '9' })}>
                            <Link to="/admin/mfdi">名仕自由潜教练申请</Link>
                        </Menu.Item>
                        <Menu.Item key="10" onClick={() => this.setState({ current: '10' })}>
                            <Link to="/admin/dsd">PIC签证</Link>
                        </Menu.Item>
                        <Menu.Item key="11" onClick={() => this.setState({ current: '11' })}>
                            <Link to="/admin/dmd">DMD</Link>
                        </Menu.Item>
                        <Menu.Item key="12" onClick={() => this.setState({ current: '12' })}>
                            <Link to="/admin/merge_certs">查找证书</Link>
                        </Menu.Item>
                        <Menu.Item key="13" onClick={() => this.setState({ current: '13' })}>
                            <Link to="/admin/find_account">找回账号</Link>
                        </Menu.Item>
                        <Menu.Item key="14" onClick={() => this.setState({ current: '14' })}>
                            <Link to="/admin/update_user_info">修改信息</Link>
                        </Menu.Item>
                        <Menu.Item key="15" onClick={() => this.setState({ current: '15' })}>
                            <Link to="/admin/wenjuan">问卷审核</Link>
                        </Menu.Item>
                        <Menu.Item key="16" onClick={() => this.setState({ current: '16' })}>
                            <Link to="/admin/pockets_apps">其它申请</Link>
                        </Menu.Item>
                        <Menu.Item key="17" onClick={() => this.setState({ current: '17' })}>
                            <Link to="/admin/store_apps">潜店申请</Link>
                        </Menu.Item>
                        <Menu.SubMenu title="财务">
                            <Menu.Item key="18" onClick={() => this.setState({ current: '18' })}>
                                <Link to="/admin/create_order">创建订单</Link>
                            </Menu.Item>
                            <Menu.Item key="19" onClick={() => this.setState({ current: '19' })}>
                                <Link to="/admin/non_routine_orders">非常规订单</Link>
                            </Menu.Item>
                            <Menu.Item key="20" onClick={() => this.setState({ current: '20' })}>
                                <Link to="/admin/textbook_orders">教材订单</Link>
                            </Menu.Item>
                            <Menu.Item key="21" onClick={() => this.setState({ current: '21' })}>
                                <Link to="/admin/application_fee_orders">申请费订单</Link>
                            </Menu.Item>
                            <Menu.Item key="22" onClick={() => this.setState({ current: '22' })}>
                                <Link to="/admin/invoice_info_orders">发票信息</Link>
                            </Menu.Item>
                            <Menu.Item key="23" onClick={() => this.setState({ current: '23' })}>
                                <Link to="/admin/orders_list">订单列表</Link>
                            </Menu.Item>
                        </Menu.SubMenu>
                    </Menu>
                </Sider>
                <Layout>
                    <Content>
                        {children}
                    </Content>
                </Layout>
            </Layout>
        );
    }

}


export default AdminContainer;