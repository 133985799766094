import { useHistory } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import React, { useState } from 'react';

import {
  Button,
  DotLoading,
  NavBar,
  Checkbox,
  Form,
  Input,
  Result,
} from 'antd-mobile-v5';
import { getCookie } from '../../components/common/cookie';
import { useSelector } from 'react-redux';
import {
  useEFRPublicAppSubmit,
  useEFRPublicAppDetail,
} from '../../services/hooks';
import CenterLayout from '../../components/common/CenterLayout';

export default function EFRPublicInstructorAppPage() {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const history = useHistory();
  const language = getCookie('language');
  const { user } = useSelector((state) => ({
    user: state.data.userInfo,
  }));
  const [appDetail, setAppDetail] = useState();
  const { loading: detailLoading } = useEFRPublicAppDetail({
    manual: false,
    onSuccess: (data) => setAppDetail(data),
  });
  const { loading: submitLoading, run: submit } = useEFRPublicAppSubmit({
    onSuccess: (data) => setAppDetail({ status: 'SUBMIT' }),
  });
  if (detailLoading)
    return (
      <CenterLayout>
        <DotLoading />
      </CenterLayout>
    );
  if (appDetail && appDetail.status) {
    return appDetail.status !== 'SUCCESS' ? (
      <Result
        status="waiting"
        title={formatMessage({ id: 'efr-public-app-waiting-title' })}
        description={formatMessage({ id: 'efr-public-app-waiting-detail' })}
      />
    ) : (
      <Result
        status="success"
        title={formatMessage({ id: 'efr-public-app-done-title' })}
        description={formatMessage({ id: 'efr-public-app-done-detail' })}
      />
    );
  } else {
    return (
      <>
        <NavBar
          style={{ backgroundColor: 'white' }}
          onBack={() => history.goBack()}
        >
          {formatMessage({ id: 'profile-efr-public-app' })}
        </NavBar>
        <Form
          form={form}
          initialValues={user}
          layout="horizontal"
          onFinish={() => submit()}
          footer={
            <Button
              block
              type="submit"
              color="primary"
              loading={submitLoading}
              loadingText={formatMessage({ id: 'common-submit-loading' })}
            >
              {formatMessage({ id: 'common-confirmRequest' })}
            </Button>
          }
        >
          <Form.Header>
            {formatMessage({ id: 'dm-form-diver-info' })}
          </Form.Header>
          <Form.Item
            name="englishName"
            label={formatMessage({ id: 'common-name' })}
          >
            <Input readOnly />
          </Form.Item>
          <Form.Item
            name="memberNumber"
            label={formatMessage({ id: 'si-cd-instructor-num' })}
          >
            <Input readOnly />
          </Form.Item>
          <Form.Item
            name="memberAgreement"
            rules={[
              {
                required: true,
                message: formatMessage({ id: 'customize-cert-agree-first' }),
              },
            ]}
          >
            <Checkbox
              style={{
                '--icon-size': '16px',
                '--font-size': '14px',
                '--gap': '6px',
              }}
            >
              <FormattedMessage
                id="owsi-agreement-text"
                values={{
                  agreement: (
                    <a
                      href={`http://assets.padi.com.cn/agreements/725DT_Instructor_Membership_License_Agreement_Long_Form_2021_${language.toUpperCase()}.pdf`}
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      <FormattedMessage id="owsi-agreement" />
                    </a>
                  ),
                }}
              />
            </Checkbox>
          </Form.Item>
          <Form.Item
            name="appAgreement"
            rules={[
              {
                required: true,
                message: formatMessage({ id: 'customize-cert-agree-first' }),
              },
            ]}
          >
            <Checkbox
              style={{
                '--icon-size': '16px',
                '--font-size': '14px',
                '--gap': '6px',
              }}
            >
              {formatMessage({ id: 'efr-public-app-agreement' })}
            </Checkbox>
          </Form.Item>
        </Form>
      </>
    );
  }
}
