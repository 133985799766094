import React from 'react'
import { useSelector } from 'react-redux';
import AppList from '../../components/common/AppList'
import { skipMermaidTListPage } from '../../reducers/mermaid'


const MermaidTList = () => {
  const { confirmRes } = useSelector((state) => ({
    confirmRes: state.mermaid.mermaidTList,
  }));

  return <AppList confirmRes={confirmRes} detailUrl="/mermaid_app_detail" paging={skipMermaidTListPage} listHeader="mermaid-t-list-title" />
}

export default MermaidTList