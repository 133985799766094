import React, { useState } from 'react'
import { Card } from 'antd-mobile';
import { Button, Space } from 'antd-mobile-v5'
import { FormattedMessage } from 'react-intl';
import UploadPhoto from '../common/UploadPhoto'
import ModifyUserInfo from '../common/ModifyUserInfo'

const BasicInfo = ({ userInfo, storeInfo }) => {

  const [visible, setVisible] = useState(false)
  console.log(storeInfo)

  return <>
    <ModifyUserInfo visible={visible} setVisible={setVisible} userInfo={userInfo} />
    <Card className="profile-list">
      <Card.Header
        title={<Space block align="center">
          <span>{storeInfo ? <FormattedMessage id="profile-store-title" /> : <FormattedMessage id="profile-profile" />}</span>
          {!storeInfo && <Button 
            color='primary' 
            fill='none' 
            size='mini' 
            onClick={() => setVisible(true)}
          >
            <FormattedMessage id="updateUserInfo-title" />
          </Button>}
        </Space>}
        thumb={<i className="fa fa-user-o fa-lg"></i>}
      />
        <Card.Body>
          <div className='info-body'>
            <>
              {storeInfo ? (
                <div className="info">
                  <div className="info-left">
                    <div><FormattedMessage id="profile-store-name" /></div>
                    <div><FormattedMessage id="profile-store-num" /></div>
                  </div>
                  <div className="info-right">
                    <div>{storeInfo.storeName}</div>
                    <div>{storeInfo.storeNumber}</div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="info">
                    <div className="info-left">
                      <div><FormattedMessage id="profile-name" /></div>
                      <div><FormattedMessage id="profile-birthDate" /></div>
                    </div>
                    <div className="info-right">
                      <div>{userInfo.englishName}</div>
                      <div>{userInfo.birthDate}</div>
                    </div>
                  </div>
                </div>
              )}
            </>
            <div className='info-photo'>
              <UploadPhoto />
            </div>
          </div>
        </Card.Body>
    </Card>
  </>
}

export default BasicInfo