import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { WhiteSpace, List, WingBlank, Modal } from 'antd-mobile';
import Header from '../../components/common/FormHeader'
import DiverInfo from './DiverInfo'
import CardLayout from '../DiveMasterForm/CardLayout'
import Signature from '../../components/common/Signature'
import Agreement from './Agreement'
import CheckCredits from './CheckCredits'
import { uploadDetailAttach, submitInProcess, clearDetailAttach } from '../../reducers/ui'
import { FormattedMessage, useIntl } from 'react-intl';
import { specialtyMapping, specialty, verifyCredits, removeExpires, getSubmitOssFormat } from '../../components/common/utils'
import UploadOssFile from '../../components/common/UploadOssFile'
import UploadFileWrapper from '../../components/common/UploadFileWrapper'
import ApplicantDetailStatus from '../../components/common/ApplicantDetailStatus'
import { getCookie } from '../../components/common/cookie';
import { usePaySi, useSubmitSiApp } from '../../services/hooks';
import { useHistory } from 'react-router-dom';

const Item = List.Item

const SpecialtyAppDetail = () => {
  const lang = getCookie('language'); 
  const { push } = useHistory()
  const { runAsync: toPay } = usePaySi();
  const { runAsync: submitSi, loading: submitting } = useSubmitSiApp();

  const { userInfo, ossSignature, signatureUrl, siDetailInProcess, credits } = useSelector(state => ({
    userInfo: state.data.userInfo,
    ossSignature: state.oss.ossSignature,
    signatureUrl: state.ui.signatureUrl,
    siDetailInProcess: state.ui.siDetailInProcess,
    userPhoto: state.ui.getUserPhoto,
    credits: state.ui.credits,
  }));

  const [needAttach, setNeedAttach] = useState(false)
  const [checked, setChecked] = useState(false)
  const dispatch = useDispatch()
  const intl = useIntl()

  useEffect(() => {
    if(siDetailInProcess && siDetailInProcess.attachFile) setNeedAttach(true)
  },[siDetailInProcess])

  const checkSubmitStatus = () => {
    if(!(signatureUrl && checked)) {
      Modal.alert(
        intl.formatMessage({id: 'dm-form-submit-error'}), 
        intl.formatMessage({id: 'dm-form-submit-error-info-no-agreement'}), 
        [{ text: intl.formatMessage({id: 'common-close'}) }]
      );
      return false
    }
    if(needAttach) {
      if(!siDetailInProcess.attachFile.length > 0) {
        Modal.alert(
          intl.formatMessage({id: 'dm-form-submit-error'}), 
          intl.formatMessage({id: 'dm-form-submit-error-info-no-agreement'}), 
          [{ text: intl.formatMessage({id: 'common-close'}) }]
        );
        return false
      }
    }
    if(siDetailInProcess.memberApplicationType !== 'SI_1' && verifyCredits(parseInt(credits))) {
      Modal.alert(
        intl.formatMessage({id: 'dm-form-submit-error'}), 
        intl.formatMessage({id: 'dm-form-submit-error-info-no-agreement'}), 
        [{ text: intl.formatMessage({id: 'common-close'}) }]
      );
      return false
    }
    return true
  }

  const reSubmitSiApp = () => {
    if(!checkSubmitStatus()) return
    let submitData = {}
    if(siDetailInProcess.attachFile) {
      const { status, handwrittenSignatureUrlInstructor, attachFile, ...detailData } = siDetailInProcess
      submitData = {
        ...detailData,
        attachFile: removeExpires(attachFile),
        handwrittenSignatureUrlInstructor: getSubmitOssFormat(signatureUrl),
      }
    }else {
      const { status, handwrittenSignatureUrlInstructor, ...detailData } = siDetailInProcess
      submitData = {
        ...detailData,
        handwrittenSignatureUrlInstructor: getSubmitOssFormat(signatureUrl),
      }
    }
    dispatch(submitInProcess(submitData))
  }

  const submitApplication = () => {
    if(!checkSubmitStatus()) return
    const { attachFile, code, memberApplicationType, credentialId } = siDetailInProcess
    let submitData = {
      code: code,
      number: 1,
      handwrittenSignatureUrl: getSubmitOssFormat(signatureUrl),
      memberApplicationType: memberApplicationType,
      siApplicationSubmitDetailDTOList: [{ credentialId }]
    }
    if(attachFile) {
      submitData.number = attachFile.length
      submitData.siApplicationSubmitDetailDTOList = [{ credentialId, attachFile }]
    }
    submitSi(submitData).then(res => {
      if (res.code) {
        push(`/application_submit_success?type=${memberApplicationType}`, { code: res.code, paid: res.paid, paymentType: 'MEMBER_APPLICATION_SI' });
      }
    })
  }

  const getApplicableList = () => {
    return specialty.filter(item => item.spi === siDetailInProcess.credentialId)
  }

  const findAttachById = () => {
    return siDetailInProcess.attachFile || null
  }

  const editableStatus = (status, type) => {
    if(type === 'SI_1') {
      return false
    }else {
      // 教练申请被拒绝 || 提交未付款
      if(status === 'ADMIN_NOT_APPROVED') return true
    }
    return false
  }

  const instructorEditable = (status, type) => {
    // 教练申请被拒绝
    if(status === 'ADMIN_NOT_APPROVED' && type !== 'SI_1') return true
    // cd提交未付款
    else if(status === 'CD_CREATED') return true
    // cd提交
    else return false
  }

  const fileName = (type) => `${ossSignature.dir}${type}/${new Date().getTime()}`

  return userInfo && siDetailInProcess ? <>
    <Header 
      text={<FormattedMessage id="si-form-title" />}
      code={siDetailInProcess.code} />
    <DiverInfo userInfo={userInfo} />
    <WhiteSpace />
    {siDetailInProcess.memberApplicationType !== 'SI_1' && <CheckCredits />}
    <WhiteSpace />
    {siDetailInProcess.attachFile ? <CardLayout title={<FormattedMessage id="si-upload-attach" />} status={"none"}>
      {getApplicableList().map((item) => (
        <UploadFileWrapper 
          labelName={specialtyMapping[item.spi][lang]}
          editable={editableStatus(siDetailInProcess.status, siDetailInProcess.memberApplicationType)}>
          <UploadOssFile
            fileList={findAttachById() || []}
            editable={editableStatus(siDetailInProcess.status, siDetailInProcess.memberApplicationType)}
            type={"SI"}
            fileName={fileName(item.spi)}
            onChange={(formData) => {
              dispatch(uploadDetailAttach({ formData }))
            }}
            onDelete={(index) => dispatch(clearDetailAttach({ credentialId: item.spi, url: findAttachById()[index] }))} />
          <WingBlank>
            <div className="small-font gray">{item.desc[lang]}</div>
          </WingBlank>
        </UploadFileWrapper>
      ))}
    </CardLayout>
      : 
      <CardLayout title={<FormattedMessage id="si-sp-app" />} status={"none"}>
      <List>
        <Item>
          {specialtyMapping[siDetailInProcess.credentialId][lang]}
        </Item>
      </List>
    </CardLayout>}
    <WhiteSpace />
    <Signature
      filePath={'signature'}
      initSignature={siDetailInProcess.handwrittenSignatureUrlInstructor}
      modified={instructorEditable(siDetailInProcess.status, siDetailInProcess.memberApplicationType)}
    />
    {instructorEditable(siDetailInProcess.status, siDetailInProcess.memberApplicationType) && 
      <Agreement 
        setChecked={setChecked} 
        checked={checked}
        type={siDetailInProcess.memberApplicationType} />}
    <WhiteSpace size="xl" />
    <ApplicantDetailStatus
      detail={siDetailInProcess}
      idType={'cd'}
      submitFn={submitApplication}
      reSubmitFn={reSubmitSiApp}
      payFn={toPay}
      applicant={siDetailInProcess.memberApplicationType}
      applicationType={siDetailInProcess.memberApplicationType}
      payApplicationType={'MEMBER_APPLICATION_SI'}
      loading={submitting}
    /> 
    <WhiteSpace size="xl" />
  </> : null
}

export default SpecialtyAppDetail