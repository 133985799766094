import React from 'react'
import { useSelector } from 'react-redux';
import CardLayout from '../DiveMasterForm/CardLayout'
import { FormattedMessage } from 'react-intl';
import { verifyCredits } from '../../components/common/utils'

import styles from './SpecialtyInstructor.module.css'

const CheckCredits = () => {

  const { credits } = useSelector(state => ({
    credits: state.ui.credits
  }));

  return <CardLayout title={<FormattedMessage id="si-credits" />} status={!verifyCredits(parseInt(credits))}>
    {credits ? <div className={styles.credits}>
      <FormattedMessage 
        id="si-credits-text" 
        values={{ credits }} />
    </div> : <div className={styles.credits}>
      <i className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
    </div>}
  </CardLayout>
}

export default CheckCredits