import React, { useState } from 'react';
import { List, Dialog, Input } from 'antd-mobile-v5';
import { useHistory } from 'react-router-dom';
import { issueCertsOptions, useIssueCertsPay, usePicSummary, useErrorModal, useProfile } from '../../services/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { getParameterByName } from '../../components/common/utils';
import { isMainlandPro } from '../../utils/user';
import Loading from '../../components/Loading';

export default function PICHomePage() {
  const type = getParameterByName('type');
  const { push } = useHistory();
  const [showBuy, setShowBuy] = useState(false);
  const { loading, data: profile } = useProfile();

  const buyPic = () => {
    if(isMainlandPro(profile)) {
      setShowBuy(true);
    } else {
      push(`/issue_certs/buy_pic?type=${type}`);
    }
  }
  if(loading) return <Loading />;
  return (
    <div>
      <PICSummary />
      <List>
        <List.Item onClick={buyPic}>
          <FormattedMessage id="issue-certs-pic-buy" />
        </List.Item>
        <List.Item
          onClick={() => {
            push(`/issue_certs/pic_history?type=${type}`);
          }}
        >
          <FormattedMessage id="issue-certs-pic-history" />
        </List.Item>
        <List.Item
          onClick={() => {
            push(`/issue_certs/list?type=${issueCertsOptions[type].toCert}`);
          }}
        >
          <FormattedMessage id="issue-certs-history" />
        </List.Item>
      </List>
      <BuyPicDialog show={showBuy} setShowBuy={setShowBuy} />
    </div>
  );
}

function PICSummary() {
  const type = getParameterByName('type');
  const { loading, data } = usePicSummary(type);
  return (
    <div
      style={{
        background: 'white',
        borderRadius: '8px',
        margin: '12px',
        padding: '24px 12px',
        fontSize: '16px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div style={{ fontSize: '48px' }}>
        {loading ? '...' : data?.availablePIC}
      </div>
      <div>
        <FormattedMessage id="issue-certs-pic-left" />
      </div>
    </div>
  );
}

function BuyPicDialog({ show, setShowBuy }) {
  const type = getParameterByName('type');
  const { formatMessage } = useIntl();
  const [amount, setAmount] = useState(1);  
  const showErrorModal = useErrorModal();

  const { loading, run } = useIssueCertsPay(type);

  const submit = () => {
    const amountInt = Number.parseInt(amount);
    if(amountInt > 0) {
      run(amountInt);
    } else {
      showErrorModal(formatMessage({id: 'issue-certs-pic-amount-placeholder'}));
    }
  }
  return (
    <Dialog
      visible={show}
      title={formatMessage({ id: 'issue-certs-pic-buy' })}
      content={
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <label htmlFor="amount">
            <FormattedMessage id="issue-certs-pic-amount" />
          </label>

          <Input
            style={{
              width: '100px',
              marginLeft: '8px',
            }}
            id="amount"
            type="number"
            placeholder={formatMessage({ id: 'issue-certs-pic-amount-placeholder' })}
            value={amount}
            onChange={(value) => setAmount(value)}
          />
        </div>
      }
      actions={[
        [
          {
            key: 'cancel',
            text: formatMessage({ id: 'common-cancel' }),
            disabled: loading,
            onClick: () => setShowBuy(false),
          },
          {
            key: 'buy',
            text: formatMessage({ id: 'issue-certs-pic-buy' }),
            disabled: loading,
            bold: true,
            onClick: submit,
          },
        ],
      ]}
    />
  );
}
