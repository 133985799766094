import React from 'react'
import PaymentBtn from '../../components/FormTools/PaymentBtn'
import { usePayOtherApps } from '../../services/hooks'
import { Button } from 'antd-mobile-v5'
import { WhiteSpace, WingBlank } from 'antd-mobile';
import { useHistory, useLocation } from 'react-router-dom'
import { FormattedMessage } from 'react-intl';
import styles from '../DiveMasterForm/DiveMasterForm.module.css'

const PocketsApplicationResult = () => {

  const { state: { detail } } = useLocation()
  const { replace } = useHistory()
  const { runAsync: toPay, loading } = usePayOtherApps();

  return Object.keys(detail).length > 0 ? <>
    <div className={styles.applicationNo}>
      <div className={styles.applicationTitle}><FormattedMessage id="dm-form-applicationNo" /></div>
      <div className={styles.number}>{detail.code}</div>
    </div>
    {!detail.paid && <WingBlank>
      <PaymentBtn code={detail.code} paymentFn={toPay} payApplicationType={'MEMBER_APPLICATION_OTHER'} />
    </WingBlank>}
    <WhiteSpace />
    <WingBlank>
      <Button
        color="primary"
        fill='outline'
        block
        disabled={loading}
        onClick={() => replace('/pockets_apps_list')}
      >
        <FormattedMessage id="common-back" />
      </Button>
    </WingBlank>
  </> : null
}

export default PocketsApplicationResult