import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import GetCode from '../common/getCode';
import { isCode, isMainlandMobile, showError } from '../common/utils'
import { List, Modal, InputItem, Toast } from 'antd-mobile';
import { useIntl, FormattedMessage } from 'react-intl';
import { bindingYouzan, checkoutYouzanStatus } from '../../services/api' 
import { 
  requestYouzanMobileCode,
  pageLoading, 
  pageLoaded } from '../../actions'

import '../../pages/MyProfile/MyProfile.css'

const BindingYouzan = ({ onHandleFn = () => {}, children, pageEntry }) => {

  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const [mobile, setMobile] = useState('')
  const [code, setCode] = useState('')
  const [errors, setErrors] = useState({})
  const [youzanStatus, setYouzanStatus] = useState()

  useEffect(() => {
    requestYouzanStatus()
  }, [])

  const requestYouzanStatus = async () => {
    try {
      const { data } = await checkoutYouzanStatus()
      setYouzanStatus(data)
    }catch(e) {
      console.log(e)
    }
  }

  const modalTitle = () => {
    if(youzanStatus && youzanStatus.status === 1) return formatMessage({id: 'profile-youzan'})
    else if(youzanStatus && youzanStatus.status === 0) return formatMessage({id: 'profile-changeYouzan'})
    else return ''
  }

  const handleSwitch = () => {
    if(showModal) return
    if(youzanStatus.status === 0) {
      if(pageEntry) {
        setShowModal(true)
      }
      onHandleFn()
      return
    }
    setShowModal(true)
  }

  const setStateAndShowAlert = (isNotIdentity, isNotCode, message) => {
    Toast.hide();
    setErrors({ mobile: isNotIdentity, code: isNotCode })
    Toast.info(message, 2.5)
  }

  const handleBindingYouzan = async () => {
    if(isMainlandMobile(mobile) && !isCode(code)) {
      setStateAndShowAlert(!isMainlandMobile(mobile), !isCode(code), formatMessage({id: 'emailLogin-errorCode'}));
      return;
    } else if (!isMainlandMobile(mobile) || !isCode(code)) {
      setStateAndShowAlert(!isMainlandMobile(mobile), !isCode(code), formatMessage({id: 'setMobile-errorCode'}));
      return;
    }
    try {
      dispatch(pageLoading());
      const { data, error } = await bindingYouzan({
        mobile, 
        code
      });
      if(error) {
        showError(error.clientMsg)
        setCode('')
        return
      }
      if(data && data.status === 0) {
        setYouzanStatus(data)
        Toast.info('绑定成功', 2)
        setShowModal(false)
        setMobile('')
        setCode('')
        setErrors({})
      }else {
        Toast.info('绑定失败', 2)
      }
    } catch (e) {
      //TODO: handle error
      console.log(e);
    } finally {
      dispatch(pageLoaded());
    }
  }

  if(!youzanStatus) return null

  return (
    <div onClick={() => handleSwitch()}>
      <Modal
        visible={showModal}
        title={modalTitle()}
        transparent
        footer={[
          { text: formatMessage({id: 'common-close'}), onPress: () => setShowModal(false)},
          { text: formatMessage({id: 'profile-binding'}), onPress: () => handleBindingYouzan() }
        ]}>
        <p className='tips'><FormattedMessage id="profile-mobileTips" /></p>
        {youzanStatus.status === 0 && <p className='current-binding'><FormattedMessage id="profile-currentMobile" />{youzanStatus.data}</p>}
        <List className="binding-youzan">
          <InputItem
            type="mobile"
            name="mobile"
            maxLength={11}
            className="mobile-input"
            error={errors.mobile}
            onChange={mobile => setMobile(mobile.trim())}
            value={mobile}
            placeholder={formatMessage({id: 'setMobile-mobileInput'})}>
          </InputItem>
          <InputItem
            type="number"
            name="code"
            className='verify-code-input'
            error={errors.code}
            onChange={code => setCode(code.trim())}
            value={code}
            maxLength="6"
            placeholder={formatMessage({id: 'common-codeInput'})}
            extra={
              <GetCode requestFn={(args) => dispatch(requestYouzanMobileCode(args))} mobile={mobile} />}>
          </InputItem>
        </List>
      </Modal>
      {children}
    </div>
  ) 
}

export default BindingYouzan