import React, { Fragment, useState } from 'react';
import { WhiteSpace } from 'antd-mobile';
import ResourcesDetail from './ResourcesDetail';
import dayjs from 'dayjs'

import styles from './ResourcesList.module.css';

function getCollapseSign(collapse) {
  return collapse ? ' [-] ' : ' [+] ';
}

const Level2Dto = ({ itemDetail }) => {
  const [collapse, setCollapse] = useState(false);
  const isNewItem = () => {
    const itemDate = ('20' + itemDetail.updateTime + '-1').replace('年', '-').replace('月', '')
    return dayjs(itemDate).isAfter(dayjs().subtract(6, 'month'));
  }
  return (
<Fragment key={itemDetail.id}>
<div onClick={() => setCollapse(!collapse)}  className={styles.lineItem}>{itemDetail.title + getCollapseSign(collapse)}{!collapse && isNewItem() && <img src={require('../../assets/images/new.png')} alt="new" className={styles.new} />}</div>
<div className={styles.layer}>
  {itemDetail.memberLibraryDTOS.map((item) => (
    <div key={item.id}>
      <div>{item.title}</div>
      {item.memberLibraryDetailDTOS.map((detail) => (
        <ResourcesDetail
          key={detail.url}
          detailInfo={detail}
          layerStyle={'layer'}
        />
      ))}
    </div>
  ))}
  {collapse && itemDetail.memberLibraryDetailDTOS.map((detail) => (
    <ResourcesDetail key={detail.url} detailInfo={detail} />
  ))}
</div>
</Fragment>

  );
};

const ResourcesItem = ({ resource }) => {
  return (
    <div className={styles.resource}>
      <WhiteSpace size="lg" />
      {resource.memberLibraryDTOS.map((item) => (
        <Fragment key={item.id}>
          <div className={styles.folderTitle}>{item.title}</div>
          <div className={styles.layer}>
            {item.memberLibraryDTOS.map((itemDetail) => (<Level2Dto key={itemDetail.id} itemDetail={itemDetail} />
              // <Fragment key={itemDetail.id}>
              //   <div>{itemDetail.title + '===>'}</div>
              //   <div className={styles.layer}>
              //     {itemDetail.memberLibraryDTOS.map((item) => (
              //       <div key={item.id}>
              //         <div>{item.title}</div>
              //         {item.memberLibraryDetailDTOS.map((detail) => (
              //           <ResourcesDetail
              //             key={detail.url}
              //             detailInfo={detail}
              //             layerStyle={'layer'}
              //           />
              //         ))}
              //       </div>
              //     ))}
              //     {itemDetail.memberLibraryDetailDTOS.map((detail) => (
              //       <ResourcesDetail key={detail.url} detailInfo={detail} />
              //     ))}
              //   </div>
              // </Fragment>
            ))}
            {item.memberLibraryDetailDTOS.map((listItem) => (
              <ResourcesDetail key={listItem.url} detailInfo={listItem} />
            ))}
          </div>
        </Fragment>
      ))}
      {resource.memberLibraryDetailDTOS.map((item) => (
        <ResourcesDetail key={item.url} detailInfo={item} />
      ))}
      <WhiteSpace size="lg" />
    </div>
  );
};

export default ResourcesItem;
