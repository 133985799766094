import React from 'react'
import { Picker, List } from 'antd-mobile';
import styles from './Picker.module.css'
import { useIntl } from 'react-intl';
import { IsNotPc } from './utils'

const PickerComponent = ({
  data,
  onOk = () => { },
  value,
  disabled,
  children,
  extra,
  title,
  smallSize
}) => {
  const { formatMessage } = useIntl();

  function onChange(e) {
    const select = e.target
    const selectIndex = select.selectedIndex
    const val = select.options[selectIndex].value
    onOk(val)
  }

  return IsNotPc() ? (
    <Picker
      title={title}
      extra={extra}
      data={data}
      onOk={val => onOk(val[0])}
      cols={1}
      disabled={disabled}
      value={[value]}
      className="forss"
    >
      <List.Item arrow="horizontal">
        {children}
      </List.Item>
    </Picker>
  ) : (
      <List.Item arrow="horizontal">
        <div className={styles.wrapper}>
          <span className={styles.label}>{children}</span>
          <select
            value={value}
            onChange={onChange}
            disabled={disabled}
            className={smallSize ? styles.pickerWithSize : styles.picker}>
            <option value="" disabled="disabled">
              {formatMessage({ id: 'common-select' })}
            </option>
            {data.map(item => <option key={item.value} value={item.value}>{item.label}</option>)}
          </select>
        </div>
      </List.Item>
    )
}

export default PickerComponent