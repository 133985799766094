import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Pagination, WhiteSpace } from 'antd-mobile'
import CenterLayout from '../../components/common/CenterLayout';
import Header from '../../components/common/FormHeader'
import EFRIAppListItem from './EFRIAppListItem'
import { skipEfrtApplicationsPage } from '../../reducers/efri'

import styles from '../DmForInstructor/ConfirmAppsPage.module.css';

const EFRIAppList = () => {

  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const { confirmRes, confirmList } = useSelector((state) => ({
    confirmRes: state.efri.efrtList,
    confirmList: Object.keys(state.efri.efrtList).length > 0 ? state.efri.efrtList.content : []
  }));

  const skipTo = (page) => {
    setCurrentPage(page)
    dispatch(skipEfrtApplicationsPage({ page }))
  }

  if (!confirmRes) return null

  return (
    Object.keys(confirmRes).length > 0 ? (
      <>
        {confirmList.length === 0 ? (
          <CenterLayout>
            <p>
              <FormattedMessage id="dm-application-empty-list" />
            </p>
          </CenterLayout>
        ) : (
          <>
            <Header text={formatMessage({ id: 'efrt-list' })} />
            <div className={styles.confirmList}>
              {confirmList.map((item) => (
                <EFRIAppListItem key={item.code} confirmItem={item} />
              ))}
              <WhiteSpace />
              <Pagination
                total={confirmRes.totalPages}
                current={currentPage}
                locale={{
                  prevText: (<span className={styles.arrowAlign}><Icon type="left" /></span>),
                  nextText: (<span className={styles.arrowAlign}><Icon type="right" /></span>),
                }}
                onChange={(page) => skipTo(page)} />
            </div>
          </>
        )}
      </>
    ) : null
  )
}

export default EFRIAppList