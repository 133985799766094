import * as actions from '../actions'
import { debug } from '../utils/env'

// store data
export const getUserInfo = ({ data }) => data.userInfo
export const getWechatUser = ({ data }) => data.wechatUser
export const getNextCerts = ({ data }) => data.nextCerts

export const getEcards = ({ data }) => {
  const { certifications = [], credentials = [] } = data
  return certifications.concat(credentials)
    .sort((c1, c2) => {
      return (new Date(c2.issueDate).getTime()) - (new Date(c1.issueDate).getTime())
    })
}

export const getCardRequest = ({ data }) => data.cardRequest

export const isMember = ({ data }) => data.credentials && data.credentials.length > 0

export const getEntityRequestStatus = (entity) => (state) => state.entityRequestStatus[entity]

export const getResetEmail = ({ data }) => data.setEmail

export const getProfile = ({ data }) => data.profile

export const getCardRequestOptions = ({ data }, id) => data.cardRequestOptions ? data.cardRequestOptions[id] : null

export const getShoppingCart = ({ data }) => data.shoppingCart

export const getAddressList = ({ data }) => data.addressList

export const getStore = ({ data }) => {
  if (data.profile) return data.profile.store
}

// store common
// export const getLanguage = ({ common }) => common.language

export const getPageLoading = ({ common }) => common.pageLoading

export const getWechatConfig = ({ common }) => common.wechatJsConfig

// store entityRequestStatus
export const isRequesting = ({ entityRequestStatus }, entity) => entityRequestStatus[entity] === actions.ENTITY_REQUESTING

//store state
export const getEcardDetail = (id) => (state) => getEcards(state).find(e => e.uniqueId === decodeURIComponent(id))
export const getEcardDetailByAffId = (id) => (state) => getEcards(state).find(e => e.affiliateCertificationId === decodeURIComponent(id))

//store ui
export const getCartList = ({ ui }) => ui.shoppingCart.cartList

export const getCheckedList = ({ ui }) => ui.shoppingCart.checked

export const getPigCard = ({ ui }) => ui.hasPigCard

export const getHasSsoResult = ({ ui }) => ui.hasSSO

export const getVeifyMobileResult = ({ ui }) => ui.verifyMobileResult

export const getBookSaleList = ({ ui }) => ui.bookSaleList.data

export const getDisplayAddManager = ({ ui }) => ui.displayAddManager

export const getMemberRenew = ({ ui }) => ui.memberRenew

export const getStoreRenew = ({ ui }) => ui.storeRenew

export const getIeList = ({ owsi }) => owsi.ieListForExaminer
export const getOwsiCdDetail = ({ owsi }) => owsi.cdDetail