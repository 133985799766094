import React, { useState } from 'react'
import { useIntl } from 'react-intl';
import Header from '../../components/FormTools/FormHeader'
import { InfiniteScroll, List } from 'antd-mobile-v5'
import { useHistory } from 'react-router-dom'
import { useGetUnconventionalOrderList } from '../../services/hooks'
import InfiniteScrollContent from '../../components/FormTools/InfiniteScrollContent'
import { formatDate, textbooksOrderStatus } from '../../components/common/utils'
import { useLanguage } from '../../services/hooks/user';

import styles from './Order.module.css'

const OrderItemsHeader = ({ status, createDate }) => {

  const lang = useLanguage();

  const getOrderItemsStatus = () => {
    const statusList = textbooksOrderStatus[lang]
    return statusList.find(statusType => statusType.code === status).status
  }

  return <div className={styles.header}>
    <span>{formatDate(createDate, 'YYYY-MM-DD')}</span>
    <span>状态：{getOrderItemsStatus()}</span>
  </div>
}

const UnconventionalOrderList = () => {
  const { push } = useHistory()
  const { formatMessage } = useIntl()
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState(1)
  const [list, setList] = useState([])
  const { runAsync } = useGetUnconventionalOrderList(page);

  async function loadMore() {
    setHasMore(true)
    const append = await runAsync(page)
    setList(val => [...val, ...append.content])
    setHasMore(page < append.totalPages)
    setPage(page + 1)
  }

  return <>
    <Header
      text={formatMessage({ id: 'unconventional-order' })}
      type={"list"}
    />
    <List>
      {list.map(item => (
        <List.Item
          title={<OrderItemsHeader status={item.status} createDate={item.createDate} />}
          description={item.time}
          clickable
          onClick={() => push(`/unconventional_order_detail?id=${item.id}`)}
        >

          {item.itemDTOS.map(goods => <div className={styles.goods}>
            <div>{goods.title}</div>
            <div className={styles.count}>{item.currencyType} {goods.price} ×{goods.number}</div>
          </div>)}
          <div
            className={styles.total}>{formatMessage({ id: 'total-price' })} {item.currencyType} {item.totalPrice}</div>
        </List.Item>
      ))}
    </List>
    <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={300}>
      <InfiniteScrollContent hasMore={hasMore} />
    </InfiniteScroll>
  </>
}

export default UnconventionalOrderList