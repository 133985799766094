import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl';
import { Card, WhiteSpace, WingBlank } from 'antd-mobile';
import { Button } from 'antd-mobile-v5'
import { useHistory } from 'react-router-dom'
import { useDeleteMemberApplication } from '../../services/hooks';
import { showModal } from "./utils";
import PaymentBtn from '../FormTools/PaymentBtn'

import styles from '../../pages/DiveMasterForm/DiveMasterForm.module.css'

const moduleMapping = {
  module1: 'Written Exam (Pass)',
  module2: 'Knowledge Development (Pass)',
  module3: 'Confined Water (Pass)',
  module4: 'Open Water (Pass)'
}

const ApplicantDetailStatus = ({
  detail,
  idType,
  submitFn = () => { },
  reSubmitFn,
  payFn = () => { },
  isOwsi,
  applicant,
  applicationType,
  isEfri,
  payApplicationType,
  loading
}) => {

  const {
    status,
    code,
    payerType,
    module1,
    module2,
    module3,
    module4,
    ieDate,
    ieLocation,
  } = detail
  const { push, goBack } = useHistory()
  const { formatMessage } = useIntl()

  const { loading: deleteLoading, run } = useDeleteMemberApplication({
    onSuccess: () => {
      goBack();
    },
  });

  const showPayBtnStatus = () => {
    const { status, paymentMethod } = detail
    if (paymentMethod) {
      return false
    }
    if (isEfri || isOwsi) {
      if (status === 'INSTRUCTOR_APPROVED' && (payerType === 'EFRI' || payerType === 'CANDIDATE')) {
        return true
      }
      return false
    } else {
      if (status) {
        return true
      }
      return false
    }
  }

  const deleteApplication = () => {
    showModal(
      '',
      formatMessage({ id: "app-cancel" }),
      () => run({ code, type: applicationType }),
      () => { },
      formatMessage({ id: 'common-comfirmBtn' }),
      formatMessage({ id: 'common-cancel' })
    )
  }

  const AppStatus = () => {
    // if (status && !detail.paymentMethod) return null
    switch (status) {
      case 'ADMIN_APPROVED':
        return (
          <div className={styles.statusBtn}>
            <FormattedMessage id="app-wait-cert" />
          </div>
        );
        break;
      case 'WAIT_CONFIRM':
        if (idType === 'ins') {
          return (
            <div className={styles.statusBtn}>
              <FormattedMessage id="app-wait-ins-review" />
            </div>
          );
        } else if (idType === 'cd') {
          return (
            <div className={styles.statusBtn}>
              <FormattedMessage id="app-wait-cd-review" />
            </div>
          );
        } else if (idType === 'trainer') {
          return (
            <div className={styles.statusBtn}>
              <FormattedMessage id="app-wait-trainer-review" />
            </div>
          );
        }
        break;
      case 'INSTRUCTOR_APPROVED':
        if (detail.paymentMethod) {
          return (
            <div className={styles.statusBtn}>
              <FormattedMessage id="app-wait-cs-review" />
            </div>
          )
        } else {
          if (payerType === 'EFRT') {
            return <div className={styles.statusBtn}>
              <FormattedMessage id="efri-wait-efrt-pay" />
            </div>
          } else if (payerType === 'CD') {
            return <div className={styles.statusBtn}>
              <FormattedMessage id="app-wait-cd-pay" />
            </div>
          } else {
            return <div className={styles.statusBtn}>
              <FormattedMessage id="app-status-to-pay" />
            </div>
          }
        }
        break;
      case 'WAIT_ADMIN_APPROVED':
        if (!detail.paymentMethod) return null
        return (
          <div className={styles.statusBtn}>
            <FormattedMessage id="app-wait-cs-review" />
          </div>
        )
        break;
      case 'INSTRUCTOR_NOT_APPROVED':
        return (
          <Button 
            block 
            color="primary" 
            loading={loading}
            onClick={() => reSubmitFn ? reSubmitFn() : submitFn()}>
            <FormattedMessage id="app-resubmit" />
          </Button>
        );
        break;
      case 'ADMIN_NOT_APPROVED':
        if (payApplicationType === 'MEMBER_APPLICATION_SI' && applicant === 'SI_1') {
          return (
            <div className={styles.statusBtn}>
              <FormattedMessage id="app-cs-not-approved" />
            </div>
          )
        }
        return (
          <Button 
            block 
            color="primary" 
            loading={loading}
            onClick={() => reSubmitFn ? reSubmitFn() : submitFn()}>
            <FormattedMessage id="app-resubmit" />
          </Button>
        );
        break;
      case 'INSTRUCTOR_CREATED':
      case 'CD_CREATED':
        return (
          <Button block color="primary" loading={loading} onClick={() => submitFn()}>
            <FormattedMessage id="app-submit" />
          </Button>
        );
        break;
      case 'SUCCESS':
        if (isOwsi) {
          if (!module1 && !module2 && !module3 && !module4) {
            return (
              <div className={styles.statusBtn}>
                <FormattedMessage id="app-success" />
              </div>
            )
          } else {
            return (
              <Button block color="primary" onClick={() => push('/ie_resit')}>
                <FormattedMessage id="owsi-id-resits" />
              </Button>
            )
          }
        }
        return (
          <div className={styles.statusBtn}>
            <FormattedMessage id="app-success" />
          </div>
        )
        break;
      case 'PASS':
        if (isOwsi) {
          return <>
            <div className={styles.statusBtn}>
              <FormattedMessage id="app-wait-cert" />
            </div>
            <WhiteSpace />
            <Card>
              <Card.Header title={formatMessage({ id: "owsi-ie" })} />
              <Card.Body>
                <div><FormattedMessage id="owsi-ie-date" />：{ieDate}</div>
                <div><FormattedMessage id="owsi-ie-location" />：{ieLocation}</div>
              </Card.Body>
            </Card>
          </>
        }
        break;
      case 'NOT_PASS':
        if (isOwsi) {
          return <>
            <div className={styles.statusBtn}>
              <FormattedMessage id="owsi-not-pass" />
            </div>
            <div>
              <div>
                <FormattedMessage id="owsi-not-pass-reason" />
                {module1 && <div>{moduleMapping.module1}</div>}
                {module2 && <div>{moduleMapping.module2}</div>}
                {module3 && <div>{moduleMapping.module3}</div>}
                {module4 && <div>{moduleMapping.module4}</div>}
              </div>
            </div>
          </>
        }
        break;
      case 'CLOSE':
        return (
          <div className={styles.statusBtn}>
            <FormattedMessage id="app-close" />
          </div>
        )
        break;
      case 'PAY_ERROR':
        return (
          <div className={styles.statusBtn}>
            <FormattedMessage id="app-pay-error" />
          </div>
        )
        break;
      default:
        return (
          <Button block color="primary" loading={loading} onClick={() => submitFn()}>
            <FormattedMessage id="app-submit" />
          </Button>
        );
    }
  }

  return <WingBlank>
    <AppStatus />
    {showPayBtnStatus() &&
      <PaymentBtn code={code} paymentFn={payFn} payApplicationType={payApplicationType} />
    }
    {!detail.paymentMethod && status && (
      <Button
        block
        color="primary"
        fill='none'
        onClick={deleteApplication}
        loading={deleteLoading}
        disabled={deleteLoading}
      >
        <FormattedMessage id="app-delete" />
      </Button>
    )}
  </WingBlank>
}

export default ApplicantDetailStatus