import React from 'react';
import { useHistory } from "react-router-dom";
import { getCoverImagePath } from '../../services/utilities'
import CardImageShow from '../CertShow/CertShow'
import { formatLocalDate } from '../../utils/time';

import './Cert.css';


export default ({ ecard }) => {
  const { push } = useHistory();
  const coverImgPath = getCoverImagePath(ecard);

  const gotoDetail = () => {
    let url = ecard.type === 'CREDENTIAL'
      ? `/cert/credential/${encodeURIComponent(ecard.uniqueId)}`
      : `/cert/certificate/${ecard.studentNumber}`;
  
    const path = document.location.pathname;
    if(path === '/members' || path === '/ecards') {
      url += '?no_nav';
    }
    push(url);
  };

  return (
    <div className="cert-details" onClick={gotoDetail}>
      <div className="time-axis">
        <div className="time-spot" />
        <div className="time-line" />
      </div>
      <div className="cert-list-header">
        <h3>{formatLocalDate(ecard.issueDate)}
          {ecard.storeNumber !== 24351 && <span>{ecard.storeName}</span>}
        </h3>
      </div>
      <div className="card-wrapper">
        <CardImageShow path={coverImgPath} cert={ecard} />
      </div>
    </div>
  );
};
