/* eslint-disable no-constant-condition */
import { all, call, fork, put, select, spawn, take } from 'redux-saga/effects'
import { api } from '../services'
import * as actions from '../actions'
import {
  getCardRequestOptions,
  getCartList,
  getIeList,
  getMemberRenew,
  getOwsiCdDetail,
  getProfile,
  getShoppingCart,
  getStoreRenew,
  getUserInfo,
  getWechatUser,
  isRequesting
} from '../reducers/selectors'
import { delCookie, getCookie, getDataFromStorage, saveStorage, setCookie } from '../components/common/cookie';
import { LOCATION_CHANGE, push, replace } from 'connected-react-router'
import { getParameterByName, gtm, isEmpty, isWechat, removeExpires } from '../components/common/utils'
import {
  activityList,
  bookSaleList,
  clearSignature,
  dmFormCounter,
  getCdSiAppListRequest,
  getCreditsRequest,
  getDetailOssFile,
  getDmOssFile,
  getOssFile,
  getSiAppDetailRequest,
  getSiAppListRequest,
  getSiDetailInProcessRequest,
  getSignatureRequest,
  getStudentInfoRequest,
  getUserPhotoRequest,
  instructorApplication,
  instructorConfirmDetail,
  lightenElearning,
  memberLibraryList,
  submitDmForm,
  submitSignatureRequest,
  submitSiInprocessRequest,
  uploadAttachFile,
  uploadDetailAttachRequest,
  uploadDmAppFile,
  uploadFileRequest
} from '../reducers/ui'
import { getOssSignatureRequest } from '../reducers/oss'
import {
  applicantDetail,
  getCdListRequest,
  getOwsiOssFile,
  getOwsiTraining,
  ieCandidate,
  ieList,
  ieListForExaminer,
  owsiCdDetail,
  submitIeRequest,
  uploadCdTrainingToServer,
  uploadOwsiAttachFile,
  uploadOwsiTraining
} from '../reducers/owsi'
import { efriDetail, efrtDetail, getEfrOssFile, getEfrtListRequest, uploadEfrAttachFile } from '../reducers/efri'
import { getDsdListRequest, getInsturctorsListRequest } from '../reducers/dsd'

import {
  basicMermaidDetail,
  basicMermaidTDetail,
  getBasicMermaidOssFile,
  getBasicMermaidTListRequest,
  getMermaidOssFile,
  getMermaidTListRequest,
  mermaidDetail,
  mermaidTDetail,
  uploadBasicMermaidAttachFile,
  uploadMermaidAttachFile
} from '../reducers/mermaid'

import { FDIDetail, FDITDetail, getFDIOssFile, getFDITListRequest, uploadFDIAttachFile } from '../reducers/fdi'

import { getMFDIOssFile, getMFDITListRequest, MFDIDetail, MFDITDetail, uploadMFDIAttachFile } from '../reducers/mfdi'

import { AFDIDetail, AFDITDetail, getAFDIOssFile, getAFDITListRequest, uploadAFDIAttachFile } from '../reducers/afdi'
import { getReferer } from '../utils/env';

const {
  ecards,
  forgotPassword,
  cardRequestOptions,
  pageLoading,
  pageLoaded,
  wechatUser,
  errorDialog,
  profile,
  skipRouter,
  skipPath,
  shoppingCart,
  addressList,
  submitSuccess,
  delectAddressSuccess,
  hasPigYearCard,
  submitEcardImgBinding,
  cancelEcardImgBinding,
  ecardImgReBinding,
  registrationResult,
  noticeDisplay,
  noticeHide,
  verifyMobile,
  showToast,
  checkoutYouzanStatus,
  submitStoreNum,
  deleteStore,
  submitShoppingCart,
  getOrderYouzanUrl,
  memberRenew,
  storeRenew,
  memberRenewYouzanUrl,
  storeRenewYouzanUrl,
  clearCardRequestInfo,
  textBookSaleUrl,
  saveProfile,
  cdSubmitApp,
  refreshToken,
  loginSuccess,
  submitDsdRequest
} = actions

const padiWxId = getCookie('padiWxId')

function* fetchEntity(entity, apiFn, ...rest) {
  yield put(entity.request(...rest))
  const result = yield call(apiFn, ...rest)
  const { data, error } = result
  if (data) {
    yield put(entity.success(data))
    return { data }
  }
  if (error) {
    yield put(entity.failure(error))
    if (window.location.pathname.indexOf('/authorize') < 0) {
      yield put(errorDialog(error.clientMsg))
    }
    if ((error.code === 'auth.required' ||
      error.code === 'expired.token' ||
      error.code === 'email.not.inm2' ||
      error.code === 'sys.auth.permission.denied') && window.location.pathname.indexOf('/authorize') < 0) {
      const referer = getReferer();
      yield put(skipRouter(`/emailLogin?referer=${referer}`))
    } else if (error.code === 'renewal.member.fdi.need.efri') {
      yield put(skipRouter('/myProfile'))
    } else if (error.code === 'si.application.not.paid') {
      yield put(skipRouter('/specialtyApp'))
    } else if (error.code === 'youzan.need.associate') {
      yield put(skipRouter('/myProfile'))
    }
    return { error }
  }
}

function* fetchEntityNoDialog(entity, apiFn, ...rest) {
  yield put(entity.request(...rest))
  const result = yield call(apiFn, ...rest)
  const { data, error } = result
  if (data) {
    yield put(entity.success(data))
    return { data }
  }
  if (error) {
    yield put(entity.failure(error))
    return { error }
  }
}

function* fetchEntityNotJson(entity, apiFn, ...rest) {
  yield put(entity.request(...rest))
  const { data, error } = yield call(apiFn, ...rest)
  if (error) {
    yield put(entity.failure(error))
    yield put(errorDialog(error.clientMsg))
    return { error }
  } else if (data.toString()) {
    yield put(entity.success(data))
    return data
  } else {
    yield put(entity.failure(error))
  }
}

function* postEntity(apiFn, notError, ...rest) {
  const { data, error } = yield call(apiFn, ...rest)
  if (error) {
    // skip router automatically
    if (error.code === 'email.not.verified') {
      yield put(skipRouter('/verifyEmail'))
    }
    yield put(pageLoaded())
    if (!notError) yield put(errorDialog(error.clientMsg))
  }
  return data
}

export const fetchEcards = fetchEntity.bind(null, ecards, api.fetchEcards)
export const fetchCardRequestOptions = fetchEntity.bind(null, cardRequestOptions, api.cardRequestOptions)
export const fetchWechatUser = fetchEntity.bind(null, wechatUser, api.fetchWechatUser)
export const fetchProfiles = fetchEntity.bind(null, profile, api.fetchProfiles)
export const fetchForgotPassword = fetchEntity.bind(null, forgotPassword, api.forgotPassword)
export const postEmailLogin = postEntity.bind(null, api.emailLogin, false)
export const postVerifyEmail = postEntity.bind(null, api.verifyEmailCode, false)
export const postVerifyMobile = fetchEntity.bind(null, verifyMobile, api.verifyMobileCode)
export const fetchSaveProfile = fetchEntity.bind(null, saveProfile, api.saveProfile)
export const postResetPassword = postEntity.bind(null, api.resetPassword, false)
export const fetchShoppingCart = fetchEntity.bind(null, shoppingCart, api.fetchShoppingCart)
export const fetchSubmitShoppingCart = fetchEntity.bind(null, submitShoppingCart, api.addToCart)
export const fetchAddressList = fetchEntity.bind(null, addressList, api.fetchAddressList)
export const postAddress = postEntity.bind(null, api.postAddressList, false)
export const credentialRequest = postEntity.bind(null, api.credentialRequest, false)
export const delectAddress = postEntity.bind(null, api.delectAddress, false)
export const fetchHasAnimalYearCard = fetchEntityNotJson.bind(null, hasPigYearCard, api.hasActivityCard)
export const fetchEcardImgBinding = fetchEntity.bind(null, submitEcardImgBinding, api.ecardImgBinding)
export const fetchCancelEcardImgBinding = fetchEntityNotJson.bind(null, cancelEcardImgBinding, api.cancelEcardImgBinding)
export const fetchEcardImgReBinding = fetchEntity.bind(null, ecardImgReBinding, api.ecardImgReBinding)
export const fetchYouzanStatus = fetchEntity.bind(null, checkoutYouzanStatus, api.checkoutYouzanStatus)
export const postStore = fetchEntity.bind(null, submitStoreNum, api.submitStore)
export const deleteStoreManager = fetchEntity.bind(null, deleteStore, api.removeStore)
export const fetchOrderYouzanUrl = fetchEntity.bind(null, getOrderYouzanUrl, api.getOrderYouzanUrl)
export const fetchMemberRenew = fetchEntity.bind(null, memberRenew, api.getMemberRenew)
export const fetchStoreRenew = fetchEntity.bind(null, storeRenew, api.getStoreRenew)
export const fetchMemberRenewYouzanUrl = fetchEntity.bind(null, memberRenewYouzanUrl, api.getMemberRenewYouzanUrl)
export const fetchStoreRenewYouzanUrl = fetchEntity.bind(null, storeRenewYouzanUrl, api.getStoreRenewYouzanUrl)
export const fetchActivityList = fetchEntity.bind(null, activityList, api.getActivityList)
export const fetchSubmitDmForm = fetchEntity.bind(null, submitDmForm, api.submitDmForm)
export const fetchBookSaleList = fetchEntity.bind(null, bookSaleList, api.getTextBookSaleList)
export const fetchSubmitBookSaleList = fetchEntity.bind(null, textBookSaleUrl, api.getTextBookSaleUrl)
export const fetchMemberLibraryList = fetchEntity.bind(null, memberLibraryList, api.getMemeberLibrary)
export const fetchDmFormCounter = fetchEntity.bind(null, dmFormCounter, api.dmFormCounter)
export const fetchInstructorApplication = fetchEntity.bind(null, instructorApplication, api.instructorApplication)
export const fetchInstructorComfirmDetail = fetchEntity.bind(null, instructorConfirmDetail, api.instructorApplicationDetail)
export const postInstructorApproved = postEntity.bind(null, api.instructorApproved, false)
export const fetchSubmitInstructorSignature = fetchEntity.bind(null, submitSignatureRequest, api.submitInstructorSignature)
export const fetchOssSignature = fetchEntity.bind(null, getOssSignatureRequest, api.getOssSignature)
export const fetchSubmitDmSignature = fetchEntity.bind(null, submitSignatureRequest, api.uploadSignature)
export const fetchSignatureOssUrl = fetchEntity.bind(null, getSignatureRequest, api.getOssFile)
export const fetchLightenElearning = fetchEntity.bind(null, lightenElearning, api.getLigtenElearning)
export const fetchUserPhoto = fetchEntityNoDialog.bind(null, getUserPhotoRequest, api.getUserPhoto)
export const fetchUploadSiFile = fetchEntity.bind(null, uploadAttachFile, api.uploadSiFile)
export const fetchUploadSiDetailAttach = fetchEntity.bind(null, uploadDetailAttachRequest, api.uploadSiFile)
export const fetchUploadPhoto = fetchEntityNoDialog.bind(null, uploadFileRequest, api.uploadPhoto)
export const fetchSiDetail = fetchEntity.bind(null, getSiAppDetailRequest, api.getSiAppDetail)
export const fetchSiAppList = fetchEntity.bind(null, getSiAppListRequest, api.getSiAppList)
export const fetchSiDetailInProcess = fetchEntity.bind(null, getSiDetailInProcessRequest, api.getSiDetailInProcess)
export const fetchSubmitSiInprocess = fetchEntity.bind(null, submitSiInprocessRequest, api.submitSiInprocess)
export const fetchSearchInstructorInfo = fetchEntity.bind(null, getStudentInfoRequest, api.getInstructorInfoByNumber)
export const fetchCdSubmitSiApp = fetchEntity.bind(null, cdSubmitApp, api.cdSubmitSiApp)
export const fetchCdSiAppList = fetchEntity.bind(null, getCdSiAppListRequest, api.cdSiAppList)
export const fetchCdSiDetail = fetchEntity.bind(null, getSiDetailInProcessRequest, api.getCdSiDetail)
export const fetchReSubmitCdSiInprocess = fetchEntity.bind(null, submitSiInprocessRequest, api.reSubmitCdSiApp)
export const fetchGetOssFile = fetchEntity.bind(null, getOssFile, api.getOssFile)
export const fetchGetDetailOssFile = fetchEntity.bind(null, getDetailOssFile, api.getOssFile)
export const fetchGetCredits = fetchEntity.bind(null, getCreditsRequest, api.checkPrerequisiteRequirements)
export const fetchRefreshToken = fetchEntity.bind(null, refreshToken, api.refreshToken)
export const fetchOwsiDetail = fetchEntity.bind(null, applicantDetail, api.getOwsiDetail)
export const fetchGetDmOssFile = fetchEntity.bind(null, getDmOssFile, api.getOssFile)
export const fetchUploadDmFile = fetchEntity.bind(null, uploadDmAppFile, api.uploadSiFile)
export const fetchUploadOwsiFile = fetchEntity.bind(null, uploadOwsiAttachFile, api.uploadSiFile)
export const fetchGetOwsiOssFile = fetchEntity.bind(null, getOwsiOssFile, api.getOssFile)
export const fetchOwsiCdList = fetchEntity.bind(null, getCdListRequest, api.getOwsiCdList)
export const fetchOwsiCdDetail = fetchEntity.bind(null, owsiCdDetail, api.getOwsiCdDetail)
export const fetchIeList = fetchEntity.bind(null, ieList, api.getIeList)
export const fetchIeCandidateList = fetchEntity.bind(null, ieCandidate, api.getIeCandidateList)
export const fetchSubmitIe = fetchEntity.bind(null, submitIeRequest, api.submitIe)
export const fetchUploadOwsiTraining = fetchEntity.bind(null, uploadOwsiTraining, api.uploadSiFile)
export const fetchUploadCdTrainingToServer = fetchEntity.bind(null, uploadCdTrainingToServer, api.uploadTrainingRecord)
export const fetchGetOwsiTraining = fetchEntity.bind(null, getOwsiTraining, api.getOssFile)
export const fetchEfriDetail = fetchEntity.bind(null, efriDetail, api.getEfriDetail)
export const fetchEfrtList = fetchEntity.bind(null, getEfrtListRequest, api.getEfrtList)
export const fetchEfrtDetail = fetchEntity.bind(null, efrtDetail, api.getEFRTDetailByCode)
export const fetchUploadEfrOssFile = fetchEntity.bind(null, uploadEfrAttachFile, api.uploadSiFile)
export const fetchGetEfriOssFile = fetchEntity.bind(null, getEfrOssFile, api.getOssFile)
export const fetchIeListForExaminer = fetchEntity.bind(null, ieListForExaminer, api.ieListForExaminer)
export const fetchDsdList = fetchEntity.bind(null, getDsdListRequest, api.dsdList)
export const fetchDsdInstList = fetchEntity.bind(null, getInsturctorsListRequest, api.dsdInstList)
export const fetchSubmitDsd = fetchEntity.bind(null, submitDsdRequest, api.dsdSubmit)
export const fetchBasicMermaidDetail = fetchEntity.bind(null, basicMermaidDetail, api.basicMermaidDetail)
export const fetchUploadBasicMermaidFile = fetchEntity.bind(null, uploadBasicMermaidAttachFile, api.uploadSiFile)
export const fetchGetBasicMermaidOssFile = fetchEntity.bind(null, getBasicMermaidOssFile, api.getOssFile)
export const fetchBasicMermaidTList = fetchEntity.bind(null, getBasicMermaidTListRequest, api.getBasicMermaidTList)
export const fetchBasicMermaidTDetail = fetchEntity.bind(null, basicMermaidTDetail, api.getBasicMermaidTDetail)
export const fetchMermaidiDetail = fetchEntity.bind(null, mermaidDetail, api.mermaidiDetail)
export const fetchUploadMermaidFile = fetchEntity.bind(null, uploadMermaidAttachFile, api.uploadSiFile)
export const fetchGetMermaidOssFile = fetchEntity.bind(null, getMermaidOssFile, api.getOssFile)
export const fetchMermaidTList = fetchEntity.bind(null, getMermaidTListRequest, api.getMermaidTList)
export const fetchMermaidTDetail = fetchEntity.bind(null, mermaidTDetail, api.getMermaidTDetail)
export const fetchFdiDetail = fetchEntity.bind(null, FDIDetail, api.getFdiDetail)
export const fetchUploadFdiFile = fetchEntity.bind(null, uploadFDIAttachFile, api.uploadSiFile)
export const fetchGetFdiOssFile = fetchEntity.bind(null, getFDIOssFile, api.getOssFile)
export const fetchFdiTList = fetchEntity.bind(null, getFDITListRequest, api.getFdiTList)
export const fetchFdiTDetail = fetchEntity.bind(null, FDITDetail, api.getFdiTDetail)
export const fetchAfdiDetail = fetchEntity.bind(null, AFDIDetail, api.getAfdiDetail)
export const fetchUploadAfdiFile = fetchEntity.bind(null, uploadAFDIAttachFile, api.uploadSiFile)
export const fetchGetAfdiOssFile = fetchEntity.bind(null, getAFDIOssFile, api.getOssFile)
export const fetchAfdiTList = fetchEntity.bind(null, getAFDITListRequest, api.getAfdiTList)
export const fetchAfdiTDetail = fetchEntity.bind(null, AFDITDetail, api.getAfdiTDetail)
export const fetchMfdiDetail = fetchEntity.bind(null, MFDIDetail, api.getMfdiDetail)
export const fetchUploadMfdiFile = fetchEntity.bind(null, uploadMFDIAttachFile, api.uploadSiFile)
export const fetchGetMfdiOssFile = fetchEntity.bind(null, getMFDIOssFile, api.getOssFile)
export const fetchMfdiTList = fetchEntity.bind(null, getMFDITListRequest, api.getMfdiTList)
export const fetchMfdiTDetail = fetchEntity.bind(null, MFDITDetail, api.getMfdiTDetail)

function* loadEcardsList() {
  const diver = yield select(getUserInfo)

  const requesting = yield select(isRequesting, actions.ECARDS)

  if (!diver && !requesting) {
    yield call(loadEcards)
  }
}

function* loadEcards() {
  const profiles = yield call(loadProfile)
  if (!profiles) return;
  const { profile } = profiles
  const ecardsStorage = JSON.parse(getDataFromStorage(`ecards_${profile.email}`))

  if (ecardsStorage) {
    yield put(actions.refreshEcards(ecardsStorage))
    yield spawn(fetchEcardsAndSave, profile.email, ecardsStorage.data)
  } else {
    yield call(fetchEcardsAndSave, profile.email)
  }
}

function* fetchEcardsAndSave(email, saved) {
  const { data } = yield call(fetchEcards)
  if (data) {
    saveStorage(`ecards_${email}`, JSON.stringify(data))
    if (saved && (saved.credentials.length !== data.credentials.length || saved.certifications !== data.certifications.length)) {
      yield put(noticeDisplay('common-updateCerts'))
    }
  }
  return data
}

function* loadProfile() {
  const profiles = yield select(getProfile)
  if (!profiles) {
    const result = yield call(fetchProfiles)
    return result.data
  } else {
    return profiles
  }
}

function* loadBookSaleList() {
  const result = yield call(fetchBookSaleList)
  return result.data
}

function* loadWechatUser() {
  const wechatUser = yield select(getWechatUser)
  if (!wechatUser) yield call(fetchWechatUser)
}

function* loadCardRequestOptions(id) {
  const options = yield select(getCardRequestOptions, id)
  const requesting = yield select(isRequesting, actions.CARDREQUESTOPTIONS)
  if (!options && !requesting) {
    yield call(fetchCardRequestOptions, id)
  }
}

function* loadShoppingCart() {
  const shoppingCart = yield select(getShoppingCart)
  if (!shoppingCart) yield call(fetchShoppingCart)
}

function* loadMemberRenew() {
  const memberRenew = yield select(getMemberRenew)
  if (isEmpty(memberRenew)) return yield call(fetchMemberRenew)
}

function* loadStoreRenew() {
  const storeRenew = yield select(getStoreRenew)
  if (!storeRenew.length > 0) return yield call(fetchStoreRenew)
}

function* watchNavigate() {
  while (true) {
    const { payload: { location: { pathname } } } = yield take(LOCATION_CHANGE)
    if (pathname === '/certs' || pathname === '/ecards') {
      yield put(pageLoading())
      try {
        // const { data } = yield call(fetchActivityList)
        // if(data) {
        //   const { activityListItemDTOS, isMember } = data;
        //   const elearning = isMember && activityListItemDTOS.find(activity => activity.type === 'ELEARNING_3IN1')
        //   const gear = activityListItemDTOS.find(activity => activity.type === 'GEAR_VIDEO')
        //   if(elearning && elearning.homeShow) yield put(push('/eLearning'))
        //   else if (gear && gear.homeShow) yield put(push('/padiPromotion'))
        // }
        const padiWxId = getCookie('padiWxId')
        if (!padiWxId) return;
        const profiles = yield call(loadProfile)

        if (profiles && !profiles.error) {

          yield call(loadEcardsList)
          if (isWechat()) {
            const token = yield call(fetchRefreshToken)
            if (token.data) {
              setCookie('wechatToken', token.data.idToken)
            }
          }
        }
      } catch (error) {
        yield put(errorDialog())
      } finally {
        yield put(pageLoaded())
      }
    }
    if (pathname.indexOf('/myProfile') === 0 || pathname.indexOf('/members') === 0) {
      yield put(pageLoading())
      yield call(loadEcardsList)
      yield put(pageLoaded())
    }
    if (pathname === '/newYearCampaign' || pathname === '/free_pub_welfare_certs') {
      try {
        yield call(loadEcardsList)
      } catch (error) {
        yield put(errorDialog())
      }
    }
    if (pathname.indexOf('/addressForm') === 0) {
      yield take(actions.SUBMIT_SUCCESS)
      yield put(push('/certs'))
    }
    if (pathname.indexOf('/renew/') === 0) {
      yield put(pageLoading())
      if (pathname.indexOf('member') > 0) {
        yield call(loadMemberRenew)
      } else if (pathname.indexOf('store') > 0) {
        yield call(loadStoreRenew)
      }
      yield put(pageLoaded())
    }
    if (pathname === '/campaign') {
      yield put(pageLoading())
      yield call(fetchActivityList)
      yield put(pageLoaded())
    }
    if (pathname === '/dmForm') {
      yield put(pageLoading())
      yield call(fetchDmFormCounter)
      yield call(loadEcardsList)
      yield put(pageLoaded())
    }
    if (pathname === '/bookSaleList') {
      yield put(pageLoading())
      yield call(loadProfile)
      yield call(loadBookSaleList)
      yield put(pageLoaded())
    }
    if (pathname === '/memberLibrary') {
      yield put(pageLoading())
      yield call(fetchMemberLibraryList)
      yield put(pageLoaded())
    }
    if (pathname === '/dmForInstructor') {
      yield put(pageLoading())
      yield put(clearSignature())
      const data = {
        code: getParameterByName('code')
      }
      yield call(loadEcardsList)
      yield call(fetchInstructorComfirmDetail, data)
      yield put(pageLoaded())
    }
    if (pathname.indexOf('/confirmApps') === 0) {
      yield put(pageLoading())
      yield call(fetchInstructorApplication, { page: 1 })
      yield put(pageLoaded())
    }
    if (pathname === '/specialtyApp') {
      yield put(pageLoading())
      yield all([
        yield call(fetchSiDetail),
        yield fork(fetchOssSignature, 'SI'),
        yield fork(fetchGetCredits),
        yield call(loadEcardsList)
      ])
      yield put(pageLoaded())
    }
    if (pathname === '/siAppList') {
      yield put(pageLoading())
      yield call(fetchSiAppList)
      yield call(loadEcardsList)
      yield put(pageLoaded())
    }
    if (pathname.indexOf('/siAppDetail') === 0) {
      const credentialId = getParameterByName('credentialId')
      const code = getParameterByName('code')
      yield put(pageLoading())
      yield all([
        yield call(fetchSiDetailInProcess, { credentialId, code }),
        yield fork(fetchOssSignature, 'SI'),
        yield fork(fetchGetCredits),
        yield call(loadEcardsList)
      ])
      yield put(pageLoaded())
    }
    if (pathname.indexOf('/cdAppForm') === 0) {
      yield put(pageLoading())
      yield all([
        yield call(fetchOssSignature, 'SI'),
        yield call(loadEcardsList)
      ])
      yield put(pageLoaded())
    }
    if (pathname.indexOf('/cdAppList') === 0) {
      yield put(pageLoading())
      yield all([
        yield call(fetchCdSiAppList),
        yield call(loadEcardsList)
      ])
      yield put(pageLoaded())
    }
    if (pathname.indexOf('/cdAppDetail') === 0) {
      const credentialId = getParameterByName('credentialId')
      const code = getParameterByName('code')
      yield put(pageLoading())
      yield all([
        yield call(fetchCdSiDetail, { credentialId, code }),
        yield fork(fetchOssSignature, 'SI')
      ])
      yield put(pageLoaded())
    }
    if (pathname.indexOf('/owsi_app') === 0 || pathname.indexOf('/ie_resit') === 0) {
      yield put(pageLoading())
      yield all([
        yield call(fetchOssSignature, 'OWSI'),
        yield call(fetchOwsiDetail),
        yield call(fetchIeList),
        yield call(loadEcardsList)
      ])
      yield put(pageLoaded())
    }
    if (pathname.indexOf('/owsi_cd_apps') === 0) {
      yield put(pageLoading())
      yield call(fetchOwsiCdList, { page: 1 })
      yield put(pageLoaded())
    }
    if (pathname === '/owsi_cd_app_detail') {
      yield put(pageLoading())
      yield put(clearSignature())
      const code = getParameterByName('code')
      yield all([
        yield call(fetchOwsiCdDetail, { code }),
        yield call(loadEcardsList)
      ])
      yield put(pageLoaded())
    }
    if (pathname.indexOf('/efri_app') === 0) {
      yield put(pageLoading())
      yield all([
        yield fork(fetchOssSignature, 'EFRI'),
        yield call(fetchEfriDetail),
        yield call(loadEcardsList)
      ])
      yield put(pageLoaded())
    }
    if (pathname.indexOf('/efrt_list') === 0) {
      yield put(pageLoading())
      yield call(fetchEfrtList, { page: 1 })
      yield put(pageLoaded())
    }
    if (pathname === '/efrt_detail') {
      yield put(pageLoading())
      yield put(clearSignature())
      const code = getParameterByName('code')
      yield all([
        yield call(fetchEfrtDetail, code),
        yield call(loadEcardsList),
        yield fork(fetchOssSignature, 'EFRI'),
      ])
      yield put(pageLoaded())
    }
    if (pathname === '/basic_mermaid_app') {
      yield put(pageLoading())
      yield all([
        yield call(fetchBasicMermaidDetail),
        yield call(loadEcardsList),
        yield fork(fetchOssSignature, 'PADI_BASIC_MERMAID_I'),
      ])
      yield put(pageLoaded())
    }
    // if(pathname.indexOf('/course/') >= 0) {
    //   const course = pathname.split('/')[2]
    //   if(course === 'BasicFreediver' || course === 'Freediver' || course === 'EnrichedAirDiver') {
    //     yield put(pageLoading())
    //     yield call(fetchLightenElearning, mapCourse[course])
    //     yield put(pageLoaded())
    //   }
    // }
    if (pathname === '/ie_list') {
      yield put(pageLoading())
      yield call(fetchIeList)
      yield put(pageLoaded())
    }
    if (pathname.indexOf('ie_candidate_list') >= 0) {
      const ieId = getParameterByName('ie_id')
      yield put(pageLoading())
      yield call(fetchIeCandidateList, ieId)
      yield put(pageLoaded())
    }
    if (pathname.indexOf('/resources_collection') >= 0) {
      yield put(pageLoading())
      yield all([
        // yield call(loadEcardsList),
        yield call(fetchOssSignature, 'COLLECTION')
      ])
      yield put(pageLoaded())
    }
    if (pathname.indexOf('/ie_exam_attempts') >= 0) {
      const ieList = yield select(getIeList)
      if (!ieList || ieList.length === 0) {
        yield put(pageLoading())
        yield call(fetchIeListForExaminer)
        yield put(pageLoaded())
      }
    }
    if (pathname === '/dsd_trainee_list') {
      yield put(pageLoading())
      yield all([
        yield call(fetchDsdInstList),
        yield call(fetchDsdList)
      ])
      yield put(pageLoaded())
    }
    if (pathname === '/basic_mermaid_app_list') {
      yield put(pageLoading())
      yield call(fetchBasicMermaidTList, { page: 1 })
      yield put(pageLoaded())
    }
    if (pathname === '/basic_mermaid_app_detail') {
      yield put(pageLoading())
      yield put(clearSignature())
      const code = getParameterByName('code')
      yield all([
        yield call(fetchBasicMermaidTDetail, code),
        yield call(loadEcardsList),
        yield fork(fetchOssSignature, 'PADI_BASIC_MERMAID_I'),
      ])
      yield put(pageLoaded())
    }
    if (pathname === '/mermaidi_app') {
      yield put(pageLoading())
      yield all([
        yield call(fetchMermaidiDetail),
        yield call(loadEcardsList),
        yield fork(fetchOssSignature, 'PADI_MERMAID_I'),
      ])
      yield put(pageLoaded())
    }
    if (pathname === '/mermaid_app_list') {
      yield put(pageLoading())
      yield call(fetchMermaidTList, { page: 1 })
      yield put(pageLoaded())
    }
    if (pathname === '/mermaid_app_detail') {
      yield put(pageLoading())
      yield put(clearSignature())
      const code = getParameterByName('code')
      yield all([
        yield call(fetchMermaidTDetail, code),
        yield call(loadEcardsList),
        yield fork(fetchOssSignature, 'PADI_MERMAID_I'),
      ])
      yield put(pageLoaded())
    }
    if (pathname === '/fdi_app') {
      yield put(pageLoading())
      yield all([
        yield call(fetchFdiDetail),
        yield call(loadEcardsList),
        yield fork(fetchOssSignature, 'FDI'),
      ])
      yield put(pageLoaded())
    }
    if (pathname === '/fdi_app_list') {
      yield put(pageLoading())
      yield call(fetchFdiTList, { page: 1 })
      yield put(pageLoaded())
    }
    if (pathname === '/fdi_app_detail') {
      yield put(pageLoading())
      yield put(clearSignature())
      const code = getParameterByName('code')
      yield all([
        yield call(fetchFdiTDetail, code),
        yield call(loadEcardsList),
        yield fork(fetchOssSignature, 'FDI'),
      ])
      yield put(pageLoaded())
    }
    if (pathname === '/afdi_app') {
      yield put(pageLoading())
      yield all([
        yield call(fetchAfdiDetail),
        yield call(loadEcardsList),
        yield fork(fetchOssSignature, 'AFDI'),
      ])
      yield put(pageLoaded())
    }
    if (pathname === '/afdi_app_list') {
      yield put(pageLoading())
      yield call(fetchAfdiTList, { page: 1 })
      yield put(pageLoaded())
    }
    if (pathname === '/afdi_app_detail') {
      yield put(pageLoading())
      yield put(clearSignature())
      const code = getParameterByName('code')
      yield all([
        yield call(fetchAfdiTDetail, code),
        yield call(loadEcardsList),
        yield fork(fetchOssSignature, 'AFDI'),
      ])
      yield put(pageLoaded())
    }
    if (pathname === '/mfdi_app') {
      yield put(pageLoading())
      yield all([
        yield call(fetchMfdiDetail),
        yield call(loadEcardsList),
        yield fork(fetchOssSignature, 'MFDI'),
      ])
      yield put(pageLoaded())
    }
    if (pathname === '/mfdi_app_list') {
      yield put(pageLoading())
      yield call(fetchMfdiTList, { page: 1 })
      yield put(pageLoaded())
    }
    if (pathname === '/mfdi_app_detail') {
      yield put(pageLoading())
      yield put(clearSignature())
      const code = getParameterByName('code')
      yield all([
        yield call(fetchMfdiTDetail, code),
        yield call(loadEcardsList),
        yield fork(fetchOssSignature, 'MFDI'),
      ])
      yield put(pageLoaded())
    }
    if (pathname === '/new_fd_pic') {
      yield put(pageLoading())
      yield call(loadEcardsList)
      yield put(pageLoaded())
    }
    if (pathname === '/fd_pic_list') {
      yield put(pageLoading())
      yield call(loadEcardsList)
      yield put(pageLoaded())
    }
    if (pathname === '/pockets_apps' || pathname === '/issue_certs/detail') {
      yield put(pageLoading())
      yield call(loadEcardsList)
      yield put(pageLoaded())
    }
    if (pathname === '/books_checkout' || pathname === '/agent_elearning') {
      yield put(pageLoading())
      yield call(loadProfile)
      yield put(pageLoaded())
    }
    if(pathname === '/club_ecards') {
      yield put(pageLoading())
      yield call(loadEcardsList)
      yield put(pageLoaded())
    }
    if(pathname === '/register') {
      delCookie('padiWxId')
    }
  }
}

function* watchWechatUser() {
  while (true) {
    yield take(actions.REQUEST_WECHAT_USER)
    yield put(pageLoading())
    yield put(noticeHide())
    if (padiWxId && padiWxId.length < 30) yield call(loadWechatUser)
    yield put(pageLoaded())
  }
}

function* watchEmailLogin() {
  while (true) {
    const emailLogin = yield take(actions.EMAIL_LOGIN)
    const referer = getParameterByName('referer');

    yield put(pageLoading())
    try {
      const data = yield call(postEmailLogin, emailLogin)
      if (data && !isWechat() && data.token) {
        document.cookie = 'padiWxId=' + data.token;
      }
      if (data && data.token) {
        yield put(loginSuccess())
        yield put(pageLoaded())
        const source = getCookie('source')
        if (source) {
          delCookie('source')
          yield put(push('/patronus/post'))
        } else {
          if (referer) {
            window.location.href = referer
          } else {
            yield put(push('/certs'))
          }
        }
      }
    } catch (error) {
      yield put(pageLoaded())
      yield put(errorDialog())
    } finally {
      yield put(pageLoaded())
    }
  }
}

function* watchEmailCode() {
  while (true) {
    const requestCode = yield take(actions.REQUEST_EMAIL_CODE)
    try {
      yield call(api.getEmailCode, requestCode.email)
    } catch (error) {
      yield put(errorDialog())
    }
  }
}

function* watchDsdEmailCode() {
  while (true) {
    const requestCode = yield take(actions.REQUEST_DSD_EMAIL_CODE)
    try {
      yield call(api.getDsdEmailCode, requestCode.email)
    } catch (error) {
      yield put(errorDialog())
    }
  }
}

function* watchVerifyEmail() {
  while (true) {
    const verifyEmail = yield take(actions.VERIFY_EMAIL_CODE)
    yield put(pageLoading())
    try {
      yield call(fetchProfiles, verifyEmail.data.email)
      const data = yield call(postVerifyEmail, verifyEmail.data)
      if (data === true) {
        const profile = yield select(getProfile)
        const profileData = { email: profile.profile.email }
        yield call(fetchSaveProfile, profileData)
        yield put(pageLoaded())
        yield put(push('/setMobile'))
      }
    } catch (error) {
      yield put(pageLoaded())
      yield put(errorDialog())
    }
  }
}

function* watchPostProfile() {
  while (true) {
    yield take(actions.POST_PROFILE)
    yield put(pageLoading())
    const profile = yield select(getProfile)
    const profileData = {
      email: profile.profile.email,
      countryCode: profile.profile.countryCode,
      mobile: profile.profile.mobile,
      firstName: profile.profile.firstName,
      lastName: profile.profile.lastName,
      birthDate: profile.profile.birthDate,
      gender: profile.profile.gender
    }
    try {
      const { data } = yield call(fetchSaveProfile, profileData)
      if (data) {
        if (data.profileId) {
          yield put(pageLoaded())
          yield put(push('/setPassword'))
        }
      }
    } catch (error) {
      yield put(errorDialog())
    } finally {
      yield put(pageLoaded())
    }
  }
}

function* watchPostPassword() {
  while (true) {
    yield take(actions.POST_PASSWORD)
    yield put(pageLoading())
    const profile = yield select(getProfile)
    const profileData = {
      email: profile.profile.email,
      countryCode: profile.profile.countryCode,
      mobile: profile.profile.mobile,
      password: profile.profile.password,
      firstName: profile.profile.firstName,
      lastName: profile.profile.lastName,
      birthDate: profile.profile.birthDate,
      gender: profile.profile.gender
    }
    try {
      const { data } = yield call(fetchSaveProfile, profileData)
      if (data) {
        if (data.profileId) {
          yield put(registrationResult(false))
          yield put(push('/registrationSuccess'))
        } else {
          yield put(push('/registrationFailure'))
        }
      }
    } catch (error) {
      yield put(errorDialog())
    } finally {
      yield put(pageLoaded())
    }
  }
}

function* watchMobileCode() {
  while (true) {
    const requestCode = yield take(actions.REQUEST_MOBILE_CODE)
    try {
      yield call(api.getMobileCode, requestCode)
    } catch (error) {
      yield put(errorDialog())
    }
  }
}

function* watchVerifyMobile() {
  while (true) {
    const verifyMobile = yield take(actions.VERIFY_MOBILE_CODE)
    yield put(pageLoading())
    try {
      const { data } = yield call(postVerifyMobile, verifyMobile.data)
      if (data) {
        const { verifyMobileCode } = data
        if (verifyMobileCode === true) {
          const profile = yield select(getProfile)
          const profileData = {
            email: profile.profile.email,
            countryCode: profile.profile.countryCode,
            mobile: profile.profile.mobile
          }
          const { data } = yield call(fetchSaveProfile, profileData)
          if (data.affiliateId <= 0) {
            yield put(pageLoaded())
            yield put(push('/set_profile'))
            return
          }
          if (data.ssoType === 'NONE') {
            yield put(pageLoaded())
            yield put(push('/setPassword'))
          } else if (data.ssoType === 'EMAIL' || data.ssoType === 'MOBILE') {
            yield put(registrationResult(true))
            yield put(pageLoaded())
            yield put(push('/registrationSuccess'))
          } else {
            yield put(pageLoaded())
            yield put(push('/registrationFailure'))
          }
        }
      }
    } catch (error) {
      yield put(pageLoaded())
      yield put(errorDialog())
    } finally {
      yield put(pageLoaded())
    }
  }
}

function* watchForgotPassword() {
  while (true) {
    const { email } = yield take(actions.SUBMIT_FORGOT_PASSWORD)
    try {
      yield put(pageLoading())
      const { error } = yield call(fetchForgotPassword, email)
      if (!error) yield put(push('/resetResult'))
    } finally {
      yield put(pageLoaded())
    }
  }
}

function* watchResetPassword() {
  while (true) {
    const password = yield take(actions.RESET_PASSWORD)
    try {
      yield call(postResetPassword, password)
      yield put(push('/emailLogin'))
    } catch (error) {
      yield put(errorDialog())
    }
  }
}

function* watchShoppingCart() {
  while (true) {
    yield take(actions.ADD_TO_CART)
    const items = yield select(getCartList)
    try {
      yield call(fetchSubmitShoppingCart, items)
    } catch (error) {
      yield put(errorDialog())
    }
  }
}

function* watchSubmitEcardImg() {
  while (true) {
    const { data } = yield take(actions.SUBMIT_PIG_CARD)
    yield put(pageLoading())
    try {
      yield call(fetchEcardImgBinding, data)
      yield put(push("/certs"))
    } catch (e) {
      yield put(errorDialog())
    } finally {
      yield put(pageLoaded())
    }
  }
}

function* watchRequestHasPigYearCard() {
  while (true) {
    yield take(actions.REQUEST_HAS_PIG_YEAR_CARD)
    yield put(pageLoading())
    try {
      yield call(fetchHasAnimalYearCard, 14)
    } catch (e) {
    } finally {
      yield put(pageLoaded())
    }
  }
}

function* watchToPigCard() {
  while (true) {
    yield take(actions.TO_PIG_CARD)
    yield put(pageLoading())
    try {
      const result = yield call(fetchHasAnimalYearCard, 14)
      if (result.binded) {
        const language = getCookie('language');
        yield put(showToast(''))
        if (language === 'tc') yield put(showToast('您已領取龍年生肖卡'))
        else yield put(showToast('您已领取龙年生肖卡'))
      } else {
        yield put(push('/newYearCampaign'))
      }
    } catch (e) {
      console.log(e);
    } finally {
      yield put(pageLoaded())
    }
  }
}

function* watchDelectCartItem() {
  while (true) {
    yield take(actions.DELECT_CART_ITEM)
    const items = yield select(getCartList)
    try {
      yield call(fetchSubmitShoppingCart, items)
    } catch (error) {
      yield put(errorDialog())
    }
  }
}

function* watchSubmitAddress() {
  while (true) {
    const address = yield take(actions.SUBMIT_ADDRESS)
    try {
      yield call(postAddress, address.data)
      yield put(submitSuccess())
    } catch (error) {
      yield put(errorDialog())
    }
  }
}

function* watchCredentialRequest() {
  while (true) {
    const { data: { ecardId } } = yield take(actions.CREDENTIAL_REQUEST)
    try {
      yield put(pageLoading())
      const result = yield call(credentialRequest, ecardId)
      if (result) {
        const language = getCookie('language')
        if (language === 'tc') yield put(showToast('您的實體卡申請成功提交，請耐心等候。'))
        else yield put(showToast('您的实体卡申请成功提交，请耐心等候。'))
      }
      yield put(submitSuccess())
    } catch (error) {
      yield put(errorDialog())
    } finally {
      yield put(pageLoaded())
    }
  }
}

function* watchDelectAddress() {
  while (true) {
    const address = yield take(actions.DELECT_ADDRESS)
    try {
      yield call(delectAddress, address.data)
      yield put(delectAddressSuccess(address.data))
    } catch (error) {
      yield put(errorDialog())
    }
  }
}

function* watchReplaceCard() {
  while (true) {
    yield take(actions.REPLACE_CARD)
    const cartList = yield select(getCartList)
    try {
      yield call(fetchSubmitShoppingCart, cartList)
    } catch (error) {
      yield put(errorDialog())
    }
  }
}

function* watchCancelEcardImg() {
  while (true) {
    const { data } = yield take(actions.SUBMIT_CANCEL_ECARD_IMG_BINDING)
    yield put(pageLoading())
    try {
      yield call(fetchCancelEcardImgBinding, data)
    } catch (e) {
      yield put(errorDialog())
    } finally {
      yield put(pageLoaded())
    }
  }
}

function* watchEcardImgReBind() {
  while (true) {
    const { data } = yield take(actions.SUBMIT_ECARD_IMG_REBINDING)
    yield put(pageLoading())
    try {
      yield call(fetchEcardImgReBinding, data)
    } catch (e) {
      yield put(errorDialog())
    } finally {
      yield put(pageLoaded())
    }
  }
}

function* watchGetYouzanMobileCode() {
  while (true) {
    const { data: { mobile } } = yield take(actions.REQUEST_YOUZAN_MOBILE_CODE)
    try {
      yield call(api.getYouzanMobileCode, mobile)
    } catch (error) {
      yield put(errorDialog())
    }
  }
}

function* watchSubmitStoreNum() {
  while (true) {
    const { data } = yield take(actions.APPLY_STORE_MANAGER)
    const language = getCookie('language');
    yield put(pageLoading())
    try {
      const result = yield call(postStore, data)
      if (result.data && result.data.status === 0) {
        yield put(actions.hideAddManager())
        if (language === 'tc') yield put(showToast('申請成功！等待客服審核'))
        else if (language === 'en') yield put(showToast('Successful application! Waiting for customer service under review'))
        else yield put(showToast('申请成功！等待客服审核'))
      } else if (result.data && result.data.status === 1) {
        if (language === 'tc') yield put(showToast('申請失敗'))
        else if (language === 'en') yield put(showToast('Application failed!'))
        else yield put(showToast('申请失败'))
      }
    } catch (error) {
      yield put(errorDialog())
    } finally {
      yield put(pageLoaded())
    }
  }
}

function* watchRemoveStoreManager() {
  while (true) {
    yield take(actions.REMOVE_STORE_MANAGER)
    const language = getCookie('language');
    yield put(pageLoading())
    try {
      const result = yield call(deleteStoreManager)
      if (result.data && result.data.status === 0) {
        if (language === 'tc') yield put(showToast('解除成功'))
        else if (language === 'en') yield put(showToast('Remove successful!'))
        else yield put(showToast('解除成功'))
      } else if (result.data && result.data.status === 1) {
        if (language === 'tc') yield put(showToast('解除失敗'))
        else if (language === 'en') yield put(showToast('Remove failed!'))
        else yield put(showToast('解除失败'))
      }
    } catch (error) {
      yield put(errorDialog())
    } finally {
      yield put(pageLoaded())
    }
  }
}

function* watchRequestShoppingCart() {
  while (true) {
    yield take(actions.REQUEST_SHOPPING_CART)
    yield put(pageLoading())
    try {
      yield call(loadShoppingCart)
      yield put(pageLoaded())
    } catch (error) {
      yield put(pageLoaded())
      yield put(errorDialog())
    }
  }
}

function* watchRequestOrderYouzanUrl() {
  while (true) {
    const { data } = yield take(actions.REQUEST_ORDER_YOUZAN_URL)
    yield put(pageLoading())
    try {
      const youzanResult = yield call(fetchOrderYouzanUrl, data)
      if (youzanResult.data) {
        const { url } = youzanResult.data
        if (url) window.location.replace(url)
      }
      yield put(pageLoaded())
    } catch (error) {
      yield put(pageLoaded())
      yield put(errorDialog())
    }
  }
}

function* watchGoToYouzan() {
  while (true) {
    yield take(actions.GO_TO_YOUZAN)
    yield put(pageLoading())
    try {
      const { data } = yield call(fetchYouzanStatus)
      const language = getCookie('language');
      if (data && data.status === 0) {
        gtm('Click - PADI Gear', 'Bottom Navi');
        if (language === 'tc') yield put(showToast('正在轉向 PADI 有贊商城，可能會再次驗證您的手機號。'))
        else if (language === 'en') yield put(showToast('Redirecting you to PADI Youzan Shop, it may need to verify your mobile again.'))
        else yield put(showToast('正在转向 PADI 有赞商城，可能会再次验证您的手机号。'))
        setTimeout(() => {window.location.href = 'https://shop18875151.youzan.com'}, 2500)
      } else if (data && data.status === 1) {
        if (language === 'tc') yield put(showToast('請先至"我的-綁定 PADI 有贊商城賬號"進行綁定。'))
        else if (language === 'en') yield put(showToast('Please go to "My - Binding with PADI Youzan Shop account", and bind your PADI Youzan Shop account first.'))
        else yield put(showToast('请先至"我的-绑定 PADI 有赞商城账号"进行绑定。'))
      }
    } catch (error) {
      yield put(errorDialog())
    } finally {
      yield put(pageLoaded())
    }
  }
}

function* watchSubmitMemberRenew() {
  while (true) {
    const { data } = yield take(actions.SUBMIT_MEMBER_RENEW)
    try {
      yield put(pageLoading())
      const result = yield call(fetchMemberRenewYouzanUrl, data)
      if (result.data && result.data.url) {
        window.location.replace(result.data.url)
      }
    } catch (error) {
      yield put(errorDialog())
    } finally {
      yield put(pageLoaded())
    }
  }
}

function* watchSubmitStoreRenew() {
  while (true) {
    const { data } = yield take(actions.SUBMIT_STORE_RENEW)
    try {
      yield put(pageLoading())
      const result = yield call(fetchStoreRenewYouzanUrl, data)
      if (result.data.url) {
        window.location.replace(result.data.url)
      }
    } catch (error) {
      yield put(errorDialog())
    } finally {
      yield put(pageLoaded())
    }
  }
}

function* watchRequestMemberRenew() {
  while (true) {
    yield take(actions.REQUEST_MEMBER_RENEW)
    yield put(pageLoading())
    try {
      yield select(loadMemberRenew)
      yield put(push('/renew/member'))
    } catch (error) {
      yield put(errorDialog())
    } finally {
      yield put(pageLoaded())
    }
  }
}

// function* watchRequestStoreRenew() {
//   while(true) {
//     yield take(actions.REQUEST_STORE_RENEW)
//     yield put(pageLoading())
//     try {
//       yield call(loadStoreRenew)
//       const storeRenew = yield select(getStoreRenew)
//       if(JSON.stringify(storeRenew) !== "{}") yield put(push('/renew/store'))
//     }catch(error) {
//       yield put(errorDialog())
//     }finally {
//       yield put(pageLoaded())
//     }
//   }
// }

function* watchRequestStoreRenew() {
  while (true) {
    yield take(actions.REQUEST_STORE_RENEW)
    yield put(pageLoading())
    try {
      yield call(loadStoreRenew)
      const storeRenew = yield select(getStoreRenew)
      if (JSON.stringify(storeRenew) !== "{}") {
        const result = yield call(fetchStoreRenewYouzanUrl, { goodsId: storeRenew.goodsId })
        if (result.data.url) {
          window.location.href = result.data.url
        }
      }
    } catch (error) {
      yield put(errorDialog())
    } finally {
      yield put(pageLoaded())
    }
  }
}

function* watchRequestCardOptionspage() {
  while (true) {
    const { data } = yield take(actions.REQUEST_CARD_OPTIONS_PAGE)
    try {
      yield put(pageLoading())
      yield put(clearCardRequestInfo())
      yield spawn(loadShoppingCart)
      yield call(loadCardRequestOptions, data)
      yield call(loadEcardsList)
      yield put(actions.addEcardData(data))
    } catch (error) {
      yield put(errorDialog())
    } finally {
      yield put(pageLoaded())
    }
  }
}

function* watchUploadDmFile() {
  while (true) {
    const { payload } = yield take('dmFormCounter/uploadDmFile')
    yield put(pageLoading())
    const { data } = yield call(fetchUploadDmFile, payload)
    if (data) yield call(fetchGetDmOssFile, data.url.replace('files.padi.com.cn/', ''))
    yield put(pageLoaded())
  }
}

// function* watchSubmitDmForm() {
//   while (true) {
//     const { payload } = yield take('dmForm/submitForm')
//     yield put(pageLoading())
//     try {
//       const result = yield call(fetchSubmitDmForm, payload)
//       if (result.data && result.data.code) yield put(push('/dmFormResult'))
//     } catch (error) {
//       yield put(errorDialog())
//     } finally {
//       yield put(pageLoaded())
//     }
//   }
// }

function* watchSubmitBookSaleList() {
  while (true) {
    const { data } = yield take(actions.SUBMIT_BOOK_SALE_LIST)
    yield put(pageLoading())
    try {
      const newData = data.map(item => {
        return {
          ...item,
          number: item.number.toString()
        }
      })
      const result = yield call(fetchSubmitBookSaleList, newData)
      if (result.data && result.data.url) window.location.replace(result.data.url)
    } catch (error) {
      yield put(errorDialog())
    } finally {
      yield put(pageLoaded())
    }
  }
}

function* watchRequestMemberLibrary() {
  while (true) {
    yield take(actions.REQUEST_MEMBER_LIBRARY)
    yield put(pageLoading())
    try {
      const { data } = yield call(fetchMemberLibraryList)
      if (data) yield put(push('/memberLibrary'))
    } catch (error) {
      console.log(error)
      yield put(errorDialog())
    } finally {
      yield put(pageLoaded())
    }
  }
}

function* watchRequestDmConfirmDetail() {
  while (true) {
    const { payload } = yield take('instructorConfirmDetail/requestConfirmDetail')
    yield put(pageLoading())
    try {
      const { data } = yield call(fetchInstructorComfirmDetail, payload)
      if (data) yield put(push(`/dmForInstructor?code=${payload.code}`))
    } catch (error) {
      console.log(error)
      yield put(errorDialog())
    } finally {
      yield put(pageLoaded())
    }
  }
}

function* watchInstructorApproved() {
  while (true) {
    const { data } = yield take(actions.INSTRUCTOR_APPROVED)
    yield put(pageLoading())
    const result = yield call(postInstructorApproved, data)
    yield put(pageLoaded())
    if (result) yield put(replace('/confirmApps'))
  }
}

function* watchSubmitSignature() {
  while (true) {
    const { payload } = yield take('signature/submitSignature')
    const key = payload.formData.get('key')
    yield put(pageLoading())
    const { data } = yield call(fetchSubmitDmSignature, payload)
    if (data) {
      yield call(fetchSignatureOssUrl, key)
    }
    yield put(pageLoaded())
  }
}

function* watchSubmitInstructorSignature() {
  while (true) {
    const { payload } = yield take('signature/submitInstructorSignature')
    yield put(pageLoading())
    yield call(fetchSubmitInstructorSignature, payload)
    yield put(pageLoaded())
  }
}

function* watchSkipApplicationPage() {
  while (true) {
    const { payload } = yield take('instructorApplication/skipApplicationPage')
    yield put(pageLoading())
    yield call(fetchInstructorApplication, payload)
    yield put(pageLoaded())
  }
}

function* watchOssSignature() {
  while (true) {
    const { payload } = yield take('ossSignature/getOssSignature')
    yield call(fetchOssSignature, payload)
  }
}

function* watchSubmitDmSignature() {
  while (true) {
    const { payload } = yield take('uploadSignature/uploadSignatureImg')
    yield put(pageLoading())
    yield call(fetchSubmitDmSignature, payload)
    yield put(pageLoaded())
  }
}

function* watchUploadPhoto() {
  while (true) {
    const { payload } = yield take('userPhoto/uploadPhoto')
    const { data, error } = yield call(fetchUploadPhoto, payload)
    if (error && error.status === 401) {
      const token = yield call(fetchRefreshToken)
      if (token.data) {
        setCookie('wechatToken', token.data.idToken)
      }
      const { data } = yield call(fetchUploadPhoto, payload)
      if (data && data.success) {
        yield put(replace(window.location.pathname))
      }
    } else {
      if (data && data.success) {
        yield put(replace(window.location.pathname))
      }
    }
  }
}

function* watchRequestUserPhoto() {
  while (true) {
    const { payload } = yield take('userPhoto/getUserPhoto')
    yield call(fetchUserPhoto, payload)
  }
}

function* watchUploadSiFile() {
  while (true) {
    const { payload } = yield take('siAppDetail/uploadSifile')
    yield put(pageLoading())
    const { data } = yield call(fetchUploadSiFile, payload)
    if (data) yield call(fetchGetOssFile, data.url.replace('files.padi.com.cn/', ''))
    yield put(pageLoaded())
  }
}

function* watchUploadSiDetailAttach() {
  while (true) {
    const { payload } = yield take('siDetailInProcess/uploadDetailAttach')
    yield put(pageLoading())
    const { data } = yield call(fetchUploadSiDetailAttach, payload)
    if (data) yield call(fetchGetDetailOssFile, data.url.replace('files.padi.com.cn/', ''))
    yield put(pageLoaded())
  }
}

function* watchSubmitSiInprocess() {
  while (true) {
    const { payload } = yield take('siDetailInProcess/submitInProcess')
    yield put(pageLoading())
    try {
      const result = yield call(fetchSubmitSiInprocess, payload)
      if (result && result.data) yield put(push('/siAppList'))
    } catch (error) {
      yield put(errorDialog())
    } finally {
      yield put(pageLoaded())
    }
  }
}

function* watchSubmitCdDetailSiInprocess() {
  while (true) {
    const { payload } = yield take('siDetailInProcess/submitCdDetailInProcess')
    yield put(pageLoading())
    try {
      const result = yield call(fetchReSubmitCdSiInprocess, payload)
      if (result && result.data) yield put(push('/cdAppList'))
    } catch (error) {
      yield put(errorDialog())
    } finally {
      yield put(pageLoaded())
    }
  }
}

function* watchSearchInstruetorInfo() {
  while (true) {
    const { payload } = yield take('studentInfo/getStudentInfo')
    try {
      yield put(pageLoading())
      yield call(fetchSearchInstructorInfo, payload.instructorNumber)
    } catch (error) {
      yield put(errorDialog())
    } finally {
      yield put(pageLoaded())
    }
  }
}

function* watchCdSubmitSiApp() {
  while (true) {
    const { data } = yield take(actions.CD_SUBMIT_SI)
    yield put(pageLoading())
    try {
      const result = yield call(fetchCdSubmitSiApp, data)
      if (result.data && result.data.code) yield put(push('/cdAppList'))
    } catch (error) {
      yield put(errorDialog())
    } finally {
      yield put(pageLoaded())
    }
  }
}

function* watchUploadOwsiFile() {
  while (true) {
    const { payload } = yield take('applicantDetail/uploadOwsiFile')
    yield put(pageLoading())
    const { data } = yield call(fetchUploadOwsiFile, payload)
    if (data) yield call(fetchGetOwsiOssFile, data.url.replace('files.padi.com.cn/', ''))
    yield put(pageLoaded())
  }
}

function* watchSkipOwsiCdApplicationPage() {
  while (true) {
    const { payload } = yield take('cdList/skipCdApplicationsPage')
    yield put(pageLoading())
    yield call(fetchOwsiCdList, payload)
    yield put(pageLoaded())
  }
}

function* watchUploadOwsiTrainingFile() {
  while (true) {
    const { payload } = yield take('owsiCdDetail/uploadTrainingRecords')
    yield put(pageLoading())
    const { data } = yield call(fetchUploadOwsiTraining, payload)
    if (data) {
      const result = yield call(fetchGetOwsiTraining, data.url.replace('files.padi.com.cn/', ''))
      if (result.data) {
        const owsiCdDetail = yield select(getOwsiCdDetail)
        const uploadData = {
          code: owsiCdDetail.code,
          trainingRecord: removeExpires(owsiCdDetail.trainingRecord)
        }
        yield call(fetchUploadCdTrainingToServer, uploadData)
      }
    }
    yield put(pageLoaded())
  }
}

function* watchRequestOwsiCdDetail() {
  while (true) {
    const { payload } = yield take('owsiCdDetail/requestOwsiCdDetail')
    yield put(pageLoading())
    try {
      const { data } = yield call(fetchOwsiCdDetail, payload)
      if (data) yield put(push(`/owsi_cd_app_detail?code=${payload.code}`))
    } catch (error) {
      console.log(error)
      yield put(errorDialog())
    } finally {
      yield put(pageLoaded())
    }
  }
}

function* watchSubmitIe() {
  while (true) {
    const { payload } = yield take('ieCandidate/submitIeList')
    yield put(pageLoading())
    try {
      const { data } = yield call(fetchSubmitIe, payload)
      if (data && data.url) window.location.replace(data.url)
    } catch (error) {
      console.log(error)
      yield put(errorDialog())
    } finally {
      yield put(pageLoaded())
    }
  }
}

function* watchUploadEfriFile() {
  while (true) {
    const { payload } = yield take('efriDetail/uploadEfrOssFile')
    yield put(pageLoading())
    const { data } = yield call(fetchUploadEfrOssFile, payload)
    if (data) yield call(fetchGetEfriOssFile, data.url.replace('files.padi.com.cn/', ''))
    yield put(pageLoaded())
  }
}

function* watchSkipEfrtListPage() {
  while (true) {
    const { payload } = yield take('efrtList/skipEfrtApplicationsPage')
    yield put(pageLoading())
    yield call(fetchEfrtList, payload)
    yield put(pageLoaded())
  }
}

function* watchSubmitDsdForm() {
  while (true) {
    const { data } = yield take(actions.SUBMIT_DSD)
    yield put(pageLoading())
    const result = yield call(fetchSubmitDsd, data)
    if (result && result.data) yield put(push('/submit_success'))
    yield put(pageLoaded())
  }
}

function* watchUploadBasicMermaidFile() {
  while (true) {
    const { payload } = yield take('basicMermaidDetail/uploadBasicMermaidOssFile')
    yield put(pageLoading())
    const { data } = yield call(fetchUploadBasicMermaidFile, payload)
    if (data) yield call(fetchGetBasicMermaidOssFile, data.url.replace('files.padi.com.cn/', ''))
    yield put(pageLoaded())
  }
}

function* watchSkipBasicMermaidTListPage() {
  while (true) {
    const { payload } = yield take('basicMermaidTList/skipBasicMermaidTListPage')
    yield put(pageLoading())
    yield call(fetchBasicMermaidTList, payload)
    yield put(pageLoaded())
  }
}

function* watchSkipMermaidTListPage() {
  while (true) {
    const { payload } = yield take('mermaidTList/skipMermaidTListPage')
    yield put(pageLoading())
    yield call(fetchMermaidTList, payload)
    yield put(pageLoaded())
  }
}

function* watchUploadMermaidFile() {
  while (true) {
    const { payload } = yield take('mermaidDetail/uploadMermaidOssFile')
    yield put(pageLoading())
    const { data } = yield call(fetchUploadMermaidFile, payload)
    if (data) yield call(fetchGetMermaidOssFile, data.url.replace('files.padi.com.cn/', ''))
    yield put(pageLoaded())
  }
}

function* watchUploadFdiFile() {
  while (true) {
    const { payload } = yield take('FDIDetail/uploadFDIOssFile')
    yield put(pageLoading())
    const { data } = yield call(fetchUploadFdiFile, payload)
    if (data) yield call(fetchGetFdiOssFile, data.url.replace('files.padi.com.cn/', ''))
    yield put(pageLoaded())
  }
}

function* watchSkipFdiTListPage() {
  while (true) {
    const { payload } = yield take('FDITList/skipFDITListPage')
    yield put(pageLoading())
    yield call(fetchFdiTList, payload)
    yield put(pageLoaded())
  }
}

function* watchUploadAfdiFile() {
  while (true) {
    const { payload } = yield take('AFDIDetail/uploadAFDIOssFile')
    yield put(pageLoading())
    const { data } = yield call(fetchUploadAfdiFile, payload)
    if (data) yield call(fetchGetAfdiOssFile, data.url.replace('files.padi.com.cn/', ''))
    yield put(pageLoaded())
  }
}

function* watchSkipAfdiTListPage() {
  while (true) {
    const { payload } = yield take('AFDITList/skipAFDITListPage')
    yield put(pageLoading())
    yield call(fetchAfdiTList, payload)
    yield put(pageLoaded())
  }
}

function* watchUploadMfdiFile() {
  while (true) {
    const { payload } = yield take('MFDIDetail/uploadMFDIOssFile')
    yield put(pageLoading())
    const { data } = yield call(fetchUploadMfdiFile, payload)
    if (data) yield call(fetchGetMfdiOssFile, data.url.replace('files.padi.com.cn/', ''))
    yield put(pageLoaded())
  }
}

function* watchSkipMfdiTListPage() {
  while (true) {
    const { payload } = yield take('MFDITList/skipMFDITListPage')
    yield put(pageLoading())
    yield call(fetchMfdiTList, payload)
    yield put(pageLoaded())
  }
}


export default function* root() {
  yield all([
    fork(watchNavigate),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchMobileCode),
    fork(watchVerifyMobile),
    fork(watchVerifyEmail),
    fork(watchEmailCode),
    fork(watchEmailLogin),
    fork(watchPostPassword),
    fork(watchShoppingCart),
    fork(watchDelectCartItem),
    fork(watchSubmitAddress),
    fork(watchDelectAddress),
    fork(watchCredentialRequest),
    fork(watchReplaceCard),
    fork(watchSubmitEcardImg),
    fork(watchCancelEcardImg),
    fork(watchEcardImgReBind),
    fork(watchRequestHasPigYearCard),
    fork(watchToPigCard),
    fork(watchGetYouzanMobileCode),
    fork(watchSubmitStoreNum),
    fork(watchRemoveStoreManager),
    fork(watchRequestShoppingCart),
    fork(watchRequestOrderYouzanUrl),
    fork(watchGoToYouzan),
    fork(watchSubmitMemberRenew),
    fork(watchSubmitStoreRenew),
    fork(watchRequestMemberRenew),
    fork(watchRequestStoreRenew),
    fork(watchRequestCardOptionspage),
    fork(watchWechatUser),
    // fork(watchSubmitDmForm),
    fork(watchSubmitBookSaleList),
    fork(watchRequestMemberLibrary),
    fork(watchRequestDmConfirmDetail),
    fork(watchInstructorApproved),
    fork(watchSubmitSignature),
    fork(watchSubmitInstructorSignature),
    fork(watchSkipApplicationPage),
    fork(watchOssSignature),
    fork(watchSubmitDmSignature),
    fork(watchRequestUserPhoto),
    fork(watchUploadSiFile),
    fork(watchUploadPhoto),
    fork(watchUploadSiDetailAttach),
    fork(watchSubmitSiInprocess),
    fork(watchSearchInstruetorInfo),
    fork(watchCdSubmitSiApp),
    fork(watchSubmitCdDetailSiInprocess),
    fork(watchUploadDmFile),
    fork(watchUploadOwsiFile),
    fork(watchSkipOwsiCdApplicationPage),
    fork(watchRequestOwsiCdDetail),
    fork(watchPostProfile),
    fork(watchSubmitIe),
    fork(watchUploadOwsiTrainingFile),
    fork(watchUploadEfriFile),
    fork(watchSkipEfrtListPage),
    fork(watchSubmitDsdForm),
    fork(watchDsdEmailCode),
    fork(watchUploadBasicMermaidFile),
    fork(watchSkipBasicMermaidTListPage),
    fork(watchUploadMermaidFile),
    fork(watchSkipMermaidTListPage),
    fork(watchUploadFdiFile),
    fork(watchSkipFdiTListPage),
    fork(watchUploadAfdiFile),
    fork(watchSkipAfdiTListPage),
    fork(watchUploadMfdiFile),
    fork(watchSkipMfdiTListPage)
  ])
}
