import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl';
import BindingYouzan from '../MyProfile/BindingYouzan'
import { showAlert, getUserRegion } from '../common/utils'
import { WhiteSpace } from 'antd-mobile';
import { Button } from 'antd-mobile-v5'
import { useHistory } from 'react-router-dom';
import { useTaiwanIp } from '../../services/hooks'
import AgreementPopup from './AgreementPopup'

const PaymentBtn = ({ code, paymentFn, payApplicationType }) => {

  const { push } = useHistory()
  const [isSubmitting, setSubmitting] = useState(false);
  const { formatMessage } = useIntl()
  const [visible, setVisible] = useState(false)
  const { profile, userInfo } = useSelector((state) => ({
    profile: state.data.profile,
    userInfo: state.data.userInfo,
  }));
  const { data: isTaiwanIp, loading } = useTaiwanIp({});

  const toPay = async () => {
    try {
      setSubmitting(true);
      const result = await paymentFn(code)
      if(result.error) {
        showAlert(
          result.error.clientMsg,
          formatMessage({ id: 'common-close' })
        );
        return
      }
      if (result.url) {
        if (profile.teachingLocation === 'TW') return push('/invoice_form', { code, nextUrl: result.url, type: payApplicationType })
        window.location.replace(result.url)
      }
    }finally {
      setSubmitting(false);
    }
  }

  const verifyIpBeforePay = () => {
    if (profile.teachingLocation === 'TW' || isTaiwanIp) {
      setVisible(true)
    }else {
      toPay()
    }
  }

  if (!userInfo || !profile || loading) return null

  return (
    <>
      <AgreementPopup visible={visible} setVisible={setVisible} toPay={toPay} />
      <WhiteSpace />
      {getUserRegion(userInfo.addressDTO.countryISOCode, profile.teachingLocation) === 'CN' ? (
        <BindingYouzan onHandleFn={() => verifyIpBeforePay()}>
          <Button 
            block 
            color='primary' 
            loading={isSubmitting} 
            disabled={isSubmitting}>
            <FormattedMessage id="app-to-pay" />
          </Button>
        </BindingYouzan>
      ) : (
          <Button 
            block 
            color='primary'
            onClick={verifyIpBeforePay}
            loading={isSubmitting} 
            disabled={isSubmitting}>
          <FormattedMessage id="app-to-pay" />
        </Button>
      )}
    </>
  )
}

export default PaymentBtn