import { Button, List, Card, Mask } from 'antd-mobile-v5';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import React, { useState } from 'react';

import { usePwInfo } from '../../services/hooks';
import EmptyStatus from '../../components/common/EmptyStatus';
import LoadingStatus from '../../components/FormTools/Loading';

import styles from './PublicWelfareCert.module.css';

export default function PublicWelfareCert() {
  const history = useHistory();
  const { data, loading } = usePwInfo();
  const [visible, setVisible] = useState(false);
  const [current, setCurrent] = useState();

  if (loading) return <LoadingStatus />;
  if (!data) return <EmptyStatus desc="暂无证书" />;
  return (
    <>
      <Card className={styles.pwInfo}>
        <p>感谢您为海洋环保做出的努力和贡献！</p>
        <p>
          目前您已为 PADI 爱海洋助力 <strong>{data.number}</strong> 次，{/**共计{' '}
          <strong>{data.priceTotal}</strong> 元， */}获取{' '}
          <strong>{data.number}</strong> 张 PADI 爱海洋公益助力证书。
        </p>
        <p>再微小的动作，都是一束微光，绽放灿烂光芒。</p>
        <Button
          block
          color="primary"
          onClick={() => history.push('/buy_cards')}
        >
          购买公益卡，为海洋助力
        </Button>
      </Card>

      {(data.publicWelfareCertificates.length > 0 ||
        data.ecardCustomizations.length > 0) && (
        <List header="助力记录">
          {data.publicWelfareCertificates.map((c) => (
            <List.Item
              key={c.date}
              // extra={`${c.price}元`}
              onClick={() =>
                history.push(
                  `/pw_cert?nickName=${data.nickName}&no=${c.no}&from=public_welfare_cert`
                )
              }
            >
              {dayjs(c.date).format('YYYY年MM月DD日')}
            </List.Item>
          ))}
          {data.ecardCustomizations.map((c, i) => (
            <List.Item
              key={c.date}
              extra={dayjs(c.date).format('YYYY年MM月DD日')}
              onClick={() => {
                setVisible(true);
                setCurrent(i);
              }}
            >
              {/* 公益捐款：{c.price}元 */}
            </List.Item>
          ))}
        </List>
      )}
      {current >= 0 && (
        <Mask visible={visible} onMaskClick={() => setVisible(false)}>
          <div className={styles.certBox}>
            <img
              src={require('../../assets/images/publicCert.jpg')}
              alt=""
              className={styles.cert}
            />
            <div className={styles.text}>
              <p>
                诚挚感谢 {data.englishName} 于{' '}
                {dayjs(data.ecardCustomizations[current].date).format(
                  'YYYY 年 MM 月 DD 日'
                )}
              </p>
              <p>
                为海洋公益事业捐助 {data.ecardCustomizations[current].price} 元
              </p>
            </div>
          </div>
        </Mask>
      )}
    </>
  );
}
