import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Modal, Toast, WingBlank } from 'antd-mobile';
import { getSubmitOssFormat, showAlert, showModal } from "../../components/common/utils";
import { efriApproved } from '../../services/api';
import { usePayEfri } from '../../services/hooks'
import PaymentBtn from '../../components/FormTools/PaymentBtn'
import AgreementPopup from '../../components/FormTools/AgreementPopup'
import { useTaiwanIp } from '../../services/hooks'

import styles from '../DiveMasterForm/DiveMasterForm.module.css'

const ApplicationStatusContent = ({ courseDate, setVisible }) => {

  const { replace, push } = useHistory()
  const { formatMessage } = useIntl()
  const { runAsync: toPayEfri } = usePayEfri();
  const { data: isTaiwanIp } = useTaiwanIp({});

  const { efrtDetail, signatureUrl, profile } = useSelector(state => ({
    efrtDetail: state.efri.efrtDetail,
    signatureUrl: state.ui.signatureUrl,
    profile: state.data.profile,
  }));

  const { status, note, payerType, code, paymentMethod } = efrtDetail

  const approvedAction = (approve) => {
    if (!signatureUrl) {
      showModal(formatMessage({ id: 'common-errorTitle' }), formatMessage({ id: 'dm-form-signature-confirm' }), () => {}, () => {}, formatMessage({ id: 'common-comfirmBtn' }), formatMessage({ id: 'common-cancel' }))
      return
    }
    if (!courseDate) {
      showModal(formatMessage({ id: 'common-errorTitle' }), formatMessage({ id: 'efri-course-date-error' }), () => {}, () => {}, formatMessage({ id: 'common-comfirmBtn' }), formatMessage({ id: 'common-cancel' }))
      return
    }
    if (approve) {
      if (!payerType) {
        Modal.alert('', formatMessage({ id: 'owsi-pay-tips' }), [
          { text: formatMessage({ id: 'common-applicant-pay' }), onPress: () => submitApprovedFn('EFRI') },
          { text: formatMessage({ id: 'efri-efrt-pay' }), onPress: () => submitApprovedFn('EFRT') },
          { text: formatMessage({ id: 'common-cancel' }), onPress: () => console.log('cancel') },
        ])
      } else {
        submitApprovedFn()
      }
    } else {
      Modal.prompt(
        formatMessage({ id: 'dm-refuse-reason' }),
        formatMessage({ id: 'dm-refuse-tips' }),
        [
          { text: formatMessage({ id: 'common-cancel' }) },
          {
            text: formatMessage({ id: 'common-comfirmBtn' }), onPress: async value => {
              if (!value) {
                showModal(
                  '',
                  formatMessage({ id: 'dm-refuse-error' }),
                  () => {},
                  () => {},
                  formatMessage({ id: 'common-comfirmBtn' }),
                  formatMessage({ id: 'common-cancel' })
                );
                return
              }
              const approvedData = {
                approve,
                code: efrtDetail.code,
                handwrittenSignatureUrl: getSubmitOssFormat(signatureUrl),
                note: value,
                courseDate
              }
              try {
                Toast.loading()
                const response = await efriApproved(approvedData);
                if (response.error) {
                  showAlert(
                    response.error.clientMsg,
                    formatMessage({ id: 'common-close' })
                  );
                } else {
                  replace('/efrt_list')
                }
              } catch (e) {
                showAlert(
                  formatMessage({ id: 'common-errorStatus' }),
                  formatMessage({ id: 'common-close' })
                );
              } finally {
                Toast.hide()
              }
            }
          },
        ]
      )
    }
  };

  const submitApprovedFn = (payerType) => {
    showModal(
      '',
      formatMessage({ id: 'dm-form-instructor-accept-confirm' }),
      async () => {
        const approvedData = {
          approve: true,
          code: efrtDetail.code,
          handwrittenSignatureUrl: getSubmitOssFormat(signatureUrl),
          courseDate
        }
        if (payerType) approvedData.payerType = payerType
        try {
          Toast.loading()
          const response = await efriApproved(approvedData);
          if (response.error) {
            showAlert(
              response.error.clientMsg,
              formatMessage({ id: 'common-close' })
            );
          } else {
            if (payerType === 'EFRT') {
              if (profile.teachingLocation === 'TW' || isTaiwanIp) {
                setVisible(true)
              } else {
                replace('/efrt_list')
              }
            }else replace('/efrt_list')
          }
        } catch (e) {
          showAlert(
            formatMessage({ id: 'common-errorStatus' }),
            formatMessage({ id: 'common-close' })
          );
        } finally {
          Toast.hide()
        }
      },
      () => {}, formatMessage({ id: 'common-comfirmBtn' }),
      formatMessage({ id: 'common-cancel' })
    )
  }

  switch (status) {
    case 'WAIT_CONFIRM':
      return (
        <div className={styles.btnBox}>
          <Button
            inline
            type="primary"
            className={styles.btn}
            onClick={() => approvedAction(true)}
          ><FormattedMessage id="dm-form-agree" /></Button>
          <Button
            inline
            type="warning"
            className={styles.btn}
            onClick={() => approvedAction(false)}
          ><FormattedMessage id="dm-form-reject" /></Button>
        </div>
      )
    case 'INSTRUCTOR_APPROVED':
      if (paymentMethod) {
        return <div className={styles.statusBtn}>
          <FormattedMessage id="app-wait-cs-review" />
        </div>
      }
      if (payerType === 'EFRT') {
        return <WingBlank>
          <PaymentBtn code={code} paymentFn={toPayEfri} payApplicationType={'MEMBER_APPLICATION_EFRI'} />
        </WingBlank>
      } else if (payerType === 'EFRI') {
        return <div className={styles.statusBtn}>
          <FormattedMessage id="dm-form-wait-pay-student" />
        </div>
      } else {
        return null
      }
    case 'INSTRUCTOR_NOT_APPROVED':
      return <div className={styles.statusBtn}>
        <FormattedMessage id="app-status-instructor-not-approved" />
      </div>
    case 'ADMIN_NOT_APPROVED':
      return <div className={styles.statusBtn}>
        <div>
          <FormattedMessage id="app-cs-not-approved" />
        </div>
        {note && <div>
          <FormattedMessage id="app-refuse-reason" />{note}
        </div>}
      </div>
    case 'ADMIN_APPROVED':
      return <div className={styles.statusBtn}>
        <FormattedMessage id="app-wait-cert" />
      </div>
    case 'SUCCESS':
      return <div className={styles.statusBtn}>
        <FormattedMessage id="app-success" />
      </div>
    case 'CLOSE':
      return <div className={styles.statusBtn}>
        <FormattedMessage id="app-close" />
      </div>
    default:
      return null
  }
}

const ApplicationStatus = ({ courseDate }) => {

  const [visible, setVisible] = useState(false)
  const { formatMessage } = useIntl()
  const { push } = useHistory()
  const { runAsync: toPayEfri } = usePayEfri();
  const { profile, efrtDetail } = useSelector(state => ({
    profile: state.data.profile,
    efrtDetail: state.efri.efrtDetail,
  }));

  const toPay = async (code) => {
    const result = await toPayEfri(code)
    if (result.error) {
      showAlert(
        result.error.clientMsg,
        formatMessage({ id: 'common-close' })
      );
      return
    }
    if (result.url) {
      if (profile.teachingLocation === 'TW') return push('/invoice_form', { code, nextUrl: result.url, type: 'MEMBER_APPLICATION_EFRI' })
      window.location.replace(result.url)
    }
  }

  return <>
    <ApplicationStatusContent courseDate={courseDate} toPay={toPay} setVisible={setVisible} />
    <AgreementPopup visible={visible} setVisible={setVisible} toPay={() => toPay(efrtDetail.code)} />
  </>
}

export default ApplicationStatus