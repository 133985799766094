import React from 'react';
import { useSelector } from 'react-redux';
import { List, WhiteSpace, WingBlank } from 'antd-mobile';
import {
  specialtyMapping,
  cdSiAppStatus,
  groupBy,
} from '../../components/common/utils';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { getCookie } from '../../components/common/cookie';
import PaymentBtn from '../../components/FormTools/PaymentBtn';
import Header from '../../components/common/FormHeader'
import { usePaySi } from '../../services/hooks';

import styles from '../SpecialtyInstructor/SpecialtyInstructor.module.css';

const Item = List.Item;

const readyForPay = (apps) =>
  apps && apps.filter((app) => app.paymentMethod).length === 0;

const CdAppListPage = () => {
  const history = useHistory();
  const intl = useIntl();
  const lang = getCookie('language');
  const { runAsync: toPay } = usePaySi();

  const { cdSiAppList } = useSelector((state) => ({
    cdSiAppList: state.ui.cdSiAppList,
  }));

  const allApps = groupBy(cdSiAppList, 'code');
  return (
    <>
      <Header 
        text={<FormattedMessage id="si-form-title" />} 
        rightContent={<i
          className="fa fa-plus-square-o fa-lg"
          style={{ color: '#fff' }}
          onClick={() => history.push('/cdAppForm')}
        ></i>} />
      {cdSiAppList.length > 0 ? (
        Object.keys(allApps).map((code) => (
          <>
            <List
              renderHeader={() =>
                `${intl.formatMessage({ id: 'si-app-number' })}${code}`
              }
              className={styles.siList}
            >
              {allApps[code].map((item) => (
                <Item
                  wrap
                  extra={
                    item.status === 'ADMIN_NOT_APPROVED'
                      ? intl.formatMessage({ id: 'si-reSubmit' })
                      : intl.formatMessage({ id: 'si-check' })
                  }
                  arrow="horizontal"
                  onClick={() =>
                    history.push(
                      `/cdAppDetail?credentialId=${item.credentialId}&code=${item.code}`
                    )
                  }
                >
                  <div className={styles.siBox}>
                    <span>
                      <FormattedMessage id="si-sp" />
                    </span>
                    {specialtyMapping[item.credentialId][lang]}
                  </div>
                  <div className={styles.siBox}>
                    <span>
                      <FormattedMessage id="si-app-name" />
                    </span>
                    {item.instructorInfoDTO.instructorName}
                  </div>
                  <div className={styles.siBox}>
                    <span>
                      <FormattedMessage id="si-instructor-number" />
                    </span>
                    {item.instructorInfoDTO.instructorNumber}
                  </div>
                  <div className={styles.siBox}>
                    <span>
                      <FormattedMessage id="si-app-status" />
                    </span>
                    <span
                      className={
                        item.status === 'ADMIN_NOT_APPROVED'
                          ? styles.refuse
                          : styles.process
                      }
                    >
                      {(item.status === 'WAIT_ADMIN_APPROVED' || item.status === 'ADMIN_APPROVED') && 
                        !item.paymentMethod 
                        ? cdSiAppStatus[lang]['WAIT_PAY'] 
                        : cdSiAppStatus[lang][item.status]}
                      {/* {cdSiAppStatus[lang][item.status]} */}
                    </span>
                  </div>
                  {item.status === 'ADMIN_NOT_APPROVED' && (
                    <div className={styles.siBox}>
                      <span>
                        <FormattedMessage id="si-refuse-reason" />
                      </span>
                      <span className={styles.refuse}>{item.note}</span>
                    </div>
                  )}
                </Item>
              ))}
            </List>
            {readyForPay(allApps[code]) && <WingBlank>
              <PaymentBtn 
                code={code}
                paymentFn={toPay}
                payApplicationType={'MEMBER_APPLICATION_SI'}
              />
            </WingBlank>}
            <WhiteSpace />
            <WhiteSpace />
          </>
        ))
      ) : (
        <div className={styles.noList}>
          <img src={require('../../assets/images/no-list.png')} alt="no list" />
        </div>
      )}
    </>
  );
};

export default CdAppListPage;
