import React from 'react'
import { Checkbox,  WingBlank } from 'antd-mobile'

const AgreementCheckbox = ({ checked, setChecked, children }) => {
  return <WingBlank>
    <Checkbox.AgreeItem 
      style={{ marginLeft: '0' }}
      checked={checked} 
      onChange={e => setChecked(e.target.checked)}>
      {children}
    </Checkbox.AgreeItem>
  </WingBlank>
}

export default AgreementCheckbox