import React from 'react'
import { InputItem } from 'antd-mobile';
import CardLayout from '../DiveMasterForm/CardLayout'
import { FormattedMessage, useIntl } from 'react-intl';

const IeExamList = ({ cdDetail }) => {

  const { formatMessage } = useIntl()

  return <CardLayout title={formatMessage({ id: "owsi-ie" })} status={"none"}>
    <InputItem
      type="text"
      editable={false}
      value={cdDetail.ieScheduleDTO.location}
    >
      <FormattedMessage id="owsi-ie-location" />
    </InputItem>
    <InputItem
      type="text"
      editable={false}
      value={cdDetail.ieScheduleDTO.ieDate}
    >
      <FormattedMessage id="owsi-ie-date" />
    </InputItem>
  </CardLayout>
}

export default IeExamList