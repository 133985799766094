import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef } from 'react'
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux'
import { Upload } from 'antd'
import { WingBlank } from 'antd-mobile'
import { PlusOutlined } from '@ant-design/icons';
import * as config from '../../services/config.json'
import { getOssFile } from '../../services/api'
import { removeExpires } from '../../components/common/utils'

import styles from '../../components/common/UploadOssFile.module.css'

const UploadFile = forwardRef((props, ref) => {

  const [fileList, setFileList] = useState([])
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImg, setPreviewImg] = useState('')
  const [fileName, setFileName] = useState('')
  const [urlList, setUrlList] = useState([])
  const { ossSignature } = useSelector(state => ({
    ossSignature: state.oss.ossSignature
  }))
  const upload = useRef()

  useImperativeHandle(ref, () => ({
    fileList: urlList,
  }));

  useEffect(() => {
    if (props.fileList) {
      const mappingFileList = props.fileList.map((item, index) => {
        return {
          uid: index,
          url: item,
          name: removeExpires([item])[0],
          status: 'done'
        }
      })
      setFileList(mappingFileList)
    }
  }, [props.fileList])


  // /* 解决ios双击触发上传的问题，增加needsclick类名 */
  // useEffect(() => {
  //   console.log(upload.current)
  //   let uploadDOM = ReactDOM.findDOMNode(upload.current)
  //   setTimeout(() => {
  //     let addClassDOM = uploadDOM.querySelector('.ant-upload>.ant-upload')
  //     addClassDOM.className += ' needsclick'
  //   }, 0)
  // }, [])

  const getExtraData = () => {
    return {
      key: fileName,
      OSSAccessKeyId: ossSignature.accessid,
      policy: ossSignature.policy,
      Signature: ossSignature.signature,
    };
  };

  const handlePreview = file => {
    if (props.skipOss) {
      window.open(file.url, '_blank')
      return
    }
    const index = fileList.findIndex(item => item.uid === file.uid)
    setPreviewImg(urlList[index])
    setPreviewVisible(true)
  };

  const fetchOssFile = async () => {
    try {
      const url = await getOssFile(fileName)
      if (url.data) setUrlList([...urlList, url.data])
    } finally {
      console.log('done')
    }
  }

  const onChange = (fs) => {
    if (fs.file.status === 'done') {
      fetchOssFile()
    }
    setFileList([...fs.fileList])
  }

  const onRemove = (fs) => {
    const index = fileList.findIndex(item => item.uid === fs.uid)
    let urlListAfterRemove = urlList
    urlListAfterRemove.splice(index, 1)
    setUrlList(urlListAfterRemove)
    const fileListAfterRemove = fileList.filter(item => item.uid !== fs.uid)
    setFileList(fileListAfterRemove)
  }

  const uploadConfig = {
    name: 'file',
    disabled: props.disabled,
    fileList: fileList,
    action: config.ossHost,
    onRemove: (fs) => onRemove(fs),
    onChange: (fs) => onChange(fs),
    data: getExtraData(),
    listType: "picture-card",
    onPreview: (fs) => handlePreview(fs),
    beforeUpload: () => setFileName(`${ossSignature.dir}${new Date().getTime()}`)
  }

  return <WingBlank style={{paddingTop: '8px'}}>
    <Upload {...uploadConfig} ref={upload}>
      <PlusOutlined style={{fontSize: '40px', color: '#ccc'}} />
      {/* <div style={{ marginTop: 8 }}>点击上传</div> */}
    </Upload>
    {previewVisible && <div className={styles.picBox}>
      <img
        src={require('../../assets/images/close-white.png')}
        alt="close icon"
        className={styles.closeIcon}
        onClick={() => setPreviewVisible(false)} />
      <img src={previewImg} alt="attach" className={styles.pic} />
    </div>}
  </WingBlank>
})

export default UploadFile