import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl';
import AgreementCheckbox from '../../components/common/AgreementCheckbox'

export default function Agreement({ changeChecked }) {

  const [agreementOne, setAgreementOne] = useState(false)
  const [agreementTwo, setAgreementTwo] = useState(false)

  useEffect(() => {
    changeChecked(agreementOne && agreementTwo)
  }, [agreementOne, agreementTwo])

  return <>
  <AgreementCheckbox setChecked={(checked) => setAgreementOne(checked)} checked={agreementOne}>
      <FormattedMessage 
        id="owsi-cd-agreement-one"
      />
    </AgreementCheckbox>
    <AgreementCheckbox setChecked={(checked) => setAgreementTwo(checked)} checked={agreementTwo}>
      <FormattedMessage 
        id="owsi-cd-agreement-two"
      />
    </AgreementCheckbox>
  </>
}