import React from 'react';
import { useHistory } from 'react-router-dom';
import { AddOutline } from 'antd-mobile-icons';
import AppList from './AppList';
import { getParameterByName } from '../../components/common/utils';
import { issueCertsOptions, useIssueCertsList } from '../../services/hooks'

export default function IssueCertsListPage() {
  const type = getParameterByName('type');
  const { push } = useHistory();
  // const { formatMessage } = useIntl();

  // const getAppsData = async (params) => {
  //   try {
  //     const data = { page: params ? params.current : 1 };
  //     const response = await efriAppsList(data);
  //     if (response.error) {
  //       showErrorDialog(
  //         formatMessage({ id: 'common-errorTitle' }),
  //         response.error.clientMsg,
  //         formatMessage({ id: 'common-know' })
  //       );
  //     } else {
  //       return {
  //         total: response.data.totalElements,
  //         list: response.data.content,
  //       };
  //     }
  //   } catch (e) {
  //     showErrorDialog(
  //       formatMessage({ id: 'common-errorTitle' }),
  //       formatMessage({ id: 'common-errorStatus' }),
  //       formatMessage({ id: 'common-know' })
  //     );
  //   }
  // };

  const { data, loading, pagination } = useIssueCertsList(type);
  //   // (params) => {console.log('PARAMS:', params);getAppsData({ current: params ? params.current : 1})},
  //   // {
  //   //   paginated: true,
  //   // }
  // // );
  // const { data, loading, pagination } = result;
  // const apps = data ? data.list : [];

  if(!type) return null;
  return (
    <AppList
      data={data?.list || []}
      detailUrl={`/issue_certs/detail?type={type}`}
      pagination={pagination}
      title={issueCertsOptions[type].nameId}
      rightContent={
        <AddOutline fontSize={20} onClick={() => push(`/issue_certs/new?type=${type}`)} />
      }
      loading={loading}
    />
  );
};