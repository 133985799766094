import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Header from '../../components/common/FormHeader';
import { useIntl, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import FDPICItems from './FDPICItems'
import { getFdPicList } from '../../services/api'
import { pageLoading, pageLoaded } from '../../actions';
import { showAlert } from '../../components/common/utils';

const FDPICList = () => {

  const { push } = useHistory()
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const [list, setList] = useState()
  const { profile, userInfo } = useSelector((state) => ({
    profile: state.data.profile,
    userInfo: state.data.userInfo,
  }));

  useEffect(() => {
    fetchFdPicList()
  }, [])

  const fetchFdPicList = async (page) => {
    try {
      dispatch(pageLoading());
      const list = await getFdPicList({ page })
      if (list.error) {
        showAlert(
          list.error.clientMsg,
          formatMessage({ id: 'common-close' })
        );
        return;
      }
      setList(list.data)
    } finally {
      dispatch(pageLoaded());
    }
  }

  if (!list || !userInfo || !profile) return null

  return <>
    <Header
      text={<FormattedMessage id="fd-pic-title" />}
      rightContent={<i
        className="fa fa-plus-square-o fa-lg"
        style={{ color: '#fff' }}
        onClick={() => push('/new_fd_pic',
          {
            instructor: list.userTypeDTO ? { instructorNumber: list.userTypeDTO.memberNumber, instructorName: list.userTypeDTO.userName } : null,
            store: list.storeInfoDTO
          })}
      ></i>}
    />
    {list.fdApplicatonDetailDTOS.map(item => <FDPICItems data={item} setList={setList} list={list} />)}
  </>
}

export default FDPICList