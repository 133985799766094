import React, { useEffect, useState } from 'react'
import { WhiteSpace } from 'antd-mobile'
import { Button, ResultPage } from 'antd-mobile-v5'
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useStripeResult, useTradeVanResult } from '../../services/hooks'
import { getParameterByName, specialtyInsMapping } from '../../components/common/utils'
import styles from './applicationSubmitResult.module.css'

const PaymentResultPage = () => {

  const sessionId = getParameterByName('session_id')
  const payToken = getParameterByName('payToken')
  const [ paymentDetail, setPaymentDetail ] = useState()
  const { push } = useHistory()
  const { formatMessage } = useIntl();
  const { run: getStripeResult } = useStripeResult({
    onSuccess: (data) => {
      if (data.type) setPaymentDetail(data)
    }
  });
  const { run: getTradeVanResult } = useTradeVanResult({
    onSuccess: (data) => {
      setPaymentDetail(data)
    }
  })

  useEffect(() => {
    if (sessionId) getStripeResult(sessionId)
    else if (payToken) getTradeVanResult(payToken)
    else return
  }, [])

  if (!paymentDetail) return null

  return <ResultPage
    status='success'
    title={formatMessage({ id: "credit-card-pay-success" })}
    description={
      <>
        <span className={styles.currency}>
          {paymentDetail.currencyType} 
        </span>
        <span className={styles.price}>
          {paymentDetail.price}
        </span>
      </>
    }
  >
    {paymentDetail.items.map(item =>
      <>
        <ResultPage.Card style={{ padding: 8 }}>
          <div className={styles['goods-info']}>
            <span>商品名：</span>
            <span>{paymentDetail.type === 'MEMBER_APPLICATION_SI' ? specialtyInsMapping[item.name] : item.name}</span>
          </div>
          <div className={styles['goods-info']}>
            <span>数量：</span>
            <span>{item.quantity}</span>
          </div>
          {item.unitPrice && <div className={styles['goods-info']}>
            <span>单价：</span>
            <span>{paymentDetail.currencyType} {item.unitPrice}</span>
          </div>}
        </ResultPage.Card>
        <WhiteSpace />
      </>)}
    <WhiteSpace />
    <Button
      block
      color="primary"
      onClick={() => push('/myProfile')}>
      <FormattedMessage id="common-back" />
    </Button>
  </ResultPage>
}

export default PaymentResultPage