import { Form, Input, Button } from 'antd-mobile-v5';
import React from 'react';
import { useIntl } from 'react-intl';
import { LockOutline } from 'antd-mobile-icons';
import styles from './Register.module.css';
import { PASSWORD_PATTERN } from '../../utils/user';

export default function PasswordConfirm({form}) {
  const {formatMessage} = useIntl();
  return <>
      <Form.Item
        label={<LockOutline />}
        name="password"
        className={styles.formItem}
        rules={[
          {
            required: true,
            message: formatMessage({ id: 'common-reset' }),
          },
          {
            pattern: PASSWORD_PATTERN,
            message: formatMessage({ id: 'common-reset' }),
          },
        ]}
      >
        <Input
          type="password"
          placeholder={formatMessage({ id: 'common-passwordInput' })}
        />
      </Form.Item>
      <Form.Item
        label={<LockOutline />}
        name="passwordConfirm"
        className={styles.formItem}
        rules={[
          {
            required: true,
            message: formatMessage({ id: 'common-reset' }),
          },
          {
            pattern: PASSWORD_PATTERN,
            message: formatMessage({ id: 'common-reset' }),
          },
        ]}
      >
        <Input
          type="password"
          placeholder={formatMessage({ id: 'common-confirmPassword' })}
        />
      </Form.Item>

  </>
}